import React from "react";
import styles from "./dashboard.module.css";
import { nFormatter } from "../../helper-functions/helper/helper";

const Cards = ({ ordercardData = [], selectedOption = "", type = "" }) => {
  console.log(ordercardData, "ordercardData");
  return (
    <div className="row g-0 justify-content-center gap-3">
      {ordercardData?.map((item) => (
        <div
          className={`border_1px_e5ecff rounded-4 p-3 my-3 col-sm-6 ${styles.order_cards}`}
        // style={{ maxWidth: "45%" }}
        >
          <span className="d-flex gap-2 align-items-center color_030304 fw_500">
            <span className="border_1px_e5ecff p-sm-2 px-1 rounded-2">
              {item?.icon}
            </span>
            {item.title}
          </span>
          <span className=" p-3 d-flex justify-content-center color_030304 fs_36  fs_xs_24 fw_600">
            {(item.title === "Today’s Sales" || item.title === "Sales") && (localStorage.getItem("currency") + " " ?? "₹")}    {item?.value ? nFormatter(item.value) : "--"}
            <span className="color_9E9E9E fw_400 fs_18 align-self-end pb-2 fs_xs_12">
              {item?.value ? item?.timeSpan && `${type === "analytics" ? "" : "/"}${selectedOption}` : ""}
            </span>
          </span>
        </div>
      ))}
    </div>
  );
};

export default Cards;
