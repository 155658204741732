import React, { useEffect, useMemo, useState } from "react";
import { BackButton, SaveButton } from "../buttons/text-button";
import ImageUpload from "../forms/image-upload";
import { useDispatch } from "react-redux";
import { categoriesImgUploadAction } from "../../../redux/actions/categories-action";
import Compressor from "compressorjs";
import errorToast from "../../helper-functions/helper/error-toast";
import InputField from "../forms/input-field";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectField from "../forms/select-field";
import ToggleSwitch, { SettingsToggle } from "../buttons/toggle-button";
import { Country } from "country-state-city";
import { isEqual } from "lodash";
import {
  siteCreateAction,
  siteViewAction,
} from "../../../redux/actions/configuration-action";
import successToast from "../../helper-functions/helper/sucess-toast";
import { useNavigate } from "react-router";
import backIcon from "../../../assets/icons/back-arrow-button.svg";
import ErrorMsg from "../forms/error-msg";
import LoadingSpinnerTable from "../../helper-functions/helper/loadingSpinner-table";
import TextAreaField from "../forms/textarea-field";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  genderOptions,
  indusOptions,
} from "../../helper-functions/helper/data";
import { removeUnderScore } from "../../helper-functions/helper/helper";
export default function SiteSettings({ name, closeSection }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [reload, setReload] = useState(false);
  // const viewData = useSiteSettings();

  const [viewData, setViewData] = useState({});
  const [imgUrl, setImageUrl] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const authenticationMethod = [
    { label: "Login with Email Address & Password", value: "login_with_email" },
    { label: "Login with Mobile Number & OTP", value: "login_with_phone" },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(siteViewAction(onViewSuccess, onViewError));
  }, []);
  const onViewSuccess = (data) => {
    setViewData(data?.data);
    setLoading(false);
  };
  const onViewError = (data) => {
    setLoading(false);
  };

  console.log(viewData, "frgtfsdgdfgdfgdfgdf");

  useEffect(() => {
    setLogoUrl(viewData?.logo ?? "");
    setImageUrl(viewData?.site_icon ?? "");
  }, [viewData]);

  const [imageSize, setImageSize] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getImgUrlLogo = (img) => {
    if (img.size <= 10485760) {
      setLogoLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(
            categoriesImgUploadAction(result, onLogoSuccess, onLogoError)
          );
        },
      });
    } else {
      setImageSize(true);
    }
  };
  const onLogoSuccess = (data) => {
    setLogoLoading(false);
    setLogoUrl(data);
  };
  const onLogoError = (data) => {
    setLogoLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };

  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
  };
  const onImgSuccess = (data) => {
    setImgLoading(false);
    setImageUrl(data);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };

  function resetLogoFn(type) {
    // setLogoUrl(viewData?.logo);
    if (type === "clear") {
      setLogoUrl("");
    } else {
      setLogoUrl(viewData?.logo);
    }
  }
  // function resetIconFn() {
  //   setImageUrl(viewData?.site_icon);
  // }
  function resetIconFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setImageUrl(viewData?.site_icon);
    }
  }
  const countriesCurrencyList = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: `${country.currency} (${country.name})`,
        value: `${country.currency}`,
        symbol: `${getSymbolFromCurrency(country.currency)}`,
      })),
    []
  );

  const initialValues = {
    logo: viewData?.logo ?? "",
    site_icon: viewData?.site_icon ?? "",
    site_name: viewData?.site_name,
    title: viewData?.title,
    subtitle: viewData?.subtitle,
    description: viewData?.description,
    currency: countriesCurrencyList?.find(
      (item) => item?.symbol === viewData?.currency
    )?.value,
    industry_type: viewData?.industry_type,
    out_of_stock: viewData?.out_of_stock,
    live_map_enable: viewData?.live_map_enable,
    order_alerts: viewData?.order_alerts ?? [],
    brands: viewData?.brands,
    store_hours: {
      sunday: {
        from: viewData?.store_hours?.sunday?.from,
        to: viewData?.store_hours?.sunday?.to,
        closed: viewData?.store_hours?.sunday?.closed,
      },
      monday: {
        from: viewData?.store_hours?.monday?.from,
        to: viewData?.store_hours?.monday?.to,
        closed: viewData?.store_hours?.monday?.closed,
      },
      tuesday: {
        from: viewData?.store_hours?.tuesday?.from,
        to: viewData?.store_hours?.tuesday?.to,
        closed: viewData?.store_hours?.tuesday?.closed,
      },
      wednesday: {
        from: viewData?.store_hours?.wednesday?.from,
        to: viewData?.store_hours?.wednesday?.to,
        closed: viewData?.store_hours?.wednesday?.closed,
      },
      thursday: {
        from: viewData?.store_hours?.thursday?.from,
        to: viewData?.store_hours?.thursday?.to,
        closed: viewData?.store_hours?.thursday?.closed,
      },
      friday: {
        from: viewData?.store_hours?.friday?.from,
        to: viewData?.store_hours?.friday?.to,
        closed: viewData?.store_hours?.friday?.closed,
      },
      saturday: {
        from: viewData?.store_hours?.saturday?.from,
        to: viewData?.store_hours?.saturday?.to,
        closed: viewData?.store_hours?.saturday?.closed,
      },
    },
    store_hours_active: viewData?.store_hours_active,
    loginMethods: {
      login_with_email: viewData?.login_with_email || false,
      login_with_phone: viewData?.login_with_phone || false,
    },
  };
  const validateDay = (hours, day, yupContext) => {
    if (hours.closed === false && (!hours.from || !hours.to)) {
      return yupContext.createError({
        path: `store_hours.${day}`, // Adjust the path to point to the correct field
        message: {
          from: !hours.from ? "Required" : "",
          to: !hours.to ? "Required" : "",
        },
      });
    }
    return true; // If everything is ok, pass the validation
  };
  console.log(initialValues, "rgeytety");
  const validationSchema = Yup.object().shape({
    logo: Yup.mixed().required("Required"),
    site_icon: Yup.mixed().required("Required"),
    site_name: Yup.string().trim().required("Required"),
    title: Yup.string().trim().required("Required"),
    subtitle: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
    currency: Yup.string().trim().required("Required"),
    industry_type: Yup.string().trim().required("Required"),
    loginMethods: Yup.object()
      .shape({
        login_with_email: Yup.boolean(),
        login_with_phone: Yup.boolean(),
      })
      .test(
        "at-least-one-method",
        "Please select at least one authentication method.",
        (value) => value.login_with_email || value.login_with_phone
      ),
    store_hours: Yup.object().shape({
      sunday: Yup.object().test(
        "sunday-validation",
        "Sunday hours validation failed",
        function (value) {
          return validateDay(value, "sunday", this);
        }
      ),
      monday: Yup.object().test(
        "monday-validation",
        "Monday hours validation failed",
        function (value) {
          return validateDay(value, "monday", this);
        }
      ),
      tuesday: Yup.object().test(
        "tuesday-validation",
        "Tuesday hours validation failed",
        function (value) {
          return validateDay(value, "tuesday", this);
        }
      ),
      wednesday: Yup.object().test(
        "wednesday-validation",
        "Wednesday hours validation failed",
        function (value) {
          return validateDay(value, "wednesday", this);
        }
      ),
      thursday: Yup.object().test(
        "thursday-validation",
        "Thursday hours validation failed",
        function (value) {
          return validateDay(value, "thursday", this);
        }
      ),
      friday: Yup.object().test(
        "friday-validation",
        "Friday hours validation failed",
        function (value) {
          return validateDay(value, "friday", this);
        }
      ),
      saturday: Yup.object().test(
        "saturday-validation",
        "Saturday hours validation failed",
        function (value) {
          return validateDay(value, "saturday", this);
        }
      ),
    }),
  });

  // console.log(currencyToSymbolMap("$"), "fgsdgdfgdfgdfgdf");
  const onSubmit = (values) => {
    dispatch(
      siteCreateAction(
        {
          logo: logoUrl,
          site_icon: imgUrl,
          site_name: values?.site_name,
          title: values?.title,
          subtitle: values?.subtitle,
          description: values?.description,
          currency: getSymbolFromCurrency(values?.currency),
          industry_type: values?.industry_type,
          out_of_stock: values?.out_of_stock,
          live_map_enable: values?.live_map_enable,
          brands: values?.brands,
          store_hours_active: values?.store_hours_active,
          login_with_email: values?.loginMethods?.login_with_email,
          login_with_phone: values?.loginMethods?.login_with_phone,
          order_alerts: values?.order_alerts ?? null,
          store_hours: {
            sunday: {
              from: values?.store_hours_active
                ? values?.store_hours?.sunday?.from
                : "",
              to: values?.store_hours_active
                ? values?.store_hours?.sunday?.to
                : "",
              closed: values?.store_hours_active
                ? values?.store_hours?.sunday?.closed
                : true,
            },
            monday: {
              from: values?.store_hours_active
                ? values?.store_hours?.monday?.from
                : "",
              to: values?.store_hours_active
                ? values?.store_hours?.monday?.to
                : "",
              closed: values?.store_hours_active
                ? values?.store_hours?.monday?.closed
                : true,
            },
            tuesday: {
              from: values?.store_hours_active
                ? values?.store_hours?.tuesday?.from
                : "",
              to: values?.store_hours_active
                ? values?.store_hours?.tuesday?.to
                : "",
              closed: values?.store_hours_active
                ? values?.store_hours?.tuesday?.closed
                : true,
            },
            wednesday: {
              from: values?.store_hours_active
                ? values?.store_hours?.wednesday?.from
                : "",
              to: values?.store_hours_active
                ? values?.store_hours?.wednesday?.to
                : "",
              closed: values?.store_hours_active
                ? values?.store_hours?.wednesday?.closed
                : true,
            },
            thursday: {
              from: values?.store_hours_active
                ? values?.store_hours?.thursday?.from
                : "",
              to: values?.store_hours_active
                ? values?.store_hours?.thursday?.to
                : "",
              closed: values?.store_hours_active
                ? values?.store_hours?.thursday?.closed
                : true,
            },
            friday: {
              from: values?.store_hours_active
                ? values?.store_hours?.friday?.from
                : "",
              to: values?.store_hours_active
                ? values?.store_hours?.friday?.to
                : "",
              closed: values?.store_hours_active
                ? values?.store_hours?.friday?.closed
                : true,
            },
            saturday: {
              from: values?.store_hours_active
                ? values?.store_hours?.saturday?.from
                : "",
              to: values?.store_hours_active
                ? values?.store_hours?.saturday?.to
                : "",
              closed: values?.store_hours_active
                ? values?.store_hours?.saturday?.closed
                : true,
            },
          },
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    console.log(data, "fgswdrfkgjsdfjkhsdfjkh");
    localStorage.setItem("brand", data?.data?.brands);
    setReload(!reload);
    successToast(data.message);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }

  console.log(countriesCurrencyList, "fgsdgdfgdfgdfgdf");

  return (
    <>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors, handleChange }) => {
            console.log(values, "rgeytety");
            const handleStoreHoursChange = (fieldName, val) => {
              setFieldValue(fieldName, !val);
            };
            const handleToggleBtnChange = (fieldName, day) => {
              const closed = !values.store_hours[day].closed;

              setFieldValue(fieldName, closed);

              if (closed) {
                setFieldValue(`store_hours.${day}.from`, "");
                setFieldValue(`store_hours.${day}.to`, "");
              }
            };
            return (
              <>
                <Form>
                  <div
                    style={{ minHeight: "4.4rem" }}
                    className="border_bottom_1px_e5ecff py-3"
                  >
                    <div className="col-xl-11 px-md-5 px-3 row g-0 justify-content-between">
                      <span
                        className="fw_600 font_montserrat fs_22 color_030304 d-flex align-items-center"
                        style={{ width: "fit-content" }}
                      >
                        <img
                          className="d-inline-block d-md-none me-2"
                          onClick={closeSection}
                          src={backIcon}
                        />{" "}
                        <span>Site Settings</span>
                      </span>
                      {
                        <div
                          className="d-flex gap-3"
                          style={{ width: "fit-content" }}
                        >
                          <div
                            onClick={() => {
                              resetIconFn();
                              resetLogoFn();
                            }}
                          >
                            <BackButton
                              type="reset"
                              name="UNDO"
                              backClassName="fs_14"
                              disabled={valuesCompareFn(values)}
                            />
                          </div>
                          <SaveButton
                            type="submit"
                            backClassName="fs_14"
                            disabled={valuesCompareFn(values)}
                          />
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-xxl-8 px-md-5 px-3 px-md-0 mb-5">
                    <ImageUpload
                      labelClass="color_030304 fs_14"
                      name="logo"
                      getImgUrl={getImgUrlLogo}
                      resetFn={resetLogoFn}
                      data={logoUrl}
                      imgLoading={logoLoading}
                      setImgLoading={setLogoLoading}
                      title={"Logo"}
                      height="150px"
                      maxWidth="150px"
                      id={"logo"}
                      insideLogoHeight={"70px"}
                      insideLogoWidth={"70px"}
                      uploadIconWidth="26px"
                      containerClass="mt-lg-5 mt-4"
                      imgHeight="150px"
                      imgWidth="150px"
                      deleteIconWidth="26px"
                      page={"site"}
                      setFieldValue={setFieldValue}
                      error={errors?.logo ? true : false}
                    />
                    <ImageUpload
                      labelClass="color_030304 fs_14"
                      name="site_icon"
                      getImgUrl={getImgUrl}
                      resetFn={resetIconFn}
                      data={imgUrl}
                      imgLoading={imgLoading}
                      setImgLoading={setImgLoading}
                      title={"Site Icon"}
                      height="100px"
                      maxWidth="100px"
                      id={"site_icon"}
                      insideLogoHeight={"50px"}
                      insideLogoWidth={"50px"}
                      uploadIconWidth="26px"
                      containerClass=""
                      imgHeight="100px"
                      imgWidth="100px"
                      deleteIconWidth="26px"
                      page={"site"}
                      setFieldValue={setFieldValue}
                      error={errors?.site_icon ? true : false}
                    />
                    {viewData?.loading ? (
                      <LoadingSpinnerTable />
                    ) : (
                      <React.Fragment>
                        <InputField
                          placeholder="Enter site name"
                          containerClass="mb-1 position-relative"
                          errMsgContainerClass="ps-2"
                          label="Site Name"
                          name="site_name"
                          type="text"
                        />
                        <InputField
                          placeholder="Enter title"
                          containerClass="mb-1 position-relative"
                          errMsgContainerClass="ps-2"
                          label="Title"
                          name="title"
                          type="text"
                        />
                        <InputField
                          placeholder="Enter subtitle"
                          containerClass="mb-1 position-relative"
                          errMsgContainerClass="ps-2"
                          label="Subtitle"
                          name="subtitle"
                          type="text"
                        />
                        <TextAreaField
                          label="Description"
                          placeholder="write a description about your store"
                          name="description"
                          id={"description"}
                        />
                        <SelectField
                          labelClass="color_#444444 fs_14"
                          placeholder={"Select currency"}
                          label={"Currency"}
                          id={"currency"}
                          name="currency"
                          errMsgContainerClass="ps-2"
                          isFormikControlled={true}
                          options={countriesCurrencyList}
                        />
                        <SelectField
                          placeholder={"Select Industry Type"}
                          options={indusOptions}
                          label={"Industry Type"}
                          id={"industry_type"}
                          name="industry_type"
                          isSearchable={false}
                          errMsgContainerClass="ps-2"
                        />
                        {/* <RadioButton
                      inputLabelClass="fw_400 fs_14"
                      labelClass="color_030304 d-flex fs_14 mb-1 fw_500"
                      name="authentication_method"
                      options={authenticationMethod}
                      label="Authentication Method"
                      onChange={(e) => {
                        if (viewData?.login_with_email === true || viewData?.login_with_phone === true) {
                          setShow(true);
                        } else {
                          handleChange(e)
                        }
                      }}
                    /> */}
                        <Field
                          name="loginMethods"
                          render={({ field, form }) => (
                            <div>
                              <label className="color_030304 d-flex fs_16 mb-1 fw_500">
                                Authentication Method
                              </label>
                              <div className="mt-1">
                                <div className="form-check">
                                  <input
                                    {...field}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="login_with_email"
                                    checked={field.value.login_with_email}
                                    onChange={() => {
                                      form.setFieldValue(
                                        "loginMethods.login_with_email",
                                        !field.value.login_with_email
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="login_with_email"
                                  >
                                    Login with Email Address & Password
                                  </label>
                                </div>
                                <div className="form-check mt-2">
                                  <input
                                    {...field}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="login_with_phone"
                                    checked={field.value.login_with_phone}
                                    onChange={() => {
                                      form.setFieldValue(
                                        "loginMethods.login_with_phone",
                                        !field.value.login_with_phone
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="login_with_phone"
                                  >
                                    Login with Mobile Number & OTP
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                        <Field
                          name="order_alerts"
                          render={({ field, form }) => (
                            <div>
                              <label className="color_030304 d-flex fs_16 mb-1 fw_500 mt-4">
                                Order Alerts
                              </label>
                              <div className="mt-1">
                                {["sms", "mail", "web"].map((method, index) => (
                                  <div className="form-check mt-2" key={method}>
                                    <input
                                      type="checkbox"
                                      id={`order_alert_${method}`}
                                      className="form-check-input"
                                      checked={field.value?.includes(method)}
                                      onChange={() => {
                                        const updatedMethods =
                                          field?.value?.includes(method) // Remove the nested .order_alerts
                                            ? field?.value?.filter(
                                                (item) => item !== method
                                              )
                                            : [...field.value, method];
                                        form.setFieldValue(
                                          "order_alerts",
                                          updatedMethods
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`order_alert_${method}`}
                                    >
                                      {method === "sms"
                                        ? "SMS"
                                        : method === "mail"
                                        ? "Email"
                                        : "Web Browser Alert"}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        />
                        <ErrorMsg name="loginMethods" />

                        <SettingsToggle
                          title="Brands"
                          id="brands"
                          subtitle="Quick enable or disable product brands"
                          handleToggleBtnChange={() => {
                            handleStoreHoursChange("brands", values.brands);
                          }}
                          name={"brands"}
                          value={values.brands}
                        />
                        <SettingsToggle
                          title="Out Of Stock Products"
                          id="out_of_stock"
                          subtitle="Quick enable or disable out of stock product to storeFront"
                          handleToggleBtnChange={() => {
                            handleStoreHoursChange(
                              "out_of_stock",
                              values.out_of_stock
                            );
                          }}
                          name={"out_of_stock"}
                          value={values.out_of_stock}
                        />
                        <SettingsToggle
                          title="Enable Live Location/Map"
                          id="live_map_enable"
                          subtitle="Quick enable or disable Live Location"
                          handleToggleBtnChange={() => {
                            handleStoreHoursChange(
                              "live_map_enable",
                              values.live_map_enable
                            );
                          }}
                          name={"live_map_enable"}
                          value={values.live_map_enable}
                        />

                        <SettingsToggle
                          id="store_hours_active"
                          handleToggleBtnChange={() => {
                            handleStoreHoursChange(
                              "store_hours_active",
                              values.store_hours_active
                            );
                          }}
                          name={"store_hours_active"}
                          value={values.store_hours_active}
                        />

                        {values.store_hours_active && (
                          <>
                            <div className="d-sm-flex align-items-center text-black fw_500 mt-3 mb-1 d-none d-sm-block">
                              <div className="col-xxl-3 col-4">Days</div>
                              <div className="col-xxl-9 col-8 d-flex">
                                <div className="col-6">From</div>
                                <div className="col-6">To</div>
                              </div>
                            </div>
                            {Object.keys(values.store_hours).map((day) => {
                              const dayData = values.store_hours[day];
                              return (
                                <div className="d-sm-flex" key={day}>
                                  <div className="col-xxl-3 col-sm-4 col-10">
                                    <ToggleSwitch
                                      name={`store_hours.${day}.closed`}
                                      label={removeUnderScore(day)}
                                      handleToggleBtnChange={() =>
                                        handleToggleBtnChange(
                                          `store_hours.${day}.closed`,
                                          day
                                        )
                                      }
                                      id={day}
                                      value={!dayData.closed}
                                      containerClassName="px-0"
                                    />
                                    <div
                                      className="d-none d-sm-block"
                                      style={{ minHeight: "1.32rem" }}
                                    ></div>
                                  </div>
                                  {!dayData.closed ? (
                                    <div className="col-xxl-9 col-sm-8 col-12 d-flex">
                                      <div className="col-6 pe-xxl-3 pe-2">
                                        <InputField
                                          containerClass="position-relative"
                                          errMsgContainerClass="ps-2"
                                          label=""
                                          name={`store_hours.${day}.from`}
                                          type="time"
                                        />
                                        {/* Render any additional fields related to 'from' */}
                                      </div>
                                      <div className="col-6 ps-xxl-3 ps-2">
                                        <InputField
                                          containerClass="position-relative"
                                          errMsgContainerClass="ps-2"
                                          label=""
                                          name={`store_hours.${day}.to`}
                                          type="time"
                                        />
                                        {/* Render any additional fields related to 'to' */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor: "#E5ECFF",
                                        height: "40px",
                                      }}
                                      className="mb-3 mb-sm-0 col-xxl-9 col-sm-8 col-12 ps-3 fw_500 d-flex text-black align-items-center rounded-1"
                                    >
                                      Closed
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  <div style={{ minHeight: "4.4rem" }} className="py-3">
                    <div className="col-xl-11 px-md-5 px-3 row g-0 justify-content-between">
                      <span
                        className="fw_600 font_montserrat fs_22 color_030304 d-flex align-items-center"
                        style={{ width: "fit-content" }}
                      ></span>
                      {
                        <div
                          className="d-flex gap-3"
                          style={{ width: "fit-content" }}
                        >
                          <div
                            onClick={() => {
                              resetIconFn();
                              resetLogoFn();
                            }}
                          >
                            <BackButton
                              type="reset"
                              name="UNDO"
                              backClassName="fs_14"
                              disabled={valuesCompareFn(values)}
                            />
                          </div>
                          <SaveButton
                            type="submit"
                            backClassName="fs_14"
                            disabled={valuesCompareFn(values)}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
}
