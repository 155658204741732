import {
  MAIL_NOTIFY_CREATE,
  MAIL_NOTIFY_EDIT,
  MAIL_NOTIFY_VIEW,
  SITE_CREATE,
  SITE_VIEW,
  TAX_CREATE,
  TAX_EDIT,
  TAX_VIEW,
  CONFIG_PAGE_CREATE,
  CONFIG_PAGE_DELETE,
  CONFIG_PAGE_EDIT,
  CONFIG_PAGE_LIST,
  CONFIG_PAGE_VIEW,
  PAYMENTS_CREATE,
  PAYMENTS_EDIT,
  PAYMENTS_VIEW,
  SHIPPING_CREATE,
  SHIPPING_VIEW,
  CONTACT_INFO_CREATE_UPDATE,
  HOME_SETTINGS_CREATE,
  HOME_SETTINGS_VIEW,
  CONTACT_INFO_VIEW,
  SITE_VIEW_WITHOUT_ACCESS,
} from "../types";

export const mailNotifyCreateAction = (data, onSuccess, onError) => {
  return {
    type: MAIL_NOTIFY_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const mailNotifyViewAction = (onSuccess, onError) => {
  return {
    type: MAIL_NOTIFY_VIEW,
    onSuccess,
    onError,
  };
};

export const mailNotifyEditAction = (data, onSuccess, onError) => {
  return {
    type: MAIL_NOTIFY_EDIT,
    data,
    onSuccess,
    onError,
  };
};

/*** Site Settings Start***/
export const siteCreateAction = (data, onSuccess, onError) => {
  return {
    type: SITE_CREATE,
    data,
    onSuccess,
    onError,
  };
};



export const siteViewAction = (
  onSuccess,
  onerror) => {
  return {
    type: SITE_VIEW,
    onSuccess,
    onerror
  };
};



export const siteViewWithoutAccessAction = () => {
  return {
    type: SITE_VIEW_WITHOUT_ACCESS,
  };
};





/*** Site Settings End***/
export const configPageListAction = (data, onSuccess, onError) => {
  return {
    type: CONFIG_PAGE_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const configPageCreateAction = (data, onSuccess, onError) => {
  return {
    type: CONFIG_PAGE_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const configPageEditAction = (data, onSuccess, onError) => {
  return {
    type: CONFIG_PAGE_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const paymentsCreateAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENTS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const configPageDeleteAction = (data, onSuccess, onError) => {
  return {
    type: CONFIG_PAGE_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const paymentsViewAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENTS_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const paymentsEditAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENTS_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const configPageViewAction = (data, onSuccess, onError) => {
  return {
    type: CONFIG_PAGE_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const taxCreateAction = (data, onSuccess, onError) => {
  return {
    type: TAX_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const taxViewAction = (onSuccess, onError) => {
  return {
    type: TAX_VIEW,
    onSuccess,
    onError,
  };
};

export const taxEditAction = (data, onSuccess, onError) => {
  return {
    type: TAX_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const shippingCreateAction = (data, onSuccess, onError) => {
  return {
    type: SHIPPING_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const shippingViewAction = (onSuccess, onError) => {
  return {
    type: SHIPPING_VIEW,
    onSuccess,
    onError,
  };
};

export const contactInfoCreateUpdateAction = (data, onSuccess, onError) => {
  return {
    type: CONTACT_INFO_CREATE_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

export const contactInfoViewAction = (onSuccess, onError) => {
  return {
    type: CONTACT_INFO_VIEW,
    onSuccess,
    onError,
  };
};

export const homeSettingsCreateAction = (data, onSuccess, onError) => {
  return {
    type: HOME_SETTINGS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const homeSettingsViewAction = (onSuccess, onError) => {
  return {
    type: HOME_SETTINGS_VIEW,
    onSuccess,
    onError,
  };
};





/*********/
