import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/ui/tables/table";
import { useDispatch } from "react-redux";
import {
  attributesDeleteAction,
  attributesMultiDeleteAction,
  attributesTableAction,
} from "../../redux/actions/attributes-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import { useLocation } from "react-router";
import NotificationComponent from "../../components/notificationComponent";

const AttributesTable = () => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [attributes, setAttributes] = useState([]);
  const [pagination, setPagination] = useState();
  const [reload, setReload] = useState(false);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      attributesTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, reload, inputValue, sortConfig]);
  const onSuccess = (data) => {
    setAttributes(data?.data?.attributes);
    setPagination(data?.data?.pagination);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };
  const tableData = attributes.map((item) => ({
    id: item?.id,
    name: removeUnderScore(item?.name),
    Action: ["/attributes/view/", "/attributes/edit/"],
  }));
  return (
    <div className=" col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5 ">
      <NotificationComponent />
      <DynamicTable
        placeholder={"Search attribute here by id, name "}
        modalText={"attribute"}
        tabletitle={"Attributes"}
        tableHeading={tableAttributeHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputVal
        ue={inputValue}
        createButtonName="CREATE ATTRIBUTE"
        createpath="/attributes/create"
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={attributesDeleteAction}
        multiDeleteAction={attributesMultiDeleteAction}
        loading={loading}
        setLoading={setLoading}
        error={error}
        id_name={"attribute_id"}
        setReload={setReload}
        reload={reload}
        rolePermission={"attributes"}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
    </div>
  );
};
export default AttributesTable;
const tableAttributeHeader = [
  { label: "ID", value: "id" },
  { label: "Attribute Name", value: "name" },
  { label: "Actions", value: "Actions" },
];
