import React from 'react'
import "./table.css"
import search from "../../../assets/icons/searchIcon.svg"

function TableInputFilter({ inputValue, setInputValue, placeholder = "category", inputClass, checkedId = [], canWrite, rolePermission }) {
    return (
        <>
            <div className={`input_border w-100  d-flex align-items-center rounded-2 pb-1 ${inputClass} ${checkedId.length > 0 && canWrite(rolePermission) ? "col-xl-8" : "col-xl-7"} col-md-10 col-12 `}>
                <span className='ps-2'> <img width={"13px"} src={search} alt='search icon' /></span>
                <input placeholder={`${placeholder}`} className='border-0 py-1 table_search_input w-100 ps-1 rounded-3' type='text' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
            </div>
        </>
    )
}

export default TableInputFilter