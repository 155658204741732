import React from "react";

const AddressIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54064 4.50195C6.94734 4.50195 6.36737 4.67788 5.87407 5.0075C5.38076 5.33712 4.99628 5.80561 4.76924 6.35374C4.54219 6.90187 4.48279 7.50502 4.59853 8.08691C4.71428 8.66881 4.99998 9.20331 5.4195 9.62283C5.83902 10.0424 6.37352 10.3281 6.95542 10.4438C7.53731 10.5595 8.14046 10.5001 8.68859 10.2731C9.23672 10.0461 9.70521 9.66157 10.0348 9.16826C10.3644 8.67496 10.5404 8.09499 10.5404 7.50169C10.5404 6.70611 10.2243 5.94312 9.66177 5.38056C9.09921 4.818 8.33622 4.50195 7.54064 4.50195ZM7.54064 9.00156C7.24399 9.00156 6.95401 8.9136 6.70735 8.74879C6.4607 8.58398 6.26846 8.34974 6.15494 8.07567C6.04141 7.8016 6.01171 7.50003 6.06959 7.20908C6.12746 6.91814 6.27031 6.65089 6.48007 6.44113C6.68983 6.23137 6.95708 6.08852 7.24803 6.03064C7.53897 5.97277 7.84055 6.00247 8.11461 6.11599C8.38868 6.22952 8.62293 6.42176 8.78773 6.66841C8.95254 6.91506 9.04051 7.20505 9.04051 7.50169C9.04051 7.89948 8.88249 8.28098 8.6012 8.56226C8.31992 8.84354 7.93843 9.00156 7.54064 9.00156Z"
        fill={fill}
      />
      <path
        d="M7.5406 17.9999C6.90911 18.0032 6.28604 17.8551 5.72356 17.568C5.16107 17.281 4.67554 16.8633 4.30763 16.3501C1.44962 12.4077 0 9.44394 0 7.5406C0 5.54071 0.794454 3.62273 2.20859 2.20859C3.62273 0.794454 5.54071 0 7.5406 0C9.54049 0 11.4585 0.794454 12.8726 2.20859C14.2867 3.62273 15.0812 5.54071 15.0812 7.5406C15.0812 9.44394 13.6316 12.4077 10.7736 16.3501C10.4057 16.8633 9.92013 17.281 9.35764 17.568C8.79516 17.8551 8.17209 18.0032 7.5406 17.9999ZM7.5406 1.63711C5.97505 1.6389 4.47413 2.2616 3.36711 3.36861C2.2601 4.47563 1.6374 5.97655 1.63561 7.5421C1.63561 9.04947 3.05524 11.837 5.63201 15.3909C5.85077 15.6922 6.13775 15.9375 6.46948 16.1066C6.80121 16.2757 7.16826 16.3638 7.5406 16.3638C7.91294 16.3638 8.27999 16.2757 8.61172 16.1066C8.94345 15.9375 9.23043 15.6922 9.44919 15.3909C12.026 11.837 13.4456 9.04947 13.4456 7.5421C13.4438 5.97655 12.8211 4.47563 11.7141 3.36861C10.6071 2.2616 9.10615 1.6389 7.5406 1.63711Z"
        fill={fill}
      />
    </svg>
  );
};

export default AddressIcon;
