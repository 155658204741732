import { api } from "./api";

export const faqTableApi = (data, params) => {
  console.log(data);
  return api.get(
    `/core/faq/list?search=${data?.search}&page=${data?.page}&page_size=${data?.size}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};
export const faqDeleteApi = (data, params) => {
  return api.delete(
    `/core/faq/delete?id=${data}`,
    JSON.stringify(params)
  );
};
export const faqMultiDeleteApi = (params) => {
  return api.delete(`/core/faq/bulk-delete`, (params));
};
export const faqViewApi = (data) => {
  return api.get(`/core/faq/view?id=${data}`);
};

export const faqEditApi = (params) => {
  return api.post(`/core/faq/edit`, JSON.stringify(params));
};

export const faqCreateApi = (params) => {
  return api.post(`/core/faq/create`, JSON.stringify(params));
};


