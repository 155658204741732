import { all } from "redux-saga/effects";
import { watchproductEnquiryTableWorker, watchproductEnquiryViewWorker } from "../product-enquiry-saga";


export default function* productEnquiryRootSaga() {
  yield all([
    watchproductEnquiryTableWorker(),
    watchproductEnquiryViewWorker(),
  ]);
}
