import { useState, useEffect } from "react";

const useTableSorting = () => {
  const [sortConfig, setSortConfig] = useState(null);

  const requestSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.column === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ column, direction });
  };

  const sortData = (data) => {
    if (!sortConfig) {
      return data;
    }

    const { column, direction } = sortConfig;

    return data.sort((a, b) => {
      const valueA = a[column]?.toString().toLowerCase();
      const valueB = b[column]?.toString().toLowerCase();

      if (valueA < valueB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  return { sortConfig, requestSort, sortData };
};

export default useTableSorting;

