import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/ui/layouts/inner-layout";
import { Field, Form, Formik } from "formik";
import InputField from "../../../components/ui/forms/input-field";
import * as Yup from "yup";
import { removeUnderScore } from "../../../components/helper-functions/helper/helper";
import ErrorMsg from "../../../components/ui/forms/error-msg";
import { useLocation, useNavigate, useParams } from "react-router";
import * as adminRolesAction from "../../../redux/actions/admin-roles-action";
import { useDispatch } from "react-redux";
import errorToast from "../../../components/helper-functions/helper/error-toast";
import successToast from "../../../components/helper-functions/helper/sucess-toast";
import { isEqual } from "lodash";
import NotificationComponent from "../../../components/notificationComponent";

const AdminRolesEdit = () => {
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminRoleData, setAdminRoleData] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  useEffect(() => {
    setFetchLoading(true);
    dispatch(
      adminRolesAction.adminRoleViewAction(
        { role_id: params.id, page: 1, page_size: 16, search: "" },
        onFetchSuccess,
        onFetchError
      )
    );
  }, []);
  const onFetchSuccess = (data) => {
    setFetchLoading(false);
    setAdminRoleData(data?.data?.role);
  };
  const onFetchError = (data) => {
    setFetchLoading(false);
    errorToast(data.data.message);
    console.log("error", "error");
  };

  const initialValues = {
    role_id: adminRoleData?.id ? adminRoleData?.id : "",
    role_name: adminRoleData?.role_name ? adminRoleData?.role_name : "",
    permission: adminRoleData?.permission
      ? {
          admin_users: {
            read: adminRoleData.permission.admin_users?.read || false,
            write: adminRoleData.permission.admin_users?.write || false,
          },
          admin_roles: {
            read: adminRoleData.permission.admin_roles?.read || false,
            write: adminRoleData.permission.admin_roles?.write || false,
          },
          products: {
            read: adminRoleData.permission.products?.read || false,
            write: adminRoleData.permission.products?.write || false,
          },
          categories: {
            read: adminRoleData.permission.categories?.read || false,
            write: adminRoleData.permission.categories?.write || false,
          },
          discounts: {
            read: adminRoleData.permission.discounts?.read || false,
            write: adminRoleData.permission.discounts?.write || false,
          },
          attributes: {
            read: adminRoleData.permission.attributes?.read || false,
            write: adminRoleData.permission.attributes?.write || false,
          },
          tags: {
            read: adminRoleData.permission.tags?.read || false,
            write: adminRoleData.permission.tags?.write || false,
          },
          orders: {
            read: adminRoleData.permission.orders?.read || false,
            write: adminRoleData.permission.orders?.write || false,
          },
          customers: {
            read: adminRoleData.permission.customers?.read || false,
            write: adminRoleData.permission.customers?.write || false,
          },
          addresses: {
            read: adminRoleData.permission.addresses?.read || false,
            write: adminRoleData.permission.addresses?.write || false,
          },
          configuration: {
            read: adminRoleData.permission.configuration?.read || false,
            write: adminRoleData.permission.configuration?.write || false,
          },
          account: {
            read: adminRoleData.permission.account?.read || false,
            write: adminRoleData.permission.account?.write || false,
          },
          brands: {
            read: adminRoleData.permission.brands?.read || false,
            write: adminRoleData.permission.brands?.write || false,
          },
          deliveryPartner: {
            read: adminRoleData.permission.deliveryPartner?.read || false,
            write: adminRoleData.permission.deliveryPartner?.write || false,
          },
          productEnquiry: {
            read: adminRoleData.permission.productEnquiry?.read || false,
            write: adminRoleData.permission.productEnquiry?.write || false,
          },
          contactUs: {
            read: adminRoleData.permission.contactUs?.read || false,
            write: adminRoleData.permission.contactUs?.write || false,
          },
          plugins: {
            read: adminRoleData.permission.plugins?.read || false,
            write: adminRoleData.permission.plugins?.write || false,
          },
          themes: {
            read: adminRoleData.permission.themes?.read || false,
            write: adminRoleData.permission.themes?.write || false,
          },
        }
      : {
          admin_users: {
            read: false,
            write: false,
          },
          admin_roles: {
            read: false,
            write: false,
          },
          products: {
            read: false,
            write: false,
          },
          categories: {
            read: false,
            write: false,
          },
          discounts: {
            read: false,
            write: false,
          },
          attributes: {
            read: false,
            write: false,
          },
          tags: {
            read: false,
            write: false,
          },
          orders: {
            read: false,
            write: false,
          },
          customers: {
            read: false,
            write: false,
          },
          addresses: {
            read: false,
            write: false,
          },
          configuration: {
            read: false,
            write: false,
          },
          account: {
            read: false,
            write: false,
          },
          brands: {
            read: false,
            write: false,
          },
          deliveryPartner: {
            read: false,
            write: false,
          },
          productEnquiry: {
            read: false,
            write: false,
          },
          contactUs: {
            read: false,
            write: false,
          },
          plugins: {
            read: false,
            write: false,
          },
          themes: {
            read: false,
            write: false,
          },
        },
  };
  const validationSchema = Yup.object().shape({
    role_name: Yup.string().trim().required("Role Name is required"),
    permission: Yup.object().test(
      "at-least-one-selected",
      "Select at least one permission",
      (value) => {
        const permissions = Object.values(value);
        return permissions.some(
          (permission) => permission.read || permission.write
        );
      }
    ),
  });
  const onSubmit = (values) => {
    console.log(values);
    setLoading(true);
    dispatch(adminRolesAction.adminRoleEditAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    if (currentPage) {
      navigate(`/admin/roles`, { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
    successToast(data?.message);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data?.data?.message);
    console.log("error");
  };
  const handleCheckboxChange = (key, field, setFieldValue) => {
    return (e) => {
      const isChecked = e.target.checked;
      setFieldValue(`permission.${key}.${field}`, isChecked);
      if (field === "write" && isChecked) {
        setFieldValue(`permission.${key}.read`, true);
      }
    };
  };
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      <NotificationComponent />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <InnerLayout
              name={`${adminRoleData?.code}`}
              page="edit"
              edit={true}
              rolePermission={"admin_roles"}
              path={"/admin/roles"}
              disabled={valuesCompareFn(values)}
            />
            <div
              className="ms-5 pe-4 pe-md-0 mt-lg-4 mt-5"
              style={{ maxWidth: "36rem" }}
            >
              <InputField
                labelClass="text-black fw_500 fs_14"
                placeholder="Enter Role Name"
                containerClass="mb-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Role Name"
                name="role_name"
                type="text"
              />
            </div>
            <div className="ms-5 pe-4 pe-md-0" style={{ maxWidth: "25rem" }}>
              <table className="">
                <colgroup>
                  <col style={{ width: "43%" }} />
                  <col style={{ width: "42%" }} />
                  <col style={{ width: "45%" }} />
                </colgroup>
                <thead>
                  <tr className=" ">
                    <th className="color_707070 fw_400 font_poppins">
                      Permission
                    </th>
                    <th className="color_030304 fw_400 text-center font_poppins">
                      Read
                    </th>
                    <th className="color_030304 fw_400 text-center font_poppins">
                      Write
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(initialValues.permission).map(
                    ([key, value]) => {
                      console.log(key, "key");
                      return (
                        <tr key={key}>
                          <td
                            className={`${
                              values.permission[key].write ||
                              values.permission[key].read
                                ? "color_030304"
                                : "color_9E9E9E"
                            }  fw_400 font_poppins pt-2`}
                          >
                            {key ? removeUnderScore(key) : "--"}
                          </td>
                          <td className="text-center pt-2 ">
                            {values.permission[key].write ? (
                              <Field
                                style={{ width: "15px", height: "15px" }}
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckCheckedDisabled"
                                checked={`permission.${key}.read`}
                                disabled
                              />
                            ) : (
                              <Field
                                style={{ width: "15px", height: "15px" }}
                                className="form-check-input"
                                type="checkbox"
                                name={`permission.${key}.read`}
                                onChange={handleCheckboxChange(
                                  key,
                                  "read",
                                  setFieldValue
                                )}
                              />
                            )}
                          </td>
                          <td className="text-center pt-2 checkbox-wrapper">
                            <Field
                              style={{ width: "15px", height: "15px" }}
                              className="checkbox-wrapper form-check-input"
                              type="checkbox"
                              name={`permission.${key}.write`}
                              onChange={handleCheckboxChange(
                                key,
                                "write",
                                setFieldValue
                              )}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              <ErrorMsg
                name="permission"
                textClass="color_F24444 fs_16 d-block"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AdminRolesEdit;
