import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import ImageUpload from "../../components/ui/forms/image-upload";
import { useLocation, useNavigate, useParams } from "react-router";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../components/ui/forms/input-field";
import SelectField from "../../components/ui/forms/select-field";
import RadioButton from "../../components/ui/forms/radio-button";
import ErrorMsg from "../../components/ui/forms/error-msg";
import {
  categoriesDropdownAction,
  categoriesEditAction,
  categoriesImgUploadAction,
  categoriesViewAction,
} from "../../redux/actions/categories-action";
import { useDispatch } from "react-redux";
import QuillField from "../../components/ui/forms/quill-field";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import {
  NavigateEditFn,
  navigateEditFn,
} from "../../components/helper-functions/helper/helper";
// import { Route, useRoutes } from "react-router";
import isEqual from "lodash.isequal";
import Compressor from "compressorjs";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import { brandsDropDownListAction } from "../../redux/actions/brands-action";
import NotificationComponent from "../../components/notificationComponent";
const CategoriesEdit = () => {
  const router = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = router;
  const currenPath = pathname.startsWith("/categories/edit");
  const [categoryDetails, setCategoryDetails] = useState();
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [imgUrl, setImageUrl] = useState("");
  const [imageSize, setImageSize] = useState(false);
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  useEffect(() => {
    dispatch(categoriesViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setCategoryDetails(data?.data);
  };
  const onError = (data) => {};
  useEffect(() => {
    setImageUrl(categoryDetails?.category_image);
  }, [categoryDetails]);

  useEffect(() => {
    dispatch(categoriesDropdownAction(OnFetchSuccess, onFetchError));
    dispatch(brandsDropDownListAction(OnBrandSuccess, onBrandError));
  }, []);

  const OnFetchSuccess = (data) => {
    setLoading(false);
    setParentCategoryList(
      data?.data
        ?.filter((item) => item?.is_parent_category)
        .map((val) => ({ label: val?.category_name, value: val?.id }))
    );
  };
  const onFetchError = () => {
    setLoading(false);
    console.log("failed to load parent category list");
  };
  const OnBrandSuccess = (data) => {
    setBrandList(
      data?.data.map((val) => ({ label: val?.name, value: val?.id }))
    );
  };
  const onBrandError = () => {
    console.log("failed to load parent category list");
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  const initialValues = {
    category_name: categoryDetails?.category_name ?? "",
    Slug: categoryDetails?.slug ?? "",
    isparent: categoryDetails?.is_parent_category ?? "",
    description: categoryDetails?.description ?? "",
    parent_category_id: categoryDetails?.parent_category_id ?? null,
    parent_category: "",
    brand_id:
      brandList?.filter((item) =>
        categoryDetails?.brand_id?.includes(item?.value)
      ) ?? [],
  };
  const validationSchema = Yup.object().shape({
    category_name: Yup.string().trim().required("Category Name is required"),
    Slug: Yup.string().trim().nullable(),
    parent_category_id: Yup.string().trim().nullable(),
    description: Yup.string().trim(),
  });
  const onSubmit = (values, action) => {
    setLoading(true);
    dispatch(
      categoriesEditAction(
        {
          category_id: params.id,
          category_image: imgUrl,
          category_name: values?.category_name,
          description: values?.description,
          slug: values?.Slug,
          is_parent_category: values?.isparent,
          parent_category_id: values?.parent_category_id,
          brand_id: values?.brand_id.map((item) => item?.value),
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };
  const onSuccessEdit = (data) => {
    setLoading(false);
    successToast(data.message);
    if (currentPage) {
      navigate("/categories", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onErrorEdit = (data) => {
    errorToast(data.data.message);
  };
  function resetFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setImageUrl(categoryDetails?.category_image);
    }
  }
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual && categoryDetails?.category_image === imgUrl) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => {
          console.log(values, "categories");
          return (
            <Form className="">
              <InnerLayout
                name={categoryDetails?.code}
                loading={loading}
                page="edit"
                edit={true}
                onSubmit={onSubmit}
                resetFn={resetFn}
                rolePermission={"categories"}
                path={"/categories"}
                disabled={valuesCompareFn(values)}
              />
              {loading ? (
                <LoadingSpinnerTable />
              ) : (
                <>
                  <span>
                    <ImageUpload
                      name="category_image"
                      getImgUrl={getImgUrl}
                      resetFn={resetFn}
                      data={imgUrl}
                      imgLoading={imgLoading}
                      setImgLoading={setImgLoading}
                    />
                    {imageSize && (
                      <label className="fs_14 ms-5 text-danger mb-3">
                        "Please upload below 10mb"
                      </label>
                    )}
                  </span>

                  <div
                    className="ms-md-5 ms-4 pe-4 pe-md-0"
                    style={{ maxWidth: "40rem" }}
                  >
                    <InputField
                      placeholder="Enter category name"
                      containerClass="mb-3 position-relative"
                      errMsgContainerClass="ps-2"
                      label="Category Name"
                      name="category_name"
                      type="text"
                    />
                    <QuillField name="description" label="Description" />
                    <div>
                      <InputField
                        placeholder="Enter slug text"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Slug"
                        name="Slug"
                        type="text"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2 mb-4">
                      <Field
                        className="form-check-input mb-1"
                        style={{ width: "15px", height: "15px" }}
                        name="isparent"
                        type="checkbox"
                      />
                      <label className="fw_500 fs_14">Is parent Category</label>
                    </div>
                    <div>
                      <SelectField
                        defaultValue={parentCategoryList.find((item) => {
                          return item.value === values.parent_category_id;
                        })}
                        placeholder={"Select discount type"}
                        options={parentCategoryList.filter(
                          (item) => item?.value != categoryDetails?.id
                        )}
                        label={"Parent Category"}
                        isFormikControlled={true}
                        name="parent_category_id"
                        errMsgContainerClass="ps-2"
                      />
                    </div>
                    {localStorage.getItem("brand") === "true" && (
                      <div>
                        <SelectField
                          isMulti={true}
                          placeholder={"Select brands"}
                          options={brandList}
                          label={"Brands"}
                          isFormikControlled={true}
                          name="brand_id"
                          errMsgContainerClass="ps-2"
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CategoriesEdit;
