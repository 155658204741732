import React, { useEffect, useState } from 'react';
import EditIcon from "../../../assets/icons/editIcon";
import AddressIcon from "../../../assets/icons/addressicon";
import SelectOrderStatus from './order-status-select';
import { paymentStatus, returnpaymentStatus } from '../../helper-functions/helper/data';
import EditRefaundDetails from './models/edit-refaund-details';

function RefundDetails({ data, orderDetails, id, reload, setReload, canWrite }) {
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        if (orderDetails) {
            setSelectedOption(returnpaymentStatus?.find(item => item?.value?.includes(orderDetails?.refund_status)) || []);
        }
    }, [orderDetails]);

    const [refaundShow, setRefaundShow] = useState(false)
    return (
        <>
            <EditRefaundDetails
                reload={reload}
                setReload={setReload}
                id={id}
                data={data}
                orderDetails={orderDetails}
                show={refaundShow}
                modalClose={() => setRefaundShow(false)}
            />
            <div className="row p-3 mt-lg-0 ">
                <div className="col-xl-6">
                    <div className=' mt-md-0 mt-3 mb-3'>
                        <div className="d-flex gap-2 align-items-center">
                            <label className="fs_16 font_montserrat fw_600 ">
                                Refund Details
                            </label>
                            {canWrite("orders") && orderDetails?.refund_bank_account_details && <button
                                onClick={() => setRefaundShow(true)}
                                className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1">
                                <EditIcon /> <span>Edit</span>
                            </button>}
                        </div>
                        <div>
                            <label className="color_707070 fs_14">Refund ID</label>
                            <div className="color_030304 mb-2 fs_14 fw_500">
                                {data?.id ?? "--"}
                            </div>
                            <label className="color_707070 fs_14">Refundable Amount</label>
                            <div className="color_030304 mb-4 fs_14 fw_500">
                            {localStorage?.getItem("currency") + " " ?? "₹ "} {orderDetails?.refund_on_complete_reject ?? orderDetails?.rejectedOrders?.total_refund}
                            </div>
                            <div className="color_030304 mb-1 fs_14 fw_400">
                                Account Details
                            </div>
                            <div className='d-flex align-items-center gap-4'>
                                <div className='d-flex flex-column '>
                                    <label className="color_707070 fs_14">Acc Holder Name</label>
                                    <label className="color_707070 fs_14 mt-2">Acc. No</label>
                                    <label className="color_707070 fs_14 mt-2">IFSC Code</label>
                                </div>
                                <div className='d-flex flex-column '>
                                    <label className="color_030304 fw_500 fs_14">{data?.acc_holder_name ?? "--"}</label>
                                    <label className="color_030304 fw_500 fs_14 mt-2">{data?.acc_no ?? "--"}</label>
                                    <label className="color_030304 fw_500 fs_14 mt-2">{data?.ifsc ?? "--"}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SelectOrderStatus isDisabled={true} label={"Refund Status"} options={returnpaymentStatus} selectClass={'col-12'} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                </div>
                <div className="col-xl-6">

                </div>
            </div>

        </>
    )
}
export default RefundDetails