import { call, put, takeLatest } from "redux-saga/effects";
import {
  configPageCreateApi,
  configPageDeleteApi,
  configPageEditApi,
  configPageListApi,
  configPageViewApi,
  paymentsCreateApi,
  paymentsEditApi,
  paymentsViewApi,
  siteCreateApi,
  siteEditApi,
  siteViewApi,
  taxCreateApi, taxEditApi, taxViewApi,
  mailNotifyCreateApi, mailNotifyEditApi, mailNotifyViewApi, shippingCreateApi, shippingViewApi, contactInfoCreateUpdateApi, homeSettingsCreateApi, homeSettingsViewApi, contactInfoViewApi, siteViewWithoutAccessApi,
} from "../apis/configuration-api";
import {
  CONFIG_PAGE_CREATE,
  CONFIG_PAGE_DELETE,
  CONFIG_PAGE_EDIT,
  CONFIG_PAGE_LIST,
  CONFIG_PAGE_VIEW,
  TAX_CREATE, TAX_EDIT, TAX_VIEW,
  SITE_CREATE,
  SITE_EDIT,
  SITE_VIEW,
  PAYMENTS_CREATE,
  PAYMENTS_EDIT,
  PAYMENTS_VIEW,
  MAIL_NOTIFY_CREATE, MAIL_NOTIFY_EDIT, MAIL_NOTIFY_VIEW, SHIPPING_CREATE, SHIPPING_VIEW, CONTACT_INFO_CREATE_UPDATE, HOME_SETTINGS_CREATE, HOME_SETTINGS_VIEW, CONTACT_INFO_VIEW, SITE_SETTING_DATA_LOADED, SITE_SETTING_DATA_FAILED, SITE_VIEW_WITHOUT_ACCESS,
} from "../types";
/***TAX***/
export function* taxCreateWorker(action) {
  try {
    const res = yield call(taxCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* taxViewWorker(action) {
  console.log(action.data, "taxcreate07");
  try {
    const res = yield call(taxViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


/***MAIL NOTIFICATION***/
export function* mailNotifyCreateWorker(action) {
  try {
    const res = yield call(mailNotifyCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* mailNotifyViewWorker(action) {
  try {
    const res = yield call(mailNotifyViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/*** Site Settings Start***/
export function* siteCreateWorker(action) {
  try {
    const res = yield call(siteCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* siteViewWorker(action) {
  try {
    const res = yield call(siteViewApi);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }

  // try {
  //   const res = yield call(siteViewApi);
  //   console.log(res, "erteyerery");
  //   if (res.status === 200) {
  //     yield put({ type: SITE_SETTING_DATA_LOADED, data: res?.data?.data });
  //   } else {
  //     yield put({
  //       type: SITE_SETTING_DATA_FAILED,
  //       data: res?.data,
  //     });
  //   }
  // } catch (error) {
  //   yield put({
  //     type: SITE_SETTING_DATA_FAILED,
  //     data: error?.response,
  //   });
  // }
}
export function* siteViewWithoutAccessWorker(action) {
  try {
    const res = yield call(siteViewWithoutAccessApi);
    if (res.status === 200) {
      yield put({ type: SITE_SETTING_DATA_LOADED, data: res?.data?.data });
    } else {
      yield put({
        type: SITE_SETTING_DATA_FAILED,
        data: res?.data,
      });
    }
  } catch (error) {
    yield put({
      type: SITE_SETTING_DATA_FAILED,
      data: error?.response,
    });
  }
  // try {
  //   const res = yield call(siteViewWithoutAccessApi);
  //   if (res.status === 200) {
  //     yield action.onSuccess(res?.data);
  //   } else {
  //     yield action.onError(res?.data);
  //   }
  // } catch (error) {
  //   yield action.onError(error?.response);
  // }
}

/*** Site Settings End***/

export function* configPageCreateWorker(action) {
  try {
    const res = yield call(configPageCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* configPageEditWorker(action) {
  try {
    const res = yield call(configPageEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* configPageDeleteWorker(action) {
  try {
    const res = yield call(configPageDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* ConfigPageViewWorker(action) {
  try {
    const res = yield call(configPageViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* configPageListWorker(action) {
  try {
    const res = yield call(configPageListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* paymentsCreateWorker(action) {
  try {
    const res = yield call(paymentsCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* paymentsViewWorker(action) {
  try {
    const res = yield call(paymentsViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* paymentsEditWorker(action) {
  try {
    const res = yield call(paymentsEditApi, action.data);
    console.log(res.status === 200);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* contactInfoCreateUpdateWorker(action) {
  try {
    const res = yield call(contactInfoCreateUpdateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* contactInfoViewWorker(action) {
  try {
    const res = yield call(contactInfoViewApi, action);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchTaxCreateWorker() {
  yield takeLatest(TAX_CREATE, taxCreateWorker);
}
export function* watchTaxViewWorker() {
  yield takeLatest(TAX_VIEW, taxViewWorker);
}


/*SHIPPING*/
export function* shippingCreateWorker(action) {
  try {
    const res = yield call(shippingCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* shippingViewWorker(action) {
  try {
    const res = yield call(shippingViewApi, action);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/*HOME SETTINGS*/
export function* homeSettingsCreateWorker(action) {
  try {
    const res = yield call(homeSettingsCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* homeSettingsViewWorker(action) {
  try {
    const res = yield call(homeSettingsViewApi, action);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


/***MAIL NOTIFICATION***/
export function* watchMailNotifyCreateWorker() {
  yield takeLatest(MAIL_NOTIFY_CREATE, mailNotifyCreateWorker);
}
export function* watchMailNotifyViewWorker() {
  yield takeLatest(MAIL_NOTIFY_VIEW, mailNotifyViewWorker);
}


export function* watchConfigPageCreateWorker() {
  yield takeLatest(CONFIG_PAGE_CREATE, configPageCreateWorker);
}
export function* watchConfigPageEditWorker() {
  yield takeLatest(CONFIG_PAGE_EDIT, configPageEditWorker);
}
export function* watchConfigPageDeleteWorker() {
  yield takeLatest(CONFIG_PAGE_DELETE, configPageDeleteWorker);
}
export function* watchConfigPageViewWorker() {
  yield takeLatest(CONFIG_PAGE_VIEW, ConfigPageViewWorker);
}
export function* watchConfigPageListWorker() {
  yield takeLatest(CONFIG_PAGE_LIST, configPageListWorker);
}

export function* watchSiteCreateWorker() {
  yield takeLatest(SITE_CREATE, siteCreateWorker);
}
export function* watchSiteViewWorker() {
  yield takeLatest(SITE_VIEW, siteViewWorker);
}
export function* watchSiteViewWithoutAccessWorker() {
  yield takeLatest(SITE_VIEW_WITHOUT_ACCESS, siteViewWithoutAccessWorker);
}

export function* watchpaymentsCreateWorker() {
  yield takeLatest(PAYMENTS_CREATE, paymentsCreateWorker);
}
export function* watchpaymentsViewWorker() {
  yield takeLatest(PAYMENTS_VIEW, paymentsViewWorker);
}
export function* watchpaymentsEditworker() {
  yield takeLatest(PAYMENTS_EDIT, paymentsEditWorker);
}

export function* watchshippingCreateWorker() {
  yield takeLatest(SHIPPING_CREATE, shippingCreateWorker);
}
export function* watchshippingViewWorker() {
  yield takeLatest(SHIPPING_VIEW, shippingViewWorker);
}
export function* watchContactInfoCreateUpdateWorker() {
  yield takeLatest(CONTACT_INFO_CREATE_UPDATE, contactInfoCreateUpdateWorker);
}

export function* watchContactInfoViewWorker() {
  yield takeLatest(CONTACT_INFO_VIEW, contactInfoViewWorker);
}

export function* watchHomeSettingsCreateWorker() {
  yield takeLatest(HOME_SETTINGS_CREATE, homeSettingsCreateWorker);
}
export function* watchHomeSettingsViewWorker() {
  yield takeLatest(HOME_SETTINGS_VIEW, homeSettingsViewWorker);
}