import { call, takeLatest } from "redux-saga/effects";
import {
  customerBlockApi,
  customerCreateApi,
  customerDeleteApi,
  customerEditApi,
  customerMultiDeleteApi,
  customerResetPswApi,
  customerSendPasswordApi,
  customerTableApi,
  customerViewApi,
} from "../apis/customer-api";
import {
  CUSTOMER_BLOCK,
  CUSTOMER_CREATE,
  CUSTOMER_DELETE,
  CUSTOMER_EDIT,
  CUSTOMER_MULTI_DELETE,
  CUSTOMER_RESETPSW,
  CUSTOMER_SEND_PASSWORD,
  CUSTOMER_TABLE,
  CUSTOMER_VIEW,
} from "../types";

export function* customerTableWorker(action) {
  console.log(action);
  try {
    const res = yield call(customerTableApi, action.data, action?.data1);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerCreateWorker(action) {
  try {
    const res = yield call(customerCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerViewWorker(action) {
  try {
    const res = yield call(customerViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerEditWorker(action) {
  try {
    const res = yield call(customerEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerDeleteWorker(action) {
  try {
    const res = yield call(customerDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* customerMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(customerMultiDeleteApi, { data: action.data });
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* customerBlockWorker(action) {
  try {
    const res = yield call(customerBlockApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* customerResetPswWorker(action) {
  try {
    const res = yield call(customerResetPswApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerSendPasswordWorker(action) {
  try {
    const res = yield call(customerSendPasswordApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* watchCustomerTableWorker() {
  yield takeLatest(CUSTOMER_TABLE, customerTableWorker);
}
export function* watchCustomerCreateWorker() {
  yield takeLatest(CUSTOMER_CREATE, customerCreateWorker);
}
export function* watchCustomerViewWorker() {
  yield takeLatest(CUSTOMER_VIEW, customerViewWorker);
}
export function* watchCustomerEditWorker() {
  yield takeLatest(CUSTOMER_EDIT, customerEditWorker);
}
export function* watchCustomerDeleteWorker() {
  yield takeLatest(CUSTOMER_DELETE, customerDeleteWorker);
}
export function* watchCustomerMultiDeleteWorker() {
  yield takeLatest(CUSTOMER_MULTI_DELETE, customerMultiDeleteWorker);
}
export function* watchCustomerBlockWorker() {
  yield takeLatest(CUSTOMER_BLOCK, customerBlockWorker);
}
export function* watchCustomerResetPswWorker() {
  yield takeLatest(CUSTOMER_RESETPSW, customerResetPswWorker);
}
export function* watchCustomerSendPasswordWorker() {
  yield takeLatest(CUSTOMER_SEND_PASSWORD, customerSendPasswordWorker);
}
