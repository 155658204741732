import { call, cancel, fork, race, take, takeEvery, takeLatest } from "redux-saga/effects";
import {
  faqEditApi,
  faqDeleteApi,
  faqMultiDeleteApi,
  faqTableApi,
  faqViewApi,
  faqCreateApi,
} from "../apis/faq-api";
import {
  FAQ_EDIT,
  FAQ_DELETE,
  FAQ_MULTI_DELETE,
  FAQ_TABLE,
  FAQ_VIEW,
  FAQ_CREATE,

} from "../types";

export function* faqTableWorker(action) {
  try {
    const res = yield call(faqTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* faqViewWorker(action) {
  try {
    const res = yield call(faqViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* faqDeleteWorker(action) {
  try {
    const res = yield call(faqDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* faqMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(faqMultiDeleteApi, { data: action.data });
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error);
  }
}

export function* faqCreateWorker(action) {
  try {
    const res = yield call(faqCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* faqEditWorker(action) {
  try {
    const res = yield call(faqEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}



export function* watchfaqTableWorker() {
  yield takeLatest(FAQ_TABLE, faqTableWorker);
}
export function* watchfaqViewWorker() {
  yield takeLatest(FAQ_VIEW, faqViewWorker);
}
export function* watchfaqEditWorker() {
  yield takeLatest(FAQ_EDIT, faqEditWorker);
}
export function* watchfaqDeleteWorker() {
  yield takeLatest(FAQ_DELETE, faqDeleteWorker);
}
export function* watchfaqMultiDeleteWorker() {
  yield takeLatest(FAQ_MULTI_DELETE, faqMultiDeleteWorker);
}
export function* watchfaqCreateWorker() {
  yield takeLatest(FAQ_CREATE, faqCreateWorker);
}














