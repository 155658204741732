import React from 'react'
import Lottie from "lottie-react";
import animationData from "../../../assets/images/shopping-cart.json";

const LoadingTable = () => {
    return (
        <Lottie
            animationData={animationData}
            autoplay
            loop
            speed={0.5}
            style={{
                width: "75px",
                height: "75px",
            }}
        />
    )
}

export default LoadingTable