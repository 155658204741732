import {
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SITE_SETTINGS,
  STORE_ACTIVE,
} from "../types";

export const adminLoginAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_LOGIN,
    data,
    onSuccess,
    onError,
  };
};
export const adminLogoutAction = (onSuccess, onError) => {
  return {
    type: ADMIN_LOGOUT,
    onSuccess,
    onError,
  };
};
export const forgotPasswordAction = (data, onSuccess, onError) => {
  return {
    type: FORGOT_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};
export const resetPasswordAction = (data, onSuccess, onError) => {
  return {
    type: RESET_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};

export const siteSettingsAction = (data) => {
  return {
    type: SITE_SETTINGS,
    data: data,
  };
};

export const activeStoreAction = (onSuccess, onError) => {
  return {
    type: STORE_ACTIVE,
    onSuccess,
    onError,
  };
};
