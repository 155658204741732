import { all } from "redux-saga/effects";
import {
    watchCategorieDropdownWorker,
    watchCategorieImgUploadWithCancel,
    watchCategorieImgUploadWorker,
    watchCategoriesEditWorker,
    watchConfigurationImgUploadWorker,
    watchcategorieCreateWorker,
    watchcategoriesDeleteWorker,
    watchcategoriesMultiDeleteWorker,
    watchcategoriesTableWorker,
    watchcategoriesViewWorker
} from "../categories-saga";
export default function* categoriesRootSaga() {
    yield all([
        watchcategoriesTableWorker(),
        watchcategoriesDeleteWorker(),
        watchcategoriesMultiDeleteWorker(),
        watchcategoriesViewWorker(),
        watchCategoriesEditWorker(),
        watchcategorieCreateWorker(),
        watchCategorieImgUploadWithCancel(),
        // watchCategorieImgUploadWorker(),
       
        watchCategorieDropdownWorker(),
    ]);
}
