import React from "react";
import ReturnIcon from "../../../assets/icons/return-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import FilterIcon from "../../../assets/icons/filter-icon.svg";
import SaveIcon from "../../../assets/icons/save-icon.svg";
import SettingsIcon from "../../../assets/icons/settings-icon.svg";
import PlusIcon from "../../../assets/icons/plus-icon";
import DeleteIcon from "../../../assets/icons/delete-icon";
import LocationIcon from "../../../assets/icons/location-icon";
import "./buttons.css";
import LoadingSpinner from "../../helper-functions/helper/loadingSpinner";
import BlockIcon from "../../../assets/icons/block-icon";
import UnblockIcon from "../../../assets/icons/unblock-icon";
import GeneratePasswordIcon from "../../../assets/icons/generate-password-icon";
import LinkIcon from "../../../assets/icons/link-icon";

const CreateButton = ({
  name = "CREATE",
  type = "button",
  createClassName = "fw_500",
  createFn,
  loading = false,
  textContainerClass = "d-flex align-items-center",
  disabled = false,
  icon = true,
  textClass = "ms-2",
}) => {
  return (
    <button
      onClick={createFn}
      type={type}
      style={{ backgroundColor: "#2820DA", width: "fit-content" }}
      className={`px-3 rounded-2 border-0 py-2 ${createClassName} text-white   all_buttons`}
      disabled={disabled}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={{ textContainerClass }}>
          <span>
            {icon &&
              (name.includes("VERIFY") ? (
                <LinkIcon fill="white" width="25" height="25" />
              ) : (
                <PlusIcon width="13" fill="white" />
              ))}
          </span>
          <span className={`${textClass}`}>{name}</span>
        </div>
      )}
    </button>
  );
};

export default CreateButton;

export const AddButton = ({
  name = "ADD PRODUCTS",
  type = "button",
  addClassName = "",
  loading = false,
  AddFn,
  addSymbol = true,
}) => {
  return (
    <button
      onClick={AddFn}
      type={type}
      style={{
        backgroundColor: "#E5ECFF80",
        width: "fit-content",
        color: "#2820DA",
      }}
      className={`px-3 rounded-2 border-0 ${addClassName} fw_500 py-2 fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center fs_14`}>
          {addSymbol ? (
            <PlusIcon width="15" fill="#2820DA" />
          ) : (
            <GeneratePasswordIcon />
          )}
          <span className={"ms-2"}>{name}</span>
        </span>
      )}
    </button>
  );
};

export const AddVariantButton = ({
  name = "Add",
  type = "button",
  addClassName = "",
  loading = false,
  onClick,
}) => {
  return (
    <button
      onClick={onClick && onClick}
      type={type}
      style={{
        backgroundColor: "#E5ECFF",
        width: "fit-content",
        color: "#030304",
      }}
      className={`px-4 rounded-2 border-0 ${addClassName} py-2 fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center fs_14 fw_500`}>
          <PlusIcon width="12" fill="#030304" />
          <span className={`ms-2`}>{name}</span>
        </span>
      )}
    </button>
  );
};

export const AddWithBorderButton = ({
  name = "ADD",
  border = "2px solid #2820DA",
  addClassName = "rounded-2 py-2 px-3",
  type = "button",
  loading = false,
  customStyles = {},
  onClick,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick && onClick}
      type={type}
      style={{
        backgroundColor: "rgba(230, 236, 255, 0.5);",
        width: "fit-content",
        color: "#2820DA",
        border: border,
        ...customStyles,
      }}
      className={`fw_500 all_buttons ${addClassName}`}
      disabled={disabled}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={`d-flex align-items-center `}>
          <span>
            {name.includes("VERIFY") ? (
              <LinkIcon width="25" height="25" />
            ) : (
              <PlusIcon width="15" fill="#2820DA" />
            )}
          </span>
          <span className={`ms-2 fs_14 mt-1`}>{name}</span>
        </div>
      )}
    </button>
  );
};

export const BlueAddButton = ({
  name = "ADD PRODUCTS",
  addClassName = "",
  type = "button",
  loading = false,
}) => {
  return (
    <button
      type={type}
      style={{
        backgroundColor: "#2820DA",
        width: "fit-content",
      }}
      className={`px-3 rounded-2 border-0 ${addClassName} py-2 fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center fs_14 text-white`}>
          <PlusIcon width="15" />
          <span className={`ms-2`}>{name}</span>
        </span>
      )}
    </button>
  );
};

export const BackButton = ({
  name = "BACK",
  backClassName = "",
  type = "button",
  loading = false,
  disabled = false,
  undoFn,
}) => {
  return (
    <button
      onClick={undoFn}
      disabled={disabled}
      type={type}
      style={{ backgroundColor: "#E5ECFF", width: "fit-content" }}
      className={`px-3 rounded-2 border-0 py-2 ${backClassName} text-black fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center fs_14`}>
          <img src={ReturnIcon} className={`me-2`} alt="plus-icon" width={15} />{" "}
          {name}
        </span>
      )}
    </button>
  );
};

export const CancelButton = ({
  name = "CANCEL",
  cancelClassName = "px-4",
  type = "button",
  loading = false,
  bgColor = "#E5ECFF",
  textColor = "color_030304",
  isDisabled = false,
}) => {
  return (
    <button
      disabled={isDisabled}
      type={type}
      style={{ backgroundColor: `${bgColor}`, width: "fit-content" }}
      className={`rounded-2 border-0 py-2 ${cancelClassName} text-black fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center px-2 ${textColor}`}>
          {name}
        </span>
      )}
    </button>
  );
};

export const DeleteButton = ({
  name = "DELETE",
  page = "red_bg",
  icon = true,
  deleteClassName = "fw_500 px-4",
  type = "button",
  deleteFn,
  loading = false,
  block = false,
  disabled = false,
}) => {
  return (
    <>
      {page === "red_bg" ? (
        <button
          disabled={disabled}
          onClick={deleteFn}
          type={type}
          style={{
            backgroundColor: "#F24444",
            width: "fit-content",
          }}
          className={
            icon === true
              ? `px-3 rounded-2 border-0 ${deleteClassName} text-white py-2  all_buttons`
              : ` rounded-2 border-0 ${deleteClassName} text-white py-2  all_buttons`
          }
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <span className={`d-flex align-items-center`}>
              {icon === true && <DeleteIcon width="15" height="15" />}{" "}
              <span className={icon === false ? `px-2` : `ms-1`}>{name}</span>
            </span>
          )}
        </button>
      ) : (
        <button
          onClick={deleteFn}
          type={type}
          style={{
            backgroundColor: "rgba(242, 68, 68, 0.1)",
            width: "fit-content",
            color: "#F24444",
          }}
          className={`px-3 rounded-2 border-0 ${deleteClassName} py-2 fw_500 all_buttons`}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <span className={`d-flex align-items-center`}>
              {block ? (
                <BlockIcon fill="#F24444" width="13" height="15" />
              ) : (
                <DeleteIcon fill="#F24444" width="15" height="17" />
              )}
              <span className={`ms-1`}></span>
              {name}
            </span>
          )}
        </button>
      )}
    </>
  );
};

export const EditButton = ({
  name = "EDIT",
  editClassName = "py-2 px-3",
  type = "button",
  loading = false,
  onClick,
}) => {
  return (
    <button
      onClick={onClick && onClick}
      type={type}
      style={{ backgroundColor: "#2820DA", width: "fit-content" }}
      className={` rounded-2 border-0   ${editClassName} text-white fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center fs_14`}>
          <img src={EditIcon} className={`me-2`} alt="plus-icon" width={15} />{" "}
          {name}
        </span>
      )}
    </button>
  );
};

export const FilterButton = ({
  name = "FILTER",
  filterClassName = "",
  type = "button",
  filterFn,
}) => {
  return (
    <button
      onClick={filterFn}
      type={type}
      style={{ backgroundColor: "#E5ECFF", width: "fit-content" }}
      className={`px-3 rounded-2 border-0 py-2 ${filterClassName} text-black fw_500`}
    >
      <span className="d-flex align-items-center fs_14">
        <img src={FilterIcon} className="me-2" alt="plus-icon" width={15} />{" "}
        {name}
      </span>
    </button>
  );
};

export const SaveButton = ({
  name = "SAVE",
  type = "button",
  saveClassName = "px-3",
  loading = false,
  disabled = false,
  onClick,
  icon = SaveIcon,
  iconWidth = 15,
}) => {
  return (
    <button
      onClick={onClick && onClick}
      disabled={disabled}
      type={type}
      style={{ backgroundColor: "#2820DA", width: "fit-content" }}
      className={`rounded-2 border-0 py-2 ${saveClassName} text-white fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className="d-flex align-items-center fs_14">
          <img src={icon} className="me-2" alt="plus-icon" width={iconWidth} />{" "}
          {name}
        </span>
      )}
    </button>
  );
};

export const ClearButton = ({
  name = "CLEAR",
  type = "button",
  clearClassName = "",
  loading = false,
  clearFn,
}) => {
  return (
    <button
      onClick={clearFn}
      type={type}
      style={{ backgroundColor: "#E5ECFF", width: "fit-content" }}
      className={` rounded-2 border-0 ${clearClassName} text-black  `}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center  px-2`}>{name}</span>
      )}
    </button>
  );
};

export const ApplyButton = ({
  name = "APPLY",
  type = "button",
  applyClassName = "px-2",
  loading = false,
  backgroundColor = "#2820DA",
  onClick = () => { },
  disabled = false
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      style={{ backgroundColor: `${backgroundColor}`, width: "fit-content" }}
      className={` rounded-2 border-0 py-2 ${applyClassName} text-white fw_500 all_buttons`}
      onClick={onClick}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          className={`d-flex justify-content-center align-items-center text-center px-2`}
        >
          {name}
        </div>
      )}
    </button>
  );
};

export const OkayButton = ({
  name = "OKAY",
  width = "fit-content",
  disabled = false,
  type = "button",
  customStyles = {},
  okayClassName = "fs_14",
  loading = false,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      style={{ backgroundColor: "#2820DA", width: width, ...customStyles }}
      className={`px-4 rounded-2 border-0 py-2 ${okayClassName} text-white fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span
          className={`d-flex align-items-center justify-content-center px-2`}
        >
          {name}
        </span>
      )}
    </button>
  );
};

export const ResetPasswordButton = ({
  name = "RESET PASSWORD",
  resetPassClassName = "",
  type = "button",
  loading = false,
}) => {
  return (
    <button
      type={type}
      style={{ backgroundColor: "#E5ECFF", width: "fit-content" }}
      className={`px-3 rounded-2 border-0 py-2 ${resetPassClassName} text-black fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center fs_14`}>
          <img
            src={SettingsIcon}
            className={`me-2`}
            alt="plus-icon"
            width={15}
          />{" "}
          {name}
        </span>
      )}
    </button>
  );
};

export const UnblockButton = ({
  name = "UNBLOCK",
  unblockClassName = "px-4",
  type = "button",
  loading = false,
  green_bg = false,
}) => {
  return (
    <>
      {green_bg ? (
        <button
          type={type}
          style={{ backgroundColor: "#3AD64C", width: "fit-content" }}
          className={`rounded-2 border-0 py-2 ${unblockClassName} text-white fw_500 all_buttons`}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="d-flex align-items-center">
              {name === "CONNECTED" && (
                <span className="me-1">
                  <LinkIcon fill="white" />
                </span>
              )}
              <span className="fs_14">{name}</span>
            </div>
          )}
        </button>
      ) : (
        <button
          type={type}
          style={{
            backgroundColor: "rgba(58, 214, 76, 0.1)",
            width: "fit-content",
          }}
          className={`px-4 rounded-2 border-0 py-2 color_3AD64C ${unblockClassName} fw_500 all_buttons`}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <span className={`d-flex align-items-center fs_14 gap-2`}>
              <UnblockIcon />
              {name}
            </span>
          )}
        </button>
      )}
    </>
  );
};

export const MarkAsDefaultButton = ({
  name = "MARK AS DEFAULT",
  markAsDefaultClassName = "",
  type = "button",
  loading = false,
  modelShow,
}) => {
  return (
    <button
      onClick={modelShow}
      type={type}
      style={{ backgroundColor: "#E5ECFF", width: "fit-content" }}
      className={`px-3 rounded-2 border-0 py-2 ${markAsDefaultClassName} text-black fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center fs_14`}>
          <LocationIcon width="13" />
          <span className={`ms-1`}>{name}</span>
        </span>
      )}
    </button>
  );
};

export const RejectButton = ({
  name = "REJECT ORDER",
  type = "button",
  clearClassName = "rounded-2 border_1px_F24444 color_F24444 fw_500",
  loading = false,
  rejectFn,
}) => {
  return (
    <button
      onClick={rejectFn}
      type={type}
      style={{ width: "fit-content", height: "2.5rem" }}
      className={` ${clearClassName}`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center  px-2`}>{name}</span>
      )}
    </button>
  );
};
export const AcceptButton = ({
  name = "ACCEPT ORDER",
  type = "button",
  clearClassName = "rounded-2 border-0 color_FFFFFF bg_color_3AD64C fw_500 ",
  loading = false,
  acceptFn,
}) => {
  return (
    <button
      onClick={acceptFn}
      type={type}
      style={{ width: "fit-content", height: "2.5rem" }}
      className={` ${clearClassName}`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className={`d-flex align-items-center  px-3 `}>{name}</span>
      )}
    </button>
  );
};

export const UpdateButton = ({
  name = "UPDATE",
  type = "button",
  saveClassName = "",
  loading = false,
  disabled = false,
  updateFn,
}) => {
  return (
    <button
      onClick={updateFn}
      disabled={disabled}
      type={type}
      style={{ backgroundColor: "#2820DA", width: "fit-content" }}
      className={`px-3 rounded-2 border-0 py-2 ${saveClassName} text-white fw_500 all_buttons`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <span className="d-flex align-items-center fs_14">
          <img src={SaveIcon} className="me-2" alt="plus-icon" width={15} />{" "}
          {name}
        </span>
      )}
    </button>
  );
};
