import { all } from "redux-saga/effects";
import {
  watchConfigPageCreateWorker,
  watchConfigPageDeleteWorker,
  watchConfigPageEditWorker,
  watchConfigPageListWorker,
  watchConfigPageViewWorker,
  watchSiteCreateWorker,
  watchSiteViewWorker,
  watchTaxCreateWorker,
  watchpaymentsCreateWorker,
  watchpaymentsViewWorker,
  watchTaxViewWorker,
  watchMailNotifyCreateWorker,
  watchMailNotifyViewWorker,
  watchpaymentsEditworker,
  watchshippingViewWorker,
  watchshippingCreateWorker,
  watchContactInfoCreateUpdateWorker,
  watchHomeSettingsCreateWorker,
  watchHomeSettingsViewWorker,
  watchContactInfoViewWorker,
  watchSiteViewWithoutAccessWorker,
} from "../configuration-saga";
import {
  watchInstalledPluginListWorker,
  watchPluginCreateWorker,
  watchPluginImageUploadWorker,
  watchPluginInstallWorker,
  watchPluginListWorker,
  watchPluginUninstallWorker,
  watchPluginUpdateWorker,
  watchPluginUploadWorker,
  watchThemeCreateWorker,
  watchThemeUninstallWorker,
  watchThemeUploadWorker,
  watchthemeImageUploadWorker,
  watchthemeInstallWorker,
  watchthemeListWorker,
} from "../plugin-saga";

export default function* configurationRootSaga() {
  yield all([
    watchTaxCreateWorker(),
    watchTaxViewWorker(),
    watchMailNotifyCreateWorker(),
    watchMailNotifyViewWorker(),
    watchSiteCreateWorker(),
    watchSiteViewWorker(),
    watchConfigPageCreateWorker(),
    watchConfigPageEditWorker(),
    watchConfigPageDeleteWorker(),
    watchConfigPageViewWorker(),
    watchConfigPageListWorker(),
    watchpaymentsCreateWorker(),
    watchpaymentsViewWorker(),
    watchpaymentsEditworker(),
    watchshippingViewWorker(),
    watchshippingCreateWorker(),
    watchContactInfoCreateUpdateWorker(),
    watchHomeSettingsCreateWorker(),
    watchHomeSettingsViewWorker(),
    watchContactInfoViewWorker(),
    watchSiteViewWithoutAccessWorker(),
    //plugins
    watchPluginUploadWorker(),
    watchPluginCreateWorker(),
    watchPluginUpdateWorker(),
    watchPluginUninstallWorker(),
    watchPluginInstallWorker(),
    watchPluginListWorker(),
    watchInstalledPluginListWorker(),
    watchPluginImageUploadWorker(),
    watchthemeImageUploadWorker(),
    watchThemeUploadWorker(),
    watchthemeListWorker(),
    watchThemeCreateWorker(),
    watchthemeInstallWorker(),
    watchThemeUninstallWorker(),
  ]);
}
