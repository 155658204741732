import {
  ADMIN_USERS_CREATE,
  ADMIN_USERS_DELETE,
  ADMIN_USERS_EDIT,
  ADMIN_USERS_UPLOAD_IMAGE,
  ADMIN_USERS_MULTI_DELETE,
  ADMIN_USERS_RESET_PASSWORD,
  ADMIN_USERS_TABLE,
  ADMIN_USERS_VIEW,
  // ADMIN_USERS_GET_IMAGE,
  ADMIN_USERS_SEND_PASSWORD,
  ADMIN_USER_DETAILS,
  LOGGED_ADMIN_USER_EDIT,
} from "../types";

export const adminUsersTableAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const adminUsersViewAction = (id, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const adminUsersEditAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const adminUsersCreateAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const adminUsersImageUploadAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_UPLOAD_IMAGE,
    data,
    onSuccess,
    onError,
  };
};
export const adminUsersResetPasswordAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_RESET_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};
export const adminUsersDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const adminUsersMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const adminUsersSendPasswordAction = (data, password, onSuccess, onError) => {
  return {
    type: ADMIN_USERS_SEND_PASSWORD,
    data,
    password,
    onSuccess,
    onError,
  };
};
//   export const adminUsersGetImageAction = (data, onSuccess, onError) => {
//     return {
//       type: ADMIN_USERS_GET_IMAGE,
//       data,
//       onSuccess,
//       onError,
//     };
export const adminUserDetailsAction = (onSuccess, onError) => {
  return {
    type: ADMIN_USER_DETAILS,
    onSuccess,
    onError,
  };
};
export const loggedAdminUsersEditAction = (data, onSuccess, onError) => {
  return {
    type: LOGGED_ADMIN_USER_EDIT,
    data,
    onSuccess,
    onError,
  };
};