import React, { useEffect, useState } from "react";
import "./table.css";
import backarrow from "../../../assets/icons/back-arrow-button.svg";
import CreateButton, {
  AddWithBorderButton,
  DeleteButton,
  FilterButton,
} from "../buttons/text-button";
import TableInputFilter from "./table-input-filter";
import { Link, NavLink, useNavigate } from "react-router-dom";
import TablePagination from "./table-pagination";
import { useDispatch } from "react-redux";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";
import DeleteModal from "../modals/delete-modal";
import { customerBlockAction } from "../../../redux/actions/customer-action";
import usePermissions from "../../helper-functions/hooks/usePermission-checker";
import useTableSorting from "../../helper-functions/hooks/use-table-sorting-hook";
import LoadingSpinnerTable from "../../helper-functions/helper/loadingSpinner-table";
import ActiveInactiveBtn from "./active-inactive-btn";
import { map } from "lodash";
import {
  SelecttextColorChanger,
  removeUnderScore,
  textColorChanger,
  truncateString,
} from "../../helper-functions/helper/helper";
import SortIcon from "../../../assets/icons/sort";
import UplaodImgLogo from "../../../assets/icons/Upload_image_logo.svg";
import { orderAllOptions } from "../../helper-functions/helper/data";
import LoadingTable from "../../helper-functions/helper/loading-spinner2";
import { deliveryBlockAction } from "../../../redux/actions/delivery-partner-action";

function DynamicTable({
  tabletitle = "Categories",
  tableHeading,
  tablebody,
  setInputValue,
  inputValue,
  createButtonName,
  createpath = "/categories/create",
  pagination,
  setCurrentPage,
  singleDeleteAction,
  multiDeleteAction,
  id_name,
  loading,
  setLoading,
  error,
  reload,
  setReload,
  placeholder,
  customer = false,
  filterFn,
  filtration = false,
  rolePermission = "",
  backBtnDisplay = true,
  modalText,
  isBorderLess = { border: true, error: false },
  variants = false,
  variantDeleteFn,
  varEditFn,
  setSortConfig = () => {},
  sortConfig,
  productsView = false,
  isMinHeight = true,
  fromPage = "",
  actionClass = "position-sticky",
  createButton = true,
  showTableTitle = true,
  faq = false,
  faqViewFn,
  faqEditFn = () => {},
}) {
  console.log(tablebody, "tablebodytablebody");
  const { canWrite } = usePermissions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState();
  const [show, setShow] = useState(false);
  const deleteCloseFn = () => setShow(false);
  const [checkedId, setCheckedId] = useState([]);
  function checkboxChecker(e, all) {
    if (all === "all" && checkedId.length === e.length) {
      setCheckedId([]);
    } else if (all === "all") {
      setCheckedId(e);
    } else {
      if (checkedId.includes(e?.target?.id)) {
        const checkedVAlues = [...checkedId];
        const findIndex = checkedVAlues.findIndex(
          (item) => item === e.target?.id
        );
        checkedVAlues.splice(findIndex, 1);
        setCheckedId(checkedVAlues);
      } else {
        setCheckedId((previous) => {
          return [...previous, e.target.id];
        });
      }
    }
  }
  const deleteFn = (data) => {
    setLoading(true);
    if (typeof data === "string") {
      dispatch(singleDeleteAction(data, onSuccess, onError));
    } else if (Array.isArray(data)) {
      dispatch(
        multiDeleteAction(
          {
            [id_name]: data,
          },
          onSuccess,
          onError
        )
      );
    } else if (typeof data === "object") {
      let actionType = customerBlockAction;
      if (customer != true) {
        actionType = deliveryBlockAction;
      }
      dispatch(actionType(data?.id, onSuccess, onError));
    }
  };
  const onSuccess = (data) => {
    successToast(data.message);
    setReload(!reload);
    setLoading(false);
    deleteCloseFn();
    setCheckedId([]);
  };
  const onError = (data) => {
    setReload(false);
    errorToast(data.data.message);
    deleteCloseFn();
    setLoading(false);
  };
  const { requestSort, sortData } = useTableSorting();

  function sortFn(item) {
    console.log(sortConfig != null && sortConfig?.sort_by == item);
    let direction = "ASC";
    if (
      sortConfig != null &&
      sortConfig?.sort_by == item &&
      sortConfig?.sort_order == "ASC"
    ) {
      direction = "DESC";
    }
    setSortConfig({ sort_by: item, sort_order: direction });
  }
  useEffect(() => {
    if (tablebody?.length === 0 && pagination?.currentPage > 1)
      setCurrentPage(pagination?.currentPage - 1);
  }, [tablebody]);

  function tableTypeFn() {
    if (
      id_name === "attribute_id" ||
      id_name === "customer_id" ||
      id_name === "address_id"
    )
      return true;
    else return false;
  }
  return (
    <>
      <DeleteModal
        text={`Are you sure, you want to ${
          deleteData?.type === "block"
            ? "block"
            : deleteData?.type === "Unblock"
            ? "Unblock"
            : "delete"
        } this ${modalText} permanently?`}
        show={show}
        modalClose={deleteCloseFn}
        deleteData={deleteData}
        deleteFn={() => deleteFn(deleteData)}
        type={
          deleteData?.type === "block"
            ? "block"
            : deleteData?.type === "Unblock"
            ? "Unblock"
            : "delete"
        }
      />
      {!variants && (
        <>
          <div className="d-flex align-items-center gap-3 mb-4 mt-4">
            {backBtnDisplay && (
              <img
                onClick={() => navigate(-1)}
                width={"30px"}
                src={backarrow}
                alt="back arrow"
              />
            )}
            <span className="fs_22 fw_500 font_montserrat color_030304 d-flex align-items-center">
              {showTableTitle && tabletitle}
              {backBtnDisplay && tablebody?.length > 0 && (
                <label
                  style={{ height: "25px" }}
                  className="color_F9F9FC bg_color_3AD64C rounded-5 px-3 ms-1 fs_14 fw_500 font_poppins d-flex align-items-center"
                >
                  {pagination?.total}
                </label>
              )}
            </span>
          </div>
          <div className="row mb-2 w-100 gx-0">
            <div
              className={` ${
                backBtnDisplay
                  ? "col-xl-5 col-md-8 col-lg-12"
                  : "col-xl-5 col-md-8 col-lg-12"
                // : "col-xl-8 col-md-8 col-lg-12 col-12"
              }`}
            >
              <div className="w-100">
                <TableInputFilter
                  placeholder={placeholder}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  checkedId={checkedId}
                  canWrite={canWrite}
                  rolePermission={rolePermission}
                />
              </div>
            </div>
            <div className="col-xl-7 col-12">
              <div className="d-flex justify-content-between mt-xl-0 mt-3 flex-sm-row flex-column mb-xl-0 mb-3">
                {filtration ? (
                  <FilterButton
                    filterClassName={
                      "px-lg-3 px-3 fs_14 fs_md_12 text-nowrap ms-xl-3"
                    }
                    filterFn={filterFn}
                  />
                ) : (
                  <div></div>
                )}
                <div
                  className={`${filtration && " mt-sm-0 mt-2"} d-flex gap-3`}
                >
                  {checkedId.length > 0 && canWrite(rolePermission) && (
                    <DeleteButton
                      deleteClassName={
                        "px-lg-3 px-3 fs_15 fs_md_12 fw_400 text-nowrap"
                      }
                      deleteFn={() => {
                        setDeleteData(checkedId);
                        setShow(true);
                      }}
                    />
                  )}
                  {canWrite(rolePermission) && createButton && (
                    <CreateButton
                      createFn={() => navigate(createpath)}
                      createClassName={
                        "px-lg-3 px-3 fs_15 fs_md_12 fw_400 text-nowrap"
                      }
                      name={createButtonName}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={`${
          isBorderLess?.error
            ? "table_main_container_error"
            : "table_main_container"
        } rounded-3  ${!isBorderLess?.border && "border-0"}`}
        style={{
          minHeight: isMinHeight ? "600px" : "200px",
        }}
      >
        <table className={"table_container h-100"}>
          <thead className="table_heading_container">
            <tr className="table_heading_row">
              {!variants && id_name != "order_id" && (
                <th
                  className={` position-sticky end-0 text-start  
                  text-nowrap table_heading_data`}
                >
                  <input
                    checked={
                      checkedId?.length ===
                        tablebody?.map((item) => item?.id).length &&
                      checkedId.length > 0
                    }
                    onChange={() =>
                      checkboxChecker(
                        tablebody.map((item) => item?.id),
                        "all"
                      )
                    }
                    style={{ transform: "scale(1.1)" }}
                    type="checkbox"
                    id="myCheckbox"
                    className="custom-checkbox form-check-input"
                  />
                </th>
              )}

              {tableHeading.map((head) => {
                return (
                  <th
                    key={head?.label}
                    className={` ${
                      head?.label === "Actions" && "position-sticky end-0"
                    } text-start 
                  text-nowrap table_heading_data color_707070 font_poppins`}
                  >
                    {head?.label === "Actions" && (
                      <div
                        className={` ${
                          (tabletitle === "Customers" &&
                            canWrite(rolePermission)) ||
                          tabletitle === "Delivery Partners"
                            ? "table_customer_Action_heading"
                            : canWrite(rolePermission) &&
                              rolePermission != "orders"
                            ? "table_Action_heading"
                            : "table_Action_heading_without_edit_delete"
                        }`}
                      >
                        {head?.label}
                      </div>
                    )}
                    {head?.label != "Actions" && (
                      <>
                        <label className={`${head?.label === "Actions" && ""}`}>
                          {head?.label}
                        </label>
                        {head?.label != "Thumbnail" &&
                          head?.value != "product_picture" &&
                          !variants &&
                          head?.value != "getItems" && (
                            <button
                              disabled={tablebody?.length > 0 ? false : true}
                              onClick={() => {
                                requestSort(head?.value);
                                sortFn(head?.value);
                              }}
                              className="bg-transparent border-0"
                            >
                              {head?.value === sortConfig?.sort_by &&
                              tablebody?.length > 0 ? (
                                <SortIcon
                                  height="18"
                                  width="18"
                                  inc={
                                    sortConfig?.sort_order == "ASC"
                                      ? "#444444"
                                      : "#C8C8C8"
                                  }
                                  dec={
                                    sortConfig?.sort_order == "DESC"
                                      ? "#444444"
                                      : "#C8C8C8"
                                  }
                                />
                              ) : (
                                <SortIcon
                                  height="18"
                                  width="18"
                                  inc="#C8C8C8"
                                  dec="#C8C8C8"
                                />
                              )}
                            </button>
                          )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="table_body_container h-100">
            {loading ? (
              <tr>
                <td colSpan="50" style={{ height: "500px" }}>
                  <div className="d-flex justify-content-center">
                    <LoadingTable />
                  </div>
                </td>
              </tr>
            ) : error ? (
              <tr className="table_body_row vh65">
                <td className="text-center" colSpan={tableHeading.length + 1}>
                  <div className="font_poppins fs_20 fw_500">
                    -- Failed to load Data --
                  </div>
                </td>
              </tr>
            ) : tablebody?.length > 0 ? (
              tablebody.map((item, index) => {
                const { id, Action, ...filteredItem } = item;
                return (
                  <tr
                    className={`table_body_row ${
                      checkedId.includes(item?.id)
                        ? "bg_table"
                        : item?.Actions?.[3]
                        ? "bg_color_09073A0D"
                        : "bg_color_ffffff"
                    }`}
                  >
                    {!variants && id_name != "order_id" && (
                      <td className="text-nowrap  table_body_data ">
                        <input
                          checked={checkedId.includes(item?.id)}
                          id={item?.id}
                          onChange={(e) => checkboxChecker(e)}
                          style={{ transform: "scale(1.2)" }}
                          type="checkbox"
                          className="custom-checkbox form-check-input"
                        />
                      </td>
                    )}
                    {Object.entries(tableTypeFn() ? item : filteredItem).map(
                      ([key, value], index) => {
                        if (index < Object.values(item)?.length - 1)
                          return (
                            <td
                              title={value}
                              key={index}
                              className={`text-nowrap color_030304  table_body_data fw_500`}
                            >
                              {item?.image === value ||
                              (item?.product_picture === value &&
                                value != undefined &&
                                (key === "product_picture" ||
                                  key === "image")) ? (
                                <>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="rounded-3"
                                    src={
                                      value === "product_picture"
                                        ? UplaodImgLogo
                                        : value === ""
                                        ? UplaodImgLogo
                                        : value ?? UplaodImgLogo
                                    }
                                    width={"80px"}
                                    height={"70px"}
                                  />
                                </>
                              ) : item?.status === value &&
                                value != undefined ? (
                                <>
                                  <ActiveInactiveBtn type={value} />
                                </>
                              ) : (item?.variant === value ||
                                  item?.orders_status === value ||
                                  item?.payment_status === value) &&
                                value != undefined ? (
                                <div className="d-flex gap-2">
                                  {value?.map((variant) => (
                                    <span
                                      className={`${
                                        rolePermission != "orders" &&
                                        "bg_color_E5ECFFB2 px-2 p-1"
                                      }  primary_color rounded-1`}
                                    >
                                      {variant?.value ?? (
                                        <span
                                          style={{
                                            backgroundColor: `${SelecttextColorChanger(
                                              variant
                                            )}10`,
                                          }}
                                          className={`${textColorChanger(
                                            variant
                                          )} px-2 p-1 rounded-1`}
                                        >
                                          {orderAllOptions?.find?.(
                                            (item) => item?.value === variant
                                          )?.label ?? removeUnderScore(variant)}
                                        </span>
                                      )}
                                    </span>
                                  ))}
                                </div>
                              ) : value ? (
                                <span className={`color_030304`}>
                                  {truncateString(value, 40)}
                                </span>
                              ) : (
                                "--"
                              )}
                            </td>
                          );
                      }
                    )}
                    <td
                      className={` ${
                        tabletitle === "Products"
                          ? "table_Action_data_height"
                          : "table_body_data"
                      } text-nowrap ${actionClass} end-0 color_030304   adminrole_table_empty__data  ${
                        checkedId.includes(item?.id)
                          ? "bg_table"
                          : item?.Actions?.[3]
                          ? ""
                          : "bg_color_ffffff"
                      } h-100`}
                    >
                      <div
                        className={` ${
                          (item?.Action?.[0] === "/customers/view/" &&
                            canWrite(rolePermission)) ||
                          tabletitle === "Delivery Partners"
                            ? "table_customer_Action_heading"
                            : canWrite(rolePermission) &&
                              rolePermission != "orders"
                            ? "table_Action_heading"
                            : "table_Action_heading_without_edit_delete"
                        } align-items-center d-flex`}
                      >
                        {!variants && !faq && (
                          <Link
                            to={{
                              pathname: `${item?.Action?.[0]}${item.id}`,
                              search: `?currentPage=${pagination?.currentPage}${
                                fromPage && `&fromPage=${fromPage}`
                              }`,
                            }}
                            className={`color_3AD64C border-0  p-0 pe-md-4 pe-3 fs_15 fw_500 font_poppins ${
                              checkedId.includes(item?.id)
                                ? "bg_table"
                                : "bg_color_ffffff"
                            }`}
                          >
                            View
                          </Link>
                        )}

                        {faq && (
                          <>
                            <button
                              type="button"
                              onClick={() => {
                                faqViewFn(item?.id);
                              }}
                              className={`color_3AD64C border-0  p-0 pe-md-4 pe-3 fs_15 fw_500 font_poppins ${
                                checkedId.includes(item?.id)
                                  ? "bg_table"
                                  : item?.Actions?.[3]
                                  ? ""
                                  : "bg_color_ffffff"
                              }`}
                            >
                              view
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                faqEditFn(item?.id);
                              }}
                              className={`primary_color border-0  p-0 pe-md-4 pe-3 fs_15 fw_500 font_poppins ${
                                checkedId.includes(item?.id)
                                  ? "bg_table"
                                  : item?.Actions?.[3]
                                  ? ""
                                  : "bg_color_ffffff"
                              }`}
                            >
                              Edit
                            </button>
                          </>
                        )}

                        {canWrite(rolePermission) &&
                          !variants &&
                          rolePermission != "orders" &&
                          !faq && (
                            <NavLink
                              to={{
                                pathname: `${item?.Action?.[1]}${item.id}`,
                                search: `?currentPage=${pagination?.currentPage}`,
                              }}
                              className={`primary_color border-0  p-0 pe-md-4 pe-3 fs_15 fw_500 font_poppins ${
                                checkedId.includes(item?.id)
                                  ? "bg_table"
                                  : "bg_color_ffffff"
                              }`}
                            >
                              Edit
                            </NavLink>
                          )}
                        {canWrite(rolePermission) &&
                          variants &&
                          !item?.Actions?.[3] && (
                            <button
                              type="button"
                              onClick={() => {
                                varEditFn(index);
                                faqEditFn(item?.id);
                              }}
                              className={`primary_color border-0  p-0 pe-md-4 pe-3 fs_15 fw_500 font_poppins ${
                                checkedId.includes(item?.id)
                                  ? "bg_table"
                                  : item?.Actions?.[3]
                                  ? ""
                                  : "bg_color_ffffff"
                              }`}
                            >
                              Edit
                            </button>
                          )}

                        {((customer === true && canWrite(rolePermission)) ||
                          tabletitle === "Delivery Partners") && (
                          <button
                            onClick={() => {
                              setDeleteData({
                                id: item?.id,
                                type:
                                  item?.Action?.[2] === true
                                    ? "Unblock"
                                    : "block",
                              });
                              setShow(true);
                            }}
                            className={`${
                              item?.Action?.[2] === true
                                ? "color_3AD64C pe-md-4 pe-3"
                                : "color_F24444 pe-md-5 pe-4"
                            }    border-0  p-0  fs_15 fw_500 font_poppins ${
                              checkedId.includes(item?.id)
                                ? "bg_table"
                                : item?.Actions?.[3]
                                ? "bg_color_09073A0D"
                                : "bg_color_ffffff"
                            }`}
                          >
                            {item?.Action?.[2] === true ? "Unblock" : "Block"}
                          </button>
                        )}

                        {canWrite(rolePermission) &&
                          rolePermission != "orders" && (
                            <button
                              type="button"
                              onClick={
                                variants
                                  ? () => {
                                      variantDeleteFn(index);
                                    }
                                  : () => {
                                      setDeleteData(item?.id);
                                      setShow(true);
                                    }
                              }
                              className={` ${
                                item?.Actions?.[3]
                                  ? "color_3AD64C"
                                  : "color_F24444"
                              } border-0   p-0 pe-md-4 pe-0 fs_15 fw_500 font_poppins ${
                                checkedId.includes(item?.id)
                                  ? "bg_table"
                                  : item?.Actions?.[3]
                                  ? ""
                                  : "bg_color_ffffff"
                              }`}
                            >
                              {item?.Actions?.[3] ? "Restore" : "Delete"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="table_body_row vh65">
                <td className="text-center" colSpan={tableHeading.length + 1}>
                  <div className="font_poppins fs_20 fw_500 col-xl-12 col-9">
                    -- Table is Empty --
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!variants && (
        <div>
          <TablePagination
            setCurrentPage={setCurrentPage}
            pagination={pagination}
            setCheckedId={setCheckedId}
          />
        </div>
      )}
    </>
  );
}

export default DynamicTable;
