import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  attributesDeleteAction,
  attributesViewAction,
} from "../../redux/actions/attributes-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";

const AttributesView = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [attributesDetails, setAttributesDetails] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(attributesViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setAttributesDetails(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const categoryFindOne = [
    {
      label: "Attribute Name",
      value: removeUnderScore(attributesDetails?.name),
    },
    {
      label: "Slug",
      value: removeUnderScore(attributesDetails?.slug),
    },
  ];
  return (
    <>
      <NotificationComponent />
      <InnerLayout
        type="attribute"
        name={attributesDetails?.id}
        id={attributesDetails?.id}
        deleteAction={attributesDeleteAction}
        path={`/attributes`}
        editButtonPath={`/attributes/edit/${attributesDetails?.id}`}
        rolePermission={"attributes"}
      />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
          {categoryFindOne.map((item, index) => {
            return (
              <div key={index} className="mb-3">
                <label className="color_707070 fs_14">{item.label}</label>
                <div className="color_030304 fs_14 mb-1">{item.value}</div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AttributesView;
