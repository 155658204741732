export const fetchSiteSettingData = {
  loading: true,
  success: false,
  error: false,
  data: {
    data: {},
  },
};

export const fetchLoggedAdminData = {
  loading: true,
  success: false,
  error: false,
  data: {
    data: {},
  },
};