import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import InputField from "../../components/ui/forms/input-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { attributesCreateAction } from "../../redux/actions/attributes-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import NotificationComponent from "../../components/notificationComponent";
const AttributesCreate = () => {
  const navigate = useNavigate();
  const router = useLocation();
  const { pathname } = router;
  const dispatch = useDispatch();
  const initialValues = {
    attribute_name: "",
    slug: "",
  };
  const validationSchema = Yup.object().shape({
    attribute_name: Yup.string().trim().required("Attribute Name is required"),
    slug: Yup.string().trim().nullable(),
  });
  const onSubmit = (values) => {
    dispatch(
      attributesCreateAction(
        {
          attribute_name: values?.attribute_name,
          slug: values?.slug,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    navigate(`/attributes`);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };

  function resetFn() {
    console.log("fsdfsdfsdf");
  }
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <InnerLayout
            path={"/attributes"}
            name="New Attribute"
            page="create"
            edit={true}
            onSubmit={onSubmit}
            rolePermission={"attributes"}
          />

          <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
            <div>
              <InputField
                id={"name"}
                label={"Attribute Name"}
                placeholder={"Enter attribute name"}
                name={"attribute_name"}
              />
            </div>
            <div>
              <InputField
                id={"slug"}
                label={"Slug"}
                placeholder={"Enter slug"}
                name={"slug"}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default AttributesCreate;
