import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import InnerLayout from '../../layouts/inner-layout';
import * as Yup from "yup";
import CreateButton, { BackButton, ClearButton, DeleteButton, EditButton, SaveButton } from '../../buttons/text-button';
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import QuillField from '../../forms/quill-field';
import InputField from '../../forms/input-field';
import { useDispatch } from 'react-redux';
import { faqCreateAction, faqDeleteAction, faqEditAction, faqViewAction } from '../../../../redux/actions/faq-action';
import successToast from '../../../helper-functions/helper/sucess-toast';
import errorToast from '../../../helper-functions/helper/error-toast';
import { truncateString } from '../../../helper-functions/helper/helper';
import UseDangerouslyInnerHtml from '../../../helper-functions/hooks/use-dangerously-inner-html';
import DeleteModal from '../../modals/delete-modal';


function FaqView({ closeSection, tab = { tab }, setTab = { setTab }, faq, setId, setCurrentPage, currentPage }) {
    const dispatch = useDispatch()
    function deleteFn() {
        dispatch(faqDeleteAction(faq?.id, onSuccess, onError));
    }
    const onSuccess = (data) => {
        console.log(data);
        successToast(data.message);
        setShow(false)
        setTab("table")
    };
    const onError = (data) => {
        errorToast(data.data.message);
        setTab("table")
    };
    const [show, setShow] = useState(false)



    return (
        <>
            <div
                style={{ padding: ".95rem 1rem" }}
                className="col-12  d-flex align-items-center justify-content-between px-md-5   border_bottom_1px_e5ecff"
            >
                <>
                    <div className="d-flex gap-2">
                        <img
                            onClick={() => { setTab("table"); setCurrentPage(currentPage) }}
                            src={backIcon}
                        />
                        <h1 className="fs_22 fw_600 font_montserrat mb-0 ">{"FAQs"}</h1>
                    </div>
                </>
                <div className='d-flex gap-3'>
                    <EditButton
                        onClick={() => { setTab("edit"); setId(faq?.id) }} />
                    <div
                        onClick={() => setShow(true)}
                        style={{ width: "fit-Content" }}
                    >
                        <DeleteButton page="" deleteClassName="fs_14" />
                    </div>
                </div>
            </div>
            <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
                <div className="mb-3">
                    <label className="color_707070 fs_14">Frequently Asked Question</label>
                    <div className="color_030304 fs_14 mb-1">{faq?.question}</div>
                </div>
                <div className="mb-3">
                    <label className="color_707070 fs_14">Answer</label>
                    <div className="color_030304 fs_14 mb-1"><UseDangerouslyInnerHtml description={faq?.answer} /></div>
                </div>
            </div>
            <DeleteModal
                show={show}
                modalClose={() => setShow(false)}
                deleteData={faq?.id}
                deleteFn={() => deleteFn(faq?.id)}
                text={`Are you sure, you want to delete this Faq permanently?`}
            />
        </>
    )
}

export default FaqView