import React from 'react'

const SortIcon = ({ inc = "#444444", dec = "#C8C8C8", height = "14", width = "14" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0555 6.28207H3.94465C3.76141 6.28192 3.58216 6.22424 3.42871 6.11605C3.27527 6.00786 3.15426 5.85382 3.08039 5.67268C3.00653 5.49154 2.98301 5.29112 3.0127 5.0958C3.04238 4.90049 3.12398 4.7187 3.24758 4.57258L6.13142 1.40858C6.24364 1.27831 6.37974 1.17471 6.53105 1.10438C6.68237 1.03404 6.84558 0.998512 7.0103 1.00005C7.17503 1.00158 7.33765 1.04015 7.48782 1.1133C7.63799 1.18645 7.77241 1.29257 7.88253 1.42491L10.7415 4.55961C10.8684 4.7045 10.9532 4.88636 10.9854 5.08273C11.0177 5.2791 10.996 5.48138 10.9231 5.66459C10.8501 5.8478 10.7291 6.00391 10.5749 6.11365C10.4207 6.22338 10.2402 6.28193 10.0555 6.28207ZM7.00008 13C6.83355 12.9999 6.6689 12.9619 6.51686 12.8886C6.36481 12.8152 6.22879 12.708 6.11764 12.5741L3.2587 9.44127C3.13175 9.29644 3.04688 9.11461 3.01457 8.91824C2.98227 8.72188 3.00394 8.51959 3.07692 8.33639C3.1499 8.15318 3.27098 7.9971 3.4252 7.88742C3.57942 7.77775 3.76001 7.7193 3.94465 7.71929H10.0555C10.2387 7.71939 10.4178 7.77701 10.5712 7.88511C10.7246 7.99322 10.8456 8.14716 10.9195 8.3282C10.9933 8.50923 11.0169 8.70956 10.9873 8.90479C10.9576 9.10002 10.8761 9.28174 10.7526 9.42782L7.86874 12.5918C7.75814 12.7213 7.62378 12.8245 7.47421 12.8947C7.32465 12.965 7.16318 13.0009 7.00008 13Z" fill={inc} />
            <path d="M6.51686 12.8886C6.6689 12.9619 6.83355 12.9999 7.00008 13C7.16318 13.0009 7.32465 12.965 7.47421 12.8947C7.62378 12.8245 7.75814 12.7213 7.86874 12.5918L10.7526 9.42782C10.8761 9.28174 10.9576 9.10002 10.9873 8.90479C11.0169 8.70956 10.9933 8.50923 10.9195 8.3282C10.8456 8.14716 10.7246 7.99322 10.5712 7.88511C10.4178 7.77701 10.2387 7.71939 10.0555 7.71929H3.94465C3.76001 7.7193 3.57942 7.77775 3.4252 7.88742C3.27098 7.9971 3.1499 8.15318 3.07692 8.33639C3.00394 8.51959 2.98227 8.72188 3.01457 8.91824C3.04688 9.11461 3.13175 9.29644 3.2587 9.44127L6.11764 12.5741C6.22879 12.708 6.36481 12.8152 6.51686 12.8886Z" fill={dec} />
        </svg>

    )
}

export default SortIcon