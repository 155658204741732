import React from "react";

const ProductIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0745 1.64782C16.8004 1.53125 16.4986 1.50061 16.2074 1.5598C15.9163 1.61899 15.6489 1.76534 15.4395 1.98021L13.5 3.96075L10.0605 0.448473C9.77921 0.161316 9.39775 0 9 0C8.60225 0 8.22079 0.161316 7.9395 0.448473L4.5 3.96075L2.5605 1.98021C2.35072 1.76606 2.08348 1.62023 1.79255 1.56115C1.50162 1.50208 1.20007 1.53241 0.926018 1.64832C0.651968 1.76423 0.417724 1.9605 0.252898 2.21233C0.0880711 2.46417 6.33365e-05 2.76025 0 3.06315L0 11.1707C0.00119089 12.1859 0.396661 13.1592 1.09966 13.8771C1.80267 14.5949 2.7558 14.9988 3.75 15H14.25C15.2442 14.9988 16.1973 14.5949 16.9003 13.8771C17.6033 13.1592 17.9988 12.1859 18 11.1707V3.06315C18.0001 2.76023 17.9122 2.46408 17.7475 2.21216C17.5827 1.96023 17.3485 1.76385 17.0745 1.64782ZM16.5 11.1707C16.5 11.78 16.2629 12.3644 15.841 12.7953C15.419 13.2262 14.8467 13.4683 14.25 13.4683H3.75C3.15326 13.4683 2.58097 13.2262 2.15901 12.7953C1.73705 12.3644 1.5 11.78 1.5 11.1707V3.06315L3.96975 5.58516C4.1104 5.72874 4.30113 5.8094 4.5 5.8094C4.69887 5.8094 4.8896 5.72874 5.03025 5.58516L9 1.53141L12.9697 5.58516C13.1104 5.72874 13.3011 5.8094 13.5 5.8094C13.6989 5.8094 13.8896 5.72874 14.0303 5.58516L16.5 3.06315V11.1707Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProductIcon;
