import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { ApplyButton, ClearButton } from "../../buttons/text-button";
import backarrow from "../../../../assets/icons/back-arrow-button.svg";
import MultiSelect from "../../forms/multi-select";
import { useDispatch } from "react-redux";
import SelectOrderStatus from "../../orders/order-status-select";
import { orderAllOptions, paymentStatus } from "../../../helper-functions/helper/data";
import SelectField from "../../forms/select-field";
import { customerListDropdownAction } from "../../../../redux/actions/address-action";
import MultiRangeSlider from "../../forms/multirange-slider";
import InputField from "../../forms/input-field";
export default function OrdersTableFilter({
    table,
    show = false,
    handleClose = () => { },
    handleFilterValues = () => { },
    filterValues = {},
}) {
    const dispatch = useDispatch()
    const [orderStatus, setOrderStatus] = useState(null);
    const [paymentOption, setPaymentOption] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    useEffect(() => {
        dispatch(customerListDropdownAction(OnFetchSuccess, onFetchError));
    }, []);
    const OnFetchSuccess = (data) => {
        setCustomerList(
            data.data.map((item) => {
                return { label: item.id + " - " + item.name, value: item.id };
            })
        );
    };
    const onFetchError = () => { };
    const [minOrderValue, setMinOrderValue] = useState([0, 100000]);
    const initialValues = {
        orders_Status: filterValues?.orders_Status ?? "",
        payment_status: filterValues?.payment_status ?? "",
        user_id: filterValues?.user_id ?? "",
        min_order_value: filterValues?.min_order_value ?? 0,
        max_order_value: filterValues?.max_order_value ?? 100000,
        from_date: filterValues?.from_date ?? "",
        to_date: filterValues?.to_date ?? "",
    };
    const onSubmit = (values) => {
        console.log(values, "rlgjikertyhoertjiyo");
        handleFilterValues(
            {
                orders_Status: values?.orders_Status ?? "",
                payment_status: values?.payment_status ?? "",
                user_id: values?.user_id ?? "",
                min_order_value: values?.min_order_value ?? 0,
                max_order_value: values?.max_order_value ?? 100000,
                from_date: values?.from_date ?? "",
                to_date: values?.to_date ?? "",
            }, "submit"
        );
    };
    const clearFn = () => {
        handleFilterValues(
            {
                orders_Status: "",
                payment_status: "",
                user_id: "",
                min_order_value: null,
                max_order_value: null,
                from_date: "",
                to_date: "",
            }, "reset"
        );
    };
    return (
        <Offcanvas
            show={show}
            onHide={() => handleClose()}
            placement="end"
            className="side-bar-container"
            backdropClassName="custom_backdrop"
        >
            <Offcanvas.Body className="p-0">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({ values, handleChange, field, setFieldValue }) => {
                        console.log(values, "fgdfgh");
                        return (
                            <Form className="h-100 d-flex flex-column justify-content-between">
                                <div>
                                    <div className="d-flex align-items-between ps-3 pt-3 gap-3">
                                        <img
                                            onClick={handleClose}
                                            width={"30px"}
                                            src={backarrow}
                                            alt="back arrow"
                                        />{" "}
                                        <span className="fs_22 fw_500 font_montserrat color_030304">
                                            Filter
                                        </span>
                                    </div>
                                    <hr className="colored-line" />
                                    <div className="ps-4 px-sm-4 d-flex flex-column gap-3 mt-4 pt-2">
                                        <div>
                                            <SelectOrderStatus
                                                labelClass="color_030304 fs_14 fw_500 mb-1"
                                                placeholder="Select order status"
                                                label="Orders Status"
                                                options={orderAllOptions}
                                                selectClass={'col-12'}
                                                selectedOption={orderStatus}
                                                setSelectedOption={setOrderStatus}
                                                isFormik={true}
                                                name="orders_Status"
                                                formValues={values}
                                            />
                                        </div>
                                        <div>
                                            <SelectOrderStatus
                                                placeholder="Select payment status"
                                                labelClass="color_030304 fs_14 fw_500 mb-1"
                                                label="Payment Status"
                                                options={paymentStatus}
                                                selectClass={'col-12'}
                                                selectedOption={paymentOption}
                                                setSelectedOption={setPaymentOption}
                                                isFormik={true}
                                                name="payment_status"
                                                formValues={values}
                                            />
                                        </div>
                                        <SelectField
                                            labelClass="text-black fw_500 fs_14"
                                            placeholder={"Select Customer"}
                                            label={"User Id"}
                                            id={"user_id"}
                                            name="user_id"
                                            errMsgContainerClass="h-0"
                                            isFormikControlled={true}
                                            options={customerList}
                                            customStyle={""}
                                        />

                                        <div className="me-3">
                                            <div className="color_030304 fs_14 fw_500 d-flex  mb-1">
                                                Order Value
                                            </div>
                                            {/* <span className="color_030304  fw_500">
                                                {" "}
                                                ₹ {minOrderValue?.[0]} - ₹{minOrderValue?.[1]}
                                            </span> */}
                                            <MultiRangeSlider
                                                table={table}
                                                min={0}
                                                max={100000}
                                                containerClass="ms-2"
                                                setValues={setMinOrderValue}
                                                values={minOrderValue}
                                                handleChangeFn={(e) => { setFieldValue("min_order_value", e?.[0]); setFieldValue("max_order_value", e?.[1]) }}
                                            />
                                        </div>
                                        <div className="d-flex gap-2 mt-3">
                                            <InputField
                                                containerClass=""
                                                unitPosition={"left"}
                                                unitType={localStorage?.getItem("currency") ?? "₹"}
                                                isUnit={true}
                                                id={"min_order_value"}
                                                label={""}
                                                placeholder={"Min. value"}
                                                name={"min_order_value"}
                                            />
                                            <InputField
                                                containerClass=""
                                                unitPosition={"left"}
                                                unitType={localStorage?.getItem("currency") ?? "₹"}
                                                isUnit={true}
                                                id={"max_order_value"}
                                                label={""}
                                                placeholder={"Max. value"}
                                                name={"max_order_value"}
                                            />
                                        </div>
                                        <span className='color_030304 fs_14 fw_500'>Order Date</span>
                                        <div className='d-flex flex-sm-row flex-column gap-2'>
                                            <InputField
                                                inputClass='py-2'
                                                labelClass="text-black fw_500 fs_14 mb-1"
                                                placeholder="Enter attribute name"
                                                containerClass="mb-3 position-relative w-100 d-flex flex-column"
                                                errMsgContainerClass="ps-2"
                                                label="From Date"
                                                name="from_date"
                                                type="date"
                                            />
                                            <InputField
                                                inputClass='py-2'
                                                labelClass="text-black fw_500 fs_14 mb-1"
                                                placeholder="Enter to_date"
                                                containerClass="mb-3 position-relative w-100 d-flex flex-column"
                                                errMsgContainerClass="ps-2"
                                                label="To Date"
                                                name="to_date"
                                                type="date"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <hr className="colored-line" />
                                    <div className="d-flex justify-content-end gap-3 px-3 pb-3 align-items-center">
                                        <ClearButton
                                            clearFn={clearFn}
                                            type="reset"
                                            clearClassName={"px-2 py-2 fs_16 fw_600"}
                                        />
                                        <ApplyButton
                                            type="submit"
                                            applyClassName={"px-2 py-2 fs_16 fw_600"}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
