import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  tagsDeleteAction,
  tagsViewAction,
} from "../../redux/actions/tags-action";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import { productEnquiryViewAction } from "../../redux/actions/product-enquiry-action";
import { Link } from "react-router-dom";
import NotificationComponent from "../../components/notificationComponent";
const ProductEnquiriesView = () => {
  const params = useParams();
  const [productEnq, setProductEnqDetails] = useState();
  const [isImgLoading, setIsImgLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productEnquiryViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data, "dfjghdfgjfdg");
    setLoading(false);
    setProductEnqDetails(data?.data?.product);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const productEnqDetails = [
    {
      label: "Product Enquiry ID",
      value: productEnq?.name,
    },
    {
      label: "Name",
      value: productEnq?.name,
    },
    {
      label: "Mobile Number",
      value: productEnq?.phone_number,
    },
    {
      label: "Email Address",
      value: productEnq?.email_address ?? "--",
    },
  ];


  const productDetails = [
    {
      label: "Image",
      value: (
        <>
          {isImgLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "250px", height: "250px" }}
            >
              <LoadingSpinner size="lg" />
            </div>
          )}
          <div
            className={`${imageRegex.test(productEnq?.tag_picture) === false &&
              "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
              }`}
            style={{ width: "250px", height: !isImgLoading && "250px" }}
          >
            <img
              className="rounded-3 mt-1"
              src={
                imageRegex.test(productEnq?.product_details?.thumbnail_image)
                  ? productEnq?.product_details?.thumbnail_image
                  : UplaodImgLogo
              }
              width={
                imageRegex.test(productEnq?.product_details?.thumbnail_image) ? "250px" : "100"
              }
              height={
                imageRegex.test(productEnq?.product_details?.thumbnail_image) ? "250px" : "100"
              }
              alt="image"
              onLoad={() => setIsImgLoading(false)}
              style={{ display: isImgLoading ? "none" : "block" }}
            />
          </div>
        </>
      ),
    },
    {
      label: "Product ID",
      value: productEnq?.product_details?.code ?? "--",
    },
    {
      label: "Product Name",
      value: productEnq?.product_details?.product_name ?? "--",
    },
    {
      label: "Product Code",
      value: productEnq?.product_details?.product_code ?? "--",
    },
    {
      label: "Category",
      value:
        productEnq?.product_details?.category?.length > 0
          ? productEnq?.product_details?.category?.map((item, index) => {
            return (
              <span key={index}>
                {item?.category_name}
                {productEnq?.product_details?.category?.length > 1 &&
                  index !== productEnq?.product_details.category?.length - 1 &&
                  " | "}
              </span>
            );
          })
          : "--",
    },
    localStorage.getItem("brand") === "true" &&
    {
      label: "Brands",
      value: productEnq?.product_details?.brands?.name ? productEnq?.product_details?.brands?.name : "--"
    },
    {
      label: "Price",
      value: productEnq?.product_details?.price ?? "--",
    },
  ];
  return (
    <>
    <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          <InnerLayout
            name={`${productEnq?.code ?? "Tags View"}`}
            id={productEnq?.id}
            deleteAction={tagsDeleteAction}
            path={`/product-enquiry`}
            editButtonPath={`/tags/edit/${productEnq?.id}`}
            type="tags"
          />

          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            <h1 className="fw_400 color_030304 fs_20">Product Enquiry Details</h1>
            {productEnqDetails.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <label className="color_707070 fs_14">{item.label}</label>
                  <div className="color_030304 fs_14 mb-1">{item.value}</div>
                </div>
              );
            })}
          </div>
          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            <h1 className="fw_400 color_030304 fs_20">Product Details</h1>
            {productDetails.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <label className="color_707070 fs_14">{item.label}</label>

                  {
                    item?.label === "Product ID" ? <Link to={`/products/view/${productEnq?.product_details?.id}`} className={`color_2820DA d-block ${item.value && "text-decoration-underline"} `}>{item.value}</Link> :
                      <div className="color_030304 fs_14 mb-1">{item.value}</div>
                  }
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ProductEnquiriesView;
