import { call, takeLatest } from "redux-saga/effects";
import {
  ordersEditApi,
  ordersTableApi,
  ordersViewApi,
  ordersCreateApi,
  billingGenerateApi,
  addressFetchApi,
  ordersRejectApi,
  ordersPartialRejectApi,
  ordersBillingEditApi,
  ordersDeliveryPartnersApi,
  ordersReturnEditApi,
  ordersServiceApi,
  ordersAssignApi,
} from "../apis/orders-api";
import {
  ORDERS_EDIT,
  ORDERS_TABLE,
  ORDERS_VIEW,
  ORDERS_CREATE,
  BILLING_GENERATE,
  ADDRESS_FETCH,
  ORDERS_REJECT,
  ORDERS_PARTIAL_REJECT,
  ORDERS_Billing_Edit,
  ORDER_DELIVERY_PARTNERS,
  ORDERS_RETURN_Edit,
  ORDERS_SERVICE,
  ORDERS_ASSIGN,
} from "../types";

export function* ordersTableWorker(action) {
  try {
    const res = yield call(ordersTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* ordersViewWorker(action) {
  try {
    const res = yield call(ordersViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* ordersCreateWorker(action) {
  try {
    const res = yield call(ordersCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* ordersEditWorker(action) {
  try {
    const res = yield call(ordersEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* billingGenerateWorker(action) {
  try {
    const res = yield call(billingGenerateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* addressFetchWorker(action) {
  try {
    const res = yield call(addressFetchApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}





export function* ordersRejecttWorker(action) {
  try {
    const res = yield call(ordersRejectApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* ordersPartialRejectWorker(action) {
  try {
    const res = yield call(ordersPartialRejectApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}




export function* ordersBillingEditWorker(action) {
  try {
    const res = yield call(ordersBillingEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* ordersDeliveryPartnersWorker(action) {
  try {
    const res = yield call(ordersDeliveryPartnersApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}



export function* ordersReturnEditWorker(action) {
  try {
    const res = yield call(ordersReturnEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* ordersServiceWorker(action) {
  try {
    const res = yield call(ordersServiceApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* ordersAssignWorker(action) {
  try {
    const res = yield call(ordersAssignApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* watchordersTableWorker() {
  yield takeLatest(ORDERS_TABLE, ordersTableWorker);
}
export function* watchordersViewWorker() {
  yield takeLatest(ORDERS_VIEW, ordersViewWorker);
}
export function* watchordersEditWorker() {
  yield takeLatest(ORDERS_EDIT, ordersEditWorker);
}

export function* watchordersCreateWorker() {
  yield takeLatest(ORDERS_CREATE, ordersCreateWorker);
}
export function* watchbillingGenerateWorker() {
  yield takeLatest(BILLING_GENERATE, billingGenerateWorker);
}
export function* watchaddressFetchWorker() {
  yield takeLatest(ADDRESS_FETCH, addressFetchWorker);
}





export function* watchordersRejectWorker() {
  yield takeLatest(ORDERS_REJECT, ordersRejecttWorker);
}


export function* watchordersPartialRejectWorker() {
  yield takeLatest(ORDERS_PARTIAL_REJECT, ordersPartialRejectWorker);
}




export function* watchordersBillingEditWorker() {
  yield takeLatest(ORDERS_Billing_Edit, ordersBillingEditWorker);
}

export function* watchordersDeliveryPartnersWorker() {
  yield takeLatest(ORDER_DELIVERY_PARTNERS, ordersDeliveryPartnersWorker);
}

export function* watchordersReturnEditWorker() {
  yield takeLatest(ORDERS_RETURN_Edit, ordersReturnEditWorker);
}

export function* watchordersServiceWorker() {
  yield takeLatest(ORDERS_SERVICE, ordersServiceWorker);
}

export function* watchordersAssignWorker() {
  yield takeLatest(ORDERS_ASSIGN, ordersAssignWorker);
}