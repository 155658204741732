import React from "react";

const TwitterLogo = ({ width = "20", height = "20", fill = "#2820DA" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5104 4.71289C17.8743 4.9943 17.1996 5.17914 16.5088 5.26127C17.2366 4.82561 17.7812 4.14026 18.0411 3.33289C17.3573 3.73919 16.6088 4.02515 15.8282 4.17835C15.1693 3.47539 14.2483 3.07704 13.2848 3.07813C11.3605 3.07813 9.79975 4.63835 9.79975 6.56354C9.79975 6.83666 9.83108 7.10153 9.88967 7.35709C6.99206 7.21121 4.42506 5.82455 2.70565 3.71569C2.39608 4.24757 2.23338 4.85216 2.2342 5.46757C2.2342 6.67667 2.84967 7.74388 3.78458 8.36868C3.23114 8.35118 2.6899 8.20171 2.20598 7.93262C2.20545 7.94726 2.20545 7.9619 2.20545 7.97574C2.20545 9.66484 3.40683 11.0738 5.00166 11.3938C4.70233 11.4748 4.3936 11.5157 4.08352 11.5154C3.85831 11.5154 3.63976 11.4947 3.42733 11.4539C3.87109 12.8383 5.15738 13.8461 6.683 13.8738C5.44844 14.8427 3.92391 15.3683 2.35453 15.3661C2.07677 15.3663 1.79924 15.3499 1.52343 15.3171C3.1167 16.3413 4.97127 16.8849 6.86535 16.8829C13.2763 16.8829 16.7817 11.5724 16.7817 6.9671C16.7817 6.81643 16.778 6.66523 16.7713 6.51615C17.4536 6.02322 18.0425 5.41257 18.5104 4.71289Z"
        fill={fill}
      />
    </svg>
  );
};

export default TwitterLogo;
