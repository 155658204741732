import { all } from "redux-saga/effects";
import {
  watchTagsCreateWorker,
  watchTagsDeleteWorker,
  watchTagsEditWorker,
  watchTagsMultiDeleteWorker,
  watchTagsTableWorker,
  watchTagsViewWorker,
  watchtagsDropDownListWorker,
} from "../tags-saga";

export default function* tagsRootSaga() {
  yield all([
    watchTagsTableWorker(),
    watchTagsViewWorker(),
    watchTagsEditWorker(),
    watchTagsDeleteWorker(),
    watchTagsMultiDeleteWorker(),
    watchTagsCreateWorker(),
    watchtagsDropDownListWorker(),
  ]);
}
