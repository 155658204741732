import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { ApplyButton, ClearButton } from "../../buttons/text-button";
import backarrow from "../../../../assets/icons/back-arrow-button.svg";
import MultiSelect from "../../forms/multi-select";
import { categoriesDropdownAction } from "../../../../redux/actions/categories-action";
import { useDispatch } from "react-redux";
import { tagsDropDownListAction } from "../../../../redux/actions/tags-action";
export default function ProductsTableFitler({
  show = false,
  handleClose = () => {},
  handleFilterValues = () => {},
  filterValues = {},
}) {
  const dispatch = useDispatch();

  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    dispatch(categoriesDropdownAction(onFetchCatSuccess, onFetchCatError));
    dispatch(tagsDropDownListAction(onFetchTagSuccess, onFetchTagError));
  }, []);

  const onFetchCatSuccess = (data) => {
    setCategoryList(
      data.data.map((item) => {
        return { label: item.category_name, value: item.id };
      })
    );
  };
  const onFetchCatError = () => {
    console.log("failed to load parent category list");
  };
  const onFetchTagSuccess = (data) => {
    setTagList(
      data.data.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  };
  const onFetchTagError = () => {
    console.log("failed to load parent category list");
  };
  const initialValues = {
    out_of_stock: filterValues?.out_of_stock ?? false,
    categories: filterValues?.categories ?? [],
    tags: filterValues?.tags ?? [],
  };

  const onSubmit = (values) => {
    handleFilterValues(
      {
        out_of_stock: values?.out_of_stock,
        categories: values?.categories,
        tags: values?.tags,
      },
      "submit"
    );
  };
  const clearFn = () => {
    handleFilterValues(
      {
        out_of_stock: false,
        categories: [],
        tags: [],
      },
      "reset"
    );
  };
  return (
    <Offcanvas
      show={show}
      onHide={() => handleClose()}
      placement="end"
      className="side-bar-container"
      backdropClassName="custom_backdrop"
    >
      <Offcanvas.Body className="p-0">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, field, setFieldValue }) => {
            return (
              <Form className="h-100 d-flex flex-column justify-content-between">
                <div>
                  <div className="d-flex align-items-between ps-3 pt-3 gap-3">
                    <img
                      onClick={handleClose}
                      width={"30px"}
                      src={backarrow}
                      alt="back arrow"
                    />{" "}
                    <span className="fs_22 fw_500 font_montserrat color_030304">
                      Filter
                    </span>
                  </div>
                  <hr className="colored-line" />
                  <div className="ps-4 px-sm-4 d-flex flex-column gap-4 mt-4 pt-2">
                    <div>
                      <span>
                        <input
                          {...field}
                          style={{ transform: "scale(1.1)" }}
                          type="checkbox"
                          id="myCheckbox"
                          name="out_of_stock"
                          className="custom-checkbox form-check-input"
                          checked={values?.out_of_stock}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </span>
                      <span className="ms-2 text-black fw_400 fs_14">
                        Out of Stock
                      </span>
                    </div>
                    <MultiSelect
                      name={`categories`}
                      placeholder="Select Category"
                      options={categoryList}
                      errorMsgShow={false}
                      containerClassName=""
                      label="Category"
                      onChange={(selectedOptions) =>
                        setFieldValue(`categories`, selectedOptions)
                      }
                      value={values?.categories}
                    />
                    <MultiSelect
                      name={`tags`}
                      placeholder="Select Tags"
                      options={tagList}
                      label="Tags"
                      onChange={(selectedOptions) =>
                        setFieldValue(`tags`, selectedOptions)
                      }
                      value={values?.tags}
                    />
                  </div>
                </div>
                <div>
                  <hr className="colored-line" />
                  <div className="d-flex justify-content-end gap-3 px-3 pb-3 align-items-center">
                    <ClearButton
                      clearFn={clearFn}
                      type="reset"
                      clearClassName={"px-2 py-2 fs_16 fw_600"}
                    />
                    <ApplyButton
                      type="submit"
                      applyClassName={"px-2 py-2 fs_16 fw_600"}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
