import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADMIN_USERS_CREATE,
  ADMIN_USERS_DELETE,
  ADMIN_USERS_EDIT,
  ADMIN_USERS_UPLOAD_IMAGE,
  ADMIN_USERS_MULTI_DELETE,
  ADMIN_USERS_RESET_PASSWORD,
  ADMIN_USERS_TABLE,
  ADMIN_USERS_VIEW,
  ADMIN_USERS_SEND_PASSWORD,
  ADMIN_USER_DETAILS,
  LOGGED_ADMIN_USER_EDIT,
  LOGGED_ADMIN_DATA_LOADED,
  LOGGED_ADMIN_DATA_FAILED,
} from "../types";
import {
  adminUserDetailsApi,
  adminUsersCreateApi,
  adminUsersDeleteApi,
  adminUsersEditApi,
  adminUsersImageUploadApi,
  adminUsersMultiDeleteApi,
  adminUsersResetPasswordApi,
  adminUsersSendPasswordApi,
  adminUsersTableApi,
  adminUsersViewApi,
  loggedAdminUserEditApi,
} from "../apis/admin-users-api";
import { s3BucketText } from "../config";
export function* adminUsersTableWorker(action) {
  try {
    const res = yield call(adminUsersTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersViewWorker(action) {
  try {
    const res = yield call(adminUsersViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersEditWorker(action) {
  try {
    const res = yield call(adminUsersEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersCreateWorker(action) {
  try {
    const res = yield call(adminUsersCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersImageUploadWorker(action) {
  try {
    // console.log(action.data);
    const imageData = new FormData();
    imageData.append("image", action.data);
    console.log(imageData);
    const res = yield call(adminUsersImageUploadApi, imageData);
    if (res.status === 200) {
      let urlParts = res.data.data.split("/");
      let lastPart = urlParts[urlParts.length - 1];
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersResetPasswordWorker(action) {
  try {
    const res = yield call(adminUsersResetPasswordApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersDeleteWorker(action) {
  try {
    const res = yield call(adminUsersDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersMultiDeleteWorker(action) {
  try {
    const res = yield call(adminUsersMultiDeleteApi, { data: action.data });
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminUsersSendPasswordWorker(action) {
  try {
    const res = yield call(adminUsersSendPasswordApi, action.data, action.password);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
// export function* adminUserDetailsWorker(action) {
//   try {
//     const res = yield call(adminUserDetailsApi);
//     if (res.status === 200) {
//       yield action.onSuccess(res.data);
//     } else {
//       yield action.onError(res?.data);
//     }
//   } catch (error) {
//     yield action.onError(error?.response);
//   }
// }
export function* adminUserDetailsWorker(action) {
  try {
    const res = yield call(adminUserDetailsApi);
    console.log(res);
    if (res.status === 200) {
      yield put({ type: LOGGED_ADMIN_DATA_LOADED, data: res?.data });
    } else {
      yield put({
        type: LOGGED_ADMIN_DATA_FAILED,
        data: res?.data,
      });
    }
  } catch (error) {
    yield put({
      type: LOGGED_ADMIN_DATA_FAILED,
      data: error?.response,
    });
  }
}
export function* loggedAdminUserEditWorker(action) {
  try {
    const res = yield call(loggedAdminUserEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchAdminUsersTableWorker() {
  yield takeLatest(ADMIN_USERS_TABLE, adminUsersTableWorker);
}
export function* watchAdminUsersViewWorker() {
  yield takeLatest(ADMIN_USERS_VIEW, adminUsersViewWorker);
}
export function* watchAdminUsersEditWorker() {
  yield takeLatest(ADMIN_USERS_EDIT, adminUsersEditWorker);
}
export function* watchAdminUsersCreateWorker() {
  yield takeLatest(ADMIN_USERS_CREATE, adminUsersCreateWorker);
}
export function* watchAdminUsersImageUploadWorker() {
  yield takeLatest(ADMIN_USERS_UPLOAD_IMAGE, adminUsersImageUploadWorker);
}
export function* watchAdminUsersResetPasswordWorker() {
  yield takeLatest(ADMIN_USERS_RESET_PASSWORD, adminUsersResetPasswordWorker);
}
export function* watchAdminUsersDeleteWorker() {
  yield takeLatest(ADMIN_USERS_DELETE, adminUsersDeleteWorker);
}
export function* watchAdminUsersMultiDeleteWorker() {
  yield takeLatest(ADMIN_USERS_MULTI_DELETE, adminUsersMultiDeleteWorker);
}
export function* watchadminUserDetailsWorker() {
  yield takeLatest(ADMIN_USER_DETAILS, adminUserDetailsWorker);
}
export function* watchAdminUsersSendPasswordWorker() {
  yield takeLatest(ADMIN_USERS_SEND_PASSWORD, adminUsersSendPasswordWorker);
}
export function* watchLoggedAdminUserEditWorker() {
  yield takeLatest(LOGGED_ADMIN_USER_EDIT, loggedAdminUserEditWorker);
}
