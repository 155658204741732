import {
  ALL_PRODUCTS_ID,
  PRODUCTS_CREATE,
  PRODUCTS_DELETE,
  PRODUCTS_EDIT,
  PRODUCTS_MULTI_DELETE,
  PRODUCTS_TABLE,
  PRODUCTS_VIEW,
  UPDATE_PRODUCTS_STATUS,
  UPDATE_PRODUCTS_STOCK_STATUS,
} from "../types";

export const productsTableAction = (data, bodyData, onSuccess, onError) => {
  return {
    type: PRODUCTS_TABLE,
    data,
    bodyData,
    onSuccess,
    onError,
  };
};

export const productsViewAction = (id, onSuccess, onError) => {
  return {
    type: PRODUCTS_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const productsEditAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCTS_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const productsDeleteAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCTS_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const productsMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCTS_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const productsCreateAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCTS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const allProductsIdAction = (onSuccess, onError) => {
  return {
    type: ALL_PRODUCTS_ID,
    onSuccess,
    onError,
  };
};
export const updateProductStatusAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_PRODUCTS_STATUS,
    data,
    onSuccess,
    onError,
  };
};
export const updateProductStockStatusAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_PRODUCTS_STOCK_STATUS,
    data,
    onSuccess,
    onError,
  };
};
