import {
  PLUGIN_CREATE,
  PLUGIN_LIST,
  PLUGIN_UPLOAD,
  INSTALLED_PLUGIN_LIST,
  PLUGIN_UNINSTALL,
  PLUGIN_UPDATE,
  PLUGIN_INSTALL,
  PLUGIN_IMAGE_UPLOAD,
  THEMES_LIST,
  THEME_INSTALL,
  THEME_IMAGE_UPLOAD,
  THEME_UPLOAD,
  THEME_CREATE,
  THEME_UNINSTALL,
} from "../types";

export const pluginListAction = (data, onSuccess, onError) => {
  return {
    type: PLUGIN_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const themesListAction = (data, onSuccess, onError) => {
  return {
    type: THEMES_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const installedPluginListAction = (data, onSuccess, onError) => {
  return {
    type: INSTALLED_PLUGIN_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const pluginCreateAction = (data, onSuccess, onError) => {
  return {
    type: PLUGIN_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const themeCreateAction = (data, onSuccess, onError) => {
  return {
    type: THEME_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const pluginUpdateAction = (data, onSuccess, onError) => {
  return {
    type: PLUGIN_UPDATE,
    data,
    onSuccess,
    onError,
  };
};
export const pluginInstallAction = (data, onSuccess, onError) => {
  return {
    type: PLUGIN_INSTALL,
    data,
    onSuccess,
    onError,
  };
};
export const themeInstallAction = (data, onSuccess, onError) => {
  return {
    type: THEME_INSTALL,
    data,
    onSuccess,
    onError,
  };
};
export const themeUninstallAction = (data, onSuccess, onError) => {
  return {
    type: THEME_UNINSTALL,
    data,
    onSuccess,
    onError,
  };
};
export const pluginUninstallAction = (data, onSuccess, onError) => {
  return {
    type: PLUGIN_UNINSTALL,
    data,
    onSuccess,
    onError,
  };
};

export const pluginUploadAction = (data, onSuccess, onError) => {
  return {
    type: PLUGIN_UPLOAD,
    data,
    onSuccess,
    onError,
  };
};
export const pluginImageUploadAction = (data, onSuccess, onError) => {
  console.log(data, "fyhbkbxsdfjhb");
  return {
    type: PLUGIN_IMAGE_UPLOAD,
    data,
    onSuccess,
    onError,
  };
};
export const themeUploadAction = (data, onSuccess, onError) => {
  return {
    type: THEME_UPLOAD,
    data,
    onSuccess,
    onError,
  };
};
export const themeImageUploadAction = (data, onSuccess, onError) => {
  return {
    type: THEME_IMAGE_UPLOAD,
    data,
    onSuccess,
    onError,
  };
};
