import { Field } from "formik";
import React, { Fragment, useState } from "react";
import EyeCloseIcon from "../../../assets/icons/eye-close-icon";
import EyeOpenIcon from "../../../assets/icons/eye-open-icon";
import "../styles/components.css";
import ErrorMsg from "./error-msg";
export default function InputField({
  isDisabled = false,
  name = "",
  replaceInputCSS = false,
  containerClass = "mb-2 position-relative",
  labelClass = "d-flex fs_14 mb-1",
  labelActiveClass = "color_9E9E9E fw_400",
  labelInActiveClass = "text-black fw_500",
  placeholder = "Enter your email address",
  label = "Email Address",
  id,
  type = "text",
  inputClass = "w-100",
  errMsgContainerClass = "",
  isContainer = true,
  defaultInputClass = "inputField focus_outline__none",
  unitContainerClass = "d-flex unit_input__container",
  unitContainerErrorClass = "d-flex unit_input_container__error",
  isUnit = false,
  unitType = localStorage?.getItem("currency") ?? "₹",
  unitPosition = "left",
  isPasswordEye = false,
  passwordEyeClass = "position-absolute  end-0 translate-middle  cursor_pointer",
  passwordEyeStyle = { top: "44%" },
  autoComplete = "",
  unitClassName = "",
  children,
}) {
  const finalInputClass = replaceInputCSS
    ? inputClass
    : defaultInputClass + " " + inputClass; // replaceInputCSS true means inputClass(prop) will completely replace default class else it will attach with default
  const Container = isContainer ? "div" : Fragment; // isContainer true means a container div will be there
  const UnitContainer = isUnit ? "div" : Fragment; // isContainer true means a container div will be there
  const PasswordEyeContainer = isPasswordEye ? "div" : Fragment;

  const [passInputType, setPassInputType] = useState("password");

  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {" "}
      <Field name={name}>
        {({ field, meta, form }) => (
          <>
            {label && (
              <label
                {...(id ? { htmlFor: id } : {})}
                className={`${
                  form.values[name] ? labelActiveClass : labelInActiveClass
                }   ${labelClass}`}
              >
                {label}
              </label>
            )}
            <UnitContainer
              {...(unitContainerClass && isUnit
                ? {
                    className: `  ${
                      meta.touched && meta.error
                        ? unitContainerErrorClass
                        : unitContainerClass
                    } ${unitPosition === "right" ? "flex-row-reverse" : ""}`,
                  }
                : {})}
            >
              {isUnit && (
                <div
                  className={`d-flex justify-content-center align-items-center font_poppins mt-1 ${
                    unitPosition === "right" ? "flex-row-reverse" : ""
                  }`}
                >
                  <span
                    className={` fw_500 fs_14 align-middle primary_color ${unitClassName} ${
                      unitPosition === "right" ? "ms-1" : "me-1"
                    }`}
                  >
                    {unitType}
                  </span>{" "}
                  {unitPosition !== "image" && (
                    <span className={`input-vr mx-2`}></span>
                  )}
                </div>
              )}
              <PasswordEyeContainer
                {...(isPasswordEye
                  ? {
                      className: `position-relative`,
                    }
                  : {})}
              >
                <input
                  {...(isDisabled ? { disabled: true } : {})}
                  {...(autoComplete ? { autoComplete: autoComplete } : {})}
                  type={type === "password" ? passInputType : type}
                  {...field}
                  placeholder={placeholder}
                  {...(id ? { id: id } : {})}
                  className={
                    isUnit
                      ? `inputField border-0 focus_outline__none w-100`
                      : `${
                          meta.touched && meta.error
                            ? `${finalInputClass} inputField_border__error`
                            : `${finalInputClass} inputField_border`
                        }`
                  }
                />
                {isPasswordEye &&
                  (passInputType === "password" ? (
                    <button
                      style={passwordEyeStyle}
                      type="button"
                      className={`${passwordEyeClass} `}
                    >
                      <EyeCloseIcon onClick={() => setPassInputType("text")} />
                    </button>
                  ) : (
                    <button
                      type="button"
                      style={passwordEyeStyle}
                      className={`${passwordEyeClass} border-0`}
                    >
                      {" "}
                      <EyeOpenIcon
                        onClick={() => setPassInputType("password")}
                      />
                    </button>
                  ))}
              </PasswordEyeContainer>
            </UnitContainer>
          </>
        )}
      </Field>
      <ErrorMsg containerClass={errMsgContainerClass} name={name} />
      {children}
    </Container>
  );
}

/**This input field only works within Formik**/
