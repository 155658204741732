import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { ApplyButton, ClearButton } from "../../buttons/text-button";
import backarrow from "../../../../assets/icons/back-arrow-button.svg";
import RadioButton from "../../forms/radio-button";
import {
  discountProdSelectOptns,
  discountTypeOptions,
} from "../../../helper-functions/helper/data";
import MultiRangeSlider from "../../forms/multirange-slider";
export default function DiscountTableFilter({
  show = false,
  handleClose = () => { },
  handleFilterValues = () => { },
  filterValues = {},
}) {
  const initialValues = {
    discount_type: filterValues.discount_type
      ? filterValues.discount_type
      : "fixed_rate",
    on_all_products:
      filterValues.on_all_products === "all" ||
        filterValues.on_all_products === "true"
        ? "all"
        : filterValues.on_all_products,
  };
  const [discountValue, setDiscountValue] = useState([0, 2000]);
  const [discountPercentValue, setDiscountPercentValue] = useState([0, 100]);
  const [minOrderValue, setMinOrderValue] = useState([0, 1000]);
  const [maxDiscountValue, setMaxDiscountValue] = useState([0, 1000]);
  const onSubmit = (values) => {
    handleFilterValues(
      {
        discount_type: values.discount_type,
        on_all_products:
          values.on_all_products === "all" ? "all" : values.on_all_products,
        value_of_discount_from:
          values.discount_type === "percentage"
            ? discountPercentValue[0]
            : discountValue[0],
        value_of_discount_to:
          values.discount_type === "percentage"
            ? discountPercentValue[1]
            : discountValue[1],
        max_discount_amount_from:
          values.discount_type === "percentage" ? maxDiscountValue[0] : "",
        max_discount_amount_to:
          values.discount_type === "percentage" ? maxDiscountValue[1] : "",
        min_order_value_from: minOrderValue[0],
        min_order_value_to: minOrderValue[1],
      },
      "submit"
    );
  };
  const clearFn = () => {
    setDiscountValue([0, 2000]);
    setDiscountPercentValue([0, 100]);
    setMinOrderValue([0, 1000]);
    setMaxDiscountValue([0, 1000]);
    handleFilterValues(
      {
        discount_type: "",
        value_of_discount_from: "",
        value_of_discount_to: "",
        max_discount_amount_from: "",
        max_discount_amoun_to: "",
        min_order_value_from: "",
        min_order_value_to: "",
        on_all_products: true,
      },
      "reset"
    );
  };
  return (
    <Offcanvas
      show={show}
      onHide={() => handleClose()}
      placement="end"
      className="side-bar-container"
      backdropClassName="custom_backdrop"
    >
      <Offcanvas.Body className="p-0">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            return (
              <Form className="h-100 d-flex flex-column justify-content-between">
                <div>
                  <div className="d-flex align-items-between ps-3 pt-3 gap-3">
                    <img
                      onClick={handleClose}
                      width={"30px"}
                      src={backarrow}
                      alt="back arrow"
                    />{" "}
                    <span className="fs_22 fw_500 font_montserrat color_030304">
                      Filter
                    </span>
                  </div>
                  <hr className="colored-line" />

                  <div className="ps-4 ps-sm-5 d-flex flex-column gap-4 mt-4 pt-2">
                    <RadioButton
                      labelClass="color_707070 d-flex fs_14 mb-1 fw_400"
                      name="discount_type"
                      options={[...discountTypeOptions].reverse()}
                      label="Discount Type"
                      inputLabelClass="fw_500"
                    />
                    <div className="me-5">
                      <div className="color_707070 d-flex fs_14 mb-1 fw_400">
                        Value of Discount
                      </div>
                      <span className="color_030304  fw_500">
                        {values.discount_type !== "percentage"
                          ? (localStorage?.getItem("currency") ?? "₹") + discountValue[0]
                          : discountPercentValue?.[0] + "%"}{" "}
                        -{" "}
                        {values.discount_type !== "percentage"
                          ? (localStorage?.getItem("currency") ?? "₹") + discountValue[1]
                          : discountPercentValue?.[1] + "%"}
                      </span>
                      <MultiRangeSlider
                        setValues={
                          values.discount_type === "percentage"
                            ? setDiscountPercentValue
                            : setDiscountValue
                        }
                        values={
                          values.discount_type === "percentage"
                            ? discountPercentValue
                            : discountValue
                        }
                        max={values.discount_type === "percentage" ? 100 : 2000}
                        containerClass="ms-2"
                      />
                    </div>
                    {values.discount_type === "percentage" && (
                      <div className="me-5">
                        <div className="color_707070 d-flex fs_14 mb-1 fw_400">
                          Max. Discount Amount
                        </div>
                        <span className="color_030304  fw_500">
                          {" "}
                          {localStorage?.getItem("currency") ?? "₹"} {maxDiscountValue?.[0]} - {localStorage?.getItem("currency") ?? "₹"}{maxDiscountValue?.[1]}
                        </span>
                        <MultiRangeSlider
                          containerClass="ms-2"
                          setValues={setMaxDiscountValue}
                          values={maxDiscountValue}
                        />
                      </div>
                    )}
                    <div className="me-5">
                      <div className="color_707070 d-flex fs_14 mb-1 fw_400">
                        Min. Order Value
                      </div>
                      <span className="color_030304  fw_500">
                        {" "}
                        {localStorage?.getItem("currency") ?? "₹"} {minOrderValue?.[0]} - {localStorage?.getItem("currency") ?? "₹"}{minOrderValue?.[1]}
                      </span>
                      <MultiRangeSlider
                        containerClass="ms-2"
                        setValues={setMinOrderValue}
                        values={minOrderValue}
                      />
                    </div>

                    <RadioButton
                      inputLabelClass="fw_500"
                      labelClass="color_707070 d-flex fs_14 mb-1 fw_400"
                      name="on_all_products"
                      options={discountProdSelectOptns}
                      label="On All Products"
                    />
                  </div>
                </div>
                <div>
                  <hr className="colored-line" />
                  <div className="d-flex justify-content-end gap-3 px-3 pb-3 align-items-center">
                    <ClearButton
                      clearFn={clearFn}
                      type="reset"
                      clearClassName={"px-2 py-2 fs_16 fw_600"}
                    />
                    <ApplyButton
                      type="submit"
                      applyClassName={"px-2 py-2 fs_16 fw_600"}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
