import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AddWithBorderButton, BackButton, SaveButton } from "../buttons/text-button";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import "./modal.css";
import SelectField from "../forms/select-field";
import AddonsItemTable from "../tables/addOns-items-table";
import ErrorMsg from "../forms/error-msg";
export default function CreateAddonModal({ show, modalClose, addonsData, setAddonsData, initialValues, setInitialValues, type = "", editData }) {
  
  const validationSchema = Yup.object().shape({
    "name": Yup.string().required(),
    "type": Yup.string().required(),
    "min": Yup.number()
      .integer("Min must be an integer")
      .test(
        "is-min-required",
        "Min is required",
        function (value) {
          const { type } = this.parent;
          return type === "multiple" ? value != null : true;
        }
      ),
    "max": Yup.number()
      .integer("Max must be an integer")
      .test(
        "is-max-required",
        "Max is required",
        function (value) {
          const { type } = this.parent;
          return type === "multiple" ? value != null : true;
        }
      ),
      productAddOnItems: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Item name is required'),
          price: Yup.number()
            .transform(value => (isNaN(value) ? undefined : value))
            .required('Item price is required'),
        })
      )
      .test(
        "is-array-length-valid",
        `Items should be more than min options`,
        function (value) {
          const { type, min } = this.parent;
          return (type === "multiple" && value.length >= min) || type !== "multiple";
        }
      )
  });
  const onSubmit = (values) => {
    if (type === "edit") {
      setAddonsData((prev) => {
        return prev.map((item, index) => index === editData ? values : item);
      });
    }
    else {
      setAddonsData([...addonsData, values])
    }
    modalClose()
  };
  function resetFn() {
  }
  return (
    <Modal
      show={show}
      onHide={modalClose}
      centered
      dialogClassName={`mx-sm-auto edit_order_refaund_model`}
      contentClassName="rounded-3 w-100"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values, errors }) => {
          
          return (
            <Form>
              <ModalHeader>
                <div className="text-black fw_500 fs_18 d-flex align-items-center gap-2">
                  <img
                    onClick={modalClose}
                    style={{ background: "#F7F7F7", borderRadius: "3px" }}
                    src={BackIcon}
                    alt="back"
                  />
                  <span>{`${type === "edit" ? "Edit" : "Create"} Add-Ons`}</span>
                </div>
                <div className="d-sm-flex g-0 gap-sm-3 gap-2 text-center">
                  <div onClick={resetFn} className="mb-2 mb-sm-0">
                    <BackButton name="UNDO" type="reset" />
                  </div>
                  <div className="mt-2 mt-sm-0">
                    <SaveButton type="submit" />
                  </div>
                </div>
              </ModalHeader>
              <ModalBody className="px-sm-5">
                <div>
                  <InputField
                    placeholder="Enter Add-ons Group name"
                    containerClass=" mt-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Name"
                    name="name"
                    type="text"
                  />
                  <SelectField
                    defaultValue={addonsTypes.find(
                      (item) => item?.value === values?.type
                    )}
                    placeholder={"Select"}
                    label={"Type"}
                    id={"type"}
                    name="type"
                    options={addonsTypes}
                    isFormikControlled={true}
                    errMsgContainerClass="ps-2"
                  />

                  {values?.type === "multiple" &&
                    <div className="d-flex gap-5">
                      <InputField
                        placeholder="Enter min. quantity"
                        containerClass=" mt-3 position-relative "
                        errMsgContainerClass="ps-2"
                        label="Minimum Option"
                        name="min"
                        type="text"
                        inputClass="no-arrows"
                      />
                      <InputField
                        placeholder="Enter max. quantity"
                        containerClass=" mt-3 position-relative "
                        errMsgContainerClass="ps-2"
                        label="Maximum Option"
                        name="max"
                        type="text"
                        inputClass="no-arrows"
                      />
                    </div>
                  }
                  <div className="d-flex justify-content-between">
                    <span>Items</span>
                    <AddWithBorderButton
                      onClick={() => {
                        setInitialValues({
                          ...values,
                          productAddOnItems: [...values?.productAddOnItems, { name: "", price: null }]
                        })
                      }}
                      addClassName="py-1 mb-2 border-0 bg_color_E5ECFFB2"
                      customStyles={{ borderRadius: "4px" }}
                      name="Add items"
                    />
                  </div>
                  <AddonsItemTable initialValues={initialValues} setInitialValues={setInitialValues} />
                  {
                    errors?.productAddOnItems === "Items should be more than min options" &&
                    <ErrorMsg name={"productAddOnItems"} />
                  }
                </div>
              </ModalBody>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  );
}


const addonsTypes = [
  { label: "Multiple", value: "multiple" },
  { label: "Single", value: "single" },
  { label: "Multiple Counter", value: "multiple_counter" }
]