import React, { useState } from "react";
import "./layouts.css";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import {
  AcceptButton,
  BackButton,
  DeleteButton,
  EditButton,
  MarkAsDefaultButton,
  RejectButton,
  ResetPasswordButton,
  SaveButton,
  UnblockButton,
  UpdateButton,
  isCustomerBlock,
} from "../buttons/text-button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";
import DeleteModal from "../modals/delete-modal";
import usePermissions from "../../helper-functions/hooks/usePermission-checker";
import ToggleSwitch from "../buttons/toggle-button";
import dayjs from "dayjs";

const InnerLayout = ({
  name = "Categories",
  page = "view",
  edit = false,
  blockUnblockShowFn,
  resetFn,
  deleteAction,
  id,
  path,
  loading = false,
  editButtonPath,
  reset = false,
  resetPassFn,
  block = false,
  Unblock = false,
  green_bg,
  rolePermission,
  reload,
  setReload,
  type = "",
  disabled,
  rejectFn,
  acceptFn,
  saveFn = () => { },
  handleOutofStockBtn = () => { },
  handleStatusBtn = () => { },
  activeStatus,
  outOfStockStatus,
  markDefault = false,
  modelShow = () => { },
  details,
  updateFn,
  acceptReturnFn,
  rejectRetunFn
}) => {
  const { canWrite } = usePermissions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const deleteCloseFn = () => setShow(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");

  function deleteFn() {
    dispatch(deleteAction(id, onSuccess, onError));
  }
  const onSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    deleteCloseFn();
    navigate(path);
  };
  const onError = (data) => {
    errorToast(data.data.message);
    deleteCloseFn();
    navigate(path);
  };

  return (
    <>
      <div className="sticky-top bg-white inner_layout_container row g-0 justify-content-between align-items-center py-3 gap-1 mt-lg-0 mt-3">
        <div
          style={{ width: "fit-Content" }}
          className="d-flex align-items-center gap-sm-2 gap-1 ms-sm-3 ms-2 fs_16 fw_500 font_montserrat color_030304 col-"
        >
          <NavLink
            onClick={currentPage ? undefined : () => navigate(-1)}
            to={currentPage ? path : undefined}
            state={{
              page: currentPage,
            }}
          >
            <div style={{ width: "25px" }}>
              <img src={BackIcon} alt="back" width={25} height={25} />
            </div>
          </NavLink>
          {name} {type === "order" && <div className="bg_color_E5ECFFB2 px-3 py-2 rounded-2 ms-2 fs_14 color_030304 fw_600" >{dayjs(details?.createdAt).format("D MMMM YYYY") + " | " + dayjs(details?.createdAt).format("h:mm A")}</div>}
        </div>
        <div
          style={{ width: "fit-Content" }}
          className="me-xl-5 me-3 pe-xl-5 gap-sm-3 gap-2 row g-0 col- mx-3 row g-0 align-items-center"
        >
          {block && canWrite(rolePermission) && (
            <div onClick={blockUnblockShowFn} style={{ width: "fit-Content" }}>
              <DeleteButton
                deleteClassName="fs_14"
                page=""
                block={block}
                name="BLOCK"
              />
            </div>
          )}
          {page === "view" && canWrite(rolePermission) && (rolePermission === "products" || rolePermission === "brands" || rolePermission === "categories") && (
            <>
              {rolePermission !== "brands" && rolePermission !== "categories" && <div style={{ width: "fit-Content" }}>
                <ToggleSwitch
                  name={`out_of_stock`}
                  label={"Out of Stock"}
                  handleToggleBtnChange={() => handleOutofStockBtn()}
                  id={"out_of_stock"}
                  value={outOfStockStatus}
                  containerClassName={`bg_color_F9F9FC px-2`}
                />
              </div>}


              <div style={{ width: "fit-Content" }}>
                <ToggleSwitch
                  name={`active_status`}
                  label={activeStatus ? "Active" : "Inactive"}
                  handleToggleBtnChange={handleStatusBtn}
                  // handleToggleBtnChange={() => handleStatusBtn()}
                  id={"active_status"}
                  value={activeStatus}
                  containerClassName={`bg_color_F9F9FC px-2`}
                />
              </div>
            </>
          )}
          {Unblock && canWrite(rolePermission) && (
            <div onClick={blockUnblockShowFn} style={{ width: "fit-Content" }}>
              <UnblockButton green_bg={green_bg} />
            </div>
          )}
          {markDefault && canWrite(rolePermission) && (
            <MarkAsDefaultButton modelShow={modelShow} />
          )}
          {canWrite(rolePermission) && type != "order" &&
            (page === "view" ? (
              <div
                onClick={() => setShow(true)}
                style={{ width: "fit-Content" }}
              >
                <DeleteButton page="" deleteClassName="fs_14" />
              </div>
            ) : page === "create" ? (
              <div onClick={resetFn} style={{ width: "fit-Content" }}>
                <BackButton type="reset" name="CLEAR" backClassName="fs_14" />
              </div>
            ) : (
              edit && (
                <div onClick={resetFn} style={{ width: "fit-Content" }}>
                  <BackButton
                    disabled={disabled}
                    type="reset"
                    name="UNDO"
                    backClassName="fs_14"
                  />
                </div>
              )
            ))}
          {canWrite(rolePermission) && type != "order" &&
            (page === "view" ? (
              <div
                style={{ width: "fit-Content" }}
                onClick={() => {
                  navigate(editButtonPath);
                }}
              >
                <EditButton loading={loading} editClassName="fs_14 py-2 px-2" />
              </div>
            ) : (
              <div style={{ width: "fit-Content" }}>
                <SaveButton
                  disabled={disabled}
                  type="submit"
                  backClassName="fs_14"
                  onClick={saveFn}
                />
              </div>
            ))}
          {reset && canWrite(rolePermission) && (
            <div onClick={() => resetPassFn()} style={{ width: "fit-Content" }}>
              {" "}
              <ResetPasswordButton />
            </div>
          )}
          {
            type === "order" && canWrite(rolePermission) &&
            <>
              {
                (details?.orders_status != "return_requested" || details?.return_status === "accepted" || details?.return_status === "rejected") &&
                <>
                  {
                    (details?.orders_status === "received" || details?.orders_status === "dispatched" || details?.orders_status === "accepted") &&
                    <RejectButton rejectFn={rejectFn} />
                  }
                  {
                    details?.orders_status === "received" &&
                    <AcceptButton acceptFn={acceptFn} />
                  }
                  {
                    !(details?.orders_status === "received") &&
                    <UpdateButton updateFn={updateFn} />
                  }
                </>
              }
              {
                details?.orders_status === "return_requested" && details?.return_status != "accepted" && details?.return_status != "rejected" &&
                <>
                  <RejectButton name="Reject Return" rejectFn={rejectRetunFn} />
                  <AcceptButton name="Accept Return" acceptFn={acceptReturnFn} />
                </>
              }
            </>
          }
        </div>
      </div>
      <DeleteModal
        show={show}
        modalClose={deleteCloseFn}
        deleteData={id}
        deleteFn={() => deleteFn(id)}
        text={`Are you sure, you want to delete this ${type} permanently?`}
      />
    </>
  );
};

export default InnerLayout;
