import React, { useEffect, useMemo, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import InputField from "../../components/ui/forms/input-field";
import SelectField from "../../components/ui/forms/select-field";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import {
  AddressCreateAction,
  AddressTableAction,
  customerListDropdownAction,
} from "../../redux/actions/address-action";
import { Country } from "country-state-city";
import { State } from "country-state-city";
import CountryCode from "../../components/ui/forms/country-code";
import { numbRegex } from "../../components/helper-functions/helper/regex";
import { customerViewAction } from "../../redux/actions/customer-action";
import MarkAsDefaultModel from "../../components/ui/modals/mark-as-default-model";
import scriptLoader from "scriptjs";
import PlacesAutocomplete from "react-places-autocomplete";
import AddressLocation from "../../components/ui/address/address-location";
import AddressLeaflet from "../../components/ui/address/address-leaflet";
import NotificationComponent from "../../components/notificationComponent";

const AddressCreate = () => {
  const navigate = useNavigate();
  const router = useLocation();
  const [imgUrl, setImageUrl] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addressData, setAddressData] = useState();
  const [getCustomerId, setCustomerId] = useState("");
  const [custAddressData, setCustAddressData] = useState();
  const [makeDefaultAddress, setMakeDefalutAddress] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const isCustAddressFalse =
    custAddressData
      ?.filter((item) => !item?.id.includes(getCustomerId))
      ?.every((item) => item?.is_default === false) ?? false;

  useEffect(() => {
    dispatch(customerListDropdownAction(OnFetchSuccess, onFetchError));
    dispatch(
      AddressTableAction(
        {
          page: "",
          size: "",
          search: "",
          // sort: ""
        },
        {
          filteredValue: {
            customer_id: [],
            country: "",
            state: "",
            pincode: "",
            is_default: "",
          },
        },
        onAddressSuccess,
        onAddressError
      )
    );
  }, []);

  const OnFetchSuccess = (data) => {
    setAddressList(
      data.data.map((item) => {
        return { label: item.id + " - " + item.name, value: item.id };
      })
    );
  };
  const onFetchError = () => {
    console.log("failed to load parent category list");
  };

  const onAddressSuccess = (data) => {
    setAddressData(data?.data?.address);
  };
  const onAddressError = () => {};
  const initialValues = {
    customer_id: "",
    recipient_name: "",
    lat: 12.2958104,
    lng: 76.63938,
    address_name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    pin_code: "",
    state: "",
    country: "IN",
    phone_number: "",
    country_code: "91",
    is_default:
      custAddressData?.length !== 0 && !isCustAddressFalse
        ? makeDefaultAddress
        : false,
  };
  const validationSchema = Yup.object().shape({
    customer_id: Yup.string().trim().required("Please Select Customer Id"),
    recipient_name: Yup.string().trim().required("Recipient Name is required"),
    address_name: Yup.string().trim().required("Address Name is required"),
    address_line1: Yup.string().trim().required("Address Line1 is required"),
    address_line2: Yup.string().trim(),
    city: Yup.string().trim().required("City is required"),
    pin_code: Yup.string()
      .matches(numbRegex, "Enter Valid Pin Code")
      .required("Pin Code is required"),
    state: Yup.string().trim().required("Please Select State"),
    country: Yup.string().trim().required("Please Select Country"),
    phone_number: Yup.number().required("Enter phone number"),
  });
  const onSubmit = (values) => {
    dispatch(
      AddressCreateAction(
        {
          customer_id: values?.customer_id,
          category_image: imgUrl?.data,
          recipient_name: values.recipient_name,
          address_name: values?.address_name,
          address_line1: values?.address_line1,
          address_line2: values?.address_line2,
          city: values?.city,
          pin_code: values?.pin_code,
          state: values?.state,
          country:
            countriesList.find((item) => item.value === values?.country)
              ?.label ?? "",
          is_default:
            custAddressData?.length !== 0 && !isCustAddressFalse
              ? makeDefaultAddress
              : values?.is_default,
          phone_number: values?.phone_number,
          lat: values?.lat,
          lng: values?.lng,
          country_code: values?.country_code,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/address`);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };

  function resetFn() {
    setImageUrl("");
  }
  const countriesList = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: `${country.isoCode}`,
      })),
    []
  );

  useEffect(() => {
    if (getCustomerId) {
      dispatch(
        customerViewAction(
          { id: getCustomerId, page: 1, size: 10, search: "" },
          onFectCustomerSuccess,
          onFetchCustomerError
        )
      );
    }
  }, [getCustomerId]);
  const onFectCustomerSuccess = (data) => {
    setCustAddressData(data?.data?.fetchCustomer?.Addresses);
  };
  const onFetchCustomerError = () => {};
  const addressConfirmModel = custAddressData?.some(
    (item) => item?.is_default === true
  );

  const getDefaultValue = (value) => {
    if (value) {
      setMakeDefalutAddress(true);
    }
  };
  const handelDefaultAddress = (e) => {
    if (e.target.checked) {
      if (addressConfirmModel) {
        setShow(true);
      }
    } else {
      setMakeDefalutAddress(false);
    }
  };
  let previousValue = null;
  return (
    <div>
      <NotificationComponent />
      <MarkAsDefaultModel
        show={show}
        modalClose={() => setShow(false)}
        getDefaultValue={getDefaultValue}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, field, handleChange }) => {
          setCustomerId(values?.customer_id);
          console.log(values, "addcreate");
          return (
            <Form>
              <InnerLayout
                name="New Address"
                page="create"
                edit={true}
                onSubmit={onSubmit}
                resetFn={resetFn}
                rolePermission={"addresses"}
              />
              <div
                className="ms-5 pe-4 pe-md-0 mt-5"
                style={{ maxWidth: "40rem" }}
              >
                <div>
                  <SelectField
                    labelClass="text-black fw_500 fs_14"
                    placeholder={"Select Customer"}
                    label={"Customer Id"}
                    id={"customer_id"}
                    name="customer_id"
                    errMsgContainerClass="ps-2"
                    isFormikControlled={true}
                    options={addressList}
                    handleSelected={(selectedOption) => {
                      if (custAddressData) {
                        setFieldValue("is_default", makeDefaultAddress);
                      } else {
                        setFieldValue("is_default", values?.is_default);
                      }
                    }}
                  />
                </div>
                <div>
                  <InputField
                    placeholder="Enter recipient name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Recipient Name"
                    name="recipient_name"
                    type="text"
                  />
                </div>
                <div>
                  <InputField
                    placeholder="Enter address name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Address Name"
                    name="address_name"
                    type="text"
                  />
                </div>

                {localStorage?.getItem("live_map_enable") === "true" && (
                  // <AddressLocation
                  //   setFieldValue={setFieldValue}
                  //   lat={12.2958104}
                  //   lng={76.639380}
                  // />
                  <AddressLeaflet
                    setFieldValue={setFieldValue}
                    lat={12.2958104}
                    lng={76.63938}
                  />
                )}
                <div>
                  <InputField
                    placeholder="Address line1"
                    containerClass="mb-0 position-relative mt-3"
                    errMsgContainerClass="ps-2 mb-1"
                    label="Address"
                    name="address_line1"
                    type="text"
                  />
                  <InputField
                    placeholder="Address line2"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label=""
                    name="address_line2"
                    type="text"
                  />
                </div>
                <div>
                  <InputField
                    placeholder="Enter city name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="City"
                    name="city"
                    type="text"
                  />
                </div>
                <div>
                  <InputField
                    placeholder="Enter Pin Code"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Pin Code"
                    name="pin_code"
                    type="text"
                  />
                </div>
                <SelectField
                  labelClass="text-black fw_500 fs_14"
                  placeholder={"Select Country"}
                  label={"Country"}
                  id={"country"}
                  name="country"
                  errMsgContainerClass="ps-2"
                  options={countriesList}
                  handleSelected={(selectedOption) => {
                    if (selectedOption.value !== previousValue) {
                      setFieldValue("state", null);
                    }
                    previousValue = selectedOption.value;
                  }}
                />
                <SelectField
                  isDisabled={!values.country}
                  labelClass="text-black fw_500 fs_14"
                  placeholder={"Select State"}
                  label={"State"}
                  id={"state"}
                  name="state"
                  errMsgContainerClass="ps-2"
                  isFormikControlled={true}
                  options={State.getStatesOfCountry(values.country).map(
                    (state) => ({
                      label: state.name,
                      value: `${state.name}`,
                    })
                  )}
                />
                <label className="color_030304 fw_500 fs_14">
                  Phone Number
                </label>
                <div className="d-flex">
                  <span className="">
                    <CountryCode />
                  </span>
                  <span className="ms-2 w-100">
                    <InputField
                      placeholder="Enter phone number"
                      containerClass=" position-relative"
                      errMsgContainerClass="ps-2"
                      label=""
                      name="phone_number"
                      type="text"
                    />
                  </span>
                </div>
                {/* {custAddressData?.length === 0 ? (
                  <div className="d-flex align-items-center gap-2 mb-4">
                    <input
                      {...field}
                      className="form-check-input mb-1"
                      style={{ width: "15px", height: "15px" }}
                      name="is_default"
                      type="checkbox"
                      checked={values?.is_default}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label className="fw_500 fs_14">Default Address</label>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-2 mb-4">
                    <input
                      {...field}
                      className="form-check-input mb-1"
                      style={{ width: "15px", height: "15px" }}
                      name="is_default"
                      type="checkbox"
                      checked={makeDefaultAddress}
                      onChange={(e) => {
                        handelDefaultAddress(e);
                      }}
                    />
                    <label className="fw_500 fs_14">Default Address</label>
                  </div>
                )} */}
                <div className="d-flex align-items-center gap-2 mb-4">
                  <input
                    {...field}
                    className="form-check-input mb-1"
                    style={{ width: "15px", height: "15px" }}
                    name="is_default"
                    type="checkbox"
                    checked={
                      custAddressData?.length !== 0 && !isCustAddressFalse
                        ? makeDefaultAddress
                        : values?.is_default
                    }
                    onChange={(e) => {
                      if (
                        custAddressData?.length !== 0 &&
                        !isCustAddressFalse
                      ) {
                        handelDefaultAddress(e);
                      } else {
                        handleChange(e);
                      }
                    }}
                  />
                  <label className="fw_500 fs_14">Default Address</label>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddressCreate;
