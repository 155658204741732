import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../../modals/modal.css";
import {
  AddWithBorderButton,
  BackButton,
  SaveButton,
} from "../../buttons/text-button";
import SelectField from "../../forms/select-field";
import {
  AddressViewAction,
} from "../../../../redux/actions/address-action";
import { useDispatch } from "react-redux";
import InputField from "../../forms/input-field";
import { Country } from "country-state-city";
import { State } from "country-state-city";
import { numbRegex } from "../../../helper-functions/helper/regex";
import { phoneNumberRegex } from "../../../helper-functions/helper/regex";
import CountryCode from "../../forms/country-code";
import { customerViewAction } from "../../../../redux/actions/customer-action";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import {
  ordersEditAction,
  ordersReturnEditAction,
} from "../../../../redux/actions/orders-action";
import MarkAsDefaultModel from "../../modals/mark-as-default-model";
import { renameKey } from "../../../helper-functions/helper/helper";
import AddressLocation from "../../address/address-location";
import AddressLeaflet from "../../../../components/ui/address/address-leaflet";
export default function EditAddress({
  show,
  modalClose,
  orderDetails,
  reload,
  setReload,
  type,
}) {
  const [formVAlues, setFormVAlues] = useState({});
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [address, setAddress] = useState(
    type === "pickup"
      ? orderDetails?.return_pickup_address
      : orderDetails?.address
  );
  const dispatch = useDispatch();
  const [addAddress, setAddAddress] = useState(false);
  const [customerId, setCustomerId] = useState(orderDetails?.customer_id);
  const [custAddressId, setCustAddressid] = useState([]);
  const [addressId, setAddressId] = useState();
  const [cusAllAddress, setCusAllAddress] = useState([]);
  useEffect(() => {
    if (customerId) {
      dispatch(
        customerViewAction(
          { id: customerId, page: 1, size: 15, search: "" },
          onCustomerSuccess,
          onCustomerError
        )
      );
    }
  }, [customerId]);

  const onCustomerSuccess = (data) => {
    setCusAllAddress(data?.data?.fetchCustomer?.Addresses);
    setCustAddressid(
      data?.data?.fetchCustomer?.Addresses?.map((item) => {
        return {
          label: item?.id + " - " + item?.address_name,
          value: item?.id,
        };
      })
    );
  };
  const onCustomerError = () => { };
  const addressConfirmModel = cusAllAddress
    ?.filter((item) => item?.id !== formVAlues?.address_id)
    ?.some((item) => item?.is_default === true);
  const handleChangeFn = (id) => {
    dispatch(AddressViewAction(id, onSuccess, onError));
  };


  useEffect(() => {
    dispatch(AddressViewAction(type === "pickup" ? orderDetails?.return_pickup_address?.id : orderDetails?.address_id, onSuccess, onError));
  }, [show])

  const onSuccess = (data) => {
    setAddress(data?.data);
  };
  const onError = (data) => { };


  const countriesList = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: `${country.isoCode}`,
      })),
    []
  );
  let previousValue = null;
  const initialValues = {
    // customer_id: customerId ?? "",
    address_id: address?.id ?? address?.address_id ?? "",
    recipient_name: address?.recipient_name ?? "",
    address_name: address?.address_name ?? "",
    lat: address?.lat ?? 12.2958104,
    lng: address?.lng ?? 76.639380,
    address_line1: address?.address_line1 ?? "",
    address_line2: address?.address_line2 ?? "",
    city: address?.city ?? "",
    pincode: address?.pin_code ?? address?.pincode ?? "",
    country: address?.country ?? "IN",
    state: address?.state ?? "",
    country_code: address?.country_code ?? "91",
    phone_number: address?.phone_number ?? "",
    is_default: address?.is_default ?? false,
  };
  const validationSchema = Yup.object().shape({
    address_id: Yup.string()
      .trim()
      .test({
        name: "conditionalRequired",
        test: function (value) {
          const addAddress = this.resolve(Yup.ref("addAddress"));
          return !addAddress || (addAddress && value);
        },
        message: "Please Select Customer Id",
      }),
    recipient_name: Yup.string().trim().required("Please enter recipient name"),
    address_name: Yup.string().trim().required("Please enter address name"),
    address_line1: Yup.string().trim().required("Please enter address 1"),
    address_line2: Yup.string().trim().required("Please enter address 2"),
    city: Yup.string().trim().required("Please enter city"),
    pincode: Yup.string()
      .matches(numbRegex, "Enter Valid Pin Code")
      .required("Pin Code is required"),
    state: Yup.string().trim().required("Please Select State"),
    country: Yup.string().trim().required("Please Select Country"),
    phone_number: Yup.string()
      .matches(phoneNumberRegex, "Invalid number")
      .required("Enter phone number"),
  });
  const onSubmit = (values) => {


    console.log(values, "asdasda");
    const { address_id, ...allvalues } = values;
    console.log(allvalues, "allvaluesallvalues");
    if (type === "pickup") {
      dispatch(
        ordersReturnEditAction(
          {
            order_id: orderDetails?.id,
            return_pickup_address: values,
          },
          onSuccessEdit,
          onErrorEdit
        )
      );
    } else {
      dispatch(
        ordersEditAction(
          {
            order_id: orderDetails?.id,
            address: addAddress ? allvalues : renameKey(values, "address_id", "id"),
          },
          onSuccessEdit,
          onErrorEdit
        )
      );
    }
  };
  const onSuccessEdit = (data) => {
    setReload(!reload);
    modalClose();
    successToast("Order Updated SuccessFully");
  };
  const onErrorEdit = (data) => {
    errorToast(data?.data?.message);
  };

  console.log(address?.id === formVAlues?.address_id, "datatataatatatat");
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="edit_address_model"
        contentClassName="rounded-3 mx-2 mx-xl-0"
      // backdrop="static"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            field,
            setFieldValue,
          }) => {
            setCustomerId(values?.customer_id);
            setAddressId(values?.address_id);
            setFormVAlues(values);
            console.log(errors, "errorserrors");
            console.log(values?.address_id, "erro7676767rserrors");
            return (
              <Form>
                <MarkAsDefaultModel
                  show={confirmationShow}
                  modalClose={() => setConfirmationShow(false)}
                  getDefaultValue={(value) => {
                    if (value) {
                      setFieldValue("is_default", true);
                    }
                  }}
                />
                <ModalHeader>
                  <div>
                    <img onClick={modalClose} src={BackIcon} />
                    <span className="ms-2 fs_16 fw_500">Edit Address</span>
                  </div>
                  <div className="d-flex gap-3">
                    <BackButton
                      // disabled={valuesCompareFn(values)}
                      type="reset"
                      name="UNDO"
                      backClassName="fs_14"
                    />

                    <SaveButton
                      // disabled={valuesCompareFn(values)}
                      type="submit"
                      backClassName="fs_14"
                    //   onClick={saveFn}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="row">
                    <div className="col-md-6 col-12 pe-lg-4">
                      <div className="mb-3">
                        <label className=" text-black fw_500 fw_400">
                          Customer Id
                        </label>
                        <p className="color_9E9E9E fw_400">
                          {orderDetails?.customer_id}
                        </p>
                      </div>
                      <AddWithBorderButton
                        onClick={() => {
                          Object.keys(values).forEach((field) => {
                            if (field === "is_default") {
                              setFieldValue("is_default", false);
                            } else {
                              setFieldValue(field, "");
                            }
                            setAddAddress(true);
                          });
                        }}
                        addClassName="py-1 mb-2"
                        customStyles={{ borderRadius: "4px" }}
                        name="Add NEW ADDRESS"
                      />

                      {!addAddress && (
                        <SelectField
                          labelClass="text-black fw_500 fs_14"
                          placeholder={"Select Customer"}
                          label={"Address Id"}
                          id={"address_id"}
                          name="address_id"
                          errMsgContainerClass="ps-2"
                          charLimit="50"
                          isFormikControlled={true}
                          options={custAddressId?.filter((item) => item?.value != values?.address_id)}
                          handleSelected={(selectedOption) => {
                            handleChangeFn(selectedOption?.value);
                            let prevAddressId = null;
                            if (selectedOption.value !== prevAddressId) {
                              Object.keys(values).forEach((field) => {
                                if (
                                  field !== "customer_id" &&
                                  field !== "address_id"
                                ) {
                                  setFieldValue(field, "");
                                  // setCustAddressDetails("");
                                }
                              });
                            }
                            prevAddressId = selectedOption.value;

                          }}
                        />
                      )}

                      <InputField
                        id={"recipient_name"}
                        label={"Recipient Name"}
                        placeholder={"Enter Recipient Name"}
                        name={"recipient_name"}
                      />
                      <InputField
                        id={"address_name"}
                        label={"Address Name"}
                        placeholder={"Enter Address Name"}
                        name={"address_name"}
                      />
   
                      <div className="mb-2">
                        <AddressLeaflet
                          setFieldValue={setFieldValue}
                          lat={addAddress ? 12.2958104 : values?.lat}
                          lng={addAddress ? 76.639380 : values?.lng}
                        />
                      </div>
                      <InputField
                        id={"address_line1"}
                        label={"Address"}
                        placeholder={"Enter Address line 1"}
                        name={"address_line1"}
                        errMsgContainerClass="d-none"
                      />

                      <InputField
                        id={"address_line2"}
                        label={false}
                        placeholder={"Enter Address line 2"}
                        name={"address_line2"}
                        errMsgContainerClass="d-none"
                        containerClass="position-relative"
                      />
                      {((errors?.address_line1 && touched?.address_line1) ||
                        (errors?.address_line2 && touched?.address_line2)) && (
                          <span className="color_F24444 fs_12 ms-1">
                            Address line 1 & 2 is Required
                          </span>
                        )}
                    </div>

                    <div className="col-md-6 col-12 pe-lg-4">
                      <InputField
                        id={"city"}
                        label={"City"}
                        placeholder={"Enter City"}
                        name={"city"}
                      />
                      <InputField
                        id={"pincode"}
                        label={"Pin Code"}
                        placeholder={"Enter Pin Code"}
                        name={"pincode"}
                      />
                      <SelectField
                        labelClass="text-black fw_500 fs_14"
                        placeholder={"Select Country"}
                        label={"Country"}
                        id={"country"}
                        name="country"
                        errMsgContainerClass="ps-2"
                        options={countriesList}
                        handleSelected={(selectedOption) => {
                          if (selectedOption.value !== previousValue) {
                            setFieldValue("state", null);
                          }
                          previousValue = selectedOption.value;
                        }}
                      />
                      <SelectField
                        isDisabled={!values.country}
                        labelClass="text-black fw_500 fs_14"
                        placeholder={"Select State"}
                        label={"State"}
                        id={"state"}
                        name="state"
                        errMsgContainerClass="ps-2"
                        isFormikControlled={true}
                        options={State.getStatesOfCountry(values.country).map(
                          (state) => ({
                            label: state.name,
                            value: `${state.name}`,
                          })
                        )}
                      />
                      <label className="color_030304 fw_500 fs_14">
                        Phone Number
                      </label>
                      <div className="d-flex">
                        <span className="">
                          <CountryCode />
                        </span>
                        <span className="ms-2 w-100">
                          <InputField
                            placeholder="Enter phone number"
                            containerClass=" position-relative"
                            errMsgContainerClass="ps-2"
                            label=""
                            name="phone_number"
                            type="text"
                          />
                        </span>
                      </div>
                      <div className="mt-2 mb-3 d-flex align-items-center">
                        <span>
                          <input
                            {...field}
                            style={{ transform: "scale(1.1)" }}
                            type="checkbox"
                            id="myCheckbox"
                            name="is_default"
                            className="custom-checkbox form-check-input"
                            checked={values?.is_default}
                            onChange={(e) => {
                              if (!values.is_default && addressConfirmModel) {
                                setConfirmationShow(true);
                              } else {
                                handleChange(e);
                              }
                            }}
                          />
                        </span>
                        <span className="ms-2 text-black fw_400 fs_14">
                          Default Address
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
