import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../../modals/modal.css";
import { CancelButton } from "../../buttons/text-button";
import TextAreaField from "../../forms/textarea-field";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import { ordersEditAction, ordersRejectAction } from "../../../../redux/actions/orders-action";
import { useDispatch } from "react-redux";
import EditProduct from "./edit-product";
import { variationFunction } from "../../../helper-functions/helper/date";

export default function OrderRejection({ show, modalClose, id, reload, setReload, product, setProduct,
  neededProduct,
  setNeededProduct,
  orderDetails,
  setOrderRejectShow
}) {
  const dispatch = useDispatch()
  const [editProductShow, setEditProductShow] = useState(false);
  const [formValues, setFormValues] = useState({})
  const [selectedRejectType, setSelectedRejectType] =
    useState("Completely Reject");
  const initialValues = {
    rejection_type: selectedRejectType,
    short_description: ""

  };
  const validationSchema = Yup.object().shape({
    short_description: Yup.string().required("Reason is Required!")
  });

  const onSubmit = (values) => {
    if (values?.rejection_type === "Partially Reject") {
      setEditProductShow(true)
      setOrderRejectShow(false)
    }
    else {
      dispatch(
        ordersRejectAction(
          {
            "order_id": id,
            "orders_status": "rejected",
            reject_reason: values?.short_description
          },
          onEditSuccess,
          onEditError
        )
      )
    }
  };

  const onEditSuccess = (data) => {
    setReload(!reload)
    successToast("Order Updated SuccessFully")
    modalClose()
  }
  const onEditError = (data) => {
    errorToast(data?.data?.message)
  }

  const handleRejectType = (item) => {
    setSelectedRejectType(item);
  };

  function variantFn(item) {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return item?.variation?.[0]?.variants?.map((item) => {
        return { value: item };
      })
    }
    else if (item?.variation && !Array.isArray(item?.variation)) {
      return item?.variation?.variants?.map((item) => {
        console.log({ value: item });
        return { value: item };
      })
    }
    else return ["--"]
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="add_delivery_details_model"
        contentClassName="rounded-3"
      // backdrop="static"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, field, error }) => {
            setFormValues(values)
            return (
              <Form>
                <ModalHeader>
                  <div className="ms-2">
                    <img src={BackIcon} />
                    <span className="ms-2 fs_16 fw_500">Order Rejection</span>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="ms-4">
                    <label className="mt-2 color_9E9E9E fw_400 fs_15">
                      Select Rejection Type
                    </label>
                    {["Completely Reject", "Partially Reject"].map(
                      (item, index) => {
                        return (
                          <div className="d-flex align-items-center border_1px_e5ecff border_radius_7px mb-3 p-2 me-4">
                            <input
                              {...field}
                              value={item}
                              name="rejection_type"
                              type="radio"
                              style={{
                                width: "17px",
                                height: "17px",
                              }}
                              checked={selectedRejectType === item}
                              onChange={(e) => handleRejectType(item)}
                            />
                            <span className="fs_15 ms-2 color_030304 fw_400">
                              {item}
                            </span>
                          </div>
                        );
                      }
                    )}
                    <TextAreaField
                      label="Short Description"
                      placeholder="Enter small description"
                      name="short_description"
                      id={"short_description"}
                      containerClass="me-4"
                    />
                    <div className="d-flex justify-content-end gap-3 me-4 mb-4">
                      <span onClick={modalClose}>
                        <CancelButton cancelClassName="px-2" />
                      </span>

                      <CancelButton
                        name="REJECT"
                        cancelClassName="px-2"
                        bgColor="#F24444"
                        textColor="text-white"
                        type="submit"
                      />
                    </div>
                  </div>
                </ModalBody>

              </Form>
            );
          }}
        </Formik>
      </Modal>
      <EditProduct
        rejectReason={formValues?.short_description}
        reload={reload}
        setReload={setReload}
        id={id}
        setProduct={setProduct}
        product={product}
        orderDetails={orderDetails}
        show={editProductShow}
        popupClose={() => setEditProductShow(false)}
        neededProduct={neededProduct}
        setNeededProduct={setNeededProduct}
      />
    </div>
  );
}
export const orderRejecion = [
  { lable: "Completely Reject", value: "completely_reject" },
  { lable: "Partially Reject", value: "partially_reject" },
];
const authenticationMethod = [
  { label: "Login with Email Address & Password", value: "login_with_email" },
  { label: "Login with Mobile Number & OTP", value: "login_with_phone" },
];

const tableProductListHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "Product ID", value: "product_id" },
  { label: "Product Name", value: "product_name" },
  { label: "Variation", value: "variation" },
  { label: "QTY", value: "qty" },
  { label: "Price", value: "price" },
  { label: "Discounted Price", value: "discounted_price" },
  { label: "Total Price", value: "total_price" },
  { label: "Actions", value: "actions" },
];
