import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik, } from "formik";
import * as Yup from "yup";
import InputField from "../../forms/input-field";
import { passwordRegex } from "../../../helper-functions/helper/regex";
import usePermissions from "../../../helper-functions/hooks/usePermission-checker";
import { BackButton, SaveButton } from "../../buttons/text-button";
import SelectField from "../../forms/select-field";
import { Country } from "country-state-city";
import RadioButton from "../../forms/radio-button";
import ErrorMsg from "../../forms/error-msg";
import { SettingsToggle } from "../../buttons/toggle-button";
import isEqual from "lodash.isequal";
import { useDispatch } from "react-redux";
import {
  taxCreateAction,
  taxEditAction,
  taxViewAction,
} from "../../../../redux/actions/configuration-action";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";
const Tax = ({ closeSection }) => {
  const dispatch = useDispatch();
  const { canWrite } = usePermissions();
  const [taxData, setTaxData] = useState();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(taxViewAction(onFetchSuccess, onFetchError));
  }, [reload]);
  const onFetchSuccess = (data) => {
    setLoading(false);
    setTaxData(data?.data);
  };
  console.log(taxData, "taxdata");
  const onFetchError = (data) => {
    setLoading(false);
  };
  const initialValues = {
    default_tax_rate: taxData?.default_tax_rate ?? "",
    country: taxData?.country ?? "",
    tax_with_product: taxData?.tax_with_product ?? "inclusive_tax",
    gst_numbers: taxData?.gst_numbers ?? "",
    address_of_gst: {
      address_line1: taxData?.address_of_gst?.address_line1 ?? "",
      address_line2: taxData?.address_of_gst?.address_line2 ?? "",
      address_line3: taxData?.address_of_gst?.address_line3 ?? "",
    },
    city: taxData?.city ?? "",
    state: taxData?.state ?? "",
    enable_disable: taxData?.enable_disable ?? false,
  };
  const validationSchema = Yup.object().shape({
    default_tax_rate: Yup.number()
      .typeError("Invalid number")
      .required("Default Tax Rate is required"),
    country: Yup.string().trim().required("Please Select Country"),
    gst_numbers: Yup.string()
      .trim()
      .test("country", "Required", function (value) {
        const country = this.options.context.country;
        if (country === "India" || country === "india") {
          return value;
        } else {
          return true;
        }
      }),

    address_of_gst: Yup.object().shape({
      address_line1: Yup.string()
        .trim()
        .test("country", "Required", function (value) {
          const country = this.options.context.country;
          if (country === "India" || country === "india") {
            return value;
          } else {
            return true;
          }
        }),
      address_line2: Yup.string()
        .trim()
        ,
      address_line3: Yup.string()
        .trim()
        ,
    }),
    city: Yup.string().test("country", "Required", function (value) {
      const country = this.options.context.country;
      if (country === "India" || country === "india") {
        return value;
      } else {
        return true;
      }
    }),
    state: Yup.string().test("country", "Required", function (value) {
      const country = this.options.context.country;
      if (country === "India" || country === "india") {
        return value;
      } else {
        return true;
      }
    }),
  });

  const onSubmit = (values, action) => {
    dispatch(
      taxCreateAction(
        {
          tax_id: taxData?.id,
          enable_disable: values?.enable_disable,
          default_tax_rate: values?.default_tax_rate,
          country: values?.country,
          tax_with_product: values?.tax_with_product,
          gst_numbers: values?.gst_numbers,
          address_of_gst: {
            address_line1: values?.address_of_gst?.address_line1,
            address_line2: values?.address_of_gst?.address_line2,
            address_line3: values?.address_of_gst?.address_line3,
          },
          city: values?.city,
          state: values?.state,
        },
        onCreateSuccess,
        onCreateError
      )
    );
  };
  const onCreateSuccess = (data) => {
    setReload(!reload)
    successToast(data?.message);
  };
  const onCreateError = (data) => {
    errorToast(data?.data?.message);
  };
  const countriesList = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: `${country.name}`,
      })),
    []
  );
  const taxwithProduct = [
    { label: "Inclusive tax", value: "inclusive_tax" },
    { label: "Exclusive tax", value: "exclusive_tax" },
  ];

  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange }) => {
            return (
              <Form className="">
                <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                  <div
                    className="mb-0 px-xxl-4 d-flex align-items-center"
                    style={{ width: "fit-Content" }}
                  >
                    <span>
                      <img
                        className="d-inline-block d-md-none"
                        onClick={closeSection}
                        src={backIcon}
                      />
                    </span>
                    <span className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat ms-2">
                      Tax
                    </span>
                  </div>
                  <div
                    className="me-xxl-5 pe-xxl-5"
                    style={{ width: "fit-Content" }}
                  >
                    {canWrite("configuration") && (
                      <div className="d-flex gap-3">
                        <BackButton
                          disabled={valuesCompareFn(values)}
                          type="reset"
                          name="UNDO"
                          backClassName="fs_14"
                        />

                        <SaveButton
                          disabled={valuesCompareFn(values)}
                          type="submit"
                          backClassName="fs_14"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="mt-5 ms-xl-5 me-xl-5 ms-4 me-4"
                  style={{ maxWidth: "40rem" }}
                >
                  <span>
                    <SettingsToggle
                      title={"Enable/Disable"}
                      subtitle={
                        "Enable/Disable Tax calculation for applicable products and shipping charges."
                      }
                      id="enable_disable"
                      handleToggleBtnChange={handleChange}
                      name={"enable_disable"}
                      value={values?.enable_disable}
                    />
                  </span>
                  {values?.enable_disable && (
                    <div className="mt-2">
                      <InputField
                        unitClassName="text-nowrap"
                        isUnit={true}
                        id={"gst_tax"}
                        label={"Default Tax Rate"}
                        placeholder={"Enter Gst Tax"}
                        name={"default_tax_rate"}
                        unitPosition="right"
                        unitType="%"
                      />
                      <SelectField
                        labelClass="text-black fw_500 fs_14"
                        placeholder={"Select Country"}
                        label={"Country"}
                        id={"Country"}
                        name="country"
                        errMsgContainerClass="ps-2"
                        options={countriesList}
                      />
                      <RadioButton
                        inputLabelClass="fw_400 fs_14"
                        labelClass="color_030304 d-flex fs_14 mb-1 fw_500"
                        name="tax_with_product"
                        options={taxwithProduct}
                        label="Tax with Product"
                      />
                      {(values?.country).toLowerCase() === "india" && (
                        <>
                          <InputField
                            placeholder="Enter GST Number"
                            containerClass="mb-3 position-relative mt-4"
                            errMsgContainerClass="ps-2"
                            label="GST Number"
                            name="gst_numbers"
                            type="text"
                          />
                          <InputField
                            placeholder="Address line 1"
                            containerClass="mb-2 position-relative"
                            label="Address of GST"
                            name="address_of_gst.address_line1"
                            type="text"
                          />
                          <InputField
                            placeholder="Address line 2"
                            containerClass="mb-2 position-relative"
                            label=""
                            name="address_of_gst.address_line2"
                            type="text"
                          />
                          <InputField
                            placeholder="Address line 3"
                            containerClass="position-relative"
                            label=""
                            name="address_of_gst.address_line3"
                            type="text"
                          />
                          <InputField
                            placeholder="Select City"
                            containerClass="mb-3 mt-4 position-relative"
                            errMsgContainerClass="ps-2"
                            label="City"
                            name="city"
                            type="text"
                          />
                          <InputField
                            placeholder="Select State"
                            containerClass="mb-3 position-relative"
                            errMsgContainerClass="ps-2"
                            label="State"
                            name="state"
                            type="text"
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default Tax;
