import {
  ADMIN_ROLE_CREATE,
  ADMIN_ROLE_DELETE,
  ADMIN_ROLE_EDIT,
  ADMIN_ROLE_LIST,
  ADMIN_ROLE_MULTI_DELETE,
  ADMIN_ROLE_TABLE,
  ADMIN_ROLE_VIEW,
  LOGGED_ADMIN_PERMISSION,
} from "../types";

export const adminRoleTableAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_ROLE_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const adminRoleViewAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_ROLE_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const adminRoleEditAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_ROLE_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const adminRoleDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_ROLE_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const adminRoleMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_ROLE_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const adminRoleCreateAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_ROLE_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const adminRoleListAction = (onSuccess, onError) => {
  return {
    type: ADMIN_ROLE_LIST,
    onSuccess,
    onError,
  };
};
export const loggedAdminRoleAction = (onSuccess, onError) => {
  return {
    type: LOGGED_ADMIN_PERMISSION,
    onSuccess,
    onError,
  };
};
