import { call, takeLatest } from "redux-saga/effects";
import {
  installedPluginListApi,
  pluginCreateApi,
  pluginInstallApi,
  pluginListApi,
  pluginUninstallApi,
  pluginUpdateApi,
  pluginUploadApi,
  pluginUploadImageApi,
  themeCreateApi,
  themeInstallApi,
  themeListApi,
  themeUninstallApi,
  themeUnnstallApi,
  themeUploadApi,
  themeUploadImageApi,
} from "../apis/plugin-api";
import {
  INSTALLED_PLUGIN_LIST,
  PLUGIN_CREATE,
  PLUGIN_IMAGE_UPLOAD,
  PLUGIN_INSTALL,
  PLUGIN_LIST,
  PLUGIN_UNINSTALL,
  PLUGIN_UPDATE,
  PLUGIN_UPLOAD,
  THEMES_LIST,
  THEME_CREATE,
  THEME_IMAGE_UPLOAD,
  THEME_INSTALL,
  THEME_UNINSTALL,
  THEME_UPLOAD,
} from "../types";

export function* pluginListWorker(action) {
  try {
    const res = yield call(pluginListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* themeListWorker(action) {
  try {
    const res = yield call(themeListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* installedPluginListWorker(action) {
  try {
    const res = yield call(installedPluginListApi, action.data);
    console.log(res, "resresres");
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* pluginCreateWorker(action) {
  try {
    const res = yield call(pluginCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* themeCreateWorker(action) {
  try {
    const res = yield call(themeCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* pluginUpdateWorker(action) {
  try {
    const res = yield call(pluginUpdateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* pluginInstallWorker(action) {
  try {
    const res = yield call(pluginInstallApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* themeInstallWorker(action) {
  try {
    const res = yield call(themeInstallApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* themeUninstallWorker(action) {
  try {
    const res = yield call(themeUninstallApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* pluginUninstallWorker(action) {
  try {
    const res = yield call(pluginUninstallApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* pluginUploadWorker(action) {
  try {
    const pluginData = new FormData();
    pluginData.append("file", action.data);
    const res = yield call(pluginUploadApi, pluginData);

    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* pluginImageUploadWorker(action) {
  try {
    const ImageData = new FormData();
    ImageData.append("file", action.data);
    const res = yield call(pluginUploadImageApi, ImageData);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* themeUploadWorker(action) {
  try {
    const pluginData = new FormData();
    pluginData.append("file", action.data);
    const res = yield call(themeUploadApi, pluginData);

    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* themeImageUploadWorker(action) {
  try {
    const ImageData = new FormData();
    ImageData.append("file", action.data);
    const res = yield call(themeUploadImageApi, ImageData);
    if (res.status === 200) {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchPluginListWorker() {
  yield takeLatest(PLUGIN_LIST, pluginListWorker);
}
export function* watchInstalledPluginListWorker() {
  yield takeLatest(INSTALLED_PLUGIN_LIST, installedPluginListWorker);
}
export function* watchPluginCreateWorker() {
  yield takeLatest(PLUGIN_CREATE, pluginCreateWorker);
}
export function* watchThemeCreateWorker() {
  yield takeLatest(THEME_CREATE, themeCreateWorker);
}
export function* watchPluginUpdateWorker() {
  yield takeLatest(PLUGIN_UPDATE, pluginUpdateWorker);
}
export function* watchPluginInstallWorker() {
  yield takeLatest(PLUGIN_INSTALL, pluginInstallWorker);
}
export function* watchPluginUninstallWorker() {
  yield takeLatest(PLUGIN_UNINSTALL, pluginUninstallWorker);
}
export function* watchPluginUploadWorker() {
  yield takeLatest(PLUGIN_UPLOAD, pluginUploadWorker);
}
export function* watchPluginImageUploadWorker() {
  yield takeLatest(PLUGIN_IMAGE_UPLOAD, pluginImageUploadWorker);
}
export function* watchThemeUploadWorker() {
  yield takeLatest(THEME_UPLOAD, themeUploadWorker);
}
export function* watchthemeImageUploadWorker() {
  yield takeLatest(THEME_IMAGE_UPLOAD, themeImageUploadWorker);
}
export function* watchthemeListWorker() {
  yield takeLatest(THEMES_LIST, themeListWorker);
}
export function* watchthemeInstallWorker() {
  yield takeLatest(THEME_INSTALL, themeInstallWorker);
}
export function* watchThemeUninstallWorker() {
  yield takeLatest(THEME_UNINSTALL, themeUninstallWorker);
}
