import React from "react";

const CategoriesIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 0H7.5C5.43 0 3.75 1.68 3.75 3.75V8.25C3.75 10.32 5.43 12 7.5 12H14.25C16.32 12 18 10.32 18 8.25V3.75C18 1.68 16.32 0 14.25 0ZM7.5 1.5H14.25C15.225 1.5 16.05 2.13 16.365 3H5.385C5.6925 2.13 6.5175 1.5 7.5 1.5ZM14.25 10.5H7.5C6.2625 10.5 5.25 9.4875 5.25 8.25V4.5H16.5V8.25C16.5 9.4875 15.4875 10.5 14.25 10.5ZM12.75 14.25C12.75 14.6625 12.4125 15 12 15H3.75C1.68 15 0 13.32 0 11.25V5.7375C0 4.8225 0.33 3.945 0.9375 3.255C1.215 2.9475 1.6875 2.9175 1.995 3.1875C2.31 3.465 2.3325 3.9375 2.0625 4.245C1.7025 4.6575 1.5 5.1825 1.5 5.73V11.2425C1.5 12.48 2.5125 13.4925 3.75 13.4925H12C12.4125 13.4925 12.75 13.83 12.75 14.2425V14.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default CategoriesIcon;
