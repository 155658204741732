import React from "react";

const DiscountIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0015 9C18.0015 7.78725 17.4705 6.648 16.5615 5.86875C16.653 4.6755 16.2233 3.49425 15.3653 2.63625C14.508 1.77825 13.323 1.3485 12.1328 1.44C11.3528 0.531 10.2135 0 9.00075 0C7.788 0 6.64875 0.531 5.86875 1.44C4.6785 1.3485 3.49425 1.77825 2.63625 2.63625C1.77825 3.495 1.3485 4.6755 1.44 5.86875C0.531 6.648 0 7.78725 0 9C0 10.2128 0.531 11.352 1.44 12.1312C1.3485 13.3245 1.77825 14.5058 2.63625 15.3638C3.49425 16.221 4.6785 16.6493 5.86875 16.56C6.64875 17.469 7.788 18 9.00075 18C10.2135 18 11.3528 17.469 12.1328 16.56C13.3238 16.6507 14.508 16.221 15.3653 15.3638C16.2233 14.505 16.653 13.3245 16.5615 12.1312C17.4705 11.352 18.0015 10.2128 18.0015 9ZM15.342 11.178L14.9295 11.4555L15.0248 11.943C15.192 12.8018 14.9228 13.6845 14.3048 14.3025C13.6868 14.9205 12.8055 15.1898 11.9445 15.0225L11.4578 14.928L11.1803 15.3397C10.6905 16.0658 9.876 16.4993 9.00225 16.4993C8.1285 16.4993 7.314 16.0658 6.82425 15.3397L6.54675 14.928L6.06 15.0225C5.19825 15.189 4.31775 14.9198 3.69975 14.3025C3.08175 13.6838 2.8125 12.8018 2.97975 11.943L3.075 11.4555L2.6625 11.178C1.9365 10.6883 1.503 9.87375 1.503 9C1.503 8.12625 1.9365 7.31175 2.6625 6.822L3.075 6.5445L2.97975 6.057C2.8125 5.19825 3.08175 4.3155 3.69975 3.6975C4.31775 3.0795 5.19975 2.8125 6.06 2.9775L6.54675 3.072L6.82425 2.66025C7.314 1.93425 8.1285 1.50075 9.00225 1.50075C9.876 1.50075 10.6905 1.93425 11.1803 2.66025L11.4578 3.072L11.9445 2.9775C12.8048 2.811 13.6868 3.0795 14.3048 3.6975C14.9228 4.31625 15.192 5.19825 15.0248 6.057L14.9295 6.5445L15.342 6.822C16.068 7.31175 16.5015 8.12625 16.5015 9C16.5015 9.87375 16.068 10.6883 15.342 11.178ZM6.7515 7.5C6.3375 7.5 6.0015 7.164 6.0015 6.75C6.0015 6.336 6.3375 6 6.7515 6C7.1655 6 7.5015 6.336 7.5015 6.75C7.5015 7.164 7.1655 7.5 6.7515 7.5ZM12.0015 11.25C12.0015 11.664 11.6655 12 11.2515 12C10.8375 12 10.5015 11.664 10.5015 11.25C10.5015 10.836 10.8375 10.5 11.2515 10.5C11.6655 10.5 12.0015 10.836 12.0015 11.25ZM11.676 6L8.076 12H6.327L9.927 6H11.676Z"
        fill={fill}
      />
    </svg>
  );
};

export default DiscountIcon;
