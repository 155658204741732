import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../../modals/modal.css";
import { BackButton, SaveButton } from "../../buttons/text-button";
import InputField from "../../forms/input-field";
import SelectOrderStatus from "../order-status-select";
import { paymentStatus, returnpaymentStatus } from "../../../helper-functions/helper/data";
import { ordersReturnEditAction } from "../../../../redux/actions/orders-action";
import { useDispatch } from "react-redux";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
export default function EditRefaundDetails({ show, modalClose, orderDetails, data, id, reload, setReload }) {
  console.log(data, "sdfgsdkfjhdsugytsdf7g");
  console.log(orderDetails, "sdfgsdkfjhdsugytsdf7g");
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch()
  const [formValues, setformValues] = useState({})
  const initialValues = {
    // transaction_id: "",
    // transaction_status: "",
    refundable_amount: orderDetails?.refund_on_complete_reject ?? orderDetails?.rejectedOrders?.total_refund ?? "",
    account_number: data?.acc_no ?? "",
    ifsc_code: data?.ifsc ?? "",
    account_holder_name: data?.acc_holder_name ?? "",
    confirm_account_number: data?.acc_no ?? "",
    refund_status: orderDetails?.refund_status ?? "",
  };
  const validationSchema = Yup.object().shape({
    // transaction_id: Yup.number().required("Required"),
    // transaction_status: Yup.string().required("Required"),
    refundable_amount: Yup.number().required("Required"),
    account_number: Yup.number().required("Required"),
    ifsc_code: Yup.string().required("Required"),
    account_holder_name: Yup.string().required("Required"),
    confirm_account_number: Yup.number().required("Required"),
    refund_status: Yup.string().required("Required"),
  });
  const onSubmit = (values) => {
    console.log(values, "dfgdfgdf");
    dispatch(
      ordersReturnEditAction(
        {
          "order_id": id,
          "refund_status": values?.refund_status,
          "refund_bank_account_details": {
            "acc_holder_name": values?.account_holder_name,
            "ifsc": values?.ifsc_code,
            "acc_no": values?.confirm_account_number,
            "transactionId": null,
            "id": data?.id
          },
          // "refund_on_complete_reject": values?.refundable_amount,
        },
        onEditSuccess,
        onEditError
      )
    )
  };
  const onEditSuccess = (data) => {
    setReload(!reload)
    successToast("Order Updated SuccessFully")
    modalClose()
  }
  const onEditError = (data) => {
    errorToast(data?.data?.message)
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="edit_order_refaund_model"
        contentClassName="rounded-3"
      // backdrop="static"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, setFieldValue }) => {
            console.log(values, "refaundval");
            setformValues(values)
            return (
              <Form>
                <ModalHeader>
                  <div>
                    <img onClick={modalClose} src={BackIcon} />
                    <span className="ms-2 fs_16 fw_500">
                      Edit Refund Details
                    </span>
                  </div>
                  <div className="d-flex gap-3">
                    <BackButton
                      // disabled={valuesCompareFn(values)}
                      type="reset"
                      name="UNDO"
                      backClassName="fs_14"
                    />

                    <SaveButton
                      // disabled={valuesCompareFn(values)}
                      type="submit"
                      backClassName="fs_14"
                    //   onClick={saveFn}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <>
                    <label className="fs_17 fw_500 color_030304">
                      Refund Details
                    </label>
                    <div className="color_9E9E9E mt-1 fs_13">Refund ID</div>
                    <p className="fs_14 color_030304 fw_500">{data?.id}</p>
                    <div className="d-lg-flex justify-content-between">
                      <div className="col-lg-6 col-12 pe-lg-4">
                        {/* <InputField
                          id={"transaction_id"}
                          label={"Transaction ID"}
                          placeholder={"Enter Transaction ID"}
                          name={"transaction_id"}
                        /> */}
                        <SelectOrderStatus
                          label="Refund Status"
                          options={returnpaymentStatus}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          isFormik={true}
                          selectClass="w-100"
                          name="refund_status"
                          formValues={values}
                        />
                      </div>
                      {/* <div className="col-md-6">
                        <SelectOrderStatus
                          options={paymentStatus}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          isFormik={true}
                        />
                      </div> */}
                      {/* <div className="col-lg-6 col-12 pe-lg-4">
                        <InputField
                          id={"transaction_status"}
                          label={"Transaction Status"}
                          placeholder={"Enter Transaction ID"}
                          name={"transaction_status"}
                        />
                      </div> */}
                    </div>
                    <label className="fs_17 fw_500 color_030304 mt-3">
                      Refund Account Details
                    </label>
                    <div className="d-lg-flex justify-content-between mt-2">
                      <div className="col-lg-6 col-12 pe-lg-4">
                        <InputField
                          isDisabled={true}
                          unitType={localStorage?.getItem("currency") ?? "₹ "}
                          isUnit={true}
                          id={"refundable_amount"}
                          label={"Refundable Amount"}
                          //   placeholder={"Enter Price"}
                          name={"refundable_amount"}
                        />
                        <InputField
                          id={"account_number"}
                          label={"Account Number"}
                          //   placeholder={"Enter Transaction ID"}
                          name={"account_number"}
                        />
                        <InputField
                          id={"ifsc_code"}
                          label={"IFSC Code"}
                          //   placeholder={"Enter Transaction ID"}
                          name={"ifsc_code"}
                        />
                      </div>
                      <div className="col-lg-6 col-12 pe-lg-4">
                        <InputField
                          id={"account_holder_name"}
                          label={"Account Holder Name"}
                          //   placeholder={"Enter Transaction ID"}
                          name={"account_holder_name"}
                        />
                        <InputField
                          id={"confirm_account_number"}
                          label={"Confirm Account Number"}
                          //   placeholder={"Enter Transaction ID"}
                          name={"confirm_account_number"}
                        />
                      </div>
                    </div>
                  </>
                </ModalBody>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
