import { Field } from "formik";
import React, { Fragment, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import ErrorMsg from "./error-msg";
import "react-quill/dist/quill.snow.css";
import errorToast from "../../helper-functions/helper/error-toast";
import { categoriesImgUploadAction } from "../../../redux/actions/categories-action";
import { useDispatch } from "react-redux";
export default function QuillField({
  name,
  errMsgContainerClass = "ms-2",
  isContainer = true,
  containerClass = "mb-2",
  labelClass = "d-flex fs_14 mb-1",
  labelActiveClass = "color_9E9E9E fw_400",
  labelInActiveClass = " text-black fw_500",
  label = "Description",
  id,
  editorStyle = defaultEditorStyle,
  replaceDefaultStyle = false,
  quillCustomStyles = {}, placeholder = "Write your description",
  toolbarItems = [
    [{ align: "" }, { align: "center" }, { align: "right" }],
    ["bold", "italic", "underline"], // toggled buttons
    [{ list: "ordered" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["image"], // custom button values
    [
      {
        size: [
          "10px",
          "12px",
          "14px",
          "16px",
          "18px",
          "20px",
          "22px",
          "24px",
          "26px",
        ],
      },
    ],
  ],
}) {
  const dispatch = useDispatch()
  const quillRef = useRef();
  const Container = isContainer ? "div" : Fragment;
  const finalEditorStyle = replaceDefaultStyle ? editorStyle : defaultEditorStyle + editorStyle
  const imageHandler = () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const range = editor.getSelection();
        dispatch(categoriesImgUploadAction(file, (url) => {
          editor.insertEmbed(range.index, "image", url);
        }, (data) => {
          console.log(data);
          errorToast("falied to upload image")
        }))
      } else {
        console.log("upload image");
        alert("upload image");
      }
    };
  };
  const [settings] = useState({
    toolbar: {
      container: toolbarItems,
      handlers: {
        image: imageHandler,
      },
    },
  });
  let fontSize = Quill.import("attributors/style/size");
  fontSize.whitelist = [
    "10px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
  ];
  Quill.register(fontSize, true);
  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      <style jsx>{finalEditorStyle}</style>
      {" "} <Field name={name}>
        {({ field, form, meta }) => (
          <>
            {label && (
              <label {...(id ? { htmlFor: id } : {})} className={`${form.values[name] ? labelActiveClass : labelInActiveClass
                }   ${labelClass}`}>
                {label}
              </label>
            )}{" "}

            <ReactQuill
              style={{
                border: `1px ${meta.touched && meta.error ? "#f24444" : "#E5ECFF"
                  } solid`,
                borderRadius: "10px",
                overflow: "hidden",
                ...quillCustomStyles,
              }}
              placeholder={placeholder}
              ref={quillRef}
              modules={settings}
              theme={"snow"}
              {...(id ? { id: id } : {})}
              value={field.value}
              onChange={(newValue, _, source) => {
                // if (source === "user") {
                if (newValue === "<p><br></p>") {
                  newValue = "";
                }
                field.onChange(field.name)(newValue);
                // }
              }}
              onBlur={() => form.setFieldTouched(name, true)}
              autoFocus={true}
            />
          </>
        )}
      </Field>
      <ErrorMsg containerClass={errMsgContainerClass} name={name} />
    </Container>
  );
}

const defaultEditorStyle = `.ql-snow.ql-toolbar .ql-picker-label.ql-active {
  color: #030304 !important;
}
.ql-snow.ql-toolbar .ql-picker-label.ql-active.ql-stroke {
  color: #030304 !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: attr(data-value) !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24" !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26" !important;
}
.ql-container {
  min-height: 350px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ql-toolbar {
  background-color: #f9f9fc;
  border: 1px solid #e5ecff;
  display: flex;
  flex-wrap: wrap;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #c8c8c8;
}
.ql-toolbar > :nth-last-child(2) {
  margin-left: auto;
}
.ql-toolbar.ql-snow > :last-child > .ql-picker {
  width: 60px;
  background-color: white;
  border-radius: 5px;
}
.quill .ql-editor {
  color: #030304;
  font-family: "Poppins", sans-serif;
}
@media only screen and (max-width: 768px) {
  .ql-toolbar {
    gap: 15px;
  }
  .ql-toolbar > :nth-last-child(2) {
    margin-left: 0%;
  }
}`

// const editorImgUploadIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <path d="M14.6667 2H5.33333C4.4496 2.00106 3.60237 2.35259 2.97748 2.97748C2.35259 3.60237 2.00106 4.4496 2 5.33333L2 14.6667C2.00106 15.5504 2.35259 16.3976 2.97748 17.0225C3.60237 17.6474 4.4496 17.9989 5.33333 18H14.6667C15.5504 17.9989 16.3976 17.6474 17.0225 17.0225C17.6474 16.3976 17.9989 15.5504 18 14.6667V5.33333C17.9989 4.4496 17.6474 3.60237 17.0225 2.97748C16.3976 2.35259 15.5504 2.00106 14.6667 2ZM5.33333 3.33333H14.6667C15.1971 3.33333 15.7058 3.54405 16.0809 3.91912C16.456 4.29419 16.6667 4.8029 16.6667 5.33333V14.6667C16.6655 14.9637 16.5971 15.2565 16.4667 15.5233L10.358 9.41467C10.0485 9.10504 9.68096 8.85944 9.27648 8.69186C8.87201 8.52429 8.43848 8.43805 8.00067 8.43805C7.56285 8.43805 7.12933 8.52429 6.72485 8.69186C6.32038 8.85944 5.95287 9.10504 5.64333 9.41467L3.33333 11.724V5.33333C3.33333 4.8029 3.54405 4.29419 3.91912 3.91912C4.29419 3.54405 4.8029 3.33333 5.33333 3.33333ZM5.33333 16.6667C4.8029 16.6667 4.29419 16.456 3.91912 16.0809C3.54405 15.7058 3.33333 15.1971 3.33333 14.6667V13.6093L6.58533 10.3573C6.77107 10.1715 6.9916 10.024 7.23434 9.92346C7.47707 9.82287 7.73725 9.77109 8 9.77109C8.26275 9.77109 8.52293 9.82287 8.76566 9.92346C9.0084 10.024 9.22893 10.1715 9.41467 10.3573L15.5233 16.4667C15.2565 16.5971 14.9637 16.6655 14.6667 16.6667H5.33333Z" fill="black" />
//   <path d="M12.6673 9.00065C13.1288 9.00065 13.5799 8.8638 13.9636 8.60741C14.3474 8.35102 14.6464 7.98661 14.823 7.56025C14.9996 7.13389 15.0458 6.66473 14.9558 6.21211C14.8658 5.75949 14.6436 5.34373 14.3172 5.0174C13.9909 4.69108 13.5752 4.46885 13.1225 4.37882C12.6699 4.28879 12.2008 4.335 11.7744 4.5116C11.348 4.6882 10.9836 4.98727 10.7272 5.37099C10.4708 5.7547 10.334 6.20583 10.334 6.66732C10.334 7.28616 10.5798 7.87965 11.0174 8.31723C11.455 8.75482 12.0485 9.00065 12.6673 9.00065ZM12.6673 5.66732C12.8651 5.66732 13.0584 5.72597 13.2229 5.83585C13.3873 5.94573 13.5155 6.10191 13.5912 6.28464C13.6669 6.46736 13.6867 6.66843 13.6481 6.86241C13.6095 7.05639 13.5143 7.23457 13.3744 7.37443C13.2346 7.51428 13.0564 7.60952 12.8624 7.6481C12.6684 7.68669 12.4674 7.66689 12.2846 7.5912C12.1019 7.51551 11.9457 7.38734 11.8358 7.22289C11.726 7.05844 11.6673 6.8651 11.6673 6.66732C11.6673 6.4021 11.7727 6.14775 11.9602 5.96021C12.1477 5.77268 12.4021 5.66732 12.6673 5.66732Z" fill="black" />
// </svg>`
