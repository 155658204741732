import { api } from "./api";

export const customerTableApi = (data, params) => {
  return api.post(
    `/core/customer/list?page=${data?.page}&page_size=${data?.size}&search=${data?.search}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};

export const customerCreateApi = (params) => {
  return api.post(`/core/customer/create`, params);
};

export const customerViewApi = (data) => {
  return api.get(
    `/core/customer/view?customer_id=${data.id}&page=${data.page}&page_size=${data.size}&search=${data.search}&searchOrder=${data?.searchOrder}&${data?.sort != null && `address_sort_by=${data?.sort?.sort_by}&address_sort_order=${data?.sort?.sort_order}&orders_sort_by=${data?.orderSort?.sort_by}&orders_sort_order=${data?.orderSort?.sort_order} `}`
  );
};

export const customerEditApi = (params) => {
  return api.post(`/core/customer/edit`, params);
};

export const customerDeleteApi = (data, params) => {
  return api.delete(`/core/customer/delete?customer_id=${data}`, params);
};

export const customerMultiDeleteApi = (params) => {
  console.log(params);
  return api.delete(`/core/customer/bulk-delete`, params);
};

export const customerBlockApi = (data, params) => {
  return api.post(
    `/core/customer/block-unblock-customer?customer_id=${data}`,
    params
  );
};

export const customerResetPswApi = (params) => {
  return api.post(`/core/customer/reset-password`, params);
};
export const customerSendPasswordApi = (params) => {
  return api.post(
    `/core/customer/send-password-user-mail?customer_id=${params}`
  );
};
