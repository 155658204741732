import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { BackButton, SaveButton } from "../../buttons/text-button";
import usePermissions from "../../../helper-functions/hooks/usePermission-checker";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import isEqual from "lodash.isequal";
import InputField from "../../forms/input-field";
import CountryCode from "../../forms/country-code";
import ErrorMsg from "../../forms/error-msg";
import FacebookLogo from "../../../../assets/icons/Facebook_logo";
import TwitterLogo from "../../../../assets/icons/Twitter-logo";
import LinkedinLogo from "../../../../assets/icons/Linked-in-logo";
import InstagramLogo from "../../../../assets/icons/Instagram-logo";
import { useDispatch } from "react-redux";
import { contactInfoCreateUpdateAction, contactInfoViewAction } from "../../../../redux/actions/configuration-action";
import successToast from "../../../helper-functions/helper/sucess-toast";
import AddressLeaflet from "../../address/address-leaflet";
const ContactInfo = ({ closeSection, name }) => {
  const { canWrite } = usePermissions();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactInfoData, setContactInfoData] = useState("")
  const phoneRegex = /^([+]\d{2})?\d{10}$/;
  useEffect(() => {
    dispatch(contactInfoViewAction(onFetchSuccess, onFetchError))
  }, [reload])
  const onFetchSuccess = (data) => {
    setContactInfoData(data?.data)
  }
  const onFetchError = () => { }
  const initialValues = {
    company_name: contactInfoData?.company_name ?? "",
    company_address: {
      address_line1: contactInfoData?.company_address?.address_line1 ?? "",
      address_line2: contactInfoData?.company_address?.address_line2 ?? "",
      address_line3: contactInfoData?.company_address?.address_line3 ?? "",
    },
    lat: contactInfoData?.company_address?.lat,
    lng: contactInfoData?.company_address?.lng,
    email_address: contactInfoData?.email_address ?? "",
    phone_number: contactInfoData?.phone_number ?? "",
    is_whatsapp: contactInfoData?.is_whatsapp ?? false,
    whatsapp_number: contactInfoData?.whatsapp_number ?? "",
    social_media_links: {
      facebook_link: contactInfoData?.social_media_links?.facebook_link ?? "",
      instagram_link: contactInfoData?.social_media_links?.instagram_link ?? "",
      linkedin_link: contactInfoData?.social_media_links?.linkedin_link ?? "",
      twitter_link: contactInfoData?.social_media_links?.twitter_link ?? "",
    },
  };
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().trim().required("Company Name is required"),
    company_address: Yup.object().shape({
      address_line1: Yup.string().trim().required("Required"),
      address_line2: Yup.string(),
      address_line3: Yup.string()
    }),
    email_address: Yup.string()
      .email("Invalid email")
      .trim()
      .required("Email is required"),
    phone_number: Yup.string()
      .trim()
      .matches(phoneRegex, "Invalid number")
      .required("Phone number is required"),
    whatsapp_number: Yup.string()
      .trim()
      .matches(phoneRegex, "Invalid number")
      .required("Whats number is required"),
    social_media_links: Yup.object().shape({
      facebook_link: Yup.string().nullable().trim(),
      instagram_link: Yup.string().nullable().trim(),
      linkedin_link: Yup.string().nullable().trim(),
      twitter_link: Yup.string().nullable().trim(),
    }),
  });
  const onSubmit = (values) => {
    console.log(values, "contact58");
    dispatch(
      contactInfoCreateUpdateAction(
        {
          company_name: values?.company_name,
          company_address: {
            address_line1: values?.company_address?.address_line1,
            address_line2: values?.company_address?.address_line2,
            address_line3: values?.company_address?.address_line3,
            lat: values?.lat,
            lng: values?.lng,
          },
          email_address: values?.email_address,
          phone_number: values?.phone_number,
          is_whatsapp: values?.is_whatsapp,
          whatsapp_number: values?.whatsapp_number,
          social_media_links: {
            facebook_link: values?.social_media_links?.facebook_link,
            instagram_link: values?.social_media_links?.instagram_link,
            linkedin_link: values?.social_media_links?.linkedin_link,
            twitter_link: values?.social_media_links?.twitter_link,
          },
        },
        onCreateUpdateSuccess,
        OnCreateUpdateError
      )
    );
  };
  const onCreateUpdateSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message)
  };
  const OnCreateUpdateError = () => { };
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, field, handleChange, setFieldValue }) => {
          return (
            <Form className="">
              <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                <div
                  className="mb-0 px-xxl-4 d-flex align-items-center"
                  style={{ width: "fit-Content" }}
                >
                  <span>
                    <img
                      className="d-inline-block d-md-none"
                      onClick={closeSection}
                      src={backIcon}
                    />
                  </span>
                  <span className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat ms-2">
                    {name + "rmation"}
                  </span>
                </div>
                <div
                  className="me-xxl-5 pe-xxl-5"
                  style={{ width: "fit-Content" }}
                >
                  {canWrite("configuration") && (
                    <div className="d-flex gap-3">
                      <BackButton
                        disabled={valuesCompareFn(values)}
                        type="reset"
                        name="UNDO"
                        backClassName="fs_14"
                      />

                      <SaveButton
                        disabled={valuesCompareFn(values)}
                        type="submit"
                        backClassName="fs_14"
                      //   onClick={saveFn}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                className="mt-5 ms-xl-5 me-xl-5 ms-4 me-4"
                style={{ maxWidth: "40rem" }}
              >
                <div className="mt-2">
                  <InputField
                    unitClassName="text-nowrap"
                    id={"company_name"}
                    label={"Company Name"}
                    placeholder={"Enter company name"}
                    name={"company_name"}
                  />

                  <InputField
                    placeholder="Address line 1"
                    containerClass="mb-2 position-relative"
                    label="Company Address"
                    name="company_address.address_line1"
                    type="text"
                  />
                  <InputField
                    placeholder="Address line 2"
                    containerClass="mb-2 position-relative"
                    label=""
                    name="company_address.address_line2"
                    type="text"
                  />
                  <InputField
                    placeholder="Address line 3"
                    containerClass="position-relative"
                    label=""
                    name="company_address.address_line3"
                    type="text"
                  />


                  {
                    localStorage?.getItem("live_map_enable") === "true" &&
                    // <AddressLocation
                    //   setFieldValue={setFieldValue}
                    //   lat={contactInfoData?.company_address?.lat ?? 12.2958104}
                    //   lng={contactInfoData?.company_address?.lng ?? 76.639380}
                    // />
                    <AddressLeaflet
                      setFieldValue={setFieldValue}
                      lat={contactInfoData?.company_address?.lat ?? 12.2958104}
                      lng={contactInfoData?.company_address?.lng ?? 76.639380}
                    />
                  }



                  <InputField
                    placeholder="Enter email address"
                    containerClass="mb-3 position-relative mt-4"
                    errMsgContainerClass="ps-2"
                    label="Contact Email Address"
                    name="email_address"
                    type="text"
                  />
                  <label className="color_030304 fw_500 fs_14">
                    Contact Phone Number
                  </label>
                  <div className="d-flex mt-1">
                    <span className="">
                      <CountryCode />
                    </span>
                    <span className="ms-2 w-100">
                      <InputField
                        placeholder="Enter phone number"
                        containerClass="position-relative"
                        errMsgContainerClass="d-none"
                        label=""
                        name="phone_number"
                        type="text"
                      />
                    </span>
                  </div>
                  {errors?.phone_number && touched?.phone_number && (
                    <ErrorMsg name={"phone_number"} />
                  )}
                  <div className="mt-2 mb-3 d-flex align-items-center">
                    <span>
                      <input
                        {...field}
                        style={{ transform: "scale(1.1)" }}
                        type="checkbox"
                        id="myCheckbox"
                        name="is_whatsapp"
                        className="custom-checkbox form-check-input"
                        checked={values?.is_whatsapp}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.checked) {
                            setFieldValue(
                              "whatsapp_number",
                              values?.phone_number
                            );
                          } else {
                            setFieldValue("whatsapp_number", "");
                          }
                        }}
                      />
                    </span>
                    <span className="ms-2 text-black fw_500 fs_14">
                      Same for WhatsApp Number
                    </span>
                  </div>

                  <label className="color_030304 fw_500 fs_14">
                    WhatsApp Phone Number
                  </label>
                  <div className="d-flex mt-1">
                    <span className="">
                      <CountryCode />
                    </span>
                    <span className="ms-2 w-100">
                      <InputField
                        placeholder="Enter phone number"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label=""
                        name="whatsapp_number"
                        type="text"
                      />
                    </span>
                  </div>
                  <InputField
                    placeholder="Enter Facebook profile link"
                    containerClass="mb-2 position-relative"
                    errMsgContainerClass="d-none"
                    label=""
                    name="social_media_links.facebook_link"
                    type="text"
                    isUnit={true}
                    unitType={<FacebookLogo />}
                    unitPosition="image"
                    unitClassName="me-2"
                  />
                  <InputField
                    placeholder="Enter Instagram profile link"
                    containerClass="mb-2 position-relative"
                    errMsgContainerClass="d-none"
                    label=""
                    name="social_media_links.instagram_link"
                    type="text"
                    isUnit={true}
                    unitType={<InstagramLogo />}
                    unitPosition="image"
                    unitClassName="me-2"
                  />
                  <InputField
                    placeholder="Enter LinkedIn profile link"
                    containerClass="mb-2 position-relative"
                    errMsgContainerClass="d-none"
                    label=""
                    name="social_media_links.linkedin_link"
                    type="text"
                    isUnit={true}
                    unitType={<LinkedinLogo />}
                    unitPosition="image"
                    unitClassName="me-2"
                  />
                  <InputField
                    placeholder="Enter Twitter profile link"
                    containerClass="mb-2 position-relative"
                    errMsgContainerClass="d-none"
                    label=""
                    name="social_media_links.twitter_link"
                    type="text"
                    isUnit={true}
                    unitType={<TwitterLogo />}
                    unitPosition="image"
                    unitClassName="me-2"
                  />
                </div>
              </div>
              <div className="row g-0 justify-content-between align-items-center p-3">
                <div
                  className="mb-0 px-xxl-4 d-flex align-items-center"
                  style={{ width: "fit-Content" }}
                >
                </div>
                <div
                  className="me-xxl-5 pe-xxl-5"
                  style={{ width: "fit-Content" }}
                >
                  {canWrite("configuration") && (
                    <div className="d-flex gap-3">
                      <BackButton
                        disabled={valuesCompareFn(values)}
                        type="reset"
                        name="UNDO"
                        backClassName="fs_14"
                      />

                      <SaveButton
                        disabled={valuesCompareFn(values)}
                        type="submit"
                        backClassName="fs_14"
                      />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ContactInfo;
