import dayjs from "dayjs";
export function isValidDate(dateString) {
  return dayjs(dateString).isValid();
}
export function formatDate(date, format = "DD MMM YYYY") {
  if (!date || !format) {
    return "--";
  } else {
    return dayjs(date).format(format);
  }
}

export function formatDateWithDay(inputDate) {
  const months = [
    "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];

  const date = new Date(inputDate);
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
}


export function variationFunction(item, type, quantity) {
  if (type === "sku") {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return item?.variation?.[0]?.sku
    }
    else if (item?.variation && !Array.isArray(item?.variation)) {
      return item?.variation?.sku
    }
    else return item?.sku
  }
  else if (type === "price") {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return item?.variation?.[0]?.price
    }
    else if (item?.variation && !Array.isArray(item?.variation)) {
      return item?.variation?.price
    }
    else return item?.price
  }
  if (type === "barcode") {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return item?.variation?.[0]?.bar_code
    }
    else if (item?.variation && !Array.isArray(item?.variation)) {
      return item?.variation?.barcode ?? item?.variation?.bar_code
    }
    else return item?.barcode ?? item?.bar_code
  }
  if (type === "discount") {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return item?.variation?.[0]?.discounted_price
    }
    else if (item?.variation && !Array.isArray(item?.variation)) {
      return item?.variation?.discounted_price
    }
    else return item?.discounted_price
  }
  if (type === "total") {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return item?.variation?.[0]?.discounted_price
    }
    else if (item?.variation && !Array.isArray(item?.variation)) {
      return item?.variation?.discounted_price
    }
    else return item?.total_price
  }
  if (type === "total_price") {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return (item?.variation?.[0]?.discounted_price ?? item?.variation?.[0]?.price) * quantity
    }
    else if (item?.variation && !Array.isArray(item?.variation)) {
      return (item?.variation?.discounted_price ? item?.variation?.discounted_price * quantity : item?.variation?.price * quantity)
    }
    else {
      return (item?.discounted_price ?? item?.price) * quantity
    }
  }
}