import React, { useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { BackButton, SaveButton } from "../buttons/text-button";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { attributesCreateAction } from "../../../redux/actions/attributes-action";
import { useDispatch } from "react-redux";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";
import "./modal.css";
export default function CreateAttributeModal({ show, modalClose, onCreateSuccess }) {
  const dispatch = useDispatch();
  const formRef = useRef();
  const validationSchema = Yup.object().shape({
    attribute_name: Yup.string().trim().required("Required"),
    slug: Yup.string().trim(),
  });
  const onSubmit = (values) => {
    console.log(values);
    dispatch(
      attributesCreateAction(
        {
          attribute_name: values?.attribute_name,
          slug: values?.slug,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    onCreateSuccess && onCreateSuccess(data.data)
    successToast(data.message);
    modalClose();
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  return (
    <Modal
      show={show}
      onHide={modalClose}
      centered
      dialogClassName={`mx-sm-auto attribute_modal_container`}
      contentClassName="rounded-3"
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          attribute_name: "",
          slug: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <ModalHeader>
            <div className="text-black fw_500 fs_18 d-flex align-items-center gap-2">
              <img
                onClick={modalClose}
                style={{ background: "#F7F7F7", borderRadius: "3px" }}
                src={BackIcon}
                alt="back"
              />
              <span>Create Attribute</span>
            </div>
            <div className="d-sm-flex g-0 gap-sm-3 gap-2 text-center">
              <div className="mb-2 mb-sm-0">
                <BackButton name="RESET" type="reset" />
              </div>
              <div className="mt-2 mt-sm-0">
                <SaveButton type="submit" />
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="px-sm-5">
            <div>
              <InputField
                placeholder="Attribute Name Goes Here"
                containerClass=" mt-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Attribute Name"
                name="attribute_name"
                type="text"
              />
              <InputField
                placeholder="Slug Text Goes Here"
                containerClass="mb-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Slug Name"
                name="slug"
                type="text"
              />
            </div>
          </ModalBody>
        </Form>
      </Formik>
    </Modal>
  );
}
