import { api } from "./api";

export const addressTableApi = (data, params) => {
  console.log(data);
  return api.post(
    `/core/address/list?page=${data?.page}&page_size=${data?.size}&search=${
      data?.search
    }&${
      data?.sort != null &&
      `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`
    }`,
    JSON.stringify(params)
  );
};
export const addressDeleteApi = (data, params) => {
  return api.delete(
    `/core/address/delete?address_id=${data}`,
    JSON.stringify(params)
  );
};
export const addressMultiDeleteApi = (params) => {
  return api.delete(`/core/address/bulk-delete`, params);
};
export const addressViewApi = (data) => {
  return api.get(`/core/address/view?address_id=${data}`);
};

export const addressEditApi = (params) => {
  return api.post(`/core/address/edit`, JSON.stringify(params));
};

export const addressCreateApi = (params) => {
  return api.post(`/core/address/create`, JSON.stringify(params));
};
export const customerListDropdownApi = () => {
  return api.get(`/core/customer/list-customers`);
};
