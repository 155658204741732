import { CATEGORIES_CREATE, CATEGORIES_DELETE, CATEGORIES_DROPDOWN_UPLOAD, CATEGORIES_EDIT, CATEGORIES_IMAGE_UPLOAD, CATEGORIES_MULTI_DELETE, CATEGORIES_TABLE, CATEGORIES_VIEW, CONFIGURATION_IMAGE_UPLOAD } from "../types";

export const categoriesTableAction = (data, onSuccess, onError) => {
  return {
    type: CATEGORIES_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const categoriesViewAction = (id, onSuccess, onError) => {
  return {
    type: CATEGORIES_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const categoriesEditAction = (data, onSuccess, onError) => {
  return {
    type: CATEGORIES_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const categoriesDeleteAction = (data, onSuccess, onError) => {
  return {
    type: CATEGORIES_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const categoriesMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: CATEGORIES_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const categoriesCreateAction = (data, onSuccess, onError) => {
  return {
    type: CATEGORIES_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const categoriesImgUploadAction = (data, onSuccess, onError) => {
  console.log(data, "rgeytsdfsdfety");
  return {
    type: CATEGORIES_IMAGE_UPLOAD,
    data,
    onSuccess,
    onError,
  };
};
export const configurationImgUploadAction = (data, onSuccess, onError) => {
  console.log(data, "gdtdfgfdgdfgfg");
  return {
    type: CONFIGURATION_IMAGE_UPLOAD,
    data,
    onSuccess,
    onError,
  };
};

export const categoriesDropdownAction = (onSuccess, onError) => {
  return {
    type: CATEGORIES_DROPDOWN_UPLOAD,
    onSuccess,
    onError,
  };
};