import React, { useState } from "react";
import ImageUpload from "../../components/ui/forms/image-upload";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectField from "../../components/ui/forms/select-field";
import InputField from "../../components/ui/forms/input-field";
import { passwordRegex } from "../../components/helper-functions/helper/regex";
import { useDispatch } from "react-redux";
import { customerCreateAction } from "../../redux/actions/customer-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import { useNavigate } from "react-router";
import { categoriesImgUploadAction } from "../../redux/actions/categories-action";
import errorToast from "../../components/helper-functions/helper/error-toast";
import Compressor from "compressorjs";
import MultiImageUpload from "../../components/ui/forms/multiImage-upload";
import CountryCode from "../../components/ui/forms/country-code";
import NotificationComponent from "../../components/notificationComponent";
const CustomerCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imgUrl, setImageUrl] = useState("");
  const [images, setImages] = useState("");
  const [imageSize, setImageSize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  console.log(imgUrl, "imgUrl");
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
  };

  const onImgSuccess = (url) => {
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  const phoneRegex = /^([+]\d{2})?\d{10}$/;
  const initialValues = {
    customerName: "",
    phoneNum: "",
    email: "",
    password: "",
    confirmPassword: "",
    country_code: "91",
  };
  const validationSchema = Yup.object().shape({
    customerName: Yup.string().trim().required("Customer Name is required"),
    phoneNum: Yup.string()
      .trim()
      .matches(phoneRegex, "Invalid number")
      .required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email")
      .trim()
      .required("Email is required"),
    password: Yup.string()
      .matches(passwordRegex, "Invalid password")
      .trim()
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "password must match")
      .required("Confirm Password is required"),
  });
  const onSubmit = (values) => {
    dispatch(
      customerCreateAction(
        {
          profile_picture: imgUrl,
          name: values?.customerName,
          email_address: values?.email,
          country_code: values?.country_code,
          phone_number: values?.phoneNum,
          password: values?.password,
        },
        onSuccess,
        onError
      )
    );
    console.log(values, "customercreate");
  };
  const onSuccess = (data) => {
    successToast(data?.message);
    navigate(`/customers`);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };
  function resetFn() {
    setImageUrl("");
  }
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors, resetForm }) => {
          console.log(values, "custvalues");
          return (
            <Form className="">
              <InnerLayout
                // loading={loading}
                name="New Customer"
                page="create"
                edit={true}
                resetFn={resetFn}
                rolePermission={"customers"}
              />
              <ImageUpload
                labelClass="color_030304"
                page="create"
                getImgUrl={getImgUrl}
                resetFn={resetFn}
                data={imgUrl}
                imgLoading={imgLoading}
                setImgLoading={setImgLoading}
              />
              {imageSize && (
                <label className="fs_14 ms-5 text-danger mb-3">
                  "Please upload below 10mb"
                </label>
              )}
              <div
                className="ms-4 ms-md-5 pe-4 pe-md-0"
                style={{ maxWidth: "40rem" }}
              >
                <div>
                  <InputField
                    placeholder="Enter customer name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Name"
                    name="customerName"
                    type="text"
                  />
                </div>
                <label className="color_030304 fw_500 fs_14">
                  Phone Number
                </label>
                <div className="d-flex mt-1">
                  <span className="">
                    <CountryCode />
                  </span>
                  <span className="ms-2 w-100">
                    <InputField
                      placeholder="Enter phone number"
                      containerClass="mb-3 position-relative"
                      errMsgContainerClass="ps-2"
                      label=""
                      name="phoneNum"
                      type="text"
                    />
                  </span>
                </div>
                <div>
                  <InputField
                    placeholder="Enter email address"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Enter email address"
                    name="email"
                    type="text"
                  />
                </div>
                <div>
                  <InputField
                    placeholder="Create password"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Password"
                    name="password"
                    type="password"
                    isPasswordEye={true}
                  />
                </div>
                <div>
                  <InputField
                    placeholder="Re-enter password"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    isPasswordEye={true}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CustomerCreate;
