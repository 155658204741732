import React from "react";
import "./buttons.css";

const ToggleSwitch = ({
  name = "Active",
  id = "",
  label,
  handleToggleBtnChange,
  value,
  containerClassName,
  labelStart = false,
  store_active_data = false,
}) => {
  return (
    <div className={`d-flex gap-2 py-2 fs_14 ${containerClassName} rounded-2`}>
      {labelStart && (
        <label
          className={`fw_500 ${
            label === "Out of Stock"
              ? value
                ? `text-black`
                : `color_707070`
              : value
              ? `primary_color`
              : `text-black`
          }`}
        >
          {label}
        </label>
      )}
      <label className="switch">
        <input
          onClick={handleToggleBtnChange}
          id={id}
          checked={value}
          type="checkbox"
          name={name}
          value="checkboxValue"
        />
        <span className="slider round"></span>
      </label>
      {labelStart === false && (
        <label
          className={`fw_500 ${
            label === "Out of Stock"
              ? value
                ? `text-black`
                : `color_707070`
              : value
              ? `primary_color`
              : `text-black`
          }`}
        >
          {label}
        </label>
      )}
      {store_active_data && (
        <>
          {value ? (
            <span className="text-success">Opened</span>
          ) : (
            <span className="text-danger">Closed</span>
          )}
        </>
      )}
    </div>
  );
};

export default ToggleSwitch;

export const SettingsToggle = ({
  id = "",
  onClick,
  title = "Store Hours",
  subtitle = "Quick enable or disable business hours",
  handleToggleBtnChange,
  name = "",
  value = false,
}) => {
  return (
    <div className="d-flex justify-content-between gap-5 py-2 fs_14">
      <div>
        <div className={`fs_18 fw_500 color_030304`}>{title}</div>
        <div className="color_707070 fs_xs_12">{subtitle}</div>
      </div>
      <label className="switch">
        <input
          onClick={onClick}
          id={id}
          checked={value}
          type="checkbox"
          name={name}
          value="checkboxValue"
          onChange={handleToggleBtnChange}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};
