import React from "react";

const EyeOpenIcon = ({ className = "", fill = "#2820DA", onClick = () => { } }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99993 13.332C11.8402 13.332 13.332 11.8402 13.332 9.99993C13.332 8.15967 11.8402 6.66785 9.99993 6.66785C8.15967 6.66785 6.66785 8.15967 6.66785 9.99993C6.66785 11.8402 8.15967 13.332 9.99993 13.332Z"
        fill="#2820DA"
      />
      <path
        d="M19.389 7.84992C18.097 5.7457 15.1581 2.21533 9.99998 2.21533C4.8419 2.21533 1.90299 5.7457 0.610965 7.84992C-0.203655 9.16752 -0.203655 10.8324 0.610965 12.15C1.90299 14.2542 4.8419 17.7846 9.99998 17.7846C15.1581 17.7846 18.097 14.2542 19.389 12.15C20.2036 10.8324 20.2036 9.16752 19.389 7.84992ZM9.99998 14.9981C7.2396 14.9981 5.00184 12.7603 5.00184 9.99994C5.00184 7.23956 7.2396 5.0018 9.99998 5.0018C12.7604 5.0018 14.9981 7.23956 14.9981 9.99994C14.9954 12.7592 12.7592 14.9953 9.99998 14.9981Z"
        fill={fill}
      />
    </svg>
  );
};

export default EyeOpenIcon;
