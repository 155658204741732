import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/ui/layouts/inner-layout";
import { useDispatch } from "react-redux";
import {
  adminUsersEditAction,
  adminUsersImageUploadAction,
  adminUsersViewAction,
} from "../../../redux/actions/admin-users-action";
import { Form, Formik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import InputField from "../../../components/ui/forms/input-field";
import SelectField from "../../../components/ui/forms/select-field";
import ImageUpload from "../../../components/ui/forms/image-upload";
import { formatDate } from "../../../components/helper-functions/helper/date";
import { genderOptions } from "../../../components/helper-functions/helper/data";
import { adminRoleListAction } from "../../../redux/actions/admin-roles-action";
import successToast from "../../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../../components/helper-functions/helper/error-toast";
import dayjs from "dayjs";
import {
  nameRegex,
  phoneNumberRegex,
} from "../../../components/helper-functions/helper/regex";
import { isEqual } from "lodash";
import LoadingSpinnerTable from "../../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../../components/notificationComponent";
export default function AdminUsersEdit() {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [adminUsersData, setAdminUsersData] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [imgUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  useEffect(() => {
    setLoading(true);
    dispatch(adminUsersViewAction(params.id, onSuccess, onError));
    dispatch(adminRoleListAction(onRoleListSuccess, onRoleListError));
  }, [params.id]);

  const onRoleListSuccess = (data) => {
    setRoleList(
      data.data.map((item) => {
        return { label: item.role_name, value: item.id };
      })
    );
  };
  const onRoleListError = () => {
    console.log("failed to load role list");
  };

  const onSuccess = (data) => {
    setError(false);
    setAdminUsersData(data.data);
    setImageUrl(data.data.profile_picture);
    setLoading(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
    errorToast(data.data.message);
  };
  const initialValues = {
    name: adminUsersData?.name ?? "",
    mobile: adminUsersData?.mobile ?? "",
    email_address: adminUsersData?.email_address ?? "",
    role_id: adminUsersData?.role_id ?? "",
    gender: adminUsersData?.gender ?? "",
    dob: formatDate(adminUsersData.dob, "YYYY-MM-DD") ?? "",
  };
  const Schema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegex, "Enter a valid name")
      .trim()
      .required("Required"),
    mobile: Yup.string()
      .matches(phoneNumberRegex, "Enter a valid mobile number")
      .trim()
      .required("Required"),
    email_address: Yup.string().trim().email().required("Required"),
    dob: Yup.date()
      .required("Required")
      .test("dob", "You must be at least 16 years old", function (value) {
        const ageInYears = dayjs().diff(dayjs(value), "year");
        return ageInYears >= 16;
      }),
    gender: Yup.string().trim().required("Required"),
    role_id: Yup.string().trim().required("Required"),
  });
  function resetFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setImageUrl(adminUsersData.profile_picture);
    }
  }
  const getImgUrl = (img) => {
    dispatch(adminUsersImageUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImageUrl(url);
  };
  const onImgError = (data) => {
    errorToast(data.data.message ?? "Failed to upload image");
  };
  const onEditSuccess = (data) => {
    successToast(data.message);
    if (currentPage) {
      navigate("/admin/users", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onEditError = (data) => {
    errorToast(data.data.message);
  };

  return (
    <>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          dispatch(
            adminUsersEditAction(
              { ...values, profile_picture: imgUrl, admin_id: params.id },
              onEditSuccess,
              onEditError
            )
          );
        }}
        validationSchema={Schema}
      >
        {({ values }) => {
          return (
            <Form>
              <InnerLayout
                name={adminUsersData?.code}
                page="edit"
                edit={true}
                resetFn={resetFn}
                rolePermission={"admin_users"}
                path={"/admin/users"}
                disabled={
                  isEqual(initialValues, values) &&
                  adminUsersData.profile_picture === imgUrl
                }
              />

              {loading ? (
                <LoadingSpinnerTable />
              ) : (
                <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
                  <ImageUpload
                    title="Profile Picture"
                    containerClass=""
                    name="Profile Picture"
                    getImgUrl={getImgUrl}
                    resetFn={resetFn}
                    data={imgUrl}
                  />
                  <InputField
                    id={"name"}
                    label={"Name"}
                    placeholder={"Enter admin name"}
                    name={"name"}
                  />
                  <InputField
                    id={"email"}
                    label={"Email Address"}
                    placeholder={"Enter email address"}
                    name={"email_address"}
                  />
                  <InputField
                    id={"mobile"}
                    label={"Mobile Number"}
                    placeholder={"Enter mobile number"}
                    name={"mobile"}
                  />
                  <SelectField
                    labelClass="color_9E9E9E d-flex fs_14 mb-1"
                    placeholder={"Select role"}
                    defaultValue={roleList.find(
                      (item) => item.id === values.role_id
                    )}
                    label={"Role"}
                    id={"role_id"}
                    name="role_id"
                    options={roleList}
                    errMsgContainerClass="ps-2"
                  />
                  <InputField
                    id={"dob"}
                    label={"DOB"}
                    placeholder={"Select date of birth"}
                    name={"dob"}
                    type="date"
                  />
                  <SelectField
                    placeholder={"Select gender"}
                    defaultValue={genderOptions.find((item) => {
                      return item.value === values.gender.toLocaleLowerCase();
                    })}
                    options={genderOptions}
                    label={"Gender"}
                    name="gender"
                    errMsgContainerClass="ps-2"
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
