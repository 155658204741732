import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import InnerLayout from "../../../components/ui/layouts/inner-layout";
import ImageUpload from "../../../components/ui/forms/image-upload";
import InputField from "../../../components/ui/forms/input-field";
import {
  passwordRegex,
  phoneNumberRegex,
} from "../../../components/helper-functions/helper/regex";
import { nameRegex } from "../../../components/helper-functions/helper/regex";
import SelectField from "../../../components/ui/forms/select-field";
import { genderOptions } from "../../../components/helper-functions/helper/data";
import { useDispatch } from "react-redux";
import { adminRoleListAction } from "../../../redux/actions/admin-roles-action";
import {
  adminUsersCreateAction,
  adminUsersImageUploadAction,
} from "../../../redux/actions/admin-users-action";
import successToast from "../../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../../components/helper-functions/helper/error-toast";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import NotificationComponent from "../../../components/notificationComponent";
export default function AdminUsersCreate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imgUrl, setImageUrl] = useState("");
  const [roleList, setRoleList] = useState([]);
  const initialValues = {
    name: "",
    email_address: "",
    mobile: "",
    dob: "",
    gender: "",
    role_id: "",
    password: "",
    confirm_password: "",
  };
  const Schema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegex, "Enter a valid name")
      .trim()
      .required("Required"),
    mobile: Yup.string()
      .matches(phoneNumberRegex, "Enter a valid mobile number")
      .trim()
      .required("Required"),
    email_address: Yup.string().trim().email().required("Required"),
    dob: Yup.date()
      .required("Required")
      .test("dob", "You must be at least 16 years old", function (value) {
        const ageInYears = dayjs().diff(dayjs(value), "year");
        return ageInYears >= 16;
      }),
    gender: Yup.string().required("Required"),
    role_id: Yup.string().required("Required"),
    password: Yup.string()
      .matches(
        passwordRegex,
        "Password must be at least 8 characters, include 1 uppercase, 1 number, and 1 special character (!@#$%^&*)"
      )
      .trim()
      .required("Password is required"),
    confirm_password: Yup.string()
      .required("Required")
      .test("password-match", "Your password didn't match", function (value) {
        return this.parent.password === value;
      }),
  });
  useEffect(() => {
    dispatch(adminRoleListAction(onRoleListSuccess, onRoleListError));
  }, []);
  const onRoleListSuccess = (data) => {
    setRoleList(
      data.data.map((item) => {
        return { label: item.role_name, value: item.id };
      })
    );
  };
  const onRoleListError = () => {
    console.log("failed to load role list");
  };

  const onSuccess = (data) => {
    successToast(data.message);
    navigate("/admin/users");
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  function resetFn() {
    setImageUrl("");
  }
  const getImgUrl = (img) => {
    dispatch(adminUsersImageUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImageUrl(url);
  };
  const onImgError = (data) => {
    errorToast(data.data.message ?? "Failed to upload image");
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        const formValues = { ...values };
        delete formValues.confirm_password;
        dispatch(
          adminUsersCreateAction(
            { ...formValues, profile_picture: imgUrl },
            onSuccess,
            onError
          )
        );
      }}
      validationSchema={Schema}
    >
      <Form>
        <InnerLayout
          name={"New Admin User"}
          page="create"
          path={"/admin/users"}
          resetFn={resetFn}
          rolePermission={"admin_users"}
        />
        <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
          <ImageUpload
            labelClass="color_030304 d-flex fs_14 mb-1 fw_500"
            title="Profile Picture"
            containerClass="rounded-2 overflow-hidden"
            name="Profile Picture"
            getImgUrl={getImgUrl}
            resetFn={resetFn}
            data={imgUrl}
          />
          <InputField
            id={"name"}
            label={"Name"}
            placeholder={"Enter admin name"}
            name={"name"}
          />
          <InputField
            autoComplete="email"
            id={"email"}
            label={"Email Address"}
            placeholder={"Enter email address"}
            name={"email_address"}
          />
          <InputField
            id={"mobile"}
            label={"Mobile Number"}
            placeholder={"Enter mobile number"}
            name={"mobile"}
          />
          <SelectField
            placeholder={"Select role"}
            label={"Role"}
            id={"role_id"}
            name="role_id"
            options={roleList}
            errMsgContainerClass="ps-2"
          />
          <InputField
            id={"dob"}
            label={"DOB"}
            placeholder={"Select date of birth"}
            name={"dob"}
            type="date"
          />{" "}
          <SelectField
            placeholder={"Select gender"}
            options={genderOptions}
            label={"Gender"}
            id={"gender"}
            name="gender"
            isSearchable={false}
            errMsgContainerClass="ps-2"
          />
          <InputField
            autoComplete="new-password"
            id={"password"}
            label={"Password"}
            placeholder={"Enter password"}
            name={"password"}
            type="password"
            isPasswordEye={true}
          />
          <InputField
            autoComplete="new-password"
            id={"confirmPassword"}
            label={"Confirm Password"}
            placeholder={"Enter confirm password"}
            name={"confirm_password"}
            type="password"
            isPasswordEye={true}
          />{" "}
        </div>
      </Form>
    </Formik>
  );
}
