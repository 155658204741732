import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import "../../modals/modal.css";
import { CancelButton } from "../../buttons/text-button";
import DoubtIcon from "../../../../assets/images/Doubt-icon.svg";
import { useDispatch } from "react-redux";
import { ordersEditAction } from "../../../../redux/actions/orders-action";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import UploadInvoice from "./upload-invoice";
const OrderStatusChange = ({
  show, modalClose, selectedOption, orderDetails, id, reload, setReload,
  paymentOption,
  text = "Are you sure, you want to change the order status to Delivered?",
}) => {
  const [uploadShow, setUploadShow] = useState(false)
  const [fileType, setFileType] = useState("")

  const dispatch = useDispatch()
  const onSubmit = () => {

    setUploadShow(true)
    modalClose()

    // dispatch(
    //   ordersEditAction(
    //     {
    //       "order_id": id,
    //       "orders_status": selectedOption?.value,
    //       "payment_admin_status": paymentOption?.value
    //     },
    //     onEditSuccess,
    //     onEditError
    //   )
    // )
  };
  const onEditSuccess = (data) => {
    setReload(!reload)
    successToast("Order Updated SuccessFully")
    modalClose();
  }
  const onEditError = (data) => {
    errorToast(data?.data?.message)
  }
  return (
    <div>
      <UploadInvoice
        reload={reload}
        setReload={setReload}
        id={id}
        selectedOption={selectedOption}
        paymentOption={paymentOption}
        fileType={fileType}
        show={uploadShow}
        setFileType={setFileType}
        modalClose={() => { setUploadShow(false); setFileType("") }} />
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="delete_modal mx-auto"
        contentClassName="rounded-3"
      >
        <ModalBody className="d-flex justify-content-center my-3">
          <div className="col-sm-9 col-11">
            <div className="text-center mb-3">
              <img src={DoubtIcon} alt="delete-icon" />
            </div>
            <div className="text-black text-center mb-4">{text}</div>
            <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
              <div onClick={modalClose}>
                <CancelButton />
              </div>
              <div onClick={onSubmit}>
                <CancelButton
                  name="CHANGE"
                  bgColor="#2820DA"
                  textColor="text-white"
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OrderStatusChange;
