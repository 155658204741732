import React from "react";

const CustomPieChartToolTip = ({ active, payload, totalOrder }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    console.log(payload, "datdfsfsda");
    return (
      <div
        className={`bg_color_ffffff px-4 box_shadow py-1 rounded-2 d-flex align-items-center  position-relative ps-2`}
        style={{ boxShadow: "0px 0px 15px 0px #0000001A" }}
      >
        {/* <div className={`${styles.left_pointer}`}></div> */}
        <span className="fw_500 fs_18">{data.value.toFixed(2)} %</span>
      </div>
    );
  }
  return null;
};

export default CustomPieChartToolTip;
