import React, { useEffect, useState } from "react";
import { BackButton, SaveButton } from "../../buttons/text-button";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../forms/input-field";
import {
  numbRegex,
  percentRegex,
} from "../../../helper-functions/helper/regex";
import RadioButton from "../../forms/radio-button";
import {
  delTypeOptions,
  delUnitsOptions,
  deliveryChargeType,
} from "../../../helper-functions/helper/data";
import VariantsLocationWeight from "./variants-location-weight";
import { useDispatch } from "react-redux";
import {
  shippingCreateAction,
  shippingViewAction,
} from "../../../../redux/actions/configuration-action";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import isEqual from "lodash.isequal";
import { viewDataFn } from "../../../helper-functions/helper/helper";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";
import ErrorMsg from "../../forms/error-msg";

function ShippingSettings({ closeSection }) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const [viewData, setViewData] = useState();
  const [numVariations, setNumVariations] = useState(1);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    dispatch(shippingViewAction(onViewSuccess, onViewError));
  }, [reload]);
  const onViewSuccess = (data) => {
    setLoading(false);
    setViewData(data?.data);
    const initData = data?.data;
    setNumVariations(
      initData?.variable_loc_weight?.length > 0
        ? initData?.variable_loc_weight?.length
        : 1
    );
    setInitialValues({
      delivery_options: initData?.delivery_options
        ? initData?.delivery_options
        : ["pickup", "delivery"],
      delivery_type: initData?.delivery_type ? initData?.delivery_type : "self",
      estimated_delivery_unit: initData?.estimated_delivery_unit
        ? initData?.estimated_delivery_unit
        : "minutes",
      shipping_tax_rate: initData?.shipping_tax_rate
        ? initData?.shipping_tax_rate
        : null,
      estimated_delivery_time: initData?.estimated_delivery_time
        ? initData?.estimated_delivery_time
        : null,
      default_delivery_charge: initData?.dd_charge ? initData?.dd_charge : null,
      default_delivery_charge_above_limit: initData?.dd_charge_above_limit
        ? initData?.dd_charge_above_limit
        : null,
      delivery_charge: initData?.delivery_charge
        ? initData?.delivery_charge
        : null,
      delivery_charge_limit: initData?.delivery_charge_above_limit
        ? initData?.delivery_charge_above_limit
        : null,
      delivery_charge_type:
        initData?.delivery_charge_type === "based_on_loc_weight"
          ? "based_on_loc_weight"
          : "fixed_rate",
      variable_loc_weight: Array.from(
        {
          length:
            initData?.variable_loc_weight?.length > 0
              ? initData?.variable_loc_weight?.length
              : 1,
        },
        (_, i) => ({
          id: initData?.variable_loc_weight?.[i]?.id
            ? initData?.variable_loc_weight?.[i]?.id
            : "",
          var_delivery_charge_type:
            initData?.variable_loc_weight?.[i]?.var_delivery_charge_type ===
            "weight_based"
              ? "weight_based"
              : "fixed",
          var_delivery_charge: initData?.variable_loc_weight?.[i]
            ?.var_delivery_charge
            ? initData?.variable_loc_weight?.[i]?.var_delivery_charge
            : "",
          var_delivery_charge_above_limit: initData?.variable_loc_weight?.[i]
            ?.var_delivery_charge_above_limit
            ? initData?.variable_loc_weight?.[i]
                ?.var_delivery_charge_above_limit
            : "",
          weight_price: Array.from(
            {
              length:
                initData?.variable_loc_weight?.[i]?.min_order_weight?.length > 0
                  ? initData?.variable_loc_weight?.[i]?.min_order_weight?.length
                  : 1,
            },
            (_, ind) => ({
              id: initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                ?.id
                ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                    ?.id
                : "",
              min_order_weight: initData?.variable_loc_weight?.[i]
                ?.min_order_weight?.[ind]?.min_weight
                ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                    ?.min_weight
                : "",
              max_order_weight: initData?.variable_loc_weight?.[i]
                ?.min_order_weight?.[ind]?.max_weight
                ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                    ?.max_weight
                : "",
              price: initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                ?.value
                ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                    ?.value
                : "",
            })
          ),
        })
      ),
    });
  };
  const onViewError = (data) => {
    setLoading(false);
  };

  const [weightPriceVariations, setWeightPriceVariations] = useState(1);
  const [formValues, setFormValues] = useState();

  const [initialValues, setInitialValues] = useState({
    delivery_options: [],
    delivery_type: "",
    shipping_tax_rate: "",
    estimated_delivery_unit: "",
    estimated_delivery_time: "",
    default_delivery_charge: null,
    default_delivery_charge_above_limit: null,
    delivery_charge: "",
    delivery_charge_limit: "",
    delivery_charge_type: "fixed_rate",
    variable_loc_weight: Array.from({ length: numVariations }, (_, i) => ({
      var_delivery_charge_type: "fixed",
      var_delivery_charge: "",
      var_delivery_charge_above_limit: "",
      weight_price: Array.from({ length: numVariations }, (_, i) => ({
        min_order_weight: "",
        max_order_weight: "",
        price: "",
      })),
    })),
  });
  const validationSchema = Yup.object().shape({
    delivery_options: Yup.array()
      .of(Yup.string()) // Ensure that all elements are strings
      .min(1, "At least one item is required"),
    delivery_type: Yup.string().required("required"),
    ...(formValues?.delivery_type === "self"
      ? {
          estimated_delivery_unit: Yup.string().required("required!"),
          shipping_tax_rate: Yup.string()
            .trim()
            .required("Shipping tax is required")
            .matches(percentRegex, "Enter a valid number"),
          estimated_delivery_time: Yup.string()
            .trim()
            .required("Estimated time is required")
            .matches(numbRegex, "Enter a valid number"),
          default_delivery_charge: Yup.string()
            .trim()
            .required("Default Delivery Charge is required")
            .matches(numbRegex, "Enter a valid number"),
          default_delivery_charge_above_limit: Yup.string()
            .trim()
            .required("Default Delivery Charge Above Limit is required")
            .matches(numbRegex, "Enter a valid number"),
          delivery_charge:
            formValues?.delivery_charge_type === "fixed_rate"
              ? Yup.string()
                  .trim()
                  .required("Delivery Charge is required")
                  .matches(numbRegex, "Enter a valid number")
              : Yup.string().trim().nullable(),
          delivery_charge_limit:
            formValues?.delivery_charge_type === "fixed_rate"
              ? Yup.string()
                  .trim()
                  .required("Delivery charge limit is required")
                  .matches(numbRegex, "Enter a valid number")
              : Yup.string().trim().nullable(),
          delivery_charge_type: Yup.string().trim().required("Required"),
          variable_loc_weight:
            formValues?.delivery_charge_type === "fixed_rate"
              ? Yup.array()
              : Yup.array().of(
                  Yup.object().shape({
                    var_delivery_charge_type: Yup.string()
                      .trim()
                      .required("Required"),
                    var_delivery_charge: Yup.string().test(
                      "delivery-charge",
                      "Delivery charge is required",
                      function (value) {
                        const varDeliveryChargeType =
                          this.parent.var_delivery_charge_type;
                        if (varDeliveryChargeType === "fixed") {
                          return Yup.string()
                            .trim()
                            .required("Delivery charge is required")
                            .matches(numbRegex, "Enter a valid number")
                            .isValidSync(value);
                        }
                        return true;
                      }
                    ),
                    var_delivery_charge_above_limit: Yup.string()
                      .trim()
                      .required("Delivery charge above limit is required")
                      .matches(numbRegex, "Enter a valid number"),
                  })
                ),
        }
      : {}),
  });
  const onSubmit = (values) => {
    console.log(values, "valuesvalues");
    let hasErrors = false;
    for (let i = 0; i < values.variable_loc_weight.length; i++) {
      const item = values.variable_loc_weight[i];
      if (item.var_delivery_charge_type === "weight_based") {
        const isValuesEmpty = item.weight_price.some(
          (price) =>
            !price.min_order_weight || !price.max_order_weight || !price.price
        );

        if (isValuesEmpty) {
          hasErrors = true;
          break;
        }
      }
    }
    if (!isAddingLocation && !hasErrors) {
      dispatch(
        shippingCreateAction(
          {
            delivery_options: values?.delivery_options,
            delivery_type: values?.delivery_type,
            ...(values?.delivery_type !== "adair"
              ? {
                  shipping_tax_rate: values?.shipping_tax_rate
                    ? parseInt(values?.shipping_tax_rate)
                    : null,
                  estimated_delivery_unit: values?.estimated_delivery_unit,
                  estimated_delivery_time: parseFloat(
                    values?.estimated_delivery_time
                  ),
                  dd_charge: parseInt(values?.default_delivery_charge),
                  dd_charge_above_limit: parseInt(
                    values?.default_delivery_charge_above_limit
                  ),
                  delivery_charge_type: values?.delivery_charge_type,
                  delivery_charge:
                    values?.delivery_charge &&
                    values?.delivery_charge_type === "fixed_rate"
                      ? parseInt(values?.delivery_charge)
                      : null,
                  delivery_charge_above_limit:
                    values?.delivery_charge_limit &&
                    values?.delivery_charge_type === "fixed_rate"
                      ? parseInt(values?.delivery_charge_limit)
                      : null,
                  variable_loc_weight:
                    values?.delivery_charge_type === "fixed_rate"
                      ? []
                      : values?.variable_loc_weight?.map((item) => ({
                          id: "",
                          var_delivery_charge_type:
                            item?.var_delivery_charge_type,
                          var_delivery_charge:
                            item?.var_delivery_charge_type === "fixed"
                              ? item?.var_delivery_charge
                              : null,
                          var_delivery_charge_above_limit:
                            item?.var_delivery_charge_above_limit,
                          min_order_weight:
                            item?.var_delivery_charge_type === "fixed"
                              ? []
                              : item?.weight_price.map((price) => ({
                                  id: "",
                                  min_weight: price?.min_order_weight,
                                  max_weight: price?.max_order_weight,
                                  value: price?.price,
                                })),
                        })),
                }
              : {
                  shipping_tax_rate: null,
                  dd_charge: null,
                  dd_charge_above_limit: null,
                  delivery_charge: null,
                  delivery_charge_above_limit: null,
                  delivery_charge_type: "fixed_rate",
                  estimated_delivery_time: null,
                  variable_loc_weight: [],
                }),
          },
          onSuccess,
          onError
        )
      );
    } else {
      setIsAddingLocation(false);
    }
  };
  const onSuccess = (data) => {
    successToast(data.message);
    setReload(!reload);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  const handleAddWeight = (index) => {
    setInitialValues((prevInitialValues) => {
      const updatedVariableLocWeight = [
        ...prevInitialValues.variable_loc_weight,
      ];
      // const newVariantId = uuidv4();
      updatedVariableLocWeight[index].weight_price.push({
        // id: newVariantId,
        min_order_weight: "",
        max_order_weight: "",
        price: "",
      });
      return {
        ...prevInitialValues,
        variable_loc_weight: updatedVariableLocWeight,
      };
    });
  };

  const handleRemoveWeight = (index) => {
    setInitialValues((prevInitialValues) => {
      const updatedVariableLocWeight = [
        ...prevInitialValues.variable_loc_weight,
      ];
      const weightPriceArray = updatedVariableLocWeight[index].weight_price;
      if (weightPriceArray.length > 1) {
        weightPriceArray.pop();
      }
      return {
        ...prevInitialValues,
        variable_loc_weight: updatedVariableLocWeight,
      };
    });
  };
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    const areEqualweight = isEqual(
      viewDataFn(viewData)?.variable_loc_weight,
      initialValues?.variable_loc_weight
    );
    if (areEqual && areEqualweight) {
      return true;
    } else {
      return false;
    }
  }

  const changeDeliveryType = (main, sub, setFieldValue) => {
    if (viewData?.delivery_type !== sub) {
      setFieldValue("shipping_tax_rate", "");
      setFieldValue("estimated_delivery_unit", "");
      setFieldValue("estimated_delivery_time", "");
      setFieldValue("default_delivery_charge", "");
      setFieldValue("delivery_charge", "");
      setFieldValue("delivery_charge_limit", "");
      setFieldValue("default_delivery_charge_above_limit", "");
      setFieldValue("delivery_charge_type", "fixed_rate");
      setFieldValue(
        "variable_loc_weight",
        Array.from({ length: numVariations }, (_, i) => ({
          var_delivery_charge_type: "fixed",
          var_delivery_charge: "",
          var_delivery_charge_above_limit: "",
          weight_price: Array.from({ length: numVariations }, (_, i) => ({
            min_order_weight: "",
            max_order_weight: "",
            price: "",
          })),
        }))
      );
    } else if (viewData?.delivery_type === sub) {
      setFieldValue(
        "shipping_tax_rate",
        viewData?.shipping_tax_rate ? viewData?.shipping_tax_rate : null
      );
      setFieldValue(
        "estimated_delivery_time",
        viewData?.estimated_delivery_time
          ? viewData?.estimated_delivery_time
          : null
      );
      setFieldValue(
        "estimated_delivery_unit",
        viewData?.estimated_delivery_unit
          ? viewData?.estimated_delivery_unit
          : null
      );
      setFieldValue(
        "default_delivery_charge",
        viewData?.dd_charge ? viewData?.dd_charge : null
      );
      setFieldValue(
        "default_delivery_charge_above_limit",
        viewData?.dd_charge_above_limit ? viewData?.dd_charge_above_limit : null
      );
      setFieldValue(
        "delivery_charge_type",
        viewData?.delivery_charge_type === "based_on_loc_weight"
          ? "based_on_loc_weight"
          : "fixed_rate"
      );
      setFieldValue(
        "variable_loc_weight",
        Array.from(
          {
            length:
              viewData?.variable_loc_weight?.length > 0
                ? viewData?.variable_loc_weight?.length
                : 1,
          },
          (_, i) => ({
            id: viewData?.variable_loc_weight?.[i]?.id
              ? viewData?.variable_loc_weight?.[i]?.id
              : "",
            var_delivery_charge_type:
              viewData?.variable_loc_weight?.[i]?.var_delivery_charge_type ===
              "weight_based"
                ? "weight_based"
                : "fixed",
            var_delivery_charge: viewData?.variable_loc_weight?.[i]
              ?.var_delivery_charge
              ? viewData?.variable_loc_weight?.[i]?.var_delivery_charge
              : "",
            var_delivery_charge_above_limit: viewData?.variable_loc_weight?.[i]
              ?.var_delivery_charge_above_limit
              ? viewData?.variable_loc_weight?.[i]
                  ?.var_delivery_charge_above_limit
              : "",
            weight_price: Array.from(
              {
                length:
                  viewData?.variable_loc_weight?.[i]?.min_order_weight?.length >
                  0
                    ? viewData?.variable_loc_weight?.[i]?.min_order_weight
                        ?.length
                    : 1,
              },
              (_, ind) => ({
                id: viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                  ?.id
                  ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                      ?.id
                  : "",
                min_order_weight: viewData?.variable_loc_weight?.[i]
                  ?.min_order_weight?.[ind]?.min_weight
                  ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                      ?.min_weight
                  : "",
                max_order_weight: viewData?.variable_loc_weight?.[i]
                  ?.min_order_weight?.[ind]?.max_weight
                  ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                      ?.max_weight
                  : "",
                price: viewData?.variable_loc_weight?.[i]?.min_order_weight?.[
                  ind
                ]?.value
                  ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                      ?.value
                  : "",
              })
            ),
          })
        )
      );
      setFieldValue(
        "delivery_charge",
        viewData?.delivery_charge ? viewData?.delivery_charge : null
      );
      setFieldValue(
        "delivery_charge_limit",
        viewData?.delivery_charge_above_limit
          ? viewData?.delivery_charge_above_limit
          : null
      );
    }
  };

  // const getData = {
  //   estimated_delivery_unit: viewData?.estimated_delivery_unit
  //     ? viewData?.estimated_delivery_unit
  //     : "minutes",
  //   shipping_tax_rate: viewData?.shipping_tax_rate
  //     ? viewData?.shipping_tax_rate
  //     : null,
  //   estimated_delivery_time: viewData?.estimated_delivery_time
  //     ? viewData?.estimated_delivery_time
  //     : null,
  //   default_delivery_charge: viewData?.dd_charge ? viewData?.dd_charge : null,
  //   default_delivery_charge_above_limit: viewData?.dd_charge_above_limit
  //     ? viewData?.dd_charge_above_limit
  //     : null,
  //   delivery_charge: viewData?.delivery_charge
  //     ? viewData?.delivery_charge
  //     : null,
  // delivery_charge_limit: viewData?.delivery_charge_above_limit
  //   ? viewData?.delivery_charge_above_limit
  //   : null,
  //   delivery_charge_type:
  // viewData?.delivery_charge_type === "based_on_loc_weight"
  //   ? "based_on_loc_weight"
  //   : "fixed_rate",
  //   variable_loc_weight: Array.from(
  //     {
  //       length:
  //         viewData?.variable_loc_weight?.length > 0
  //           ? viewData?.variable_loc_weight?.length
  //           : 1,
  //     },
  //     (_, i) => ({
  //       id: viewData?.variable_loc_weight?.[i]?.id
  //         ? viewData?.variable_loc_weight?.[i]?.id
  //         : "",
  //       var_delivery_charge_type:
  //         viewData?.variable_loc_weight?.[i]?.var_delivery_charge_type ===
  //         "weight_based"
  //           ? "weight_based"
  //           : "fixed",
  //       var_delivery_charge: viewData?.variable_loc_weight?.[i]
  //         ?.var_delivery_charge
  //         ? viewData?.variable_loc_weight?.[i]?.var_delivery_charge
  //         : "",
  //       var_delivery_charge_above_limit: viewData?.variable_loc_weight?.[i]
  //         ?.var_delivery_charge_above_limit
  //         ? viewData?.variable_loc_weight?.[i]?.var_delivery_charge_above_limit
  //         : "",
  //       weight_price: Array.from(
  //         {
  //           length:
  //             viewData?.variable_loc_weight?.[i]?.min_order_weight?.length > 0
  //               ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.length
  //               : 1,
  //         },
  //         (_, ind) => ({
  //           id: viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]?.id
  //             ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]?.id
  //             : "",
  //           min_order_weight: viewData?.variable_loc_weight?.[i]
  //             ?.min_order_weight?.[ind]?.min_weight
  //             ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
  //                 ?.min_weight
  //             : "",
  //           max_order_weight: viewData?.variable_loc_weight?.[i]
  //             ?.min_order_weight?.[ind]?.max_weight
  //             ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
  //                 ?.max_weight
  //             : "",
  //           price: viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
  //             ?.value
  //             ? viewData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
  //                 ?.value
  //             : "",
  //         })
  //       ),
  //     })
  //   ),
  // };

  return (
    <>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors }) => {
            setFormValues(values);
            return (
              <Form>
                <div>
                  <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                    <div
                      className="mb-0 px-xxl-4 d-flex align-items-center"
                      style={{ width: "fit-Content" }}
                    >
                      <span>
                        <img
                          className="d-inline-block d-md-none"
                          onClick={closeSection}
                          src={backIcon}
                        />
                      </span>
                      <span className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat ms-2">
                        Shipping Setting
                      </span>
                    </div>
                    <div
                      className="me-xxl-5 pe-xxl-5"
                      style={{ width: "fit-Content" }}
                    >
                      {/* {canWrite("configuration") &&
                    ( */}
                      <div className="d-flex gap-3">
                        <BackButton
                          disabled={valuesCompareFn(values)}
                          undoFn={() => setReload(!reload)}
                          type="reset"
                          name="UNDO"
                          backClassName="fs_14"
                        />
                        <SaveButton
                          disabled={valuesCompareFn(values)}
                          type="submit"
                          backClassName="fs_14"
                        />
                      </div>
                      {/* )
                  } */}
                    </div>
                  </div>
                </div>
                <div
                  className="mt-5 ms-xl-5 me-xl-5 ms-4 me-4"
                  style={{ maxWidth: "40rem" }}
                >
                  <Field
                    name="delivery_options"
                    render={({ field, form }) => (
                      <div>
                        <label className=" color_9E9E9E fw_400 d-flex fs_14 mb-1">
                          Delivery Options
                        </label>
                        <div className="mt-1 d-flex gap-4">
                          {["pickup", "delivery"].map((method, index) => (
                            <div className="form-check" key={method}>
                              <input
                                type="checkbox"
                                id={`order_alert_${method}`}
                                className="form-check-input"
                                checked={field.value?.includes(method)}
                                onChange={() => {
                                  const updatedMethods = field?.value?.includes(
                                    method
                                  ) // Remove the nested .delivery_options
                                    ? field?.value?.filter(
                                        (item) => item !== method
                                      )
                                    : [...field.value, method];
                                  form.setFieldValue(
                                    "delivery_options",
                                    updatedMethods
                                  );
                                }}
                              />
                              <label
                                className="form-check-label color_030304 fs_16"
                                htmlFor={`order_alert_${method}`}
                              >
                                {method === "pickup"
                                  ? "Pickup"
                                  : method === "delivery"
                                  ? "Delivery"
                                  : "Delivery"}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                  <ErrorMsg name="delivery_options" />

                  <div className="mb-4 mt-4">
                    <RadioButton
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("delivery_type", value);
                        changeDeliveryType(
                          values?.delivery_type,
                          value,
                          setFieldValue
                        );
                      }}
                      labelClass="color_9E9E9E d-flex fs_14 mb-2"
                      name="delivery_type"
                      options={delTypeOptions}
                      label="Delivery Type"
                    />
                  </div>

                  {values?.delivery_type !== "adair" && (
                    <>
                      <InputField
                        unitClassName="text-nowrap"
                        isUnit={true}
                        id={"shipping_tax_rate"}
                        label={"Shipping Tax Rate"}
                        placeholder={"Enter tax rate"}
                        name={"shipping_tax_rate"}
                        unitPosition="right"
                        unitType="%"
                      />
                      <div className="mb-5 mt-3">
                        <RadioButton
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("estimated_delivery_unit", value);
                          }}
                          labelClass="color_9E9E9E d-flex fs_14 mb-2"
                          name="estimated_delivery_unit"
                          options={delUnitsOptions}
                          label="Estimated Delivery Time unit"
                        />
                      </div>
                      <InputField
                        unitClassName="text-nowrap"
                        // isUnit={true}
                        id={"estimated_delivery_time"}
                        label={`Estimated Delivery ${
                          values?.estimated_delivery_unit === "days"
                            ? "Days"
                            : "Time"
                        }`}
                        placeholder={`Enter estimated delivery  ${
                          values?.estimated_delivery_unit === "days"
                            ? "Days"
                            : "time"
                        }`}
                        name={"estimated_delivery_time"}
                        // unitPosition="right"
                        // unitType="Day"
                      />
                      <InputField
                        isUnit={true}
                        id={"default_delivery_charge"}
                        label={"Default Delivery Charge"}
                        placeholder={"Enter amount"}
                        name={"default_delivery_charge"}
                      />
                      <InputField
                        isUnit={true}
                        id={"default_delivery_charge_above_limit"}
                        label={"Default Delivery Charge Free Above Limit"}
                        placeholder={"Enter amount"}
                        name={"default_delivery_charge_above_limit"}
                      />

                      <RadioButton
                        inputLabelClass="fw_400"
                        containerClass="mb-4"
                        labelClass="color_9E9E9E d-flex fs_14 mb-1"
                        name="delivery_charge_type"
                        options={deliveryChargeType}
                        label="Delivery Charge Type"
                      />
                      {values?.delivery_charge_type === "fixed_rate" && (
                        <>
                          <h3 className="fs_22  color_030304 font_montserrat fw_500 mb-3 my-5">
                            Fixed Rate
                          </h3>
                          <InputField
                            isUnit={true}
                            id={"delivery_charge"}
                            label={"Delivery Charge"}
                            placeholder={"Enter amount"}
                            name={"delivery_charge"}
                          />
                          <InputField
                            isUnit={true}
                            id={"delivery_charge_limit"}
                            label={"Delivery Charge Free Above Limit"}
                            placeholder={"Enter amount"}
                            name={"delivery_charge_limit"}
                          />
                        </>
                      )}
                      {values?.delivery_charge_type !== "fixed_rate" && (
                        <>
                          <VariantsLocationWeight
                            setIsAddingLocation={setIsAddingLocation}
                            setInitialValues={setInitialValues}
                            initialValues={initialValues}
                            numVariations={numVariations}
                            setNumVariations={setNumVariations}
                            weightPriceVariations={weightPriceVariations}
                            addVariant={(value = 1) => {
                              setNumVariations((prev) => prev + value);
                              setWeightPriceVariations(1);
                              setInitialValues((prevInitialValues) => {
                                const variableLocWeight =
                                  values.variable_loc_weight;
                                const updatedVariableLocWeight = [
                                  ...variableLocWeight,
                                  ...Array.from({ length: value }, (_, i) => ({
                                    var_delivery_charge_type: "fixed",
                                    var_delivery_charge: "",
                                    var_delivery_charge_above_limit: "",
                                    weight_price: Array.from(
                                      { length: weightPriceVariations },
                                      (_, j) => ({
                                        min_order_weight: "",
                                        max_order_weight: "",
                                        price: "",
                                      })
                                    ),
                                  })),
                                ];
                                return {
                                  ...values,
                                  variable_loc_weight: updatedVariableLocWeight,
                                };
                              });
                            }}
                            addWeight={(index) => handleAddWeight(index)}
                            removeWeight={(index) => handleRemoveWeight(index)}
                            validationContext={
                              initialValues.variable_loc_weight
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="row g-0 justify-content-between align-items-center  p-3 top-25">
                  <div
                    className="mb-0 px-xxl-4 d-flex align-items-center"
                    style={{ width: "fit-Content" }}
                  ></div>
                  <div
                    className="me-xxl-5 pe-xxl-5"
                    style={{ width: "fit-Content" }}
                  >
                    <div className="d-flex gap-3">
                      <BackButton
                        disabled={valuesCompareFn(values)}
                        undoFn={() => setReload(!reload)}
                        type="reset"
                        name="UNDO"
                        backClassName="fs_14"
                      />
                      <SaveButton
                        disabled={valuesCompareFn(values)}
                        type="submit"
                        backClassName="fs_14"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export default ShippingSettings;
