import React, { useState } from "react";
import SelectField from "../components/ui/forms/select-field";
import { orderStatusOptions } from "../components/helper-functions/helper/data";
import { Formik } from "formik";

export default function Simple() {
  return <div className="mt-5 pt-5">
    <Formik
      initialValues={{ "category_id": "" }}
    >
      {/* <SelectField
        style={{
          singleValue: (base, state) => ({
            ...base,
            fontWeight: "500",
            color: state.data.color,
            background: state.data.color + "10",
            width: "fit-content",
            padding: "0px 5px", borderRadius: "5px"
          }),
        }}
        placeholder={"Select Category"}
        options={orderStatusOptions}
        label={"Category"}
        isFormikControlled={true}
        name="category_id"
        errMsgContainerClass="ps-2"
      /> */}
    </Formik>
  </div>;
}
