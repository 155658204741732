import {
  CONTACT_ENQUIRY_TABLE,
  CONTACT_ENQUIRY_VIEW,
} from "../types";

export const contactEnquiryTableAction = (data, onSuccess, onError) => {
  console.log(data, "fsdfsdhfsdfsdfgsd");
  return {
    type: CONTACT_ENQUIRY_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const contactEnquiryViewAction = (id, onSuccess, onError) => {
  return {
    type: CONTACT_ENQUIRY_VIEW,
    id,
    onSuccess,
    onError,
  };
};
