import React from "react";

const AttributesIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18H9.3105L18 9.3105V9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0ZM1.5 9C1.49823 7.14061 2.18763 5.34693 3.43429 3.96738C4.68095 2.58782 6.39586 1.72087 8.24594 1.53493C10.096 1.34899 11.9491 1.85733 13.4454 2.96121C14.9417 4.06508 15.9742 5.6857 16.3425 7.50825C14.0128 7.55077 11.7904 8.49517 10.1428 10.1428C8.49517 11.7904 7.55077 14.0128 7.50825 16.3425C5.81446 15.9976 4.29162 15.0786 3.19696 13.7409C2.10229 12.4031 1.50289 10.7285 1.5 9ZM9.0165 16.173C9.10039 14.3021 9.8812 12.5302 11.2054 11.2059C12.5296 9.88158 14.3013 9.10058 16.1722 9.0165L9.0165 16.173Z"
        fill={fill}
      />
    </svg>
  );
};

export default AttributesIcon;
