import React, { useEffect, useState } from "react";
import {
  customerEditAction,
  customerViewAction,
} from "../../redux/actions/customer-action";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import ImageUpload from "../../components/ui/forms/image-upload";
import InputField from "../../components/ui/forms/input-field";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import { categoriesImgUploadAction } from "../../redux/actions/categories-action";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import isEqual from "lodash.isequal";
import Compressor from "compressorjs";
import CountryCode from "../../components/ui/forms/country-code";
import NotificationComponent from "../../components/notificationComponent";
const CustomerEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [error, setError] = useState(true);
  const [customerData, setCustomerData] = useState();
  const [imgUrl, setImageUrl] = useState("");
  const [imageSize, setImageSize] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  useEffect(() => {
    setImageUrl(customerData?.profile_picture);
  }, [customerData]);
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  useEffect(() => {
    setLoading(true);
    dispatch(
      customerViewAction(
        { id: params.id, page: 1, size: 10, search: "" },
        onSuccess,
        onError
      )
    );
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setError(false);
    setCustomerData(data?.data?.fetchCustomer);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };

  const initialValues = {
    name: customerData?.name,
    phone_number: customerData?.phone_number,
    email_address: customerData?.email_address,
    country_code: customerData?.country_code,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(" Name is required"),
    phone_number: Yup.string().trim().required("Phone number is required"),
    email_address: Yup.string().trim().required("Email is required"),
  });
  const onSubmit = (values, action) => {
    dispatch(
      customerEditAction(
        {
          customer_id: customerData?.id,
          profile_picture: imgUrl,
          name: values?.name,
          email_address: values?.email_address,
          country_code: values?.country_code,
          phone_number: values?.phone_number,
          country_code: values?.country_code,
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    setLoading(false);
    successToast(data.message);
    if (currentPage) {
      navigate("/customers", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onEditError = (data) => {
    errorToast(data.data.message);
  };
  function resetFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setImageUrl(customerData?.profile_picture);
    }
  }

  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual && customerData?.profile_picture === imgUrl) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => {
          return (
            <Form className="">
              {loading ? (
                <div className="position-absolute top-50 start-50 translate-middle">
                  <LoadingSpinnerTable />
                </div>
              ) : error ? (
                <div>Error</div>
              ) : (
                <>
                  <InnerLayout
                    name={`${customerData?.id ?? "Customer Edit"}`}
                    loading={loading}
                    page="edit"
                    edit={true}
                    rolePermission={"customers"}
                    path={"/customers"}
                    disabled={valuesCompareFn(values)}
                    resetFn={resetFn}
                  />
                  <ImageUpload
                    name="category_image"
                    getImgUrl={getImgUrl}
                    resetFn={resetFn}
                    data={imgUrl}
                    imgLoading={imgLoading}
                    setImgLoading={setImgLoading}
                  />
                  <div
                    className="ms-5 pe-4 pe-md-0"
                    style={{ maxWidth: "40rem" }}
                  >
                    <InputField
                      placeholder="Enter customer name"
                      containerClass="mb-3 position-relative"
                      errMsgContainerClass="ps-2"
                      label="Name"
                      name="name"
                      type="text"
                    />
                    <label className="color_030304 fw_500 fs_14">
                      Phone Number
                    </label>
                    <div className="d-flex mt-1">
                      <span className="">
                        <CountryCode />
                      </span>
                      <span className="ms-2 w-100">
                        <InputField
                          placeholder="Enter phone number"
                          containerClass="mb-3 position-relative"
                          errMsgContainerClass="ps-2"
                          label=""
                          name="phone_number"
                          type="text"
                        />
                      </span>
                    </div>
                    <div>
                      <InputField
                        placeholder="Enter email address"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Email Address"
                        name="email_address"
                        type="text"
                      />
                    </div>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CustomerEdit;
