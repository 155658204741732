import { all } from "redux-saga/effects";
import {
  watchAdminLoginWorker,
  watchAdminLogoutWorker,
  watchForgotPasswordWorker,
  watchResetPasswordWorker,
  watchSiteSettingsWorker,
  watchstoreActiveWorker,
} from "../auth-saga";

export default function* authRootSaga() {
  yield all([
    watchAdminLoginWorker(),
    watchForgotPasswordWorker(),
    watchAdminLogoutWorker(),
    watchResetPasswordWorker(),
    watchstoreActiveWorker(),
    // watchSiteSettingsWorker()
  ]);
}
