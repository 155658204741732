import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/ui/tables/table";
import { useDispatch } from "react-redux";
import {
  categoriesDeleteAction,
  categoriesMultiDeleteAction,
  categoriesTableAction,
} from "../../redux/actions/categories-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import { useLocation } from "react-router";
import NotificationComponent from "../../components/notificationComponent";

const CategoriesTable = () => {
  const location = useLocation();
  const [ inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [reload, setReload] = useState(false);
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      categoriesTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, reload, inputValue, sortConfig]);
  const onSuccess = (data) => {
    setCategories(data?.data?.categories);
    setPagination(data?.data?.pagination);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
    console.log(data);
  };
  const tableData = categories.map((item) => ({
    id: item?.id,
    code: item?.code,
    category_name: removeUnderScore(item?.category_name),
    Parentname:
      item?.Parentname === null ? "--" : item?.Parentname?.category_name,
    Action: ["/categories/view/", "/categories/edit/"],
  }));
  return (
    <>
     <NotificationComponent />
      <div className=" col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5 ">
        <DynamicTable
          placeholder={"Search category here by name, id"}
          modalText={'category'}
          tableHeading={tableCategoriesHeader}
          tablebody={tableData}
          setInputValue={setInputValue}
          inputValue={inputValue}
          createButtonName="CREATE CATEGORY"
          createpath="/categories/create"
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          singleDeleteAction={categoriesDeleteAction}
          multiDeleteAction={categoriesMultiDeleteAction}
          loading={loading}
          setLoading={setLoading}
          error={error}
          id_name={"category_id"}
          setReload={setReload}
          reload={reload}
          rolePermission={"categories"}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
      </div>
    </>
  );
};
export default CategoriesTable;

const tableCategoriesHeader = [
  { label: "Category ID", value: "code" },
  { label: "Category Name", value: "category_name" },
  { label: "Parent Category", value: "parent_category_id" },
  { label: "Actions", value: "Actions" },
];
