import React, { Fragment } from "react";
import { Field } from "formik";
export default function RadioButton({
  index = 1,
  options,
  isContainer = true,
  containerClass = "",
  name = "",
  label = "",
  labelClass = "d-block color_9E9E9E mb-1",
  buttonContainerClass = "d-flex align-items-center gap-1 color_030304",
  mainContainerClass = "d-flex align-items-center gap-4 flex-wrap",
  inputClass = "",
  inputLabelClass = "",
  onChange,
  disabled = false
}) {
  const Container = isContainer ? "div" : Fragment;
  console.log(options?.[0]?.value, "gfdgdfg");
  console.log(options, "gfdgdfg");
  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {label && (
        <label className={labelClass} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={mainContainerClass}>
        <Field name={name}>
          {({ field }) => {
            return options.map((option, ind) => {
              return (
                <div
                  className={buttonContainerClass}
                  key={option.key ?? option.label}
                >
                  <input
                    disabled={disabled}
                    className={`radio-button ${inputClass}`}
                    type="radio"
                    id={options?.[0]?.value === "fixed" ? (index + option?.value) : option.id ?? option.value}
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                    {...(onChange ? { onChange: onChange } : {})}
                  />
                  <label
                    className={inputLabelClass}
                    htmlFor={options?.[0]?.value === "fixed" ? (index + option?.value) : option.id ?? option.value}
                  >
                    {option.label}
                  </label>
                </div>
              );
            });
          }}
        </Field>
      </div>
    </Container>
  );
}
