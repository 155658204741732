import React, { useState } from "react";
import EditIcon from "../../../assets/icons/editIcon";
import BillingDetails from "./models/billing-details";
import { useDispatch } from "react-redux";
import { billingGenerateAction } from "../../../redux/actions/orders-action";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";

function BillingSection({ data, edit = true, reload, setReload, canWrite }) {
  const [billingDetailsData, setBillingDetailsData] = useState([]);
  const [billDetailsShow, setBillDetailsShow] = useState(false);
  const [couponCode, setCouponCode] = useState({
    couponCode: billingDetailsData?.coupon_code ?? "",
    applied: false,
  });
  const [couponBillingData, setCouponBillingData] = useState([]);
  const dispatch = useDispatch();

  console.log(data, "billingggggg");

  const billingDetails = [
    {
      type: "Total Price Value",
      value: `${
        localStorage?.getItem("currency") ?? "₹ "
      } ${data?.total_cost_value?.toFixed(2)}`,
    },
    {
      type: "Tax",
      value: `${
        localStorage?.getItem("currency") ?? "₹ "
      } ${data?.total_tax?.toFixed(2)}`,
    },
    {
      type: "Shipping Charges",
      value: `${
        localStorage?.getItem("currency") ?? "₹ "
      } ${data?.delivery_charge?.toFixed(2)}`,
    },
    {
      type: "Total with Shipping Charges",
      value: `${
        localStorage?.getItem("currency") ?? "₹ "
      } ${data?.tax_on_delivery_charge?.toFixed(2)}`,
    },
    {
      type: `${data?.discount ? "Coupon Type" : ""}`,
      value: `${data?.discount ? data?.discount?.discount_type : ""}`,
    },
    {
      type: `${data?.discount ? `Coupon(${data?.discount?.coupon_code})` : ""}`,
      value: `${data?.discount ? data?.discount?.coupon_code : ""}`,
    },
    {
      type: `${data?.discount ? "Discount Value" : ""}`,
      value: `${
        data?.discount
          ? `${localStorage?.getItem("currency") + " " ?? "₹ "}` +
            data?.discount_amount?.toFixed(2)
          : ""
      }`,
    },
    {
      type: `${data?.discount ? "Total with Discount Value" : ""}`,
      value: `${
        data?.discount
          ? `${localStorage?.getItem("currency") + " " ?? "₹ "}` +
            data?.total_value_with_discount_with_delivery?.toFixed(2)
          : ""
      }`,
    },
  ];
  function billingEditFn() {
    dispatch(
      billingGenerateAction(
        {
          customer_id: data?.customer_id,
          address_id: data?.address_id,
          products: data?.cart,
          coupon_code: data?.discount?.coupon_code ?? "",
          delivery_type: data?.delivery_type,
        },
        onSuccess,
        onError
      )
    );
  }
  const onSuccess = (data) => {
    console.log(data, "akjfdss");
    successToast(data.message);
    setBillingDetailsData(data?.data);
    setCouponBillingData(data?.data);
    setCouponCode({
      couponCode: data?.data?.coupon_code,
      applied: data?.data?.coupon_code ? true : false,
    });
    setBillDetailsShow(true);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  return (
    <>
      <div className="d-flex gap-2 align-items-center">
        <label className="fs_16 font_montserrat fw_600 mb-2">
          Billing Details
        </label>
        {edit && canWrite("orders") && (
          <button
            onClick={() => billingEditFn()}
            className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1"
          >
            <EditIcon /> <span>Edit</span>
          </button>
        )}
      </div>
      {billingDetails?.map((item) => (
        <div className={`${item?.type && "p-1"} d-flex align-items-center`}>
          <label className="w-75 color_707070 fw_400 font_montserrat fs_16">
            {item?.type}
          </label>{" "}
          <span
            className={`text-nowrap fw_500 font_poppins fs_16 text-end w-25 ${
              item?.type === "Discount Value" || item?.type.includes("Coupon(")
                ? "color_3AD64C"
                : "color_030304"
            }`}
          >
            {item?.value}
          </span>
        </div>
      ))}
      <hr className="border_1px_e5ecff w-100" />
      <div className="p-1 d-flex align-items-center">
        <label className="w-75 color_2820DA  font_montserrat fs_20 fw_700 ">
          Total Value Of Order
        </label>{" "}
        <span
          className={` fw_700 text-nowrap font_poppins fs_20 text-end w-25 color_2820DA `}
        >
          {`${localStorage?.getItem("currency") + " " ?? "₹ "}` +
            data?.total_value_with_discount_with_delivery?.toFixed(2)}
        </span>
      </div>
      <BillingDetails
        deliveryType={data?.delivery_type}
        setBillingDetailsData={setBillingDetailsData}
        couponBillingData={couponBillingData}
        setCouponBillingData={setCouponBillingData}
        type={"edit"}
        show={billDetailsShow}
        modalClose={() => setBillDetailsShow(false)}
        addedProductsData={{
          customer_id: data?.customer_id,
          address_id: data?.address_id,
          products: data?.cart,
        }}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        billingDetailsData={billingDetailsData}
        data={data}
        reload={reload}
        setReload={setReload}
        // addedProductsData={addedProductsData}
      />
    </>
  );
}

export default BillingSection;
