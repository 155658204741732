import { api } from "./api";

export const tagsTableApi = (data, params) => {
  return api.get(
    `/core/tags/list?search=${data?.search}&page=${data?.page}&page_size=${data?.size}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};
export const tagsDeleteApi = (data, params) => {
  return api.delete(`/core/tags/delete?tag_id=${data}`, JSON.stringify(params));
};
export const tagsMultiDeleteApi = (params) => {
  return api.delete(`/core/tags/bulk-delete`, params);
};
export const tagsViewApi = (data) => {
  return api.get(`/core/tags/view?tag_id=${data}`);
};

export const tagsEditApi = (params) => {
  console.log(params);
  return api.post(`/core/tags/edit`, JSON.stringify(params));
};

export const tagsCreateApi = (params) => {
  return api.post(`/core/tags/create`, JSON.stringify(params));
};
export const tagsDropDownListApi = () => {
  return api.get(`/core/tags/list-all-tags`);
};
