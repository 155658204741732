import React from "react";

const LocationIcon = ({ fill = "black", width = "10", height = "14" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C2.24299 0 0 2.27451 0 5.07025C0 8.53985 4.47451 13.6334 4.66502 13.8486C4.84395 14.0507 5.15637 14.0503 5.33498 13.8486C5.52549 13.6334 10 8.53985 10 5.07025C9.99995 2.27451 7.75699 0 5 0ZM5 12.8442C3.4941 11.0302 0.900408 7.45151 0.900408 5.0703C0.900408 2.77796 2.73946 0.913064 5 0.913064C7.26054 0.913064 9.09959 2.77796 9.09959 5.07028C9.09956 7.45159 6.50628 11.0297 5 12.8442Z"
        fill={fill}
      />
      <path
        d="M4.99997 2.51953C3.61285 2.51953 2.48438 3.6639 2.48438 5.07051C2.48438 6.47713 3.61288 7.6215 4.99997 7.6215C6.38707 7.6215 7.51555 6.47713 7.51555 5.07051C7.51555 3.6639 6.38707 2.51953 4.99997 2.51953ZM4.99997 6.70843C4.10933 6.70843 3.38478 5.97368 3.38478 5.07051C3.38478 4.16735 4.10935 3.43259 4.99997 3.43259C5.89059 3.43259 6.61517 4.16735 6.61517 5.07051C6.61517 5.97368 5.89059 6.70843 4.99997 6.70843Z"
        fill={fill}
      />
    </svg>
  );
};

export default LocationIcon;
