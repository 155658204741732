import React from "react";

const LogoutIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1218 7.87954L15.2125 4.97029C14.9146 4.68254 14.4398 4.6908 14.1521 4.98875C13.8713 5.27938 13.8713 5.74018 14.1521 6.03081L17.0613 8.94006C17.1478 9.0282 17.2233 9.12643 17.2863 9.23256C17.2751 9.23256 17.2661 9.22655 17.2548 9.22655L5.49181 9.25056C5.0776 9.25056 4.74182 9.58634 4.74182 10.0006C4.74182 10.4148 5.0776 10.7505 5.49181 10.7505L17.2503 10.7265C17.2713 10.7265 17.2886 10.716 17.3088 10.7145C17.2423 10.8414 17.1579 10.9581 17.0583 11.061L14.1491 13.9703C13.8511 14.258 13.8429 14.7328 14.1306 15.0308C14.4184 15.3287 14.8932 15.337 15.1911 15.0492C15.1974 15.0432 15.2035 15.037 15.2096 15.0308L18.1188 12.1215C19.29 10.95 19.29 9.05102 18.1188 7.87954H18.1218Z"
        fill={fill}
      />
      <path
        d="M6.24999 17.5H4.74998C3.50734 17.5 2.49998 16.4926 2.49998 15.25V4.75001C2.49998 3.50738 3.50734 2.50001 4.74998 2.50001H6.24999C6.6642 2.50001 6.99998 2.16423 6.99998 1.75002C6.99998 1.33581 6.66423 1 6.24999 1H4.74998C2.67998 1.0025 1.0025 2.67998 1 4.75001V15.25C1.0025 17.32 2.67998 18.9975 4.75001 19H6.25002C6.66424 19 7.00001 18.6642 7.00001 18.25C7.00001 17.8358 6.66423 17.5 6.24999 17.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default LogoutIcon;
