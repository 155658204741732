
import { call, takeLatest } from "redux-saga/effects";
import * as discountApi from "../apis/discount-api"
import { DISCOUNT_CREATE, DISCOUNT_DELETE, DISCOUNT_EDIT, DISCOUNT_MULTI_DELETE, DISCOUNT_TABLE, DISCOUNT_VIEW } from "../types";

export function* discountTableWorker(action) {
    try {
        const res = yield call(discountApi.discountTableApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}
export function* discountDeleteWorker(action) {
    try {
        const res = yield call(discountApi.discountDeleteApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}
export function* discountMultiDeleteWorker(action) {
    try {
        const res = yield call(discountApi.discountMutliDeleteApi, { data: action.data });
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}
export function* discountViewWorker(action) {
    try {
        const res = yield call(discountApi.discountViewApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}
export function* discountEditWorker(action) {
    try {
        const res = yield call(discountApi.discountEditApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}
export function* discountCreateWorker(action) {
    try {
        const res = yield call(discountApi.discountCreateApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}


export function* watchDiscountTableWorker() {
    yield takeLatest(DISCOUNT_TABLE, discountTableWorker);
}
export function* watchDiscountDeleteWorker() {
    yield takeLatest(DISCOUNT_DELETE, discountDeleteWorker);
}
export function* watchDiscountMultiDeleteWorker() {
    yield takeLatest(DISCOUNT_MULTI_DELETE, discountMultiDeleteWorker);
}
export function* watchDiscountViewWorker() {
    yield takeLatest(DISCOUNT_VIEW, discountViewWorker);
}
export function* watchDiscountEditWorker() {
    yield takeLatest(DISCOUNT_EDIT, discountEditWorker);
}
export function* watchDiscountCreateWorker() {
    yield takeLatest(DISCOUNT_CREATE, discountCreateWorker);
}