import { api } from "./api";

export const brandsTableApi = (data, params) => {
  return api.get(
    `/core/brands/list?search=${data?.search}&page=${data?.page}&page_size=${data?.size}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`,
    // `/core/brands/list?page=${data?.page}&page_size=${data?.size}&search=${data?.search}`,
    JSON.stringify(params)
  );
};
export const brandsDeleteApi = (data, params) => {
  return api.delete(`/core/brands/delete?brand_id=${data}`, JSON.stringify(params));
};
export const brandsMultiDeleteApi = (params) => {
  return api.delete(`/core/brands/bulk-delete`, params);
};
export const brandsViewApi = (data) => {
  return api.get(`/core/brands/view?brand_id=${data}`);
};

export const brandsEditApi = (params) => {
  console.log(params);
  return api.post(`/core/brands/edit`, JSON.stringify(params));
};

export const brandsCreateApi = (params) => {
  return api.post(`/core/brands/create`, JSON.stringify(params));
};
export const brandsDropDownListApi = () => {
  return api.get(`/core/brands/brands-drop-down`);
};
