import { all } from "redux-saga/effects";
import {
  watchAddressCreateWorker,
  watchAddressDeleteWorker,
  watchAddressEditWorker,
  watchAddressMultiDeleteWorker,
  watchAddressTableWorker,
  watchAddressViewWorker,
  watchCustomerListDropdownWorker,
} from "../address-saga";
export default function* addressRootSaga() {
  yield all([
    watchAddressTableWorker(),
    watchAddressDeleteWorker(),
    watchAddressMultiDeleteWorker(),
    watchAddressViewWorker(),
    watchAddressEditWorker(),
    watchAddressCreateWorker(),
    watchCustomerListDropdownWorker(),
  ]);
}
