export const removeUnderScore = (str) => {
  // return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.replace(/_/g, " ");
  return str
    ?.split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function booleanConverter(value, convertTo = ["Yes", "No"]) {
  if (typeof value === "boolean") {
    return value ? convertTo[0] : convertTo[1];
  }
  return value;
}

export function stringToNumbersConverter(values, keys) {
  let convertedData = {};
  if (keys) {
    convertedData = { ...values };
    for (const key of keys) {
      if (key in convertedData) {
        convertedData[key] = Number(values[key]);
      }
    }
  } else {
    for (let key in values) {
      convertedData[key] = Number(values[key]);
    }
  }

  return convertedData;
}

export function isValueValid(
  value,
  allowedFalsyValues = [undefined, null, "", NaN]
) {
  if (allowedFalsyValues.length > 0) {
    for (const falsyValue of allowedFalsyValues) {
      if (Object.is(value, falsyValue)) {
        return true;
      }
    }
    if (value) {
      return true;
    }
    return false;
  }
  return true;
}
export function truncateString(string, maxLength) {
  console.log(string, "rtrettreter");
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  }
  return string;
}

export function generateRandomId(length = 5) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const getSelectedAndUnselectedProducts = (
  allProductsId,
  addedProductsList
) => {
  const selectedProductIds = addedProductsList?.map((data) => data?.id);
  const unselectedProductIds = allProductsId
    ?.filter((item) => !selectedProductIds?.includes(item?.id))
    ?.map((item) => item?.id);

  return {
    selectedProductData: selectedProductIds,
    unselectedProductData: unselectedProductIds,
  };
};

export function textColorChanger(text) {
  if (text === "accepted" || text === "Order accepted") {
    return "color_04BA71";
  } else if (text === "preparing") {
    return "color_ffc107";
  } else if (text === "Refunded") {
    return "color_0064D2";
  } else if (text === "Order Packed") {
    return "color_F2C025";
  } else if (
    text === "Dispatched" ||
    text === "dispatched" ||
    text === "Refunded" ||
    text === "Processed" ||
    text === "Refund Processing" ||
    text === "Processing"
  ) {
    return "color_2A22E5";
  } else if (
    text === "Delivered" ||
    text === "Paid" ||
    text === "paid" ||
    text === "delivered"
  ) {
    return "color_34BF44";
  } else if (
    text === "Rejected" ||
    text === "cancelled" ||
    text === "Returned" ||
    text === "Unpaid" ||
    text === "unpaid" ||
    text === "rejected" ||
    text === "returned"
  ) {
    return "color_F23D3D";
  } else if (
    text === "Partially Rejected" ||
    text === "Partially Returned" ||
    text === "partially_rejected" ||
    text === "Return Requested" ||
    text === "return_requested" ||
    text === "Order Received" ||
    text === "received" ||
    text === "requested" ||
    text === "Refund Request" ||
    text === "Refund Pending" ||
    text === "Pending"
  ) {
    return "color_FF6C1A";
  }
  if (text === "received") {
    return "color_04BA71";
  } else return "color_030304";
}

export function SelecttextColorChanger(text) {
  if (text === "accepted" || text === "Order Accepted") {
    return "#04BA71";
  } else if (text == "preparing") {
    return "#c29c25";
  } else if (text === "Refunded") {
    return "#0064D2";
  } else if (text === "Order Packed") {
    return "#F2C025";
  } else if (
    text === "Dispatched" ||
    text === "dispatched" ||
    text === "Refunded" ||
    text === "Processed" ||
    text === "Refund Processing" ||
    text === "Processing"
  ) {
    return "#2A22E5";
  } else if (text === "Delivered" || text === "delivered") {
    return "#34BF44";
  } else if (
    text === "Rejected" ||
    text === "cancelled" ||
    text === "Returned" ||
    text === "rejected" ||
    text === "returned" ||
    text === "Cancelled"
  ) {
    return "#F23D3D";
  } else if (text === "Preparing") {
    return "#c29c25";
  } else if (
    text === "Partially Rejected" ||
    text === "Partially Returned" ||
    text === "partially_rejected" ||
    text === "return_requested" ||
    text === "Return Requested" ||
    text === "Order Received" ||
    text === "received" ||
    text === "Requested" ||
    text === "Refund Request" ||
    text === "Refund Pending" ||
    text === "Pending" ||
    text === "None"
  ) {
    return "#FF6C1A";
  } else if (text === "Paid" || text === "paid") {
    return "#34BF44";
  } else if (text === "Unpaid" || text === "unpaid") {
    return "#F23D3D";
  } else if (text === "received") {
    return "#04BA71";
  } else if (text === "successful") return "";
}

export function viewDataFn(initData) {
  console.log(initData, "gytjhufgjhfghjfgjfg");
  return {
    shipping_tax_rate: initData?.shipping_tax_rate
      ? initData?.shipping_tax_rate
      : null,
    estimated_delivery_time: initData?.estimated_delivery_time
      ? initData?.estimated_delivery_time
      : null,
    delivery_charge: initData?.delivery_charge
      ? initData?.delivery_charge
      : null,
    delivery_charge_limit: initData?.delivery_charge_above_limit
      ? initData?.delivery_charge_above_limit
      : null,

    default_delivery_charge: initData?.dd_charge ? initData?.dd_charge : null,
    default_delivery_charge_above_limit: initData?.dd_charge_above_limit
      ? initData?.dd_charge_above_limit
      : null,

    delivery_charge_type:
      initData?.delivery_charge_type === "based_on_loc_weight"
        ? "based_on_loc_weight"
        : "fixed_rate",
    variable_loc_weight: Array.from(
      {
        length:
          initData?.variable_loc_weight?.length > 0
            ? initData?.variable_loc_weight?.length
            : 1,
      },
      (_, i) => ({
        id: initData?.variable_loc_weight?.[i]?.id ?? "",
        var_delivery_charge_type:
          initData?.variable_loc_weight?.[i]?.var_delivery_charge_type ===
          "weight_based"
            ? "weight_based"
            : "fixed",
        var_delivery_charge: initData?.variable_loc_weight?.[i]
          ?.var_delivery_charge
          ? initData?.variable_loc_weight?.[i]?.var_delivery_charge
          : "",
        var_delivery_charge_above_limit: initData?.variable_loc_weight?.[i]
          ?.var_delivery_charge_above_limit
          ? initData?.variable_loc_weight?.[i]?.var_delivery_charge_above_limit
          : "",
        weight_price: Array.from(
          {
            length:
              initData?.variable_loc_weight?.[i]?.min_order_weight?.length > 0
                ? initData?.variable_loc_weight?.[i]?.min_order_weight?.length
                : 1,
          },
          (_, ind) => ({
            id: initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]?.id
              ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]?.id
              : "",
            min_order_weight: initData?.variable_loc_weight?.[i]
              ?.min_order_weight?.[ind]?.min_weight
              ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                  ?.min_weight
              : "",
            max_order_weight: initData?.variable_loc_weight?.[i]
              ?.min_order_weight?.[ind]?.max_weight
              ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                  ?.max_weight
              : "",
            price: initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
              ?.value
              ? initData?.variable_loc_weight?.[i]?.min_order_weight?.[ind]
                  ?.value
              : "",
          })
        ),
      })
    ),
  };
}
export function gstPercentage(taxData, productData) {
  return [
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
    { label: "28%", value: "28" },
    {
      label: `${taxData?.default_tax_rate}%(Default)`,
      value: `${taxData?.default_tax_rate}`,
    },
  ];
}

export function orderStatusFn(status, orderDetails) {
  if (status === "received") {
    return [
      {
        label: "Order Received",
        value: "received",
        color: "#04BA71",
      },
      {
        label: "Preparing",
        value: "preparing",
        color: "#c29c25",
      },
      {
        label: "Dispatched",
        value: "dispatched",
        color: "#2A22E5",
      },
      {
        label: "Delivered",
        value: "delivered",
        color: "#34BF44",
      },
    ];
  } else if (status === "preparing") {
    return [
      {
        label: "Dispatched",
        value: "dispatched",
        color: "#2A22E5",
      },
      {
        label: "Preparing",
        value: "preparing",
        color: "#c29c25",
      },
      {
        label: "Order Accepted",
        value: "accepted",
        color: "#04BA71",
      },
      {
        label: "Delivered",
        value: "delivered",
        color: "#34BF44",
      },
    ];
  } else if (status === "accepted") {
    return [
      {
        label: "Dispatched",
        value: "dispatched",
        color: "#2A22E5",
      },
      {
        label: "Preparing",
        value: "preparing",
        color: "#c29c25",
      },
      {
        label: "Order Accepted",
        value: "accepted",
        color: "#04BA71",
      },
      {
        label: "Delivered",
        value: "delivered",
        color: "#34BF44",
      },
    ];
  } else if (status === "dispatched") {
    return [
      {
        label: "Dispatched",
        value: "dispatched",
        color: "#2A22E5",
      },
      {
        label: "Delivered",
        value: "delivered",
        color: "#34BF44",
      },
      {
        label: "Order Accepted",
        value: "accepted",
        color: "#04BA71",
      },
      {
        label: "Preparing",
        value: "preparing",
        color: "#c29c25",
      },
    ];
  } else if (status === "delivered") {
    return [
      {
        label: "Delivered",
        value: "delivered",
        color: "#34BF44",
      },
      {
        label: "Order Accepted",
        value: "accepted",
        color: "#04BA71",
      },
      {
        label: "Dispatched",
        value: "dispatched",
        color: "#2A22E5",
      },
      {
        label: "Preparing",
        value: "preparing",
        color: "#c29c25",
      },
      ,
    ];
  } else if (status === "rejected") {
    return [
      {
        label: "Rejected",
        value: "rejected",
        color: "#F23D3D",
      },
      {
        label: "Order Accepted",
        value: "accepted",
        color: "#07bc0c",
      },
      {
        label: "Dispatched",
        value: "dispatched",
        color: "#2A22E5",
      },
      {
        label: "Preparing",
        value: "preparing",
        color: "#c29c25",
      },
    ];
  } else if (status === "partially_rejected") {
    return [
      {
        label: "Partially Rejected",
        value: "partially_rejected",
        color: "#FF6C1A",
      },
      {
        label: "Dispatched",
        value: "dispatched",
        color: "#2A22E5",
      },
      {
        label: "Delivered",
        value: "delivered",
        color: "#34BF44",
      },
    ];
  } else if (status === "cancelled") {
    return [
      {
        label: "Cancelled",
        value: "cancelled",
        color: "#F23D3D",
      },
    ];
  } else if (
    status === "return_requested" &&
    orderDetails?.return_status === "accepted"
  ) {
    return [
      {
        label: "Return Requested",
        value: "return_requested",
        color: "#FF6C1A",
      },
      {
        label: "Returned",
        value: "returned",
        color: "#F23D3D",
      },
    ];
  } else if (status === "return_requested") {
    return [
      {
        label: "Return Requested",
        value: "return_requested",
        color: "#FF6C1A",
      },
    ];
  } else if (status === "returned") {
    return [
      {
        label: "Returned",
        value: "returned",
        color: "#F23D3D",
      },
    ];
  }
  const orderStatusOptions = [
    {
      label: "Order Received",
      value: "received",
      color: "#04BA71",
    },
    {
      label: "Order Accepted",
      value: "accepted",
      color: "#0064D2",
    },
    {
      label: "Order Packed",
      value: "Order Packed",
      color: "#F2C025",
    },

    {
      label: "Refunded",
      value: "Refunded",
      color: "#04BA71",
    },
    {
      label: "Cancelled",
      value: "cancelled",
      color: "#F23D3D",
    },
    {
      label: "Returned",
      value: "returned",
      color: "#F23D3D",
    },
    {
      label: "Partially Returned",
      value: "Partially Returned",
      color: "#FF6C1A",
    },
    {
      label: "Return Requested",
      value: "return_requested",
      color: "#FF6C1A",
    },
    {
      label: "Partially Rejected",
      value: "partially_rejected",
      color: "#FF6C1A",
    },
    {
      label: "Preparing",
      value: "preparing",
      color: "#c29c25",
    },
  ];

  // const filteredOptions = orderStatusOptions?.filter(
  //   (item) => !status?.includes(item?.value)
  // );
  // return filteredOptions;
}

export const percentageCalculator = (value, totalvalue) => {
  const percenatge = (value / totalvalue) * 100;
  return percenatge;
};
export const valueCalculator = (value, totalvalue) => {
  const orders = (value * totalvalue) / 100;
  return orders;
};

export function nFormatter(num) {
  // if (num >= 1000000000) {
  //   return (num / 1000000000).toFixed(1).replace(/\.1$/, "") + "G";
  // }
  if (num >= 10000000) {
    return (num / 10000000).toFixed(1).replace(/\.1$/, "") + "CR";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.1$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.1$/, "") + "K";
  }
  return num;
}

export function renameKey(obj, oldKey, newKey) {
  // Check if the old key exists in the object
  if (oldKey in obj) {
    // Create a new object with the same properties as the original object
    const updatedObj = { ...obj };

    // Assign the value of the old key to the new key in the updated object
    updatedObj[newKey] = updatedObj[oldKey];

    // Delete the old key from the updated object
    delete updatedObj[oldKey];

    // Return the updated object
    return updatedObj;
  } else {
    // If the old key doesn't exist in the object, return the original object as-is
    return obj;
  }
}

export function getMonthAbbreviation(monthIndex) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[monthIndex];
}
export function getMonthAbbreviationName(monthName) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthIndex = months?.findIndex(
    (month) => month?.toLowerCase() === monthName?.toLowerCase()
  );
  return months[monthIndex]?.slice(0, 3);
}
