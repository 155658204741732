// import React from "react";
// import Lottie from "lottie-react";
// import animationData from "../../../assets/images/shopping-cart.json";
// const LoadingSpinnerTable = () => {
//   return (
//     <div className="position-absolute top-50 start-50">
//       <>
//         <Lottie
//           animationData={animationData}
//           autoplay
//           loop
//           speed={0.5}
//           style={{
//             width: "75px",
//             height: "75px",
//           }}
//         />
//       </>
//     </div>
//   );
// };

// export default LoadingSpinnerTable;
import React from "react";
import loadIcon from "../../../assets/images/cartLoading.png"
import Lottie from "lottie-react";
import animationData from "../../../assets/images/shopping-cart.json";
const LoadingSpinnerTable = () => {
  const loadingStyles = {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: '9999',
    background: 'linear-gradient(270deg, rgba(230, 230, 230, 0.5) 0%, rgba(255, 255, 255, 0.5) 33.33%, rgba(233, 233, 233, 0.5) 69.79%, rgba(255, 255, 255, 0.5) 100%)',
  };
  const innerDivStyles = {
    opacity: 1,
  };
  return (
    <div style={loadingStyles}>
      <div style={innerDivStyles}>
      <Lottie
          animationData={animationData}
          autoplay
          loop
          speed={0.5}
          style={{
            width: "75px",
            height: "75px",
          }}
        />
      </div>
      <p className="fw_500 fs_32 font_montserrat color_030304">Loading...</p>
    </div >
  );
};

export default LoadingSpinnerTable;
