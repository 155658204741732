import React, { useState } from 'react'
import Select, { components } from 'react-select';
import DownArrowIcon from '../../../assets/icons/down-arrow-icon';
import { customStyles } from '../forms/select-field';
import SelectOrderStatus from './order-status-select';
import { paymentStatus } from '../../helper-functions/helper/data';
import EditIcon from "../../../assets/icons/editIcon";
import EditPaymenDetails from "../../../components/ui/orders/models/edit-paymen-details";
import { removeUnderScore } from '../../helper-functions/helper/helper';


function PaymentDetails({ selectedOption, setSelectedOption, orderDetails,
    edit = true,
    reload,
    setReload, canWrite }) {
    const paymentDetails = orderDetails?.payment_method
    const [paymentDetailShow, setPaymentDetailShow] = useState(false);
    const [transStatus, setTransStatus] = useState(null)
    return (
        <>
            <EditPaymenDetails
                reload={reload}
                setReload={setReload}
                orderDetails={orderDetails}
                setTransStatus={setTransStatus}
                transStatus={transStatus}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                show={paymentDetailShow}
                modalClose={() => setPaymentDetailShow(false)}
            />
            <div className='px-xl-5 mt-xl-0 mt-5'>
                <div className="d-flex gap-2 align-items-center">
                    <label className="fs_16 font_montserrat fw_600 ">
                        Payment Details
                    </label>
                    {
                        edit && canWrite("orders") && <button
                            onClick={() => setPaymentDetailShow(true)}
                            className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1">
                            <EditIcon /> <span>Edit</span>
                        </button>
                    }
                </div>
                <div>
                    <label className="color_707070 fs_14">Payment ID</label>
                    <div className="color_030304 mb-4 fs_13 fw_500">
                        {orderDetails?.pg_order_id}
                    </div>
                    <label className="color_707070 fs_14">Payment Method</label>
                    <div className="color_030304 mb-4 fs_13 fw_500">
                        {removeUnderScore(paymentDetails?.main_payment_type === "CashOnDelivery" ? "Offline" : "Online")}
                    </div>
                    {
                        paymentDetails?.main_payment_type !== "CashOnDelivery" &&
                        <>  <label className="color_707070 fs_14">Transaction ID</label>
                            <div className="color_030304 mb-4 fs_13 fw_500">
                                {orderDetails?.pg_transaction_id ?? "--"}
                            </div>
                            <label className="color_707070 fs_14">Transaction Status</label>
                            <div className={`${orderDetails?.pg_transaction_status === "success" ? "color_04BA71" : "color_F24444"} mb-4 fs_13 fw_500`}>
                                {removeUnderScore(orderDetails?.pg_transaction_status === "success" ? "success" : "failed")}
                            </div>
                        </>
                    }
                    <SelectOrderStatus display={false} isDisabled={true} label='payment Status' options={paymentStatus} selectClass={'col-12'} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                </div>
            </div>
        </>
    )
}

export default PaymentDetails