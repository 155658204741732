import { all } from "redux-saga/effects";
import {
  watchproductsEditWorker,
  watchproductsDeleteWorker,
  watchproductsMultiDeleteWorker,
  watchproductsTableWorker,
  watchproductsViewWorker,
  watchproductsCreateWorker,
  watchallProductsIdWorker,
  watchupdateProductStatusWorker,
  watchupdateProductStockStatusWorker,
} from "../products-saga";

export default function* productsRootSaga() {
  yield all([
    watchproductsTableWorker(),
    watchproductsDeleteWorker(),
    watchproductsMultiDeleteWorker(),
    watchproductsViewWorker(),
    watchproductsEditWorker(),
    watchproductsCreateWorker(),
    watchallProductsIdWorker(),
    watchupdateProductStatusWorker(),
    watchupdateProductStockStatusWorker(),
  ]);
}
