import { call, takeLatest } from "redux-saga/effects";
import {
  BRANDS_CREATE,
  BRANDS_DELETE,
  BRANDS_DROPDOWN_LIST,
  BRANDS_EDIT,
  BRANDS_MULTI_DELETE,
  BRANDS_TABLE,
  BRANDS_VIEW,
} from "../types";
import {
  brandsCreateApi,
  brandsDeleteApi,
  brandsDropDownListApi,
  brandsEditApi,
  brandsMultiDeleteApi,
  brandsTableApi,
  brandsViewApi,
} from "../apis/brands-api";

export function* brandsTableWorker(action) {
  try {
    const res = yield call(brandsTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* brandsViewWorker(action) {
  try {
    const res = yield call(brandsViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* brandsDeleteWorker(action) {
  try {
    const res = yield call(brandsDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* brandsMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(brandsMultiDeleteApi, { data: action.data });
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error);
  }
}

export function* brandsCreateWorker(action) {
  try {
    const res = yield call(brandsCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* brandsEditWorker(action) {
  try {
    const res = yield call(brandsEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* brandsDropDownListWorker(action) {
  try {
    const res = yield call(brandsDropDownListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchBrandsTableWorker() {
  yield takeLatest(BRANDS_TABLE, brandsTableWorker);
}
export function* watchBrandsViewWorker() {
  yield takeLatest(BRANDS_VIEW, brandsViewWorker);
}
export function* watchBrandsEditWorker() {
  yield takeLatest(BRANDS_EDIT, brandsEditWorker);
}
export function* watchBrandsDeleteWorker() {
  yield takeLatest(BRANDS_DELETE, brandsDeleteWorker);
}
export function* watchBrandsMultiDeleteWorker() {
  yield takeLatest(BRANDS_MULTI_DELETE, brandsMultiDeleteWorker);
}
export function* watchBrandsCreateWorker() {
  yield takeLatest(BRANDS_CREATE, brandsCreateWorker);
}
export function* watchBrandsDropDownListWorker() {
  yield takeLatest(BRANDS_DROPDOWN_LIST, brandsDropDownListWorker);
}
