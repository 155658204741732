import React, { useState } from "react";
import { Range } from "react-range";
import "../../ui/tables/table.css";

const MultiRangeSlider = ({ setValues, values, containerClass = "", min = 0, max = 1000, handleChangeFn, table }) => {

    const handleSliderChange = (newValues) => {
        setValues(newValues);
    };
    return (
        <div className={`sliderContainer mt-3 ${containerClass}`}>
            <Range
                min={min}
                max={max}
                step={1}
                values={values}
                onChange={(e) => { handleSliderChange(e); table === "order" && handleChangeFn(e) }}
                renderTrack={({ props, children }) => {
                    const rangeStart = values[0] / (max / 100);
                    const rangeEnd = values[1] / (max / 100);
                    const trackStyle = {
                        ...props.style,
                        height: "5px",
                        borderRadius: "5px",
                        background: "#2820DA" // Set the background color to the desired filled track color
                    };

                    const filledTrackStyle = {
                        ...trackStyle,
                        background: `linear-gradient(to right, #E5ECFF ${rangeStart}%, #E5ECFF ${rangeStart}%, #2820DA ${rangeStart}%, #2820DA ${rangeEnd}%, #E5ECFF ${rangeEnd}%, #E5ECFF ${rangeEnd}%)`,
                        width: "100%",
                        marginLeft: 0
                    };
                    return (
                        <div {...props} className="multiSlider" style={trackStyle}>
                            <div style={filledTrackStyle} />
                            {children}
                        </div>
                    );
                }}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        className="thumb"
                        style={{
                            ...props.style,
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            backgroundColor: "#2820DA",
                            position: "absolute",
                            top: "0px"
                        }}
                    >
                        <div
                            className="innerThumb"
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                                backgroundColor: "#fff"
                            }}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default MultiRangeSlider;
