import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import TableInputFilter from "../table-input-filter";
import CloseIcon from "../../../../assets/icons/close-icon";
import DownArrowIcon from "../../../../assets/icons/down-arrow-icon";

const SearchBar = ({ customerList, setCustId, custId }) => {
  const [dropMenu, setDropMenu] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const mainRef = useRef(null);
  function removeFn(itemToRemove) {
    const updatedItems = custId.filter((item) => item !== itemToRemove);
    setCustId(updatedItems);
    if (custId?.length > 1) {
      custId.slice(0, 2);
    }
  }
  function removeRemainingFn() {
    setCustId(custId.slice(0, 2));
  }
  const hideCharacters = (str, limit) => {
    if (str.length <= limit) {
      return str;
    }

    return str.slice(0, limit) + "...";
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (mainRef.current && !mainRef.current.contains(e.target)) {
        setDropMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [customerList]);
  return (
    <div>
      <div className="position-relative mb-4">
        <label className=" fs_14 mb-1">Customer id</label>
        <div
          style={{ height: "2.5rem", border: "1px solid #E6ECFF" }}
          className={`w-100 bg_color_E5ECFFB2 py-1 bg-white rounded-2 px-2 d-flex align-items-center position-relative ${
            custId?.length === 0 ? `color_9E9E9E fs_14` : `text-black`
          }`}
        >
          <div
            onClick={() => setDropMenu(!dropMenu)}
            className="position-absolute"
            style={{ right: "15px" }}
          >
            <DownArrowIcon />
          </div>
          {custId?.length === 0 ? (
            "Select customer"
          ) : (
            <div className="d-flex w-100 overflow-x-scroll scrollbar">
              {custId?.slice(0, 2).map((item, index) => {
                // Display only the first two items
                return (
                  <div
                    className="px-2 py-1 rounded-1 mx-2 bg_color_E5ECFFB2 text-nowrap fs_12 fw_500 d-flex g-0 align-items-center"
                    key={index}
                  >
                    {hideCharacters(item, 5)}
                    <span
                      className="mx-2 cursor_pointer"
                      onClick={() => removeFn(item)}
                    >
                      <CloseIcon width={8} height={8} />
                    </span>
                  </div>
                );
              })}

              {custId?.length > 2 && ( // Display a single item if more than two items exist
                <div className="px-2 py-1 rounded-1 mx-2 bg_color_E5ECFFB2 text-nowrap fs_12 fw_500 d-flex g-0 align-items-center">
                  {`${custId.length - 2} +`}{" "}
                  <span
                    className="mx-2 cursor_pointer"
                    onClick={() => removeRemainingFn()}
                  >
                    <CloseIcon width={8} height={8} />
                  </span>
                  {/* Display the count of remaining items */}
                </div>
              )}
            </div>
          )}
        </div>
        {dropMenu && (
          <div
            ref={mainRef}
            className="position-absolute bg-white w-100 px-sm-2 py-2"
            style={{
              height: "15rem",
              zIndex: 1,
              top: "4.5rem",
              border: "2px solid #F2F2F2",
            }}
          >
            <Formik>
              <Form>
                <TableInputFilter
                  inputClass={"col-xl-12 col-md-12"}
                  placeholder="customers"
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                />
              </Form>
            </Formik>

            <div
              className="overflow-y-scroll pt-2 scrollbar rounded-3"
              style={{ height: "11rem" }}
            >
              {customerList?.map((item, index) => {
                const itemId = item?.value;
                const itemLabel = item?.label;
                return (
                  <>
                    {inputValue?.length === 0 ? (
                      <div
                        key={index}
                        onClick={() =>
                          setCustId((prevIds) => [...prevIds, item?.value])
                        }
                        className="mb-4 px-1 cursor_pointer text-black fs_14"
                      >
                        {item?.label}
                      </div>
                    ) : (
                      <>
                        {itemLabel
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()) && (
                          <div
                            key={index}
                            onClick={() =>
                              setCustId((prevIds) => [...prevIds, item?.value])
                            }
                            className="mb-4 px-1 cursor_pointer text-black fs_14"
                          >
                            {item?.label}
                          </div>
                        )}
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
