import React from "react";
import { Navigate, Outlet } from "react-router";
// import { api } from "../redux/apis/api";
import ErrorView from "../components/ui/view/error-view";
import { useSelector } from "react-redux";

export default function PrivateRoute() {
  const error = useSelector((state) => state.errorReducer)
  const accessToken = localStorage.getItem("accessToken");
  return accessToken ? (error.status ? <ErrorView error={error} /> : <Outlet />) : <Navigate to="/login" />;
}
