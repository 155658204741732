import React, { useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { ViewContent } from "../../components/ui/view/view-content";
import useLoggedAdminDetails from "../../components/helper-functions/hooks/useLoggedAdmin-details";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";

const AccountsView = () => {
  const loggedAdminData = useLoggedAdminDetails();
  const [isImgLoading, setIsImgLoading] = useState(true);
  const accountsData = {
    profile_picture: loggedAdminData?.data?.data?.profile_picture ?? "",
    name: loggedAdminData?.data?.data?.name ?? "--",
    email_address: loggedAdminData?.data?.data?.email_address ?? "--",
  };
  return (
    <>
     <NotificationComponent />
      {loggedAdminData?.loading ? (
        <LoadingSpinnerTable />
      ) : (
        <React.Fragment>
          <InnerLayout
            name={"Accounts"}
            page="view"
            editButtonPath={`/accounts/edit/${loggedAdminData?.data?.data?.id}`}
            // resetFn={resetFn}
            id={loggedAdminData?.data?.data?.id}
            rolePermission={"account"}
          />{" "}
          <ViewContent
            dataKeys={["profile_picture", "name", "email_address"]}
            labels={[
              {
                label: "Profile Picture",
                renderAs: (url) => {
                  return (
                    // <img
                    //   width={170}
                    //   className="rounded mb-3"
                    //   height={"180px"}
                    //   src={
                    //     loggedAdminData?.data?.data?.profile_picture
                    //       ? loggedAdminData?.data?.data?.profile_picture
                    //       : UplaodImgLogo
                    //   }
                    //   alt="Profile Picture"
                    // />
                    <>
                      {isImgLoading && (
                        <div
                          className="d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
                          style={{ width: "250px", height: "250px" }}
                        >
                          <LoadingSpinner size="lg" />
                        </div>
                      )}
                      <div
                        className={`${imageRegex.test(
                          loggedAdminData?.data?.data?.profile_picture
                        ) === false &&
                          "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
                          }`}
                        style={{
                          width: "250px",
                          height: !isImgLoading && "250px",
                        }}
                      >
                        <img
                          className="rounded-3 mt-1"
                          src={
                            imageRegex.test(
                              loggedAdminData?.data?.data?.profile_picture
                            )
                              ? loggedAdminData?.data?.data?.profile_picture
                              : UplaodImgLogo
                          }
                          width={
                            imageRegex.test(
                              loggedAdminData?.data?.data?.profile_picture
                            )
                              ? "250px"
                              : "100"
                          }
                          height={
                            imageRegex.test(
                              loggedAdminData?.data?.data?.profile_picture
                            )
                              ? "250px"
                              : "100"
                          }
                          alt="image"
                          onLoad={() => setIsImgLoading(false)}
                          style={{ display: isImgLoading ? "none" : "block" }}
                        />
                      </div>
                    </>
                  );
                },
              },
              "Name",
              "Email Address",
            ]}
            data={accountsData}
          />
        </React.Fragment>
      )}
    </>
  );
};

export default AccountsView;
