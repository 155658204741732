import { call, takeLatest } from "redux-saga/effects";
import {
  ADDRESS_CREATE,
  ADDRESS_DELETE,
  ADDRESS_EDIT,
  ADDRESS_MULTI_DELETE,
  ADDRESS_TABLE,
  ADDRESS_VIEW,
  CUSTOMER_LIST_DROPDOWN,
} from "../types";
import {
  addressCreateApi,
  addressDeleteApi,
  addressEditApi,
  addressMultiDeleteApi,
  addressTableApi,
  addressViewApi,
  customerListDropdownApi,
} from "../apis/address-api";

export function* addressTableWorker(action) {
  console.log(action);
  try {
    const res = yield call(addressTableApi, action.data, action.data1);
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addressViewWorker(action) {
  try {
    const res = yield call(addressViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addressDeleteWorker(action) {
  try {
    const res = yield call(addressDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addressMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(addressMultiDeleteApi, { data: action.data });
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error);
  }
}

export function* addressCreateWorker(action) {
  try {
    const res = yield call(addressCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* addressEditWorker(action) {
  try {
    const res = yield call(addressEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* customerListDropdownWorker(action) {
  try {
    const res = yield call(customerListDropdownApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* watchAddressTableWorker() {
  yield takeLatest(ADDRESS_TABLE, addressTableWorker);
}
export function* watchAddressViewWorker() {
  yield takeLatest(ADDRESS_VIEW, addressViewWorker);
}
export function* watchAddressEditWorker() {
  yield takeLatest(ADDRESS_EDIT, addressEditWorker);
}
export function* watchAddressDeleteWorker() {
  yield takeLatest(ADDRESS_DELETE, addressDeleteWorker);
}
export function* watchAddressMultiDeleteWorker() {
  yield takeLatest(ADDRESS_MULTI_DELETE, addressMultiDeleteWorker);
}
export function* watchAddressCreateWorker() {
  yield takeLatest(ADDRESS_CREATE, addressCreateWorker);
}
export function* watchCustomerListDropdownWorker() {
  yield takeLatest(CUSTOMER_LIST_DROPDOWN, customerListDropdownWorker);
}