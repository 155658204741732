import { call, put, takeLatest } from "redux-saga/effects";
import { colorCreateApi, colorViewApi } from "../apis/interface-api";
import { COLOR_CREATE, COLOR_VIEW } from "../types";

/***TAX***/
export function* colorCreateWorker(action) {
  try {
    const res = yield call(colorCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* colorViewWorker(action) {
  console.log(action, "sdafafdsfdsf");
  try {
    const res = yield call(colorViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchColorCreateWorker() {
  yield takeLatest(COLOR_CREATE, colorCreateWorker);
}
export function* watchColorViewWorker() {
  yield takeLatest(COLOR_VIEW, colorViewWorker);
}
