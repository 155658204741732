import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  discountDeleteAction,
  discountViewAction,
} from "../../redux/actions/discount-action";
import { useParams } from "react-router";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { ViewContent } from "../../components/ui/view/view-content";
import errorToast from "../../components/helper-functions/helper/error-toast";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import DiscountedProductsList from "../../components/ui/discounts/discounted-products-list";
import { formatDate } from "../../components/helper-functions/helper/date";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";

export default function DiscountsView() {
  const [addedProductsList, setAddedProductsList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [discountsData, setDiscountsData] = useState({});
  useEffect(() => {
    setLoading(true);
    dispatch(discountViewAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    setError(false);
    setDiscountsData(data.data?.fetchDiscount);
    setAddedProductsList(data?.data?.products);
    console.log(data?.data?.products);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data.data?.message);
    setLoading(false);
    setError(true);
  };
  return (
    <>
      <NotificationComponent />
      {loading ? <LoadingSpinnerTable /> : <>
      <InnerLayout
        deleteAction={discountDeleteAction}
        id={params.id}
        editButtonPath={`/discounts/edit/${params.id}`}
        name={discountsData?.code}
        path={`/discounts`}
        error={error}
        loading={loading}
        rolePermission={"discounts"}
        type="discount"
      />
      <ViewContent
        containerClass={"m-4 mb-0 p-4 pb-2"}
        dataKeys={[
          "discount_name",
          "coupon_code",
          "discount_type",
          "value_of_discount",
          "max_discount_amount",
          "min_order_value",
          "usage_limit",
          "usage_limit",
          "on_all_products",
        ]}
        labels={[
          "Discount Name",
          "Coupon Code",
          {
            label: "Discount Type",
            renderAs: (value) => <p>{removeUnderScore(value)}</p>,
          },
          {
            label: "Value of Discount",
            renderAs: (value, _, data) => (
              <p>
                {data.discount_type === "percentage"
                  ? value + "%"
                  : (localStorage?.getItem("currency") + " " ?? "₹ ") + value}
              </p>
            ),
          },
          {
            label: "Max. Discount Amount",
            renderAs: (value, _, data) =>
              data.discount_type === "percentage" ? (
                <p>{(localStorage?.getItem("currency") + " " ?? "₹ ") + value}</p>
              ) : null,
          },
          {
            label: "Min. Order Value",
            renderAs: (value) => <p>{(localStorage?.getItem("currency") + " " ?? "₹ ") + value}</p>,
          },
          {
            label: "Discount Validity",
            renderAs: () => (
              <div className="d-flex gap-4 color_707070">
                <div>
                  <span>From</span>
                  <p className="color_030304 mb-0">
                    {" "}
                    {formatDate(
                      discountsData?.discount_validity_from,
                      "D MMMM YYYY, hh:mm A"
                    ) ?? "--"}
                  </p>
                </div>
                <div>
                  {" "}
                  <span>To</span>
                  <p className="color_030304 mb-0">
                    {formatDate(
                      discountsData?.discount_validity_to,
                      "D MMMM YYYY, hh:mm A"
                    ) ?? "--"}
                  </p>
                </div>
              </div>
            ),
          },
          {
            label: () => (
              <p className="mb-0">
                {"Usage Limit"}{" "}
                <span className="primary_color">(Per Usage)</span>{" "}
              </p>
            ),
          },
          {
            label: "On All Products",
            renderAs: (value) => <p>{value === "all" ? "Yes" : value === "exclude" ? "Exclude" : "Include"}</p>,
          },
        ]}
        data={discountsData}
      />
      {discountsData?.on_all_products !== "all" && (
        <div className="m-5 mt-0">
          <DiscountedProductsList
            productList={addedProductsList}
            tableDiscountHeader={tableDiscountHeader}
            addedProductsList={addedProductsList}
            action={false}
            page={"view"}
            frontPage={true}
            setInputValue={setInputValue}
            inputValue={inputValue}
            searchTopLabel={`Discount ${
              discountsData?.on_all_products === "exclude"
                ? "Excluded"
                : "Included"
            } Products`}
          />
        </div>
      )}
      </>}
      
    </>
  );
}
const tableDiscountHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "Product ID", value: "id" },
  { label: "Product Name", value: "product_name" },
  { label: "Actions", value: "Actions" },
];
