import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/ui/tables/table";
import { useDispatch } from "react-redux";
import {
  deliverypartnerDeleteAction,
  deliverypartnerMultiDeleteAction,
  deliverypartnerTableAction,
} from "../../redux/actions/delivery-partner-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import { useLocation } from "react-router";
import NotificationComponent from "../../components/notificationComponent";

const BrandsTable = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [deliverypartnerList, setDeliverypartnerList] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      deliverypartnerTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, inputValue, reload, sortConfig]);

  const onSuccess = (data) => {
    setLoading(false);
    setDeliverypartnerList(data?.data?.driver);
    setPagination(data?.data?.pagination);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const tableData = deliverypartnerList?.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: removeUnderScore(item?.name),
    phone_number: item?.mobile,
    vehicle_number: item?.vehicle_number,
    Action: ["/delivery-partner/view/", "/delivery-partner/edit/", !item?.status],
  }));

  

  return (
    <div className=" col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-4 mb-5 ">
      <NotificationComponent />
      <DynamicTable
        tabletitle="Delivery Partners"
        modalText={'delivery partner'}
        placeholder={"Search delivery partner here by id, name"}
        tableHeading={tableTagsHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputVal
        ue={inputValue}
        createButtonName="CREATE DELIVERY PARTNER"
        createpath="/delivery-partner/create"
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={deliverypartnerDeleteAction}
        multiDeleteAction={deliverypartnerMultiDeleteAction}
        setLoading={setLoading}
        loading={loading}
        setReload={setReload}
        reload={reload}
        id_name={"driver_id"}
        rolePermission={"brands"}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
    </div>
  );
};
export default BrandsTable;

const tableTagsHeader = [
  { label: "ID", value: "code" },
  { label: "Delivery Partner Name", value: "name" },
  { label: "Phone Number", value: "mobile" },
  { label: "Vehicle Number", value: "vehicle_number" },
  { label: "Actions", value: "Actions" },
];
