import React from "react";
import arrowRight from "../../../assets/icons/PaginationArrowRight.svg";
import arrowLeft from "../../../assets/icons/pagArrowLeft.svg";

function TablePagination({
  pagination,
  setCurrentPage,
  setCheckedId,
}) {
  const handlePageChange = (value) => {
    if (value === "next") {
      setCurrentPage(pagination?.currentPage + 1);
    } else if (value === "prev") {
      setCurrentPage(pagination?.currentPage - 1);
    } else {
      setCurrentPage(value);
    }
  };
  const getPageNumbers = () => {
    if (pagination?.totalPages <= 5) {
      return Array.from(
        { length: pagination?.totalPages },
        (_, index) => index + 1
      );
    }
    if (pagination?.currentPage <= 2) {
      return [1, 2, 3, 4, 5];
    }
    if (pagination?.currentPage >= pagination?.totalPages - 1) {
      return [
        pagination?.totalPages - 4,
        pagination?.totalPages - 3,
        pagination?.totalPages - 2,
        pagination?.totalPages - 1,
        pagination?.totalPages,
      ];
    }
    const startPage = pagination?.currentPage - 2;
    const endPage = pagination?.currentPage + 2;
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };
  const pageNumbers = getPageNumbers();
  return (
    <>
      {pagination?.totalPages > 1 && (
        <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
          <span className="color_707070 fw_500 fs_16">
            {pagination?.currentPage}/{pagination?.totalPages}
          </span>
          <div className="me-3" style={{ width: "28px" }}>
            <button
              disabled={pagination?.previousPage ? false : true}
              onClick={() => {
                handlePageChange("prev");
                setCheckedId([]);
              }}
              className="border-0 bg-transparent "
            >
              <img width={"28px"} src={arrowLeft} alt="arrow left" />
            </button>
          </div>
          {pageNumbers.map((pageNumber) => (
            <button
              onClick={() => {
                handlePageChange(pageNumber);
                setCheckedId([]);
              }}
              key={pageNumber}
              className={`border-0  ${
                pagination?.currentPage === pageNumber
                  ? "bg_color_E5ECFFB2 primary_color"
                  : "bg-transparent color_030304"
              }   fw_600 fs_18 px-3 rounded-2 py-1`}
            >
              {pageNumber ?? pageNumber}
            </button>
          ))}

          <div>
            <button
              disabled={pagination?.nextPage ? false : true}
              onClick={() => {
                handlePageChange("next");
                setCheckedId([]);
              }}
              className="border-0 bg-transparent "
            >
              <img width={"28px"} src={arrowRight} alt="arrow left" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default TablePagination;
