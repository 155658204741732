import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  attributesDeleteAction,
  attributesViewAction,
} from "../../redux/actions/attributes-action";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";

import OrderHistory from "../../components/ui/orders/order-history";
import BillingSection from "../../components/ui/orders/billing-section";
import PaymentDetails from "../../components/ui/orders/payment-details";
import SelectOrderStatus from "../../components/ui/orders/order-status-select";
import {
  orderAllOptions,
  paymentStatus,
} from "../../components/helper-functions/helper/data";
import ShippingDetails from "../../components/ui/orders/shipping-details";
import PickupDetails from "../../components/ui/orders/pickup-details";
import RefundDetails from "../../components/ui/orders/refund-details";
import DynamicTable from "../../components/ui/tables/table";
import {
  ordersDeliveryPartnersAction,
  ordersEditAction,
  ordersReturnEditAction,
  ordersViewAction,
} from "../../redux/actions/orders-action";
import {
  orderStatusFn,
  removeUnderScore,
  restaurantOrderStatusFn,
} from "../../components/helper-functions/helper/helper";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import AddDeliveryDetails from "../../components/ui/orders/models/add-delivery-details";
import OrderStatusChange from "../../components/ui/orders/models/order-status-change";
import { variationFunction } from "../../components/helper-functions/helper/date";
import OrderRejection from "../../components/ui/orders/models/order-rejection";
import PickupDeliveryDetails from "../../components/ui/orders/models/pick-add-delivery-details";
import usePermissions from "../../components/helper-functions/hooks/usePermission-checker";
import "./orders.css";
import TrackDriver from "../../components/ui/orders/track-driver";
import NotificationComponent from "../../components/notificationComponent";

const OrderStatus = () => {
  const { canWrite } = usePermissions();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [showDeliveryPartner, setShowDeliveryPartner] = useState(false);
  const [showReturnDeliveryPartner, setShowReturnDeliveryPartner] =
    useState(false);
  const DeliveryPartnerClose = () => setShowDeliveryPartner(false);
  const [showDelivered, setShowDelivered] = useState(false);
  const DeliveredClose = () => setShowDelivered(false);
  const [product, setProduct] = useState([]);
  const [neededProduct, setNeededProduct] = useState([]);
  const [orderRejectShow, setOrderRejectShow] = useState(false);
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const industryType = localStorage.getItem("indus_type");
  useEffect(() => {
    dispatch(ordersDeliveryPartnersAction(onPartnerSuccess, onPartnerError));
  }, []);

  const onPartnerSuccess = (data) => {
    setDeliveryPartners(
      data.data.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  };
  const onPartnerError = (data) => {
    console.log(data);
  };
  //ACCEPTFN
  function acceptFn() {
    setLoading(true);
    dispatch(
      ordersEditAction(
        {
          order_id: params?.id,
          orders_status: "accepted",
        },
        onEditSuccess,
        onEditError
      )
    );
  }

  //  UPDATE FUNCTION
  function updateFn(type) {
    if (type === "dispatched") {
      if (localStorage.getItem("delivery_type") === "adair") {
        dispatch(
          ordersEditAction(
            {
              order_id: params?.id,
              orders_status: selectedOption?.value,
            },
            onEditSuccess,
            onEditError
          )
        );
      } else {
        setShowDeliveryPartner(true);
      }
    } else if (type === "delivered") {
      if (localStorage.getItem("delivery_type") === "adair") {
        dispatch(
          ordersEditAction(
            {
              order_id: params?.id,
              orders_status: selectedOption?.value,
              payment_admin_status: "paid",
              order_invoice: "",
            },
            onEditSuccess,
            onEditError
          )
        );
      } else {
        setShowDelivered(true);
      }
    } else if (type === "returned") {
      dispatch(
        ordersReturnEditAction(
          {
            order_id: params?.id,
            return_status: "completed",
            orders_status: "returned",
          },
          onEditSuccess,
          onEditError
        )
      );
    } else {
      dispatch(
        ordersEditAction(
          {
            order_id: params?.id,
            orders_status: selectedOption?.value,
            // "payment_admin_status": paymentOption?.value
          },
          onEditSuccess,
          onEditError
        )
      );
    }
  }
  // REJECT FUNCTION
  function rejectRetunFn(type) {
    dispatch(
      ordersReturnEditAction(
        {
          order_id: params?.id,
          return_status: type === "accept" ? "accepted" : "rejected",
        },
        onEditSuccess,
        onEditError
      )
    );
  }
  const onEditSuccess = (data) => {
    setReload(!reload);
    successToast("Order Updated SuccessFully");
    setLoading(false);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
    setLoading(false);
  };

  console.log(params.id);
  useEffect(() => {
    dispatch(ordersViewAction(params.id, onSuccess, onError));
  }, [reload]);
  const onSuccess = (data) => {
    setLoading(false);
    setOrderDetails(data?.data);
    setProduct(data?.data?.cart);
    setNeededProduct(data?.data?.cart);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [paymentOption, setPaymentOption] = useState(null);
  useEffect(() => {
    if (orderDetails) {
      setSelectedOption(
        orderAllOptions?.find((item) =>
          item?.value?.includes(orderDetails?.orders_status)
        ) || []
      );
      setPaymentOption(
        paymentStatus?.find((item) =>
          item?.value?.includes(
            orderDetails?.payment_admin_status === "paid" ? "paid" : "unpaid"
          )
        ) || []
      );
    }
  }, [orderDetails]);

  console.log(paymentOption, "paymentOptionpaymentOption");

  function variantFn(item) {
    if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
      return item?.variation?.[0]?.variants?.map((item) => {
        return { value: item };
      });
    } else if (item?.variation && !Array.isArray(item?.variation)) {
      return item?.variation?.variants?.map((item) => {
        console.log({ value: item });
        return { value: item };
      });
    } else return ["--"];
  }
  const tableData = orderDetails?.cart?.map((item) => ({
    product_picture: item?.thumbnail_image ?? "product_picture",
    product_name: item?.product_name ?? "--",
    variant: variantFn(item),
    qty: item?.quantity,
    addons: item?.add_ons
      ? item?.add_ons?.map((item) =>
          item?.add_on_items?.map((addon) => {
            return (
              <p className="color_030304 fw_400 bg_order rounded-3 py-2 px-2 m-0">
                {addon?.name}
                <span className="color_9E9E9E">
                  {addon?.quantity === 1 ? " " : " x "}
                </span>
                {addon?.quantity === 1 ? " " : addon?.quantity}
                <span className="fw_500 primary_color ms-3">
                  ₹ {addon?.price}
                </span>
              </p>
            );
          })
        )
      : "--",
    // sku: variationFunction(item, "sku"),
    // bar_code: variationFunction(item, "barcode"),
    price: variationFunction(item, "price")
      ? parseInt(variationFunction(item, "price")).toFixed(2)
      : "--",
    discount_price: variationFunction(item, "discount")
      ? parseInt(variationFunction(item, "discount"))?.toFixed(2)
      : "--",
    // total_price: (localStorage?.getItem("currency") + " " ?? "₹ ") + (item?.total_price)?.toFixed(2),
    total_price: item?.product_total_price
      ? (localStorage?.getItem("currency") + " " ?? "₹ ") +
        item?.product_total_price?.toFixed(2)
      : "--",

    "": "",
  }));
  const oldTableData = orderDetails?.rejectedOrders?.order?.cart?.map(
    (item) => ({
      product_picture: item?.thumbnail_image ?? "product_picture",
      product_name: item?.product_name ?? "--",
      variant: variantFn(item),
      qty: item?.quantity,
      addons: item?.add_ons
        ? item?.add_ons?.map((item) =>
            item?.add_on_items?.map((addon) => {
              return (
                <p className="color_030304 fw_400 bg_order rounded-3 py-2 px-2 m-0">
                  {addon?.name}
                  <span className="color_9E9E9E">
                    {addon?.quantity === 1 ? " " : " x "}
                  </span>
                  {addon?.quantity === 1 ? " " : addon?.quantity}
                  <span className="fw_500 primary_color ms-3">
                    ₹ {addon?.price}
                  </span>
                </p>
              );
            })
          )
        : "--",
      // sku: variationFunction(item, "sku"),
      // bar_code: variationFunction(item, "barcode"),
      price: variationFunction(item, "price"),
      discount_price: variationFunction(item, "discount"),
      // total_price: (localStorage?.getItem("currency") + " " ?? "₹ ") + item?.total_price,
      total_price: item?.product_total_price
        ? (localStorage?.getItem("currency") + " " ?? "₹ ") +
          item?.product_total_price?.toFixed(2)
        : "--",

      "": "",
    })
  );
  const returnProductsData = orderDetails?.return_products?.map((item) => ({
    product_picture: item?.thumbnail_image ?? "product_picture",
    product_name: item?.product_name ?? "--",
    variant: variantFn(item),
    qty: item?.quantity,
    addons: item?.add_ons
      ? item?.add_ons?.map((item) =>
          item?.add_on_items?.map((addon) => {
            return (
              <p className="color_030304 fw_400 bg_order rounded-3 py-2 px-2 m-0">
                {addon?.name}
                <span className="color_9E9E9E">
                  {addon?.quantity === 1 ? " " : " x "}
                </span>
                {addon?.quantity === 1 ? " " : addon?.quantity}
                <span className="fw_500 primary_color ms-3">
                  ₹ {addon?.price}
                </span>
              </p>
            );
          })
        )
      : "--",
    // sku: variationFunction(item, "sku") ?? "--",
    // bar_code: variationFunction(item, "barcode"),
    price: variationFunction(item, "price"),
    discount_price: variationFunction(item, "discount"),
    total_price: item?.product_total_price
      ? (localStorage?.getItem("currency") + " " ?? "₹ ") +
        item?.product_total_price?.toFixed(2)
      : "--",
    "": "",
  }));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPage = searchParams.get("fromPage");

  return (
    <>
      <NotificationComponent />
      <InnerLayout
        createdAt={orderDetails?.createdAt}
        details={orderDetails}
        type="order"
        name={orderDetails?.code}
        id={orderDetails?.id}
        deleteAction={attributesDeleteAction}
        path={
          fromPage === "view"
            ? `/customers/view/${orderDetails?.customer_id}?currentPage=1`
            : `/orders`
        }
        rolePermission={"orders"}
        acceptFn={() => acceptFn()}
        updateFn={() => updateFn(selectedOption?.value)}
        rejectFn={() => setOrderRejectShow(true)}
        // acceptReturnFn={() => setShowReturnDeliveryPartner(true)}
        acceptReturnFn={() => rejectRetunFn("accept")}
        rejectRetunFn={() => rejectRetunFn()}
      />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <div className={`py-5 px-lg-5 px-3`}>
          <div className="row">
            <div className="col-xl-8">
              <div className="fs_19 font_montserrat fw_600 mb-2">
                Order Details
              </div>
              <label className="color_707070 fs_14">Order Value</label>
              <div className="color_030304 mb-4 fs_13 fw_500">
                {orderDetails?.total_value_with_discount_with_delivery
                  ? (localStorage?.getItem("currency") + " " ?? "₹ ") +
                    orderDetails?.total_value_with_discount_with_delivery?.toFixed(
                      2
                    )
                  : "--"}
              </div>
              <SelectOrderStatus
                isFormik={false}
                options={orderStatusFn(
                  orderDetails?.orders_status,
                  orderDetails
                )}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <label className="color_707070 fs_14 mt-4">Delivery Type</label>
              <div className="color_030304 mb-4 fs_13 fw_500">
                {removeUnderScore(orderDetails?.delivery_type ?? "--")}
              </div>
              {localStorage.getItem("delivery_type") === "adair" &&
                orderDetails?.qwqerData?.otp && (
                  <>
                    <label className="color_707070 fs_14">
                      One Time Password
                    </label>
                    <div className="color_030304 mb-4 fs_13 fw_500">
                      {orderDetails?.qwqerData?.otp}
                    </div>
                  </>
                )}
              {orderDetails?.return_products != null && (
                <>
                  <div className="fs_19 font_montserrat fw_600 mt-4">
                    Return Details
                  </div>
                  <label className="color_707070 fs_14 mt-2">
                    Return Status
                  </label>
                  <div
                    className={` ${
                      orderDetails?.return_status === "accepted" ||
                      orderDetails?.return_status === "completed"
                        ? "color_3AD64C"
                        : orderDetails?.return_status === "rejected"
                        ? "color_F23D3D"
                        : "color_030304"
                    }  mb-4 fs_13 fw_500 `}
                  >
                    {removeUnderScore(orderDetails?.return_status)}
                  </div>
                </>
              )}
              {orderDetails?.reject_reason && (
                <>
                  <label className="color_707070 fs_14 mt-4">
                    Rejection Reason
                  </label>
                  <div className="color_030304 mb-4 fs_13 fw_500">
                    {orderDetails?.reject_reason}
                  </div>
                </>
              )}
              {orderDetails?.return_reason != null && (
                <>
                  <label className="color_707070 fs_14 mt-1">
                    Return Reason
                  </label>
                  <div className="color_030304 mb-4 fs_13 fw_500">
                    {orderDetails?.return_reason}
                  </div>
                </>
              )}
              {orderDetails?.rejectedOrders != null && (
                <>
                  <div className="fs_18 font_montserrat fw_600 mt-5">
                    Product Details
                  </div>
                  <DynamicTable
                    tabletitle="Products"
                    tableHeading={tableDiscountHeader}
                    tablebody={oldTableData}
                    isBorderLess={true}
                    variants={true}
                    isMinHeight={false}
                    actionClass=""
                  />
                  <div className="row p-3">
                    <div className="col-xl-6">
                      <BillingSection
                        canWrite={canWrite}
                        edit={false}
                        type={false}
                        data={orderDetails?.rejectedOrders?.order}
                      />
                    </div>
                    <div className="col-xl-6">
                      <PaymentDetails
                        canWrite={canWrite}
                        edit={false}
                        type={false}
                        selectedOption={paymentOption}
                        setSelectedOption={setPaymentOption}
                        orderDetails={orderDetails}
                        reload={reload}
                        setReload={setReload}
                      />
                    </div>
                  </div>
                </>
              )}
              {/* MAIN BILLING SECTION */}
              <div className="fs_18 font_montserrat fw_600 mt-5">
                Product Details{" "}
                {orderDetails?.rejectedOrders != null &&
                  " - After Rejected Partially"}
              </div>
              <DynamicTable
                tabletitle="Products"
                tableHeading={tableDiscountHeader}
                tablebody={tableData}
                isBorderLess={true}
                variants={true}
                isMinHeight={false}
                actionClass=""
              />
              {orderDetails?.comments != "" &&
                orderDetails?.comments != null && (
                  <div className="ms-3">
                    <label className="color_707070 fs_14 mt-5">Comments</label>
                    <div className="color_030304 mb-4 fs_13 fw_500">
                      {orderDetails?.comments}
                    </div>
                  </div>
                )}
              <div className="row p-3 mt-5">
                <div className="col-xl-6">
                  <BillingSection
                    canWrite={canWrite}
                    data={orderDetails}
                    reload={reload}
                    setReload={setReload}
                  />
                </div>
                <div className="col-xl-6">
                  <PaymentDetails
                    canWrite={canWrite}
                    selectedOption={paymentOption}
                    setSelectedOption={setPaymentOption}
                    orderDetails={orderDetails}
                    reload={reload}
                    setReload={setReload}
                  />
                </div>
              </div>
              {orderDetails?.return_products != null && (
                <>
                  <div className="fs_18 font_montserrat fw_600 mt-5">
                    Product Details - Returned
                  </div>
                  <DynamicTable
                    tabletitle="Products"
                    tableHeading={tableDiscountHeader}
                    tablebody={returnProductsData}
                    isBorderLess={true}
                    variants={true}
                    isMinHeight={false}
                    actionClass=""
                  />
                </>
              )}
              <ShippingDetails
                canWrite={canWrite}
                deliveryPartners={deliveryPartners}
                reload={reload}
                setReload={setReload}
                orderDetails={orderDetails}
                data={orderDetails?.address}
                selectedOption={selectedOption}
              />
              {orderDetails?.return_pickup_address && (
                <PickupDetails
                  canWrite={canWrite}
                  data={orderDetails?.return_pickup_address}
                  orderDetails={orderDetails}
                  reload={reload}
                  setReload={setReload}
                  id={params?.id}
                  deliveryPartners={deliveryPartners}
                />
              )}
              {orderDetails?.payment_admin_status === "paid" &&
                orderDetails?.refund_bank_account_details && (
                  <RefundDetails
                    canWrite={canWrite}
                    id={params?.id}
                    data={orderDetails?.refund_bank_account_details}
                    orderDetails={orderDetails}
                    reload={reload}
                    setReload={setReload}
                  />
                )}
              <div className="mb-5 pb-5">
                {orderDetails?.liveTrack && (
                  <>
                    <div className="fs_18 font_montserrat fw_600 my-3">
                      Live Driver Details
                    </div>
                    <TrackDriver
                      orderDetails={orderDetails}
                      driverLatLng={{
                        lat: parseFloat(
                          orderDetails?.liveTrack?.lat ?? 12.2958104
                        ),
                        lng: parseFloat(
                          orderDetails?.liveTrack?.long ?? 76.63938
                        ),
                      }}
                      userLatLng={{
                        lat: parseFloat(
                          orderDetails?.address?.lat ?? 12.2958104
                        ),
                        lng: parseFloat(orderDetails?.address?.lng ?? 76.63938),
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-xl-4">
              <OrderHistory orderDetails={orderDetails} />
            </div>
          </div>
        </div>
      )}
      <OrderRejection
        orderDetails={orderDetails}
        setProduct={setProduct}
        product={product}
        reload={reload}
        setReload={setReload}
        show={orderRejectShow}
        modalClose={() => setOrderRejectShow(false)}
        setOrderRejectShow={setOrderRejectShow}
        id={params?.id}
        neededProduct={neededProduct}
        setNeededProduct={setNeededProduct}
      />
      <AddDeliveryDetails
        deliveryPartners={deliveryPartners}
        orderDetails={orderDetails}
        reload={reload}
        setReload={setReload}
        show={showDeliveryPartner}
        modalClose={DeliveryPartnerClose}
        selectedOption={selectedOption}
        id={params?.id}
        paymentOption={paymentOption}
      />
      <PickupDeliveryDetails
        deliveryPartners={deliveryPartners}
        orderDetails={orderDetails}
        reload={reload}
        setReload={setReload}
        show={showReturnDeliveryPartner}
        modalClose={() => setShowReturnDeliveryPartner(false)}
        selectedOption={selectedOption}
        id={params?.id}
      />
      <OrderStatusChange
        paymentOption={paymentOption}
        orderDetails={orderDetails}
        reload={reload}
        setReload={setReload}
        show={showDelivered}
        modalClose={DeliveredClose}
        selectedOption={selectedOption}
        id={params?.id}
      />
    </>
  );
};
export default OrderStatus;
const tableDiscountHeader = [
  { label: "Pictures", value: "product_picture" },
  { label: "Name", value: "product_name" },
  { label: "Variants", value: "variant" },
  { label: "Qty", value: "qty" },
  { label: "Add-Ons", value: "addons" },
  // { label: "SKU ", value: "sku" },
  // { label: "Barcode", value: "bar_code" },
  { label: "Price", value: "price" },
  { label: "Discounted Price", value: "discount_price" },
  { label: "Total Price", value: "total_price" },
  { label: "", value: "" },
];
