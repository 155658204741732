import React from "react";
import Select, { components } from "react-select";
import DownArrowIcon from "../../../assets/icons/down-arrow-icon";
import ErrorMsg from "./error-msg";
import { Field, useFormikContext } from "formik";

const MultiSelect = ({
  options,
  label,
  value,
  onChange,
  placeholder = "Select Tags",
  name,
  errorMsgShow = true,
  containerClassName = "mb-3",
}) => {
  const { values, setValues, errors, touched, setFieldValue } =
    useFormikContext();

  console.log(value, "");
  const handleChange = (selectedOptions) => {
    console.log(selectedOptions, "selectedOptions");
    onChange(selectedOptions);
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DownArrowIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <Field name={name}>
      {({ form, meta, Field }) => {
        return (
          <div className={`${containerClassName}`}>
            <label className="d-flex fs_14 mb-1 color_030304 fw_500">
              {label}
            </label>
            <Select
              placeholder={placeholder} // Add the placeholder prop here
              components={DropdownIndicator}
              styles={customStyles}
              isMulti
              className={` selectField focus_outline__none py-1 ${
                meta?.error && meta?.touched
                  ? "selectField_border__error"
                  : "selectField_border"
              }`}
              options={options}
              value={value}
              onChange={handleChange}
            />
            {errorMsgShow && <ErrorMsg name={name} />}
          </div>
        );
      }}
    </Field>
  );
};

export default MultiSelect;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#464646",
    fontFamily: "Poppins,sans-serif",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "10px",
    width: "100%",
    borderColor: state.isFocused ? "transparent" : "transparent",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected ? "#e5ecffb2" : null,
      borderBottom: "1px solid #F5F5F5",
      fontFamily: "Poppins,sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
    };
  },

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#9E9E9E",
      fontFamily: "Poppins,sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      opacity: "0.9",
      position: "absolute",
      // top: "0.6rem"
    };
  },
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#E5ECFF",
    gap: "5px",
    padding: "0px 6px",
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "500",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    ...(state.selectProps.menuIsOpen
      ? {
          transform: "rotate(180deg)",
        }
      : {}),
  }),
};
