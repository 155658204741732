import React from "react";
import PassSuccessImage from "../../assets/images/modals/password-successful-image.png";
import { OkayButton } from "../../components/ui/buttons/text-button";
import { useNavigate } from "react-router";

const PasswordSuccessfullyUpdated = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center align-items-center text-center vh-100">
      <div style={{ maxWidth: "32rem" }} className="">
        <div className="fs_30 fs_md_22 fw_400 font_montserrat">
          Password Successfully Updated!
        </div>
        <div className="color_9E9E9E fs_15 fs_md_12 px-sm-4 px-3">
          You can now securely access your account using your <br className="d-sm-block d-none"/> new password.
        </div>
        <div className="my-5 py-3">
          <img style={{ height: "200px" }} src={PassSuccessImage} alt="image" />
        </div>
        <div
          className="mt-5 mx-5"
          onClick={() => {
            navigate("/login");
          }}
        >
          <OkayButton name="Login" okayClassName="w-100 fs_16 fs_md_14 px-3" />
        </div>
      </div>
    </div>
  );
};

export default PasswordSuccessfullyUpdated;
