import React from "react";

const HelpCenterIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 4C4.75 3.586 5.086 3.25 5.5 3.25H7V1.75C7 1.336 7.336 1 7.75 1C8.164 1 8.5 1.336 8.5 1.75V3.25H10C10.414 3.25 10.75 3.586 10.75 4C10.75 4.414 10.414 4.75 10 4.75H8.5V6.25C8.5 6.664 8.164 7 7.75 7C7.336 7 7 6.664 7 6.25V4.75H5.5C5.086 4.75 4.75 4.414 4.75 4ZM18.427 10.7613L13.3232 16.489C11.9012 18.085 9.8605 19 7.72375 19H4C2.3455 19 1 17.6545 1 16V12.25C1 10.5955 2.3455 9.25 4 9.25H10.6435C11.4977 9.25 12.247 9.70675 12.6603 10.3885L15.0723 7.738C15.4788 7.29175 16.0338 7.03 16.6368 7.00225C17.239 6.9685 17.8165 7.18225 18.2628 7.58875C19.1733 8.419 19.2467 9.8425 18.427 10.7613ZM17.2525 8.698C17.1033 8.56225 16.9082 8.49775 16.7065 8.5015C16.504 8.51125 16.318 8.59825 16.1815 8.74825L12.862 12.3962C12.5732 13.1995 11.8578 13.8145 10.9765 13.9405L7.10575 14.4932C6.697 14.5525 6.316 14.2675 6.2575 13.8572C6.199 13.447 6.484 13.0675 6.8935 13.009L10.7643 12.4562C11.1835 12.3962 11.5 12.0317 11.5 11.608C11.5 11.1355 11.116 10.7515 10.6435 10.7515H4C3.17275 10.7515 2.5 11.4242 2.5 12.2515V16.0015C2.5 16.8287 3.17275 17.5015 4 17.5015H7.72375C9.433 17.5015 11.0665 16.7695 12.2035 15.493L17.3072 9.7645C17.5825 9.4555 17.5578 8.977 17.2525 8.698Z"
        fill={fill}
      />
    </svg>
  );
};

export default HelpCenterIcon;
