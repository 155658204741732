import React, { useState } from "react";
import { useFormikContext } from "formik";
import { varDeliveryChargeType } from "../../../helper-functions/helper/data";
import InputField from "../../forms/input-field";
import RadioButton from "../../forms/radio-button";
import DeleteIconWithBg from "../../../../assets/icons/products-delete";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "../../forms/select-field";
import ErrorMsg from "../../forms/error-msg";

function VariantsLocationWeight({
  weightPriceVariations = 1,
  numVariations = 1,
  addVariant = () => {},
  // addWeight = () => { },
  addWeight = () => {},
  removeWeight = () => {},
  initialValues,
  setInitialValues,
  setNumVariations,
  setIsAddingLocation,
}) {
  const { values, setValues, errors, touched, setFieldValue } =
    useFormikContext();
  const handleDeleteVariation = (indexToDelete) => {
    setValues({
      ...values,
      variable_loc_weight: values.variable_loc_weight.filter(
        (_, index) => index !== indexToDelete
      ),
    });
    setInitialValues((prevInitialValues) => {
      const updatedVariableLocWeight =
        prevInitialValues.variable_loc_weight.filter(
          (_, index) => index !== indexToDelete
        );
      return {
        ...prevInitialValues,
        variable_loc_weight: updatedVariableLocWeight,
      };
    });
    setNumVariations(numVariations - 1);
    // addVariant(-1);
  };
  const handleDeletePriceWeight = (indexToDelete, indToDelete) => {
    setValues((previousValues) => {
      const updatedVariableLocWeight = previousValues.variable_loc_weight.map(
        (item, index) => {
          if (index === indexToDelete) {
            const updatedWeightPrice = item.weight_price.filter(
              (_, ind) => ind !== indToDelete
            );
            return {
              ...item,
              weight_price: updatedWeightPrice,
            };
          }
          return item;
        }
      );
      return {
        ...previousValues,
        variable_loc_weight: updatedVariableLocWeight,
      };
    });
    removeWeight(indexToDelete);
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSelectChange = (newValue, index) => {
    setSelectedOptions(newValue);
    const newLocations = newValue.map((option) => option.value);
    setFieldValue(`variable_loc_weight[${index}].location`, newLocations);
  };
  const handleCreateOption = (inputValue, index) => {
    const newOption = {
      label: inputValue,
      value: inputValue,
    };
    const newOptions = [
      ...values?.variable_loc_weight?.[index]?.location?.map((value) => ({
        label: value,
        value: value,
      })),
      newOption,
    ];
    setSelectedOptions(newOptions);
    const newLocations = newOptions.map((option) => option.value);
    setFieldValue(`variable_loc_weight[${index}].location`, newLocations);
  };

  return (
    values.variable_loc_weight?.length > 0 && (
      <div>
        <h3 className="fs_22 fs_md_18  color_030304 font_montserrat fw_500 mb-4  mt-5">
          Variable Based on Weights
        </h3>
        {[...Array(numVariations)].map((n, index) => (
          <>
            <RadioButton
              index={index}
              inputLabelClass="color_030304 fs_16 fw_400"
              containerClass="mb-4"
              labelClass="color_030304 d-flex fs_16 fw_500 mb-3"
              name={`variable_loc_weight[${index}].var_delivery_charge_type`}
              options={varDeliveryChargeType}
              label="Delivery Charge Type"
            />
            {values?.variable_loc_weight?.[index]?.var_delivery_charge_type ===
            "fixed" ? (
              <>
                <InputField
                  isUnit={true}
                  id={`variable_loc_weight[${index}].var_delivery_charge${index}`}
                  label={"Delivery Charge"}
                  placeholder={"Enter amount"}
                  name={`variable_loc_weight[${index}].var_delivery_charge`}
                />
              </>
            ) : (
              <div>
                {[
                  ...Array(
                    initialValues?.variable_loc_weight?.[index]?.weight_price
                      ?.length
                  ),
                ].map((n, ind) => (
                  <>
                    <div key={ind} className="d-flex align-items-center gap-2">
                      <div>
                        <InputField
                          // labelClass='text-nowrap'
                          id={`variable_loc_weight[${index}].weight_price[${ind}].min_order_weight`}
                          label={"Min. Order Weight (kg)"}
                          placeholder={"Enter min. weight"}
                          name={`variable_loc_weight[${index}].weight_price[${ind}].min_order_weight`}
                          errMsgContainerClass="d-none"
                          unitPosition="right"
                          unitType="kg"
                        />
                        <span className="color_F24444 fs_12 d-block line_height">
                          {((touched?.variable_loc_weight?.[index]
                            ?.weight_price?.[ind]?.min_order_weight &&
                            !values?.variable_loc_weight?.[index]
                              ?.weight_price?.[ind]?.min_order_weight) ||
                            (ind ===
                              initialValues?.variable_loc_weight?.[index]
                                ?.weight_price?.length -
                                1 &&
                              touched?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind + 1]?.min_order_weight &&
                              !values?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind + 1]?.min_order_weight)) &&
                          values?.variable_loc_weight?.[index]?.weight_price?.[
                            ind
                          ]?.min_order_weight === ""
                            ? "Required"
                            : " "}
                        </span>
                      </div>
                      <div>
                        <InputField
                          // labelClass='text-nowrap'
                          id={`variable_loc_weight[${index}].weight_price[${ind}].max_order_weight`}
                          label={"Max. Order Weight (kg)"}
                          placeholder={"Enter max. weight"}
                          name={`variable_loc_weight[${index}].weight_price[${ind}].max_order_weight`}
                          errMsgContainerClass="d-none"
                          unitPosition="right"
                          unitType="kg"
                        />
                        <span className="color_F24444 fs_12 d-block line_height">
                          {((touched?.variable_loc_weight?.[index]
                            ?.weight_price?.[ind]?.max_order_weight &&
                            !values?.variable_loc_weight?.[index]
                              ?.weight_price?.[ind]?.max_order_weight) ||
                            (ind ===
                              initialValues?.variable_loc_weight?.[index]
                                ?.weight_price?.length -
                                1 &&
                              touched?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind + 1]?.max_order_weight &&
                              !values?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind + 1]?.max_order_weight)) &&
                          values?.variable_loc_weight?.[index]?.weight_price?.[
                            ind
                          ]?.max_order_weight === ""
                            ? "Required"
                            : " "}
                        </span>
                      </div>
                      <div>
                        <InputField
                          // labelClass='text-nowrap'
                          isUnit={true}
                          id={`variable_loc_weight[${index}].weight_price[${ind}].price`}
                          label={"Price"}
                          placeholder={"Enter amount"}
                          name={`variable_loc_weight[${index}].weight_price[${ind}].price`}
                          errMsgContainerClass="d-none"
                        />
                        <span className="color_F24444 fs_12 d-block line_height">
                          {((touched?.variable_loc_weight?.[index]
                            ?.weight_price?.[ind]?.price &&
                            !values?.variable_loc_weight?.[index]
                              ?.weight_price?.[ind]?.price) ||
                            (ind ===
                              initialValues?.variable_loc_weight?.[index]
                                ?.weight_price?.length -
                                1 &&
                              touched?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind + 1]?.price &&
                              !values?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind + 1]?.price)) &&
                          values?.variable_loc_weight?.[index]?.weight_price?.[
                            ind
                          ]?.price === ""
                            ? "Required"
                            : "   "}
                        </span>
                      </div>
                      {ind !== 0 && (
                        <button
                          onClick={() => handleDeletePriceWeight(index, ind)}
                          type="button"
                        >
                          <DeleteIconWithBg />
                        </button>
                      )}
                    </div>
                    {ind ==
                      values?.variable_loc_weight?.[index]?.weight_price
                        ?.length -
                        1 && (
                      <div className="w-100 d-flex justify-content-end">
                        <button
                          onClick={() => {
                            if (
                              values?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind]?.min_order_weight &&
                              values?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind]?.max_order_weight &&
                              values?.variable_loc_weight?.[index]
                                ?.weight_price?.[ind]?.price
                            ) {
                              setValues((previousValues) => {
                                const updatedVariableLocWeight =
                                  previousValues.variable_loc_weight.map(
                                    (item, idx) => {
                                      if (idx === index) {
                                        return {
                                          ...item,
                                          weight_price: [
                                            ...item.weight_price,
                                            {
                                              min_order_weight: "",
                                              max_order_weight: "",
                                              price: "",
                                            },
                                          ],
                                        };
                                      }
                                      return item;
                                    }
                                  );
                                return {
                                  ...previousValues,
                                  variable_loc_weight: updatedVariableLocWeight,
                                };
                              });
                              addWeight(index);
                            }
                          }}
                          className="bg_color_2820DA color_FFFFFF fw_400 fs_14 py-2 rounded-2 w-25"
                        >
                          + Add
                        </button>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
            <InputField
              isUnit={true}
              id={`variable_loc_weight[${index}].var_delivery_charge_above_limit`}
              label={"Delivery Charge Free Above Limit"}
              placeholder={"Enter amount"}
              name={`variable_loc_weight[${index}].var_delivery_charge_above_limit`}
            />
            {values.variable_loc_weight?.length - 1 != index && (
              <hr className="border_1px_e5ecff mb-4" />
            )}
          </>
        ))}
      </div>
    )
  );
}

export default VariantsLocationWeight;
