import { all } from "redux-saga/effects";
import { watchfaqCreateWorker, watchfaqDeleteWorker, watchfaqEditWorker, watchfaqMultiDeleteWorker, watchfaqTableWorker, watchfaqViewWorker } from "../faq-saga";

export default function* faqRootSaga() {
    yield all([
        watchfaqTableWorker(),
        watchfaqDeleteWorker(),
        watchfaqMultiDeleteWorker(),
        watchfaqViewWorker(),
        watchfaqEditWorker(),
        watchfaqCreateWorker(),

    ]);
}
