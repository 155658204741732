import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { siteViewAction, siteViewWithoutAccessAction } from "../../../redux/actions/configuration-action";

const useSiteSettings = () => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);


  const siteSettingsData = useSelector((store) => store.siteSettingReducer);
  console.log(siteSettingsData, "retetretertertert");
  useEffect(() => {
    // if (localStorage?.getItem("accessToken")) {
    //   const fetchSiteSettingsDetails = () => {
    //     dispatch(siteViewAction());
    //   };
    //   fetchSiteSettingsDetails();
    //   const intervalId = setInterval(fetchSiteSettingsDetails, 15 * 60 * 1000);
    //   setTimer(intervalId);
    //   return () => {
    //     clearInterval(intervalId);
    //   };
    // }

    // else {
    const fetchSiteSettingsDetails = () => {
      dispatch(siteViewWithoutAccessAction());
    };
    fetchSiteSettingsDetails();
    // const intervalId = setInterval(fetchSiteSettingsDetails, 15 * 60 * 1000);
    // setTimer(intervalId);
    // return () => {
    //   clearInterval(intervalId);
    // }
  }, []);

  return siteSettingsData
};

export default useSiteSettings;
