import React, { useState } from 'react'
import "../table.css"
import 'bootstrap/dist/js/bootstrap.bundle';
import Offcanvas from "react-bootstrap/Offcanvas";
import backarrow from "../../../../assets/icons/back-arrow-button.svg";
import { useNavigate } from 'react-router';
import MultiRangeSlider from '../../forms/multirange-slider';
import { Form, Formik } from "formik";
import InputField from '../../forms/input-field';
import { ApplyButton, ClearButton } from '../../buttons/text-button';
import * as Yup from "yup";
function TableFilter({
    setFilterShow,
    filterShow,
    setDate,
    date = {},
}) {
    const [values, setValues] = useState([0, 1000]);
    const initialValues = {
        from_date: date?.from_date ?? "",
        to_date: date?.to_date ?? "",
    };
    const loginSchema = Yup.object().shape({
        from_date: Yup.date(),
        to_date: Yup.date()
            .min(Yup.ref("from_date"), "To Date must be greater than From Date")
            .test("not-equal", "To Date cannot be equal to From Date", function (value) {
                const from_date = this.resolve(Yup.ref("from_date"));
                if (!from_date || !value) {
                    return true;
                }
                return value.getTime() !== from_date.getTime();
            })
    });
    const onSubmit = (data) => {
        setDate({
            from_date: data?.from_date,
            to_date: data?.to_date,
            from: values?.[0],
            to: values?.[1]
        })
        setFilterShow(false)
    };
    function clearFn() {
        setDate({
            from_date: "",
            to_date: "",
            from: 0,
            to: 1000
        })
        setValues([0, 1000])
    }
    return (
        <div>
            <Offcanvas
                show={filterShow}
                onHide={() => setFilterShow(false)}
                placement="end"
                className="side-bar-container"
            >
                <Offcanvas.Body className='p-0'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={loginSchema}
                    >
                        <Form className=' h-100 row g-0 align-items-between '>
                            <div className='pt-3'>
                                <div className='d-flex ps-3 pt-3 gap-3'>
                                    <img
                                        onClick={() => setFilterShow(false)}
                                        width={"30px"}
                                        src={backarrow}
                                        alt="back arrow"
                                    />  <span className="fs_22 fw_500 font_montserrat color_030304">
                                        Filter
                                    </span>
                                </div>
                                <hr className="colored-line" />
                                <div className='px-4'>
                                    {/* NEEDED CODE */}
                                    <div className='mb-5 mt-4 pt-3'>
                                        <div className='color_444444 fs_18 fw_400'>No. of Orders</div>
                                        <span className='color_030304 fs_18 fw_500'> {values?.[0]} - {values?.[1]}</span>
                                        <MultiRangeSlider
                                            setValues={setValues}
                                            values={values} />
                                    </div>
                                    <span className='color_444444 fs_18 fw_400'>Created Date</span>
                                    <div className='d-flex flex-sm-row flex-column gap-2 mt-1'>
                                        <InputField
                                            inputClass='py-2'
                                            labelClass="text-black fw_500 fs_14 mb-1"
                                            placeholder="Enter attribute name"
                                            containerClass="mb-3 position-relative w-100 d-flex flex-column"
                                            errMsgContainerClass="ps-2"
                                            label="From Date"
                                            name="from_date"
                                            type="date"
                                        />
                                        <InputField
                                            inputClass='py-2'
                                            labelClass="text-black fw_500 fs_14 mb-1"
                                            placeholder="Enter to_date"
                                            containerClass="mb-3 position-relative w-100 d-flex flex-column"
                                            errMsgContainerClass="ps-2"
                                            label="To Date"
                                            name="to_date"
                                            type="date"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-end'>
                                <div className='w-100'>
                                    <hr className="colored-line" />
                                    <div className='d-flex justify-content-end gap-3 p-2'>
                                        <ClearButton clearFn={() => clearFn()} type='reset' clearClassName={"px-3 py-1 fs_18 fw_600"} />
                                        <ApplyButton type='submit' applyClassName={"px-3 py-1 fs_18 fw_600"} />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}
export default TableFilter