import { SERVER_ERROR, SERVER_WORKING } from "../types";

export const errorReducer = (state = { status: false, error: {} }, action) => {
    switch (action.type) {
        case SERVER_ERROR:
            return {
                ...state,
                status: true,
                error: action.data,
            };

        case SERVER_WORKING:
            return {
                ...state,
                status: false,
                error: action.data,
            };
        default:
            return state;
    }
};