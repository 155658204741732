import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import DynamicTable from "../../components/ui/tables/table";
import {
  productsDeleteAction,
  productsViewAction,
  updateProductStatusAction,
  updateProductStockStatusAction,
} from "../../redux/actions/products-action";
import MultiImageUpload from "../../components/ui/forms/multiImage-upload";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import UseDangerouslyInnerHtml from "../../components/helper-functions/hooks/use-dangerously-inner-html";
import { taxViewAction } from "../../redux/actions/configuration-action";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import { AddWithBorderButton } from "../../components/ui/buttons/text-button";
import ProductMetaDataTable from "../../components/ui/tables/product-meta-data-table";
import ProductAddonsSection from "../../components/ui/view/product-addons-section";
import NotificationComponent from "../../components/notificationComponent";

export default function ProductsView() {
  const [addonsData, setAddonsData] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [productsViewData, setProductsViewData] = useState({});
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [variants, setVariants] = useState();
  const [metaList, setMetaList] = useState([]);

  useEffect(() => {
    // setLoading(true);
    dispatch(productsViewAction(params?.id, onSuccess, onError));
  }, [params?.id]);
  const onSuccess = (data) => {
    setAddonsData(data.data.product?.productAddOns);
    setMetaList(data.data.product?.meta_data);
    setLoading(false);
    setError(false);
    setProductsViewData(data.data?.product);
    setVariants(data?.data?.product?.variation);
  };

  // const variantsList = variants
  //   ? Object.entries(variants).map(([key, value]) => ({
  //     key,
  //     value,
  //   }))
  //   : [];

  const onError = (data) => {
    setLoading(false);
    setError(true);
  };

  const [taxData, setTaxData] = useState();
  useEffect(() => {
    setLoading(true);
    dispatch(taxViewAction(onViewSuccess, onViewError));
  }, []);
  const onViewSuccess = (data) => {
    setLoading(false);
    setTaxData(data?.data);
  };

  console.log(taxData);
  console.log(productsViewData);
  const onViewError = (data) => {
    setLoading(false);
  };

  const basicDetails = [
    {
      label: "Thumbnail",
      value: (
        <>
          {isImgLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "250px", height: "250px" }}
            >
              <LoadingSpinner size="lg" />
            </div>
          )}

          <div
            className={`${
              imageRegex.test(productsViewData?.thumbnail_image) === false &&
              "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
            }`}
            style={{ width: "250px", height: "250px" }}
          >
            <img
              className="rounded-3 mt-1"
              src={
                imageRegex.test(productsViewData?.thumbnail_image)
                  ? productsViewData?.thumbnail_image
                  : UplaodImgLogo
              }
              width={
                imageRegex.test(productsViewData?.thumbnail_image)
                  ? "250px"
                  : "100"
              }
              height={
                imageRegex.test(productsViewData?.thumbnail_image)
                  ? "250px"
                  : "100"
              }
              alt="image"
              onLoad={() => setIsImgLoading(false)}
              style={{ display: isImgLoading ? "none" : "block" }}
            />
          </div>
        </>
      ),
    },
    {
      label: "Product Name",
      value: productsViewData?.product_name
        ? removeUnderScore(productsViewData?.product_name)
        : "--",
    },
    {
      label: "Slug",
      value: productsViewData?.slug
        ? removeUnderScore(productsViewData?.slug)
        : "--",
    },

    {
      label: "Category",
      value:
        productsViewData?.category?.length > 0
          ? productsViewData?.category?.map((item, index) => {
              return (
                <span key={index}>
                  {item?.category_name}
                  {productsViewData?.category?.length > 1 &&
                    index !== productsViewData.category?.length - 1 &&
                    " | "}
                </span>
              );
            })
          : "--",
    },
    localStorage.getItem("brand") === "true" && {
      label: "Brands",
      value: productsViewData?.brands?.name
        ? productsViewData?.brands?.name
        : "--",
    },
    {
      label: "Attribute",
      value:
        productsViewData?.attributes?.length > 0
          ? productsViewData?.attributes?.map((item, index) => {
              return (
                <span key={index}>
                  {item?.name}
                  {productsViewData?.attributes?.length > 1 &&
                    index !== productsViewData.attributes?.length - 1 &&
                    " | "}
                </span>
              );
            })
          : "--",
    },
    {
      label: "Tag",
      value:
        productsViewData?.tags?.length > 0
          ? productsViewData?.tags?.map((item, index) => {
              return (
                <span key={index}>
                  {item?.name}
                  {productsViewData?.tags?.length > 1 &&
                    index !== productsViewData.tags?.length - 1 &&
                    " | "}
                </span>
              );
            })
          : "--",
    },
    {
      label: "Short Description",
      value: productsViewData?.short_description
        ? productsViewData?.short_description
        : "--",
    },
    {
      label: "Description",
      value: productsViewData?.description ? (
        <UseDangerouslyInnerHtml description={productsViewData?.description} />
      ) : (
        "--"
      ),
    },
  ];
  const priceDiscountsList = [
    {
      label: "Price",
      value: productsViewData?.price
        ? (localStorage?.getItem("currency") + " " ?? "₹ ") +
          productsViewData?.price
        : "--",
    },
    {
      label: "Discounted Price",
      value: productsViewData?.discount_price
        ? (localStorage?.getItem("currency") + " " ?? "₹ ") +
          productsViewData?.discount_price
        : "--",
    },
    {
      label: "Inventory Count",
      value: productsViewData?.inventory_count
        ? productsViewData?.inventory_count
        : "--",
    },
  ];
  const seoList = [
    {
      label: "SEO Title",
      value: productsViewData?.seo_title ? productsViewData?.seo_title : "--",
    },
    {
      label: "SEO Description",
      value: productsViewData?.seo_description
        ? productsViewData?.seo_description
        : "--",
    },
  ];
  const taxList = [
    {
      label: "HSN Code",
      value: productsViewData?.hsn_code ? productsViewData?.hsn_code : "--",
    },
    {
      label: ` ${
        (taxData?.country === "india" || taxData?.country === "India") && "GST "
      }Tax ${
        taxData?.tax_with_product === "exclusive_tax"
          ? "(Excluded)"
          : "(Included)"
      }`,
      value: (
        <div>
          {productsViewData?.gst_tax === null ? (
            <span>
              {productsViewData?.gst_tax === null
                ? taxData?.default_tax_rate
                : productsViewData?.gst_tax}
              <span className="color_2820DA">%</span>{" "}
              <span className="color_707070">(Default)</span>
            </span>
          ) : (
            <span>
              {productsViewData?.gst_tax}{" "}
              <span className="color_2820DA">%</span>
              {taxData?.default_tax_rate == productsViewData?.gst_tax && (
                <span> (Default)</span>
              )}
            </span>
          )}
        </div>
      ),
    },
  ];
  const taxListsData = [
    {
      label: ` ${
        taxData?.country === "india" || taxData?.country === "India"
          ? "GST "
          : ""
      }Tax ${
        taxData?.tax_with_product === "exclusive_tax"
          ? "(Excluded)"
          : "(Included)"
      }`,
      value: (
        <div>
          {productsViewData?.gst_tax === null ? (
            <span>
              {productsViewData?.gst_tax === null
                ? taxData?.default_tax_rate
                : productsViewData?.gst_tax}
              <span className="color_2820DA">%</span>{" "}
              <span className="color_707070">(Default)</span>
            </span>
          ) : (
            <span>
              {productsViewData?.gst_tax}{" "}
              <span className="color_2820DA">%</span>
              {taxData?.default_tax_rate == productsViewData?.gst_tax && (
                <span> (Default)</span>
              )}
            </span>
          )}
        </div>
      ),
    },
  ];
  const tableData = productsViewData?.variation_products?.map((item) => ({
    product_picture:
      item?.variants_pictures?.length > 0
        ? item?.variants_pictures?.[0]
        : "product_picture",
    variant: item?.variants.map((item) => {
      return { value: item };
    }),
    price: (localStorage?.getItem("currency") + " " ?? "₹ ") + item?.price,
    discount_price:
      item?.discounted_price === ""
        ? "No"
        : (localStorage?.getItem("currency") + " " ?? "₹ ") +
          item?.discounted_price,
    sku: item?.sku,
    bar_code: item?.bar_code,
    inventory_count: item?.inventory_count,
    "": "",
  }));

  const [activeStatus, setActiveStatus] = useState();
  const [outOfStockStatus, setOutOfStockStatus] = useState();
  useEffect(() => {
    setActiveStatus(productsViewData?.status);
    setOutOfStockStatus(productsViewData?.out_of_stock);
  }, [productsViewData]);

  const handleOutofStockBtn = () => {
    setOutOfStockStatus(!outOfStockStatus);
    dispatch(
      updateProductStockStatusAction(
        {
          product_id: params?.id,
        },
        onOutofStockSuccess,
        onOutOfStockError
      )
    );
  };
  const onOutofStockSuccess = (data) => {
    successToast(data?.message);
  };
  const onOutOfStockError = (data) => {
    errorToast(data?.data?.message);
  };
  const handleStatusBtn = () => {
    setActiveStatus(!activeStatus);
    dispatch(
      updateProductStatusAction(
        {
          product_id: params?.id,
        },
        onStatusSuccess,
        onStatusError
      )
    );
  };
  const onStatusSuccess = (data) => {
    successToast(data?.message);
  };
  const onStatusError = (data) => {
    errorToast(data?.data?.message);
  };
  console.log(variants);
  return (
    <>
      <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : error ? (
        <div>Error</div>
      ) : (
        <React.Fragment>
          <InnerLayout
            deleteAction={productsDeleteAction}
            id={params.id}
            editButtonPath={`/products/edit/${params.id}`}
            name={productsViewData?.code}
            path={`/products`}
            error={error}
            loading={loading}
            rolePermission={"products"}
            type="products"
            handleOutofStockBtn={handleOutofStockBtn}
            handleStatusBtn={handleStatusBtn}
            outOfStock={productsViewData?.out_of_stock}
            status={productsViewData?.status}
            activeStatus={activeStatus}
            outOfStockStatus={outOfStockStatus}
          />
          <div
            className={`py-5 pe-5`}
            style={{
              paddingLeft: "2.4rem",
            }}
          >
            <div className="fs_19 font_montserrat fw_600 mb-2">
              Basic Details
            </div>
            <div className="mb-4 col-lg-5">
              {basicDetails?.map((item, index) => {
                return (
                  <React.Fragment key={item?.label}>
                    <label className="color_707070 fs_14">{item.label}</label>
                    <div className="color_030304 mb-4 fs_13 fw_500">
                      {item?.value}
                    </div>
                  </React.Fragment>
                );
              })}
              {localStorage.getItem("indus_type") === "food" && (
                <>
                  <label className="color_707070 fs_14">Veg/Non-Veg</label>
                  <div className="color_030304 mb-4 fs_13 fw_500">
                    {productsViewData?.is_veg === true ? "Veg" : "Non-Veg"}
                  </div>
                </>
              )}
            </div>
            <div className="fs_18 fw_600 mb-2 mt-5 font_montserrat">
              Variations
            </div>
            {variants?.length === 0 ? (
              <div className="color_F24444 fs_14">Not added</div>
            ) : (
              <>
                {variants?.map((item, index) => {
                  return (
                    <div className="primary_color fs_12 fw_500" key={index}>
                      <span className="fs_14 color_030304">
                        {item.variation_name}
                      </span>
                      <div className="d-flex gap-2 mt-2 mb-3">
                        {item?.values?.map((val, index) => {
                          return (
                            <span
                              className="bg_color_E5ECFFB2 px-2 py-1"
                              key={index}
                            >
                              {val}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <div className="m-lg-4 ms-lg-0 col-xl-8">
                  <DynamicTable
                    tabletitle="Variations"
                    placeholder="Search discount here by id"
                    modalText={"discount"}
                    loading={loading}
                    error={error}
                    tableHeading={tableProductsViewHeader}
                    tablebody={tableData}
                    variants={true}
                    isBorderLess={{
                      border: false,
                      error: false,
                    }}
                    productsView={true}
                    isMinHeight={false}
                    actionClass=""
                  />
                </div>
              </>
            )}
            <div style={{ maxWidth: "600px" }}>
              <ProductAddonsSection
                page="view"
                addonsData={addonsData}
                setAddonsData={setAddonsData}
              />
            </div>
            {variants?.length === 0 && (
              <>
                <div className="fs_18 fw_600 mt-5 mb-3 font_montserrat">
                  Price & Discounts
                </div>
                <div className="mb-3 col-lg-5">
                  {priceDiscountsList?.map((item, index) => {
                    return (
                      <React.Fragment>
                        <label className="color_707070 fs_13">
                          {item.label}
                        </label>
                        <div className="color_030304 mb-1 fs_13 fw_500">
                          {item.value}
                        </div>
                      </React.Fragment>
                    );
                  })}{" "}
                </div>
              </>
            )}
            <div className="pictures_section mt-5">
              <MultiImageUpload
                images={
                  productsViewData?.variation_products?.length > 0
                    ? productsViewData.variation_products
                        ?.map(
                          (item) =>
                            item?.variants_pictures ?? item?.product_picture
                        )
                        .flat()
                    : productsViewData.product_picture
                }
                // images={}
                // productsViewData?.variant_data
                //   ? productsViewData.variant_data
                //       ?.map((item) => item?.product_picture)
                //       .flat()
                //   : productsViewData.product_picture
                page="view"
                containerClass="ms-0"
                model={false}
                borderLess={false}
              />
            </div>

            {productsViewData?.variation_products?.length === 0 && (
              <>
                <div className="fs_18 fw_600 mb-2 mt-5 font_montserrat">
                  Product Weight
                </div>
                <div className="mb-3 col-lg-5">
                  <label className="color_707070 fs_13">Weight</label>
                  <div className="color_030304 fs_13 fw_500 mb-2">
                    {productsViewData?.product_weight === null
                      ? "--"
                      : productsViewData?.product_weight + " kg"}
                  </div>
                </div>
              </>
            )}
            {taxData?.enable_disable && (
              <>
                <div className="fs_18 fw_600 mb-2 mt-5 font_montserrat">
                  Tax
                </div>
                <div className="mb-3 col-lg-5">
                  {taxData?.country === "india" || taxData?.country === "India"
                    ? taxList?.map((item) => {
                        return (
                          <React.Fragment key={item?.label}>
                            <label className="color_707070 fs_13">
                              {item.label}
                            </label>
                            <div className="color_030304 fs_13 fw_500 mb-2">
                              {item.value}
                            </div>
                          </React.Fragment>
                        );
                      })
                    : taxListsData?.map((item) => {
                        return (
                          <React.Fragment key={item?.label}>
                            <label className="color_707070 fs_13">
                              {item.label}
                            </label>
                            <div className="color_030304 fs_13 fw_500 mb-2">
                              {item.value}
                            </div>
                          </React.Fragment>
                        );
                      })}
                </div>
              </>
            )}
            <div className="fs_18 fw_600 mb-2 mt-5 font_montserrat">SEO</div>
            <div className="mb-3 col-lg-5">
              {seoList?.map((item) => {
                return (
                  <React.Fragment key={item?.label}>
                    <label className="color_707070 fs_13">{item.label}</label>
                    <div className="color_030304 fs_13 fw_500 mb-2">
                      {item.value}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {metaList?.length > 0 && (
              <div style={{ maxWidth: "700px" }} className="mt-5">
                <h3 className="fs_16  color_030304 font_montserrat fw_600 mb-1 mt-4">
                  Meta Data
                </h3>
                <ProductMetaDataTable
                  editable={false}
                  metaList={metaList}
                  setMetaList={setMetaList}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </>
  );
}
const tableProductsViewHeader = [
  { label: "Pictures", value: "product_picture" },
  { label: "Variants", value: "variants" },
  { label: "Price", value: "price" },
  { label: "Discounted Price ", value: "discount_price" },
  { label: "SKU", value: "sku" },
  { label: "Barcode", value: "bar_code" },
  { label: "Inventory Count", value: "inventory_count" },
  { label: "", value: "" },
];
