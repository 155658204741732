import {
  ADDRESS_CREATE,
  ADDRESS_DELETE,
  ADDRESS_EDIT,
  ADDRESS_MULTI_DELETE,
  ADDRESS_TABLE,
  ADDRESS_VIEW,
  CUSTOMER_LIST_DROPDOWN,
} from "../types";

export const AddressTableAction = (data, data1, onSuccess, onError) => {
  return {
    type: ADDRESS_TABLE,
    data,
    data1,
    onSuccess,
    onError,
  };
};

export const AddressViewAction = (id, onSuccess, onError) => {
  return {
    type: ADDRESS_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const AddressEditAction = (data, onSuccess, onError) => {
  return {
    type: ADDRESS_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const AddressDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ADDRESS_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const AddressMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ADDRESS_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const AddressCreateAction = (data, onSuccess, onError) => {
  return {
    type: ADDRESS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const customerListDropdownAction = ( onSuccess, onError) => {
  return {
    type: CUSTOMER_LIST_DROPDOWN,
    onSuccess,
    onError,
  };
};
