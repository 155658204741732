import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  adminUserDetailsAction,
  adminUsersImageUploadAction,
  loggedAdminUsersEditAction,
} from "../../redux/actions/admin-users-action";
import errorToast from "../../components/helper-functions/helper/error-toast";
import { useDispatch } from "react-redux";
import { nameRegex } from "../../components/helper-functions/helper/regex";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ImageUpload from "../../components/ui/forms/image-upload";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import isEqual from "lodash.isequal";
import InputField from "../../components/ui/forms/input-field";
import useLoggedAdminDetails from "../../components/helper-functions/hooks/useLoggedAdmin-details";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import Compressor from "compressorjs";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";
const AccountsEdit = () => {
  const loggedAdminData = useLoggedAdminDetails();
  const [imgUrl, setImageUrl] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const [imageSize, setImageSize] = useState(false);
  // const [loggedAdminData, setLoggedAdminData] = useState("")
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  useEffect(() => {
    setImageUrl(loggedAdminData?.data?.data?.profile_picture);
  }, [loggedAdminData, reload]);
  const initialValues = {
    name: loggedAdminData?.data?.data?.name,
    email_address: loggedAdminData?.data?.data?.email_address,
    profile_picture: loggedAdminData?.data?.data?.profile_picture,
  };
  const Schema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegex, "Enter a valid name")
      .trim()
      .required("Required"),
  });
  function resetFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setImageUrl("");
      setReload(!reload);
    }
  }
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(
            adminUsersImageUploadAction(result, onImgSuccess, onImgError)
          );
        },
      });
    } else {
      setImageSize(true);
    }
    // dispatch(adminUsersImageUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImageSize(false);
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImageSize(false);
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  const onSubmit = (values) => {
    dispatch(
      loggedAdminUsersEditAction(
        {
          admin_id: params?.id,
          profile_picture: imgUrl,
          name: values?.name,
          email_address: values?.email_address,
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    navigate(`/accounts/view`);
    successToast(data?.message);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
  };
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual && loggedAdminData?.data?.data?.profile_picture === imgUrl) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      <NotificationComponent />
      {loggedAdminData?.loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values }) => {
            return (
              <Form>
                <InnerLayout
                  name={"Accounts"}
                  page="edit"
                  edit={true}
                  resetFn={resetFn}
                  rolePermission={"account"}
                  path={"accounts/view"}
                  disabled={valuesCompareFn(values)}
                />
                <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
                  {" "}
                  <ImageUpload
                    title="Profile Picture"
                    containerClass=""
                    name="Profile Picture"
                    getImgUrl={getImgUrl}
                    resetFn={resetFn}
                    data={imgUrl}
                    imgLoading={imgLoading}
                    setImgLoading={setImgLoading}
                  />
                  {imageSize && (
                    <label className="fs_14 text-danger mb-3">
                      "Please upload below 10mb"
                    </label>
                  )}
                  <InputField
                    id={"name"}
                    label={"Name"}
                    placeholder={"Enter admin name"}
                    name={"name"}
                  />
                  <InputField
                    id={"email"}
                    label={"Email Address"}
                    placeholder={"Enter email address"}
                    name={"email_address"}
                    isDisabled={true}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default AccountsEdit;
