import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  customerDeleteAction,
  customerMultiDeleteAction,
  customerTableAction,
} from "../../redux/actions/customer-action";
import DynamicTable from "../../components/ui/tables/table";
import TableFilter from "../../components/ui/tables/table-filter/table-filter";
import { useLocation } from "react-router";
import NotificationComponent from "../../components/notificationComponent";

const CustomerTable = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [reload, setReload] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [date, setDate] = useState({
    from_date: "",
    to_date: "",
    from: 0,
    to: 1000,
  });

  console.log(date, "dsfgjsdhgfdsh");

  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      customerTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig,
        },
        {
          no_of_orders: {
            min: date?.from,
            max: date?.to,
          },
          filteredValue: {
            from_date: date?.from_date,
            to_date: date?.to_date,
          },
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, reload, inputValue, date, sortConfig]);
  const onSuccess = (data) => {
    console.log(data, "customers");
    setCustomers(data?.data?.customers);
    setPagination(data?.data?.pagination);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };
  const tableData = customers.map((item) => ({
    id: item?.id,
    name: item?.name,
    phone_number: item?.phone_number,
    email_address: item?.email_address,
    Action: ["/customers/view/", "/customers/edit/", item?.is_blocked],
  }));
  console.log(date, "customers");
  console.log(customers, "jjhchgchgchgc");
  return (
    <div className=" col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5 ">
      <NotificationComponent />
      <DynamicTable
        tabletitle="Customers"
        modalText={"customer"}
        placeholder={
          "Search customers here by id, name, phone number and email address"
        }
        tableHeading={tableCustomerHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        createButtonName="CREATE CUSTOMER"
        createpath="/customers/create"
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={customerDeleteAction}
        multiDeleteAction={customerMultiDeleteAction}
        loading={loading}
        setLoading={setLoading}
        error={error}
        id_name={"customer_id"}
        setReload={setReload}
        reload={reload}
        filterFn={() => setFilterShow(true)}
        filtration={true}
        customer={true}
        rolePermission="customers"
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
      <TableFilter
        setFilterShow={setFilterShow}
        filterShow={filterShow}
        setDate={setDate}
        date={date}
      />
    </div>
  );
};

export default CustomerTable;

const tableCustomerHeader = [
  { label: "ID", value: "id" },
  { label: "Customer Name", value: "name" },
  { label: "Phone Number", value: "phone_number" },
  { label: "Email Address", value: "email_address" },
  { label: "Actions", value: "Actions" },
];
