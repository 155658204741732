import React, { useState } from 'react'
import CreateAddonModal from '../modals/create-addon-modal'
import { AddWithBorderButton } from '../buttons/text-button'
import ProductAddonsTable from '../tables/product-addOns-table'
import DeleteModal from '../modals/delete-modal'
import ViewAddonModal from '../modals/view-addon-modal'

const ProductAddonsSection = ({ addonsData, setAddonsData, page = "" }) => {
    const [show, setShow] = useState(false)
    const [delShow, setDelShow] = useState(false)
    const [editShow, setEditShow] = useState(false)

    const [deleteData, setDeleteData] = useState();
    const [editData, setEditData] = useState();        
    const [initialValues, setInitialValues] = useState({
        "name": "",
        "type": "",
        "min": "",
        "max": "",
        "productAddOnItems": Array.from({ length: addonsData?.productAddOnItems?.length ?? 0 }, (_, i) => ({
            name: addonsData?.productAddOnItems?.[i]?.name ?? "",
            price: addonsData?.productAddOnItems?.[i]?.price ?? "",
        })),
    })
    function deleteDataFn(index) {
        const newAddonsData = addonsData.filter((_, i) => i !== index);
        setAddonsData(newAddonsData);
        setDelShow(false);
    }
    
    return (
        <>
            <DeleteModal
                text={`Are you sure, you want to delete this add-on group permanently?`}
                show={delShow}
                modalClose={() => setDelShow(false)}
                deleteData={deleteData}
                deleteFn={() => deleteDataFn(deleteData)}
                type={"delete"}
            />
            {/* CREATE ADDON */}
            <CreateAddonModal
                show={show}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                modalClose={() => setShow(false)}
                addonsData={addonsData}
                setAddonsData={setAddonsData}
            />
            {/* Edit Addons */}
            {
                page != "view" &&
                <CreateAddonModal
                    type="edit"
                    page={page}
                    editData={editData}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    show={editShow}
                    modalClose={() => setEditShow(false)}
                    addonsData={addonsData}
                    setAddonsData={setAddonsData}
                />
            }
            {page === "view" &&
                <ViewAddonModal
                    type="edit"
                    page={page}
                    editData={editData}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    show={editShow}
                    modalClose={() => setEditShow(false)}
                    addonsData={addonsData}
                    setAddonsData={setAddonsData}
                />
            }
            <div className="">
                <h3 className="fs_16  color_030304 font_montserrat fw_600 mb-3 mt-4">
                    Add-Ons
                </h3>
                {
                    page != "view" &&
                    <AddWithBorderButton
                        onClick={() => {
                            setShow(true); setInitialValues(
                                {
                                    "name": "",
                                    "type": "",
                                    "min": "",
                                    "max": "",
                                    "productAddOnItems": Array.from({ length: 1 }, (_, i) => ({
                                        name: "",
                                        price: "",
                                    })),
                                }
                            )
                        }}
                        addClassName="py-1 mb-2"
                        customStyles={{ borderRadius: "4px" }}
                        name="Add Addons"
                    />
                }
            </div>
            {
                addonsData?.length > 0 &&
                <ProductAddonsTable
                    page={page}
                    addonsData={addonsData}
                    editFn={(index) => {
                        setInitialValues(addonsData?.find((_, i) => i === index))
                        setEditShow(true)
                        setEditData(index)
                    }}
                    deleteFn={(index) => {
                        setDelShow(true)
                        setDeleteData(index);

                    }}
                />
            }
        </>)
}

export default ProductAddonsSection