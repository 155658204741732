import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import "../../modals/modal.css";
import { ApplyButton } from "../../buttons/text-button";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import {
  billingGenerateAction,
  ordersBillingEditAction,
  ordersCreateAction,
} from "../../../../redux/actions/orders-action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export default function BillingDetails({
  show,
  modalClose,
  billingDetailsData,
  addedProductsData,
  type,
  data,
  couponCode,
  setCouponCode,
  reload,
  setReload,
  couponBillingData,
  setCouponBillingData,
  setBillingDetailsData,
  deliveryType,
  errorMsg,
}) {
  console.log(couponBillingData, "dsfkhs");
  console.log(billingDetailsData, "dsfkhs");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [couponCode, setCouponCode] = useState({
  //   couponCode: billingDetailsData?.coupon_code ?? "",
  //   applied: false,
  // });
  console.log(couponCode, "couponBillingDatacouponBillingData");
  const [loading, setLoading] = useState({
    applyCoupon: false,
    createOrder: false,
  });
  const billingDetails = [
    {
      title: "Total Price Value",
      label: `${localStorage?.getItem("currency") + " " ?? "₹ "} ${(
        couponBillingData?.total_Price ??
        billingDetailsData?.total_Price ??
        data?.total_cost_value
      )?.toFixed(2)}`,
      values: "",
      color: "color_030304",
    },
    {
      title: "Tax",
      label: `${localStorage?.getItem("currency") + " " ?? "₹ "} ${(
        couponBillingData?.tax ??
        billingDetailsData?.tax ??
        data?.total_tax
      )?.toFixed(2)}`,
      values: 100,
      color: "color_030304",
    },
    {
      title: "Shipping Charges",
      label: `${localStorage?.getItem("currency") + " " ?? "₹ "} ${(
        couponBillingData?.shippingCharges ??
        billingDetailsData?.shippingCharges ??
        data?.delivery_charge
      )?.toFixed(2)}`,
      values: 50,
      color: "color_030304",
    },
    {
      title: "Total with Shipping Charges",
      label: `${localStorage?.getItem("currency") + " " ?? "₹ "} ${(
        couponBillingData?.total_value_with_tax ??
        billingDetailsData?.total_value_with_tax ??
        data?.total_value_with_tax
      )?.toFixed(2)}`,
      values: "",
      color: "color_030304",
    },
    {
      title: "Coupon Type",
      label: couponBillingData?.coupon_type
        ? couponBillingData?.coupon_type
        : billingDetailsData?.coupon_type
        ? billingDetailsData?.coupon_type
        : "-",
      values: "Flat rate",
      color: "color_030304",
    },
    {
      title: `Coupon(${
        couponBillingData?.coupon_code ??
        billingDetailsData?.coupon_code ??
        "--"
      })`,
      label: couponBillingData?.coupon_code
        ? couponBillingData?.coupon_code
        : billingDetailsData?.coupon_code
        ? billingDetailsData?.coupon_code
        : "-",
      values: "--",
      color: "color_3AD64C",
    },
    {
      title: "Discount Value",
      label: `- ${localStorage?.getItem("currency") + " " ?? "₹ "} ${(
        couponBillingData?.discount ??
        billingDetailsData?.discount ??
        data?.discount_amount
      )?.toFixed(2)}`,
      values: "",
      color: "color_3AD64C",
    },
    {
      title: "Total with Discount Value",
      label: `${localStorage?.getItem("currency") + " " ?? "₹ "} ${(
        couponBillingData?.total_Price_with_discount ??
        billingDetailsData?.total_Price_with_discount ??
        data?.discount_amount
      )?.toFixed(2)}`,
      values: "--",
      color: "color_030304",
    },
  ];

  function applyCouponFn() {
    setLoading({ applyCoupon: true });
    dispatch(
      billingGenerateAction(
        {
          ...addedProductsData,
          coupon_code: couponCode?.couponCode,
        },
        onSuccess,
        onError
      )
    );
  }
  function removeCouponFn() {
    dispatch(
      billingGenerateAction(
        {
          ...addedProductsData,
          coupon_code: "",
        },
        onSuccess,
        onError
      )
    );
  }
  const onSuccess = (data) => {
    if (data.message === "Invalid Coupon") {
      console.log(data, "laskdal");

      errorToast(data.message);
    } else {
      successToast(data.message);
    }
    console.log(data, "laskdal");
    setLoading({ applyCoupon: false });
    setCouponBillingData(data?.data);
    setBillingDetailsData(data?.data);
  };
  const onError = (data) => {
    console.log(data, "laskdal");
    setLoading({ applyCoupon: false });
    errorToast(data.data.message);
  };
  function createOrderFn() {
    setLoading({ createOrder: true });
    if (type === "edit") {
      dispatch(
        ordersBillingEditAction(
          {
            order_id: data?.id,
            delivery_type: deliveryType,
            charges: {
              total_Price:
                couponBillingData?.total_Price ??
                billingDetailsData?.total_Price,
              tax: couponBillingData?.tax ?? billingDetailsData?.tax,
              total_Price_with_tax:
                couponBillingData?.total_Price_with_tax ??
                billingDetailsData?.total_Price_with_tax,
              coupon_type: couponBillingData?.coupon_type ?? "",
              coupon_code:
                couponBillingData?.coupon_code ??
                billingDetailsData?.coupon_code,
              discount_id:
                couponBillingData?.discount_id ??
                billingDetailsData?.discount_id,
              discount:
                couponBillingData?.discount ?? billingDetailsData?.discount,
              total_Price_with_discount:
                couponBillingData?.total_Price_with_discount ??
                billingDetailsData?.total_Price_with_discount,
              shippingCharges:
                couponBillingData?.shippingCharges ??
                billingDetailsData?.shippingCharges,
              total_order_value:
                couponBillingData?.total_order_value ??
                billingDetailsData?.total_order_value,
            },
          },
          onOrderSuccess,
          onOrderError
        )
      );
    } else {
      dispatch(
        ordersCreateAction(
          {
            ...addedProductsData,
            delivery_type: deliveryType,
            coupon_code: couponCode?.couponCode,
            charges: {
              total_Price:
                couponBillingData?.total_Price ??
                billingDetailsData?.total_Price,
              tax: couponBillingData?.tax ?? billingDetailsData?.tax,
              total_Price_with_tax:
                couponBillingData?.total_Price_with_tax ??
                billingDetailsData?.total_Price_with_tax,
              coupon_type: couponBillingData?.coupon_type ?? "",
              coupon_code:
                couponBillingData?.coupon_code ??
                billingDetailsData?.coupon_code,
              discount_id:
                couponBillingData?.discount_id ??
                billingDetailsData?.discount_id,
              discount:
                couponBillingData?.discount ?? billingDetailsData?.discount,
              total_Price_with_discount:
                couponBillingData?.total_Price_with_discount ??
                billingDetailsData?.total_Price_with_discount,
              shippingCharges:
                couponBillingData?.shippingCharges ??
                billingDetailsData?.shippingCharges,
              total_order_value:
                couponBillingData?.total_order_value ??
                billingDetailsData?.total_order_value,
            },
            // payment: {
            //   payment_id: "",
            //   payment_meyhod: "offline",
            //   transaction_id: "",
            //   transaction_status: "unsuccessfull",
            //   payment_status: "unpaid",
            // },
          },
          onOrderSuccess,
          onOrderError
        )
      );
    }
  }

  const onOrderSuccess = (data) => {
    setLoading({ createOrder: false });
    console.log(data, "akjfdss");
    successToast(data.message);
    if (type === "edit") {
      modalClose();
      setReload(!reload);
    } else {
      navigate("/orders");
    }
  };
  const onOrderError = (data) => {
    setLoading({ createOrder: false });
    errorToast(data.data.message);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="billing_details_model"
        contentClassName="rounded-3"
        // backdrop="static"
      >
        <ModalHeader>
          <div className="ms-2">
            <button onClick={() => modalClose()}>
              <img src={BackIcon} />
            </button>
            <span className="ms-2 fs_16 fw_500">Billing Details</span>
          </div>
          <div>
            <ApplyButton
              disabled={
                errorMsg === "Service unavailable for this weight range." &&
                localStorage.getItem("delivery_type") === "adair"
                  ? true
                  : false
              }
              type="button"
              name="SUBMIT"
              applyClassName={"px-4 fs_16 fw_600"}
              onClick={() => createOrderFn()}
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="ms-4 me-3">
            <label className="mt-3 fs_16 fw_500 mb-2">Apply Coupon</label>
            <div className="d-flex border_1px_E6ECFF py-2 border_radius_7px">
              <input
                className="w-100 border-0 focus_outline__none textAreaField ms-2"
                placeholder="Enter coupon code"
                value={couponCode?.couponCode}
                onChange={(e) =>
                  setCouponCode({
                    ...couponCode,
                    couponCode: e.target.value,
                  })
                }
              />
              {couponBillingData?.discount > 0 ? (
                <button
                  className="bg_color_F24444 fs_14 text-white fw_500 px-4 border_radius_7px py-1 me-2"
                  type="button"
                  onClick={() => {
                    setCouponCode({
                      couponCode: "",
                      applied: false,
                    });
                    removeCouponFn();
                  }}
                >
                  REMOVE
                </button>
              ) : (
                <button
                  disabled={couponCode?.couponCode ? false : true}
                  className="bg_color_2820DA fs_14 text-white fw_500 px-4 border_radius_7px py-1 me-2"
                  type="button"
                  onClick={() => {
                    setCouponCode({
                      ...couponCode,
                      applied: !couponCode?.applied,
                    });
                    applyCouponFn();
                  }}
                >
                  APPLY
                </button>
              )}
            </div>
            <p className="fs_16 fw_400 mt-4">Billing Details</p>
            {billingDetails?.map((item) => {
              return (
                <>
                  <div className="d-flex justify-content-between px-sm-3">
                    <span className="mb-2 color_707070 fs_14">
                      {item?.title}
                    </span>
                    <span className={`${item?.color}`}>{item?.label}</span>
                  </div>
                </>
              );
            })}
            <div className="d-flex justify-content-between fw_600 color_2820DA fs_xs_18 fs_20 border_top_1px_e6ecff border_bottom_1px_e5ecff py-2 px-3">
              <span className="">Total Value Of Order</span>
              <span>
                {localStorage?.getItem("currency") + " " ?? "₹ "}
                {(
                  couponBillingData?.total_order_value ??
                  billingDetailsData?.total_order_value ??
                  data?.total_value_with_delivery
                )?.toFixed(2)}
              </span>
            </div>
          </div>
          {errorMsg === "Service unavailable for this weight range." && (
            <p className="text-danger">{errorMsg}</p>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
