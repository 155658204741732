import React from 'react'

const BrandsIcon = ({ fill = "#707070" }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10" cy="10" r="8.25" stroke={fill} strokeWidth="1.5" />
            <path d="M7.68686 13.4704C7.7917 13.5523 7.92275 13.5932 8.04725 13.5932C8.17175 13.5932 8.38143 13.4977 8.38143 13.4977L10.0065 12.3648L11.6577 13.484C11.8739 13.6273 12.1491 13.6205 12.3523 13.4567C12.5554 13.2997 12.634 13.0199 12.5554 12.7674L11.9657 10.9043L13.3876 9.69633C13.5776 9.52571 13.6497 9.2459 13.5645 9.00021C13.4793 8.75453 13.25 8.59074 13.001 8.59074H11.199L10.5634 6.8095C10.4782 6.56382 10.2489 6.40002 9.9999 6.40002C9.75091 6.40002 9.52157 6.56382 9.43639 6.8095L8.80079 8.59074H6.99885C6.74985 8.59074 6.52051 8.75453 6.43533 9.00021C6.35015 9.2459 6.42223 9.51889 6.61225 9.6895L8.0407 10.8975L7.47718 12.7811C7.39855 13.0336 7.48373 13.3134 7.68686 13.4704Z" stroke={fill} strokeWidth="1.5"
                fill={fill}
            />
        </svg>
    )
}

export default BrandsIcon