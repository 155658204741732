import React, { useEffect, useState } from "react";
import EditIcon from "../../../assets/icons/editIcon";
import AddressIcon from "../../../assets/icons/addressicon";
import PrintIcon from "../../../assets/icons/printIcon";
import dayjs from "dayjs";
import AddDeliveryDetails from "./models/add-delivery-details";
import EditAddress from "./models/edit-address";

function PickupDetails({
    data,
    orderDetails,
    selectedOption,
    reload,
    setReload,
    deliveryPartners,
    canWrite
}) {
    const [showDeliveryPartner, setShowDeliveryPartner] = useState(false);
    const DeliveryPartnerClose = () => setShowDeliveryPartner(false);
    const [showshipping, setShowshipping] = useState(false);
    const shippingClose = () => setShowshipping(false);
    const deliver = orderDetails?.return_delivery_partner;
    console.log(data, "datatataatatatat");
    return (
        <>
            <div className="row p-3 mt-lg-0 mt-3">
                <div className="col-xl-6">

                    <div className="d-flex gap-2 align-items-center">
                        <label className="fs_16 font_montserrat fw_600 ">
                            Pickup Details                        </label>
                        {canWrite("orders") && <button
                            onClick={() => setShowshipping(true)}
                            className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1"
                        >
                            <EditIcon /> <span>Edit</span>
                        </button>}
                    </div>
                    <div>
                        <div className="color_030304 fs_16 fw_500 mt-4">
                            <AddressIcon />
                            <span>
                                {" "}
                                {"" + data?.address_name}
                                <span className="color_3AD64C fw_600">
                                    {data?.is_default && "(Default Address)"}
                                </span>
                            </span>
                        </div>
                        <p className="color_030304 fs_16 fw_400">
                            {data?.address_line1 + " " +
                                data?.address_line2 +
                                " " +
                                data?.city +
                                " " +
                                data?.state +
                                " " +
                                data?.country}
                        </p>
                        <p className="color_030304 fs_16 fw_400">
                            +91 {data?.phone_number}
                        </p>
                    </div>

                </div>
                <div className="col-xl-6">
                    {orderDetails?.return_delivery_partner != null && (
                        <div className="px-xl-5 mt-xl-0 mt-5">
                            <div className="d-flex gap-2 align-items-center">
                                <label className="fs_16 font_montserrat fw_600 text-nowrap ">
                                    Delivery Partner Details(Pickup){" "}
                                </label>
                                <button
                                    onClick={() => setShowDeliveryPartner(true)}
                                    className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1"
                                >
                                    <EditIcon /> <span>Edit</span>
                                </button>
                            </div>
                            <div>
                                <label className="color_707070 fs_14">Delivery Partner</label>
                                <div className="color_030304 mb-4 fs_13 fw_500">
                                    {deliver?.name}
                                </div>
                                <label className="color_707070 fs_14">AWD Number</label>
                                <div className="color_030304 mb-4 fs_13 fw_500">
                                    {deliver?.aws_number}
                                </div>
                                <label className="color_707070 fs_14">Dispatched On</label>
                                <div className="color_030304 mb-4 fs_13 fw_500">
                                    {dayjs(deliver?.dispatched_on).format(
                                        "DD MMMM YYYY hh:mm:ss A"
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <EditAddress
                type="pickup"
                orderDetails={orderDetails}
                show={showshipping}
                modalClose={shippingClose}
                reload={reload}
                setReload={setReload}
            />
            <AddDeliveryDetails
                type="pickup"
                deliveryPartners={deliveryPartners}
                reload={reload}
                setReload={setReload}
                id={orderDetails?.id}
                orderDetails={orderDetails}
                selectedOption={selectedOption}
                show={showDeliveryPartner}
                modalClose={DeliveryPartnerClose}
            />
        </>
    );
}

export default PickupDetails;
