import React from "react";

export default function GeneratePasswordIcon({
  fill = "#2820DA",
  width = "15",
  height = "15",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2440_16533)">
        <path
          d="M9.38693 3.724L0.599599 12.5127C0.238545 12.8998 0.0418933 13.412 0.0510899 13.9413C0.0602864 14.4706 0.274613 14.9757 0.648898 15.35C1.02318 15.7244 1.52819 15.9389 2.05749 15.9482C2.58679 15.9575 3.09903 15.761 3.48627 15.4L12.2756 6.61267L9.38693 3.724ZM10.3903 6.61267L8.66627 8.33667L7.66627 7.33333L9.39027 5.60933L10.3903 6.61267ZM2.5436 14.4587C2.40883 14.5871 2.22979 14.6588 2.0436 14.6588C1.85741 14.6588 1.67837 14.5871 1.5436 14.4587C1.41121 14.3259 1.33686 14.1461 1.33686 13.9587C1.33686 13.7712 1.41121 13.5914 1.5436 13.4587L6.72293 8.27867L7.72627 9.282L2.5436 14.4587ZM14.2216 9.752L15.9996 10.6413L14.2216 11.5333L13.3329 13.308L12.4443 11.5333L10.6663 10.6413L12.4443 9.752L13.3329 7.97467L14.2216 9.752ZM4.44427 3.55533L2.66627 2.66667L4.44427 1.778L5.33293 0L6.2216 1.778L7.9996 2.66667L6.2216 3.55533L5.33293 5.33333L4.44427 3.55533ZM12.8883 3.11133L11.3329 2.33333L12.8883 1.55533L13.6663 0L14.4443 1.55533L15.9996 2.33333L14.4443 3.11133L13.6663 4.66667L12.8883 3.11133Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2440_16533">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
