import { ADDRESS_FETCH, BILLING_GENERATE, ORDERS_ASSIGN, ORDERS_Billing_Edit, ORDERS_CREATE, ORDERS_DELETE, ORDERS_DROPDOWN_UPLOAD, ORDERS_EDIT, ORDERS_IMAGE_UPLOAD, ORDERS_MULTI_DELETE, ORDERS_PARTIAL_REJECT, ORDERS_REJECT, ORDERS_RETURN_Edit, ORDERS_SERVICE, ORDERS_TABLE, ORDERS_VIEW, ORDER_DELIVERY_PARTNERS } from "../types";

export const ordersTableAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const ordersViewAction = (id, onSuccess, onError) => {
  return {
    type: ORDERS_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const ordersEditAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_EDIT,
    data,
    onSuccess,
    onError,
  };
};


export const ordersCreateAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const billingGenerateAction = (data, onSuccess, onError) => {
  return {
    type: BILLING_GENERATE,
    data,
    onSuccess,
    onError,
  };
};

export const addressFetchAction = (data, onSuccess, onError) => {
  return {
    type: ADDRESS_FETCH,
    data,
    onSuccess,
    onError,
  };
};


export const ordersRejectAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_REJECT,
    data,
    onSuccess,
    onError,
  };
};

export const ordersPartialRejectAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_PARTIAL_REJECT,
    data,
    onSuccess,
    onError,
  };
};

export const ordersBillingEditAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_Billing_Edit,
    data,
    onSuccess,
    onError,
  };
};



export const ordersDeliveryPartnersAction = (onSuccess, onError) => {
  return {
    type: ORDER_DELIVERY_PARTNERS,
    onSuccess,
    onError,
  };
};


export const ordersReturnEditAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_RETURN_Edit,
    data,
    onSuccess,
    onError,
  };
};

export const ordersServiceAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_SERVICE,
    data,
    onSuccess,
    onError,
  };
};


export const ordersAssignAction = (data, onSuccess, onError) => {
  return {
    type: ORDERS_ASSIGN,
    data,
    onSuccess,
    onError,
  };
};