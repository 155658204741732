import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import ImageUpload from "../../components/ui/forms/image-upload";
import InputField from "../../components/ui/forms/input-field";
import SelectField from "../../components/ui/forms/select-field";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import QuillField from "../../components/ui/forms/quill-field";
import { useDispatch } from "react-redux";
import {
  categoriesCreateAction,
  categoriesDropdownAction,
  categoriesImgUploadAction,
} from "../../redux/actions/categories-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import Compressor from "compressorjs";
import { brandsDropDownListAction } from "../../redux/actions/brands-action";
import NotificationComponent from "../../components/notificationComponent";
const CategoriesCreate = () => {
  const navigate = useNavigate();
  const router = useLocation();
  const { pathname } = router;
  const currenPath = pathname.startsWith("/categories/edit");
  const [imgUrl, setImageUrl] = useState("");
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [imageSize, setImageSize] = useState(false);
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };

  useEffect(() => {
    dispatch(categoriesDropdownAction(OnFetchSuccess, onFetchError));
    dispatch(brandsDropDownListAction(OnBrandSuccess, onBrandError));
  }, []);

  const OnFetchSuccess = (data) => {
    console.log(data);
    setParentCategoryList(
      data?.data
        ?.filter((item) => item?.is_parent_category)
        .map((val) => ({ label: val?.category_name, value: val?.id }))
    );
  };
  const onFetchError = () => {
    console.log("failed to load parent category list");
  };
  const OnBrandSuccess = (data) => {
    console.log(data);
    setBrandList(
      data?.data
        .map((val) => ({ label: val?.name, value: val?.id }))
    );
  };
  const onBrandError = () => {
    console.log("failed to load parent category list");
  };

  const initialValues = {
    category_name: "",
    slug: "",
    is_parent_category: false,
    description: "",
    parent_category_name: "",
    brand_id: []
  };
  const validationSchema = Yup.object().shape({
    category_name: Yup.string().trim().required("Category Name is required"),
    slug: Yup.string().trim().nullable(),
    is_parent_category: Yup.boolean().required("Parent category is required"),
    description: Yup.string().trim(),
  });
  const onSubmit = (values) => {
    console.log(values);
    dispatch(
      categoriesCreateAction(
        {
          category_image: imgUrl,
          category_name: values.category_name,
          description: values.description,
          slug: values.slug,
          is_parent_category: values.is_parent_category,
          parent_category_id: values.parent_category_id,
          parent_category: values?.parent_category_name,
          brand_id: values?.brand_id.map((item) => item?.value),
          status: true
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/categories`);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.data.message);
  };

  function resetFn() {
    setImageUrl("");
  }

  console.log(brandList);
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          console.log(values, "categorecreate");
          return (
            <Form>
              <InnerLayout
                name="New Category"
                page="create"
                edit={true}
                onSubmit={onSubmit}
                resetFn={resetFn}
                rolePermission={"categories"}
              />
              <span>
                <ImageUpload
                  labelClass="color_030304"
                  name="category_image"
                  getImgUrl={getImgUrl}
                  resetFn={resetFn}
                  data={imgUrl}
                  imgLoading={imgLoading}
                  setImgLoading={setImgLoading}
                />
                {imageSize && (
                  <label className="fs_14 ms-5 text-danger mb-3">
                    "Please upload below 10mb"
                  </label>
                )}
              </span>
              <div
                className="ms-md-5 ms-4 pe-4 pe-md-0"
                style={{ maxWidth: "40rem" }}
              >
                <div>
                  <InputField
                    placeholder="Enter category Name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Category Name"
                    name="category_name"
                    type="text"
                  />
                </div>
                <QuillField labelClass="text-black mb-1" name="description" />

                <div>
                  <InputField
                    placeholder="Enter slug text"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Slug"
                    name="slug"
                    type="text"
                  />
                </div>

                <div className="d-flex align-items-center gap-2 mb-4 mt-3">
                  <Field
                    className="form-check-input mb-1"
                    style={{ width: "15px", height: "15px" }}
                    name="is_parent_category"
                    type="checkbox"
                  />
                  <label className="fw_500 fs_14">Is parent Category</label>
                </div>

                <div>
                  <SelectField
                    labelClass="text-black fw_500 fs_14"
                    placeholder={"Select parent category(Optional)"}
                    label={"Parent Category"}
                    id={"parent_category_id"}
                    name="parent_category_id"
                    errMsgContainerClass="ps-2"
                    isFormikControlled={true}
                    options={parentCategoryList}
                  />
                </div>

                {
                  localStorage.getItem("brand") === "true" &&
                  <div>
                    <SelectField
                      isMulti={true}
                      placeholder={"Select brands"}
                      options={brandList}
                      label={"Brands"}
                      isFormikControlled={true}
                      name="brand_id"
                      errMsgContainerClass="ps-2"
                    />
                  </div>
                }

              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CategoriesCreate;
