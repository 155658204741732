import React, { useState } from "react";
import styles from "./auth.module.css";
import InputField from "../../components/ui/forms/input-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { OkayButton as ResetPasswordBtn } from "../../components/ui/buttons/text-button";
import FFlogo from "../../assets/images/ff-logo.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { forgotPasswordAction } from "../../redux/actions/auth-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email_address: Yup.string()
      .email("Enter a valid email")
      .trim()
      .required("Required"),
  });
  const onSuccess = (data) => {
    setLoading(false);
    navigate(`/reset-email-sent/${data?.data?.Email}`);
    successToast(data.message);
  };
  const onError = (data) => {
    setLoading(false);
    console.log(data);
    errorToast(data.data.message);
  };
  return (
    <main
      className={`d-flex ${styles.forgot_pass_container}  vh-100 justify-content-center  align-items-center mx-3`}
    >
      <img
        src={FFlogo}
        alt="ff logo"
        className="d-lg-none d-inline-block position-absolute top-0 start-0 m-4"
      />
      <section
        className={` box_shadow p-sm-5 p-4 pt-sm-4 w-100 d-flex flex-column rounded-3 bg-white`}
        style={{ maxWidth: "30rem" }}
      >
        <span className="color_030304 fs_30 pt-1 fs_md_26">
          Forgot Password?{" "}
        </span>
        <span className="color_9E9E9E pb-4 fs_14 fs_md_14 fw_400">
          Please enter your email address to receive a password
          <br className="d-sm-block d-none" /> reset link.
        </span>
        <Formik
          initialValues={{
            email_address: "",
          }}
          onSubmit={(values) => {
            setLoading(true);
            dispatch(forgotPasswordAction(values, onSuccess, onError));
          }}
          validationSchema={validationSchema}
        >
          <Form>
            <InputField
              autoComplete="email"
              name="email_address"
              errMsgContainerClass="ps-2 pb-4"
            />
            <ResetPasswordBtn
              disabled={false}
              name="Reset Password"
              width={"100%"}
              type="submit"
              loading={loading}
            />
          </Form>
        </Formik>
      </section>
    </main>
  );
}
