import { api } from "./api";

export const adminLoginApi = (params) => {
  return api.post("/core/auth/login", JSON.stringify(params));
};
export const adminLogoutApi = () => {
  return api.post("/core/auth/logout");
};
export const forgotPasswordApi = (params) => {
  return api.post("/core/auth/forgot-password", JSON.stringify(params));
};
export const resetPasswordApi = (params) => {
  return api.post("/core/auth/reset-password", JSON.stringify(params));
};
export const siteSettingsApi = () => {
  return api.get("/configuration/site-settings/view");
};
export const activeStatusApi = () => {
  return api.post("/core/enable-disable-store");
};