import { call, put, takeLatest } from "redux-saga/effects";
import * as authApis from "../apis/auth-apis";
import {
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  STORE_ACTIVE,
} from "../types";

export function* adminLoginWorker(action) {
  try {
    const res = yield call(authApis.adminLoginApi, action.data);
    console.log(res);
    if (res.status === 200) {
      localStorage.setItem("accessToken", res?.data?.data?.access_token);
      // localStorage.setItem(
      //   "role_permissions",
      //   JSON.stringify(res?.data?.data?.role_permissions?.permission)
      // );
      // localStorage.setItem("email_address", res?.data?.data?.email_address);
      // localStorage.setItem("profile_pic", res?.data?.data?.profile_picture);

      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error?.response);
    console.log(error);
  }
}
export function* adminLogoutWorker(action) {
  try {
    const res = yield call(authApis.adminLogoutApi);
    console.log(res);
    if (res.status === 200) {
      localStorage.clear();
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error?.response);
    console.log(error);
  }
}
export function* forgotPasswordWorker(action) {
  try {
    const res = yield call(authApis.forgotPasswordApi, action.data);
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error?.response);
    console.log(error);
  }
}
export function* resetPasswordWorker(action) {
  try {
    const res = yield call(authApis.resetPasswordApi, action.data);
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error?.response);
    console.log(error);
  }
}
export function* storeActiveWorker(action) {
  try {
    const res = yield call(authApis.activeStatusApi);
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
   
  }
}

// export function* siteSettingsWorker(action) {
//   try {
//     const res = yield call(authApis.siteSettingsApi);
//     console.log(res);
//     if (res.status === 200) {
//       yield put({ type: SITE_SETTING_DATA_LOADED, data: res?.data });
//       console.log(res);
//     } else {
//       yield put({
//         type: SITE_SETTING_DATA_FAILED,
//         data: res?.data,
//       });
//     }
//   } catch (error) {
//     yield put({
//       type: SITE_SETTING_DATA_FAILED,
//       data: error?.response,
//     });
//   }
// }
export function* watchAdminLoginWorker() {
  yield takeLatest(ADMIN_LOGIN, adminLoginWorker);
}
export function* watchAdminLogoutWorker() {
  yield takeLatest(ADMIN_LOGOUT, adminLogoutWorker);
}
export function* watchForgotPasswordWorker() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordWorker);
}
export function* watchResetPasswordWorker() {
  yield takeLatest(RESET_PASSWORD, resetPasswordWorker);
}
export function* watchstoreActiveWorker() {
  yield takeLatest(STORE_ACTIVE, storeActiveWorker);
}
// export function* watchSiteSettingsWorker() {
//   yield takeLatest(SITE_SETTINGS, siteSettingsWorker);
// }
