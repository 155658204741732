import React, { useState } from "react";
import { configurationImgUploadAction } from "../../../redux/actions/categories-action";
import { useDispatch } from "react-redux";
import Compressor from "compressorjs";
import errorToast from "../../helper-functions/helper/error-toast";
import UploadIcon from "../../../assets/icons/uploadIcon";
import UplaodImgLogo from "../../../assets/icons/Upload_image_logo.svg";
import DeleteIconWithBg from "../../../assets/icons/products-delete";

const BannerImageField = ({ data, index, setFieldValue }) => {
  const dispatch = useDispatch();
  const [isImgLoad, setIsImgLoad] = useState(false);
  // const [imgUrl, setImgUrl] = useState();
  const [imageSize, setImageSize] = useState(false);
  const [sdfjhvsdf, setsdfjhvsdf] = useState();
  const handleImage = (e) => {
    setIsImgLoad(true);
    let img = e.target.files[0];
    if (img?.size <= 10485760) {
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(
            configurationImgUploadAction(
              result,
              (data) => onImageSuccess(data, `banners[${index}].image`),
              onImageError
            )
          );
        },
      });
    } else {
      setImageSize(true);
    }
  };

  const onImageSuccess = (data, fieldName) => {
    setIsImgLoad(false);
    setFieldValue(fieldName, data);
  };
  const onImageError = (data) => {
    setIsImgLoad(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  function handleDeleteImage(image) {
    setFieldValue(image, "");
  }
  return (
    <div className="uploadAdd-image-container mx-auto">
      {data?.image ? (
        <>
          <div
            style={{ height: "12rem", border: "1px solid #E5ECFF" }}
            className="rounded-3  position-relative w-100"
          >
            <img
              className="rounded-3"
              src={data?.image}
              width={"100%"}
              height={"100%"}
            />
            <span
              onClick={() => handleDeleteImage(`banners[${index}].image`)}
              className="position-absolute bottom-0 end-0 pe-4 pb-2"
            >
              <DeleteIconWithBg fill="#F24444" bgFill="#FFFFFF" />
            </span>
          </div>
        </>
      ) : (
        <div
          className={`h-100 d-flex flex-column align-items-center justify-content-around`}
        >
          <label
            htmlFor={`banners[${index}].image`}
            className="choose-file-label w-100"
          >
            <div
              style={{ height: "12rem", border: "1px solid #E5ECFF" }}
              className="bg_color_F9F9FC position-relative rounded-1"
            >
              {/* <img src={upload} width="50px" height="50px" /> */}
              <span className="position-absolute bottom-0 end-0">
                <UploadIcon />
              </span>
              <div className="d-flex justify-content-center align-items-center h-100">
                <img src={UplaodImgLogo} width={50} />
              </div>
            </div>
          </label>
          <input
            type="file"
            accept="image/*;capture=camera,image/jpeg,image/png,image/svg,image/webp,image/jfif"
            style={{ display: "none" }}
            id={`banners[${index}].image`}
            name={`banners[${index}].image`}
            onChange={handleImage}
          />
        </div>
      )}
    </div>
  );
};

export default BannerImageField;
