import React, { useEffect, useState } from 'react'
import { AddWithBorderButton } from '../../buttons/text-button'
import DeleteIconWithBg from '../../../../assets/icons/products-delete'
import InputField from '../../forms/input-field'
import SelectField from '../../forms/select-field'
import { categoriesDropdownAction } from '../../../../redux/actions/categories-action'
import { tagsDropDownListAction } from '../../../../redux/actions/tags-action'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import MultiSelect from '../../forms/multi-select'
import ImageField from '../../forms/image-field'

function HomeVariant({
    numVariations,
    categoryList,
    tagsList,
    addVariant = () => { },
    setNumVariations,
    setInitialValues
}) {
    const { values, setValues, errors, touched, setFieldValue } = useFormikContext();
    const handleCategoryChange = (selectedOptions, index) => {
        setFieldValue(`variations[${index}].categories`, selectedOptions);
    };

    const handleTagsChange = (selectedOptions, index) => {
        setFieldValue(`variations[${index}].tags`, selectedOptions);
    };

    const option1 = [
        { label: 'Fruits', value: 'c0063b9b-90f8-4ecaf-8dc5-475e4ead4120' },
        { label: 'Vegetables', value: '599b8a69-b8fcf-4193-8f52-d04d494cef70' },
        { label: 'dff', value: '599b8a69-b8cf-4f193-8f52-d04d494cef70' },
        { label: 'Veghhhhetables', value: '599b8a69-b8cfff-4193-8f52-d04d494cef70' },
    ];
    const option2 = [
        { label: 'Fruits', value: 'c0063b9b-90f8-4ecaf-8dc5-475e4ead4120' },
        { label: 'Vegetables', value: '599b8a69-b8fcf-4193-8f52-d04d494cef70' },
        { label: 'dff', value: '599b8a69-b8cf-4f193-8f52-d04d494cef70' },
        { label: 'Veghhhhetables', value: '599b8a69-b8cfff-4193-8f52-d04d494cef70' },
    ];


    const handleDeleteVariation = (indexToDelete) => {
        setValues({
            ...values,
            variations: values?.variations?.filter(
                (_, index) => index !== indexToDelete
            ),
        });
        setInitialValues((prevInitialValues) => {
            const updatedVariations = prevInitialValues.variations?.filter(
                (_, index) => index !== indexToDelete
            );
            return {
                ...prevInitialValues,
                variations: updatedVariations,
            };
        });
        setNumVariations(numVariations - 1)
    };

    return (
        <>
            {
                numVariations === 0 && <AddWithBorderButton
                    onClick={() => {
                        addVariant();
                    }}
                    addClassName="py-1 mb-2 mt-4"
                    customStyles={{ borderRadius: "4px" }}
                    name="Add Variants"
                />
            }
            {[...Array(numVariations)].map((n, index) => (
                <div
                    key={index}
                    className={`border_1px_e5ecff col-xl-7 col-12 p-3 ${index > 0 && "mt-5"}`}>
                    <div className='d-flex justify-content-between'>
                        <span className='color_030304 fw_500 fs_18'>Product List {index + 1}</span>
                        {/* {
                            index != 0 && */}
                        <button
                            onClick={() => handleDeleteVariation(index)}

                        > <DeleteIconWithBg fill="#F24444" bgFill="#F9F9FC" />
                        </button>
                        {/* } */}

                    </div>
                    <InputField
                        containerClass="mt-4"
                        label="Product List Name"
                        id={`variations[${index}].product_list_name2`}
                        placeholder={"Enter product list name"}
                        name={`variations[${index}].product_list_name`}
                    />
                    <MultiSelect
                        name={`variations[${index}].tags`}
                        placeholder="Select Tags"
                        options={tagsList}
                        label="Tags"
                        onChange={(selectedOptions) => handleTagsChange(selectedOptions, index)}
                        value={values.variations[index]?.tags}
                    />
                    <MultiSelect
                        name={`variations[${index}].categories`}
                        placeholder="Select Categories"
                        options={categoryList}
                        label="Categories"
                        onChange={(selectedOptions) => handleCategoryChange(selectedOptions, index)}
                        value={values.variations[index]?.categories}
                    />
                    <p className="font_poppins fs_16 fw_500 color_000000 mb-2">Banner</p>
                    <div className='col-xl-6 col-12'>
                        <ImageField
                            name={`variations[${index}].banner`}
                            index={index}
                            type="variant"
                        />
                    </div>
                </div>
            ))}
            {
                numVariations != 0 && <div className='d-flex justify-content-end col-xl-7 col-12 p-2'>
                    <AddWithBorderButton
                        onClick={() => {
                            addVariant();
                        }}
                        addClassName="py-1 mb-2 mt-4"
                        customStyles={{ borderRadius: "4px" }}
                        name="Add Variants"
                    />
                </div>
            }

        </>
    )
}

export default HomeVariant 