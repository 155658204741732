import { SITE_SETTING_DATA_FAILED, SITE_SETTING_DATA_LOADED, SITE_SETTING_DATA_LOADING } from "../types";
import { fetchSiteSettingData } from "./constants";
export const siteSettingReducer = (state = fetchSiteSettingData, action) => {
    switch (action.type) {
        case SITE_SETTING_DATA_LOADING:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case SITE_SETTING_DATA_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                data: action.data,
            };
        case SITE_SETTING_DATA_LOADED:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                data: action.data,
            };
        default:
            return state;
    }
};