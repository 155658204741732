import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import "./modal.css";
import { CancelButton, OkayButton } from "../buttons/text-button";
import WarningIcon from "../../../assets/icons/Warning-icon(address).svg";
const MarkAsDefaultModel = ({
  show,
  modalClose,
  text = "Choosing this address as the default address will replace the existing default address.",
  getDefaultValue,
}) => {
  const submit = (value)=>{
    getDefaultValue(value);
    modalClose();
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="delete_modal mx-auto"
        contentClassName="rounded-3"
      >
        <ModalBody className="d-flex justify-content-center my-3">
          <div className="col-sm-9 col-11">
            <div className="text-center mb-3">
              <img src={WarningIcon} alt="warning-icon" />
            </div>
            <div className="text-black text-center mb-4">{text}</div>
            <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
              <span onClick={modalClose}>
                <CancelButton />
              </span>
              <span onClick={()=>submit("make_default_address")}>
                <OkayButton type="sumbit" name="OKAY" okayClassName="fs_16 fs_md_14" />
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MarkAsDefaultModel;
