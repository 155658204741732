import React, { useState } from "react";
import DeleteModal from "../../components/ui/modals/delete-modal";
import ResetPassModal from "../../components/ui/modals/reset-pass-modal";
import PasswordSuccessfulModal from "../../components/ui/modals/password-successful-modal";
import ImageUpload from "../../components/ui/forms/image-upload";
import CreateTagModal from "../../components/ui/modals/create-tag-modal";
import CreateAttributeModal from "../../components/ui/modals/create-attribute-modal";
import EditVariantModal from "../../components/ui/modals/edit-variant-modal";

const Modal = () => {
  const [deleteShow, setDeleteShow] = useState(false);
  const deleteModalClose = () => setDeleteShow(false);
  const deleteModalShow = () => setDeleteShow(true);

  const [resetPassShow, setResetPassShow] = useState(false);
  const resetPassModalShow = () => setResetPassShow(true);
  const resetPassModalClose = () => setResetPassShow(false);

  const [passwordSuccessful, setPasswordSuccessful] = useState(false);
  const passSuccessModalShow = () => setPasswordSuccessful(true);
  const passSuccessModalClose = () => setPasswordSuccessful(false);

  const [addProducts, setAddProducts] = useState(false);
  const addProductModalShow = () => setAddProducts(true);
  const addProductModalClose = () => setAddProducts(false);
  const [imgUrl, setImageUrl] = useState("");
  const getImgUrl = (img) => {
    setImageUrl(img);
  };
  console.log(imgUrl, "imgUrl");

  const [showCreateAttribute, setShowCreateAttribute] = useState(false);
  const [showEditVariant, setShowEditVariant] = useState(false);
  const [showCreateTag, setCreateTag] = useState(false);
  return (
    <div>
      <button type="button" onClick={deleteModalShow}>
        Delete
      </button>
      <DeleteModal
        show={deleteShow}
        setDeleteShow={setDeleteShow}
        modalShow={deleteModalShow}
        modalClose={deleteModalClose}
        type="delete"
      />
      <button type="button" onClick={resetPassModalShow}>
        Reset Password
      </button>
      <ResetPassModal
        show={resetPassShow}
        setresetPassShow={setResetPassShow}
        modalShow={resetPassModalShow}
        modalClose={resetPassModalClose}
      />
      <button type="button" onClick={passSuccessModalShow}>
        Password Successful
      </button>
      <PasswordSuccessfulModal
        show={passwordSuccessful}
        setPasswordSuccessful={setPasswordSuccessful}
        modalShow={passSuccessModalShow}
        modalClose={passSuccessModalClose}
      />
      <button type="button" onClick={addProductModalShow}>
        Add Products
      </button>
     
      <ImageUpload getImgUrl={getImgUrl} />

      <CreateTagModal
        firstPlaceholder="Create new password"
        // resetFn={resetPassord}
        show={showCreateTag}
        modalClose={() => {
          setCreateTag(false);
        }}
      />
      <button type="buttton" onClick={() => setCreateTag(true)}>
        Create Tag
      </button>
      <CreateAttributeModal
        firstPlaceholder="Create new password"
        // resetFn={resetPassord}
        show={showCreateAttribute}
        modalClose={() => {
          setShowCreateAttribute(false);
        }}
      />
      <button type="buttton" onClick={() => setShowCreateAttribute(true)}>
        Create Attribute
      </button>
      <EditVariantModal 
      show={showEditVariant}
      modalClose={() => {
        setShowEditVariant(false);
      }}/>
      <button type="buttton" onClick={() => setShowEditVariant(true)}>
        Edit Variant
      </button>
    </div>
  );
};

export default Modal;
