import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DynamicTable from "../../components/ui/tables/table";
import { useLocation } from "react-router";
import ProductsTableFitler from "../../components/ui/tables/table-filter/products-table-filter";
import {
  productsDeleteAction,
  productsMultiDeleteAction,
  productsTableAction,
} from "../../redux/actions/products-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import NotificationComponent from "../../components/notificationComponent";
export default function ProductsTable() {
  const location = useLocation();
  const [productsData, setProductsData] = useState({
    products: [],
    pagination: {},
  });
  const [inputValue, setInputValue] = useState("");
  //   const [checkedId, setCheckedId] = useState([]);
  const dispatch = useDispatch();

  const [filterShow, setFilterShow] = useState(false);
  const [filterSubmission, setFilterSubmission] = useState("submit");
  const [filterValues, setFilterValues] = useState({
    out_of_stock: false,
    categories: [],
  });
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      productsTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig,
          filter: {
            filteredValue: filterValues,
          },
        },
        {
          product_id: [],
          category: filterValues?.categories?.map((item) => item?.value),
          tags: filterValues?.tags?.map((item) => item?.value),
          ...(filterValues?.out_of_stock ? { out_of_stock: true } : {}),
        },
        onSuccess,
        onError
      )
    );
  }, [reload, inputValue, currentPage, filterValues, sortConfig]);

  const onSuccess = (data) => {
    console.log(data);
    setError(false);
    setProductsData(data.data);
    setLoading(false);
    filterSubmission === "submit" && setFilterShow(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };

  const handleFilterValues = (values, type) => {
    setFilterSubmission(type);
    setFilterValues(values);
  };

  console.log(productsData);

  const tableData = productsData?.products
    ?.map((item) => ({
      image: item?.thumbnail_image ? item?.thumbnail_image : "product_picture",
      id: item?.id,
      code: item?.code,
      product_name: item?.product_name
        ? removeUnderScore(item?.product_name)
        : "--",
      category:
        item?.category.length > 0
          ? item?.category
              .map((value, index) => {
                return value?.category_name;
              })
              .join("  |  ")
          : "--",
      status: item?.status,
      price:
        item?.price === null
          ? item?.variation_products?.[0]?.price
          : item?.price ?? "--",
      Action: ["/products/view/", "/products/edit/"],
    }))
    .filter((item) => item.email !== "ff_admin@gmail.com");
  return (
    <div className="col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5">
      <NotificationComponent />
      <DynamicTable
        tabletitle="Products"
        placeholder="Search product here by id and name"
        modalText={"product"}
        loading={loading}
        reload={reload}
        error={error}
        tableHeading={tableDiscountHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        setCurrentPage={setCurrentPage}
        createButtonName="CREATE PRODUCTS"
        createpath="/products/create"
        pagination={productsData?.pagination}
        singleDeleteAction={productsDeleteAction}
        multiDeleteAction={productsMultiDeleteAction}
        setLoading={setLoading}
        setReload={setReload}
        id_name={"product_id"}
        filtration={true}
        filterFn={() => setFilterShow(true)}
        rolePermission={"products"}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
      <ProductsTableFitler
        show={filterShow}
        handleClose={() => setFilterShow(false)}
        filterValues={filterValues}
        handleFilterValues={handleFilterValues}
      />
    </div>
  );
}
const tableDiscountHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "ID", value: "code" },
  { label: "Product Name ", value: "product_name" },
  { label: "Category ", value: "category_id" },
  { label: "Status", value: "status" },
  { label: "Price", value: "price" },
  { label: "Actions", value: "Actions" },
];
