import React, { useEffect, useState } from "react";
import DashboardIcon from "../../../assets/icons/layout-icons/dashboard-icon";
import AdminUsersIcon from "../../../assets/icons/layout-icons/admin-users-icon";
import AdminRoleIcon from "../../../assets/icons/layout-icons/admin-role-icon";
import { useLocation, useNavigate } from "react-router";
import usePermissions from "../../helper-functions/hooks/usePermission-checker";
import ProductIcon from "../../../assets/icons/layout-icons/product-icon";
import CategoriesIcon from "../../../assets/icons/layout-icons/categories-icon";
import DiscountIcon from "../../../assets/icons/layout-icons/Discount_icon";
import AttributesIcon from "../../../assets/icons/layout-icons/Attributes-icon";
import TagsIcon from "../../../assets/icons/layout-icons/Tags-icon";
import OrdersIcon from "../../../assets/icons/layout-icons/Orders-icon";
import CustomersIcon from "../../../assets/icons/layout-icons/Customers-icon";
import AddressIcon from "../../../assets/icons/layout-icons/Address-icon";
import ConfigurationIcon from "../../../assets/icons/layout-icons/Configuration-icon";
import AccountIcon from "../../../assets/icons/layout-icons/Account-icon";
import DeliveryPartnersIcon from "../../../assets/icons/layout-icons/deliveryPartner-icon";
import BrandsIcon from "../../../assets/icons/layout-icons/Brands-icon";
import ContactIcon from "../../../assets/icons/layout-icons/contact-icon";
import HeadsetIcon from "../../../assets/icons/layout-icons/headsetIcon";
import PluginIcon from "../../../assets/icons/layout-icons/pluginIcon";
import InterfaceIcon from "../../../assets/icons/layout-icons/interface-icon";
import ThemeIcon from "../../../assets/icons/layout-icons/themeIcon";

const SideBar = ({ setShow, show = false }) => {
  const router = useLocation();
  const { canRead } = usePermissions();
  const navigate = useNavigate();
  const [activetab, setActivetab] = useState("Admin Users");

  const activeTabFn = () => {
    if (router.pathname.startsWith("/attributes")) {
      return "Attributes";
    } else if (router.pathname.startsWith("/admin/users")) {
      return "Admin Users";
    } else if (router.pathname.startsWith("/admin/roles")) {
      return "Admin Roles";
    } else if (router.pathname.startsWith("/categories")) {
      return "Categories";
    } else if (router.pathname.startsWith("/discounts")) {
      return "Discounts";
    } else if (router.pathname.startsWith("/tags")) {
      return "Tags";
    } else if (router.pathname.startsWith("/brands")) {
      return "Brands";
    } else if (router.pathname.startsWith("/deliverypartners")) {
      return "Delivery Partners";
    } else if (router.pathname.startsWith("/customers")) {
      return "Customers";
    } else if (router.pathname.startsWith("/address")) {
      return "Addresses";
    } else if (router.pathname.startsWith("/products")) {
      return "Products";
    } else if (router.pathname.startsWith("/orders")) {
      return "Orders";
    } else if (router.pathname.startsWith("/plugins")) {
      return "Plugins";
    } else if (router.pathname.startsWith("/themes")) {
      return "Themes";
    } else if (router.pathname.startsWith("/configuration")) {
      return "Configuration";
    } else if (router.pathname.startsWith("/accounts")) {
      return "Account";
    } else if (router.pathname.startsWith("/delivery-partner")) {
      return "Delivery Partners";
    } else if (router.pathname.startsWith("/contact-enquiry")) {
      return "Contact Enquiries";
    } else if (router.pathname.startsWith("/interface")) {
      return "Interface";
    } else if (router.pathname.startsWith("/product-enquiry")) {
      return "Product Enquiries";
    } else return "Dashboard";
  };
  useEffect(() => {
    setActivetab(activeTabFn());
  }, [activetab, router.pathname]);
  const handleSibeBarTab = (item) => {
    if (show) {
      navigate(item?.path);
      setActivetab(activeTabFn());
      setShow(false);
    } else {
      navigate(item?.path);
      setActivetab(activeTabFn());
    }
  };

  const storedBrand = localStorage.getItem("brand");

  const sideBarData = [
    {
      heading: "Home",
      list: [
        {
          image: (
            <DashboardIcon
              fill={activetab === "Dashboard" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Dashboard",
          path: "/",
          display: true,
        },
        {
          image: (
            <AdminUsersIcon
              fill={activetab === "Admin Users" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Admin Users",
          path: "/admin/users",
          display: canRead("admin_users") ?? true,
        },
        {
          image: (
            <AdminRoleIcon
              fill={activetab === "Admin Roles" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Admin Roles",
          path: "/admin/roles",
          display: canRead("admin_roles") ?? true,
        },
      ],
    },
    {
      heading: "E-Commerce",
      list: [
        {
          image: (
            <ProductIcon
              fill={activetab === "Products" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Products",
          path: "/products",
          display: canRead("products") ?? true,
        },

        {
          image: (
            <BrandsIcon fill={activetab === "Brands" ? `#2820DA` : `#707070`} />
          ),
          title: "Brands",
          path: "/brands",
          display:
            (canRead("brands") &&
              (localStorage.getItem("brand") === "true" ? true : false)) ??
            true,
        },
        {
          image: (
            <CategoriesIcon
              fill={activetab === "Categories" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Categories",
          path: "/categories",
          display: canRead("categories") ?? true,
        },
        {
          image: (
            <DiscountIcon
              fill={activetab === "Discounts" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Discounts",
          path: "/discounts",
          display: canRead("discounts") ?? true,
        },
        {
          image: (
            <AttributesIcon
              fill={activetab === "Attributes" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Attributes",
          path: "/attributes",
          display: canRead("attributes") ?? true,
        },
        {
          image: (
            <TagsIcon fill={activetab === "Tags" ? `#2820DA` : `#707070`} />
          ),
          title: "Tags",
          path: "/tags",
          display: canRead("tags") ?? true,
        },
        {
          image: (
            <OrdersIcon fill={activetab === "Orders" ? `#2820DA` : `#707070`} />
          ),
          title: "Orders",
          path: "/orders",
          display: canRead("orders") ?? true,
        },
        {
          image: (
            <HeadsetIcon
              fill={activetab === "Product Enquiries" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Product Enquiries",
          path: "/product-enquiry",
          display: canRead("orderEnquiry") ?? true,
        },
        {
          image: (
            <ContactIcon
              fill={activetab === "Contact Enquiries" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Contact Enquiries",
          path: "/contact-enquiry",
          display: canRead("contactUs") ?? true,
        },
      ],
    },
    {
      heading: "Customer Data",
      list: [
        {
          image: (
            <CustomersIcon
              fill={activetab === "Customers" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Customers",
          path: "/customers",
          display: canRead("customers") ?? true,
        },
        {
          image: (
            <AddressIcon
              fill={activetab === "Addresses" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Addresses",
          path: "/address",
          display: canRead("addresses") ?? true,
        },
        {
          image: (
            <DeliveryPartnersIcon
              fill={activetab === "Delivery Partners" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Delivery Partners",
          path: "/delivery-partners",
          // display: true,
          display:
            (canRead("deliveryPartner") &&
              localStorage.getItem("delivery_type") === "self") ??
            true,
        },
      ],
    },
    {
      heading: "Settings",
      list: [
        {
          image: (
            <InterfaceIcon
              fill={activetab === "interface" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Interface",
          path: "/interface",
          display: canRead("configuration") ?? true,
        },
        {
          image: (
            <PluginIcon
              fill={activetab === "Plugins" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Plugins",
          path: "/plugins",
          display: canRead("plugins") ?? true,
        },
        {
          image: (
            <ThemeIcon fill={activetab === "Themes" ? `#2820DA` : `#707070`} />
          ),
          title: "Themes",
          path: "/themes",
          display: canRead("themes") ?? true,
        },

        {
          image: (
            <ConfigurationIcon
              fill={activetab === "Configuration" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Configuration",
          path: "/configuration",
          display: canRead("configuration") ?? true,
        },
        {
          image: (
            <AccountIcon
              fill={activetab === "Account" ? `#2820DA` : `#707070`}
            />
          ),
          title: "Account",
          path: "/accounts/view",
          display: canRead("account") ?? true,
        },
      ],
    },
  ];
  const filteredData = sideBarData
    .map((item) => {
      const filteredList = item.list.filter(
        (listItem) => listItem.display === true
      );
      if (filteredList.length > 0) {
        return { heading: item.heading, list: filteredList };
      }
      return null;
    })
    .filter(Boolean);

  return (
    <>
      <div
        style={{ overflowY: "scroll" }}
        className={`${!show && "sidebar_container"} scrollbar pt-4`}
      >
        {filteredData?.map((item) => {
          return (
            <React.Fragment key={item?.heading}>
              <div className="ps-4 mt-2 color_444444 fw_500 fs_14">
                {item?.heading}
              </div>
              <ul className="list-unstyled ms-4">
                {item?.list.map((item) => {
                  return (
                    <li
                      className={`mt-1 mx-2 rounded-2 text-center py-1 ${
                        activetab === item?.title
                          ? "bg_color_E5ECFFB2 color_030304 fw_500"
                          : "color_707070"
                      } fw_400  d-flex gap-3 align-items-center fs_15 ps-3 cursor_pointer`}
                      onClick={() => handleSibeBarTab(item)}
                      key={item?.title}
                    >
                      {item?.image}
                      {item?.title}
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default SideBar;
