import React from "react";

const PlusIcon = ({ fill = "white", width = "14", height = "14", className }) => {
  return (
    <svg
    className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 6.125H7.875V0.875C7.875 0.391754 7.48325 0 7 0C6.51675 0 6.125 0.391754 6.125 0.875V6.125H0.875C0.391754 6.125 0 6.51675 0 7C0 7.48325 0.391754 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIcon;
