import React, { useEffect, useState } from "react";
import * as adminRoleActions from "../../../redux/actions/admin-roles-action";
import { useDispatch } from "react-redux";
import errorToast from "../../../components/helper-functions/helper/error-toast";
import DynamicTable from "../../../components/ui/tables/table";
import { useLocation } from "react-router";
import NotificationComponent from "../../../components/notificationComponent";

export default function AdminRolesTable() {
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);
  const dispatch = useDispatch();
  const [adminRolesData, setAdminRolesData] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  const [reload, setReload] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      adminRoleActions.adminRoleTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, inputValue, reload, sortConfig]);

  const onSuccess = (data) => {
    setLoading(false);
    setError(false);
    setAdminRolesData(data?.data?.roles);
    setPagination(data?.data?.pagination);
    console.log(data);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
    errorToast(data?.data?.message);
  };
  console.log(adminRolesData);
  const tableData = adminRolesData?.map((item) => ({
    id: item?.id,
    code: item?.code,
    role_name: item?.role_name,
    Action: ["/admin/roles/view/", "/admin/roles/edit/"],
  }));
  return (
    <div className="col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5">
      <NotificationComponent />
      <DynamicTable
        tabletitle={"Admin Roles"}
        tableHeading={tableAdminRolesHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        createButtonName="CREATE ROLES"
        createpath="/admin/roles/create"
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={adminRoleActions.adminRoleDeleteAction}
        multiDeleteAction={adminRoleActions.adminRoleMultiDeleteAction}
        loading={loading}
        setLoading={setLoading}
        error={error}
        id_name={"role_id"}
        setReload={setReload}
        reload={reload}
        rolePermission={"admin_roles"}
        placeholder={"Search roles here id, name "}
        modalText={"admin role"}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
    </div>
  );
}
const tableAdminRolesHeader = [
  { label: "ID", value: "code" },
  { label: "Name", value: "role_name" },
  { label: "Actions", value: "Actions" },
];
