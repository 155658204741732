import React from "react";
import navbarLogo from "../../../assets/images/ff-logo.svg";
import { Link } from "react-router-dom";
export default function ErrorView(props) {
  console.log(props.error);
  return (
    <section className="vh-100 d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center ">
        <Link to={"/"}>
          <img
            src={navbarLogo}
            className="ps-lg-3 w-100 mb-4"
            alt="logo"
            style={{
              maxWidth: "100px",
            }}
          />
        </Link>
        <h1 className="color_F24444 fs_24 font_montserrat mb-1">
          Oops Something Went Wrong!
        </h1>{" "}
        <p className="fs_12">Please reload the page</p>
      </div>
    </section>
  );
}
