import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../../modals/modal.css";
import DiscountedProductsList from "../../discounts/discounted-products-list";
import SelectField from "../../forms/select-field";
import { CancelButton, DeleteButton } from "../../buttons/text-button";
import ProductRejectionTable from "../../tables/product-table-rejection";
import { variationFunction } from "../../../helper-functions/helper/date";
import { useDispatch } from "react-redux";
import { ordersEditAction, ordersPartialRejectAction } from "../../../../redux/actions/orders-action";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
      
export default function EditProduct({
  show,
  popupClose,
  orderDetails,
  product,
  setProduct,
  neededProduct,
  setNeededProduct,
  id,
  reload,
  setReload,
  rejectReason
}) {
  const dispatch = useDispatch()



  const initialValues = {
    // qty: "",
  };




  const onSubmit = (values) => {
    dispatch(
      ordersPartialRejectAction(
        {
          "order_id": id,
          "orders_status": "partially_rejected",
          "products": neededProduct,
          "reject_reason": rejectReason
        },
        onEditSuccess,
        onEditError
      )
    )
  };

  const onEditSuccess = (data) => {
    setReload(!reload)
    successToast("Order Updated SuccessFully")
    popupClose()
  }
  const onEditError = (data) => {
    errorToast(data?.data?.message)
  }











  return (
    <div>
      <Modal
        show={show}
        onHide={popupClose}
        centered
        dialogClassName="edit_product_model"
        contentClassName="rounded-3"
      // backdrop="static"
      >
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, field, error, setFieldValue }) => {
            console.log(values, "editproduct");
            return (
              <Form>
                <ModalHeader>
                  <div className="ms-2">
                    <img src={BackIcon} />
                    <span className="ms-2 fs_16 fw_500">Edit Products</span>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <ProductRejectionTable
                    tableDiscountHeader={tableProductListHeader}
                    product={product}
                    setProduct={setProduct}
                    neededProduct={neededProduct}
                    setNeededProduct={setNeededProduct}
                  />
                  <div className="d-flex justify-content-end gap-2 mt-3 mb-4">
                    <span onClick={popupClose}><CancelButton cancelClassName="px-2" /></span>
                    <DeleteButton
                      icon={false}
                      name="PARTIALLY REJECT"
                      type="submit"
                      deleteClassName="px-2"
                      disabled={JSON.stringify(orderDetails?.cart) === JSON.stringify(neededProduct)}
                    />
                  </div>
                </ModalBody>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
const tableProductListHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "Variation", value: "variation" },
  { label: "Qty", value: "qty" },
  { label: "Add-Ons", value: "addons" },
  // { label: "SkU", value: "sku" },
  // { label: "Barcode", value: "barcode" },
  { label: "Price", value: "price" },
  { label: "Discounted Price", value: "discounted_price" },
  { label: "Total Price", value: "total_price" },
  { label: "Actions", value: "action" },
];
