import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = ({size="sm"}) => {
  return (
    <div>
      <Spinner
        animation="border"
        as={"span"}
        size={size}
        variant="grey"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default LoadingSpinner;
