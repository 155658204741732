import React from "react";

const UnblockIcon = ({ fill = "#3AD64C", width = "14", height = "14" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 0C8.88523 0 10.6724 0.864337 11.9692 2.20267C13.2087 3.48213 14 5.19487 14 7C13.9999 10.8599 10.8599 14 7 14C3.18639 14 0 10.7875 0 7C0 3.14014 3.14014 0 7 0ZM9.24958 3.02927C8.58506 2.65132 7.81765 2.43424 7 2.43424C4.48234 2.43424 2.43413 4.48245 2.43424 7.00011C2.43424 7.81776 2.65132 8.58517 3.02927 9.24969L4.40656 7.87238L6.12771 9.59353L4.75042 10.9708C5.41494 11.3488 6.18236 11.5659 7 11.5659C9.51766 11.5659 11.5659 9.51766 11.5658 7C11.5658 6.18235 11.3487 5.41494 10.9707 4.75042L9.59777 6.12341L7.87662 4.40226L9.24958 3.02927Z"
        fill={fill}
      />
    </svg>
  );
};

export default UnblockIcon;
