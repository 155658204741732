import React from "react";

const SalesIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="23"
      height="23"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9992 4.66667C19.9992 3.6075 19.5133 2.63417 18.6658 1.99583C17.8267 1.365 16.7708 1.17 15.765 1.45917C15.0508 1.66583 14.225 1.75 12.9167 1.75C11.7783 1.75 10.7175 1.46083 9.59417 1.15417C8.415 0.8325 7.195 0.5 5.83334 0.5C4.8675 0.5 3.81084 0.715833 2.69334 1.1425C1.08167 1.75667 -0.00166321 3.32417 -0.00166321 5.04333V11.3333C-0.00166321 12.3925 0.485003 13.3658 1.3325 14.0042C2.17084 14.635 3.2275 14.83 4.23334 14.5408C4.9475 14.3342 5.77417 14.25 7.08334 14.25C8.22167 14.25 9.2825 14.5392 10.4042 14.8458C11.5833 15.1675 12.8033 15.5 14.165 15.5C15.1308 15.5 16.1875 15.2842 17.305 14.8575C18.9175 14.2433 20 12.6758 20 10.9567L19.9992 4.66667ZM4.16667 10.5C3.70667 10.5 3.33334 10.1267 3.33334 9.66667C3.33334 9.20667 3.70667 8.83333 4.16667 8.83333C4.62667 8.83333 5 9.20667 5 9.66667C5 10.1267 4.62667 10.5 4.16667 10.5ZM4.16667 5.5C3.70667 5.5 3.33334 5.12667 3.33334 4.66667C3.33334 4.20667 3.70667 3.83333 4.16667 3.83333C4.62667 3.83333 5 4.20667 5 4.66667C5 5.12667 4.62667 5.5 4.16667 5.5ZM11.6667 10.5C11.6667 10.96 11.2942 11.3333 10.8333 11.3333C10.3725 11.3333 10 10.96 10 10.5V7.51167L9.75584 7.75583C9.43 8.08167 8.90334 8.08167 8.5775 7.75583C8.25167 7.43 8.25167 6.90333 8.5775 6.5775L10.2442 4.91083C10.4825 4.67167 10.8408 4.60083 11.1525 4.73C11.4642 4.85917 11.6667 5.16333 11.6667 5.5V10.5ZM15.8333 12.1667C15.3733 12.1667 15 11.7933 15 11.3333C15 10.8733 15.3733 10.5 15.8333 10.5C16.2933 10.5 16.6667 10.8733 16.6667 11.3333C16.6667 11.7933 16.2933 12.1667 15.8333 12.1667ZM15.8333 7.16667C15.3733 7.16667 15 6.79333 15 6.33333C15 5.87333 15.3733 5.5 15.8333 5.5C16.2933 5.5 16.6667 5.87333 16.6667 6.33333C16.6667 6.79333 16.2933 7.16667 15.8333 7.16667Z"
        fill="#FFA800"
      />
    </svg>
  );
};

export default SalesIcon;
