import { api } from "./api";

export const productsTableApi = (data, params) => {
  return api.post(
    `/core/products/list?page=${data?.page}&page_size=${data?.size}&search=${
      data?.search
    }&${
      data?.sort != null &&
      `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`
    }`,
    JSON.stringify(params)
  );
};
export const productsDeleteApi = (data, params) => {
  return api.delete(
    `/core/products/delete?product_id=${data}`,
    JSON.stringify(params)
  );
};
export const productsMultiDeleteApi = (params) => {
  return api.delete(`/core/products/bulk-delete`, params);
};
export const productsViewApi = (data) => {
  return api.get(`/core/products/view?product_id=${data}`);
};

export const productsEditApi = (params) => {
  return api.post(`/core/products/edit`, JSON.stringify(params));
};

export const productsCreateApi = (params) => {
  return api.post(`/core/products/create`, JSON.stringify(params));
};
export const allProductsIdAApi = () => {
  return api.get(`/core/products/list-all-products`);
};
export const updateProductStatusApi = (params) => {
  return api.post(
    `/core/products/update-product-status`,
    JSON.stringify(params)
  );
};
export const updateProductStockStatusApi = (params) => {
  return api.post(
    `/core/products/update-stock-availability`,
    JSON.stringify(params)
  );
};
