import React from "react";

const DashboardIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25002 0H3.00001C2.20436 0 1.44129 0.316072 0.878683 0.878683C0.316072 1.44129 0 2.20436 0 3.00001L0 5.25002C0 6.04567 0.316072 6.80874 0.878683 7.37135C1.44129 7.93396 2.20436 8.25003 3.00001 8.25003H5.25002C6.04567 8.25003 6.80874 7.93396 7.37135 7.37135C7.93396 6.80874 8.25003 6.04567 8.25003 5.25002V3.00001C8.25003 2.20436 7.93396 1.44129 7.37135 0.878683C6.80874 0.316072 6.04567 0 5.25002 0ZM6.75003 5.25002C6.75003 5.64785 6.59199 6.02938 6.31069 6.31069C6.02938 6.59199 5.64785 6.75003 5.25002 6.75003H3.00001C2.60219 6.75003 2.22065 6.59199 1.93935 6.31069C1.65804 6.02938 1.50001 5.64785 1.50001 5.25002V3.00001C1.50001 2.60219 1.65804 2.22065 1.93935 1.93935C2.22065 1.65804 2.60219 1.50001 3.00001 1.50001H5.25002C5.64785 1.50001 6.02938 1.65804 6.31069 1.93935C6.59199 2.22065 6.75003 2.60219 6.75003 3.00001V5.25002Z"
        fill={fill}
      />
      <path
        d="M15 0H12.75C11.9544 0 11.1913 0.316072 10.6287 0.878683C10.0661 1.44129 9.75 2.20436 9.75 3.00001V5.25002C9.75 6.04567 10.0661 6.80874 10.6287 7.37135C11.1913 7.93396 11.9544 8.25003 12.75 8.25003H15C15.7957 8.25003 16.5587 7.93396 17.1213 7.37135C17.684 6.80874 18 6.04567 18 5.25002V3.00001C18 2.20436 17.684 1.44129 17.1213 0.878683C16.5587 0.316072 15.7957 0 15 0ZM16.5 5.25002C16.5 5.64785 16.342 6.02938 16.0607 6.31069C15.7794 6.59199 15.3978 6.75003 15 6.75003H12.75C12.3522 6.75003 11.9707 6.59199 11.6893 6.31069C11.408 6.02938 11.25 5.64785 11.25 5.25002V3.00001C11.25 2.60219 11.408 2.22065 11.6893 1.93935C11.9707 1.65804 12.3522 1.50001 12.75 1.50001H15C15.3978 1.50001 15.7794 1.65804 16.0607 1.93935C16.342 2.22065 16.5 2.60219 16.5 3.00001V5.25002Z"
        fill={fill}
      />
      <path
        d="M5.25002 9.75H3.00001C2.20436 9.75 1.44129 10.0661 0.878683 10.6287C0.316072 11.1913 0 11.9544 0 12.75L0 15C0 15.7957 0.316072 16.5587 0.878683 17.1213C1.44129 17.684 2.20436 18 3.00001 18H5.25002C6.04567 18 6.80874 17.684 7.37135 17.1213C7.93396 16.5587 8.25003 15.7957 8.25003 15V12.75C8.25003 11.9544 7.93396 11.1913 7.37135 10.6287C6.80874 10.0661 6.04567 9.75 5.25002 9.75ZM6.75003 15C6.75003 15.3978 6.59199 15.7794 6.31069 16.0607C6.02938 16.342 5.64785 16.5 5.25002 16.5H3.00001C2.60219 16.5 2.22065 16.342 1.93935 16.0607C1.65804 15.7794 1.50001 15.3978 1.50001 15V12.75C1.50001 12.3522 1.65804 11.9707 1.93935 11.6893C2.22065 11.408 2.60219 11.25 3.00001 11.25H5.25002C5.64785 11.25 6.02938 11.408 6.31069 11.6893C6.59199 11.9707 6.75003 12.3522 6.75003 12.75V15Z"
        fill={fill}
      />
      <path
        d="M15 9.75H12.75C11.9544 9.75 11.1913 10.0661 10.6287 10.6287C10.0661 11.1913 9.75 11.9544 9.75 12.75V15C9.75 15.7957 10.0661 16.5587 10.6287 17.1213C11.1913 17.684 11.9544 18 12.75 18H15C15.7957 18 16.5587 17.684 17.1213 17.1213C17.684 16.5587 18 15.7957 18 15V12.75C18 11.9544 17.684 11.1913 17.1213 10.6287C16.5587 10.0661 15.7957 9.75 15 9.75ZM16.5 15C16.5 15.3978 16.342 15.7794 16.0607 16.0607C15.7794 16.342 15.3978 16.5 15 16.5H12.75C12.3522 16.5 11.9707 16.342 11.6893 16.0607C11.408 15.7794 11.25 15.3978 11.25 15V12.75C11.25 12.3522 11.408 11.9707 11.6893 11.6893C11.9707 11.408 12.3522 11.25 12.75 11.25H15C15.3978 11.25 15.7794 11.408 16.0607 11.6893C16.342 11.9707 16.5 12.3522 16.5 12.75V15Z"
        fill={fill}
      />
    </svg>
  );
};

export default DashboardIcon;
