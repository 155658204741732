import { api } from "./api";

//TAX SETTINGS
export const taxCreateApi = (data) => {
  return api.post(`/configuration/tax/create-or-edit`, JSON.stringify(data));
};
export const taxViewApi = (params) => {
  return api.get(`/configuration/tax/view`, JSON.stringify(params));
};

//MAIL NOTIFICATION SETTINGS
export const mailNotifyCreateApi = (data) => {
  return api.post(`/configuration/mails/create-or-edit`, JSON.stringify(data));
};
export const mailNotifyViewApi = (params) => {
  return api.get(`/configuration/mails/view`, JSON.stringify(params));
};

//site settings
export const siteCreateApi = (params) => {
  return api.post(
    `/configuration/site-settings/create-or-edit`,
    JSON.stringify(params)
  );
};
export const siteViewApi = () => {
  return api.get(`/configuration/site-settings/view`);
};
export const siteViewWithoutAccessApi = () => {
  return api.get(`/configuration/site-settings/logo-title`);
};

export const paymentsCreateApi = (params) => {
  return api.post(`/configuration/payment/create`, JSON.stringify(params));
};
export const paymentsViewApi = (data) => {
  return api.get(
    `/configuration/payment/view?main_payment_type=${data.main_payment_type}`
  );
};
export const paymentsEditApi = (params) => {
  return api.put(`/configuration/payment/edit`, JSON.stringify(params));
};

/**Pages**/
export const configPageDeleteApi = (params) => {
  return api.delete(`/configuration/pages/delete?page_id=${params}`);
};
export const configPageCreateApi = (params) => {
  return api.post(`/configuration/pages/create`, JSON.stringify(params));
};
export const configPageViewApi = (params) => {
  return api.get(`/configuration/pages/view?main_page_type=${params}`);
};
export const configPageListApi = () => {
  return api.get(`/configuration/pages/list`);
};
export const configPageEditApi = (params) => {
  return api.put(`/configuration/pages/edit`, JSON.stringify(params));
};

export const shippingCreateApi = (params) => {
  return api.post(
    `/configuration/shipping-settings/create-or-edit`,
    JSON.stringify(params)
  );
};
export const shippingViewApi = () => {
  return api.get(`/configuration/shipping-settings/view`);
};

export const contactInfoCreateUpdateApi = (data) => {
  return api.post(
    `/configuration/contact-info/create-or-edit`,
    JSON.stringify(data)
  );
};

export const contactInfoViewApi = () => {
  return api.get(`/configuration/contact-info/view`);
};

export const homeSettingsCreateApi = (params) => {
  return api.post(
    `/configuration/home-setting/create-or-edit`,
    JSON.stringify(params)
  );
};
export const homeSettingsViewApi = () => {
  return api.get(`/configuration/home-setting/view`);
};