import { all } from "redux-saga/effects";
import {
  watchCustomerBlockWorker,
  watchCustomerCreateWorker,
  watchCustomerDeleteWorker,
  watchCustomerEditWorker,
  watchCustomerMultiDeleteWorker,
  watchCustomerResetPswWorker,
  watchCustomerSendPasswordWorker,
  watchCustomerTableWorker,
  watchCustomerViewWorker,
} from "../customer-saga";

export default function* customerRootSaga() {
  yield all([
    watchCustomerTableWorker(),
    watchCustomerCreateWorker(),
    watchCustomerViewWorker(),
    watchCustomerEditWorker(),
    watchCustomerDeleteWorker(),
    watchCustomerMultiDeleteWorker(),
    watchCustomerBlockWorker(),
    watchCustomerResetPswWorker(),
    watchCustomerSendPasswordWorker(),
  ]);
}
