import { LOGGED_ADMIN_DATA_FAILED, LOGGED_ADMIN_DATA_LOADED, LOGGED_ADMIN_DATA_LOADING } from "../types";
import { fetchLoggedAdminData } from "./constants";
export const loggedAdminReducer = (state = fetchLoggedAdminData, action) => {
  switch (action.type) {
    case LOGGED_ADMIN_DATA_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    case LOGGED_ADMIN_DATA_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        data: action.data,
      };
    case LOGGED_ADMIN_DATA_LOADED:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.data,
      };
    default:
      return state;
  }
};