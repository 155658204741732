import React from 'react'

const AddressIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.25 8V8.5C9.25 8.776 9.026 9 8.75 9H8.25C7.974 9 7.75 8.776 7.75 8.5V8C7.75 7.724 7.974 7.5 8.25 7.5H8.75C9.026 7.5 9.25 7.724 9.25 8ZM8.75 2.5H8.25C7.974 2.5 7.75 2.724 7.75 3V3.5C7.75 3.776 7.974 4 8.25 4H8.75C9.026 4 9.25 3.776 9.25 3.5V3C9.25 2.724 9.026 2.5 8.75 2.5ZM8.75 5H8.25C7.974 5 7.75 5.224 7.75 5.5V6C7.75 6.276 7.974 6.5 8.25 6.5H8.75C9.026 6.5 9.25 6.276 9.25 6V5.5C9.25 5.224 9.026 5 8.75 5ZM12 2.75V9.25C12 10.7665 10.7665 12 9.25 12H2.75C1.2335 12 0 10.7665 0 9.25V5.0785C0 4.344 0.286 3.6535 0.8055 3.134L1.8665 2.073C2.606 1.3335 3.895 1.3335 4.634 2.073L5.016 2.455C5.1635 1.077 6.3335 0 7.7505 0H9.2505C10.767 0 12 1.2335 12 2.75ZM5 5.0785C5 4.7495 4.8665 4.4275 4.634 4.1945L3.573 3.1335C3.488 3.0485 3.37 2.9995 3.25 2.9995C3.13 2.9995 3.012 3.0485 2.927 3.1335L1.866 4.1945C1.6335 4.427 1.5 4.749 1.5 5.0785V9.25C1.5 9.939 2.061 10.5 2.75 10.5H5V5.0785ZM10.5 2.75C10.5 2.061 9.939 1.5 9.25 1.5H7.75C7.061 1.5 6.5 2.061 6.5 2.75V10.5H9.25C9.939 10.5 10.5 9.939 10.5 9.25V2.75ZM3.5 5H3C2.724 5 2.5 5.224 2.5 5.5V6C2.5 6.276 2.724 6.5 3 6.5H3.5C3.776 6.5 4 6.276 4 6V5.5C4 5.224 3.776 5 3.5 5ZM3.5 7.5H3C2.724 7.5 2.5 7.724 2.5 8V8.5C2.5 8.776 2.724 9 3 9H3.5C3.776 9 4 8.776 4 8.5V8C4 7.724 3.776 7.5 3.5 7.5Z" fill="#1C2126" />
        </svg>
    )
}

export default AddressIcon