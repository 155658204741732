import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { OkayButton } from "../buttons/text-button";
import PassSuccessImage from "../../../assets/images/modals/password-successful-image.png";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { adminUsersSendPasswordAction } from "../../../redux/actions/admin-users-action";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";

const PasswordSuccessfulModal = ({ show, modalClose, password, id, action = adminUsersSendPasswordAction,
  onSuccess = (data) => { { successToast(data.message); modalClose() } },
  onError = (data) => { errorToast(data.message ?? "Failed") },
}) => {
  const params = useParams();
  const dispatch = useDispatch()
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName=""
        contentClassName="rounded-3"
      >
        <ModalBody>
          <div className="d-flex justify-content-center text-center my-4">
            <div className="col-11">
              <div className="fs_22 fs_md_18 fw_500">
                Password Successfully Updated!
              </div>
              <div className="color_9E9E9E fs_md_14">
                User can now securely access their account using new password.
              </div>
              <div className="my-4">
                <img src={PassSuccessImage} alt="image" />
              </div>
              <div onClick={() => { dispatch(action(id ?? params.id, password, onSuccess, onError)) }} className="mt-5">
                <OkayButton name="Send Password" okayClassName="fs_16 fs_md_14 px-3" />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PasswordSuccessfulModal;
