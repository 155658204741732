import React, { useEffect, useMemo, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useNavigate, useParams } from "react-router";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../components/ui/forms/input-field";
import SelectField from "../../components/ui/forms/select-field";
import { useDispatch } from "react-redux";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import {
  AddressEditAction,
  AddressTableAction,
  AddressViewAction,
} from "../../redux/actions/address-action";
import { Country } from "country-state-city";
import { State } from "country-state-city";
import isEqual from "lodash.isequal";
import CountryCode from "../../components/ui/forms/country-code";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import { numbRegex } from "../../components/helper-functions/helper/regex";
import { MarkAsDefaultButton } from "../../components/ui/buttons/text-button";
import { customerViewAction } from "../../redux/actions/customer-action";
import MarkAsDefaultModel from "../../components/ui/modals/mark-as-default-model";
import AddressLocation from "../../components/ui/address/address-location";
import AddressLeaflet from "../../components/ui/address/address-leaflet";
import NotificationComponent from "../../components/notificationComponent";
const AddressEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [custAddressData, setCustAddressData] = useState();
  const [addressDetails, setAddressDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImageUrl] = useState("");
  const location = useLocation();
  const [makeDefaultAddress, setMakeDefalutAddress] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  const getImgUrl = (img) => {
    setImageUrl(img);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(AddressViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setAddressDetails(data?.data);
    dispatch(
      customerViewAction(
        { id: data?.data?.customer_id, page: 1, size: 10, search: "" },
        onCustomerSuccess,
        onCustomerError
      )
    );
  };
  const onError = (data) => {
    setLoading(false);
  };

  const onCustomerSuccess = (data) => {
    setCustAddressData(data?.data?.fetchCustomer?.Addresses);
  };
  const onCustomerError = () => {};
  useEffect(() => {
    setImageUrl(addressDetails?.category_image);
  }, [imgUrl, addressDetails]);

  const initialValues = {
    address_id: addressDetails?.id,
    recipient_name: addressDetails?.recipient_name,
    address_name: addressDetails?.address_name,
    address_line1: addressDetails?.address_line1,
    address_line2: addressDetails?.address_line2,
    city: addressDetails?.city,
    pin_code: addressDetails?.pincode,
    state: addressDetails?.state,
    country: addressDetails?.country,
    phone_number: addressDetails?.phone_number,
    is_default: addressDetails?.is_default
      ? addressDetails?.is_default
      : makeDefaultAddress,
    lat: addressDetails?.lat,
    lng: addressDetails?.lng,
    country_code: addressDetails?.country_code,
  };
  const validationSchema = Yup.object().shape({
    address_id: Yup.string().trim().required("Category Name is required"),
    recipient_name: Yup.string().trim().required("Recipient Name is required"),
    address_name: Yup.string().trim().required("Address Name is required"),
    address_line1: Yup.string().trim().required("Address Line1 is required"),
    address_line2: Yup.string().trim(),
    city: Yup.string().trim().required("City is required"),
    pin_code: Yup.string()
      .matches(numbRegex, "Enter Valid Pin Code")
      .required("Pin Code is required"),
    state: Yup.string().trim().required("Please Select State"),
    country: Yup.string().trim().required("Please Select Country"),
    phone_number: Yup.string().trim().required("Enter phone number"),
  });
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      AddressEditAction(
        {
          address_id: addressDetails?.id,
          customer_id: addressDetails?.customer_id,
          recipient_name: values?.recipient_name,
          address_name: values?.address_name,
          address_line1: values?.address_line1,
          address_line2: values?.address_line2,
          city: values?.city,
          pin_code: values?.pin_code,
          state: values?.state,
          country: values?.country,
          phone_number: values?.phone_number,
          is_default: values?.is_default,
          lat: values?.lat,
          lng: values?.lng,
          country_code: values?.country_code,
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };
  const onSuccessEdit = (data) => {
    setLoading(false);
    successToast(data.message);
    if (currentPage) {
      navigate("/address", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onErrorEdit = (data) => {
    errorToast(data.data.message);
  };

  const defaultCountry = useMemo(
    () =>
      countriesList.find((item) => {
        return item.label == addressDetails?.country;
      }),
    [addressDetails?.country]
  );
  const defaultState = useMemo(() => {
    console.log(State, "state");
    State.getStatesOfCountry(
      countriesList.find((item) => item.label === addressDetails?.country)
        ?.value
    )
      .map((state) => ({
        label: state.name,
        value: `${state.name.toLocaleLowerCase()}`,
      }))
      .find((item) => item.value === addressDetails?.state?.toLowerCase());
  }, [addressDetails?.state]);
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    const is_default = isEqual(addressDetails?.is_default, makeDefaultAddress);
    if (areEqual && is_default) {
      return true;
    } else {
      return false;
    }
  }
  let previousValue = null;
  const [defaultAddress, setDefaultAddress] = useState([]);
  const [show, setShow] = useState(false);
  const modelCloseFn = () => setShow(false);
  const showDefaultModel = custAddressData?.some(
    (item) => item?.is_default && !item?.id.includes(params?.id)
  );

  const isCustAddressFalse =
    custAddressData
      ?.filter((item) => !item?.id.includes(params?.id))
      ?.every((item) => item?.is_default === false) ?? false;
  const getDefaultValue = (value) => {
    if (value) {
      setMakeDefalutAddress(true);
    }
  };

  const handelDefaultAddress = (e) => {
    if (e.target.checked) {
      if (showDefaultModel) {
        setShow(true);
      }
    } else {
      setMakeDefalutAddress(false);
    }
  };
  return (
    <div>
      <NotificationComponent />
      <MarkAsDefaultModel
        show={show}
        modalClose={modelCloseFn}
        getDefaultValue={getDefaultValue}
      />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, field, handleChange }) => {
            console.log(values, "addedit");
            return (
              <Form>
                <InnerLayout
                  name={addressDetails?.id}
                  loading={loading}
                  page="edit"
                  edit={true}
                  onSubmit={onSubmit}
                  rolePermission={"addresses"}
                  path={"/address"}
                  disabled={valuesCompareFn(values)}
                  // markDefault={custAddressData?.map((item) => item?.id && item?.is_default)}
                  modelShow={() => setShow(true)}
                  markDefault={!addressDetails?.is_default}
                />

                <div
                  className="ms-lg-5 mx-4 mt-5"
                  style={{ maxWidth: "40rem" }}
                >
                  <div className="fs_14 mb-4">
                    <label className="color_707070">Customer ID</label>
                    <div>{addressDetails?.customer_id}</div>
                  </div>
                  <InputField
                    placeholder="Enter Recipient Name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Recipient Name"
                    name="recipient_name"
                    type="text"
                  />
                  <InputField
                    placeholder="Enter Address Name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Address Name"
                    name="address_name"
                    type="text"
                  />
                  {localStorage?.getItem("live_map_enable") === "true" && (
                    // <AddressLocation
                    //   setFieldValue={setFieldValue}
                    //   lat={values?.lat}
                    //   lng={values?.lng}
                    // />
                    <AddressLeaflet
                      setFieldValue={setFieldValue}
                      lat={values?.lat}
                      lng={values?.lng}
                    />
                  )}
                  <InputField
                    placeholder="Enter Address Line1"
                    containerClass="position-relative mt-3"
                    errMsgContainerClass="ps-2"
                    label="Address"
                    name="address_line1"
                    type="text"
                  />
                  <InputField
                    placeholder="Enter Address Line2"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label=""
                    name="address_line2"
                    type="text"
                  />
                  <InputField
                    placeholder="Enter City"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="City"
                    name="city"
                    type="text"
                  />
                  <InputField
                    placeholder="Enter PinCode"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Pin Code"
                    name="pin_code"
                    type="text"
                  />
                  <SelectField
                    defaultValue={defaultCountry}
                    placeholder={"Select Country"}
                    options={countriesList}
                    label={"Country"}
                    name="country"
                    handleSelected={(selectedOption) => {
                      if (selectedOption.value !== previousValue) {
                        setFieldValue("state", null);
                      }
                      previousValue = selectedOption.value;
                    }}
                    errMsgContainerClass="ps-2"
                  />
                  <SelectField
                    isDisabled={!values.country}
                    defaultValue={values.country === "AF" ? null : defaultState}
                    placeholder={"Select State"}
                    options={State.getStatesOfCountry(
                      countriesList.find(
                        (item) =>
                          item.label === values.country ||
                          item.value === values.country
                      )?.value
                    ).map((state) => ({
                      label: state.name,
                      value: `${state.name.toLocaleLowerCase()}`,
                    }))}
                    label={"State"}
                    name="state"
                    errMsgContainerClass="ps-2"
                  />
                  <label className="color_030304 fw_500 fs_14">
                    Phone Number
                  </label>
                  <div className="d-flex">
                    <span className="">
                      <CountryCode />
                    </span>
                    <span className="ms-2 w-100">
                      <InputField
                        placeholder="Enter your phone number"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label=""
                        name="phone_number"
                        type="text"
                      />
                    </span>
                  </div>

                  {/* {addressDetails?.is_default || isCustAddressFalse ? (
                    <div className="d-flex align-items-center gap-2 mb-4">
                      <input
                        className="form-check-input mb-1"
                        style={{ width: "15px", height: "15px" }}
                        name="is_default"
                        type="checkbox"
                        {...field}
                        checked={values?.is_default}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <label className="fw_500 fs_16">Default Address</label>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center gap-2 mb-4">
                      <input
                        className="form-check-input mb-1"
                        style={{ width: "15px", height: "15px" }}
                        name="is_default"
                        type="checkbox"
                        {...field}
                        checked={makeDefaultAddress}
                        onChange={(e) => {
                          handelDefaultAddress(e, setFieldValue);
                        }}
                      />
                      <label className="fw_500 fs_16">Default Address</label>
                    </div>
                  )} */}
                  <div className="d-flex align-items-center gap-2 mb-4">
                    <input
                      className="form-check-input mb-1"
                      style={{ width: "15px", height: "15px" }}
                      name="is_default"
                      type="checkbox"
                      {...field}
                      checked={
                        addressDetails?.is_default || isCustAddressFalse
                          ? values?.is_default
                          : makeDefaultAddress
                      }
                      onChange={(e) => {
                        if (addressDetails?.is_default || isCustAddressFalse) {
                          return handleChange(e);
                        } else {
                          return handelDefaultAddress(e, setFieldValue);
                        }
                      }}
                    />
                    <label className="fw_500 fs_16">Default Address</label>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default AddressEdit;
const countriesList = Country.getAllCountries().map((country) => ({
  label: country.name,
  value: `${country.isoCode}`,
}));
