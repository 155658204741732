import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  customerBlockAction,
  customerResetPswAction,
  customerSendPasswordAction,
  customerViewAction,
} from "../../redux/actions/customer-action";
import { customerDeleteAction } from "../../redux/actions/customer-action";
import DynamicTable from "../../components/ui/tables/table";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import ResetPassModal from "../../components/ui/modals/reset-pass-modal";
import PasswordSuccessfulModal from "../../components/ui/modals/password-successful-modal";
import DeleteModal from "../../components/ui/modals/delete-modal";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import { formatDateWithDay } from "../../components/helper-functions/helper/date";
import NotificationComponent from "../../components/notificationComponent";

const CustomerView = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [orderinputValue, setOrderInputValue] = useState("");
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [reload, setReload] = useState(false);
  const [customerDetails, setCustomerDetails] = useState();
  const [customerAddressData, setCustomerAddressData] = useState();
  const [isCustomerBlock, isSetCustomerBlock] = useState();
  const [imgUrl, setUmgUrl] = useState();
  const AddressCharacterHider = (str) => {
    return str?.substring(0, 25) + "...";
  };
  const [showResetPass, setShowResetPass] = useState(false);
  const [showPassSuccess, setShowPassSuccess] = useState(false);
  const [blockUnBlockShow, setBlockUnBlock] = useState(false);
  const [isImgLoad, setIsImgLoad] = useState(true);
  const customerData = [
    {
      label: "Profile Picture",
      value: (
        <>
          {isImgLoad && (
            <div
              className="d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
              style={{ width: "250px", height: "250px" }}
            >
              <LoadingSpinner size="lg" />
            </div>
          )}
          <div
            className={`${
              !imageRegex.test(customerDetails?.profile_picture) &&
              "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
            }`}
            style={{ width: "250px", height: !isImgLoad && `250px` }}
          >
            <img
              className="rounded-3 mt-1"
              src={
                imageRegex.test(customerDetails?.profile_picture)
                  ? customerDetails?.profile_picture
                  : UplaodImgLogo
              }
              width={
                imageRegex.test(customerDetails?.profile_picture)
                  ? "250px"
                  : "100"
              }
              height={
                imageRegex.test(customerDetails?.profile_picture)
                  ? "250px"
                  : "100"
              }
              alt="image"
              onLoad={() => setIsImgLoad(false)}
              style={{ display: isImgLoad ? "none" : "block" }}
            />
          </div>
        </>
      ),
    },
    {
      label: "Customer Name",
      value: customerDetails?.name,
    },
    {
      label: "Phone Number",
      value:
        "+" +
        customerDetails?.country_code +
        " " +
        customerDetails?.phone_number,
    },
    {
      label: "Email Address",
      value: customerDetails?.email_address,
    },
  ];

  console.log(orders, "regtertretertert");
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  const [ordersortConfig, setOrdrerSortConfig] = useState({
    sort_by: "",
    sort_order: "",
  });
  useEffect(() => {
    // setLoading(true);
    dispatch(
      customerViewAction(
        {
          id: params.id,
          page: currentPage,
          size: 10,
          search: inputValue,
          searchOrder: orderinputValue,
          sort: sortConfig,
          orderSort: ordersortConfig,
        },
        onSuccess,
        onError
      )
    );
  }, [
    inputValue,
    reload,
    currentPage,
    sortConfig,
    orderinputValue,
    ordersortConfig,
  ]);
  const onSuccess = (data) => {
    console.log(data, "regtertretertert");
    setLoading(false);
    setError(false);
    setPagination(data?.data?.pagination);
    setCustomerDetails(data?.data?.fetchCustomer);
    setCustomerAddressData(data?.data?.fetchCustomer?.Addresses);
    setOrders(data?.data?.fetchCustomer?.Orders);
    isSetCustomerBlock(data?.data?.fetchCustomer?.is_blocked);
    setUmgUrl(data?.data?.profile_picture);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const tableData = customerAddressData?.map((item) => ({
    id: item?.id,
    address_name: item?.address_name,
    address_line1: AddressCharacterHider(item?.address_line1),
    country: item?.country,
    state: item?.state,
    is_default: item?.is_default === true ? "Yes" : "No",
    Action: ["/address/view/"],
  }));

  const ordersData = orders?.map((item) => ({
    id: item?.id,
    code: item?.code,
    no_of_items: item?.product?.length ?? 0,
    order_date: formatDateWithDay(item?.createdAt),
    orders_status: Array.isArray(item?.orders_status)
      ? item?.orders_status
      : [item?.orders_status],
    payment_status: [item?.payment_admin_status] ?? ["unpaid"],
    order_value: item?.total_value_with_discount_with_delivery
      ? `${localStorage?.getItem("currency") + " " ?? "₹ "} ${
          item?.total_value_with_discount_with_delivery
        }`
      : "--",
    Action: ["/orders/status/", "/orders/edit/"],
  }));

  function resetPassord(values) {
    dispatch(
      customerResetPswAction(
        { customer_id: params.id, password: values?.password },
        onResetSuccess,
        onResetError
      )
    );
  }
  const onResetSuccess = (data) => {
    setShowResetPass(false);
    setShowPassSuccess(true);
  };
  const onResetError = (data) => {
    errorToast(data?.data?.message);
  };

  function blockUnblockFn() {
    dispatch(
      customerBlockAction(params.id, blockUnblockOnSuccess, blockUnblockOnError)
    );
  }
  const blockUnblockOnSuccess = (data) => {
    setBlockUnBlock(false);
    successToast(data.message);
    setReload(!reload);
    setLoading(false);
  };
  const blockUnblockOnError = (data) => {
    setReload(false);
    errorToast(data.data.message);
    setBlockUnBlock(false);
    setLoading(false);
  };
  return (
    <>
      <NotificationComponent />
      <DeleteModal
        show={blockUnBlockShow}
        modalClose={() => setBlockUnBlock(false)}
        deleteData={params.id}
        deleteFn={() => blockUnblockFn(params.id)}
        type={isCustomerBlock === true ? "Unblock" : "block"}
        text={`Are you sure, you want to ${
          isCustomerBlock === true ? "unblock" : "block"
        } this customer permanently?`}
      />
      <ResetPassModal
        firstPlaceholder="Create new password"
        resetFn={resetPassord}
        show={showResetPass}
        modalClose={() => {
          setShowResetPass(false);
        }}
      />
      <PasswordSuccessfulModal
        show={showPassSuccess}
        modalClose={() => setShowPassSuccess(false)}
        action={customerSendPasswordAction}
        id={params?.id}
      />

      {loading ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <LoadingSpinnerTable />
        </div>
      ) : error ? (
        <div>Error</div>
      ) : (
        <>
          <InnerLayout
            Unblock={isCustomerBlock === true ? true : false}
            block={isCustomerBlock === false ? true : false}
            green_bg={false}
            reset={true}
            type="customer"
            name={`${customerDetails?.id ?? "Customer View"}`}
            id={customerDetails?.id}
            isCustomerBlock={isCustomerBlock}
            deleteAction={customerDeleteAction}
            path={`/customers`}
            editButtonPath={`/customers/edit/${customerDetails?.id}`}
            reload={reload}
            setReload={setReload}
            rolePermission={"customers"}
            resetPassFn={() => {
              setShowResetPass(true);
            }}
            blockUnblockShowFn={() => setBlockUnBlock(true)}
          />
          <div className="ms-md-5 mx-4 col-md-6 col-xl-5 mt-5">
            {customerData.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <label className="color_707070 fs_14">{item.label}</label>
                  <div className="color_030304 fs_16 mb-1">{item.value}</div>
                </div>
              );
            })}
          </div>
          <div className="ms-md-5 ms-lg-4 mx-4 col-lg-11 px-lg-0 ps-lg-4 mb-5">
            <DynamicTable
              tabletitle="Addresses"
              placeholder="Search address here"
              tableHeading={tableAddressHeader}
              tablebody={tableData}
              setInputValue={setInputValue}
              inputValue={inputValue}
              id_name={"address_id"}
              createButtonName="ADD ADDRESS"
              createpath="/address/create"
              pagination={pagination}
              setCurrentPage={setCurrentPage}
              loading={loading}
              setLoading={setLoading}
              error={error}
              setReload={setReload}
              reload={reload}
              backBtnDisplay={false}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              isMinHeight={false}
              fromPage="view"
            />
          </div>
          <div className="ms-md-5 ms-lg-4 mx-4 col-lg-11 px-lg-0 ps-lg-4 mb-5">
            <DynamicTable
              placeholder={"Search order here by id, name "}
              modalText={"order"}
              tabletitle={"Orders"}
              tableHeading={tableAttributeHeader}
              tablebody={ordersData}
              setInputValue={setOrderInputValue}
              inputValue={orderinputValue}
              createButtonName="CREATE ORDER"
              createpath="/orders/create"
              pagination={pagination}
              setCurrentPage={setCurrentPage}
              loading={loading}
              setLoading={setLoading}
              error={error}
              id_name={"order_id"}
              setReload={setReload}
              reload={reload}
              rolePermission={"orders"}
              sortConfig={ordersortConfig}
              setSortConfig={setOrdrerSortConfig}
              filtration={false}
              createButton={false}
              backBtnDisplay={false}
              fromPage="view"
            />
          </div>
        </>
      )}
    </>
  );
};

export default CustomerView;
const tableAddressHeader = [
  { label: "ID", value: "id" },
  { label: "Address Name", value: "address_name" },
  { label: "Address", value: "address_line1" },
  { label: "Country", value: "country" },
  { label: "State", value: "state" },
  { label: "Is Default", value: "is_default" },
  { label: "Actions", value: "Actions" },
];
const tableAttributeHeader = [
  { label: "ID", value: "code" },
  { label: "No. of Items", value: "getItems" },
  { label: "Order Date", value: "order_date" },
  { label: "Order Status", value: "orders_status" },
  { label: "Payment Status", value: "payment_admin_status" },
  { label: "Order Value", value: "total_value_with_discount_with_delivery" },
  { label: "Actions", value: "Actions" },
];
