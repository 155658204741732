import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../../modals/modal.css";
import { BackButton, SaveButton } from "../../buttons/text-button";
import { useDispatch } from "react-redux";
import SelectField from "../../forms/select-field";
import InputField from "../../forms/input-field";
import { ordersAssignAction, ordersDeliveryPartnersAction, ordersEditAction, ordersReturnEditAction } from "../../../../redux/actions/orders-action";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";

export default function AddDeliveryDetails({ show, modalClose, selectedOption, orderDetails, id, reload, setReload, deliveryPartners, type, paymentOption }) {

  const partnerDetails = type === "pickup" ? orderDetails?.return_delivery_partner : orderDetails?.driver ?? orderDetails?.delivery_partner_details

  const dispatch = useDispatch();
  const date = new Date
  const initialValues = {
    delivery_partner: partnerDetails?.name ?? "",
    awb_number: partnerDetails?.awb_number ?? "",
  };
  const validationSchema = Yup.object().shape({
    delivery_partner: Yup.string().required("Required"),
    awb_number: Yup.number().test("is-required", "Awb number is required!", function (value) {
      return localStorage.getItem("delivery_type") != "third_party" ? true : value ? true : false
    })
  });

  const onSubmit = (values) => {
    let actionType = ordersEditAction;
    if (type === "pickup") {
      actionType = ordersReturnEditAction;
    }
    if (localStorage.getItem("delivery_type") === "self") {
      dispatch(
        ordersAssignAction(
          {
            "order_id": id,
            driver_id: values?.delivery_partner
          },
          onEditSuccess,
          onEditError
        )
      )
    }
    else {
      dispatch(
        actionType(
          {
            "order_id": id,
            "orders_status": selectedOption?.value,
            [type === "pickup" ? "return_delivery_partner" : "delivery_partner"]: {
              "id": "",
              "name": values?.delivery_partner,
              "awb_number": values?.awb_number,
              "dispatched_on": date,
            },
            // "payment_admin_status": paymentOption?.value
          },
          onEditSuccess,
          onEditError
        )
      )
    }
  };
  const onEditSuccess = (data) => {
    modalClose()
    console.log(data);
    successToast("Updated Success Fully")
    setReload(!reload)
  }
  const onEditError = (data) => {
    errorToast(data?.data?.message)
  }
  console.log(partnerDetails, "partnerDetailspartnerDetails");
  console.log(deliveryPartners, "dfgsdfgdsfg");
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="add_delivery_details_model"
        contentClassName="rounded-3 mx-2 mx-xl-0"
      // backdrop="static"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            return (
              <Form>
                <ModalHeader>
                  <div>
                    <img onClick={modalClose} src={BackIcon} />
                    <span className="ms-2 fs_16 fw_500">
                      Add Delivery Details
                    </span>
                  </div>
                  <div className="d-flex gap-3">
                    <BackButton
                      // disabled={valuesCompareFn(values)}
                      type="reset"
                      name="UNDO"
                      backClassName="fs_14"
                    />

                    <SaveButton
                      // disabled={valuesCompareFn(values)}
                      type="submit"
                      backClassName="fs_14"
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {
                      localStorage.getItem("delivery_type") != "third_party" &&
                      <SelectField
                        // defaultValue={deliveryPartners.find(
                        //   (item) => item.value === values.delivery_partner
                        // )}
                        labelClass="text-black fw_500 fs_14"
                        placeholder={"Select Delivery partner"}
                        label={"Delivery Partner"}
                        id={"delivery_partner"}
                        name="delivery_partner"
                        errMsgContainerClass="ps-2"
                        isFormikControlled={true}
                        charLimit="50"
                        options={deliveryPartners}
                      />
                    }
                    {
                      localStorage.getItem("delivery_type") === "third_party" &&
                      <>
                        <InputField
                          id={"delivery_partner"}
                          label={"Delivery Partner Name"}
                          placeholder={"Enter Delivery Partner Name"}
                          name={"delivery_partner"}
                        />
                        <InputField
                          id={"awb_number"}
                          label={"AWB Number"}
                          placeholder={"Enter Recipient Name"}
                          name={"awb_number"}
                        />
                      </>
                    }

                  </div>
                </ModalBody>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}



const deliveryPartners = [
  { label: "Ramu", value: "Ramu" },
  { label: "jay", value: "jay" },
  { label: "Ajay", value: "Ajay" },
  { label: "Kumar", value: "kumar" },
]