import React from 'react'

const EditIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.47 0.53003C11.13 0.19063 10.6693 0 10.189 0C9.70862 0 9.2479 0.19063 8.90798 0.53003L0 9.43801V12H2.56199L11.47 3.09202C11.8092 2.75204 11.9998 2.29134 11.9998 1.81103C11.9998 1.33071 11.8092 0.870017 11.47 0.53003ZM2.14999 11H0.999997V9.85001L7.65498 3.20002L8.80498 4.35002L2.14999 11ZM10.763 2.38503L9.50947 3.63852L8.36198 2.48853L9.61497 1.23703C9.76747 1.08453 9.97431 0.998856 10.19 0.998856C10.4056 0.998856 10.6125 1.08453 10.765 1.23703C10.9175 1.38953 11.0031 1.59636 11.0031 1.81203C11.0031 2.02769 10.9175 2.23453 10.765 2.38703L10.763 2.38503Z" fill="#2820DA" />
        </svg>

    )
}

export default EditIcon