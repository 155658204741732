import React, { useEffect, useState } from "react";
import "./meta-table.css";
import DeleteModal from "../modals/delete-modal";
export default function ProductAddonsTable({
  addonsData, editFn, deleteFn, page
}) {

  return (
    <>
      <DeleteModal
        text={`Are you sure, you want to delete this Meta Data permanently?`}
        // show={show}
        // modalClose={deleteCloseFn}
        // deleteData={deleteData}
        // deleteFn={() => deleteDataFn(deleteData)}
        type={"delete"}
      />

      <div className={`table_main_container rounded-3 border-0`}>
        <table className={"table_container h-100"}>
          <thead className={`table_heading_container table_heading_border`}>
            <tr className="table_heading_row">
              {/* {editable && ( */}
              <th
                className={` position-sticky end-0 text-start  
                  text-nowrap products_table_heading_data`}
              >
                <input
                  e={{ transform: "scale(1.1)" }}
                  type="checkbox"
                  id="myCheckbox"
                  className="custom-checkbox form-check-input"
                />
              </th>
              {/* )} */}
              <th
                className={`text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
              >
                <div className={``}>
                  Index
                </div>
              </th>
              <th
                className={`text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
              >
                <div className={`discounts_table_Action_heading`}>
                  Add-Ons Group Name
                </div>
              </th>

              {/* {editable && ( */}
              <th
                className={`position-sticky end-0 text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
              >
                <div className={``}>
                  Actions
                </div>
              </th>
              {/* )} */}
            </tr>
          </thead>
          <tbody className="table_body_container h-100">
            <>
              {addonsData?.map((item, index) => (
                <tr
                  key={index}
                  className={`table_body_row fs_13 bg_color_ffffff`}
                >
                  <td className="text-nowrap meta_table_body_data">
                    <input
                      // id={item?.id}
                      style={{ transform: "scale(1.2)" }}
                      type="checkbox"
                      className="custom-checkbox form-check-input"
                    />
                  </td>
                  <td className="text-nowrap color_030304 meta_table_body_data fw_500">
                    <div class=" d-flex flex-column  color_030304">
                      {index + 1}
                    </div>
                  </td>
                  <td className="text-nowrap color_030304 meta_table_body_data fw_500">
                    <div class="expanding-input d-flex flex-column  color_030304">
                      {item?.name}
                      {/* <span className="color_F23D3D">Required!</span>d */}
                    </div>
                  </td>
                  {
                    page != "view" ? <td
                      className={`meta_table_body_data text-nowrap position-sticky  end-0 color_030304      h-100 bg_color_ffffff`}
                    >
                      <div className={`align-items-center d-flex`}>
                        <button
                          type="button"
                          onClick={() => editFn(index)}
                          className={`primary_color border-0 p-0 pe-md-4 pe-3   fs_15 fw_500 font_poppins bg_color_ffffff`}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => deleteFn(index)}
                          type="button"
                          className={`color_F24444 border-0 bg_color_ffffff   p-0 pe-md-4 pe-0 fs_15 fw_500 font_poppins `}
                        >
                          Delete
                        </button>
                      </div>
                    </td> :
                      <td
                        className={`meta_table_body_data text-nowrap position-sticky  end-0 color_030304      h-100 bg_color_ffffff`}
                      >
                        <button
                          type="button"
                          onClick={() => editFn(index)}
                          className={`color_3AD64C border-0 p-0 pe-md-4 pe-3   fs_15 fw_500 font_poppins bg_color_ffffff`}
                        >
                          view
                        </button>
                      </td>
                  }
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
}

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#E5ECFFB2",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins,sans-serif",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#2820DA",
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    padding: "0px",
  }),
};
