import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import InputField from "../../components/ui/forms/input-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import { customerListDropdownAction } from "../../redux/actions/address-action";
import SelectField from "../../components/ui/forms/select-field";
import DiscountedProductsList from "../../components/ui/discounts/discounted-products-list";
import DiscountsAddProductsModal from "../../components/ui/modals/discounts-add-products-modal";
import EditProduct from "../../components/ui/orders/models/edit-product";
import {
  addressFetchAction,
  billingGenerateAction,
  ordersServiceAction,
} from "../../redux/actions/orders-action";
import BillingDetails from "../../components/ui/orders/models/billing-details";
import RadioButton from "../../components/ui/forms/radio-button";
import { del_type } from "../../components/helper-functions/helper/data";
import NotificationComponent from "../../components/notificationComponent";

const OrdersCreate = () => {
  const [weight, setWeight] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const deliveryOptions = localStorage.getItem("delivery_options");
  const useUpdatedInitialValues = (addedProductsList, productVariations) => {
    const [initialValues, setInitialValues] = useState({
      customer_id: "",
      address_id: "",
      delivery_type:
        JSON.parse(deliveryOptions)?.length < 2
          ? JSON.parse(deliveryOptions)?.[0]
          : "pickup",
      products: [],
    });
    const [couponCode, setCouponCode] = useState({
      couponCode: billingDetailsData?.coupon_code ?? "",
      applied: false,
    });
    useEffect(() => {
      // Function to generate the updated initial values
      const generateUpdatedInitialValues = () => {
        return {
          customer_id: "",
          address_id: "",
          products: addedProductsList?.map((item, index) => {
            return {
              id: item.id,
              variation: item?.variation?.map((variant) => {
                const productVariationKey = `${item.id}-${variant.id}-${index}`;
                const productVariation = productVariations[
                  productVariationKey
                ] || {
                  value: "",
                  variation_name: "",
                  error: "",
                };
                return {
                  id: variant?.id,
                  value: productVariation.value,
                  variation_name: productVariation.variation_name,
                  error: productVariation.error,
                };
              }),
            };
          }),
        };
      };

      // Update the initialValues state using the function
      setInitialValues(generateUpdatedInitialValues());
    }, [addedProductsList, productVariations]);

    return initialValues;
  };
  const [couponBillingData, setCouponBillingData] = useState([]);
  const router = useLocation();
  const { pathname } = router;
  const dispatch = useDispatch();
  const [customerList, setCustomerList] = useState([]);
  const [getCustomerId, setGetCustomerId] = useState(null);
  const [custAddressId, setCustAddressid] = useState([]);
  const [addProducts, setAddProducts] = useState(false);
  const addProductModalShow = () => setAddProducts(true);
  const addProductModalClose = () => setAddProducts(false);
  const [addedProductsList, setAddedProductsList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [emptyProductsError, setEmptyProductsError] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [delivery, setDelivery] = useState("");
  const [couponCode, setCouponCode] = useState({
    couponCode: "",
    applied: false,
  });
  const [billingDetailsData, setBillingDetailsData] = useState([]);
  const [addedProductsData, setAddedProductsData] = useState([]);
  const [variantionError, setVariantionError] = useState([]);
  const [productVariations, setProductVariations] = useState({
    customer_id: "",
    address_id: "",
    products: [],
  });
  const initialValues = useUpdatedInitialValues(
    addedProductsList,
    productVariations
  );
  function updateVariationId(array) {
    array.forEach((obj) => {
      const { addVariant, variation_products } = obj;
      const combination = addVariant?.map((variant) => variant.value);

      const matchedProduct = variation_products?.find(
        (product) =>
          JSON.stringify(product.variants) === JSON.stringify(combination) ||
          JSON.stringify(product.variants?.sort()) ===
          JSON.stringify(combination?.sort())
      );

      if (matchedProduct) {
        obj.newVariant = {
          id: matchedProduct.id,
          variants: matchedProduct?.variants,
          bar_code: matchedProduct?.bar_code,
          discounted_price: matchedProduct?.discounted_price,
          price: matchedProduct?.price,
          variants_pictures: matchedProduct?.variants_pictures,
        };
      }
    });

    return array;
  }
  const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required("Customer Id is required"),
    address_id: Yup.string().test(
      "is-required",
      'Address Id is required when delivery type is "delivery"',
      function (value) {
        if (this.parent.delivery_type === "delivery") {
          console.log(
            value &&
            errorMsg != "No service is available in this region." &&
            errorMsg != "Service unavailable for this weight range.",
            "zxcxczxczxczxcxz"
          );
          return value && errorMsg != "No service is available in this region."
            ? true
            : this.createError({
              message: `${errorMsg ? errorMsg : "Address Id is required"}`,
            });
        }
        return true;
      }
    ),
  });
  const [variantErr, setVariantErr] = useState(true);
  useEffect(() => {
    if (!addedProductsList?.some((item) => item.newError)) {
      setVariantErr("");
    }
  }, [addedProductsList]);
  const onSubmit = (values) => {
    setEmptyProductsError(addedProductsList?.length >= 1 ? false : true);
    addedProductsList.forEach((obj, index) => {
      if (!obj.newVariant && obj.variation_products?.length >= 1) {
        addedProductsList[index].newError = true; // Update the newError property in the addedProductsList array
      } else {
        addedProductsList[index].newError = false;
      }
    });
    const updatedProductsList = addedProductsList.map((obj) => ({
      ...obj,
      newError: !obj.newVariant,
    }));
    setAddedProductsList(updatedProductsList);
    const hasTrueNewError = addedProductsList?.some((item) => item.newError);
    if (hasTrueNewError === false && addedProductsList?.length !== 0) {
      dispatch(
        billingGenerateAction(
          {
            customer_id: getCustomerId,
            address_id: addressId === "" ? null : addressId,
            delivery_type:
              JSON.parse(deliveryOptions)?.length === 2
                ? delivery
                : JSON.parse(deliveryOptions)?.[0],
            products: addedProductsList?.map((item) => {
              return {
                id: item?.id,
                product_name: item?.product_name,
                quantity: item?.qty ?? 1,
                variation: item?.newVariant,
                price: item?.price,
                discounted_price: item?.discount_price,
                thumbnail_image: item?.thumbnail_image,
                sku: item?.sku,
                barcode: item?.barcode,
                product_total_price:
                  item?.total_price ??
                  item?.newVariant?.discount_price ??
                  item?.newVariant?.price ??
                  item?.discount_price ??
                  item?.price,
              };
            }),
            coupon_code: "",
          },
          onSuccess,
          onError
        )
      );
    } else {
      setVariantErr("Select variation in above Products");
    }
    setAddedProductsData({
      customer_id: getCustomerId,
      address_id: addressId === "" ? null : addressId,
      delivery_type:
        JSON.parse(deliveryOptions)?.length > 1
          ? delivery
          : JSON.parse(deliveryOptions)?.[0],
      products: addedProductsList?.map((item) => {
        return {
          id: item?.id,
          product_name: item?.product_name,
          quantity: item?.qty ?? 1,
          variation: item?.newVariant,
          price: item?.price,
          discounted_price: item?.discount_price,
          thumbnail_image: item?.thumbnail_image,
          sku: item?.sku,
          barcode: item?.barcode,
          product_total_price:
            item?.total_price ??
            item?.newVariant?.discount_price ??
            item?.newVariant?.price ??
            item?.discount_price ??
            item?.price,
        };
      }),
      cart: addedProductsList?.map((item) => {
        return {
          id: item?.id,
          product_name: item?.product_name,
          quantity: item?.qty ?? 1,
          variation: item?.newVariant,
          price: item?.price,
          discounted_price: item?.discount_price,
          thumbnail_image: item?.thumbnail_image,
          sku: item?.sku,
          barcode: item?.barcode,
          total_price:
            item?.total_price ??
            item?.newVariant?.discount_price ??
            item?.newVariant?.price ??
            item?.discount_price ??
            item?.price,
        };
      }),
    });
  };
  const onSuccess = (data) => {
    setWeight(data?.data?.tottal_weight);
    setAddressId(addressId);
    successToast(data.message);
    setBillingDetailsData(data?.data);
    setBillDetailsShow(true);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  // Get all customer id
  useEffect(() => {
    dispatch(customerListDropdownAction(OnFetchSuccess, onFetchError));
  }, []);
  const OnFetchSuccess = (data) => {
    setCustomerList(
      data.data.map((item) => {
        return { label: item.id + " - " + item.name, value: item.id };
      })
    );
  };
  const onFetchError = () => { };

  useEffect(() => {
    if (getCustomerId) {
      dispatch(
        addressFetchAction(
          { id: getCustomerId },
          onCustomerSuccess,
          onCustomerError
        )
      );
    }
  }, [getCustomerId]);

  const onCustomerSuccess = (data) => {
    setAllAddress(data?.data);
    setCustAddressid(
      data?.data?.map((item) => {
        return {
          label:
            item?.id +
            " - " +
            item?.address_name +
            " " +
            `${item?.is_default ? "(default)" : ""}`,
          value: item?.id,
        };
      })
    );
  };
  const onCustomerError = () => { };
  const [editProductShow, setEditProductShow] = useState(false);
  const [billDetailsShow, setBillDetailsShow] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("delivery_type") === "adair" && addressId) {
      dispatch(
        ordersServiceAction(
          {
            from_latitude: parseFloat(localStorage.getItem("store_lat")),
            from_longitude: parseFloat(localStorage.getItem("store_lng")),
            to_latitude: allAddress?.find((item) => item?.id === addressId)
              ?.lat,
            to_longitude: allAddress?.find((item) => item?.id === addressId)
              ?.lng,
            weight: weight ?? "1",
            // "weight": "200"
          },
          onServiceSuccess,
          onServiceError
        )
      );
    }
  }, [addressId, weight]);
  const onServiceSuccess = (data) => {
    setErrorMsg(data?.data?.message);
  };
  const onServiceError = (data) => { };
  // No service is available in this region.
  // Service unavailable for this weight range.

  return (
    <div className="">
      <NotificationComponent />
      <BillingDetails
        deliveryType={delivery}
        errorMsg={errorMsg}
        setBillingDetailsData={setBillingDetailsData}
        couponBillingData={couponBillingData}
        setCouponBillingData={setCouponBillingData}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        show={billDetailsShow}
        modalClose={() => {
          setBillDetailsShow(false);
          setCouponBillingData([]);
          setCouponCode({
            couponCode: "",
            applied: false,
          });
        }}
        billingDetailsData={billingDetailsData}
        addedProductsData={addedProductsData}
      />
      <EditProduct
        show={editProductShow}
        modalClose={() => setEditProductShow(false)}
        tableDiscountHeader={tableProductListHeader}
        addProductModalShow={addProductModalShow}
        productList={addedProductsList}
        addedProductsList={addedProductsList}
        setAddedProductsList={setAddedProductsList}
        selectedProductList={selectedProductList}
        setSelectedProductList={setSelectedProductList}
        action={true}
        page={"create"}
        emptyProductsError={emptyProductsError}
      />
      <DiscountsAddProductsModal
        show={addProducts}
        modalClose={addProductModalClose}
        addedProductsList={addedProductsList}
        setAddedProductsList={setAddedProductsList}
        selectedProductList={selectedProductList}
        setSelectedProductList={setSelectedProductList}
        setEmptyProductsError={setEmptyProductsError}
        orderCreate={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, setFieldValue }) => {
          console.log(errors, "kjfasks");
          setGetCustomerId(values?.customer_id);
          setAddressId(values?.address_id);
          setDelivery(values?.delivery_type);
          return (
            <Form>
              <InnerLayout
                resetFn={() => {
                  setAddedProductsList([]);
                  setAddedProductsData([]);
                }}
                path={"/orders"}
                name="Orders"
                page="create"
                edit={true}
                onSubmit={onSubmit}
                rolePermission={"orders"}
              />
              <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
                <SelectField
                  charLimit={100}
                  labelClass="text-black fw_500 fs_14"
                  placeholder={"Select Customer"}
                  label={"Customer Id"}
                  id={"customer_id"}
                  name="customer_id"
                  errMsgContainerClass="ps-2"
                  isFormikControlled={true}
                  options={customerList}
                />
                {deliveryOptions.includes("pickup") &&
                  deliveryOptions.includes("delivery") && (
                    <RadioButton
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("delivery_type", value);
                      }}
                      labelClass="text-black d-flex fs_14 mb-1 fw_500"
                      name="delivery_type"
                      options={del_type}
                      label="Delivery Type"
                    />
                  )}
                {values?.delivery_type != "pickup" && (
                  <SelectField
                    containerClass="mt-4"
                    charLimit={100}
                    labelClass="text-black fw_500 fs_14"
                    placeholder={"Select Customer"}
                    label={"Address Id"}
                    id={"address_id"}
                    name="address_id"
                    errMsgContainerClass="ps-2"
                    isFormikControlled={true}
                    options={custAddressId}
                  />
                )}
              </div>
              <div className="product-list-section ms-4 ms-sm-5 pb-4">
                <div className="pe-3" style={{ maxWidth: "80rem" }}>
                  <DiscountedProductsList
                    pageType="order"
                    tableDiscountHeader={tableProductListHeader}
                    addProductModalShow={addProductModalShow}
                    productList={addedProductsList}
                    addedProductsList={addedProductsList}
                    setAddedProductsList={setAddedProductsList}
                    selectedProductList={selectedProductList}
                    setSelectedProductList={setSelectedProductList}
                    action={true}
                    page={"create"}
                    emptyProductsError={emptyProductsError}
                    orderCreate={true}
                    setProductVariations={setProductVariations}
                    productVariations={productVariations}
                  />
                </div>
                <div className="d-flex justify-content-end fs_14 color_F24444 fw_500 mt-3 mx-3">
                  {variantErr}
                </div>
              </div>
              {variantionError ? (
                <ul>
                  {variantionError.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OrdersCreate;
const tableProductListHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "Product ID", value: "product_id" },
  { label: "Product Name", value: "product_name" },
  { label: "Variation", value: "variation" },
  { label: "QTY", value: "qty" },
  { label: "Price", value: "price" },
  { label: "Discounted Price", value: "discounted_price" },
  { label: "Total Price", value: "total_price" },
  { label: "Actions", value: "action" },
];
