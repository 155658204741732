import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  discountDeleteAction,
  discountMultiDeleteAction,
  discountTableAction,
} from "../../redux/actions/discount-action";
import DynamicTable from "../../components/ui/tables/table";
import DiscountTableFilter from "../../components/ui/tables/table-filter/discount-table-filter";
import { useLocation } from "react-router";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import NotificationComponent from "../../components/notificationComponent";

export default function DiscountsTable() {
  const location = useLocation();
  const [discountsData, setDiscountsData] = useState({
    admins: [],
    pagination: {},
  });
  const [inputValue, setInputValue] = useState("");
  //   const [checkedId, setCheckedId] = useState([]);
  const dispatch = useDispatch();

  const [filterShow, setFilterShow] = useState(false);
  const [filterSubmission, setFilterSubmission] = useState("submit");
  const [filterValues, setFilterValues] = useState({
    discount_type: "",
    value_of_discount_from: "",
    value_of_discount_to: "",
    max_discount_amount_from: "",
    max_discount_amoun_to: "",
    min_order_value_from: "",
    min_order_value_to: "",
    on_all_products: true,
  });
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  console.log(filterValues, "status");
  useEffect(() => {
    setLoading(true);
    dispatch(
      discountTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig,
          filter: {
            filteredValue: filterValues,
          },
        },
        onSuccess,
        onError
      )
    );
  }, [reload, inputValue, currentPage, filterValues, sortConfig]);

  const onSuccess = (data) => {
    setError(false);
    setDiscountsData(data.data);
    setLoading(false);
    filterSubmission === "submit" && setFilterShow(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };

  const handleFilterValues = (values, type) => {
    Object.keys(values).forEach((key) => {
      values[key] = values[key].toString();
    });
    setFilterSubmission(type);
    setFilterValues(values);
  };

  console.log(filterValues, "data--");

  const tableData = discountsData?.rows?.map((item) => ({
    id: item?.id,
    code: item?.code,
    discount_name: item?.discount_name,
    discount_type: item?.discount_type
      ? removeUnderScore(item?.discount_type)
      : "--",
    min_order_value: item?.min_order_value ? (localStorage?.getItem("currency") + " " ?? "₹ ") + item?.min_order_value : "--",
    on_all_products: item?.on_all_products
      ? removeUnderScore(
        item?.on_all_products === "all" ? "Yes" : item?.on_all_products
      )
      : "--",
    Action: ["/discounts/view/", "/discounts/edit/"],
  }));
  return (
    <div className="col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5">
      <NotificationComponent />
      <DynamicTable
        tabletitle="Discounts"
        placeholder="Search discount here by id"
        modalText={"discount"}
        loading={loading}
        reload={reload}
        error={error}
        tableHeading={tableDiscountHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        setCurrentPage={setCurrentPage}
        createButtonName="CREATE DISCOUNT"
        createpath="/discounts/create"
        pagination={discountsData?.pagination}
        singleDeleteAction={discountDeleteAction}
        multiDeleteAction={discountMultiDeleteAction}
        setLoading={setLoading}
        setReload={setReload}
        id_name={"discount_id"}
        filtration={true}
        filterFn={() => setFilterShow(true)}
        rolePermission={"discounts"}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
      <DiscountTableFilter
        show={filterShow}
        handleClose={() => setFilterShow(false)}
        filterValues={filterValues}
        handleFilterValues={handleFilterValues}
      />
    </div>
  );
}
const tableDiscountHeader = [
  { label: "Discount ID", value: "code" },
  { label: "Discount Name", value: "discount_name" },
  { label: "Discount Type", value: "discount_type" },
  { label: "Min. Order Value", value: "min_order_value" },
  { label: "On All Products", value: "on_all_products" },
  { label: "Actions", value: "Actions" },
];
