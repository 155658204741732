import React, { useEffect, useRef, useState } from "react";
import "../forms/PicturesOfProducts.css";
import ImageUploading from "react-images-uploading";
import DeleteIcon from "../../../assets/icons/light_delete_icon.svg";
import AddImagesIcon from "../../../assets/icons/Add_images_icon.svg";
import ImgUploadLogo from "../../../assets/images/Upload_product_img_logo.svg";
import PlusIcon from "../../../assets/icons/plus-icon";
import { useDispatch } from "react-redux";
import { categoriesImgUploadAction } from "../../../redux/actions/categories-action";
import errorToast from "../../helper-functions/helper/error-toast";
import LoadingSpinner from "../../helper-functions/helper/loadingSpinner";
import { CANCEL_CATEGORIES_IMAGE_UPLOAD } from "../../../redux/types";
const MultiImageUpload = ({
  images = [],
  setImages,
  model = false,
  page = "create",
  border = true,
  containerClass = "ms-4 ms-md-5 pe-4",
  isRequired,
  // variants = false,
  setFieldValue,
  varientData,
}) => {
  const dispatch = useDispatch();
  const [isImgLoading, setIsImgLoading] = useState([]);

  const [error, setError] = useState();
  const [screen, setScreen] = useState();
  const [loading, setLoading] = useState(false);
  const onChange = (imageList, addUpdateIndex, formik, varientData) => {
    setLoading(true);
    // if (model) {
    //   setFieldValue("product_picture", imageList);
    // } else {
    //   setFieldValue("image", imageList);
    // }

    for (let index = images?.length; index < imageList?.length; index++) {
      dispatch(
        categoriesImgUploadAction(
          imageList[index]?.file,
          onImgSuccess,
          onImgError
        )
      );
    }
  };
  const onImgSuccess = (url) => {
    setLoading(false);
    setImages((im) => [...im, url]);
    setFieldValue("product_picture", [...images, url]);
  };
  const onImgError = (data) => {
    setLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  const onSuccessLate = (index) => {
    dispatch({ type: CANCEL_CATEGORIES_IMAGE_UPLOAD });
    setLoading(false);
    if (index) {
      setImages((prev) => [...prev]);
      setFieldValue("product_picture", [...images]);
    } else {
      setImages([]);
      setFieldValue("product_picture", []);
    }
  };
  const onImageRemove = (index) => {
    setImages([...images.filter((img, ind) => ind !== index)]);
    setFieldValue("product_picture", [
      ...images.filter((img, ind) => ind !== index),
    ]);
  };
  // const handleImageLoad = (index) => {
  //   // setIsImgLoading((prevLoading) => {
  //   //   const updatedLoading = [...prevLoading];
  //   //   updatedLoading[index] = false; // Set the loading status for the current image to false
  //   //   return updatedLoading;
  //   // });
  //   setIsImgLoading(false);
  //   window.addEventListener("load", handleImageLoad);

  //   return () => {
  //     // Clean up the event listener when the component unmounts
  //     window.removeEventListener("load", handleImageLoad);
  //   };
  // };
  // const handleImageUpload = (images) => {
  //   setIsImgLoading(new Array(images.length).fill(true));
  // };
  const handleError = (errors) => {
    if (errors?.maxNumber) {
      setError("Max 10 images");
    } else if (errors?.acceptType) {
      setError("Image type only");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.innerWidth);
    };
    setScreen(window?.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   setIsImgLoading(prevLoadings => [...prevLoadings, ...Array(images.length - prevLoadings.length).fill(true)]);
  // }, [images]);
  // const handleImageLoad = (index) => {
  //   setIsImgLoading(prevLoadings => {
  //     const updatedLoadings = [...prevLoadings];
  //     updatedLoadings[index] = false;
  //     return updatedLoadings;
  //   });
  // };
  return (
    <div className={containerClass}>
      <label className="color_030304 fw_500 mb-2">Pictures</label>
      <div
        className={` ${border && !isRequired
            ? "border_1px_E6ECFF"
            : isRequired && "border_1px_red"
          } border_radius_7px ${model ? "model_picture_container" : "picture_border"
          } position-relative w-100`}
        style={{ height: images?.length === 0 && "20rem" }}
      >
        {images?.length === 0 ? (
          <div className="">
            {loading ? (
              <div
                className={`${
                  model && screen < 440
                    ? "col-12"
                    : screen < 400
                    ? "col-12"
                    : "col-6"
                }  ${
                  model
                    ? "model_card_container col-sm-4 col-lg-3"
                    : "card_container col-sm-4 col-xl-3"
                } mt-3 mb-3 ms-3 d-flex justify-content-center align-items-center border_1px_E6ECFF position-relative`}
                // className="d-flex justify-content-center align-items-center "
                style={{ height: images?.length === 0 ? "18rem" : undefined }}
              >
                <LoadingSpinner size="lg" />
                {page !== "view" && loading && (
                  <div
                    className="position-absolute bottom-0 end-0 pe-3 pb-3"
                    onClick={() => onSuccessLate()}
                  >
                    <img
                      className="cursor_pointer"
                      width={25}
                      src={DeleteIcon}
                      alt="Delete-Icon"
                    />
                  </div>
                )}
              </div>
            ) : (
              <ImageUploading
                acceptType={["jpg", "png", "jpeg", "webp", "svg", "jfif"]}
                value={images}
                multiple
                onChange={onChange}
                maxNumber={page === "create" && 10}
                dataURLKey="data_url"
                maxFileSize={20485760}
                required={isRequired}
              >
                {({ imageList, onImageUpload, isDragging, dragProps }) => (
                  <div className="upload__image-wrapper">
                    {images?.length === 0 && (
                      <div className="position-absolute start-50 top-50 translate-middle d-flex justify-content-center">
                        <div>
                          <span className="d-flex justify-content-center">
                            <img
                              className="d-none d-md-block"
                              src={ImgUploadLogo}
                            />
                            <img
                              className="d-block d-md-none"
                              src={ImgUploadLogo}
                              width={65}
                            />
                          </span>
                          <label className="color_9E9E9E mt-3 mt-md-4 fs_14 text-center">
                            Image will show when you upload the picture in
                            varients
                          </label>
                          {(model || isRequired || !varientData) &&
                            page !== "view" && (
                              <div className="text-center mt-3">
                                <button
                                  type="button"
                                  className=" bg_color_2820DA px-3 border_radius_5px py-1"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <span className="">
                                    <PlusIcon width="13" fill="#FFFFFF" />
                                  </span>
                                  <span className="fs_sm_12 fs_14 text-white fw_500 ms-2">
                                    ADD PICTURES
                                  </span>
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </ImageUploading>
            )}
          </div>
        ) : (
          <div
            className={`row g-0 ms-2 me-2 me-xx-0 d-flex justify-content-center justify-content-sm-start`}
          >
            {images?.map((item, index) => {
              return (
                <div
                  className={`${model && screen < 440
                      ? "col-12"
                      : screen < 400
                        ? "col-12"
                        : "col-6"
                    }  ${model
                      ? "model_card_container col-sm-4 col-lg-3"
                      : "card_container col-sm-4 col-xl-3"
                    } mt-3 mb-3`}
                >
                  <div className="card  position-relative mx-2">
                    <span className="d-none d-md-block">
                      {isImgLoading[index] && (
                        <span className="text-danger">Loading</span>
                      )}

                      <img
                        src={item}
                        alt="product_image"
                        className="w-100 product_images border_1px_E6ECFF rounded-3"
                        height={
                          model && screen <= 1200
                            ? 270
                            : model && screen > 1200
                              ? 250
                              : screen <= 1200
                                ? 275
                                : 320
                        }
                        // onLoad={() => handleImageLoad(index)}
                      />
                    </span>
                    <span className="d-block d-md-none">
                      <img
                        src={item}
                        alt="product_image"
                        className="w-100 product_images border_1px_E6ECFF rounded-3"
                        height={230}
                      />
                    </span>
                    {page !== "view" && !varientData && (
                      <div
                        className="position-absolute bottom-0 end-0 pe-3 pb-3"
                        onClick={() => onImageRemove(index)}
                      >
                        <img
                          className="cursor_pointer"
                          width={25}
                          src={DeleteIcon}
                          alt="Delete-Icon"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {images?.length > 0 &&
              images?.length < 10 &&
              page !== "view" &&
              !varientData && (
                <div
                  className={`mt-3 mb-3 ${screen < 400 ? "col-12" : "col-6"}  ${model
                      ? "model_card_container col-sm-4 col-lg-3"
                      : "card_container col-sm-4 col-xl-3"
                  }  d-flex justify-content-center align-items-center position-relative`}
                >
                  {loading ? (
                    <div className="">
                      <LoadingSpinner size="lg" />
                      {page !== "view" && loading && (
                        <div
                          className="position-absolute bottom-0 end-0 pe-3 pb-3"
                          // onClick={() => onSuccessLate(images.map((_, index)=> (index)))}
                          onClick={() => onSuccessLate(images.length)}
                        >
                          <img
                            className="cursor_pointer"
                            width={25}
                            src={DeleteIcon}
                            alt="Delete-Icon"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="container bg_color_E6ECFF border_radius_7px mx-2 h-100">
                      <ImageUploading
                        acceptType={["jpg", "png", "jpeg", "webp","jfif"]}
                        value={images}
                        multiple
                        onChange={onChange}
                        maxNumber={page === "create" && 10}
                        dataURLKey="data_url"
                        maxFileSize={20485760}
                        required={isRequired}
                      >
                        {({ onImageUpload, errors, dragProps }) => {
                          handleError(errors);
                          return (
                            <div
                              className="text-center d-flex justify-content-center align-items-center w-100 h-100"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <div
                              // onClick={() => handleImageUpload(images)}
                              >
                                <span className="d-flex justify-content-center align-items-center">
                                  <img
                                    className="d-none d-md-block"
                                    width={40}
                                    src={AddImagesIcon}
                                  />
                                  <img
                                    className="d-block d-md-none "
                                    width={35}
                                    src={AddImagesIcon}
                                  />
                                </span>
                                <p className="primary_color fw_500 fs_xs_14 mt-2">
                                  Add Pictures
                                </p>
                              </div>
                            </div>
                          );
                        }}
                      </ImageUploading>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
      <p className="text-danger mt-2">{error}</p>
    </div>
  );
};
export default MultiImageUpload;
