import React, { useState } from "react";
import Select, { components } from "react-select";
import DownArrowIcon from "../../../assets/icons/down-arrow-icon";
import {
  SelecttextColorChanger,
  removeUnderScore,
  textColorChanger,
} from "../../helper-functions/helper/helper";
import { Field, useFormikContext } from "formik";

function SelectOrderStatus({
  options,
  selectClass = "col-lg-6 col-md-8 col-12 ",
  selectedOption,
  setSelectedOption,
  name,
  isFormik = false,
  defaultValue = null,
  formValues = {},
  isDisabled = false,
  label = "Order status",
  placeholder = "Select...",
  labelClass = "color_707070 fs_14",
  display = true,
}) {
  const handleChange = (selected) => {
    console.log(selected, "selected");
    setSelectedOption(selected);
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {<DownArrowIcon fill={`${display ? "#030304" : "transparent"}`} />}
      </components.DropdownIndicator>
    );
  };
  const selectValue = (form) => {
    let result = null;
    if (form.values[name]) {
      result = options.find((item) => item.value === form.values[name]) ?? {
        label: form.values[name],
        value: form.values[name],
      };
    }
    return result ?? defaultValue ?? null;
  };
  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (base, state) => ({
      ...base,
      color: "#464646",
      fontFamily: "Poppins,sans-serif",
      fontWeight: "400",
      fontSize: "15px",
      borderRadius: "10px",
      width: "100%",
      borderColor: state.isFocused ? "transparent" : "transparent",
      backgroundColor: state.isDisabled ? "transparent" : null, // Set the background color for disabled state
      boxShadow: state.isFocused ? null : null,
      "&:hover": {},
    }),
    option: (provided, state) => {
      return {
        ...provided,
        ...(state.data.color
          ? { color: state.data.color }
          : { color: "black" }),
        backgroundColor: state.isSelected ? "#e5ecffb2" : null,

        borderBottom: "1px solid #F5F5F5",
        fontFamily: "Poppins,sans-serif",
        "&:hover": {
          backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
        },
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#9E9E9E",
        fontFamily: "Poppins,sans-serif",
        fontSize: "14px",
        fontWeight: "400",
        opacity: "0.9",
        position: "absolute",
        // top: "0.6rem"
      };
    },
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#E5ECFF",
      gap: "5px",
      padding: "0px 6px",
    }),
    singleValue: (base) => ({
      ...base,
      fontWeight: "500",
      color: SelecttextColorChanger(
        name === "orders_Status"
          ? formValues?.orders_Status
          : isFormik
          ? removeUnderScore(
              formValues?.payment_status ?? formValues?.refund_status
            )
          : selectedOption?.label
      ),
      backgroundColor: `${SelecttextColorChanger(
        name === "orders_Status"
          ? formValues?.orders_Status
          : isFormik
          ? removeUnderScore(
              formValues?.payment_status ?? formValues?.refund_status
            )
          : selectedOption?.label
      )}10`,
      width: "fit-content",
      padding: "4px 10px 4px 10px",
      borderRadius: "7px",
    }),
    // dropdownIndicator: () => ({ display: "none" }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      ...(state.selectProps.menuIsOpen
        ? {
            transform: "rotate(180deg)",
          }
        : {}),
    }),
  };
  return (
    <>
      {isFormik ? (
        <Field name={name}>
          {({ form, meta, field }) => {
            return (
              <>
                <label className={`${labelClass}`}>{label}</label>
                <Select
                  placeholder={placeholder}
                  {...field}
                  options={options}
                  value={selectValue(form)}
                  onChange={(selectedOption) => {
                    form.setFieldValue(name, selectedOption.value);
                  }}
                  styles={customStyles}
                  components={{
                    DropdownIndicator,
                  }}
                  className={`${
                    meta.error && meta.touched
                      ? "selectField focus_outline__none selectField_border__error"
                      : "selectField selectField_border focus_outline__none"
                  } ${selectClass}`}
                />
              </>
            );
          }}
        </Field>
      ) : (
        <>
          <label className="color_707070 fs_14">{label}</label>
          <Select
            isDisabled={isDisabled}
            options={options}
            value={selectedOption}
            onChange={handleChange}
            styles={customStyles}
            components={{
              DropdownIndicator,
            }}
            className={`selectField ${
              display ? "selectField_border" : ""
            } focus_outline__none py-1 ${selectClass}`}
          />
        </>
      )}
    </>
  );
}

export default SelectOrderStatus;
