import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/ui/tables/table";
import { useDispatch } from "react-redux";
import {
  AddressDeleteAction,
  AddressMultiDeleteAction,
  AddressTableAction,
} from "../../redux/actions/address-action";
import AddressTableFilter from "../../components/ui/tables/table-filter/address-table-filter";
import { useLocation } from "react-router";
import NotificationComponent from "../../components/notificationComponent";

const AddressTable = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);
  const dispatch = useDispatch();
  const [address, setAddress] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [reload, setReload] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [filterData, setFilterData] = useState({
    customer_id: [],
    country: "",
    state: "",
    pincode: "",
    is_default: null,
  });
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      AddressTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig,
        },
        {
          filteredValue: {
            customer_id: filterData?.customer_id,
            country: filterData.country,
            state: filterData.state,
            pincode: filterData.pincode,
            is_default: filterData?.is_default,
          },
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, reload, inputValue, filterData, sortConfig]);
  const onSuccess = (data) => {
    setAddress(data?.data?.address);
    setPagination(data?.data?.pagination);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };
  const tableData = address?.map((item) => ({
    id: item?.id,
    customer_id: item?.customer_id,
    address_name: item?.address_name,
    address_line1: item?.address_line1,
    country: item?.country,
    state: item?.state,
    is_default: item?.is_default === true ? "Yes" : "No",
    Action: ["/address/view/", "/address/edit/"],
  }));
  return (
    <div className="col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5">
      <NotificationComponent />
      <DynamicTable
        tabletitle="Addresses"
        placeholder={"Search address, customer id, address id here"}
        modalText={"address  "}
        tableHeading={addresseHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        createButtonName="CREATE ADDRESS"
        createpath="/address/create"
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={AddressDeleteAction}
        multiDeleteAction={AddressMultiDeleteAction}
        loading={loading}
        setLoading={setLoading}
        error={error}
        id_name={"address_id"}
        setReload={setReload}
        reload={reload}
        rolePermission={"addresses"}
        filtration={true}
        filterFn={() => setFilterShow(true)}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
      <AddressTableFilter
        setFilterShow={setFilterShow}
        filterShow={filterShow}
        setFilterData={setFilterData}
        filterData={filterData}
      />
    </div>
  );
};
export default AddressTable;

const addresseHeader = [
  { label: "ID", value: "id" },
  { label: "Customer ID", value: "customer_id" },
  { label: "Address Name", value: "address_name" },
  { label: "Address", value: "address_line1" },
  { label: "Country", value: "country" },
  { label: "State", value: "state" },
  { label: "Default Address", value: "is_default" },
  { label: "Actions", value: "Action" },
];
