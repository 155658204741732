import { call, takeLatest } from "redux-saga/effects";
import {
  productsEditApi,
  productsDeleteApi,
  productsMultiDeleteApi,
  productsTableApi,
  productsViewApi,
  productsCreateApi,
  allProductsIdAApi,
  updateProductStatusApi,
  updateProductStockStatusApi,
} from "../apis/products-api";
import {
  PRODUCTS_EDIT,
  PRODUCTS_DELETE,
  PRODUCTS_MULTI_DELETE,
  PRODUCTS_TABLE,
  PRODUCTS_VIEW,
  PRODUCTS_CREATE,
  ALL_PRODUCTS_ID,
  UPDATE_PRODUCTS_STOCK_STATUS,
  UPDATE_PRODUCTS_STATUS,
} from "../types";

export function* productsTableWorker(action) {
  try {
    const res = yield call(productsTableApi, action.data, action?.bodyData);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* productsViewWorker(action) {
  try {
    const res = yield call(productsViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* productsDeleteWorker(action) {
  try {
    const res = yield call(productsDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* productsMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(productsMultiDeleteApi, { data: action.data });
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error);
  }
}

export function* productsCreateWorker(action) {
  try {
    const res = yield call(productsCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* productsEditWorker(action) {
  try {
    const res = yield call(productsEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* allProductsIdWorker(action) {
  try {
    const res = yield call(allProductsIdAApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* updateProductStatusWorker(action) {
  try {
    const res = yield call(updateProductStatusApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* updateProductStockStatusWorker(action) {
  try {
    const res = yield call(updateProductStockStatusApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* watchproductsTableWorker() {
  yield takeLatest(PRODUCTS_TABLE, productsTableWorker);
}
export function* watchproductsViewWorker() {
  yield takeLatest(PRODUCTS_VIEW, productsViewWorker);
}
export function* watchproductsEditWorker() {
  yield takeLatest(PRODUCTS_EDIT, productsEditWorker);
}
export function* watchproductsDeleteWorker() {
  yield takeLatest(PRODUCTS_DELETE, productsDeleteWorker);
}
export function* watchproductsMultiDeleteWorker() {
  yield takeLatest(PRODUCTS_MULTI_DELETE, productsMultiDeleteWorker);
}
export function* watchproductsCreateWorker() {
  yield takeLatest(PRODUCTS_CREATE, productsCreateWorker);
}
export function* watchallProductsIdWorker() {
  yield takeLatest(ALL_PRODUCTS_ID, allProductsIdWorker);
}
export function* watchupdateProductStatusWorker() {
  yield takeLatest(UPDATE_PRODUCTS_STATUS, updateProductStatusWorker);
}
export function* watchupdateProductStockStatusWorker() {
  yield takeLatest(
    UPDATE_PRODUCTS_STOCK_STATUS,
    updateProductStockStatusWorker
  );
}
