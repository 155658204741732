import React from "react";

const OrdersIcon = ({ fill = "#707070",bodyFill="none" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={bodyFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0347 3.05774C16.8237 2.80457 16.5596 2.60095 16.2611 2.46135C15.9626 2.32175 15.637 2.24959 15.3074 2.24999H3.18149L3.14999 1.98674C3.08552 1.43956 2.82253 0.935045 2.41086 0.568856C1.99919 0.202667 1.46746 0.000256344 0.916496 0L0.749997 0C0.551085 0 0.360321 0.0790173 0.219669 0.219669C0.0790173 0.360321 0 0.551085 0 0.749997C0 0.948909 0.0790173 1.13967 0.219669 1.28032C0.360321 1.42098 0.551085 1.49999 0.749997 1.49999H0.916496C1.1002 1.50002 1.2775 1.56746 1.41477 1.68953C1.55205 1.8116 1.63975 1.9798 1.66124 2.16224L2.69324 10.9372C2.80038 11.8498 3.23884 12.6913 3.92541 13.302C4.61199 13.9126 5.49887 14.25 6.41772 14.2499H14.2499C14.4489 14.2499 14.6396 14.1709 14.7803 14.0303C14.9209 13.8896 14.9999 13.6989 14.9999 13.4999C14.9999 13.301 14.9209 13.1103 14.7803 12.9696C14.6396 12.829 14.4489 12.7499 14.2499 12.7499H6.41772C5.95352 12.7486 5.50108 12.6038 5.12243 12.3352C4.74378 12.0667 4.45747 11.6876 4.30273 11.25H13.2427C14.1219 11.25 14.9732 10.9411 15.6478 10.3773C16.3224 9.81344 16.7775 9.03049 16.9334 8.16522L17.5222 4.89973C17.5809 4.57574 17.5677 4.24278 17.4835 3.92446C17.3992 3.60614 17.246 3.31024 17.0347 3.05774ZM16.0499 4.63348L15.4604 7.89897C15.3668 8.41872 15.0933 8.88896 14.6878 9.22736C14.2824 9.56576 13.7708 9.75076 13.2427 9.74996H4.06423L3.35849 3.74998H15.3074C15.4176 3.74933 15.5266 3.77295 15.6266 3.81918C15.7266 3.8654 15.8152 3.93309 15.8861 4.01744C15.957 4.10178 16.0084 4.20071 16.0367 4.30719C16.065 4.41366 16.0695 4.52506 16.0499 4.63348Z"
        fill={fill}
      />
      <path
        d="M5.24999 18C6.07842 18 6.74999 17.3284 6.74999 16.5C6.74999 15.6716 6.07842 15 5.24999 15C4.42157 15 3.75 15.6716 3.75 16.5C3.75 17.3284 4.42157 18 5.24999 18Z"
        fill={fill}
      />
      <path
        d="M12.75 18C13.5784 18 14.25 17.3284 14.25 16.5C14.25 15.6716 13.5784 15 12.75 15C11.9216 15 11.25 15.6716 11.25 16.5C11.25 17.3284 11.9216 18 12.75 18Z"
        fill={fill}
      />
    </svg>
  );
};

export default OrdersIcon;
