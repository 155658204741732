import React, { useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { SaveButton } from "../buttons/text-button";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { useState } from "react";

const CreatePageModal = ({ show, modalClose, onSave = () => { }, resetFn = () => { }, pageData }) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required")
    .test("uniqueName", "Page name should be unique", function(value){
      if(pageData?.map((item)=> (item?.page_name)?.toLocaleLowerCase()).includes(value.toLocaleLowerCase())){
        return false;
      }else{
        return true;
      }
    }),
    description: Yup.string().trim().required("Description is required"),
  });
  const onSubmit = (values) => {
    // resetFn(values);
    onSave(values, setLoading)
    console.log(values);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Modal
      show={show}
      onHide={modalClose}
      centered
      dialogClassName="mx-sm-auto"
      contentClassName="rounded-3"
    >
      <ModalHeader>
        <div className="text-black fw_500 fs_18 d-flex align-items-center gap-2">
          <img
            onClick={modalClose}
            style={{ background: "#F7F7F7", borderRadius: "3px" }}
            src={BackIcon}
            alt="back"
          />
          <span>Create Page</span>
        </div>
        <div onClick={handleSubmit}>
          <SaveButton loading={loading} disabled={loading} />
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik
          innerRef={formRef}
          initialValues={{
            name: "",
            description: "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="mx-sm-4 mx-2 px-sm-1">
            <InputField
              inputClass="py-2 w-100"
              placeholder={"Enter page name"}
              containerClass="position-relative mt-3"
              errMsgContainerClass="ps-2"
              label="Name"
              name="name"
              type="text"
            />
            <InputField
              inputClass="py-2 w-100"
              placeholder={"Write here"}
              containerClass="mb-3 position-relative"
              errMsgContainerClass="ps-2"
              label="Description"
              name="description"
              type="text"
            />
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default CreatePageModal;
