import React, { useState } from "react";
import InnerLayout from "../../../components/ui/layouts/inner-layout";
import { Field, Form, Formik } from "formik";
import InputField from "../../../components/ui/forms/input-field";
import * as Yup from "yup";
import { removeUnderScore } from "../../../components/helper-functions/helper/helper";
import ErrorMsg from "../../../components/ui/forms/error-msg";
import { useNavigate } from "react-router";
import { adminRoleCreateAction } from "../../../redux/actions/admin-roles-action";
import { useDispatch } from "react-redux";
import errorToast from "../../../components/helper-functions/helper/error-toast";
import successToast from "../../../components/helper-functions/helper/sucess-toast";
import NotificationComponent from "../../../components/notificationComponent";

const AdminRolesCreate = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    role_name: "",
    permission: {
      admin_users: {
        read: false,
        write: false,
      },
      admin_roles: {
        read: false,
        write: false,
      },
      products: {
        read: false,
        write: false,
      },
      categories: {
        read: false,
        write: false,
      },
      discounts: {
        read: false,
        write: false,
      },
      attributes: {
        read: false,
        write: false,
      },
      tags: {
        read: false,
        write: false,
      },
      orders: {
        read: false,
        write: false,
      },
      customers: {
        read: false,
        write: false,
      },
      addresses: {
        read: false,
        write: false,
      },
      configuration: {
        read: false,
        write: false,
      },
      account: {
        read: false,
        write: false,
      },
      brands: {
        read: false,
        write: false,
      },
      deliveryPartner: {
        read: false,
        write: false,
      },
      productEnquiry: {
        read: false,
        write: false,
      },
      contactUs: {
        read: false,
        write: false,
      },
      plugins: {
        read: false,
        write: false,
      },
      themes: {
        read: false,
        write: false,
      },
      // faq: {
      //   read: false,
      //   write: false,
      // },
    },
  };
  const validationSchema = Yup.object().shape({
    role_name: Yup.string().trim().required("Role Name is required"),
    permission: Yup.object().test(
      "at-least-one-selected",
      "Select at least one permission",
      (value) => {
        const permissions = Object.values(value);
        return permissions.some(
          (permission) => permission.read || permission.write
        );
      }
    ),
  });
  const onSubmit = (values) => {
    console.log(values);
    setLoading(true);
    dispatch(adminRoleCreateAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    navigate(`/admin/roles`);
    successToast(data?.message);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data?.data?.message);
    console.log("error");
  };
  const handleCheckboxChange = (key, field, setFieldValue) => {
    return (e) => {
      const isChecked = e.target.checked;
      setFieldValue(`permission.${key}.${field}`, isChecked);
      if (field === "write" && isChecked) {
        setFieldValue(`permission.${key}.read`, true);
      }
    };
  };
  return (
    <>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <InnerLayout
              name="New Admin Role"
              page="create"
              rolePermission={"admin_roles"}
            />
            <div
              className="ms-5 pe-4 pe-md-0 pt-4 pt-lg-0 mt-5"
              style={{ maxWidth: "36rem" }}
            >
              <InputField
                placeholder="Enter Role Name"
                containerClass="mb-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Role Name"
                name="role_name"
                type="text"
              />
            </div>
            <div className="ms-5 pe-4 pe-md-0" style={{ maxWidth: "25rem" }}>
              <table className="">
                <colgroup>
                  <col style={{ width: "45%" }} />
                  <col style={{ width: "42%" }} />
                  <col style={{ width: "45%" }} />
                </colgroup>
                <thead>
                  <tr className=" ">
                    <th className="color_030304 color_707070 fw_400 font_poppins">
                      Permission
                    </th>
                    <th
                      className={`fw_400 text-center font_poppins color_9E9E9E`}
                    >
                      Read
                    </th>
                    <th className="color_9E9E9E fw_400 text-center font_poppins">
                      Write
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(initialValues.permission).map(
                    ([key, value]) => (
                      <tr key={key}>
                        <td
                          className={`${
                            values.permission[key].write ||
                            values.permission[key].read
                              ? "color_030304"
                              : "color_9E9E9E"
                          }  fw_400 font_poppins pt-2`}
                        >
                          {key ? removeUnderScore(key) : "--"}
                        </td>
                        <td className="text-center pt-2">
                          <Field
                            className="form-check-input"
                            style={{ width: "15px", height: "15px" }}
                            name={`permission.${key}.read`}
                            type="checkbox"
                            disabled={values.permission[key].write}
                            onChange={handleCheckboxChange(
                              key,
                              "read",
                              setFieldValue
                            )}
                          />
                        </td>
                        <td className="text-center pt-2">
                          <Field
                            className="form-check-input"
                            style={{ width: "15px", height: "15px" }}
                            name={`permission.${key}.write`}
                            type="checkbox"
                            onChange={handleCheckboxChange(
                              key,
                              "write",
                              setFieldValue
                            )}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <ErrorMsg
                name="permission"
                textClass="color_F24444 fs_16 d-block"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AdminRolesCreate;
