export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Prefer not to say", value: "Prefer not to say" },
];
export const indusOptions = [
  { label: "Fashion", value: "fashion" },
  { label: "FMCG", value: "fmcg" },
  { label: "Food", value: "food" },
];
export const delTypeOptions = [
  { label: "Self", value: "self" },
  { label: "Adair", value: "adair" },
  { label: "Third Party", value: "third_party" },
];
export const delUnitsOptions = [
  { label: "Minutes", value: "minutes" },
  { label: "Days", value: "days" },
];
export const del_type = [
  { label: "PickUp", value: "pickup" },
  { label: "Delivery", value: "delivery" },
];
export const vegOptns = [
  { label: "Veg", value: "veg" },
  { label: "Non-Veg ", value: "nonveg" },
];
export const discountTypeOptions = [
  { label: "Percentage", value: "percentage" },
  { label: "Fixed Rate ", value: "fixed_rate" },
];
export const transacOptions = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "failed" },
];

export const discountProdSelectOptns = [
  { label: "Yes", value: "all" },
  { label: "Include", value: "include" },
  { label: "Exclude ", value: "exclude" },
];
export const deliveryChargeType = [
  { label: "Fixed", value: "fixed_rate" },
  {
    label: "Variable Based on Weights",
    value: "based_on_loc_weight",
  },
];
export const varDeliveryChargeType = [
  { label: "Fixed Rate", value: "fixed" },
  { label: "Weight Based Rate", value: "weight_based" },
];

export const labelCreateClass = "color_030304 d-flex fs_14 mb-1 fw_500";

export const variantTypeOptions = [
  {
    label: "color",
    value: "color",
  },
  {
    label: "size",
    value: "size",
  },
  {
    label: "material",
    value: "material",
  },
];
export const emailInboxOptions = [
  { label: "Adair", value: "adair" },
  { label: "Custom SMTP", value: "custom" },
];

export const gstPercentage = [
  { label: "5%", value: "5" },
  { label: "12%", value: "12" },
  { label: "18%", value: "18" },
  { label: "28%", value: "28" },
];

export const paymentStatus = [
  {
    label: "Paid",
    value: "paid",
    color: "#34BF44",
  },
  {
    label: "Unpaid",
    value: "unpaid",
    color: "#F23D3D",
  },
];
export const returnpaymentStatus = [
  {
    label: "None",
    value: "none",
    color: "#FF6C1A",
  },
  {
    label: "Refund Pending",
    value: "pending",
    color: "#FF6C1A",
  },
  {
    label: "Refund Request",
    value: "requested",
    color: "#FF6C1A",
  },
  {
    label: "Refunded",
    value: "processed",
    color: "#0064D2",
  },
  {
    label: "Refund Processing",
    value: "processing",
    color: "#0064D2",
  },
];

export const orderAllOptions = [
  {
    label: "Order Received",
    value: "received",
    color: "#04BA71",
  },
  {
    label: "Preparing",
    value: "preparing",
    color: "#FF6C1A",
  },
  {
    label: "Order Accepted",
    value: "accepted",
    color: "#0064D2",
  },
  {
    label: "Dispatched",
    value: "dispatched",
    color: "#2A22E5",
  },
  {
    label: "Delivered",
    value: "delivered",
    color: "#34BF44",
  },
  {
    label: "Rejected",
    value: "rejected",
    color: "#F23D3D",
  },
  {
    label: "Refunded",
    value: "Refunded",
    color: "#04BA71",
  },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "#F23D3D",
  },
  {
    label: "Returned",
    value: "returned",
    color: "#F23D3D",
  },
  {
    label: "Return Requested",
    value: "return_requested",
    color: "#FF6C1A",
  },
  {
    label: "Partially Returned",
    value: "Partially Returned",
    color: "#FF6C1A",
  },
  {
    label: "Partially Rejected",
    value: "partially_rejected",
    color: "#FF6C1A",
  },
];

export const pieChartSuggestions = [
  {
    title: "Completed Orders",
    backgroundColor: "#3AD64C",
  },
  {
    title: "New Orders",
    backgroundColor: "#FDC827",
  },
  {
    title: "Dispatched Orders",
    backgroundColor: "#2A22E5",
  },
  {
    title: "Rejected Orders",
    backgroundColor: "#F24444",
  },
];

export const transactionStatus = [
  {
    label: "Successful",
    value: "successful",
  },
  {
    label: "Failed",
    value: "failed",
  },
];
