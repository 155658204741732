import { all } from "redux-saga/effects";
import {
  watchattributesEditWorker,
  watchattributesDeleteWorker,
  watchattributesMultiDeleteWorker,
  watchattributesTableWorker,
  watchattributesViewWorker,
  watchattributesCreateWorker,
  watchattributesDropDownListWorker,
} from "../attributes-saga";
export default function* attributesRootSaga() {
  yield all([
    watchattributesTableWorker(),
    watchattributesDeleteWorker(),
    watchattributesMultiDeleteWorker(),
    watchattributesViewWorker(),
    watchattributesEditWorker(),
    watchattributesCreateWorker(),
    watchattributesDropDownListWorker(),
  ]);
}
