import { combineReducers } from "redux";
import { errorReducer } from "./error-reducer"
import { loggedAdminReducer } from "./user-reducer"
import { siteSettingReducer } from "./siteSetting-reducer"

const rootReducer = combineReducers({
    errorReducer, siteSettingReducer, loggedAdminReducer
});

export default rootReducer;
