
import React from 'react'

const GoogleIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.35921 10.6988V8.2395H17.646C17.727 8.66775 17.769 9.17475 17.769 9.72375C17.769 11.5688 17.265 13.8503 15.639 15.4755C14.058 17.1217 12.0382 18 9.36221 18C4.40171 18 0.230957 13.9598 0.230957 9C0.230957 4.04025 4.40171 0 9.36221 0C12.1065 0 14.061 1.077 15.5295 2.48025L13.794 4.215C12.741 3.22725 11.3137 2.45925 9.35921 2.45925C5.73746 2.45925 2.90471 5.37825 2.90471 9C2.90471 12.6217 5.73746 15.5408 9.35921 15.5408C11.7082 15.5408 13.0462 14.5972 13.9035 13.74C14.5987 13.0448 15.0562 12.0518 15.2362 10.6957L9.35921 10.6988Z" fill="white" />
        </svg>
    )
}

export default GoogleIcon