import React from "react";

const FacebookLogo = ({ width = "20", height = "20", fill = "#2820DA" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 11.2513H13.75L14.5833 7.91797H11.6667V6.2513C11.6667 5.39349 11.6667 4.58464 13.3333 4.58464H14.5833V1.78472C14.3119 1.74868 13.2858 1.66797 12.2024 1.66797C9.94033 1.66797 8.33333 3.04869 8.33333 5.58439V7.91797H5.83333V11.2513H8.33333V18.3346H11.6667V11.2513Z"
        fill={fill}
      />
    </svg>
  );
};

export default FacebookLogo;
