import {
  DELIVERYPARTNER_CREATE,
  DELIVERYPARTNER_DELETE,
  TAGS_DROPDOWN_LIST,
  DELIVERYPARTNER_EDIT,
  DELIVERYPARTNER_MULTI_DELETE,
  DELIVERYPARTNER_TABLE,
  DELIVERYPARTNER_VIEW,
  DELIVERY_BLOCK,
} from "../types";

export const deliverypartnerCreateAction = (data, onSuccess, onError) => {
  return {
    type: DELIVERYPARTNER_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const deliverypartnerTableAction = (data, onSuccess, onError) => {
  return {
    type: DELIVERYPARTNER_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const deliverypartnerViewAction = (id, onSuccess, onError) => {
  return {
    type: DELIVERYPARTNER_VIEW,
    id,
    onSuccess,
    onError,
  };                                     
};

export const deliverypartnerEditAction = (data, onSuccess, onError) => {
  return {
    type: DELIVERYPARTNER_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const deliverypartnerDeleteAction = (data, onSuccess, onError) => {
  return {
    type: DELIVERYPARTNER_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const deliverypartnerMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: DELIVERYPARTNER_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const tagsDropDownListAction = (onSuccess, onError) => {
  return { type: TAGS_DROPDOWN_LIST, onSuccess, onError };
};

export const deliveryBlockAction = (data, onSuccess, onError) => {
  return {
    type: DELIVERY_BLOCK,
    data,
    onSuccess,
    onError,
  };
};

