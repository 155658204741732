import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import caution from "../../../assets/images/caution.svg";

const FillBannerModal = ({ show, modalClose, fillBannerMsg }) => {
  return (
    <Modal show={show} onHide={modalClose} size="" centered dialogClassName="">
      <ModalBody>
        <div className="d-flex justify-content-end">
          <label className="px-2 py-1 bg_color_F3F6FF rounded-1">
            {smallCloseIcon}
          </label>
        </div>

        <div className="d-flex justify-content-center align-items-center text-center my-4">
          <div>
            <img src={caution} />
            <p className="my-3">{fillBannerMsg}</p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FillBannerModal;

const smallCloseIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13L13 1M1 1L13 13"
      stroke="#030304"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
