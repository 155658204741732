import { Field, useFormikContext } from "formik";
import React, { useState } from "react";
import {
  categoriesImgUploadAction,
  configurationImgUploadAction,
} from "../../../redux/actions/categories-action";
import { useDispatch } from "react-redux";
import errorToast from "../../helper-functions/helper/error-toast";
import Compressor from "compressorjs";
import UploadIcon from "../../../assets/icons/uploadIcon";
import DeleteIconWithBg from "../../../assets/icons/products-delete";
import LoadingSpinner from "../../helper-functions/helper/loadingSpinner";
import UplaodImgLogo from "../../../assets/icons/Upload_image_logo.svg";
import ErrorMsg from "./error-msg";

const ImageField = ({ name, index, type }) => {
  const { values, setValues, errors, touched, setFieldValue } =
    useFormikContext();
  const dispatch = useDispatch();
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [imageSize, setImageSize] = useState(false);
  const handleImage = (e) => {
    setIsImgLoad(true);
    let img = e.target.files[0];
    if (img?.size <= 10485760) {
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(
            configurationImgUploadAction(
              result,
              (data) => onImageSuccess(data, name),
              onImageError
            )
          );
        },
      });
    } else {
      setImageSize(true);
    }
  };
  const onImageSuccess = (data, fieldName) => {
    setIsImgLoad(false);
    setFieldValue(fieldName, data);
  };
  const onImageError = (data) => {
    setIsImgLoad(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };

  function handleDeleteImage(image) {
    setFieldValue(image, "");
  }

  console.log(values[name], "fdggdfgdfgdfgdf");
  console.log(values, "fdggdfgdfgdfgdf");
  console.log(values?.variations?.[index]?.banner, "fdggdfgdfgdfgdf");
  return (
    <>
      {values[name] || values?.variations?.[index]?.banner ? (
        <>
          <div
            style={{ height: "12rem" }}
            className="rounded-3  position-relative w-100"
          >
            <img
              className="rounded-3"
              src={
                type === "variant"
                  ? values?.variations?.[index]?.banner
                  : values[name]
              }
              width={"100%"}
              height={"100%"}
            />
            <span
              onClick={() => handleDeleteImage(name)}
              className="position-absolute bottom-0 end-0 pe-4 pb-2"
            >
              <DeleteIconWithBg fill="#F24444" bgFill="#FFFFFF" />
            </span>
          </div>
        </>
      ) : (
        <>
          <label className="w-100" htmlFor={name}>
            <div
              style={{ height: "12rem" }}
              className="bg_color_F9F9FC position-relative"
            >
              {isImgLoad ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  <span className="position-absolute bottom-0 end-0">
                    <UploadIcon />
                  </span>
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <img src={UplaodImgLogo} />
                  </div>
                  <div className="d-none">
                    <Field name="profile">
                      {({ field, form }) => (
                        <div style={{ height: "0px" }}>
                          <input
                            accept="image/*;capture=camera,image/jpeg,image/png,image/svg,image/webp,image/jfif"
                            style={{
                              visibility: "hidden",
                              width: "100%",
                              height: "0px",
                            }}
                            type="file"
                            id={name}
                            name={name}
                            {...field}
                            onChange={handleImage}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </>
              )}
            </div>
          </label>
        </>
      )}
      <ErrorMsg name={name} />
    </>
  );
};
export default ImageField;
