import React, { useEffect, useState } from "react";
import UplaodImgLogo from "../../../assets/icons/Upload_image_logo.svg";
import UploadIcon from "../../../assets/icons/Image_upload_icon.svg";
import DeleteIcon from "../../../assets/icons/light_delete_icon.svg";
import LoadingSpinner from "../../helper-functions/helper/loadingSpinner";
import { CANCEL_CATEGORIES_IMAGE_UPLOAD } from "../../../redux/types";
import { useDispatch } from "react-redux";
import ErrorMsg from "./error-msg";
import { imageRegex } from "../../helper-functions/helper/regex";
import { useFormikContext } from "formik";
const ImageUpload = ({
  page,
  title = "",
  labelClass = "",
  getImgUrl,
  imgLoading,
  setImgLoading,
  name,
  inputName = "img",
  data,
  resetFn,
  containerClass = "mt-5 ms-4 ms-md-5",
  maxWidth = "250px",
  height = "250px",
  id = "logo",
  insideLogoWidth = "100px",
  insideLogoHeight = "100px",
  uploadIconWidth = "40px",
  imgWidth = "255px",
  imgHeight = "250px",
  deleteIconWidth = "30px",
  setFieldValue,
  error = false,
}) => {

  console.log(data, "bfdrgkdfjigjdfhgjdfhug");
  const dispatch = useDispatch();
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [uploadedImage, setUploadedImage] = useState({
    imgUrl: data,
    err: false,
  });
  useEffect(() => {
    setUploadedImage({ imgUrl: data, err: false });
    page === "site" && setFieldValue(name, data);
  }, [data]);

  const handleImage = (e) => {
    // setIsImgLoad(false);
    let img = e.target.files[0];
    console.log(e.target.files,"fyhbkbxsdfjhb");
    // setUploadedImage({ imgUrl: URL.createObjectURL(img), err: false });
    page === "site" && setFieldValue(name, data);
    getImgUrl(e.target.files[0]);
    console.log(e.target.files[0], "imgUrl");
    e.target.value = null;
  };

  const onSuccessLate = () => {
    console.log("cencelimg", "cencelimg");
    setImgLoading(false);
    setIsImgLoad(false);
    dispatch({ type: CANCEL_CATEGORIES_IMAGE_UPLOAD });
  };
  return (
    <>
      <div className={containerClass}>
        <label
          htmlFor={id}
          className={`${(name === "NewCategory" || name === "image_url") ? "text-black fw_500" : "color_9E9E9E"
            } ${labelClass} fs_16 cursor_pointer mb-2`}
        >
          {title ? title : page === "view" ? "Profile Picture" : "Image"}
        </label>
        <div
          className={`image_upload_container bg_color_F9F9FC  ${!data && "rounded-3 border_1px_A0A0BF"
            }
         d-flex justify-content-center align-items-center position-relative`}
          style={{ maxWidth: maxWidth, height: height }}
        >
          <div>
            <label htmlFor={!data && id}>
              {imgLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "255px", height: "250px" }}
                >
                  <LoadingSpinner size="lg" />
                </div>
              ) : (
                <span className="image_upload_section mt-2">
                  {imageRegex.test(data) ? (
                    <span>
                      {/* {isImgLoad && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "250px", height: "250px" }}
                      >
                        <LoadingSpinner size="lg" />
                      </div>
                    )} */}
                      <img
                        className={data && "border_radius_10px"}
                        width={imgWidth}
                        height={imgHeight}
                        src={data}
                      // onLoad={() => data && setIsImgLoad(false)}
                      // style={{ display: isImgLoad ? "none" : "block" }}
                      />
                    </span>
                  ) : (
                    <>
                      <img
                        className={!data && "border_radius_10px"}
                        width={insideLogoWidth}
                        height={insideLogoHeight}
                        src={UplaodImgLogo}
                      />
                    </>
                  )}
                </span>
              )}

              {imageRegex.test(data) === false && !imgLoading && (
                <div
                  className="position-absolute cursor_pointer border_radius_5px box_shadow"
                  style={{
                    bottom: "10px",
                    right: "8px",
                    zIndex: 1,
                  }}
                >
                  <img
                    onClick={() => {
                      imageRegex.test(data) &&
                        setUploadedImage({
                          imgUrl: "",
                          err: false,
                        });
                    }}
                    width={uploadIconWidth}
                    src={UploadIcon}
                  />
                </div>
              )}
            </label>
          </div>
          {((imageRegex.test(data) && page !== "view") ||
            imgLoading) && (
              <div
                className="position-absolute cursor_pointer border_radius_5px box_shadow"
                style={{
                  bottom: "10px",
                  right: "8px",
                  zIndex: 1,
                }}
              >
                <img
                  onClick={() => {
                    data &&
                      setUploadedImage({ imgUrl: "", err: false });
                    page === "site" && setFieldValue(name, "");
                    imageRegex.test(data) === false &&
                      onSuccessLate();
                    resetFn("clear");
                  }}
                  width={deleteIconWidth}
                  src={DeleteIcon}
                />
              </div>
            )}
        </div>
        {
          page != "site" && <>
            {error && (
              <span className="color_F24444 fs_14">
                {<span className="fs_14 me-1 fw_600">&middot;</span>}Required
              </span>
            )}
          </>
        }
        {
          page === "site" &&
          <ErrorMsg name={name} />
        }
        <input
          disabled={data}
          type={"file"}
          style={{ visibility: "hidden", width: "100%" }}
          id={id}
          name={inputName}
          accept="image/*;capture=camera,image/jpeg,image/jpg,image/JPG,image/JPEG,image/png,image/svg,image/webp,image/jfif"
          onChange={handleImage}
        />
      </div>
    </>
  );
};

export default ImageUpload;
