import { api } from "./api";

export const adminRoleTableApi = (params) => {
  return api.get(
    `/core/role/list?page=${params?.page}&page_size=${params?.size}&search=${params?.search}&${params?.sort != null && `sort_by=${params?.sort?.sort_by}&sort_order=${params?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};
export const adminRoleDeleteApi = (data) => {
  return api.delete(`/core/role/delete?role_id=${data}`);
};
export const adminRoleMultiDeleteApi = (params) => {
  return api.delete(`/core/role/bulk-delete`, params);
};
export const adminRoleViewApi = (data) => {
  return api.get(
    `/core/role/view?role_id=${data?.role_id}&page=${data?.page}&page_size=${data?.page_size}&search=${data?.search}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`
  );
};

export const adminRoleEditApi = (params) => {
  return api.post(`/core/role/edit`, JSON.stringify(params));
};

export const adminRoleCreateApi = (params) => {
  return api.post(`/core/role/create`, JSON.stringify(params));
};
export const adminRoleListApi = () => {
  return api.get(`/core/role/list-roles`);
};
export const loggedAdminRoleApi = () => {
  return api.get(`/core/admin/view-admin-role`);
};
