import React from 'react'
import { removeUnderScore } from '../../helper-functions/helper/helper'

function ActiveInactiveBtn({ type }) {
    console.log(type);
    return (
        <>
            <button style={{height: "30px"}} className={`d-flex align-items-center fs_16  fw_500 ${type === true ? "color_3AD64C bg_color_3AD64C_opacity_1 px-4" : "color_F24444 bg_color_F24444_opacity_1 px-3"}  rounded-2`}><span className='fs_20'>&#8226;</span><span className='ps-2'>{type ? "Active" : "Inactive"}</span></button>
        </>
    )
}

export default ActiveInactiveBtn