import { useState, useEffect } from "react";
import { loggedAdminRoleAction } from "../../../redux/actions/admin-roles-action";
import { useDispatch } from "react-redux";

const usePermissions = () => {
  const dispatch = useDispatch();
  const [, setTimer] = useState(null);
  const [, setPermission] = useState({});

  useEffect(() => {
    const fetchPermissions = () => {
      dispatch(loggedAdminRoleAction(onSuccess, onError));
    };
    fetchPermissions();
    const intervalId = setInterval(fetchPermissions, 15 * 60 * 1000);
    setTimer(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onSuccess = (data) => {
    localStorage.setItem("permission", JSON.stringify(data?.data?.permission));
    setPermission(data?.data?.permission);
  };

  const onError = (error) => {
    console.log(error);
  };

  const canRead = (section) => {
    const permissionsData = localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : {};
    return permissionsData ? permissionsData[section]?.read : false;
  };

  const canWrite = (section) => {
    const permissionsData = localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : {};
    return permissionsData ? permissionsData[section]?.write : false;
  };
  return { canRead, canWrite };
};

export default usePermissions;
