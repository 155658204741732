import React, { useState } from "react";
import styles from "./auth.module.css";
import FFlogo from "../../assets/images/ff-logo.svg";
import { OkayButton as ResetPasswordBtn } from "../../components/ui/buttons/text-button";
import { Form, Formik } from "formik";
import InputField from "../../components/ui/forms/input-field";
import * as Yup from "yup";
import { passwordRegex } from "../../components/helper-functions/helper/regex";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { resetPasswordAction } from "../../redux/actions/auth-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";

const ResetPassword = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, "Password must be at least 8 characters, include 1 uppercase, 1 number, and 1 special character (!@#$%^&*)")
      .trim()
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Required")
      .test("password-match", "Your password didn't match", function (value) {
        return this.parent.password === value;
      }),
  });
  const onSuccess = (data) => {
    setLoading(false);
    navigate("/password-successfully-updated");
    successToast(data.message);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
  };
  return (
    <main
      className={`d-flex  ${styles.reset_pass_conainer} vh-100 justify-content-center  align-items-center mx-3`}
    >
      <img
        src={FFlogo}
        alt="ff logo"
        className="d-lg-none d-inline-block position-absolute top-0 start-0 m-4"
      />
      <section
        className={` box_shadow p-sm-5 p-4 pt-sm-4 w-100 d-flex flex-column rounded-3 bg-white`}
        style={{ maxWidth: "32rem" }}
      >
        <span className="color_030304 fs_30 pt-1 fs_md_26 fw_400">
          Reset Password?{" "}
        </span>
        <span className="color_9E9E9E pb-4 fs_16 fs_md_14 fw_400">
          Please enter your new password below.
        </span>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            console.log("hello");
            setLoading(true);
            dispatch(
              resetPasswordAction(
                {
                  verification_token: params?.id,
                  password: values?.password,
                },
                onSuccess,
                onError
              )
            );
          }}
          validationSchema={validationSchema}
        >
          <Form>
            <InputField
              placeholder="Enter password"
              containerClass="mb-3 position-relative"
              errMsgContainerClass="ps-2"
              // autoComplete="current-password"
              label="New Password"
              name="password"
              type="password"
              isPasswordEye={true}
            />
            <InputField
              placeholder="Enter password"
              containerClass="mb-3 position-relative"
              errMsgContainerClass="ps-2"
              // autoComplete="current-password"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              isPasswordEye={true}
            />
            <ResetPasswordBtn
              disabled={false}
              name="Reset Password"
              width={"100%"}
              type="submit"
              loading={loading}
            />
          </Form>
        </Formik>
      </section>
    </main>
  );
};

export default ResetPassword;
