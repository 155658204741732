import { call, takeLatest } from "redux-saga/effects";
import {
  TAGS_CREATE,
  TAGS_DELETE,
  TAGS_DROPDOWN_LIST,
  TAGS_EDIT,
  TAGS_MULTI_DELETE,
  TAGS_TABLE,
  TAGS_VIEW,
} from "../types";
import {
  tagsCreateApi,
  tagsDeleteApi,
  tagsDropDownListApi,
  tagsEditApi,
  tagsMultiDeleteApi,
  tagsTableApi,
  tagsViewApi,
} from "../apis/tags-api";

export function* tagsTableWorker(action) {
  try {
    const res = yield call(tagsTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tagsViewWorker(action) {
  try {
    const res = yield call(tagsViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tagsDeleteWorker(action) {
  try {
    const res = yield call(tagsDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tagsMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(tagsMultiDeleteApi, { data: action.data });
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error);
  }
}

export function* tagsCreateWorker(action) {
  try {
    const res = yield call(tagsCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* tagsEditWorker(action) {
  try {
    const res = yield call(tagsEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tagsDropDownListWorker(action) {
  try {
    const res = yield call(tagsDropDownListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchTagsTableWorker() {
  yield takeLatest(TAGS_TABLE, tagsTableWorker);
}
export function* watchTagsViewWorker() {
  yield takeLatest(TAGS_VIEW, tagsViewWorker);
}
export function* watchTagsEditWorker() {
  yield takeLatest(TAGS_EDIT, tagsEditWorker);
}
export function* watchTagsDeleteWorker() {
  yield takeLatest(TAGS_DELETE, tagsDeleteWorker);
}
export function* watchTagsMultiDeleteWorker() {
  yield takeLatest(TAGS_MULTI_DELETE, tagsMultiDeleteWorker);
}
export function* watchTagsCreateWorker() {
  yield takeLatest(TAGS_CREATE, tagsCreateWorker);
}
export function* watchtagsDropDownListWorker() {
  yield takeLatest(TAGS_DROPDOWN_LIST, tagsDropDownListWorker);
}
