import React, { useEffect, useState } from "react";
import styles from "../configuration/configuration.module.css";
import { useDispatch } from "react-redux";
import usePermissions from "../../components/helper-functions/hooks/usePermission-checker";
import {
  themeInstallAction,
  themeUninstallAction,
  themesListAction,
} from "../../redux/actions/plugin-action";
import "./theme.css";
import { SaveButton } from "../../components/ui/buttons/text-button";
import uploadIcon from "../../assets/icons/upload-plugin.svg";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import InstallLoading from "../../components/helper-functions/helper/installing-loader";
import { storeName } from "../../redux/config";
import errorToast from "../../components/helper-functions/helper/error-toast";
import { truncateString } from "../../components/helper-functions/helper/helper";
import NotificationComponent from "../../components/notificationComponent";
import ThemeUploadModal from "../../components/ui/themes/theme-upload-modal";
import applyTheme from "../../assets/icons/themes/applyTheme.svg";
import DeleteIcon from "../../assets/icons/delete-icon";

export default function Themes() {
  const dispatch = useDispatch();
  const { canWrite } = usePermissions();
  const [themes, setThemes] = useState();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [themeloading, setThemeLoading] = useState(false);
  const [themeModalShow, setThemeModalShow] = useState(false);

  console.log(themes, "themes");

  useEffect(() => {
    if (inputValue) {
      dispatch(
        themesListAction({ search: inputValue }, onFetchSuccess, onFetchError)
      );
    }

    themeList();
  }, [inputValue]);

  const themeList = () => {
    dispatch(
      themesListAction({ search: inputValue }, onFetchSuccess, onFetchError)
    );
  };

  useEffect(() => {
    if (reload) {
      dispatch(themesListAction({ search: "" }, onFetchSuccess, onFetchError));
    }
  }, [reload]);

  const onFetchSuccess = (data) => {
    setLoading(false);
    setThemes(data);
  };
  const onFetchError = (err) => {
    console.log(err);
    setLoading(false);
    errorToast(err);
  };

  const themeInstallFn = (item) => {
    dispatch(
      themeInstallAction(
        {
          id: item?.id,
          name: item?.name,
          description: item?.description,
          image_url: item?.image_url,
          url: item?.url,
          original_name: item?.original_name,
          store_name: storeName,
        },
        onSuccess,
        onError
      ),
      setLoading(true),
      setThemeLoading(true)
    );
  };

  const themeUninstallFn = (item) => {
    dispatch(
      themeUninstallAction(
        {
          id: item?.id,
        },
        onDeleteSuccess,
        onDeleteError
      ),
      setLoading(true),
      setThemeLoading(true)
    );
  };

  const onSuccess = (data) => {
    setLoading(false);
    setThemeLoading(false);
    setReload(!reload);
    console.log(data);
  };

  const onError = (data) => {
    setLoading(false);
    setThemeLoading(false);
    errorToast(data.data.message);
  };

  const onDeleteSuccess = (data) => {
    setLoading(false);
    setThemeLoading(false);
    setReload(!reload);
    console.log(data);
  };
  const onDeleteError = (data) => {
    setLoading(false);
    setThemeLoading(false);
    errorToast(data.data.message);
  };
  return (
    <>
      <NotificationComponent />
      {loading ? (
        themeloading ? (
          <InstallLoading label="Updating theme" />
        ) : (
          <LoadingSpinnerTable />
        )
      ) : (
        <div className="mx-3 me-sm-0  ms-sm-4 mt-5 mt-lg-4  col-sm-11 pt-4 mb-4">
          <div className="d-flex align-items-center gap-2">
            <span className="font_montserrat color_030304 fw_500 fs_22 me-4">
              Themes
            </span>
            {canWrite("configuration") && (
              <SaveButton
                onClick={() => setThemeModalShow(true)}
                type="button"
                name="UPLOAD"
                icon={uploadIcon}
              />
            )}
          </div>
          <div
            className={`rounded ${styles.config_content__container} border_1px_e5ecff d-flex flex-wrap mt-4 p-3`}
          >
            <div className="mx-4">
              <div className="d-flex flex-wrap gap-3">
                {themes?.map((item, index) => (
                  <div
                    className={`theme_card border_1px_E6ECFF rounded-2  position-relative `}
                  >
                    {item?.is_installed === true && (
                      <label className="position-absolute bg-white m-2 px-2 py-1 rounded-1 fs_14 fw_500 box-shadow">
                        Current Theme
                      </label>
                    )}
                    <div className="apply-theme-section rounded-1">
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <div>
                          {item?.is_installed === true ? (
                            <label className=" bg-white m-2 px-2 py-1 rounded-1 fs_14 fw_500 box-shadow">
                              Current Theme
                            </label>
                          ) : (
                            <div className="my-4">
                              <button
                                onClick={() => themeInstallFn(item)}
                                type="button"
                                className="text-dark fw_500 apply-btn rounded-1 px-3 py-2 text-uppercase fs_14 d-flex align-items-center justify-content-center"
                              >
                                <img src={applyTheme} className="me-2" /> Apply
                                Theme
                              </button>
                            </div>
                          )}
                          {item?.is_default === false && (
                            <div>
                              <button
                                type="button"
                                onClick={() => themeUninstallFn(item)}
                                className="text-dark apply-btn rounded-1 px-3 py-2 fw_500 text-uppercase fs_14 d-flex align-items-center justify-content-between"
                              >
                                <DeleteIcon fill="red" width="18" height="18" />
                                <label className="ms-2"> remove Theme</label>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2 theme-image-container m-2">
                      {item?.image_url && (
                        <img
                          src={item?.image_url}
                          alt="icon"
                          width={"100%"}
                          height={"100%"}
                        />
                      )}
                    </div>
                    <div className="mx-2">
                      <span className="fs_16 fw_500">{item.name}</span>
                      <div className="fs_12 color_707070">
                        {truncateString(item.description, 85)}
                      </div>
                    </div>
                    {/* <div
                    className={`theme_card border_1px_E6ECFF rounded-2 p-3 position-relative ${styles.remove_theme_container}`}
                  >
                    {item?.is_default === false && (
                      <button
                        className={`${styles.remove_theme_btn_container} w-100 py-3 text-white`}
                      >
                        Remove Theme
                      </button>
                    )}
                    <div className="d-flex align-items-center gap-2">
                      {item?.image_url && (
                        <img
                          src={item?.image_url}
                          alt="icon"
                          width={"150px"}
                          height={"150px"}
                        />
                      )}
                    </div>
                    <span className="fs_17 fw_500">{item.name}</span>
                    <div className="my-1 fs_14 mt-2 color_707070">
                      {truncateString(item.description, 50)}
                    </div>

                    {item?.is_installed === true ? (
                      <label
                        className="px-2 py-1 rounded-2 my-2 fw_500 fs_15"
                        style={{
                          border: "2px solid #030304",
                          backgroundColor: "#2F5CEF",
                          color: "white",
                        }}
                      >
                        Current Theme
                      </label>
                    ) : (
                      <button
                        onClick={() => themeInstallFn(item)}
                        type="button"
                        style={{ border: "2px solid #030304" }}
                        className="px-2 py-1 rounded-2 my-2 fw_500 fs_15"
                      >
                        Use Theme
                      </button>
                    )}
                  </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      <ThemeUploadModal
        show={themeModalShow}
        modalClose={() => setThemeModalShow(false)}
        themeList={themeList}
      />
    </>
  );
}
