import React, { useMemo } from "react";
import SelectField from "./select-field";
import { Country } from "country-state-city";
import DownArrowIcon from "../../../assets/icons/down-arrow-icon";
const CountryCode = () => {
  const countriesCode = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: `${country.phonecode}`,
        value: `${country.phonecode}`,
      })),
    []
  );
  countriesCode.forEach(code => {
    if (!code.label.startsWith('+')) {
      code.label = '+' + code.label;
    }
  });
  return (
    <div style={{width:"5.8rem"}}>
      <SelectField
      containerClass="mb-0"
        defaultValue={countriesCode.find((code) => code.value === "91")}
        // labelsLimit={10}
        options={countriesCode}
        isFormikControlled={true}
        name="country_code"
        errMsgContainerClass="d-none"
        Icon={<DownArrowIcon fill="#2820DA" />}
      />
    </div>
  );
};

export default CountryCode;
