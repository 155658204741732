import React from "react";
import AdairLogo from "../../../assets/icons/adair-logo";

const


  PrintContent = ({ orderDetails, contactInfoData, navbarLogo }) => {

    console.log(navbarLogo, "fsdffsdfsdfsdfsdfdfsfsd");
    const billingDetails = [
      {
        type: "Total Price Value",
        value: `${localStorage?.getItem("currency") ?? "₹ "} ${orderDetails?.total_cost_value?.toFixed(2)}`
      },
      {
        type: "Tax",
        value: `${localStorage?.getItem("currency") ?? "₹ "} ${orderDetails?.total_tax?.toFixed(2)}`
      },
      {
        type: "Shipping Charges",
        value: `${localStorage?.getItem("currency") ?? "₹ "} ${orderDetails?.delivery_charge?.toFixed(2)}`
      },
      {
        type: "Total with Shipping Charges",
        value: `${localStorage?.getItem("currency") ?? "₹ "} ${(orderDetails?.tax_on_delivery_charge)?.toFixed(2)}`
      },
      {
        type: `${orderDetails?.discount ? "Coupon Type" : ""}`,
        value: `${orderDetails?.discount ? orderDetails?.discount?.discount_type : ""}`
      },
      {
        type: `${orderDetails?.discount ? `Coupon(${orderDetails?.discount?.coupon_code})` : ""}`,
        value: `${orderDetails?.discount ? orderDetails?.discount?.coupon_code : ""}`
      },
      {
        type: `${orderDetails?.discount ? "Discount Value" : ""}`,
        value: `${orderDetails?.discount ? `${localStorage?.getItem("currency") ?? "₹ "}` + orderDetails?.discount_amount?.toFixed(2) : ""}`,
      },
      {
        type: `${orderDetails?.discount ? "Total with Discount Value" : ""}`,
        value: `${orderDetails?.discount ? `${localStorage?.getItem("currency") ?? "₹ "}` + orderDetails?.total_value_with_discount_with_delivery?.toFixed(2) : ""}`,
      },
    ]

    return (
      <div >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginLeft: "2rem",
            marginRight: "2rem"
          }}
        >
          {/* <img
          src={navbarLogo}
          className="ps-lg-3 w-100"
          alt="logo"
          width="70px"
          height={"70px"}
        /> */}
          <AdairLogo />
          {/* <span className="" style={{ color: "#030304", fontFamily: "Poppins, sans-serif", fontWeight: "400" }}>
          www.ffecommerce.com
        </span> */}
        </div>
        <hr style={{ marginTop: "10px", color: "#e5ecff" }} />
        <div style={{ marginLeft: "5rem", marginTop: "2rem" }}>
          <div style={{ marginTop: "1rem" }}>
            <table style={{ textAlign: "left", fontSize: "20px" }}>
              <tr>
                <td style={{ color: "#9E9E9E", width: "10rem", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>Order ID</td>
                <td style={{ color: "#030304", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>{orderDetails?.code ?? "--"}</td>
              </tr>
              {/* <tr>
              <td style={{ color: "#9E9E9E", width: "10rem", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>Shipping Date</td>
              <td style={{ color: "#030304", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>
                {orderDetails?.delivery_partner_details?.dispatched_on ?? "--"}
              </td>
            </tr> */}
              <tr>
                <td style={{ color: "#9E9E9E", width: "10rem", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>AWB Number</td>
                <td style={{ color: "#030304", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>
                  {orderDetails?.delivery_partner_details?.awb_number ?? "--"}
                </td >
              </tr>
              <tr>
                <td style={{ color: "#9E9E9E", width: "10rem", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>Payment Status</td>
                <td style={{ color: `${orderDetails?.payment_admin_status === "paid" ? "#3AD64C" : "#FF6F1E"}`, fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>{orderDetails?.payment_admin_status ?? "--"}</td>
              </tr>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginTop: "1.5rem",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <span style={{ color: "#030304", fontSize: "24px", fontWeight: "600", }}>
              Shipping From
            </span>
            <span style={{ color: "#030304", fontSize: "20px", marginTop: "0.5rem", fontWeight: "400" }}>
              {contactInfoData?.company_name}
            </span>
            <span
              style={{
                color: "#030304",
                fontSize: "20px",
                marginTop: "0.5rem",
                width: "20rem",
                fontWeight: "400",
              }}
            >{`${contactInfoData?.company_address?.address_line1
              ? contactInfoData?.company_address?.address_line1 + ","
              : ""
              }\n${contactInfoData?.company_address?.address_line2
                ? contactInfoData?.company_address?.address_line2 + ","
                : ""
              }\n${contactInfoData?.company_address?.address_line3 ?? ""}`}</span>
            <span style={{ color: "#030304", fontSize: "20px", marginTop: "0.5rem", fontWeight: "400", }}>
              {contactInfoData?.phone_number
                ? `+91 ${contactInfoData?.phone_number}`
                : ""}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginTop: "1.5rem",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "400",
            }}
          >
            <span style={{ color: "#030304", fontSize: "24px", fontWeight: "600", }}>
              Shipping To
            </span>
            <span style={{ color: "#030304", fontSize: "20px", marginTop: "0.5rem", fontWeight: "400", }}>
              {orderDetails?.address?.recipient_name}
            </span>
            {
              orderDetails?.delivery_type === "delivery" &&
              <>
                <span
                  style={{
                    color: "#030304",
                    fontSize: "20px",
                    marginTop: "0.5rem",
                    width: "20rem",
                    fontWeight: "400",
                  }}
                >{`${orderDetails?.address?.address_line1
                  ? orderDetails?.address?.address_line1 + ","
                  : ""
                  }\n${orderDetails?.address?.address_line2
                    ? orderDetails?.address?.address_line2 + ","
                    : ""
                  }\n${orderDetails?.address?.city ? orderDetails?.address?.city + "," : ""
                  }`}</span>
                <span
                  style={{
                    color: "#030304",
                    fontSize: "20px",
                    marginTop: "0.5rem",
                    width: "20rem",
                    fontWeight: "400",
                  }}
                >{`${orderDetails?.address?.state ? orderDetails?.address?.state : ""
                  }\n${orderDetails?.address?.pincode
                    ? " - " + orderDetails?.address?.pincode
                    : ""
                  }`}</span>
              </>}

            <span style={{ color: "#030304", fontSize: "20px", marginTop: "0.5rem", fontWeight: "400", }}>
              {orderDetails?.phone_number
                ? `+91 ${orderDetails?.phone_number}`
                : ""}
            </span>
          </div>
          {

            orderDetails?.payment_admin_status === "unpaid" && <div style={{ marginTop: "1.5rem", fontFamily: "Poppins, sans-serif" }}>
              <span style={{ color: "#030304", fontSize: "24px", fontWeight: "600", }}>
                Billing Details
              </span>
              <table style={{ textAlign: "left", fontSize: "20px" }}>
                {
                  billingDetails?.map((item) => {
                    return (
                      <tr>
                        <td style={{ color: "#9E9E9E", width: "20rem", fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>{item?.type}</td>
                        <td style={{ color: `${item?.type === "Discount Value" || item?.type.includes("Coupon(") ? "#3AD64C" : "#030304"}`, fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>{item?.value ?? "--"}</td>
                      </tr>)
                  }
                  )
                }
              </table>
              <div style={{ width: "35rem" }}>
                <hr style={{ marginTop: "10px", color: "#e5ecff" }} />
              </div>

              <table style={{ textAlign: "left", fontSize: "20px" }}>

                <tr>
                  <td style={{ color: "#2820DA", width: "20rem", fontWeight: "600", fontFamily: "Poppins, sans-serif" }}>Total Value Of Order</td>
                  <td style={{ color: "#2820DA", fontWeight: "600", fontFamily: "Poppins, sans-serif" }}>{`${localStorage?.getItem("currency") + " " ?? "₹ "}` + orderDetails?.total_value_with_discount_with_delivery.toFixed(2)}</td>
                </tr>
              </table>
              <div style={{ width: "35rem" }}>
                <hr style={{ marginTop: "10px", color: "#e5ecff" }} />
              </div>
            </div>
          }
        </div>
      </div>
    );
  };

export default PrintContent;
