import {
  ATTRIBUTES_CREATE,
  ATTRIBUTES_DELETE,
  ATTRIBUTES_DROPDOWN_LIST,
  ATTRIBUTES_EDIT,
  ATTRIBUTES_MULTI_DELETE,
  ATTRIBUTES_TABLE,
  ATTRIBUTES_VIEW,
} from "../types";

export const attributesTableAction = (data, onSuccess, onError) => {
  return {
    type: ATTRIBUTES_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const attributesViewAction = (id, onSuccess, onError) => {
  return {
    type: ATTRIBUTES_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const attributesEditAction = (data, onSuccess, onError) => {
  return {
    type: ATTRIBUTES_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const attributesDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ATTRIBUTES_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const attributesMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ATTRIBUTES_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const attributesCreateAction = (data, onSuccess, onError) => {
  return {
    type: ATTRIBUTES_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const attributesDropDownListAction = (onSuccess, onError) => {
  return { type: ATTRIBUTES_DROPDOWN_LIST, onSuccess, onError };
};
