import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import usePermissions from "../../helper-functions/hooks/usePermission-checker";
import { SaveButton } from "../buttons/text-button";
import { useDispatch } from "react-redux";
import backIcon from "../../../assets/icons/back-arrow-button.svg";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";
import LoadingSpinnerTable from "../../helper-functions/helper/loadingSpinner-table";
import uploadIcon from "../../../assets/icons/upload-plugin.svg";
import TableInputFilter from "../tables/table-input-filter";
import {
  installedPluginListAction,
  pluginUninstallAction,
  pluginUpdateAction,
} from "../../../redux/actions/plugin-action";
import "../../../modules/plugin/plugin.css";
import InstallLoading from "../../helper-functions/helper/installing-loader";
const InstalledPlugins = ({ closeSection }) => {
  const dispatch = useDispatch();
  const { canWrite } = usePermissions();
  const [installedPluginList, setInstalledPluginList] = useState();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [installationLoading, setInstallationLoading] = useState(false);
  const [installLabel, setinstallLabel] = useState("Uninstalling")

  useEffect(() => {
    dispatch(
      installedPluginListAction(
        { search: inputValue },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [inputValue]);

  useEffect(() => {
    if (reload) {
      dispatch(
        installedPluginListAction({ search: "" }, onFetchSuccess, onFetchError)
      );
    }
  }, [reload]);

  const onFetchSuccess = (data) => {
    setLoading(false);
    setInstalledPluginList(data);
  };
  const onFetchError = (err) => {
    console.log(err);
  };
  const timeOutSet = () => {
    setLoading(false);
    setReload(!reload);
    window.location.reload()
  };

  const installFn = (item) => {
    dispatch(
      pluginUpdateAction(
        {
          id: item?.id,
          name: item?.update?.name,
          version: item?.update?.version,
          url: item?.update?.url,
          image_url: item?.update?.image_url,
          description: item?.update?.description,
          original_name: item?.update?.original_name,
        },
        onUpdateSuccess,
        onUpdateError
      ),
      setinstallLabel('Updating'),
      setLoading(true),
      setInstallationLoading(true)
    );
  };

  const onUpdateSuccess = (data) => {
    setLoading(true);
    setInstallationLoading(false)
    setTimeout(timeOutSet, 10000);
    successToast(data?.message);
  };

  const onUpdateError = (data) => {
    setLoading(false);
    setInstallationLoading(false)
    errorToast(data?.message);
  };

  const uninstallFn = (item) => {
    dispatch(
      pluginUninstallAction(
        {
          id: item?.id,
        },
        onRemoveSuccess,
        onRemoveError
      ),
      setLoading(true),
      setInstallationLoading(true)
    );
  };

  const onRemoveSuccess = (data) => {
    setLoading(true);
    setInstallationLoading(false);
    successToast(data?.message);
    setTimeout(timeOutSet, 10000);
  };

  const onRemoveError = (data) => {
    setLoading(false);
    setInstallationLoading(false);
    errorToast(data?.data?.message);
  };

  return (
    <div>
      {loading ? (
        installationLoading ? <InstallLoading label={installLabel} /> : <LoadingSpinnerTable />
      ) : (
        <Formik initialValues={{}} enableReinitialize={true}>
          <Form className="">
            <>
              <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                <div
                  className="mb-0 px-xxl-4 d-flex align-items-center"
                  style={{ width: "fit-Content" }}
                >
                  <span>
                    <img
                      className="d-inline-block d-md-none"
                      onClick={closeSection}
                      src={backIcon}
                    />
                  </span>
                  <span className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat ms-2 me-4">
                    Installed Plugins
                  </span>{" "}
                </div>
              </div>
              <div
                className="mt-5 ms-xl-5 me-xl-5 ms-4 me-4"
                style={{ maxWidth: "40rem" }}
              >
                <TableInputFilter
                  placeholder={"Search by plugin name"}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  canWrite={canWrite}
                />
              </div>
            </>
            {installedPluginList?.length > 0 ? (
              <>
                <div className="m-3 row g-0 gap-5 mt-5 ms-xl-5 me-xl-5 ms-4 me-4">
                  {installedPluginList?.map((item, index) => (
                    <React.Fragment key={index}>
                      {!item?.is_latest && item?.update !== null && (
                        <div
                          className="p-3 w-100 rounded-3 mb-3"
                          style={{
                            maxWidth: "350px",
                            border: "1px solid #E5ECFF",
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            {item?.plugin_icon && (
                              <img
                                src={item?.image_url}
                                alt="icon"
                                width={35}
                                height={35}
                              />
                            )}
                            <span className="fs_17 fw_500">{item.name}</span>
                          </div>
                          <div className="my-1 fs_14 mt-2 color_707070">
                            {item.description}
                          </div>
                          <div className="my-2">
                            {" "}
                            <span className="color_707070">Version: </span>
                            {item?.version}{" "}
                          </div>
                          {!item?.is_latest && item?.update !== null && (
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <div className="d-sm-flex align-items-center gap-1">
                                <SaveButton
                                  icon={uploadIcon}
                                  name="UPDATE"
                                  type="submit"
                                  onClick={() => installFn(item)}
                                  iconWidth={14}
                                  saveClassName="px-2"
                                />
                                <span className="color_707070 text-nowrap">
                                  Version:{" "}
                                </span>
                                {item?.update?.version
                                  ? item?.update?.version
                                  : "--"}
                              </div>
                              <button
                                type="button"
                                onClick={() => uninstallFn(item)}
                                style={{ border: "2px solid #030304" }}
                                className="px-2 py-1 rounded-2 my-2 fw_500 fs_15"
                              >
                                REMOVE
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {item?.is_latest && (
                        <div
                          className="p-3 w-100 rounded-3"
                          style={{
                            maxWidth: "350px",
                            border: "1px solid #E5ECFF",
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            {item?.image_url && (
                              <img
                                src={item?.image_url}
                                alt="icon"
                                width={35}
                                height={35}
                              />
                            )}
                            <span className="fs_17 fw_500">{item.name}</span>
                          </div>
                          <div className="my-1 fs_14 mt-2 color_707070">
                            {item.description}
                          </div>
                          <div className="my-2">
                            {" "}
                            <span className="color_707070">Version: </span>
                            {item?.version}{" "}
                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-5">
                            <span className="color_707070 fs_14">
                              Latest Version Installed
                            </span>
                            <button
                              type="button"
                              onClick={() => uninstallFn(item)}
                              style={{ border: "2px solid #030304" }}
                              className="px-2 py-1 rounded-2 my-2 fw_500 fs_15"
                            >
                              REMOVE
                            </button>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </>
            ) : (
              <div
                style={{ minHeight: "25rem" }}
                className="text-center fw_600 fs_20 h-100 d-flex justify-content-center align-items-center"
              >
                No Installed Plugins
              </div>
            )}
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default InstalledPlugins;
