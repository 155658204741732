

import React from "react";

const EnquiryIcon = ({ className, fill = "#2820DA" }) => {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3333 0.25C11.9215 0.451648 12.4567 0.783186 12.8992 1.22L14.2792 2.60167C14.7165 3.04358 15.0484 3.57855 15.25 4.16667H11.3333V0.25ZM9.66667 10H6.33333C6.11232 10 5.90036 10.0878 5.74408 10.2441C5.5878 10.4004 5.5 10.6123 5.5 10.8333C5.5 11.0543 5.5878 11.2663 5.74408 11.4226C5.90036 11.5789 6.11232 11.6667 6.33333 11.6667H9.66667C9.88768 11.6667 10.0996 11.5789 10.2559 11.4226C10.4122 11.2663 10.5 11.0543 10.5 10.8333C10.5 10.6123 10.4122 10.4004 10.2559 10.2441C10.0996 10.0878 9.88768 10 9.66667 10ZM11.3333 5.83333C10.8913 5.83333 10.4674 5.65774 10.1548 5.34518C9.84226 5.03262 9.66667 4.60869 9.66667 4.16667V0H4.66667C3.562 0.00132321 2.50296 0.440735 1.72185 1.22185C0.940735 2.00296 0.501323 3.062 0.5 4.16667V15.8333C0.501323 16.938 0.940735 17.997 1.72185 18.7782C2.50296 19.5593 3.562 19.9987 4.66667 20H11.3333C12.438 19.9987 13.497 19.5593 14.2782 18.7782C15.0593 17.997 15.4987 16.938 15.5 15.8333V5.83333H11.3333ZM11.3333 16.6667H4.66667C4.44565 16.6667 4.23369 16.5789 4.07741 16.4226C3.92113 16.2663 3.83333 16.0543 3.83333 15.8333C3.83333 15.6123 3.92113 15.4004 4.07741 15.2441C4.23369 15.0878 4.44565 15 4.66667 15H11.3333C11.5543 15 11.7663 15.0878 11.9226 15.2441C12.0789 15.4004 12.1667 15.6123 12.1667 15.8333C12.1667 16.0543 12.0789 16.2663 11.9226 16.4226C11.7663 16.5789 11.5543 16.6667 11.3333 16.6667ZM9.66667 13.3333H6.33333C5.67029 13.3333 5.03441 13.0699 4.56557 12.6011C4.09673 12.1323 3.83333 11.4964 3.83333 10.8333C3.83333 10.1703 4.09673 9.53441 4.56557 9.06557C5.03441 8.59672 5.67029 8.33333 6.33333 8.33333H9.66667C10.3297 8.33333 10.9656 8.59672 11.4344 9.06557C11.9033 9.53441 12.1667 10.1703 12.1667 10.8333C12.1667 11.4964 11.9033 12.1323 11.4344 12.6011C10.9656 13.0699 10.3297 13.3333 9.66667 13.3333Z"
                fill={fill} />
        </svg>

    );
};

export default EnquiryIcon;

