import React from "react";

const DeleteIcon = ({ fill = "white", width = "13", height = "14" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8334 2.625C12.8334 2.14175 12.4416 1.75 11.9584 1.75H9.75568C9.38708 0.704293 8.40044 0.00355469 7.29168 0H5.54168C4.43291 0.00355469 3.44627 0.704293 3.07768 1.75H0.875C0.391754 1.75 0 2.14175 0 2.625C0 3.10825 0.391754 3.5 0.875 3.5H1.16668V10.7917C1.16668 12.5636 2.6031 14 4.375 14H8.45832C10.2303 14 11.6667 12.5636 11.6667 10.7917V3.5H11.9584C12.4416 3.5 12.8334 3.10825 12.8334 2.625ZM9.91668 10.7917C9.91668 11.5971 9.26376 12.25 8.45835 12.25H4.375C3.56959 12.25 2.91668 11.5971 2.91668 10.7917V3.5H9.91668V10.7917Z"
        fill={fill}
      />
      <path
        d="M4.95825 10.5C5.4415 10.5 5.83325 10.1082 5.83325 9.625V6.125C5.83325 5.64175 5.4415 5.25 4.95825 5.25C4.47501 5.25 4.08325 5.64175 4.08325 6.125V9.625C4.08325 10.1082 4.47501 10.5 4.95825 10.5Z"
        fill={fill}
      />
      <path
        d="M7.875 10.5C8.35825 10.5 8.75 10.1082 8.75 9.625V6.125C8.75 5.64175 8.35825 5.25 7.875 5.25C7.39175 5.25 7 5.64175 7 6.125V9.625C7 10.1082 7.39175 10.5 7.875 10.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeleteIcon;
