import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../../modals/modal.css";
import { BackButton, SaveButton } from "../../buttons/text-button";
import InputField from "../../forms/input-field";
import RadioButton from "../../forms/radio-button";
import SelectOrderStatus from "../order-status-select";
import { discountTypeOptions, paymentStatus, transacOptions, transactionStatus } from "../../../helper-functions/helper/data";
import SelectField from "../../forms/select-field";
import { useDispatch } from "react-redux";
import { ordersEditAction } from "../../../../redux/actions/orders-action";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";

export default function EditPaymenDetails({ show, modalClose,
  selectedOption, setSelectedOption,
  setTransStatus,
  transStatus,
  orderDetails,
  reload,
  setReload
}) {
  const dispatch = useDispatch()
  const payment = orderDetails?.payment_method
  const [formValues, setFormValues] = useState({})
  const initialValues = {
    payment_type: payment?.main_payment_type === "CashOnDelivery" ? "offline" : "online",
    transaction_id: orderDetails?.pg_transaction_id ?? "",
    transaction_status: orderDetails?.pg_transaction_status === "success" ? "success" : "failed",
    payment_status: orderDetails?.payment_admin_status === "paid" ? "paid" : "unpaid",
  };
  const validationSchema = Yup.object().shape({
    transaction_id: Yup.string().test("testPaymentType", function (value) {
      if (this.parent.payment_type === "online") {
        return value;
      } else {
        return true;
      }
    }),
    transaction_status: Yup.string().test("testPaymentType", "transaction_status is a required field", function (value) {
      if (this.parent.payment_type === "online") {
        return value !== undefined; // Field is required when payment_type is "online"
      }
      return true; // Field is optional for other payment_types
    }),
    // transaction_status: Yup.string().required("Required"),
    payment_status: Yup.string().required("Required")
  });
  const onSubmit = (values) => {

    console.log(values, 'regtetretret');
    if (values?.payment_type === "online") {
      dispatch(
        ordersEditAction(
          {
            "order_id": orderDetails?.id,
            "payment_admin_status": values?.payment_status,
            "pg_transaction_status": values?.transaction_status,
          },
          onEditSuccess,
          onEditError,
        )
      )
    }
    else {
      dispatch(
        ordersEditAction(
          {
            "order_id": orderDetails?.id,
            "payment_admin_status": values?.payment_status
          },
          onEditSuccess,
          onEditError,
        )

      )
    }
  };

  const onEditSuccess = (data) => {
    modalClose()
    setReload(!reload)
    successToast("Order Updated SuccessFully")
  }
  const onEditError = (data) => {
    console.log(data);
    setReload(!reload)
    // errorToast(data?.data?.message)
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="edit_payment_details_model"
        contentClassName="rounded-3"
      // backdrop="static"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue, errors }) => {
            console.log(values, "payment");
            console.log(errors, "payment");
            setFormValues(values)
            return (
              <Form>
                <ModalHeader>
                  <div className="ms-2">
                    <img onClick={modalClose} src={BackIcon} />
                    <span className="ms-2 fs_16 fw_500">
                      Edit Payment Details
                    </span>
                  </div>
                  <div className="d-flex gap-3 me-5">
                    <BackButton
                      // disabled={valuesCompareFn(values)}
                      type="reset"
                      name="UNDO"
                      backClassName="fs_14"
                    />

                    <SaveButton
                      // disabled={valuesCompareFn(values)}
                      type="submit"
                      backClassName="fs_14"
                    //   onClick={saveFn}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="px-5">
                    <label className="fs_14 color_9E9E9E">Payment ID</label>
                    <div className="fs_16 color_030304 fw_500">{payment?.id}</div>
                    <label className="fs_14 color_9E9E9E mt-3">
                      Payment Type
                    </label>
                    <RadioButton
                      disabled={true}
                      inputLabelClass="fw_400 fs_14"
                      labelClass="color_030304 d-flex fs_14 mb-1 fw_500"
                      name="payment_type"
                      options={paymentType}
                      label={false}
                    />
                    {values?.payment_type === "online" && (
                      <>
                        <InputField
                          isDisabled={true}
                          containerClass="mt-3"
                          id={"transaction_id"}
                          label={"Transaction ID"}
                          placeholder={"Enter Transaction ID"}
                          name={"transaction_id"}
                        />
                        <SelectField
                          dynColor={values?.transaction_status === "success" ? "#04BA71" : values?.transaction_status === "failed" && "#F24444"}
                          placeholder={"Select Transaction Status"}
                          label={"Transaction Status "}
                          id={"transaction_status"}
                          name="transaction_status"
                          options={transacOptions}
                          isFormikControlled={true}
                          errMsgContainerClass="ps-2"
                        />
                      </>
                    )}

                    <div className="mt-3">
                      <SelectOrderStatus
                        label="Payment Status"
                        options={paymentStatus}
                        selectClass={'col-12'}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        isFormik={true}
                        name="payment_status"
                        formValues={values}
                      />
                    </div>
                    <div className="mb-3">

                    </div>
                  </div>
                </ModalBody>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
export const paymentType = [
  {
    label: "Online",
    value: "online",
  },
  {
    label: "Offline",
    value: "offline",
  },
];
