import React from "react";
import arrowLeftIcon from "../../../assets/icons/arrow-right-icon.svg";

const ConfigSection = ({ item, onClick }) => {
  return (
    <div
      onClick={onClick && onClick}
      style={{ maxWidth: "590px" }}
      className="border_1px_e5ecff col-lg-6 rounded-2 d-flex justify-content-between px-3 align-items-center py-2 mt-2 w-100 cursor_pointer"
    >
      <div>
        <p className="mb-0 color_030304 fw_500 fs_sm_14">{item.title}</p>
        <p className="mb-0 fs_14 fs_sm_12 color_707070 fw_400">
          {item.subHeading}
        </p>
      </div>
      <img src={arrowLeftIcon} />
    </div>
  );
};

export default ConfigSection;
