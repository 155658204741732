import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  categoriesDeleteAction,
  categoriesEditAction,
  categoriesViewAction,
} from "../../redux/actions/categories-action";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import UseDangerouslyInnerHtml from "../../components/helper-functions/hooks/use-dangerously-inner-html";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import NotificationComponent from "../../components/notificationComponent";
const CategoriesView = () => {
  const params = useParams();
  const [categoryDetails, setCategoryDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(true);
  const [activeStatus, setActiveStatus] = useState();
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(categoriesViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setCategoryDetails(data?.data);
  };
  const onError = (data) => { };
  useEffect(() => {
    setActiveStatus(categoryDetails?.status);
  }, [categoryDetails]);
  const categoryFindOne = [
    {
      label: "Image",
      value: (
        <>
          {isImgLoading && (
            <div
              className="d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
              style={{ width: "250px", height: "250px" }}
            >
              <LoadingSpinner size="lg" />
            </div>
          )}
          <div
            className={`${imageRegex.test(categoryDetails?.category_image) === false &&
              "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
              }`}
            style={{ width: "250px", height: !isImgLoading && "250px" }}
          >
            <img
              className="rounded-3 mt-1"
              src={
                imageRegex.test(categoryDetails?.category_image)
                  ? categoryDetails?.category_image
                  : UplaodImgLogo
              }
              width={
                imageRegex.test(categoryDetails?.category_image)
                  ? "250px"
                  : "100"
              }
              height={
                imageRegex.test(categoryDetails?.category_image)
                  ? "250px"
                  : "100"
              }
              alt="image"
              onLoad={() => setIsImgLoading(false)}
              style={{ display: isImgLoading ? "none" : "block" }}
            />
          </div>
        </>
      ),
    },
    {
      label: "Name",
      value: categoryDetails?.category_name,
    },
    {
      label: "Description",
      value: categoryDetails?.description ? (
        <UseDangerouslyInnerHtml description={categoryDetails?.description} />
      ) : (
        "--"
      ),
    },
    {
      label: "Slug",
      value: categoryDetails?.slug,
    },
    {
      label: "Is parent category?",
      value: categoryDetails?.is_parent_category === true ? "Yes" : "No",
    },
    {
      label: "Parent Category",
      value: categoryDetails?.Parentname?.category_name ?? "--",
    },
    localStorage.getItem("brand") === "true" &&
    {
      label: "Brands",
      value: categoryDetails?.brands?.length > 0 ? categoryDetails?.brands?.map((item, index) => item?.name).join(" | ") : "--"
    },
  ];
  const handleChangeStatus = ()=>{
    setActiveStatus(!activeStatus);
    dispatch(
      categoriesEditAction(
        {
          category_id: params.id,
          category_image: categoryDetails?.category_image,
          category_name: categoryDetails?.category_name,
          description: categoryDetails?.description,
          slug: categoryDetails?.slug,
          is_parent_category: categoryDetails?.is_parent_category,
          parent_category_id: categoryDetails?.parent_category_id,
          brand_id: categoryDetails?.brand_id,
          status: !activeStatus
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  }
  const onSuccessEdit = (data)=>{
    successToast(data?.message);
  };
  const onErrorEdit = (data)=>{
    errorToast(data?.data?.message);
  };
  return (
    <>
     <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          <InnerLayout
            type="category"
            id={categoryDetails?.id}
            deleteAction={categoriesDeleteAction}
            path={`/categories`}
            editButtonPath={`/categories/edit/${categoryDetails?.id}`}
            rolePermission={"categories"}
            name={categoryDetails?.code}
            handleStatusBtn={handleChangeStatus}
            status={categoryDetails?.status}
            activeStatus={activeStatus}
          />

          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            {categoryFindOne.map((item, index) => {
              return (
                <div key={index} className="mb-3">
                  <label className="color_707070 fs_14">{item.label}</label>
                  <div className="color_030304 fs_14 mb-1">{item?.value}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default CategoriesView;
