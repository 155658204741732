import React, { useEffect, useState } from "react";
import backIcon from "../../assets/icons/back-arrow-button.svg";
import styles from "./configuration.module.css";
import ConfigPages from "../../components/ui/configurations/pages/config-pages";
import ShippingSettings from "../../components/ui/configurations/shipping-settings/shipping-settings";
import SiteSettings from "../../components/ui/configurations/site-settings";
import MailNotification from "../../components/ui/configurations/mail-notification/mail-notification";
import Tax from "../../components/ui/configurations/tax/tax";
import PaymentSettings from "../../components/ui/configurations/payment/payment-settings";
import useScreenSize from "../../components/helper-functions/hooks/useScreenSize";
import arrowLeftIcon from "../../assets/icons/arrow-right-icon.svg";
import { useDispatch } from "react-redux";
import ContactInfo from "../../components/ui/configurations/contact-info/contact-info";
import HomeSetting from "../../components/ui/configurations/pages/home-setting";
import FaqSection from "../../components/ui/configurations/faqs/faq-section";
import usePermissions from "../../components/helper-functions/hooks/usePermission-checker";
import { useNavigate } from "react-router";
import NotificationComponent from "../../components/notificationComponent";
export default function Configuration() {
  const { width } = useScreenSize();
  const [activeConfig, setActiveConfig] = useState(
    localStorage.getItem("config_section_status")
  );
  const configNav = width > 768 ? true : !activeConfig;
  const configContent = width > 768 ? true : activeConfig;
  const { canWrite, canRead } = usePermissions();
  function handleCloseSection() {
    setActiveConfig(null);
    localStorage.setItem("config_section_status", "");
  }
  // const dispatch = useDispatch()
  // const [viewData, setViewData] = useState();

  // useEffect(() => {
  //   dispatch(shippingViewAction(onViewSuccess, onViewError));
  // }, []);
  // const onViewSuccess = (data) => {
  //   console.log(data, "data");
  //   setViewData(data?.data);
  // };
  // const onViewError = (data) => { };

  function renderConfig(state) {
    switch (state) {
      case "Pages":
        return <ConfigPages closeSection={handleCloseSection} />;
      case "Tax":
        return <Tax name={activeConfig} closeSection={handleCloseSection} />;
      case "Shipping Setting":
        return (
          <ShippingSettings
            name={activeConfig}
            closeSection={handleCloseSection}
          />
        );
      case "Payment":
        return <PaymentSettings closeSection={handleCloseSection} />;
      case "Mail Notification":
        return (
          <MailNotification
            closeSection={handleCloseSection}
            name={activeConfig}
          />
        );
      case "Site Settings":
        return (
          <SiteSettings closeSection={handleCloseSection} name={activeConfig} />
        );
      case "Home Setting":
        return <HomeSetting closeSection={handleCloseSection} />;
      case "Contact Info":
        return (
          <ContactInfo name={activeConfig} closeSection={handleCloseSection} />
        );
      case "FAQs":
        return (
          <FaqSection name={activeConfig} closeSection={handleCloseSection} />
        );
      default:
        return (
          <ConfigPages name={activeConfig} closeSection={handleCloseSection} />
        );
    }
  }
  useEffect(() => {
    if (width < 720) {
      !activeConfig && setActiveConfig(null);
    } else {
      const configSection = localStorage.getItem("config_section_status");
      setActiveConfig(configSection ? configSection : "Pages");
    }
  }, [width]);

  const navigate = useNavigate();
  return (
    <>
      <NotificationComponent />
      {canWrite("configuration") ? (
        <div className="mx-3 me-sm-0  ms-sm-4 mt-5 mt-lg-4  col-sm-11 pt-4 mb-4">
          <div className="d-flex align-items-center gap-2">
            <span className="font_montserrat color_030304 fw_500 fs_22">
              Configuration
            </span>
          </div>
          <div
            className={`rounded ${styles.config_content__container} border_1px_e5ecff d-flex flex-wrap mt-4`}
          >
            {configNav && (
              <div className={`${styles.config_nav__container} p-4`}>
                <p className="color_444444 fw_500 fs_14 mt-2 d-none d-md-block">
                  Configuration
                </p>
                <ul
                  style={{ top: "5rem" }}
                  className="d-flex flex-column gap-3 gap-md-2 ps-0 position-sticky"
                >
                  {configList.map((item) => {
                    return (
                      <li
                        key={item}
                        onClick={() => {
                          setActiveConfig(item);
                          localStorage.setItem("config_section_status", item);
                        }}
                        className={`cursor_pointer list-unstyled d-flex d-md-block justify-content-between  px-3 py-3 py-md-1  border_radius_5px ${
                          styles.config_list__item
                        }
                  ${
                    activeConfig === item
                      ? "bg_color_E5ECFFB2 color_030304 fw_500"
                      : "color_707070"
                  }`}
                      >
                        {item}
                        <img
                          className="d-inline-block d-md-none"
                          src={arrowLeftIcon}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {configContent && (
              <div className={`${styles.config_section__container}`}>
                {renderConfig(activeConfig)}
              </div>
            )}
          </div>
        </div>
      ) : (
        navigate("/")
      )}
    </>
  );
}

const configList = [
  "Pages",
  "Tax",
  "Shipping Setting",
  "Payment",
  "Mail Notification",
  "Site Settings",
  "Home Setting",
  "Contact Info",
  "FAQs",
];
