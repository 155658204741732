import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { faqDeleteAction, faqMultiDeleteAction, faqTableAction } from '../../../../redux/actions/faq-action';
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import CreateButton from '../../buttons/text-button';
import DynamicTable from '../../tables/table';
import UseDangerouslyInnerHtml from '../../../helper-functions/hooks/use-dangerously-inner-html';

function FaqTable({ closeSection, tab, setTab, setId, currentPage, setCurrentPage }) {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);
    const [faqs, setFaqs] = useState([])
    const location = useLocation()
    const [inputValue, setInputValue] = useState("");
    const [pagination, setPagination] = useState();
    const [reload, setReload] = useState(false);
    const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
    useEffect(() => {
        setLoading(true);
        dispatch(
            faqTableAction(
                {
                    page: currentPage,
                    size: 10,
                    search: inputValue,
                    sort: sortConfig
                },
                onSuccess,
                onError
            )
        );
    }, [currentPage, reload, inputValue, sortConfig]);
    const onSuccess = (data) => {
        console.log(data, "djfhdsjfhsjfhjfhf");
        setFaqs(data?.data?.faqs);
        setPagination(data?.data?.pagination);
        setLoading(false);
        // setError(false);
    };
    const onError = (data) => {
        setLoading(false);
    };
    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.slice(0, maxLength) + '...';
        }
    }
    const tableData = faqs.map((item) => ({
        id: item?.id,
        code: item?.faq_code ?? item?.id,
        faq_question: item?.question,
        faq_answer: (<div className='pt-3'><UseDangerouslyInnerHtml description={truncateString(item?.answer, 30)} /></div>),
        Action: ["/categories/view/", "/categories/edit/"],
    }));

    function faqViewFn(id) {
        setTab("view")
        setId(id)
    }
    function faqEditFn(id) {
        setTab("edit")
        setId(id)
    }
    return (
        <>
            <div
                style={{ padding: ".95rem 1rem" }}
                className="col-12  d-flex align-items-center justify-content-between px-md-5   border_bottom_1px_e5ecff"
            >
                <>
                    <div className="d-flex gap-2">
                        <img
                            className="d-inline-block d-md-none me-2"
                            onClick={closeSection}
                            src={backIcon}
                        />{" "}
                        <h1 className="fs_22 fw_600 font_montserrat mb-0 ">{"FAQs"}</h1>
                    </div>
                </>
                <CreateButton
                    createFn={() => setTab("create")}
                    createClassName={"px-lg-3 px-3 fs_15 fs_md_12 fw_500 text-nowrap"}
                    name={"CREATE FAQ"}
                />
            </div>
            <div className=" col-lg-12 col-12 px-lg-5 px-2 ps-lg-4  mb-5 ">
                <DynamicTable
                    placeholder={"Search category here by name, id"}
                    modalText={'category'}
                    tableHeading={tableFaqHeader}
                    tablebody={tableData}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    createButtonName="CREATE CATEGORY"
                    createpath="/categories/create"
                    pagination={pagination}
                    setCurrentPage={setCurrentPage}
                    backBtnDisplay={false}
                    showTableTitle={false}
                    singleDeleteAction={faqDeleteAction}
                    multiDeleteAction={faqMultiDeleteAction}
                    loading={loading}
                    createButton={false}
                    setLoading={setLoading}
                    id_name={"faq_id"}
                    setReload={setReload}
                    reload={reload}
                    rolePermission={"configuration"}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    faq={true}
                    faqViewFn={faqViewFn}
                    faqEditFn={faqEditFn}
                />
            </div>
        </>
    )
}
export default FaqTable
const tableFaqHeader = [
    { label: "ID", value: "code" },
    { label: "FAQs Question", value: "faq_question" },
    { label: "FAQs Answers", value: "faq_answer" },
    { label: "Actions", value: "Actions" },
];