import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  tagsDeleteAction,
  tagsViewAction,
} from "../../redux/actions/tags-action";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import NotificationComponent from "../../components/notificationComponent";
const TagsView = () => {
  const params = useParams();
  const [tagsDetails, setTagsDetails] = useState();
  const [isImgLoading, setIsImgLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tagsViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setTagsDetails(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const tagsFindOne = [
    {
      label: "Image",
      value: (
        <>
          {isImgLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "250px", height: "250px" }}
            >
              <LoadingSpinner size="lg" />
            </div>
          )}
          <div
            className={`${
              imageRegex.test(tagsDetails?.tag_picture) === false &&
              "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
            }`}
            style={{ width: "250px", height: !isImgLoading && "250px" }}
          >
            <img
              className="rounded-3 mt-1"
              src={
                imageRegex.test(tagsDetails?.tag_picture)
                  ? tagsDetails?.tag_picture
                  : UplaodImgLogo
              }
              width={
                imageRegex.test(tagsDetails?.tag_picture) ? "250px" : "100"
              }
              height={
                imageRegex.test(tagsDetails?.tag_picture) ? "250px" : "100"
              }
              alt="image"
              onLoad={() => setIsImgLoading(false)}
              style={{ display: isImgLoading ? "none" : "block" }}
            />
          </div>
        </>
      ),
    },
    {
      label: "Tag Name",
      value: tagsDetails?.name,
    },
    {
      label: "Slug",
      value: tagsDetails?.slug,
    },
  ];
  return (
    <>
    <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          <InnerLayout
            name={`${tagsDetails?.code ?? "Tags View"}`}
            id={tagsDetails?.id}
            deleteAction={tagsDeleteAction}
            path={`/tags`}
            editButtonPath={`/tags/edit/${tagsDetails?.id}`}
            rolePermission={"tags"}
            type="tags"
          />

          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            {tagsFindOne.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <label className="color_707070 fs_14">{item.label}</label>
                  <div className="color_030304 fs_14 mb-1">{item.value}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default TagsView;
