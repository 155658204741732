import React from "react";
const DeleteIconWithBg = ({
  fill = "#030304",
  bgFill = "#E6ECFF",
  width = "42",
  height = "42",
  onClick,
  className = "",
  customStyles = {},
}) => {
  return (
    <svg
      style={{ ...customStyles }}
      className={className}
      onClick={onClick && onClick}
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H37C39.7614 0 42 2.23858 42 5V37C42 39.7614 39.7614 42 37 42H5C2.23858 42 0 39.7614 0 37V5Z"
        fill={bgFill}
        // fillOpacity="0.5"
      />
      <g clipPath="url(#clip0_1713_25938)">
        <path
          d="M30 13H26.9C26.6679 11.8714 26.0538 10.8573 25.1613 10.1287C24.2687 9.40009 23.1522 9.00145 22 9L20 9C18.8478 9.00145 17.7313 9.40009 16.8387 10.1287C15.9462 10.8573 15.3321 11.8714 15.1 13H12C11.7348 13 11.4804 13.1054 11.2929 13.2929C11.1054 13.4804 11 13.7348 11 14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15H13V28C13.0016 29.3256 13.5289 30.5964 14.4662 31.5338C15.4036 32.4711 16.6744 32.9984 18 33H24C25.3256 32.9984 26.5964 32.4711 27.5338 31.5338C28.4711 30.5964 28.9984 29.3256 29 28V15H30C30.2652 15 30.5196 14.8946 30.7071 14.7071C30.8946 14.5196 31 14.2652 31 14C31 13.7348 30.8946 13.4804 30.7071 13.2929C30.5196 13.1054 30.2652 13 30 13ZM20 11H22C22.6203 11.0008 23.2251 11.1934 23.7316 11.5514C24.2381 11.9095 24.6214 12.4155 24.829 13H17.171C17.3786 12.4155 17.7619 11.9095 18.2684 11.5514C18.7749 11.1934 19.3797 11.0008 20 11ZM27 28C27 28.7956 26.6839 29.5587 26.1213 30.1213C25.5587 30.6839 24.7957 31 24 31H18C17.2044 31 16.4413 30.6839 15.8787 30.1213C15.3161 29.5587 15 28.7956 15 28V15H27V28Z"
          fill={fill}
        />
        <path
          d="M19 27C19.2652 27 19.5196 26.8946 19.7071 26.7071C19.8946 26.5196 20 26.2652 20 26V20C20 19.7348 19.8946 19.4804 19.7071 19.2929C19.5196 19.1054 19.2652 19 19 19C18.7348 19 18.4804 19.1054 18.2929 19.2929C18.1054 19.4804 18 19.7348 18 20V26C18 26.2652 18.1054 26.5196 18.2929 26.7071C18.4804 26.8946 18.7348 27 19 27Z"
          fill={fill}
        />
        <path
          d="M23 27C23.2652 27 23.5196 26.8946 23.7071 26.7071C23.8946 26.5196 24 26.2652 24 26V20C24 19.7348 23.8946 19.4804 23.7071 19.2929C23.5196 19.1054 23.2652 19 23 19C22.7348 19 22.4804 19.1054 22.2929 19.2929C22.1054 19.4804 22 19.7348 22 20V26C22 26.2652 22.1054 26.5196 22.2929 26.7071C22.4804 26.8946 22.7348 27 23 27Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1713_25938">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DeleteIconWithBg;
