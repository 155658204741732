export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SITE_SETTINGS = "SITE_SETTINGS";
export const STORE_ACTIVE = "STORE_ACTIVE";
export const LOGGED_ADMIN_USER_EDIT = "LOGGED_ADMIN_USER_EDIT";

export const SITE_SETTING_DATA_FAILED = "SITE_SETTING_DATA_FAILED";
export const SITE_SETTING_DATA_LOADED = "SITE_SETTING_DATA_LOADED";
export const SITE_SETTING_DATA_LOADING = "SITE_SETTING_DATA_LOADING";
export const SITE_VIEW_WITHOUT_ACCESS = "SITE_VIEW_WITHOUT_ACCESS";

export const LOGGED_ADMIN_DATA_LOADING = "LOGGED_ADMIN_DATA_LOADING";
export const LOGGED_ADMIN_DATA_FAILED = "LOGGED_ADMIN_DATA_FAILED";
export const LOGGED_ADMIN_DATA_LOADED = "LOGGED_ADMIN_DATA_LOADED";
// CATEGORIES TYPES
export const CATEGORIES_TABLE = "CATEGORIES_TABLE";
export const CATEGORIES_VIEW = "CATEGORIES_VIEW";
export const CATEGORIES_EDIT = "CATEGORIES_EDIT";
export const CATEGORIES_DELETE = "CATEGORIES_DELETE";
export const CATEGORIES_MULTI_DELETE = "CATEGORIES_MULTI_DELETE";
export const CATEGORIES_CREATE = "CATEGORIES_CREATE";
export const CATEGORIES_IMAGE_UPLOAD = "CATEGORIES_IMAGE_UPLOAD";
export const CONFIGURATION_IMAGE_UPLOAD = "CONFIGURATION_IMAGE_UPLOAD";
export const CANCEL_CATEGORIES_IMAGE_UPLOAD = "CANCEL_CATEGORIES_IMAGE_UPLOAD";
export const CATEGORIES_DROPDOWN_UPLOAD = "CATEGORIES_DROPDOWN_UPLOAD";

// CUSTOMER TYPES
export const CUSTOMER_TABLE = "CUSTOMER_TABLE";
export const CUSTOMER_CREATE = "CUSTOMER_CREATE";
export const CUSTOMER_VIEW = "CUSTOMER_VIEW";
export const CUSTOMER_EDIT = "CUSTOMER_EDIT";
export const CUSTOMER_MULTI_DELETE = "CUSTOMER_MULTI_DELETE";
export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_BLOCK = "CUSTOMER_BLOCK";
export const CUSTOMER_RESETPSW = "CUSTOMER_RESETPSW";
export const CUSTOMER_SEND_PASSWORD = "CUSTOMER_SEND_PASSWORD";
//ADMIN USER TYPES
export const ADMIN_USERS_TABLE = "ADMIN_USERS_TABLE";
export const ADMIN_USERS_VIEW = "ADMIN_USERS_VIEW";
export const ADMIN_USERS_EDIT = "ADMIN_USERS_EDIT";
export const ADMIN_USERS_CREATE = "ADMIN_USERS_CREATE";
export const ADMIN_USERS_DELETE = "ADMIN_USERS_DELETE";
export const ADMIN_USERS_UPLOAD_IMAGE = "ADMIN_USERS_UPLOAD_IMAGE";
export const ADMIN_USERS_RESET_PASSWORD = "ADMIN_USERS_RESET_PASSWORD";
export const ADMIN_USERS_MULTI_DELETE = "ADMIN_USERS_MULTI_DELETE";
export const ADMIN_USER_DETAILS = "ADMIN_USER_DETAILS";
export const ADMIN_USERS_SEND_PASSWORD = "ADMIN_USERS_SEND_PASSWORD";

//ADMIN ROLE TYPES
export const ADMIN_ROLE_TABLE = "ADMIN_ROLE_TABLE";
export const ADMIN_ROLE_VIEW = "ADMIN_ROLE_VIEW";
export const ADMIN_ROLE_EDIT = "ADMIN_ROLE_EDIT";
export const ADMIN_ROLE_CREATE = "ADMIN_ROLE_CREATE";
export const ADMIN_ROLE_DELETE = "ADMIN_ROLE_DELETE";
export const ADMIN_ROLE_MULTI_DELETE = "ADMIN_ROLE_MULTI_DELETE";
export const ADMIN_ROLE_LIST = "ADMIN_ROLE_LIST";
export const LOGGED_ADMIN_PERMISSION = "LOGGED_ADMIN_PERMISSION";

// CATEGORIES TYPES
export const ATTRIBUTES_TABLE = "ATTRIBUTES_TABLE";
export const ATTRIBUTES_VIEW = "ATTRIBUTES_VIEW";
export const ATTRIBUTES_EDIT = "ATTRIBUTES_EDIT";
export const ATTRIBUTES_DELETE = "ATTRIBUTES_DELETE";
export const ATTRIBUTES_MULTI_DELETE = "ATTRIBUTES_MULTI_DELETE";
export const ATTRIBUTES_CREATE = "ATTRIBUTES_CREATE";
export const ATTRIBUTES_DROPDOWN_LIST = "ATTRIBUTES_DROPDOWN_LIST";

// TAGS TYPES
export const TAGS_TABLE = "TAGS_TABLE";
export const TAGS_VIEW = "TAGS_VIEW";
export const TAGS_EDIT = "TAGS_EDIT";
export const TAGS_DELETE = "TAGS_DELETE";
export const TAGS_MULTI_DELETE = "TAGS_MULTI_DELETE";
export const TAGS_CREATE = "TAGS_CREATE";
export const TAGS_DROPDOWN_LIST = "TAGS_DROPDOWN_LIST";

// DELIVERY PARTNER TYPES
export const DELIVERYPARTNER_CREATE = "DELIVERYPARTNER_CREATE";
export const DELIVERYPARTNER_DELETE = "DELIVERYPARTNER_DELETE";
// export const TAGS_DROPDOWN_LIST = "TAGS_DROPDOWN_LIST"
export const DELIVERYPARTNER_EDIT = "DELIVERYPARTNER_EDIT";
export const DELIVERYPARTNER_MULTI_DELETE = "DELIVERYPARTNER_MULTI_DELETE";
export const DELIVERYPARTNER_TABLE = "DELIVERYPARTNER_TABLE";
export const DELIVERYPARTNER_VIEW = "DELIVERYPARTNER_VIEW";
export const DELIVERY_BLOCK = "DELIVERY_BLOCK";

// BRANDS TYPES
export const BRANDS_CREATE = "BRANDS_CREATE";
export const BRANDS_DELETE = "BRANDS_DELETE";
export const BRANDS_DROPDOWN_LIST = "BRANDS_DROPDOWN_LIST";
export const BRANDS_EDIT = "BRANDS_EDIT";
export const BRANDS_MULTI_DELETE = "BRANDS_MULTI_DELETE";
export const BRANDS_TABLE = "BRANDS_TABLE";
export const BRANDS_VIEW = "BRANDS_VIEW";

// DISCOUNT TYPES
export const DISCOUNT_TABLE = "DISCOUNT_TABLE";
export const DISCOUNT_DELETE = "DISCOUNT_DELETE";
export const DISCOUNT_MULTI_DELETE = "DISCOUNT_MULTI_DELETE";
export const DISCOUNT_VIEW = "DISCOUNT_VIEW";
export const DISCOUNT_EDIT = "DISCOUNT_EDIT";
export const DISCOUNT_CREATE = "DISCOUNT_CREATE";
// ADDRESS TYPES
export const ADDRESS_TABLE = "ADDRESS_TABLE";
export const ADDRESS_VIEW = "ADDRESS_VIEW";
export const ADDRESS_EDIT = "ADDRESS_EDIT";
export const ADDRESS_DELETE = "ADDRESS_DELETE";
export const ADDRESS_MULTI_DELETE = "ADDRESS_MULTI_DELETE";
export const ADDRESS_CREATE = "ADDRESS_CREATE";
export const CUSTOMER_LIST_DROPDOWN = "CUSTOMER_LIST_DROPDOWN";

// CATEGORIES TYPES
export const PRODUCTS_TABLE = "PRODUCTS_TABLE";
export const PRODUCTS_VIEW = "PRODUCTS_VIEW";
export const PRODUCTS_EDIT = "PRODUCTS_EDIT";
export const PRODUCTS_DELETE = "PRODUCTS_DELETE";
export const PRODUCTS_MULTI_DELETE = "PRODUCTS_MULTI_DELETE";
export const PRODUCTS_CREATE = "PRODUCTS_CREATE";
export const ALL_PRODUCTS_ID = "ALL_PRODUCTS_ID";
export const UPDATE_PRODUCTS_STATUS = "UPDATE_PRODUCTS_STATUS";
export const UPDATE_PRODUCTS_STOCK_STATUS = "UPDATE_PRODUCTS_STOCK_STATUS";

//Error
export const SERVER_ERROR = "SERVER_ERROR";
export const SERVER_WORKING = "SERVER_WORKING";

// ORDERS TYPES
export const ORDERS_TABLE = "ORDERS_TABLE";
export const ORDERS_VIEW = "ORDERS_VIEW";
export const ORDERS_EDIT = "ORDERS_EDIT";
export const ORDERS_CREATE = "ORDERS_CREATE";
export const BILLING_GENERATE = "BILLING_GENERATE";
export const ADDRESS_FETCH = "ADDRESS_FETCH";
export const ORDERS_REJECT = "ORDERS_REJECT";
export const ORDERS_PARTIAL_REJECT = "ORDERS_PARTIAL_REJECT";
export const ORDERS_Billing_Edit = "ORDERS_Billing_Edit";
export const ORDER_DELIVERY_PARTNERS = "ORDER_DELIVERY_PARTNERS";
export const ORDERS_RETURN_Edit = "ORDERS_RETURN_Edit";
export const ORDERS_SERVICE = "ORDERS_SERVICE";
export const ORDERS_ASSIGN = "ORDERS_ASSIGN";

// CONFIGURATION TYPES
export const TAX_CREATE = "TAX_CREATE";
export const TAX_VIEW = "TAX_VIEW";
export const TAX_EDIT = "TAX_EDIT";
export const MAIL_NOTIFY_CREATE = "MAIL_NOTIFY_CREATE";
export const MAIL_NOTIFY_VIEW = "MAIL_NOTIFY_VIEW";
export const MAIL_NOTIFY_EDIT = "MAIL_NOTIFY_EDIT";
export const SITE_CREATE = "SITE_CREATE";
export const SITE_VIEW = "SITE_VIEW";
export const SITE_EDIT = "SITE_EDIT";
export const CONFIG_PAGE_CREATE = "CONFIG_PAGE_CREATE";
export const CONFIG_PAGE_EDIT = "CONFIG_PAGE_EDIT";
export const CONFIG_PAGE_DELETE = "CONFIG_PAGE_DELETE";
export const CONFIG_PAGE_VIEW = "CONFIG_PAGE_VIEW";
export const CONFIG_PAGE_LIST = "CONFIG_PAGE_LIST";
export const PAYMENTS_CREATE = "PAYMENTS_CREATE";
export const PAYMENTS_VIEW = "PAYMENTS_VIEW";
export const PAYMENTS_EDIT = "PAYMENTS_EDIT";

export const SHIPPING_CREATE = "SHIPPING_CREATE";
export const SHIPPING_VIEW = "SHIPPING_VIEW";
export const CONTACT_INFO_CREATE_UPDATE = "CONTACT_INFO_CREATE_UPDATE";

export const HOME_SETTINGS_CREATE = "HOME_SETTINGS_CREATE";
export const HOME_SETTINGS_VIEW = "HOME_SETTINGS_VIEW";
export const CONTACT_INFO_VIEW = "CONTACT_INFO_VIEW";
export const DASHBOARD_DATA = "DASHBOARD_DATA";

// CATEGORIES TYPES
export const FAQ_TABLE = "FAQ_TABLE";
export const FAQ_VIEW = "FAQ_VIEW";
export const FAQ_EDIT = "FAQ_EDIT";
export const FAQ_DELETE = "FAQ_DELETE";
export const FAQ_MULTI_DELETE = "FAQ_MULTI_DELETE";
export const FAQ_CREATE = "FAQ_CREATE";

// CONTACT ENQUIRY TYPES
export const CONTACT_ENQUIRY_TABLE = "CONTACT_ENQUIRY_TABLE";
export const CONTACT_ENQUIRY_VIEW = "CONTACT_ENQUIRY_VIEW";

// PRODUCT ENQUIRY TYPES
export const PRODUCT_ENQUIRY_TABLE = "PRODUCT_ENQUIRY_TABLE";
export const PRODUCT_ENQUIRY_VIEW = "PRODUCT_ENQUIRY_VIEW";

//PLUGINS
export const PLUGIN_LIST = "PLUGIN_LIST";
export const INSTALLED_PLUGIN_LIST = "INSTALLED_PLUGIN_LIST";
export const PLUGIN_CREATE = "PLUGIN_CREATE";
export const PLUGIN_UPDATE = "PLUGIN_UPDATE";
export const PLUGIN_INSTALL = "PLUGIN_INSTALL";
export const THEME_INSTALL = "THEME_INSTALL";
export const THEME_UNINSTALL = "THEME_UNINSTALL";
export const PLUGIN_UNINSTALL = "PLUGIN_UNINSTALL";
export const PLUGIN_UPLOAD = "PLUGIN_UPLOAD";
export const PLUGIN_IMAGE_UPLOAD = "PLUGIN_IMAGE_UPLOAD";
export const THEME_UPLOAD = "THEME_UPLOAD";
export const THEME_IMAGE_UPLOAD = "THEME_IMAGE_UPLOAD";
export const THEME_CREATE = "THEME_CREATE";
export const COLOR_CREATE = "COLOR_CREATE";
export const COLOR_VIEW = "COLOR_VIEW";

// THEMES
export const THEMES_LIST = "THEMES_LIST";
