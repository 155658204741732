import { Field } from "formik";
import React from "react";
import ErrorMsg from "./error-msg";

export default function TextAreaField({
  isContainer = true,
  placeholder = "",
  replaceInputCSS = false,
  defaultInputClass = "textAreaField focus_outline__none",
  id,
  name = "",
  label = "",
  inputClass = "w-100",
  containerClass = "mb-0 position-relative",
  errMsgContainerClass = "",
  labelClass = "d-flex fs_14 mb-1",
  labelActiveClass = "color_9E9E9E fw_400",
  labelInActiveClass = " text-black fw_500",
  resize = "none", rows = "2"
}) {
  const Container = isContainer ? "div" : React.Fragment;
  const finalInputClass = replaceInputCSS
    ? inputClass
    : defaultInputClass + " " + inputClass;
  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {" "}      <Field name={name}>
        {({ field, meta, form }) => (
          <>
            {label && (
              <label {...(id ? { htmlFor: id } : {})} className={`${form.values[name] ? labelActiveClass : labelInActiveClass
                }   ${labelClass}`}>
                {label}
              </label>
            )}{" "}

            <textarea
              rows={rows}
              style={{
                resize: resize,
              }}
              {...(id ? { id: id } : {})}
              {...field}
              placeholder={placeholder}
              className={`${meta.touched && meta.error
                ? `${finalInputClass} inputField_border__error`
                : `${finalInputClass} inputField_border`
                }`}
            ></textarea></>
        )}
      </Field>{" "}
      <ErrorMsg containerClass={errMsgContainerClass} name={name} />
    </Container>
  );
}
