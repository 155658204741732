import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  adminUsersDeleteAction,
  adminUsersResetPasswordAction,
  adminUsersViewAction,
} from "../../../redux/actions/admin-users-action";
import { useLocation, useParams } from "react-router";
import InnerLayout from "../../../components/ui/layouts/inner-layout";
import ResetPassModal from "../../../components/ui/modals/reset-pass-modal";
import PasswordSuccessfulModal from "../../../components/ui/modals/password-successful-modal";
import UplaodImgLogo from "../../../assets/icons/Upload_image_logo.svg";
import { ViewContent } from "../../../components/ui/view/view-content";
import errorToast from "../../../components/helper-functions/helper/error-toast";
import NotificationComponent from "../../../components/notificationComponent";
export default function AdminUsersView() {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [adminUsersData, setAdminUsersData] = useState({});
  const [showResetPass, setShowResetPass] = useState(false);
  const [password, setpassword] = useState("");
  const [showPassSuccess, setShowPassSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(adminUsersViewAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    setError(false);
    setAdminUsersData({ ...data.data, role_name: data.data?.Role?.role_name });
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data.data.message);
    setLoading(false);
    setError(true);
  };

  function resetPassord(values) {
    setpassword(values.password);
    dispatch(
      adminUsersResetPasswordAction(
        {
          admin_id: params.id,
          password: values.password,
        },
        onPassRestSuccess,
        onPassRestError
      )
    );
  }
  const onPassRestSuccess = () => {
    setShowResetPass(false);
    setShowPassSuccess(true);
  };
  const onPassRestError = (data) => {
    errorToast(data.data.message);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPage = searchParams.get("fromPage");
  return (
    <>
      <NotificationComponent />
      <InnerLayout
        resetPassFn={() => {
          setShowResetPass(true);
        }}
        deleteAction={adminUsersDeleteAction}
        id={params.id}
        editButtonPath={`/admin/users/edit/${params.id}`}
        name={adminUsersData?.code}
        path={
          fromPage === "view"
            ? `/admin/roles/view/${adminUsersData?.role_id}?currentPage=1`
            : `/admin/users/`
        }
        reset={true}
        rolePermission={"admin_users"}
        // type={`Admin user "${adminUsersData.name}"`}
        type={`Admin user`}
      />
      <ViewContent
        dataKeys={[
          "profile_picture",
          "name",
          "email_address",
          "mobile",
          "role_name",
          "dob",
          "gender",
        ]}
        labels={[
          {
            label: "Profile Picture",
            renderAs: (url) => {
              return (
                <img
                  width={170}
                  className="rounded mb-3"
                  height={"180px"}
                  src={url ? url : UplaodImgLogo}
                  alt="Profile Picture"
                />
              );
            },
          },
          "Name",
          "Email Address",
          "Mobile",
          "Role",
          { label: "DOB", type: "date" },
          "Gender",
        ]}
        data={adminUsersData}
      />
      <ResetPassModal
        firstPlaceholder="Create new password"
        resetFn={resetPassord}
        show={showResetPass}
        modalClose={() => {
          setShowResetPass(false);
        }}
      />
      <PasswordSuccessfulModal
        show={showPassSuccess}
        password={password}
        modalClose={() => setShowPassSuccess(false)}
      />
    </>
  );
}
