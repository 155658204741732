import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  discountEditAction,
  discountViewAction,
} from "../../redux/actions/discount-action";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import InputField from "../../components/ui/forms/input-field";
import SelectField from "../../components/ui/forms/select-field";
import {
  discountProdSelectOptns,
  discountTypeOptions,
} from "../../components/helper-functions/helper/data";
import { numbRegex } from "../../components/helper-functions/helper/regex";
import RadioButton from "../../components/ui/forms/radio-button";
import {
  getSelectedAndUnselectedProducts,
  stringToNumbersConverter,
} from "../../components/helper-functions/helper/helper";
import errorToast from "../../components/helper-functions/helper/error-toast";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import { isEqual } from "lodash";
import DiscountedProductsList from "../../components/ui/discounts/discounted-products-list";
import DiscountsAddProductsModal from "../../components/ui/modals/discounts-add-products-modal";
import { allProductsIdAction } from "../../redux/actions/products-action";
import DeleteModal from "../../components/ui/modals/delete-modal";
import { formatDate } from "../../components/helper-functions/helper/date";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";
export default function DiscountsEdit() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [addedProductsList, setAddedProductsList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [discountsData, setDiscountsData] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  const [allProductsId, setAllProductsId] = useState([]);
  const [addProducts, setAddProducts] = useState(false);
  const addProductModalShow = () => setAddProducts(true);
  const addProductModalClose = () => setAddProducts(false);
  const [emptyProductsError, setEmptyProductsError] = useState(false);
  const [warningModalShow, setWarningModalShow] = useState(false);
  const handleWarningModalClose = () => setWarningModalShow(false);
  const handleWarningModalShow = (e) => setWarningModalShow(e);
  useEffect(() => {
    dispatch(allProductsIdAction(onListFetchSuccess, onListFetchError));
  }, []);
  const onListFetchSuccess = (data) => {
    setAllProductsId(data?.data);
  };
  const onListFetchError = (data) => {
    console.log(data?.data?.message);
  };
  const initialValues = {
    discount_name: discountsData.discount_name ?? "",
    coupon_code: discountsData?.coupon_code ?? "",
    discount_type: discountsData?.discount_type ?? "",
    value_of_discount: discountsData?.value_of_discount ?? "",
    max_discount_amount: discountsData?.max_discount_amount ?? "",
    min_order_value: discountsData?.min_order_value ?? "",
    usage_limit: discountsData?.usage_limit ?? "",
    discount_validity_from: discountsData?.discount_validity_from ? formatDate(discountsData?.discount_validity_from, "YYYY-MM-DDTHH:mm") : "",
    discount_validity_to: discountsData?.discount_validity_to ? formatDate(discountsData?.discount_validity_to, "YYYY-MM-DDTHH:mm") : "",
    on_all_products: discountsData?.on_all_products ?? "all",
  };
  const Schema = Yup.object().shape({
    discount_name: Yup.string().trim().required("Required"),
    coupon_code: Yup.string().trim().required("Required"),
    discount_type: Yup.string().trim().required("Required"), discount_validity_from: Yup.date().required("Required"),
    discount_validity_to: Yup.date()
      .min(
        Yup.ref("discount_validity_from"),
        "End date can't be before Start date"
      )
      .required("Required"),
    value_of_discount: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .test(
        "is-greater-than-zero",
        "Discount must be greater than zero",
        (value) => Number(value) > 0
      )
      .test(
        "max percentage discount",
        "Discount can't be more than 100%",
        function (value) {
          return this.parent.discount_type === "percentage"
            ? Number(value) <= 100
            : true;
        }
      )
      .required("Required"),
    max_discount_amount: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .test("max discount", "Required", function (value) {
        if (this?.parent.discount_type === "percentage") {
          return value;
        }
        return true;
      }),
    min_order_value: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .required("Required"),
    on_all_products: Yup.string().trim().required("Required"),
    usage_limit: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number").required("Required"),
  });
  useEffect(() => {
    setLoading(true);
    dispatch(discountViewAction(params?.id, onSuccess, onError));
  }, [params.id, reload]);
  const onSuccess = (data) => {
    setDiscountsData(data.data?.fetchDiscount);
    setAddedProductsList(data?.data?.products);

    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data.data.message);
    setError(true);
    setLoading(false);
  };
  const onEditSuccess = (data) => {
    successToast(data.message);
    if (currentPage) {
      navigate(`/discounts`, { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onEditError = (data) => {
    errorToast(data.data.message);
  };

  const { selectedProductData, unselectedProductData } =
    getSelectedAndUnselectedProducts(allProductsId, addedProductsList);
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (
      areEqual &&
      discountsData?.selected_products?.length === addedProductsList?.length
    ) {
      return true;
    } else {
      return false;
    }
  }
  console.log(initialValues, "aaaaaaaaaa");
  return (
    <>
    <NotificationComponent />
      <DiscountsAddProductsModal
        show={addProducts}
        modalClose={addProductModalClose}
        addedProductsList={addedProductsList}
        setAddedProductsList={setAddedProductsList}
        selectedProductList={selectedProductList}
        setSelectedProductList={setSelectedProductList}
        setEmptyProductsError={setEmptyProductsError}
      />{" "}
      {loading ? <LoadingSpinnerTable /> : 
      <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log(values);
          const formValues = { ...values };
          if (
            selectedProductData?.length > 0 ||
            values?.on_all_products === "all"
          ) {
            setLoading(true);
            dispatch(
              discountEditAction(
                {
                  ...stringToNumbersConverter(formValues, [
                    "value_of_discount",
                    "max_discount_amount",
                    "min_order_value",
                  ]),
                  discount_id: params.id,
                  selected_products:
                    selectedProductData && values?.on_all_products !== "all"
                      ? selectedProductData
                      : [],
                  unselected_products: unselectedProductData
                    ? unselectedProductData
                    : [],
                },
                onEditSuccess,
                onEditError
              )
            );
          }
        }}
        validationSchema={Schema}
      >
        {({ values, setFieldValue, errors }) => {
          console.log(discountTypeOptions.find(
            (item) => item.value === values.discount_type
          ), "values");
          return (
            <>
              <DeleteModal
                text={`Are you sure, you want to change it permanently(the product data will be deleted)?`}
                show={warningModalShow}
                modalClose={handleWarningModalClose}
                deleteFn={() => {
                  setFieldValue("on_all_products", warningModalShow);
                  setAddedProductsList([]);
                  handleWarningModalClose();
                }}
                type="warning"
              />
              <Form>
                <InnerLayout
                  error={error}
                  loading={loading}
                  name={discountsData?.code}
                  page="edit"
                  edit={true}
                  rolePermission={"discounts"}
                  path={"/discounts"}
                  disabled={valuesCompareFn(values)}
                  resetFn={() => setReload(!reload)}
                  saveFn={() =>
                    setEmptyProductsError(
                      values?.on_all_products !== "all" &&
                        addedProductsList?.length === 0
                        ? true
                        : false
                    )
                  }
                />
                <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
                  <InputField
                    id={"discount_name"}
                    label={"Discount Name"}
                    placeholder={"Enter discount name"}
                    name={"discount_name"}
                  />
                  <InputField
                    id={"coupon_code"}
                    label={"Coupon Code"}
                    placeholder={"Enter coupon code"}
                    name={"coupon_code"}
                  />
                  <SelectField
                    placeholder={"Select discount type"}
                    defaultValue={discountTypeOptions.find(
                      (item) => item.value === values.discount_type
                    )}
                    label={"Discount Type "}
                    id={"discount_type"}
                    name="discount_type"
                    options={discountTypeOptions}
                    isFormikControlled={true}
                    errMsgContainerClass="ps-2"
                  />

                  <InputField
                    unitPosition={
                      values.discount_type === "percentage" ? "right" : "left"
                    }
                    unitType={values.discount_type === "percentage" ? "%" : `${localStorage?.getItem("currency") ?? "₹"}`}
                    isUnit={true}
                    id={"value_of_discount"}
                    label={"Value of Discount"}
                    placeholder={"Enter value of discount"}
                    name={"value_of_discount"}
                  />
                  {values.discount_type === "percentage" && (
                    <InputField
                      isUnit={true}
                      id={"max_discount_amount"}
                      label={"Max. Discount Amount"}
                      placeholder={"Enter max.discount amount"}
                      name={"max_discount_amount"}
                    />
                  )}
                  <InputField
                    isUnit={true}
                    id={"min_order_value"}
                    label={"Min. Order Value"}
                    placeholder={"Enter min. order value"}
                    name={"min_order_value"}
                  />
                  <h3 className="fs_14  color_9E9E9E  fw_400 mb-3 ">
                    Discount Validity
                  </h3>{" "}
                  <div className="d-flex flex-column flex-md-row gap-md-3">
                    <InputField
                      id={"discount_validity_from"}
                      label={"From"}
                      placeholder={"Enter Discount start date"}
                      name={"discount_validity_from"}
                      type="datetime-local"
                      containerClass="flex-grow-1 "
                    />
                    <InputField
                      id={"discount_validity_to"}
                      label={"To"}
                      placeholder={"Enter Discount end date"}
                      name={"discount_validity_to"}
                      type="datetime-local"
                      containerClass="flex-grow-1 "
                    />
                  </div>
                  <InputField
                    unitClassName="text-nowrap"
                    isUnit={true}
                    id={"usage_limit"}
                    label={"Usage Limit"}
                    placeholder={"Enter usage limit"}
                    name={"usage_limit"}
                    unitPosition="right"
                    unitType="per User"
                  />
                  <RadioButton
                    onChange={(e) => {
                      const { value } = e.target;
                      const shouldShowModal =
                        values?.on_all_products !== "all" &&
                        addedProductsList?.length > 0;
                      if (shouldShowModal) {
                        handleWarningModalShow(value);
                      } else {
                        setFieldValue("on_all_products", value);
                      }
                    }}
                    labelClass="color_9E9E9E d-flex fs_14 mb-1"
                    name="on_all_products"
                    options={discountProdSelectOptns}
                    label="On All Products"
                  />
                </div>
                <div className="m-5 mx-lg-5 mx-4">
                  {values?.on_all_products !== "all" && (
                    <DiscountedProductsList
                      productList={addedProductsList}
                      addProductModalShow={addProductModalShow}
                      tableDiscountHeader={tableDiscountHeader}
                      addedProductsList={addedProductsList}
                      setAddedProductsList={setAddedProductsList}
                      selectedProductList={selectedProductList}
                      setSelectedProductList={setSelectedProductList}
                      action={true}
                      page={"edit"}
                      emptyProductsError={emptyProductsError}
                      setInputValue={setInputValue}
                      inputValue={inputValue}
                      searchTopLabel={`Discount ${values?.on_all_products === "exclude"
                        ? "Excluded"
                        : "Included"
                        } Products`}
                    />
                  )}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      </>}
      
    </>
  );
}
const tableDiscountHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "ID", value: "id" },
  { label: "Product Name", value: "product_name" },
  { label: "Actions", value: "Actions" },
];
