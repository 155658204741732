import React, { useEffect, useRef, useState, useMemo } from "react";
import * as Yup from "yup";
import InputField from "../../components/ui/forms/input-field";
import SelectField from "../../components/ui/forms/select-field";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  numbRegex,
  percentRegex,
} from "../../components/helper-functions/helper/regex";
import { Field, Form, Formik } from "formik";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import ImageUpload from "../../components/ui/forms/image-upload";
import {
  categoriesDropdownAction,
  categoriesImgUploadAction,
} from "../../redux/actions/categories-action";
import QuillField from "../../components/ui/forms/quill-field";
import TextAreaField from "../../components/ui/forms/textarea-field";
import CreateTagModal from "../../components/ui/modals/create-tag-modal";
import CreateAttributeModal from "../../components/ui/modals/create-attribute-modal";
import { AddWithBorderButton } from "../../components/ui/buttons/text-button";
import DynamicTable from "../../components/ui/tables/table";
import EditVariantModal from "../../components/ui/modals/edit-variant-modal";
import {
  productsEditAction,
  productsViewAction,
} from "../../redux/actions/products-action";
import { attributesDropDownListAction } from "../../redux/actions/attributes-action";
import { tagsDropDownListAction } from "../../redux/actions/tags-action";
import ProductsVariationsNew from "../../components/ui/view/products-variations-new";
import MultiImageUpload from "../../components/ui/forms/multiImage-upload";
import isEqual from "lodash.isequal";
import Compressor from "compressorjs";
import omit from "lodash/omit";
import { Country } from "country-state-city";
// import { gstPercentage } from "../../components/helper-functions/helper/data";
import { taxViewAction } from "../../redux/actions/configuration-action";
import { gstPercentage } from "../../components/helper-functions/helper/helper";
import SelectFieldTax from "../../components/ui/forms/select-field-tax";
import { brandsDropDownListAction } from "../../redux/actions/brands-action";
import ProductMetaDataTable from "../../components/ui/tables/product-meta-data-table";
import usePermissions from "../../components/helper-functions/hooks/usePermission-checker";
import { vegOptns } from "../../components/helper-functions/helper/data";
import RadioButton from "../../components/ui/forms/radio-button";
import ProductAddonsSection from "../../components/ui/view/product-addons-section";
import NotificationComponent from "../../components/notificationComponent";

export default function ProductsEdit() {
  const [addonsData, setAddonsData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const combinationDataRef = useRef([]);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [imgUrl, setImageUrl] = useState("");
  const [productData, setProductData] = useState({});
  const [comboDeleted, setComboDeleted] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [showCreateAttribute, setShowCreateAttribute] = useState(false);
  const [showCreateTag, setShowCreateTag] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [images, setImages] = useState();
  const [formValues, setFormValues] = useState();
  const [imageSize, setImageSize] = useState(false);
  console.log(images, "images");
  const [varaintStatus, setVariantStatus] = useState({});
  const [editvariantData, setEditvariantData] = useState({});
  const [edited, setEdited] = useState(false);
  const [editVariant, setEditVariant] = useState({
    show: false,
    data: {},
  });
  const [brandList, setBrandList] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  const [taxData, setTaxData] = useState();
  const [metaList, setMetaList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(taxViewAction(onViewSuccess, onViewError));
  }, []);
  const onViewSuccess = (data) => {
    setLoading(false);
    setTaxData(data?.data);
  };
  const onViewError = (data) => {
    setLoading(false);
  };

  console.log(editvariantData, "sdgsdsdgdg");

  const [initialValues, setInitialValues] = useState({
    product_name: "",
    slug: "",
    category_id: [],
    attributes: [],
    tags: [],
    brand_id: "",
    description: "",
    is_veg: "veg",
    seo_title: "",
    sku: "",
    barcode: "",
    variants: {},
    variantion_search: [],
    variation: [],
    variation_products: [],
    seo_description: "",
    short_description: "",
    price: 0,
    isDiscounted: false,
    isInventory: false,
    discount_price: "",
    inventory_count: "",
    product_weight: "",
    hsn_code: "",
    gst_tax: "",
    // image:""
  });
  const Schema = Yup.object().shape({
    product_name: Yup.string().trim().required("Required"),
    slug: Yup.string().trim(),
    category_id: Yup.array()
      .min(1, "Select atleast one category")
      .required("Required"),
    attributes: Yup.array(),
    sku: Yup.string().trim(),
    barcode: Yup.string().trim(),
    tags: Yup.array(),
    variation_products: Yup.array().test(
      "check-price",
      "Price is required in all items",
      function (variation_products) {
        if (variation_products?.length > 0) {
          return variation_products.every((item) => !!item.price);
        }
        return true;
      }
    ),
    description: Yup.string().trim(),
    short_description: Yup.string().trim(),
    seo_title: Yup.string().trim(),
    seo_description: Yup.string().trim(),
    price: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .nullable()
      .test("isDiscounted", "Required", function (value) {
        if (this?.parent.variation_products?.length == 0) {
          return value;
        }
        return true;
      }),
    isDiscounted: Yup.boolean(),
    isInventory: Yup.boolean(),
    discount_price: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .test("isDiscounted", "Required", function (value) {
        if (
          this?.parent.isDiscounted &&
          this?.parent.variation_products?.length === 0
        ) {
          return value;
        }
        return true;
      })
      .test(
        "is-greater-than-price",
        "Discount must be less than or equal to price",
        function (value) {
          if (
            this?.parent.isDiscounted &&
            this?.parent.variation_products?.length === 0
          ) {
            return Number(value) <= Number(this?.parent.price);
          }
          return true;
        }
      ),
    inventory_count: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .test("isDiscounted", "Required", function (value) {
        if (
          this?.parent.isInventory &&
          this?.parent.variation_products?.length === 0
        ) {
          return value;
        }
        return true;
      }),
    image: Yup.mixed(),
    product_weight: Yup.string()
      .nullable()
      .trim()
      .matches(numbRegex, "Enter a valid number"),

    hsn_code: Yup.string().trim(),
    gst_tax: Yup.string()
      .nullable()
      .trim()
      .matches(percentRegex, "Enter a valid number"),
  });

  useEffect(() => {
    dispatch(productsViewAction(params.id, onFetchSuccess, onFetchError));
    dispatch(categoriesDropdownAction(onFetchCatSuccess, onFetchCatError));
    dispatch(
      attributesDropDownListAction(onFetchAttrSuccess, onFetchAttrError)
    );
    dispatch(tagsDropDownListAction(onFetchTagSuccess, onFetchTagError));
    dispatch(brandsDropDownListAction(OnBrandSuccess, onBrandError));
  }, [edited]);
  // useEffect(() => {

  // }, [productData, categoryList, attributesList, tagsList]);

  const onFetchSuccess = (data) => {
    const product = data.data.product;
    setProductData(product);
    setMetaList(
      data.data.product?.meta_data ? data.data.product?.meta_data : []
    );
    setImageUrl(product.thumbnail_image);
    setImages(
      formValues?.variation_products
        ? formValues?.variation_products
            ?.map((item) => item?.product_picture)
            .flat()
        : product?.product_picture
    );
    setAddonsData(data.data.product?.productAddOns);
  };

  const onFetchError = (data) => {};
  const onFetchCatSuccess = (data) => {
    console.log(data);
    setCategoryList(
      data.data.map((item) => {
        return { label: item.category_name, value: item.id };
      })
    );
  };
  const onFetchCatError = () => {
    console.log("failed to load parent category list");
  };
  const onFetchAttrSuccess = (data) => {
    setAttributesList(
      data.data.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  };
  const onFetchAttrError = () => {
    console.log("failed to load attributes list");
  };
  const onFetchTagSuccess = (data) => {
    setTagsList(
      data.data.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  };
  const onFetchTagError = () => {
    console.log("failed to load tags list");
  };

  const OnBrandSuccess = (data) => {
    console.log(data);
    const list = data?.data.map((val) => ({
      label: val?.name,
      value: val?.id,
    }));
    setBrandList([{ label: "None", value: null }, ...list]);
  };
  const onBrandError = () => {
    console.log("failed to load parent category list");
  };
  const onEditSuccess = (data) => {
    setLoading(false);
    successToast(data.message);
    setEdited(!edited);
    navigate("/products");
    if (currentPage) {
      navigate("/products", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onEditError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
  };
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  function resetFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setEdited(!edited);
      setImageUrl(productData?.thumbnail_image);
    }
  }
  // useEffect(() => {
  //   if (formValues?.variation_products?.length > 0) {
  //     setImages(
  //       formValues?.variation_products?.map((pic) => pic?.product_picture).flat()
  //     );
  //   }
  // }, [formValues?.variation_products]);

  function valuesCompareFn(values) {
    console.log(values, "gdfgfdgfdgdfgdfg");
    const filteredArr1 = values?.variation_products?.map(
      ({ variant, actions, ...rest }) => {
        delete rest.variant;
        delete rest.actions;
        return rest;
      }
    );
    const productDataWithoutDelete = productData?.variation_products?.map(
      (obj) => omit(obj, "delete")
    );
    const filteredArr1WithoutDelete = filteredArr1.map((obj) =>
      omit(obj, "delete")
    );
    const areEqual = isEqual(initialValues, values);
    const areVariantsEqual = isEqual(
      productDataWithoutDelete,
      filteredArr1WithoutDelete
    );
    if (
      areEqual &&
      productData?.thumbnail_image === imgUrl &&
      areVariantsEqual
    ) {
      return true;
    } else {
      return false;
    }
  }
  const [variantsErrorMsg, setVariantsErrorMsg] = useState(false);
  const onVariationUpdate = () => {
    setVariantsErrorMsg(false);
  };
  const countriesList = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: `${country.name}`,
      })),
    []
  );
  let previousValue = null;

  const gstPercentagedata = gstPercentage(taxData, productData);

  function findVariantNames(variantsArray, variantsToCompare, type) {
    let array = [];

    for (let i = 0; i < variantsToCompare?.length; i++) {
      for (let j = 0; j < variantsArray?.length; j++) {
        // Corrected the variable j increment
        for (let v = 0; v < variantsArray[j].values.length; v++) {
          // Added missing v < variantsArray[j].values.length
          if (
            JSON.stringify(variantsToCompare[i]).includes(
              JSON.stringify(variantsArray[j].values[v])
            )
          ) {
            if (type === "variant_name") {
              array.push(variantsArray[j].variation_name);
            } else if (type === "variant_json") {
              array.push({
                [variantsArray[j].variation_name]: variantsToCompare?.[i],
              });
            }
          }
        }
      }
    }

    return array;
  }
  const { canRead } = usePermissions();
  return (
    <>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values, "wgtwertwertwetwetew");
          if (
            Object.keys(varaintStatus)?.some(
              (item) => varaintStatus[item].saved === false
            )
          ) {
            return;
          }
          let variantsData = [];
          Object.keys(values.variants).forEach((key) => {
            const variantValue = values.variants[key];
            variantsData.push({
              ...variantValue,
              id: "",
              values: variantValue.values.map((item) => item?.value),
            });
          });

          let variantsEditData = [];
          Object.keys(editvariantData).forEach((key) => {
            const variantValue = values.variants[key];
            variantsEditData.push({
              ...variantValue,
              id: "",
              values: variantValue.values.map((item) => item?.value),
            });
          });
          const dispatchingData = {
            product_id: params.id,
            thumbnail_image: imgUrl,
            product_name: values.product_name,
            slug: values.slug,
            category_id: values.category_id.map((item) => item.value),
            attributes_id: values.attributes.map((item) => item.value),
            tag_id: values.tags.map((item) => item.value),
            brand_id: values.brand_id ?? null,
            price:
              values?.variation_products?.length > 0
                ? null
                : values.price
                ? +values.price
                : null,
            is_discount_price: values.isDiscounted
              ? values.isDiscounted
              : false,
            discount_price:
              values?.variation_products?.length > 0
                ? null
                : values.discount_price
                ? +values.discount_price
                : null,

            short_description: values.short_description,
            is_inventory: values.isInventory,
            inventory_count: values.inventory_count
              ? +values.inventory_count
              : null,
            description: values.description,
            is_veg: values?.is_veg === "veg" ? true : false,
            variation_search:
              variantsEditData.length === 0
                ? []
                : variantsEditData.map((item) => item.values).flat(),
            variation: variantsEditData.length === 0 ? [] : variantsEditData,
            variation_products: values?.variation_products
              ?.filter((value) => value?.delete != true)
              ?.map((variant) => ({
                id: "",
                variants_pictures:
                  variant?.variants_pictures?.length > 0
                    ? variant?.variants_pictures
                    : [],
                variant_name: findVariantNames(
                  variantsData,
                  variant?.variant?.map((vari) => vari?.value),
                  "variant_name"
                ),
                variant_json: findVariantNames(
                  variantsData,
                  variant?.variant?.map((vari) => vari?.value),
                  "variant_json"
                ),
                variants: variant?.variant?.map((vari) => vari?.value),
                price: variant?.price ? +variant?.price : null,
                discounted_price: variant?.discounted_price
                  ? +variant?.discounted_price
                  : null,
                sku: variant?.sku ?? "",
                bar_code: variant?.bar_code ?? "",
                inventory_count: variant?.inventory_count
                  ? +variant?.inventory_count
                  : null,
                max_weight: variant?.max_weight ? +variant?.max_weight : null,
              })),
            product_picture:
              values.variation_products?.length > 0 ? [] : images,
            // : values.variation_products
            //   .map((item) => item.product_picture)
            //   .flat(),
            sku: values.sku ? values.sku : "",
            barcode: values.barcode,
            seo_title: values.seo_title,
            seo_description: values.seo_description,
            product_weight: values?.product_weight
              ? values?.product_weight
              : null,
            gst_tax: values?.gst_tax ? values?.gst_tax : null,
            hsn_code: values?.hsn_code,
            meta_data:
              metaList[metaList.length - 1]?.value ||
              metaList[metaList.length - 1]?.title
                ? metaList
                : metaList.slice(0, -1),
            add_ons: addonsData,
          };

          setLoading(true);
          dispatch(
            productsEditAction(dispatchingData, onEditSuccess, onEditError)
          );
        }}
        validationSchema={Schema}
        enableReinitialize={true}
        onReset={() => {
          setComboDeleted("reset");
        }}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          validateField,
          handleChange,
        }) => {
          console.log(values, "fgffgfgfgfg");
          console.log(errors, "dsadasdsd");
          console.log(
            gstPercentagedata.find((item) => item?.value == values.gst_tax),
            "gstPercentagedata"
          );
          return (
            <>
              <Form>
                <InnerLayout
                  path={"/products"}
                  name={productData?.code}
                  page="edit"
                  rolePermission={"products"}
                  edit={true}
                  // disabled={valuesCompareFn(values)}
                  resetFn={resetFn}
                  saveFn={() => {
                    setVariantsErrorMsg(
                      Object.keys(varaintStatus)?.some(
                        (item) => varaintStatus[item].saved === false
                      )
                    );
                  }}
                />
                <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
                  <h2 className="fs_16  color_030304 font_montserrat fw_500 mb-3">
                    Basic Details
                  </h2>
                  <span>
                    <ImageUpload
                      resetFn={resetFn}
                      id="Thumbnail"
                      title="Thumbnail"
                      containerClass=""
                      page="create"
                      getImgUrl={getImgUrl}
                      data={imgUrl}
                      imgLoading={imgLoading}
                      setImgLoading={setImgLoading}
                    />
                    {imageSize && (
                      <label className="fs_14 ms-5 text-danger mb-3">
                        "Please upload below 10mb"
                      </label>
                    )}
                  </span>

                  <InputField
                    id={"product_name"}
                    label={"Product Name"}
                    placeholder={"Product Name Goes Here"}
                    name={"product_name"}
                  />
                  <InputField
                    id={"slug"}
                    label={"Slug"}
                    placeholder={"Enter slug text"}
                    name={"slug"}
                  />
                  <SelectField
                    isMulti={true}
                    placeholder={"Select Category"}
                    options={categoryList}
                    label={"Category"}
                    isFormikControlled={true}
                    name="category_id"
                    errMsgContainerClass="ps-2"
                  />
                  {localStorage.getItem("brand") === "true" && (
                    <SelectField
                      defaultValue={brandList.find(
                        (item) => item.value === values.brand_id
                      )}
                      placeholder={"Select Brand (optional)"}
                      label={"Brand"}
                      id={"brand_id"}
                      name="brand_id"
                      options={brandList}
                      isFormikControlled={true}
                      errMsgContainerClass="ps-2"
                    />
                  )}
                  <SelectField
                    isMulti={true}
                    placeholder={"Select Attributes"}
                    options={attributesList}
                    label={"Attributes"}
                    isFormikControlled={true}
                    name="attributes"
                    errMsgContainerClass="ps-2"
                  >
                    <AddWithBorderButton
                      disabled={!canRead("attributes")}
                      onClick={() => setShowCreateAttribute(true)}
                      addClassName="py-1 mb-2"
                      customStyles={{ borderRadius: "4px" }}
                      name="Add Attributes"
                    />
                  </SelectField>
                  <SelectField
                    isMulti={true}
                    placeholder={"Select tags"}
                    options={tagsList}
                    label={"Tags"}
                    isFormikControlled={true}
                    name="tags"
                    errMsgContainerClass="ps-2"
                  >
                    <AddWithBorderButton
                      disabled={!canRead("attributes")}
                      onClick={() => setShowCreateTag(true)}
                      addClassName="py-1 mb-2"
                      customStyles={{ borderRadius: "4px" }}
                      name="Add Tags"
                    />
                  </SelectField>

                  <TextAreaField
                    label="Short Description"
                    placeholder="Enter small description"
                    name="short_description"
                    id={"short_description"}
                  />
                  <QuillField
                    name="description"
                    label="Description"
                    placeholder="Write a description"
                  />

                  {localStorage.getItem("indus_type") === "food" && (
                    <div className="mb-4">
                      <RadioButton
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("is_veg", value);
                        }}
                        labelClass="color_9E9E9E d-flex fs_14 mb-1 "
                        name="is_veg"
                        options={vegOptns}
                        label="Veg/Non Veg"
                      />
                    </div>
                  )}
                  <InputField
                    id={"sku"}
                    label={"SKU"}
                    placeholder={"Enter SKU"}
                    name={"sku"}
                  />
                  <InputField
                    id={"barcode"}
                    label={"Barcode"}
                    placeholder={"Enter barcode"}
                    name={"barcode"}
                  />
                  <ProductsVariationsNew
                    setEditvariantData={setEditvariantData}
                    taxData={taxData}
                    setInitialValues={setInitialValues}
                    productData={productData}
                    categoryList={categoryList}
                    attributesList={attributesList}
                    tagsList={tagsList}
                    brandList={brandList}
                    setComboDeleted={setComboDeleted}
                    ref={combinationDataRef}
                    comboDeleted={comboDeleted}
                    varaintStatus={varaintStatus}
                    setVariantStatus={setVariantStatus}
                    onVariationUpdate={onVariationUpdate}
                  />
                  {variantsErrorMsg &&
                    Object.keys(varaintStatus)?.some(
                      (item) => varaintStatus[item].saved === false
                    ) && (
                      <span
                        role="alert"
                        className={`color_F24444 fs_12 d-block mt-1`}
                      >
                        <span className="fs_14 me-1 fw_600 ">&middot;</span>
                        {`Please ensure you have created a variant and saved it correctly before proceeding.`}
                      </span>
                    )}
                  {values.variation_products?.length === 0 &&
                    Object.values(varaintStatus)?.length === 0 && (
                      <>
                        <h3 className="fs_16  color_030304 font_montserrat fw_600 mb-3 mt-5">
                          Price & Discounts
                        </h3>{" "}
                        <InputField
                          unitType={localStorage?.getItem("currency") ?? "₹"}
                          isUnit={true}
                          id={"price"}
                          label={"Price"}
                          placeholder={"Enter Price"}
                          name={"price"}
                        />
                        <label
                          className={`d-flex align-items-center gap-1 color_030304 font_poppins ${
                            values.isDiscounted ? "" : "mb-3"
                          }`}
                        >
                          <Field
                            className="form-check-input mt-0"
                            style={{ width: "15px", height: "15px" }}
                            type="checkbox"
                            name="isDiscounted"
                            onChange={(e) => {
                              handleChange(e);
                              if (!e.target.checked) {
                                setFieldValue("discount_price", "");
                              }
                            }}
                          />
                          {`Discounted Price?`}
                        </label>
                        {values.isDiscounted && (
                          <InputField
                            containerClass="my-2"
                            unitType={localStorage?.getItem("currency") ?? "₹"}
                            isUnit={true}
                            id={"discount_price"}
                            label={"Discounted Price"}
                            placeholder={"Enter Discounted Price"}
                            name={"discount_price"}
                          />
                        )}
                        <label
                          className={`d-flex align-items-center gap-1 color_030304 font_poppins `}
                        >
                          <Field
                            className="form-check-input mt-0"
                            style={{ width: "15px", height: "15px" }}
                            type="checkbox"
                            name="isInventory"
                            onChange={(e) => {
                              handleChange(e);
                              if (!e.target.checked) {
                                setFieldValue("inventory_count", "");
                              }
                            }}
                          />
                          {`Inventory?`}
                        </label>
                        {values.isInventory && (
                          <InputField
                            containerClass="my-2"
                            id={"inventory_count"}
                            label={"Inventory Count"}
                            placeholder={"Enter Inventory Count"}
                            name={"inventory_count"}
                          />
                        )}
                      </>
                    )}
                </div>
                {values.variation_products?.length > 0 && (
                  <div className="m-4 col-xl-10">
                    <DynamicTable
                      tabletitle="Products"
                      tableHeading={tableDiscountHeader}
                      tablebody={values.variation_products
                        .map((item) => {
                          return {
                            ...item,
                            variants: item.variant,
                          };
                        })
                        .map((item) => {
                          console.log(item, "dgdfgdfgfg");
                          return {
                            product_picture:
                              item?.variants_pictures?.length > 0
                                ? item?.variants_pictures?.[0]
                                : "product_picture",
                            variant: item?.variants,
                            price: item?.price
                              ? (localStorage?.getItem("currency") + " " ??
                                  "₹ ") + item?.price
                              : "--",
                            discount_price:
                              item?.discounted_price === ""
                                ? "No"
                                : (localStorage?.getItem("currency") + " " ??
                                    "₹ ") + item?.discounted_price,
                            sku: item?.sku,
                            bar_code: item?.bar_code,
                            inventory_count: item?.inventory_count,
                            max_weight: item?.max_weight
                              ? item?.max_weight + " kg"
                              : "--",
                            Actions: ["", "", "", item?.delete],
                          };
                        })}
                      rolePermission={"products"}
                      isBorderLess={{
                        border: errors.variation_products ? true : false,
                        error: errors.variation_products ? true : false,
                      }}
                      variants={true}
                      variantDeleteFn={(index) => {
                        values.variation_products[index] = {
                          ...values.variation_products[index],
                          delete: !values.variation_products[index].delete,
                        };
                        setFieldValue(
                          "variation_products",
                          values.variation_products
                        );
                        setComboDeleted("combo_deleted");
                      }}
                      varEditFn={(index) => {
                        setEditVariant({
                          data: values?.variation_products.find(
                            (_, itemIndex) => index === itemIndex
                          ),
                          show: true,
                        });
                      }}
                      isMinHeight={false}
                    />

                    <p className="color_F24444 fs_12 mt-1">
                      {errors.variation_products &&
                        touched.variation_products && (
                          <>
                            <span>&middot;</span>{" "}
                            {"Price is required in all items"}!
                          </>
                        )}
                    </p>
                  </div>
                )}
                <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
                  <ProductAddonsSection
                    addonsData={addonsData}
                    setAddonsData={setAddonsData}
                  />
                </div>
                <div className="mx-4 mx-md-5">
                  <MultiImageUpload
                    page="edit"
                    images={
                      values?.variation_products?.length > 0
                        ? values.variation_products
                            ?.map(
                              (item) =>
                                item?.variants_pictures ?? item?.product_picture
                            )
                            .flat()
                        : images
                    }
                    // images={images}
                    isRequired={errors.image && touched.image}
                    varientData={values?.variation_products?.length > 0}
                    setImages={setImages}
                    name="image"
                    setFieldValue={setFieldValue}
                    containerClass="ms-0"
                  />
                  {/* <ErrorMsg name="image" /> */}
                </div>
                <div style={{ maxWidth: "600px" }} className="m-5 mx-lg-5 mx-4">
                  {Object.values(varaintStatus)?.length === 0 &&
                    values.variation_products?.length === 0 && (
                      <>
                        <h3 className="fs_16  color_030304 font_montserrat fw_600 mb-3 mt-4">
                          Product Weight
                        </h3>
                        <InputField
                          unitClassName="text-nowrap"
                          isUnit={true}
                          id={"product_weight"}
                          label={"Weight"}
                          placeholder={"Enter weight"}
                          name={"product_weight"}
                          unitPosition="right"
                          unitType="Kg"
                        />
                      </>
                    )}
                  {taxData?.enable_disable && (
                    <>
                      <h3 className="fs_16  color_030304 font_montserrat fw_600 mb-3 mt-4">
                        Tax
                      </h3>

                      {taxData?.country === "india" ||
                      taxData?.country === "India" ? (
                        <>
                          <InputField
                            id={"hsn_code"}
                            label={"HSN Code"}
                            placeholder={"Enter HSN code"}
                            name={"hsn_code"}
                          />
                          {/* <SelectField
                              placeholder={"Select tax percentage"}
                              defaultValue={gstPercentagedata.find(
                                (item) => item?.value == values.gst_tax
                              )}
                              label={`GST Tax ${taxData?.tax_with_product === "exclusive_tax" ? "(Excluded)" : "(Included)"}`}
                              id={"gst_tax"}
                              name="gst_tax"
                              options={gstPercentagedata}
                              isFormikControlled={true}
                              errMsgContainerClass="ps-2"
                            /> */}
                          <SelectFieldTax
                            placeholder={"Select tax percentage"}
                            defaultValue={gstPercentagedata.find(
                              (item) => item?.value == values.gst_tax
                            )}
                            label={`GST Tax ${
                              taxData?.tax_with_product === "exclusive_tax"
                                ? "(Excluded)"
                                : "(Included)"
                            }`}
                            id={"gst_tax"}
                            name="gst_tax"
                            options={gstPercentagedata}
                            isFormikControlled={true}
                            errMsgContainerClass="ps-2"
                          />
                        </>
                      ) : (
                        <InputField
                          unitClassName="text-nowrap"
                          isUnit={true}
                          id={"gst_tax"}
                          label={"Tax"}
                          placeholder={"Enter Tax"}
                          name={"gst_tax"}
                          unitPosition="right"
                          unitType="%"
                        />
                      )}
                    </>
                  )}
                  <h3 className="fs_16  color_030304 font_montserrat fw_600 mb-3 mt-4">
                    SEO
                  </h3>{" "}
                  <InputField
                    id={"seo_title"}
                    label={"SEO Title"}
                    placeholder={"Enter SEO title"}
                    name={"seo_title"}
                  />
                  <TextAreaField
                    label="SEO Description"
                    placeholder="Enter SEO Description"
                    name="seo_description"
                    id={"seo_description"}
                  />
                </div>

                <div style={{ maxWidth: "700px" }} className="m-5 mx-lg-5 mx-4">
                  <h3 className="fs_16  color_030304 font_montserrat fw_600 mb-3 mt-4">
                    Meta Data
                  </h3>
                  <AddWithBorderButton
                    disabled={
                      metaList[metaList?.length - 1]?.value ||
                      metaList[metaList?.length - 1]?.title ||
                      metaList?.length === 0
                        ? false
                        : true
                    }
                    onClick={() =>
                      setMetaList([...metaList, { title: "", value: "" }])
                    }
                    addClassName="py-1 mb-2"
                    customStyles={{ borderRadius: "4px" }}
                    name="Add Meta Data"
                  />

                  {metaList?.length > 0 && (
                    <ProductMetaDataTable
                      metaList={metaList}
                      setMetaList={setMetaList}
                    />
                  )}
                </div>
              </Form>

              <CreateAttributeModal
                onCreateSuccess={(data) => {
                  setFieldValue("attributes", [
                    ...values["attributes"],
                    { label: data.name, value: data.id },
                  ]);
                }}
                show={showCreateAttribute}
                modalClose={() => {
                  setShowCreateAttribute(false);
                }}
              />
              <CreateTagModal
                show={showCreateTag}
                onCreateSuccess={(data) => {
                  setFieldValue("tags", [
                    ...values["tags"],
                    { label: data.name, value: data.id },
                  ]);
                }}
                modalClose={() => {
                  setShowCreateTag(false);
                }}
              />
              <EditVariantModal
                onEditUpdate={(editedValue, data) => {
                  const itemIndex = values.variation_products.findIndex(
                    (item) =>
                      JSON.stringify(data.variant) ===
                      JSON.stringify(item.variant)
                  );
                  values.variation_products[itemIndex] = editedValue;
                  setFieldValue = { setFieldValue };
                  combinationDataRef.current = values.variation_products;
                  setEditVariant({ show: false, data: {} });
                  validateField("variation_products");
                }}
                show={editVariant.show}
                data={editVariant.data}
                modalClose={() => {
                  setEditVariant({ show: false, data: {} });
                }}
                images={images}
                setImages={setImages}
                skuAndBarcode={{ sku: values.sku, barcode: values.barcode }}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}
const tableDiscountHeader = [
  { label: "Pictures", value: "product_picture" },
  { label: "Variants", value: "variants" },
  { label: "Price", value: "price" },
  { label: "Discounted Price", value: "discount_price" },
  { label: "SKU ", value: "sku" },
  { label: "Barcode", value: "bar_code" },
  { label: "Inventory Count", value: "bar_code" },
  { label: "Product weight", value: "max_weight" },
  { label: "Actions", value: "Actions" },
];
