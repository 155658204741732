import React, { useState } from "react";
import loginBgImg from "../../assets/images/login-bg.png";
import { Form, Formik } from "formik";
import InputField from "../../components/ui/forms/input-field";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { OkayButton as LoginSubmitBtn } from "../../components/ui/buttons/text-button";
import FFlogo from "../../assets/images/ff-logo.svg";
import { useDispatch } from "react-redux";
import { adminLoginAction } from "../../redux/actions/auth-action";
import errorToast from "../../components/helper-functions/helper/error-toast";
import successToast from "../../components/helper-functions/helper/sucess-toast";
// import bcrypt from "bcryptjs";
export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loginSchema = Yup.object().shape({
    email_address: Yup.string()
      .email("Enter a valid email")
      .trim()
      .required("Required"),
    password: Yup.string().trim().required("Required"),
  });
  const onSuccess = (data) => {
    setLoading(false);
    navigate("/");
    localStorage.setItem("userId", data?.data?.id)
    successToast(data.message);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
  };

  return (
    <main className="d-lg-flex flex-column flex-lg-row align-items-center justify-content-center vh-100">
      <div className="w-100 position-sticky top-0 start-0 bg-white mb-5 pb-4 d-lg-none d-block ">
        <img src={FFlogo} alt="ff logo" className=" m-4" />
      </div>
      <img
        src={loginBgImg}
        className="col-12 col-lg-6 h-100 d-none d-lg-inline-block"
        alt="dark background"
      />
      <section
        style={{ maxWidth: "580px" }}
        className="col-12 col-lg-6 mx-auto d-flex align-items-center"
      >
        <Formik
          // enableReinitialize={true}
          initialValues={{
            email_address: "",
            password: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              console.log("submitted");
              setSubmitting(false);
            }, 2000);
            setLoading(true);
            // const hashedPassword = bcrypt.hashSync(values.password, 10);
            dispatch(adminLoginAction({
              email_address: values?.email_address,
              password: values?.password,
            }, onSuccess, onError));
          }}
          validationSchema={loginSchema}
        >
          {({ isValid, values, initialValues, isSubmitting }) => {
            return (
              <Form className="mx-4 mx-sm-5 px-2 px-sm-3 mb-5 pb-5 mb-lg-0 pb-lg-0 col-10">
                <h1 className="fw_400 font_montserrat mb-0">Login</h1>
                <p className="color_9E9E9E mb-5">
                  Welcome back! Please enter your details.
                </p>
                <InputField
                  autoComplete="email"
                  name="email_address"
                  errMsgContainerClass="ps-2"
                />
                <div className="position-relative mb-4">
                  <InputField
                    placeholder="Enter your password"
                    containerClass="mb-4 position-relative"
                    errMsgContainerClass="ps-2"
                    autoComplete="current-password"
                    label="Password"
                    name="password"
                    type="password"
                    isPasswordEye={true}
                  />{" "}
                  <Link
                    style={{ bottom: "-8px" }}
                    to={"/forgot-password"}
                    className="color_FF6F1E fw_500 position-absolute  end-0 fs_14"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <LoginSubmitBtn
                  customStyles={{ marginTop: "8px" }}
                  disabled={
                    !isValid || !(values.email_address && values.password)
                  }
                  name="Login"
                  width={"100%"}
                  type="submit"
                  loading={loading}
                />
              </Form>
            );
          }}
        </Formik>
      </section>
    </main>
  );
}
