import React, { useEffect, useState } from "react";
import Select from "react-select";
import CartIcon from "../../assets/icons/cart-icon";
import SalesIcon from "../../assets/icons/sales-icon";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
} from "recharts";
import CustomBar from "../../components/ui/dashboard/custom-bar";
import DashboardTable from "../../components/ui/dashboard/dashboard-table";
import styles from "./dashboard.module.css";
import Cards from "../../components/ui/dashboard/cards";
import { useDispatch } from "react-redux";
import { dashBoardAction } from "../../redux/actions/dashboard-action";
import errorToast from "../../components/helper-functions/helper/error-toast";
import useScreenSizeDetector from "../../components/helper-functions/hooks/useScreenSizeDetector";
import DynamicTable from "../../components/ui/tables/table";
import {
  getMonthAbbreviation,
  getMonthAbbreviationName,
  nFormatter,
  percentageCalculator,
  removeUnderScore,
} from "../../components/helper-functions/helper/helper";
import { formatDateWithDay } from "../../components/helper-functions/helper/date";
import { pieChartSuggestions } from "../../components/helper-functions/helper/data";
import dayjs from "dayjs";
import CustomPieChartToolTip from "../../components/ui/dashboard/custom-piechart-tooltip";
import { ordersEditAction } from "../../redux/actions/orders-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import usePermissions from "../../components/helper-functions/hooks/usePermission-checker";
import GoogleIcon from "../../assets/icons/google";
import EyeIcon from "../../assets/icons/cart-icon";
import ProductVisitIcon from "../../assets/icons/sales-icon";
import EnquiryIcon from "../../assets/icons/enquiry-icon";
import { Link } from "react-router-dom";
import DashboardProductTable from "../../components/ui/dashboard/dashboard-product-table";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className={`bg_color_ffffff px-4 box_shadow py-1 rounded-2 d-flex align-items-center ${styles.custom_tooltip} position-relative ps-2`}
        style={{ boxShadow: "0px 0px 15px 0px #0000001A" }}
      >
        <div className={`${styles.left_pointer}`}></div>
        <span className="fw_500 fs_18">
          {localStorage?.getItem("currency") ?? "₹"}
          {data.totalSales}
        </span>
      </div>
    );
  }
  return null;
};

// const CustomPieChartToolTip = ({ active, payload }) => {
//   if (active && payload && payload.length) {
//     const data = payload[0].payload;
//     return (
//       <div
//         className={`bg_color_ffffff px-4 box_shadow py-1 rounded-2 d-flex align-items-center  position-relative ps-2`}
//         style={{ boxShadow: "0px 0px 15px 0px #0000001A" }}
//       >
//         {/* <div className={`${styles.left_pointer}`}></div> */}
//         <span className="fw_500 fs_18">{data.value} orders</span>
//       </div>
//     );
//   }
//   return null;
// };

const Dashboard = () => {
   const { canWrite } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [dashBoardData, setDashBoardData] = useState({});
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  const [productsData, setProductsData] = useState({
    products: [],
    pagination: {},
  });
  const first = async () => {};
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [filterValues, setFilterValues] = useState({
    discount_type: "",
    value_of_discount_from: "",
    value_of_discount_to: "",
    max_discount_amount_from: "",
    max_discount_amoun_to: "",
    min_order_value_from: "",
    min_order_value_to: "",
    on_all_products: true,
  });

  const defaultValue = {
    value: "Monthly",
    label: "Monthly",
    textDisplay: "month",
  };
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [analyticsselectedOption, setAnalyticsselectedOption] =
    useState(defaultValue);
  const [barChartselectedOption, setBarChartSelectedOption] =
    useState(defaultValue);
  const [analyticsbarChartselectedOption, setAnalyticsBarChartSelectedOption] =
    useState(defaultValue);
  const [pieChartSelectedOption, setPieChartSelectedOption] =
    useState(defaultValue);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      dashBoardAction(
        {
          orders_sales: selectedOption?.value,
          orders_sales_graph: barChartselectedOption?.value,
          orders_pie_chart: pieChartSelectedOption?.value,
          page: currentPage,
          page_size: 10,
          sort_by: sortConfig.sort_by,
          sort_order: sortConfig.sort_order,
          search: inputValue,
          analytics: analyticsselectedOption?.value,
          sort_by_product_enquiry: "",
          sort_order_product_enquiry: "",
          sort_by_contact_enquiry: "",
          sort_order_contact_enquiry: "",
          product_graph: analyticsbarChartselectedOption?.value,
        },
        onSuccess,
        onError
      )
    );
  }, [
    selectedOption?.value,
    barChartselectedOption?.value,
    pieChartSelectedOption?.value,
    analyticsselectedOption?.value,
    analyticsbarChartselectedOption?.value,
    reload,
  ]);

  const onSuccess = (data) => {
    setLoading(false);
    setDashBoardData(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
    // errorToast(data?.data?.message);
  };

  const tableData = dashBoardData?.outOfStockProducts?.map((item) => ({
    image: item?.thumbnail_image ? item?.thumbnail_image : "product_picture",
    id: item?.id,
    code: item?.code,
    product_name: item?.product_name
      ? removeUnderScore(item?.product_name)
      : "--",
    category: "not added",
    status: item?.status ? item?.status : "--",
    price: item?.price
      ? `${localStorage?.getItem("currency") + " " ?? "₹ "}` + item?.price
      : item?.variation_products
      ? `${localStorage?.getItem("currency") + " " ?? "₹ "}` +
        item?.variation_products[0]?.price?.toFixed(2)
      : "not added",
    Action: ["/products/view/"],
  }));

  const preparingOrdersData = dashBoardData?.preparingOrders?.map((item) => ({
    id: item?.id,
    code: item?.code,
    no_of_items: item?.cart?.length,
    order_date: item.order_history?.find((obj) => obj.accepted)?.accepted
      ? formatDateWithDay(
          item.order_history.find((obj) => obj.accepted)?.accepted
        )
      : "--",
    order_value: item?.total_value_with_discount_with_delivery?.toFixed(2),
    Action: ["/products/view/"],
  }));
  const dispatchedOrdersData = dashBoardData?.dispatchedOrders?.map((item) => ({
    id: item?.id,
    code: item?.code,
    no_of_items: item?.cart?.length,
    order_date: formatDateWithDay(
      item.order_history.find((obj) => obj.dispatched)?.dispatched
    ),
    order_value: item?.total_value_with_discount_with_delivery?.toFixed(2),
    Action: ["/products/view/"],
  }));
  const activeOrdersTableHeading = [
    { label: "ID", value: "id" },
    { label: "No. of Items", value: "no_of_items" },
    { label: "Order Date", value: "order_date" },
    { label: "Payment Status", value: "payment_status" },
    { label: "Order Value", value: "order_value" },
    { label: "Actions", value: "actions" },
  ];

  const recievedOrdersTableBody = dashBoardData?.receivedOrders?.map(
    (item) => ({
      id: item?.id,
      code: item?.code,
      no_of_items: item?.cart?.length,
      order_date: formatDateWithDay(item?.createdAt),
      payment_status: item?.payment_admin_status === "paid" ? "paid" : "unpaid",
      order_value:
        `${localStorage?.getItem("currency") + " " ?? "₹ "}` +
        item?.total_value_with_discount_with_delivery?.toFixed(2),
      Action: ["View", "Approve"],
    })
  );
  const productEnq = dashBoardData?.todayProductEnquires?.map((item) => ({
    id: item?.id,
    code: item?.code,
    product_code: [item?.Product?.code, item?.product_id],
    name: item?.name,
    phone_number: item?.phone_number,
    Action: ["/product-enquiry/view/"],
  }));

  console.log(
    dashBoardData?.topProductEnquires?.map((item) => ({
      id: item?.Product?.id,
      code: item?.Product?.code,
      product_code: [item?.Product?.code, item?.Product?.id],
      name: item?.Product?.product_name,
      enquiryCount: item?.enquiryCount,
      Action: ["/product-enquiry/view/"],
    })),
    "rwetrwetertrwt"
  );
  const topproductEnqData = dashBoardData?.topProductEnquires?.map((item) => ({
    product_code: [item?.Product?.code, item?.Product?.id],
    name: item?.Product?.product_name,
    enquiryCount: item?.enquiryCount,
    Action: ["/product-enquiry/view/"],
  }));

  const contactEnq = dashBoardData?.todayContactEnquires?.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: item?.name,
    phone_number: item?.phone_number,
    Action: ["/contact-enquiry/view/"],
  }));

  const customTickFormatter = (tickValue) => {
    return `${localStorage?.getItem("currency") + " " ?? "₹ "} ${nFormatter(
      tickValue
    )} `;
  };

  const customx_axisTickFormatter = (tickValue) => {
    if (barChartselectedOption?.value === "Daily") {
      const parts = tickValue?.split(" ");
      console.log(parts, "parts");
      const month = parts[0];
      console.log(month, "parts");
      const day = parts[1]?.replace(/[^0-9]/g, "");
      const formattedDate = `${getMonthAbbreviationName(month)} ${day}`;
      return formattedDate;
    } else if (barChartselectedOption?.value === "Weekly") {
      return tickValue;
    } else if (barChartselectedOption?.value === "Monthly") {
      const year = parseInt(tickValue?.split(" ")[1]);
      const month = new Date(Date.parse(tickValue + " 1")).getMonth();
      const formattedDate = `${getMonthAbbreviation(month)} ${year
        ?.toString()
        ?.slice(-2)}`;
      return formattedDate;
    }
  };
  const anlyCustomTickFormatter = (tickValue) => {
    return nFormatter(tickValue);
  };
  const analyCustomx_axisTickFormatter = (tickValue) => {
    if (analyticsbarChartselectedOption?.value === "Daily") {
      const parts = tickValue?.split(" ");
      console.log(parts, "parts");
      const month = parts[0];
      console.log(month, "parts");
      const day = parts[1]?.replace(/[^0-9]/g, "");
      const formattedDate = `${getMonthAbbreviationName(month)} ${day}`;
      return formattedDate;
    } else if (analyticsbarChartselectedOption?.value === "Weekly") {
      return tickValue;
    } else if (analyticsbarChartselectedOption?.value === "Monthly") {
      const year = parseInt(tickValue?.split(" ")[1]);
      const month = new Date(Date.parse(tickValue + " 1")).getMonth();
      const formattedDate = `${getMonthAbbreviation(month)} ${year
        ?.toString()
        ?.slice(-2)}`;
      return formattedDate;
    }
  };

  const { width } = useScreenSizeDetector();
  const filteredData = dashBoardData?.graph;

  const finaldata =
    barChartselectedOption?.value === "Weekly"
      ? filteredData.map((item, index) => {
          return {
            ...item,
            weeks: `Week ${index + 1}`,
          };
        })
      : filteredData;

  const analyFilteredData =
    analyticsbarChartselectedOption?.value === "Monthly"
      ? dashBoardData?.enquiry_graph?.slice(7, 13)
      : dashBoardData?.enquiry_graph;

  const analyfinaldata =
    analyticsbarChartselectedOption?.value === "Weekly"
      ? analyFilteredData.map((item, index) => {
          return {
            ...item,
            weeks: `Week ${index + 1}`,
          };
        })
      : analyFilteredData;

  const total =
    dashBoardData?.pieChart?.completedOrders +
    dashBoardData?.pieChart?.dispatchedOrders +
    dashBoardData?.pieChart?.newOrders +
    dashBoardData?.pieChart?.rejectedOrders;

  const pieData = [
    {
      name: "completedOrders",
      value: percentageCalculator(
        dashBoardData?.pieChart?.completedOrders,
        total
      ),
    },
    {
      name: "newOrders",
      value: percentageCalculator(dashBoardData?.pieChart?.newOrders, total),
    },
    {
      name: "dispatchedOrders",
      value: percentageCalculator(
        dashBoardData?.pieChart?.dispatchedOrders,
        total
      ),
    },
    {
      name: "rejectedOrders",
      value: percentageCalculator(
        dashBoardData?.pieChart?.rejectedOrders,
        total
      ),
    },
  ];

  const COLORS = ["#3AD64C", "#F2C025", "#2820DA", "#F24444"];
  const ordercardData = [
    {
      icon: <CartIcon className={`${styles.order_cards_icons}`} />,
      title: "Total Orders",
      value:
        selectedOption?.value === "Monthly"
          ? dashBoardData?.ordersAndSales?.topData?.totalOrdersMonth
          : selectedOption?.value === "Weekly"
          ? dashBoardData?.ordersAndSales?.topData?.totalOrdersWeek
          : dashBoardData?.ordersAndSales?.topData?.totalOrders,
      timeSpan: true,
    },
    {
      icon: <SalesIcon className={`${styles.order_cards_icons}`} />,
      title: "Sales",
      value: dashBoardData?.ordersAndSales?.topData?.sales,
      timeSpan: dashBoardData?.ordersAndSales?.topData?.sales ? true : false,
    },
    {
      icon: <CartIcon className={`${styles.order_cards_icons}`} />,
      title: "Today’s Orders",
      value: dashBoardData?.ordersAndSales?.todaysData?.todaysOrders,
      timeSpan: false,
    },
    {
      icon: <SalesIcon className={`${styles.order_cards_icons}`} />,
      title: "Today’s Sales",
      value: dashBoardData?.ordersAndSales?.todaysData?.todaySales,
      timeSpan: false,
    },
  ];
  const analyticsData = [
    {
      icon: <EyeIcon className={`${styles.order_cards_icons}`} />,
      title: "Site Visits",
      value: dashBoardData?.analytics?.siteVisits,
      timeSpan: true,
    },
    {
      icon: <ProductVisitIcon className={`${styles.order_cards_icons}`} />,
      title: "Product Visits",
      value: dashBoardData?.analytics?.productVisits,
      timeSpan: dashBoardData?.ordersAndSales?.topData?.sales ? true : false,
    },
    {
      icon: <EnquiryIcon className={`${styles.order_cards_icons}`} />,
      title: "Total Enquiries",
      value: dashBoardData?.analytics?.totalEnquires,
      timeSpan: false,
    },
    {
      icon: (
        <EnquiryIcon fill="#FFA800" className={`${styles.order_cards_icons}`} />
      ),
      title: "Today's Enquiries",
      value: dashBoardData?.analytics?.todayEnquires,
      timeSpan: false,
    },
  ];
  //ACCEPT fn
  function acceptFn(id) {
    setLoading(true);
    dispatch(
      ordersEditAction(
        {
          order_id: id,
          orders_status: "accepted",
        },
        onEditSuccess,
        onEditError
      )
    );
  }

  const onEditSuccess = (data) => {
    setReload(!reload);
    successToast("Order Accepted Successfully");
    setLoading(false);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
    setLoading(false);
  };

  const { canRead } = usePermissions();

  console.log(
    recievedOrdersTableBody?.map(({ Action, ...newObj }) => newObj),
    "ssjsdjgfdshgf"
  );
  return (
    <div className="px-lg-0 px-2 px-lg-4 pt-lg-4 mb-5 pt-5 mt-2">
      <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <div
          className="border_1px_e5ecff rounded-3 px-xl-5 px-4 pt-4 pb-3"
          style={{ minHeight: "50rem" }}
        >
          <div className="d-xxl-flex">
            <div className="col-xxl-6 pe-xxl-5 mb-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <span className="font_montserrat fw_600 fs_21  text-start">
                  Orders & Sales
                </span>
                <span className="text-end" style={{ maxWidth: "18%" }}>
                  <Select
                    value={selectedOption}
                    onChange={(selectedOption) => {
                      setSelectedOption(selectedOption);
                    }}
                    options={options?.filter((item) => item?.label != "Daily")}
                    styles={customStyles}
                    defaultValue={defaultValue}
                  />
                </span>
              </div>
              <Cards
                ordercardData={ordercardData}
                selectedOption={selectedOption?.textDisplay}
              />
            </div>
            <div className="col-xxl-6">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <span className="font_montserrat fw_600 fs_21  text-start">
                  Orders & Sales Chart
                </span>
                <span className="text-end" style={{ width: "20%" }}>
                  <Select
                    value={barChartselectedOption}
                    onChange={(selectedOption) => {
                      setBarChartSelectedOption(selectedOption);
                    }}
                    options={options}
                    styles={customStyles}
                    defaultValue={defaultValue}
                  />
                </span>
              </div>
              <div className="border_1px_e5ecff rounded-4 p-1 pt-3">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart width={650} height={455} data={finaldata}>
                    <CartesianGrid vertical={false} stroke="#F9F9FC" />
                    <XAxis
                      dataKey={
                        barChartselectedOption?.value === "Daily"
                          ? "day"
                          : barChartselectedOption?.value === "Weekly"
                          ? "weeks"
                          : "month"
                      }
                      tick={{ fontSize: 11, fill: "#707070" }}
                      label={{ display: "none" }}
                      stroke={"#E5ECFF"}
                      tickFormatter={customx_axisTickFormatter}
                    />
                    <YAxis
                      dataKey={"totalSales"}
                      tickCount={9}
                      tick={{
                        fontSize: 11,
                        fontFamily: "Poppins,sans-serif",
                        fill: "#707070",
                      }}
                      stroke={"#E5ECFF"}
                      tickFormatter={customTickFormatter}
                      domain={[0, "auto"]}
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: "transparent" }}
                    />
                    <Bar
                      dataKey="totalSales"
                      fill="#8884d8"
                      barSize={10}
                      shape={<CustomBar />}
                    />
                  </BarChart>
                </ResponsiveContainer>
                <style>
                  {`
                  .recharts-xAxis .recharts-cartesian-axis-tick-line,
            .recharts-yAxis .recharts-cartesian-axis-tick-line {
              display: none;
            }
          `}
                </style>
              </div>
            </div>
          </div>
          <div className={`d-xxl-flex flex-xxl-row mt-4`}>
            <div
              className={`col-xxl-8 border_1px_e5ecff rounded-3 mx-xxl-3 mb-2 mb-xxl-0 ps-4 py-3 `}
            >
              <DashboardTable
                canWrite={canWrite}
                acceptFn={acceptFn}
                viewAll={true}
                tableTitle1="Active Orders"
                tableTitle2="(Orders Received)"
                tableHeading={
                  canRead("orders")
                    ? activeOrdersTableHeading
                    : activeOrdersTableHeading?.filter(
                        (item) => item?.label != "Actions"
                      )
                }
                tableBody={
                  canRead("orders")
                    ? recievedOrdersTableBody
                    : recievedOrdersTableBody?.map(
                        ({ Action, ...newObj }) => newObj
                      )
                }
                customStyles={{ maxWidth: "95%" }}
                tableBorder=""
                accept={true}
                viewPath={"/orders/status"}
                buttonPath={"/orders"}
              />
            </div>
            <div className={`col-xxl-4 border_1px_e5ecff rounded-3 p-3 px-4`}>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <span className="font_montserrat fw_500 fs_21  text-start">
                  Orders
                </span>
                <span className="text-end" style={{ maxWidth: "30%" }}>
                  <Select
                    value={pieChartSelectedOption}
                    onChange={(selectedOption) => {
                      setPieChartSelectedOption(selectedOption);
                    }}
                    options={options}
                    styles={customStyles}
                    defaultValue={defaultValue}
                  />
                </span>
              </div>
              {dashBoardData?.pieChart?.totalOrders > 0 ? (
                <>
                  <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                      <Pie
                        data={pieData}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        innerRadius={80}
                        outerRadius={120}
                        fill="#8884d8"
                        paddingAngle={0}
                        labelLine={false}
                        label={() => null}
                        stroke="none"
                      >
                        {data.map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                        <Label
                          position="center"
                          value={total}
                          fontSize={20}
                          fontWeight="bold"
                          dy={-8}
                          fill="#000000"
                        />
                        <Label
                          position="center"
                          value={`Orders per ${pieChartSelectedOption?.textDisplay}`}
                          fontSize={14}
                          dy={12}
                          fill="#707070"
                        />
                      </Pie>
                      <Tooltip
                        content={<CustomPieChartToolTip totalOrder={total} />}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  {pieChartSuggestions?.map((item) => (
                    <div
                      className={`d-flex align-items-center position-relative fw_500 mb-2 gap-2`}
                    >
                      <div
                        className={`${styles.outer_circle} d-flex align-items-center justify-content-center me-1`}
                        style={{ backgroundColor: item.backgroundColor }}
                      >
                        <span
                          className={`${styles.inner_circle} bg_color_ffffff`}
                        ></span>
                      </div>
                      {item.title}
                    </div>
                  ))}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "35.5rem" }}
                >
                  No orders placed yet
                </div>
              )}
            </div>
          </div>
          <div className="d-xxl-flex mt-4">
            <div className="col-xxl-6 mx-xxl-3">
              <DashboardTable
                viewAll={false}
                tableTitle1="Preparing Orders"
                tableHeading={
                  canRead("orders")
                    ? preparingOrdersTableHeading
                    : preparingOrdersTableHeading?.filter(
                        (item) => item?.label != "Actions"
                      )
                }
                tableBody={
                  canRead("orders")
                    ? preparingOrdersData
                    : preparingOrdersData?.map(
                        ({ Action, ...newObj }) => newObj
                      )
                }
                headerBackground={"#E5ECFF"}
                customStyles={{ maxWidth: "40rem" }}
                viewPath={"/orders/status"}
              />
            </div>
            <div className="col-xxl-6 mt-3 mt-xxl-0">
              <DashboardTable
                viewAll={false}
                tableTitle1="Dispatched Orders"
                tableHeading={
                  canRead("orders")
                    ? dispatchedOrdersTableHeading
                    : dispatchedOrdersTableHeading?.filter(
                        (item) => item?.label != "Actions"
                      )
                }
                tableBody={
                  canRead("orders")
                    ? dispatchedOrdersData
                    : dispatchedOrdersData?.map(
                        ({ Action, ...newObj }) => newObj
                      )
                }
                headerBackground={"#E5ECFF"}
                customStyles={{ maxWidth: "40rem" }}
                viewPath={"/orders/status"}
              />
            </div>
          </div>
          <div className="d-xxl-flex mt-3 pt-3">
            <div className="col-xxl-6 pe-xxl-5 mb-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <span className="font_montserrat fw_600 fs_21  text-start">
                  Analytics
                </span>
                <span className="text-end" style={{ maxWidth: "18%" }}>
                  <Select
                    value={analyticsselectedOption}
                    onChange={(analyticsselectedOption) => {
                      setAnalyticsselectedOption(analyticsselectedOption);
                    }}
                    options={options?.filter((item) => item?.label != "Daily")}
                    styles={customStyles}
                    defaultValue={defaultValue}
                  />
                </span>
              </div>
              <Cards
                type="analytics"
                ordercardData={analyticsData}
                selectedOption={setAnalyticsselectedOption?.textDisplay}
              />

              <Link
                target="_blank"
                className="color_FFFFFF text-decoration-none d-flex justify-content-center gap-3 fs_20 align-items-center bg_color_2820DA w-100 py-2  rounded-3 "
                to={
                  "https://analytics.google.com/analytics/web/#/p406910594/reports/intelligenthome"
                }
              >
                <GoogleIcon /> See More Analytics
              </Link>
            </div>
            <div className="col-xxl-6">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <span className="font_montserrat fw_600 fs_21  text-start">
                  Product Enquiries Chart
                </span>
                <span className="text-end" style={{ width: "20%" }}>
                  <Select
                    value={analyticsbarChartselectedOption}
                    onChange={(selectedOption) => {
                      setAnalyticsBarChartSelectedOption(selectedOption);
                    }}
                    options={options}
                    styles={customStyles}
                    defaultValue={defaultValue}
                  />
                </span>
              </div>
              <div className="border_1px_e5ecff rounded-4 p-1 pt-3">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart width={650} height={455} data={analyfinaldata}>
                    <CartesianGrid vertical={false} stroke="#F9F9FC" />
                    <XAxis
                      dataKey={
                        analyticsbarChartselectedOption?.value === "Daily"
                          ? "day"
                          : analyticsbarChartselectedOption?.value === "Weekly"
                          ? "weeks"
                          : "month"
                      }
                      tick={{ fontSize: 11, fill: "#707070" }}
                      label={{ display: "none" }}
                      stroke={"#E5ECFF"}
                      tickFormatter={analyCustomx_axisTickFormatter}
                    />
                    <YAxis
                      dataKey={"totalSales"}
                      tickCount={9}
                      tick={{
                        fontSize: 11,
                        fontFamily: "Poppins,sans-serif",
                        fill: "#707070",
                      }}
                      stroke={"#E5ECFF"}
                      tickFormatter={anlyCustomTickFormatter}
                      domain={[0, "auto"]}
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: "transparent" }}
                    />
                    <Bar
                      dataKey="totalSales"
                      fill="#8884d8"
                      barSize={10}
                      shape={<CustomBar />}
                    />
                  </BarChart>
                </ResponsiveContainer>
                <style>
                  {`
                  .recharts-xAxis .recharts-cartesian-axis-tick-line,
            .recharts-yAxis .recharts-cartesian-axis-tick-line {
              display: none;
            }
          `}
                </style>
              </div>
            </div>
          </div>
          <div className="d-xxl-flex mt-4">
            <div className="col-xxl-6 mx-xxl-3">
              <DashboardProductTable
                viewAll={false}
                tableTitle1="Today's Product Enquiries"
                tableHeading={todayOrderEnq}
                tableBody={productEnq}
                headerBackground={"#E5ECFF"}
                customStyles={{ maxWidth: "40rem" }}
                viewPath={"product-enquiry/view"}
              />
            </div>
            <div className="col-xxl-6 mt-3 mt-xxl-0">
              <DashboardProductTable
                viewAll={false}
                tableTitle1="Today's Contact Enquiries"
                tableHeading={todayContactEn}
                tableBody={contactEnq}
                headerBackground={"#E5ECFF"}
                customStyles={{ maxWidth: "40rem" }}
                viewPath={"/contact-enquiry/view"}
              />
            </div>
          </div>

          <div className="d-xxl-flex mt-4">
            <div className="col-xxl-6 mx-xxl-3">
              <DashboardProductTable
                topProduct={true}
                viewAll={false}
                tableTitle1="Top Product Enquiries"
                tableHeading={topProductEnq}
                tableBody={topproductEnqData}
                headerBackground={"#E5ECFF"}
                customStyles={{ maxWidth: "40rem" }}
                viewPath={"/products/view"}
              />
            </div>
          </div>
          <div className="p-xxl-5 p-3 pt-xxl-0 pt-0 mt-4 border_1px_e5ecff m-xxl-3  rounded-3">
            <DynamicTable
              backBtnDisplay={false}
              tabletitle="Out of Stock Products"
              placeholder="Search product here by id"
              modalText={"product"}
              loading={loading}
              reload={reload}
              error={error}
              tableHeading={tableDiscountHeader}
              tablebody={tableData}
              setInputValue={setInputValue}
              inputValue={inputValue}
              setCurrentPage={setCurrentPage}
              createButtonName="CREATE PRODUCTS"
              createpath="/products/create"
              // pagination={productsData?.pagination}
              setLoading={setLoading}
              setReload={setReload}
              id_name={"product_id"}
              filtration={false}
              rolePermission={"view"}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

const options = [
  { value: "Daily", label: "Daily", textDisplay: "day" },
  { value: "Monthly", label: "Monthly", textDisplay: "month" },
  { value: "Weekly", label: "Weekly", textDisplay: "week" },
];

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#E5ECFFB2",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins,sans-serif",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#2820DA",
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    padding: "0px",
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: "left", // You can change 'left', 'center', or 'right' as needed
  }),
};

const data = [
  { name: "MAR", value: 100 },
  { name: "FEB", value: 25 },
  { name: "JAN", value: 15 },
  { name: "APR", value: 30 },
  { name: "JAN", value: 23 },
  { name: "FEB", value: 25 },
  { name: "MAR", value: 15 },
  { name: "APR", value: 45 },
  { name: "JAN", value: 15 },
  { name: "FEB", value: 25 },
  { name: "MAR", value: 15 },
  { name: "APR", value: 30 },
];

const preparingOrdersTableHeading = [
  { label: "ID", value: "id" },
  { label: "No. of Items", value: "no_of_items" },
  { label: "Accepted On", value: "accepted_on" },
  { label: "Order Value", value: "order_value" },
  { label: "Actions", value: "actions" },
];

const dispatchedOrdersTableHeading = [
  { label: "ID", value: "id" },
  { label: "No. of Items", value: "no_of_items" },
  { label: "Dispatched On", value: "dispatched_on" },
  { label: "Order Value", value: "order_value" },
  { label: "Actions", value: "actions" },
];

const tableDiscountHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "ID", value: "id" },
  { label: "Product Name ", value: "product_name" },
  { label: "Category ", value: "category_id" },
  { label: "Status", value: "status" },
  { label: "Price", value: "price" },
  { label: "Actions", value: "Actions" },
];

const todayOrderEnq = [
  { label: "Order Enquiry ID", value: "code" },
  { label: "Product ID", value: "product_code" },
  { label: "Name", value: "name" },
  { label: "Mobile Number", value: "phone_number" },
  { label: "", value: "" },
  { label: "Actions", value: "actions" },
];
const topProductEnq = [
  { label: "Product ID", value: "product_code" },
  { label: "Name", value: "name" },
  { label: "Enquiry count", value: "enquiryCount" },
  { label: "Actions", value: "actions" },
];

const todayContactEn = [
  { label: "Contact Enquiry ID", value: "id" },
  { label: "Name", value: "no_of_items" },
  { label: "Mobile Number", value: "dispatched_on" },
  { label: "", value: "" },
  { label: "Actions", value: "actions" },
];
