import React, { useEffect, useState } from "react";
import EditIcon from "../../../assets/icons/editIcon";
import AddressIcon from "../../../assets/icons/addressicon";
import PrintIcon from "../../../assets/icons/printIcon";
import dayjs from "dayjs";
import AddDeliveryDetails from "./models/add-delivery-details";
import EditAddress from "./models/edit-address";
import PrintContent from "./print-component";
import ReactDOM from "react-dom";
import { contactInfoViewAction } from "../../../redux/actions/configuration-action";
import { useDispatch } from "react-redux";
import navbarLogo from "../../../assets/icons/ff-logo-print.svg";
import useSiteSettings from "../../helper-functions/hooks/useSitesettings";
import AddressLocation from "../address/address-location";

function ShippingDetails({
  data,
  orderDetails,
  selectedOption,
  reload,
  setReload,
  deliveryPartners,
  canWrite
}) {
  const dispatch = useDispatch();
  const [showDeliveryPartner, setShowDeliveryPartner] = useState(false);
  const DeliveryPartnerClose = () => setShowDeliveryPartner(false);
  const siteSettingsData = useSiteSettings();
  console.log(siteSettingsData?.data?.site_icon, "tyrtyteryrtyrtytr");
  const [showshipping, setShowshipping] = useState(false);
  const shippingClose = () => setShowshipping(false);
  const deliver = orderDetails?.driver ?? orderDetails?.delivery_partner_details;
  const [contactInfoData, setContactInfoData] = useState([]);

  useEffect(() => {
    dispatch(contactInfoViewAction(onFetchSuccess, onFetchError));
  }, [reload]);
  const onFetchSuccess = (data) => {
    setContactInfoData(data?.data);
  };
  const onFetchError = () => { };
  const delayTime = 2000;

  const handlePrint = () => {
    const printContent = () => (
      <div>
        <PrintContent orderDetails={orderDetails} contactInfoData={contactInfoData} navbarLogo={navbarLogo ?? siteSettingsData?.data?.site_icon} />
      </div>
    );
    // printContent()
    // setTimeout(printContent, delayTime);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>Print Content</title></head><body>"
    );
    ReactDOM.render(printContent(), printWindow.document.body);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    // Print the content in the current window
    printWindow.print();
    // printWindow.close();
  };
  return (
    <>
      <div className="row p-3 mt-lg-0 mt-3">
        <div className="col-xl-6">
          <label className="fs_16 font_montserrat fw_600 mb-2">
            Customer Details
          </label>
          <div>
            <label className="color_707070 fs_14">Customer ID</label>
            <div className="color_030304 mb-4 fs_13 fw_500">
              {data?.customer_id}
            </div>
          </div>
          <div>
            <label className="color_707070 fs_14">Customer Name</label>
            <div className="color_030304 mb-4 fs_13 fw_500">
              {orderDetails?.customerDetails?.name}
            </div>
          </div>
          {
            orderDetails?.delivery_type != "pickup" &&
            <>  <div className="d-flex gap-2 align-items-center">
              <label className="fs_16 font_montserrat fw_600 ">
                Shipping Details
              </label>
              {canWrite("orders") &&
                <button
                  onClick={() => setShowshipping(true)}
                  className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1"
                >
                  <EditIcon /> <span>Edit</span>
                </button>}
            </div>
              <div>
                <div className="color_030304 fs_16 fw_500 mt-4">
                  <AddressIcon />
                  <span>
                    {" "}
                    {"" + data?.address_name}
                    <span className="color_3AD64C fw_600">
                      {data?.is_default && "(Default Address)"}
                    </span>
                  </span>
                </div>
                <p className="color_030304 fs_16 fw_400">
                  {data?.address_line1 + " " +
                    data?.address_line2 +
                    " " +
                    data?.city +
                    " " +
                    data?.state +
                    " " +
                    data?.country}
                </p>
                <p className="color_030304 fs_16 fw_400">
                  +91 {data?.phone_number}
                </p>
              </div></>}

          {
            (orderDetails?.orders_status != "received") &&
            <button
              className="bg_color_E5ECFFB2 color_2820DA fw_500 fs_12 px-3 py-1 rounded-2 d-flex align-items-center"
              onClick={handlePrint}
            >
              <PrintIcon />
              <span>PRINT DELIVERY DETAILS</span>
            </button>
          }
        </div>
        <div className="col-xl-6">
          {((localStorage.getItem("delivery_type") === "adair" && orderDetails?.delivery_partner_details != null) || (localStorage.getItem("delivery_type") === "self") || (localStorage.getItem("delivery_type") === "third_party"))
            && <div className="px-xl-5 mt-xl-0 mt-5">
              <div className="d-flex gap-2 align-items-center">
                <label className="fs_16 font_montserrat fw_600 ">
                  Delivery Details
                </label>

                {
                  localStorage.getItem("delivery_type") != "adair" &&
                  <button
                    onClick={() => setShowDeliveryPartner(true)}
                    className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1"
                  >
                    <EditIcon /> Edit
                  </button>
                }
              </div>
              {
                ((orderDetails?.driver === null && orderDetails?.delivery_partner_details === null) && localStorage.getItem("delivery_type") != "adair") &&
                <>
                  <label className="color_707070 fs_14 mt-3 mb-2">Please choose your delivery partner</label>
                  <button
                    onClick={() => setShowDeliveryPartner(true)}
                    className="color_2820DA border_2px_2820DA rounded-2 fs_14 fw_500 px-2 d-flex align-items-center gap-1"
                  >
                    Choose
                  </button>
                </>
              }
              {(orderDetails?.driver != null || (orderDetails?.delivery_partner_details != null)) && (
                <div className="mt-3">
                  <label className="color_707070 fs_14">Delivery Partner</label>
                  <div className="color_030304 mb-4 fs_13 fw_500">
                    {deliver?.name ?? deliver?.rider_name}
                  </div>
                  {
                    localStorage.getItem("delivery_type") === "adair" &&
                    <>
                      <label className="color_707070 fs_14">Phone number</label>
                      <div className="color_030304 mb-4 fs_13 fw_500">
                        {deliver?.rider_phone}
                      </div>
                    </>
                  }
                  {
                    localStorage.getItem("delivery_type") != "adair" &&
                    <>
                      <label className="color_707070 fs_14">Dispatched On</label>
                      <div className="color_030304 mb-4 fs_13 fw_500">
                        {dayjs(deliver?.updatedAt).format(
                          "DD MMMM YYYY hh:mm:ss A"
                        )}
                      </div>
                    </>
                  }
                </div>
              )}
            </div>
          }
        </div>
      </div>
      <EditAddress
        orderDetails={orderDetails ?? orderDetails}
        show={showshipping}
        modalClose={shippingClose}
        reload={reload}
        setReload={setReload}
      />
      <AddDeliveryDetails
        deliveryPartners={deliveryPartners}
        reload={reload}
        setReload={setReload}
        id={orderDetails?.id}
        orderDetails={orderDetails}
        selectedOption={selectedOption}
        show={showDeliveryPartner}
        modalClose={DeliveryPartnerClose}
      />
    </>
  );
}

export default ShippingDetails;
