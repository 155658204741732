import React, { useState } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF, OverlayViewF } from '@react-google-maps/api';
import { TileLayer } from 'react-leaflet/TileLayer'
import 'leaflet/dist/leaflet.css';
import { BikeIcon, BoatIcon, UserIcon } from '../../../assets/icons/helperIcon'
import { MapContainer, Popup, Marker, Polyline } from 'react-leaflet';

const TrackDriver = ({ driverLatLng, userLatLng, orderDetails }) => {
    const [marker, setMarker] = useState({
        lat: driverLatLng?.lat,
        lng: driverLatLng?.lng,
    });
    const [map, setMap] = React.useState(null)
    const [zoom, setZoom] = React.useState(15)

    const onLoad = React.useCallback(function callback(map) {
        map.panTo(marker);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const maxBounds = [
        [6.755, 68.11],  // Southwest coordinates (latitude, longitude)
        [35.674, 97.395] // Northeast coordinates (bounding box of India)
    ];




    






    return (
        <>
            {/* <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '35vh',
                    borderRadius: "7px"
                }}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <MarkerF
                    position={{
                        lat: marker?.lat,
                        lng: marker?.lng
                    }}
                    icon={driver}
                />
                <MarkerF
                    position={{
                        lat: userLatLng?.lat,
                        lng: userLatLng?.lng
                    }}
                    icon={user}
                />

            </GoogleMap> */}

            <div style={{ height: "40vh" }} >
                <MapContainer
                    maxBounds={maxBounds}
                    // ref={viewPage ? null : setMap}
                    center={marker}
                    zoom={19}
                    style={{ height: "100%", width: "100%" }}
                    scrollWheelZoom={true}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={
                        [
                            marker?.lat ?? 12.2958104,
                            marker?.lng ?? 76.639380
                        ]
                    } icon={BikeIcon}>
                        <Popup>
                            Estimated Delivery Time  <br />
                            {orderDetails?.estimated_eta ? orderDetails?.estimated_eta + " mins" : "--"}
                        </Popup>
                    </Marker>
                    <Marker position={
                        [
                            userLatLng?.lat ?? 12.2958104,
                            userLatLng?.lng ?? 76.639380
                        ]
                    } icon={UserIcon}>
                        <Popup>
                            Estimated Delivery Time  <br />
                            {orderDetails?.estimated_eta ? orderDetails?.estimated_eta + " mins" : "--"}
                        </Popup>
                    </Marker>
                    <Polyline positions={[[marker.lat, marker.lng], [userLatLng.lat, userLatLng.lng]]} color="blue" />
                </MapContainer>
            </div >
        </>

    )
}

export default TrackDriver