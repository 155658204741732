import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  brandsEditAction,
  brandsDeleteAction,
  brandsViewAction,
} from "../../redux/actions/brands-action";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import errorToast from "../../components/helper-functions/helper/error-toast";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import NotificationComponent from "../../components/notificationComponent";
const BrandsView = () => {
  const params = useParams();
  const [tagsDetails, setTagsDetails] = useState();
  const [isImgLoading, setIsImgLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");

  useEffect(() => {
    dispatch(brandsViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setTagsDetails(data?.data);
    setActiveStatus(data?.data?.is_active);
  };
  const onError = (data) => {
    setLoading(false);
  };

  const [edited, setEdited] = useState(false);

  const [activeStatus, setActiveStatus] = useState(false);

  const toggleActiveStatus = () => {
    const newStatus = !activeStatus;
    setActiveStatus(newStatus);
    dispatch(
      brandsEditAction(
        {
          brand_id: params.id,
          brand_picture: tagsDetails?.brand_picture,
          name: tagsDetails?.name,
          slug: tagsDetails?.slug,
          is_active: newStatus,
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };

  const onSuccessEdit = (data) => {
    successToast(data.messsage);
    setEdited(!edited);
    // if (currentPage) {
    //   navigate("/brands", { state: { page: currentPage } });
    // } else {
    //   navigate(-1);
    // }
  };
  const onErrorEdit = (data) => {
    errorToast(data?.message);
  };

  console.log(tagsDetails?.is_active, "jhchjcjhcjgcjc");

  const tagsFindOne = [
    {
      label: "Image",
      value: (
        <>
          {isImgLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "250px", height: "250px" }}
            >
              <LoadingSpinner size="lg" />
            </div>
          )}
          <div
            className={`${
              imageRegex.test(tagsDetails?.brand_picture) === false &&
              "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
            }`}
            style={{ width: "250px", height: !isImgLoading && "250px" }}
          >
            <img
              className="rounded-3 mt-1"
              src={
                imageRegex.test(tagsDetails?.brand_picture)
                  ? tagsDetails?.brand_picture
                  : UplaodImgLogo
              }
              width={
                imageRegex.test(tagsDetails?.brand_picture) ? "250px" : "100"
              }
              height={
                imageRegex.test(tagsDetails?.brand_picture) ? "250px" : "100"
              }
              alt="image"
              onLoad={() => setIsImgLoading(false)}
              style={{ display: isImgLoading ? "none" : "block" }}
            />
          </div>
        </>
      ),
    },
    {
      label: "Brand Name",
      value: tagsDetails?.name,
    },
    {
      label: "Slug",
      value: tagsDetails?.slug,
    },
  ];
  return (
    <>
      <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          <InnerLayout
            name={`${tagsDetails?.code ?? "Brands View"}`}
            id={tagsDetails?.id}
            deleteAction={brandsDeleteAction}
            path={`/brands`}
            editButtonPath={`/brands/edit/${tagsDetails?.id}`}
            rolePermission={"brands"}
            type="brands"
            handleStatusBtn={toggleActiveStatus}
            activeStatus={activeStatus}
          />

          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            {tagsFindOne.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <label className="color_707070 fs_14">{item.label}</label>
                  <div className="color_030304 fs_14 mb-1">{item.value}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default BrandsView;
