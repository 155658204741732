import React from "react";

const ThemeIcon = ({ width = "20", height = "18", fill = "#707070" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.25V15C1 15.5967 1.23705 16.169 1.65901 16.591C2.08097 17.0129 2.65326 17.25 3.25 17.25H16.75C17.3467 17.25 17.919 17.0129 18.341 16.591C18.7629 16.169 19 15.5967 19 15V5.25M1 5.25V3C1 2.40326 1.23705 1.83097 1.65901 1.40901C2.08097 0.987053 2.65326 0.75 3.25 0.75H16.75C17.3467 0.75 17.919 0.987053 18.341 1.40901C18.7629 1.83097 19 2.40326 19 3V5.25M1 5.25H19M3.25 3H3.258V3.008H3.25V3ZM5.5 3H5.508V3.008H5.5V3ZM7.75 3H7.758V3.008H7.75V3Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ThemeIcon;
