import React from "react";

const TagsIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.03028 6.94235C5.32352 7.23559 5.32352 7.71033 5.03028 8.00282C4.73704 8.29606 4.26231 8.29606 3.96982 8.00282C3.67657 7.70958 3.67657 7.23484 3.96982 6.94235C4.26306 6.64911 4.73779 6.64911 5.03028 6.94235ZM15.4189 11.506L14.9952 11.9297C14.975 12.8544 14.6165 13.7724 13.919 14.4789L11.5438 16.8848C10.8366 17.601 9.89313 17.997 8.88666 18H8.87391C7.87269 18 6.93147 17.61 6.22349 16.902L0.661671 11.3875C0.174186 10.9007 -0.0613062 10.231 0.0136914 9.54777L0.587423 4.36094C0.625672 4.01895 0.890413 3.74671 1.2309 3.70021L6.39749 2.99373C7.09646 2.90073 7.79994 3.13847 8.29343 3.63196L13.8995 9.19078C14.1897 9.48102 14.4222 9.80801 14.5977 10.1568C15.2067 9.28228 15.1212 8.06057 14.3412 7.28059L8.64817 1.72702C8.47642 1.55528 8.23943 1.47578 8.00994 1.50803L2.84335 2.2145C2.43161 2.27 2.05438 1.98276 1.99813 1.57327C1.94263 1.16304 2.22987 0.78505 2.63936 0.728802L7.80669 0.0215743C8.49742 -0.0766726 9.2084 0.16482 9.70263 0.660554L15.3942 6.21413C16.8537 7.67283 16.8619 10.0443 15.4182 11.5067L15.4189 11.506ZM12.8405 10.2535L7.23446 4.69467C7.06722 4.52743 6.83247 4.45018 6.59923 4.47943L2.01313 5.10716L1.50389 9.71202C1.47914 9.93926 1.55714 10.1628 1.71914 10.324L7.28096 15.8386C7.7077 16.2661 8.27243 16.4993 8.87316 16.4993H8.88066C9.48514 16.4978 10.0514 16.2601 10.4751 15.8296L12.8503 13.4236C13.7165 12.5462 13.712 11.1235 12.8398 10.252L12.8405 10.2535Z"
        fill={fill}
      />
    </svg>
  );
};

export default TagsIcon;
