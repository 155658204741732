import { api } from "./api";

export const contactEnquiryTableApi = (data, params) => {
  return api.get(
    `/core/admin-contactus/list?search=${data?.search}&page=${data?.page}&page_size=${data?.size}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};
export const contactEnquiryViewApi = (data) => {
  return api.get(`/core/admin-contactus/view?contact_us_id=${data}`);
};