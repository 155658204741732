// export const url = "https://api-ecommerce.firstfloor.site/api";
// export const url = "https://api-ksb.firstfloor.agency/api";
//zs materials
// export const url = "https://api.zsmaterials.in/api";

import { io } from "socket.io-client";


// console.log(process.env.REACT_APP_API_URL, "etfresfjghsdjgfhsd");
export const url = process.env.REACT_APP_API_URL;
export const socket = io(process.env.REACT_APP_OTHER_VARIABLE);
export const storeName = process.env.REACT_APP_STORE_NAME;
console.log("from config file env is",storeName);






