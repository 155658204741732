import React, { useEffect } from "react";
import "./index.css";
import Index from "./routers/index";
import { Provider } from "react-redux";
import store from "./redux/store";
import ScrollToTop from "./components/helper-functions/helper/scroll-to-top";
import { socket } from "./redux/config";

function App() {
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    console.log(userId, "sfdfsdfsfsdf");
    if (userId) {
      socket.emit("connected", userId);
    }
  }, []);
  return (
    <Provider store={store}>
      <Index />
    </Provider>
  );
}

export default App;
