import React from 'react'

function HistoryIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3C8.27654 3.00123 6.61441 3.6397 5.33333 4.79258V3.58333C5.33333 3.42862 5.27187 3.28025 5.16248 3.17085C5.05308 3.06146 4.90471 3 4.75 3C4.59529 3 4.44692 3.06146 4.33752 3.17085C4.22812 3.28025 4.16667 3.42862 4.16667 3.58333V5.33333C4.16667 5.79746 4.35104 6.24258 4.67923 6.57077C5.00742 6.89896 5.45254 7.08333 5.91667 7.08333H7.66667C7.82138 7.08333 7.96975 7.02187 8.07914 6.91248C8.18854 6.80308 8.25 6.65471 8.25 6.5C8.25 6.34529 8.18854 6.19692 8.07914 6.08752C7.96975 5.97812 7.82138 5.91667 7.66667 5.91667H5.91667C5.89699 5.91375 5.87752 5.90966 5.85833 5.90442C6.80889 4.94707 8.06224 4.34929 9.40445 4.21314C10.7467 4.07698 12.0945 4.41088 13.218 5.15784C14.3414 5.9048 15.1708 7.01852 15.5645 8.30889C15.9583 9.59925 15.892 10.9863 15.3769 12.2332C14.8619 13.4801 13.93 14.5096 12.7405 15.146C11.5509 15.7824 10.1773 15.9862 8.85422 15.7226C7.53112 15.459 6.34051 14.7444 5.48562 13.7008C4.63072 12.6571 4.16455 11.3491 4.16667 10C4.16667 9.84529 4.10521 9.69692 3.99581 9.58752C3.88642 9.47812 3.73804 9.41667 3.58333 9.41667C3.42862 9.41667 3.28025 9.47812 3.17085 9.58752C3.06146 9.69692 3 9.84529 3 10C3 11.3845 3.41054 12.7378 4.17971 13.889C4.94888 15.0401 6.04213 15.9373 7.32122 16.4672C8.6003 16.997 10.0078 17.1356 11.3656 16.8655C12.7235 16.5954 13.9708 15.9287 14.9497 14.9497C15.9287 13.9708 16.5954 12.7235 16.8655 11.3656C17.1356 10.0078 16.997 8.6003 16.4672 7.32122C15.9373 6.04213 15.0401 4.94888 13.889 4.17971C12.7378 3.41054 11.3845 3 10 3Z" fill="#707070" />
            <path d="M10.0001 6.5C9.84537 6.5 9.697 6.56146 9.5876 6.67085C9.47821 6.78025 9.41675 6.92862 9.41675 7.08333V10C9.41678 10.1547 9.47826 10.303 9.58766 10.4124L11.3377 12.1624C11.4477 12.2687 11.595 12.3275 11.748 12.3261C11.9009 12.3248 12.0472 12.2635 12.1554 12.1553C12.2635 12.0472 12.3249 11.9008 12.3262 11.7479C12.3276 11.595 12.2688 11.4476 12.1625 11.3376L10.5834 9.7585V7.08333C10.5834 6.92862 10.522 6.78025 10.4126 6.67085C10.3032 6.56146 10.1548 6.5 10.0001 6.5Z" fill="#707070" />
        </svg>
    )
}

export default HistoryIcon