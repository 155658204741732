import { api } from "./api";

export const ordersTableApi = (data, params) => {
  console.log(data);
  return api.post(`/core/orders/lists`, JSON.stringify(data)
  );
};

export const ordersViewApi = (data) => {
  return api.get(`/core/orders/view?order_id=${data}`);
};

export const ordersEditApi = (params) => {
  return api.post(`/core/orders/edit`, JSON.stringify(params));
};

export const ordersCreateApi = (params) => {
  return api.post(`/core/orders/create`, JSON.stringify(params));
};

export const billingGenerateApi = (params) => {
  return api.post(`/core/orders/billing-lists`, JSON.stringify(params));
};

export const addressFetchApi = (data, params) => {
  return api.get(
    `/core/orders/customer-address?customer_id=${data?.id}`,
    JSON.stringify(params)
  );
};

export const ordersRejectApi = (params) => {
  return api.post(`/core/orders/reject-order`, JSON.stringify(params));
};
export const ordersPartialRejectApi = (params) => {
  return api.post(`/core/orders/partially-reject-order`, JSON.stringify(params));
};


export const ordersBillingEditApi = (params) => {
  return api.post(`/core/orders/edit-billing`, JSON.stringify(params));
};

export const ordersDeliveryPartnersApi = (data, params) => {
  console.log(data);
  return api.get(
    `/core/orders/driver-list`,
    JSON.stringify(params)
  );
};

export const ordersReturnEditApi = (params) => {
  return api.post(`/core/orders/edit-return-details`, JSON.stringify(params));
};

export const ordersServiceApi = (data) => {
  return api.post(`/core/orders/get-price-details`, JSON.stringify(data));
};

export const ordersAssignApi = (params) => {
  return api.post(`/core/orders/assign-order`, JSON.stringify(params));
};

