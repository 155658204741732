import { call, takeLatest } from "redux-saga/effects";
import {
  attributesEditApi,
  attributesDeleteApi,
  attributesMultiDeleteApi,
  attributesTableApi,
  attributesViewApi,
  attributesCreateApi,
  attributesDropDownListApi,
} from "../apis/attributes-api";
import {
  ATTRIBUTES_EDIT,
  ATTRIBUTES_DELETE,
  ATTRIBUTES_MULTI_DELETE,
  ATTRIBUTES_TABLE,
  ATTRIBUTES_VIEW,
  ATTRIBUTES_CREATE,
  ATTRIBUTES_DROPDOWN_LIST,
} from "../types";

export function* attributesTableWorker(action) {
  try {
    const res = yield call(attributesTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* attributesViewWorker(action) {
  try {
    const res = yield call(attributesViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* attributesDeleteWorker(action) {
  try {
    const res = yield call(attributesDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* attributesMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(attributesMultiDeleteApi, { data: action.data });
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error);
  }
}

export function* attributesCreateWorker(action) {
  try {
    const res = yield call(attributesCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* attributesEditWorker(action) {
  try {
    const res = yield call(attributesEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* attributesDropDownListWorker(action) {
  try {
    const res = yield call(attributesDropDownListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchattributesTableWorker() {
  yield takeLatest(ATTRIBUTES_TABLE, attributesTableWorker);
}
export function* watchattributesViewWorker() {
  yield takeLatest(ATTRIBUTES_VIEW, attributesViewWorker);
}
export function* watchattributesEditWorker() {
  yield takeLatest(ATTRIBUTES_EDIT, attributesEditWorker);
}
export function* watchattributesDeleteWorker() {
  yield takeLatest(ATTRIBUTES_DELETE, attributesDeleteWorker);
}
export function* watchattributesMultiDeleteWorker() {
  yield takeLatest(ATTRIBUTES_MULTI_DELETE, attributesMultiDeleteWorker);
}
export function* watchattributesCreateWorker() {
  yield takeLatest(ATTRIBUTES_CREATE, attributesCreateWorker);
}
export function* watchattributesDropDownListWorker() {
  yield takeLatest(ATTRIBUTES_DROPDOWN_LIST, attributesDropDownListWorker);
}
