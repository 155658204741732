import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/ui/layouts/inner-layout";
import { Form, Formik } from "formik";
import { removeUnderScore } from "../../../components/helper-functions/helper/helper";
import ErrorMsg from "../../../components/ui/forms/error-msg";
import * as adminRolesAction from "../../../redux/actions/admin-roles-action";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import DynamicTable from "../../../components/ui/tables/table";
import usePermissions from "../../../components/helper-functions/hooks/usePermission-checker";
import NotificationComponent from "../../../components/notificationComponent";

const AdminRolesView = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [fetchLoading, setFetchLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const [adminRoleData, setAdminRoleData] = useState({});
  const [adminUsersData, setAdminUsersData] = useState({
    admins: [],
    pagination: {},
  });

  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setFetchLoading(true);
    dispatch(
      adminRolesAction.adminRoleViewAction(
        {
          role_id: params.id,
          page: currentPage,
          page_size: 10,
          search: inputValue,
          sort: sortConfig,
        },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [inputValue, currentPage, sortConfig]);
  console.log(params.id);
  const onFetchSuccess = (data) => {
    setFetchLoading(false);
    setAdminRoleData(data?.data?.role);
    // successToast(data?.message);
    console.log("success");
    console.log(data?.data?.pagination);
    setAdminUsersData({
      admins: data?.data?.role?.Admins,
      pagination: data?.data?.pagination,
    });
  };

  const onFetchError = (data) => {
    setFetchLoading(false);
    // errorToast(data?.data?.message);
  };

  const initialValues = {
    role_id: adminRoleData?.id ? adminRoleData?.id : "",
    role_name: adminRoleData?.role_name ? adminRoleData?.role_name : "",
    permission: adminRoleData?.permission
      ? {
          admin_users: {
            read: adminRoleData.permission.admin_users?.read || false,
            write: adminRoleData.permission.admin_users?.write || false,
          },
          admin_roles: {
            read: adminRoleData.permission.admin_roles?.read || false,
            write: adminRoleData.permission.admin_roles?.write || false,
          },
          products: {
            read: adminRoleData.permission.products?.read || false,
            write: adminRoleData.permission.products?.write || false,
          },
          categories: {
            read: adminRoleData.permission.categories?.read || false,
            write: adminRoleData.permission.categories?.write || false,
          },
          discounts: {
            read: adminRoleData.permission.discounts?.read || false,
            write: adminRoleData.permission.discounts?.write || false,
          },
          attributes: {
            read: adminRoleData.permission.attributes?.read || false,
            write: adminRoleData.permission.attributes?.write || false,
          },
          tags: {
            read: adminRoleData.permission.tags?.read || false,
            write: adminRoleData.permission.tags?.write || false,
          },
          orders: {
            read: adminRoleData.permission.orders?.read || false,
            write: adminRoleData.permission.orders?.write || false,
          },
          customers: {
            read: adminRoleData.permission.customers?.read || false,
            write: adminRoleData.permission.customers?.write || false,
          },
          addresses: {
            read: adminRoleData.permission.addresses?.read || false,
            write: adminRoleData.permission.addresses?.write || false,
          },
          configuration: {
            read: adminRoleData.permission.configuration?.read || false,
            write: adminRoleData.permission.configuration?.write || false,
          },
          account: {
            read: adminRoleData.permission.account?.read || false,
            write: adminRoleData.permission.account?.write || false,
          },
          brands: {
            read: adminRoleData.permission.brands?.read || false,
            write: adminRoleData.permission.brands?.write || false,
          },
          deliveryPartner: {
            read: adminRoleData.permission.deliveryPartner?.read || false,
            write: adminRoleData.permission.deliveryPartner?.write || false,
          },
          productEnquiry: {
            read: adminRoleData.permission.productEnquiry?.read || false,
            write: adminRoleData.permission.productEnquiry?.write || false,
          },
          contactUs: {
            read: adminRoleData.permission.contactUs?.read || false,
            write: adminRoleData.permission.contactUs?.write || false,
          },
          plugins: {
            read: adminRoleData.permission.plugins?.read || false,
            write: adminRoleData.permission.plugins?.write || false,
          },
          themes: {
            read: adminRoleData.permission.themes?.read || false,
            write: adminRoleData.permission.themes?.write || false,
          },
        }
      : {
          admin_users: {
            read: false,
            write: false,
          },
          admin_roles: {
            read: false,
            write: false,
          },
          products: {
            read: false,
            write: false,
          },
          categories: {
            read: false,
            write: false,
          },
          discounts: {
            read: false,
            write: false,
          },
          attributes: {
            read: false,
            write: false,
          },
          tags: {
            read: false,
            write: false,
          },
          orders: {
            read: false,
            write: false,
          },
          customers: {
            read: false,
            write: false,
          },
          addresses: {
            read: false,
            write: false,
          },
          configuration: {
            read: false,
            write: false,
          },
          account: {
            read: false,
            write: false,
          },
          brands: {
            read: false,
            write: false,
          },
          deliveryPartner: {
            read: false,
            write: false,
          },
          productEnquiry: {
            read: false,
            write: false,
          },
          contactUs: {
            read: false,
            write: false,
          },
          plugins: {
            read: false,
            write: false,
          },
          themes: {
            read: false,
            write: false,
          },
        },
  };

  const tableData = adminUsersData?.admins?.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: item?.name,
    email_address: item?.email_address,
    Action: ["/admin/users/view/"],
  }));
  const { canRead } = usePermissions();
  return (
    <>
      <NotificationComponent />
      <Formik enableReinitialize={true} initialValues={initialValues}>
        <Form className="">
          <InnerLayout
            name={`${adminRoleData?.code}`}
            page="view"
            edit={true}
            path={`/admin/roles`}
            deleteAction={adminRolesAction?.adminRoleDeleteAction}
            id={adminRoleData?.id}
            editButtonPath={`/admin/roles/edit/${adminRoleData?.id}`}
            rolePermission={"admin_roles"}
            type="admin roles"
          />
          <div
            className="ms-5 pe-4 pe-md-0 pt-4 pt-lg-0 mt-5"
            style={{ maxWidth: "36rem" }}
          >
            <label className="text-black fw_500 fs_14 color_707070">
              Role Name
            </label>
            <div className="text-black fs_15 mb-4">
              {adminRoleData?.role_name
                ? removeUnderScore(adminRoleData?.role_name)
                : ""}
            </div>
          </div>
          <div className="ms-5 pe-4 pe-md-0" style={{ maxWidth: "25rem" }}>
            <table className="">
              <colgroup>
                <col style={{ width: "45%" }} />
                <col style={{ width: "42%" }} />
                <col style={{ width: "45%" }} />
              </colgroup>
              <thead>
                <tr className=" ">
                  <th className="color_707070 fw_400 font_poppins">
                    Permission
                  </th>
                  <th className="color_030304 fw_400 text-center font_poppins">
                    Read
                  </th>
                  <th className="color_030304 fw_400 text-center font_poppins">
                    Write
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(initialValues.permission).map(
                  ([key, value]) => (
                    <tr key={key}>
                      <td
                        className={`${
                          initialValues.permission[key].write ||
                          initialValues.permission[key].read
                            ? "color_030304"
                            : "color_9E9E9E"
                        }  fw_400 font_poppins pt-2`}
                      >
                        {key ? removeUnderScore(key) : "--"}
                      </td>
                      <td className="text-center pt-2">
                        <input
                          style={{ width: "15px", height: "15px" }}
                          className="form-check-input"
                          type="checkbox"
                          name={`permission.${key}.read`}
                          checked={
                            initialValues.permission[key] &&
                            initialValues.permission[key].read
                              ? true
                              : false
                          }
                          disabled
                        />
                      </td>
                      <td className="text-center pt-2">
                        <input
                          style={{ width: "15px", height: "15px" }}
                          className="form-check-input"
                          type="checkbox"
                          name={`permission.${key}.write`}
                          checked={
                            initialValues.permission[key] &&
                            initialValues.permission[key].write
                              ? true
                              : false
                          }
                          disabled
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <ErrorMsg
              name="permission"
              textClass="color_F24444 fs_16 d-block"
            />
          </div>
          <div
            className="
          col-lg-8 col-12 px-lg-0 px-2 ps-lg-4 mt-lg-4 mt-5 mb-3"
          >
            {canRead("admin_users") && (
              <DynamicTable
                tabletitle={"Admin Users"}
                tablebody={tableData}
                tableHeading={tableAdminRolesHeader}
                setInputValue={setInputValue}
                inputValue={inputValue}
                pagination={adminUsersData?.pagination}
                setCurrentPage={setCurrentPage}
                backBtnDisplay={false}
                placeholder={
                  "Search admin users here by id, name and email address"
                }
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
                isMinHeight={false}
                fromPage="view"
              />
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default AdminRolesView;
const tableAdminRolesHeader = [
  { label: "ID", value: "id" },
  { label: "Name", value: "name" },
  { label: "Email Address", value: "email_address" },
  { label: "Actions", value: "Actions" },
];
