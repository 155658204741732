import React, { Profiler, useEffect, useState } from "react";
import CategoriesTable from "../modules/categories/categories";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageNotFound from "./404";
import Home from "../modules/index";
import MainLayout from "../components/ui/layouts/main-layout";
import Login from "../modules/auth/login";
import Modal from "../modules/categories/modal";
import ForgotPassword from "../modules/auth/forgot-password";
import ResetEmailSent from "../modules/auth/reset-email-sent";
import ResetPassword from "../modules/auth/reset-password";
import PasswordSuccessfullyUpdated from "../modules/auth/password-successfully-updated";
import ProtectedRoute from "./protectedRoute";
import PrivateRoute from "./privateRoute";
import { ToastContainer } from "react-toastify";
import CategoriesView from "../modules/categories/categories-view";
import CategoriesCreate from "../modules/categories/categories-create";
import CategoriesEdit from "../modules/categories/categories-edit";
import AdminRolesCreate from "../modules/admin/roles/admin-roles-create";
import AdminUsersTable from "../modules/admin/users/admin-users";
import AdminUsersView from "../modules/admin/users/admin-users-view";
import AdminRolesTable from "../modules/admin/roles/admin-roles";
import AdminRolesView from "../modules/admin/roles/admin-roles-view";
import AdminRolesEdit from "../modules/admin/roles/admin-roles-edit";
import AdminUsersEdit from "../modules/admin/users/admin-users-edit";
import TagsTable from "../modules/tags/tags";
import TagsView from "../modules/tags/tags-view";
import TagsEdit from "../modules/tags/tags-edit";
import TagsCreate from "../modules/tags/tags-create";
import DeliveryPartnerTable from "../modules/delivery-partner/delivery-Partner";
import DeliveryPartnerView from "../modules/delivery-partner/delivery-Partner-view";
import DeliveryPartnerEdit from "../modules/delivery-partner/delivery-Partner-edit";
import DeliveryPartnerCreate from "../modules/delivery-partner/delivery-Partner-create";
import BrandsTable from "../modules/brands/brands";
import BrandsView from "../modules/brands/brands-view";
import BrandsEdit from "../modules/brands/brands-edit";
import BrandsCreate from "../modules/brands/brands-create";
import AttributesTable from "../modules/attributes/attributes";
import AttributesView from "../modules/attributes/attributes-view";
import AttributesEdit from "../modules/attributes/attributes-edit";
import AttributesCreate from "../modules/attributes/attributes-create";
import AdminUsersCreate from "../modules/admin/users/admin-users-create";
import DiscountsTable from "../modules/discounts/discounts";
import DiscountsView from "../modules/discounts/discounts-view";
import DiscountsEdit from "../modules/discounts/discounts-edit";
import DiscountsCreate from "../modules/discounts/discounts-create";
import CustomerTable from "../modules/customer/customer";
import CustomerCreate from "../modules/customer/customer-create";
import CustomerView from "../modules/customer/customer-view";
import CustomerEdit from "../modules/customer/customer-edit";
import AddressTable from "../modules/address/address";
import AddressView from "../modules/address/address-view";
import AddressEdit from "../modules/address/address-edit";
import AddressCreate from "../modules/address/address-create";
import ProductsTable from "../modules/products/products";
import ProductsCreate from "../modules/products/products-create";
import ProductsView from "../modules/products/products-view";
import ScrollToTop from "../components/helper-functions/helper/scroll-to-top";
import ErrorBoundary from "../components/ui/layouts/error-boundary";
import ProductsEdit from "../modules/products/products-edit";
import OrdersTable from "../modules/orders/orders";
import OrdersCreate from "../modules/orders/orders-create";
import Configuration from "../modules/configuration/configuration";
import OrderStatus from "../modules/orders/orders-status";
import Dashboard from "../modules/dashboard/dashboard";
import AccountsView from "../modules/accounts/accounts-view.js";
import AccountsEdit from "../modules/accounts/accounts-edit";
import useSiteSettings from "../components/helper-functions/hooks/useSitesettings";
import ContactEnquiriesTable from "../modules/contact-enquiries/contact-enquiries";
import ContactEnquiriesView from "../modules/contact-enquiries/contact-enquiries-view";
import ProductEnquiriesTable from "../modules/product-enquiries/product-enquiries";
import ProductEnquiriesView from "../modules/product-enquiries/product-enquiries-view";
import { socket } from "../redux/config";
import successToastOrder from "../components/helper-functions/helper/sucess-toast-order";
import Plugin from "../modules/plugin/plugin";
import Interface from "../modules/interface/interface.js";
import Themes from "../modules/themes/themes.js";
import privateRouteConfig from "./privateRouteConfig.js";
import protectedRouteConfig from "./protectedRouteConfig.js"
import pluginsMainfest from "../pluginsMainfest.json";

const Index = () => {
  const siteSettingsData = useSiteSettings();
  console.log(siteSettingsData?.data?.title, "tyrtyury6t");
  useEffect(() => {
    localStorage.setItem("currency", siteSettingsData?.data?.currency ?? "₹");
    localStorage.setItem("brand", siteSettingsData?.data?.brands);
    localStorage.setItem(
      "live_map_enable",
      siteSettingsData?.data?.live_map_enable
    );
    localStorage.setItem("indus_type", siteSettingsData?.data?.industry_type);
    localStorage.setItem(
      "delivery_options",
      JSON.stringify(
        siteSettingsData?.data?.shippingDetails?.delivery_options ?? []
      )
    );
    localStorage.setItem(
      "delivery_type",
      siteSettingsData?.data?.shippingDetails?.delivery_type
    );
    localStorage.setItem(
      "store_lat",
      JSON.stringify(siteSettingsData?.data?.contactInfo?.company_address?.lat)
    );
    localStorage.setItem(
      "store_lng",
      JSON.stringify(siteSettingsData?.data?.contactInfo?.company_address?.lng)
    );
    if (siteSettingsData?.data?.title) {
      document.title = siteSettingsData?.data?.title?.toUpperCase();
    } else {
      document.title = "ADAIR";
    }
    const faviconLink = document.getElementById("dynamic-favicon");
    console.log(faviconLink, "faviconLink");
    if (faviconLink && siteSettingsData?.data?.site_icon) {
      faviconLink.href = siteSettingsData?.data?.site_icon;
    }
  }, [siteSettingsData]);
  useEffect(() => {
    socket.on("new_order_placed", (data) => {
      if (window.location.pathname != "/orders") {
        successToastOrder(`New Order Placed - ${data}`);
      }
    });
    return () => {
      socket.off("new_order_placed");
    };
  }, []);

  const [privateConfigRoutes, setConfigRoutes] = useState([...privateRouteConfig.routes]);
  const [protectedConfigRoutes, setprotectedConfigRoutes] = useState([...protectedRouteConfig.routes]);

  useEffect(() => {
    // Use dynamic imports to load plugins
    for (let index = 0; index < pluginsMainfest.subfolders.length; index++) {
      const element = pluginsMainfest.subfolders[index];
      import(`../installedPlugins/${element}/src/routers/privateRouteConfig.js`).then((data) => {
        const privateRouteConfig = data.default;
        console.log("route config is from plugin is", privateRouteConfig);
        setConfigRoutes((prevRoutes) => [...prevRoutes, privateRouteConfig.routes]);
      });

      import(`../installedPlugins/${element}/src/routers/protectedRouteConfig.js`).then((data) => {
        const protectedRouteConfig = data.default;
        console.log("route config is from plugin is", protectedRouteConfig);
        setprotectedConfigRoutes((prevRoutes) => [...prevRoutes, protectedRouteConfig.routes]);
      });

      // Dynamically import the updateComponent1 function and use it
      import(`../installedPlugins/${element}/src/updateComponents.js`).then((updateComponentsModule) => {
        const updateComponent = updateComponentsModule.default;
        updateComponent();
      });

    }
  }, []);

  // console.log("privateConfigRoutes", privateConfigRoutes);

  return (
    <>
      <Router>
        <ErrorBoundary>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route element={<PrivateRoute />}>
              {/* <Route element={<NotificationComponent />}> */}
              <Route
                element={
                  <MainLayout siteSettingsData={siteSettingsData?.data} />
                }
              >
                {privateConfigRoutes.map((i) => i)}
                <Route path="/" element={<Dashboard />} />
                <Route path="/accounts/view" element={<AccountsView />} />
                <Route path="/accounts/edit/:id" element={<AccountsEdit />} />

                <Route path="/categories" element={<CategoriesTable />} />
                {/* <Route element={<MainLayout />}> */}
                <Route path="/" element={<Home />} />
                <Route path="categories" element={<CategoriesTable />} />
                <Route
                  path="/categories/view/:id"
                  element={<CategoriesView />}
                />
                <Route
                  path="/categories/edit/:id"
                  element={<CategoriesEdit />}
                />
                <Route
                  path="/categories/create"
                  element={<CategoriesCreate />}
                />
                <Route path="/admin/roles" element={<AdminRolesTable />} />
                <Route
                  path="/admin/roles/create"
                  element={<AdminRolesCreate />}
                />
                <Route
                  path="/admin/roles/view/:id"
                  element={<AdminRolesView />}
                />
                <Route
                  path="/admin/roles/edit/:id"
                  element={<AdminRolesEdit />}
                />
                <Route path="/admin/users" element={<AdminUsersTable />} />
                <Route
                  path="/admin/users/view/:id"
                  element={<AdminUsersView />}
                />
                <Route
                  path="/admin/users/edit/:id"
                  element={<AdminUsersEdit />}
                />
                <Route
                  path="/admin/users/create"
                  element={<AdminUsersCreate />}
                />

                {/*Discounts*/}
                <Route path="/discounts" element={<DiscountsTable />} />
                <Route path="/discounts/view/:id" element={<DiscountsView />} />
                <Route path="/discounts/edit/:id" element={<DiscountsEdit />} />
                <Route path="/discounts/create" element={<DiscountsCreate />} />

                {/*Tags*/}
                <Route path="/tags" element={<TagsTable />} />
                <Route path="tags/view/:id" element={<TagsView />} />
                <Route path="tags/edit/:id" element={<TagsEdit />} />
                <Route path="tags/create" element={<TagsCreate />} />
                {/*CONTACT-ENQUIRY*/}

                <Route
                  path="/contact-enquiry"
                  element={<ContactEnquiriesTable />}
                />
                <Route
                  path="contact-enquiry/view/:id"
                  element={<ContactEnquiriesView />}
                />

                {/*ORDER ENQUIRY*/}
                <Route
                  path="/product-enquiry"
                  element={<ProductEnquiriesTable />}
                />
                <Route
                  path="product-enquiry/view/:id"
                  element={<ProductEnquiriesView />}
                />

                {/*Delivery Partners*/}
                <Route
                  path="/delivery-partners"
                  element={<DeliveryPartnerTable />}
                />
                <Route
                  path="/delivery-partner/view/:id"
                  element={<DeliveryPartnerView />}
                />
                <Route
                  path="/delivery-partner/edit/:id"
                  element={<DeliveryPartnerEdit />}
                />
                <Route
                  path="/delivery-partner/create"
                  element={<DeliveryPartnerCreate />}
                />
                {/*Brands*/}
                <Route path="/brands" element={<BrandsTable />} />
                <Route path="brands/view/:id" element={<BrandsView />} />
                <Route path="brands/edit/:id" element={<BrandsEdit />} />
                <Route path="brands/create" element={<BrandsCreate />} />

                {/* ATTRIBUTES */}
                <Route path="/attributes" element={<AttributesTable />} />
                <Route
                  path="/attributes/view/:id"
                  element={<AttributesView />}
                />
                <Route
                  path="/attributes/edit/:id"
                  element={<AttributesEdit />}
                />
                <Route
                  path="/attributes/create"
                  element={<AttributesCreate />}
                />

                {/*Address*/}
                <Route path="address" element={<AddressTable />} />
                <Route path="address/view/:id" element={<AddressView />} />
                <Route path="address/edit/:id" element={<AddressEdit />} />
                <Route path="address/create" element={<AddressCreate />} />

                {/* CUSTOMER */}
                <Route path="/customers" element={<CustomerTable />} />
                <Route path="/customers/create" element={<CustomerCreate />} />
                <Route path="/customers/view/:id" element={<CustomerView />} />
                <Route path="/customers/edit/:id" element={<CustomerEdit />} />

                {/* PRODUCTS */}
                <Route path="/products" element={<ProductsTable />} />
                <Route path="/products/create" element={<ProductsCreate />} />
                <Route path="/products/view/:id" element={<ProductsView />} />
                <Route path="/products/edit/:id" element={<ProductsEdit />} />

                {/* ORDERS */}
                <Route path="/orders" element={<OrdersTable />} />
                <Route path="/orders/create" element={<OrdersCreate />} />
                <Route path="/orders/status/:id" element={<OrderStatus />} />

                {/* PLUGIN */}
                <Route path="/plugins" element={<Plugin />} />
                <Route path="/themes" element={<Themes />} />
                <Route path="/interface" element={<Interface />} />

                {/* CONFIGURATION */}
                <Route path="/configuration" element={<Configuration />} />
              </Route>
            </Route>
            {/* </Route> */}
            <Route element={<ProtectedRoute />}>
              {protectedConfigRoutes.map((i) => i)}
              <Route path="login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:id" element={<ResetPassword />} />
              <Route
                path="/reset-email-sent/:id"
                element={<ResetEmailSent />}
              />
              <Route
                path="/password-successfully-updated"
                element={<PasswordSuccessfullyUpdated />}
              />
            </Route>
          </Routes>
          <ToastContainer limit={2} />
          <ScrollToTop />
        </ErrorBoundary>
      </Router>
    </>
  );
};
export default Index;
