import React, { useEffect, useRef } from "react";
import { socket } from "../redux/config";
import successToastOrder from "../components/helper-functions/helper/sucess-toast-order";

const NotificationComponent = () => {
  const noticationSound =
    "https://ff-ecommerce-development.s3.ap-south-1.amazonaws.com/harp-motif2-36586.mp3";

  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }
  useEffect(() => {
    socket.on("new_order_placed", (data) => {
      console.log(data, "new_order_placed");
      if (window.location.pathname != "/orders") {
        successToastOrder(`New Order Placed - ${data}`);
        playAudio();
      }
    });
    return () => {
      socket.off("new_order_placed");
      console.log("new_order_placed", "disconnected");
    };
  }, []);
  return (
    <div>
      <audio ref={audioPlayer} src={noticationSound} />
    </div>
  );
};

export default NotificationComponent;
