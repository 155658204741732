import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/ui/tables/table";
import { useDispatch } from "react-redux";
import {
  tagsDeleteAction,
  tagsMultiDeleteAction,
  tagsTableAction,
} from "../../redux/actions/tags-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import { useLocation } from "react-router";
import { productEnquiryTableAction } from "../../redux/actions/product-enquiry-action";
import dayjs from "dayjs";
import NotificationComponent from "../../components/notificationComponent";

const ProductEnquiriesTable = () => {
  console.log("fsdfsdhfsdfsdfgsd");
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [productEnquiry, setProductEnquiry] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      productEnquiryTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, inputValue, reload, sortConfig]);

  const onSuccess = (data) => {
    setLoading(false);
    setProductEnquiry(data?.data?.orderEnquiry);
    setPagination(data?.data?.pagination);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const tableData = productEnquiry.map((item) => ({
    id: item?.id,
    code: item?.code,
    pro_code: [item?.Product?.code, item?.product_id] ?? "--",
    name: removeUnderScore(item?.name) ?? "--",
    phone_number: item?.phone_number ?? "--",
    createdAt: dayjs(item?.createdAt).format(
      "DD MMMM YYYY, hh:mm:ss A"
    ) ?? "--",
    Action: ["/product-enquiry/view/"],
  }));

  return (
    <div className=" col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-4 mb-5 ">
      <NotificationComponent />
      <DynamicTable
        tabletitle={"Products Enquiries"}
        modalText={'product'}
        placeholder={"Search product enquiry id, product id, name & mobile number "}
        tableHeading={tableTagsHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={tagsDeleteAction}
        multiDeleteAction={tagsMultiDeleteAction}
        setLoading={setLoading}
        loading={loading}
        setReload={setReload}
        reload={reload}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        createButton={false}
      />
    </div>
  );
};
export default ProductEnquiriesTable;

const tableTagsHeader = [
  { label: "Product Enquiry ID", value: "code" },
  { label: "Product ID", value: "product_code" },
  { label: "Name", value: "name" },
  { label: "Mobile Number", value: "phone_number" },
  { label: "Created Date & Time", value: "createdAt" },
  { label: "Actions", value: "Actions" },
];
