import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  AddressDeleteAction,
  AddressViewAction,
} from "../../redux/actions/address-action";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import AddressLocation from "../../components/ui/address/address-location";
import AddressLeaflet from "../../components/ui/address/address-leaflet";
import NotificationComponent from "../../components/notificationComponent";

const AddressView = () => {
  const params = useParams();
  const [addressData, setAddressData] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AddressViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setAddressData(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const addressFindOne = [
    {
      label: "Customer ID",
      value: addressData?.customer_id,
    },
    {
      label: "Recipient Name",
      value: addressData?.recipient_name,
    },
    {
      label: "Address Name",
      value: addressData?.address_name,
    },

    {
      label: "Address",
      value: (
        <>
          {addressData?.address_line1}
          <br />
          {addressData?.address_line2}
        </>
      ),
    },
    {
      label: "Addresss",
      value: [addressData?.lat, addressData?.lng],
    },
    {
      label: "City",
      value: addressData?.city,
    },
    {
      label: "Pin Code",
      value: addressData?.pincode,
    },
    {
      label: "State",
      value: addressData?.state,
    },
    {
      label: "Country",
      value: addressData?.country,
    },
    {
      label: "Phone Number",
      value: "+" + addressData?.country_code + " " + addressData?.phone_number,
    },
    {
      label: "Default Address?",
      value: addressData?.is_default === true ? "Yes" : "No",
    },
  ];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPage = searchParams.get("fromPage");
  console.log(localStorage?.getItem("live_map_enable") === "true");
  return (
    <>
      <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          <InnerLayout
            type="address"
            id={addressData?.id}
            deleteAction={AddressDeleteAction}
            path={
              fromPage === "view"
                ? `/customers/view/${addressData?.customer_id}?currentPage=1`
                : `/address`
            }
            editButtonPath={`/address/edit/${addressData?.id}`}
            rolePermission={"addresses"}
            name={addressData?.id}
          />
          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            {addressFindOne.map((item, index) => {
              return (
                <>
                  {item.label === "Addresss" &&
                  localStorage?.getItem("live_map_enable") === "true" ? (
                    <div className="mb-3">
                      {/* <AddressLocation
                          viewPage={true}
                          lat={item.value?.[0]}
                          lng={item.value?.[1]}
                        /> */}
                      <AddressLeaflet
                        viewPage={true}
                        lat={item.value?.[0]}
                        lng={item.value?.[1]}
                      />
                    </div>
                  ) : (
                    item.label != "Addresss" && (
                      <div key={index} className="mb-2">
                        <label className="color_707070 fs_14">
                          {item.label}
                        </label>
                        <div className="color_030304 fs_14 mb-3">
                          {item.value}
                        </div>
                      </div>
                    )
                  )}
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default AddressView;
