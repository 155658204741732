import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../components/ui/forms/input-field";
import { useDispatch } from "react-redux";
import {
  attributesEditAction,
  attributesViewAction,
} from "../../redux/actions/attributes-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import isEqual from "lodash.isequal";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";
const AttributesEdit = () => {
  const navigate = useNavigate();
  const router = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = router;
  const [loading, setLoading] = useState(false);
  const currenPath = pathname.startsWith("/attributes/edit");
  const [attributesDetails, setAttributesDetails] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");
  useEffect(() => {
    setLoading(true);
    dispatch(attributesViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setAttributesDetails(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const initialValues = {
    attribute_name: attributesDetails?.name ?? "",
    Slug: attributesDetails?.slug ?? "",
  };
  const validationSchema = Yup.object().shape({
    attribute_name: Yup.string().trim().required("Category Name is required"),
    Slug: Yup.string().trim().nullable(),
  });
  const onSubmit = (values, action) => {
    dispatch(
      attributesEditAction(
        {
          attribute_id: attributesDetails?.id,
          attribute_name: values?.attribute_name,
          slug: values?.Slug,
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };
  const onSuccessEdit = (data) => {
    successToast(data.message);
    if (currentPage) {
      navigate("/attributes", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onErrorEdit = (data) => {
    errorToast(data.data.message);
  };
  const startsWithExample = pathname.startsWith("/attributes/edit/:id");
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors, resetForm }) => {
          return (
            <Form className="">
              {loading ? (
                <LoadingSpinnerTable />
              ) : (
                <>
                  <InnerLayout
                    name={attributesDetails?.id}
                    page="edit"
                    edit={true}
                    onSubmit={onSubmit}
                    path={"/attributes"}
                    rolePermission={"attributes"}
                    disabled={valuesCompareFn(values)}
                  />

                  <div
                    className="ms-5 pe-4 pe-md-0 mt-lg-4 pt-lg-2 mt-5"
                    style={{ maxWidth: "40rem" }}
                  >
                    <InputField
                      placeholder="Enter attribute name"
                      containerClass="mb-3 position-relative"
                      errMsgContainerClass="ps-2"
                      label="Attribute Name"
                      name="attribute_name"
                      type="text"
                    />
                    <div>
                      <InputField
                        placeholder="Enter slug"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Slug"
                        name="Slug"
                        type="text"
                      />
                    </div>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AttributesEdit;
