import React, { useEffect, useState } from "react";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { BackButton, SaveButton } from "../../buttons/text-button";
import { SettingsToggle } from "../../buttons/toggle-button";
import { removeUnderScore } from "../../../helper-functions/helper/helper";
import InputField from "../../forms/input-field";
import ErrorMsg from "../../forms/error-msg";
import { useDispatch } from "react-redux";
import {
  paymentsEditAction,
  paymentsViewAction,
} from "../../../../redux/actions/configuration-action";
import errorToast from "../../../helper-functions/helper/error-toast";
import successToast from "../../../helper-functions/helper/sucess-toast";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";
import { isEqual } from "lodash";
const PaymentGateway = ({ onBack = () => {}, data = {} }) => {
  console.log(data.type);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [paymentsSettingData, setPaymentsSettingData] = useState({});
  useEffect(() => {
    setLoading(true);
    dispatch(
      paymentsViewAction(
        {
          main_payment_type: data.type,
        },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [data.type, reload]);
  const onFetchSuccess = (data) => {
    setLoading(false);
    setPaymentsSettingData(data.data);
  };
  const onFetchError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
  };
  console.log(paymentsSettingData);
  const idMap = {
    RazorpayPaymentGateway: "key_ID",
    PaytmPaymentGateway: "merchant_ID",
    AdairPay: "key_id",
    Stripe: "key_id",
    OtherPaymentGateway: "ID",
  };
  const idValues = {
    RazorpayPaymentGateway: paymentsSettingData?.key_id
      ? paymentsSettingData?.key_id
      : "",
    AdairPay: paymentsSettingData?.key_id ? paymentsSettingData?.key_id : "",
    Stripe: paymentsSettingData?.key_id ? paymentsSettingData?.key_id : "",
    PaytmPaymentGateway: paymentsSettingData?.merchant_id
      ? paymentsSettingData?.merchant_id
      : "",
    OtherPaymentGateway: paymentsSettingData?.id_one
      ? paymentsSettingData?.id_one
      : "",
  };
  const keyMap = {
    RazorpayPaymentGateway: "Key_secret",
    AdairPay: "Key_secret",
    Stripe: "Key_secret",
    // PaytmPaymentGateway: "merchant_key",
    OtherPaymentGateway: "key",
  };
  const keyValue = {
    RazorpayPaymentGateway: paymentsSettingData?.key_secret
      ? paymentsSettingData?.key_secret
      : "",
    AdairPay: paymentsSettingData?.key_secret
      ? paymentsSettingData?.key_secret
      : "",
    stripe: paymentsSettingData?.key_secret
      ? paymentsSettingData?.key_secret
      : "",
    OtherPaymentGateway: paymentsSettingData?.key_one
      ? paymentsSettingData?.key_one
      : "",
  };

  let initialValues, validationSchema;

  if (data.type !== "CashOnDelivery") {
    initialValues = {
      payment_Activate: paymentsSettingData?.enable
        ? paymentsSettingData?.enable
        : false,
      payment_types: {
        credit_card: paymentsSettingData?.payment_type?.credit_card
          ? paymentsSettingData?.payment_type?.credit_card
          : false,
        debit_card: paymentsSettingData?.payment_type?.debit_card
          ? paymentsSettingData?.payment_type?.debit_card
          : false,
        net_banking: paymentsSettingData?.payment_type?.net_banking
          ? paymentsSettingData?.payment_type?.net_banking
          : false,
        upi_payments: paymentsSettingData?.payment_type?.upi_payments
          ? paymentsSettingData?.payment_type?.upi_payments
          : false,
      },
      [idMap[data.type]]: idValues[data.type] ?? "",
      [keyMap[data.type]]: keyValue[data.type] ?? "",
    };
    validationSchema = Yup.object().shape({
      payment_Activate: Yup.bool(),
      payment_types: Yup.object()
        .shape({
          credit_card: Yup.bool(),
          debit_card: Yup.bool(),
          net_banking: Yup.bool(),
          upi_payments: Yup.bool(),
        })
        .test(
          "atLeastOneTrue",
          "At least one payment type must be true",
          function (value) {
            if (
              this.parent.payment_Activate &&
              data.type !== "CashOnDelivery"
            ) {
              return Object.values(value).some((v) => v === true);
            }
            return true;
          }
        ),
      [idMap[data.type]]: Yup.string()
        .trim()
        .test("requiredIfActive", "Required", function (value) {
          return this.parent.payment_Activate && data.type !== "CashOnDelivery"
            ? value
              ? value.trim() !== ""
              : false
            : true;
        }),
      [keyMap[data.type]]: Yup.string()
        .trim()
        .test("requiredIfActive", "Required", function (value) {
          return this.parent.payment_Activate && data.type !== "CashOnDelivery"
            ? value
              ? value.trim() !== ""
              : false
            : true;
        }),
    });
  } else {
    initialValues = {
      payment_Activate: paymentsSettingData?.enable
        ? paymentsSettingData?.enable
        : false,
    };

    validationSchema = Yup.object().shape({
      payment_Activate: Yup.bool(),
    });
  }

  const onSubmit = (values) => {
    console.log(data.type, "reyerteryter");
    if (data.type === "CashOnDelivery") {
      dispatch(
        paymentsEditAction(
          {
            payment_id: paymentsSettingData.id,
            enable: values?.payment_Activate,
          },
          onSuccess,
          onError
        )
      );
    } else if (
      data.type === "RazorpayPaymentGateway" ||
      data.type === "AdairPay" ||
      data.type === "Stripe"
    ) {
      dispatch(
        paymentsEditAction(
          {
            payment_id: paymentsSettingData.id,
            enable: values?.payment_Activate,
            payment_type: {
              credit_card: values?.payment_types?.credit_card,
              debit_card: values?.payment_types?.debit_card,
              net_banking: values?.payment_types?.net_banking,
              upi_payments: values?.payment_types?.upi_payments,
            },
            key_id: values?.key_ID ?? values?.key_id,
            key_secret: values?.Key_secret,
          },
          onSuccess,
          onError
        )
      );
    } else if (data.type === "PaytmPaymentGateway") {
      dispatch(
        paymentsEditAction(
          {
            payment_id: paymentsSettingData.id,
            enable: values?.payment_Activate,
            payment_type: {
              credit_card: values?.payment_types?.credit_card,
              debit_card: values?.payment_types?.debit_card,
              net_banking: values?.payment_types?.net_banking,
              upi_payments: values?.payment_types?.upi_payments,
            },
            merchant_id: values?.merchant_ID,
            merchant_secret: values?.merchant_key,
          },
          onSuccess,
          onError
        )
      );
    } else {
      dispatch(
        paymentsEditAction(
          {
            payment_id: paymentsSettingData.id,
            enable: values?.payment_Activate,
            payment_type: {
              credit_card: values?.payment_types?.credit_card,
              debit_card: values?.payment_types?.debit_card,
              net_banking: values?.payment_types?.net_banking,
              upi_payments: values?.payment_types?.upi_payments,
            },
            id: values?.ID,
            key: values?.key,
          },
          onSuccess,
          onError
        )
      );
    }
  };
  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }
  console.log(data?.type, "erghektjeroitheroitjhro");

  function subtitleFn(data) {
    console.log(data?.type, "erghektjeroitheroitjhro");
    if (data?.type === "CashOnDelivery") {
      return "For Safe, Contactless, and hassle-free delivery, pay using a card/wallet/net banking or choose Cash on delivery";
    } else if (data?.type === "RazorpayPaymentGateway") {
      return "Choose your Payment Type for secured payment through Razor Pay We do not store or have access to your payment information";
    } else if (data?.type === "AdairPay") {
      return "Choose your Payment Type for secured payment through Adair we do not store or have access to your payment information";
    }
    // else if (data?.type === "PaytmPaymentGateway") {
    //   return "Choose your Payment Type for secured payment through Paytm we do not store or have access to your payment information"
    // }
    // else if (data?.type === "OtherPaymentGateway") {
    //   return "Choose your convenient, secured payment option. We do not store or have access to your payment information"
    // }
  }

  return (
    <>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ handleChange, values, errors }) => {
            console.log(errors);
            console.log(values, "rtyertertretertert");
            function onChange() {}
            return (
              <Form>
                <div
                  style={{ top: "3.5rem" }}
                  className="col-12 d-flex flex-wrap gap-3 z-3 align-items-center justify-content-between py-3 bg-white  position-sticky   border_bottom_1px_e5ecff   
        px-3
        px-sm-5
        "
                >
                  <div
                    onClick={onBack}
                    className="d-flex align-items-center gap-2 cursor_pointer"
                  >
                    <img src={backIcon} alt="back" width={25} height={25} />
                    <span className="font_montserrat color_030304 fw_500 fs_20 fs_md_18">
                      {data.title}
                    </span>
                  </div>
                  <div className="d-flex flex-wrap gap-3">
                    <BackButton
                      disabled={valuesCompareFn(values)}
                      type="reset"
                      name="UNDO"
                      backClassName="fs_14"
                    />
                    <SaveButton
                      disabled={valuesCompareFn(values)}
                      type="submit"
                      backClassName="fs_14"
                    />
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-9 col-lg-11 mt-5 pe-lg-0 px-3 pe-5 px-sm-5">
                  <SettingsToggle
                    title={"Enable/Disable"}
                    subtitle={subtitleFn(data)}
                    id="payment_Activate"
                    handleToggleBtnChange={handleChange}
                    // handleToggleBtnChange={onChange}
                    name={"payment_Activate"}
                    value={values.payment_Activate}
                  />
                  {values.payment_Activate &&
                    data.type !== "CashOnDelivery" && (
                      <div className="mt-4">
                        <div className="mb-4">
                          <p className="color_030304 fw_600 fs_14 mb-1">
                            Payment Type
                          </p>
                          {Object.entries(initialValues.payment_types).map(
                            ([key, value]) => (
                              <div
                                className="d-flex gap-4 align-items-start mt-1 fw_500"
                                key={key}
                              >
                                <span
                                  style={{ width: "130px" }}
                                  className="fs_14 color_030304"
                                >
                                  {key ? removeUnderScore(key) : "-"}
                                </span>
                                <Field
                                  className="form-check-input text-start align-self-center"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    marginTop: "5px",
                                  }}
                                  name={`payment_types.${key}`}
                                  type="checkbox"
                                />
                              </div>
                            )
                          )}
                          <ErrorMsg
                            name="payment_types"
                            textClass="color_F24444 fs_12 d-block mt-2"
                          />
                        </div>
                        <InputField
                          placeholder={`Enter ${
                            idMap[data.type]
                              ? removeUnderScore(idMap[data.type])
                              : ""
                          }`}
                          containerClass="position-relative"
                          errMsgContainerClass="ps-2"
                          label={
                            idMap[data.type]
                              ? removeUnderScore(idMap[data.type])
                              : ""
                          }
                          name={idMap[data.type]}
                          type="text"
                        />
                        <InputField
                          placeholder={`Enter ${
                            keyMap[data.type]
                              ? removeUnderScore(keyMap[data.type])
                              : ""
                          }`}
                          containerClass="mb-2 position-relative"
                          errMsgContainerClass="ps-2"
                          label={
                            keyMap[data.type]
                              ? removeUnderScore(keyMap[data.type])
                              : ""
                          }
                          name={keyMap[data.type]}
                          type="text"
                        />
                      </div>
                    )}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default PaymentGateway;
