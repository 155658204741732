import { api } from "./api";

export const deliverypartnerTableApi = (data, params) => {
  return api.get(
    `/core/driver/list?page=${data?.page}&page_size=${data?.size}&search=${data?.search}&${data?.sort != null && `driver_sort_by=${data?.sort?.sort_by}&driver_sort_order=${data?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};
export const deliverypartnerDeleteApi = (data) => {
  console.log(data, "dvfxvxcdvcxv");
  return api.post(`/core/driver/delete`, data);
};
export const deliverypartnerMultiDeleteApi = (params) => {
  console.log(params, "fdsxsfsd");
  return api.post(`/core/driver/bulk-delete`, JSON.stringify(params));
};
export const deliverypartnerViewApi = (data) => {
  return api.get(`/core/driver/view?driver_id=${data?.id}&page=${data.page}&page_size=${data.size}&search=${data.search}`);
};

export const deliverypartnerEditApi = (params) => {
  return api.post(`/core/driver/edit`, JSON.stringify(params));
};

export const deliverypartnerCreateApi = (params) => {
  return api.post(`/core/driver/create`, JSON.stringify(params));
};
export const tagsDropDownListApi = () => {
  return api.get(`/core/tags/list-all-tags`);
};

export const deliveryBlockApi = (data, params) => {
  return api.post(
    `/core/driver/block-unblock`,
    JSON.stringify(data)
  );
};
