import React, { useEffect, useState } from "react";
import "./meta-table.css";
import DeleteModal from "../modals/delete-modal";
import InputField from "../forms/input-field";
import { useFormikContext } from "formik";
export default function AddonsItemTable({
  initialValues,
  setInitialValues,
  page = ""
}) {
  const { values, setValues, errors, touched, setFieldValue } = useFormikContext();
  const handleDeleteVariation = (indexToDelete) => {
    setValues({
      ...values,
      productAddOnItems: values?.productAddOnItems?.filter(
        (_, index) => index !== indexToDelete
      ),
    });
    setInitialValues((prevInitialValues) => {
      const updatedVariations = prevInitialValues.productAddOnItems?.filter(
        (_, index) => index !== indexToDelete
      );

      return {
        ...prevInitialValues,
        productAddOnItems: updatedVariations,
      };
    });
  };
  return (
    <>
      <DeleteModal
        text={`Are you sure, you want to delete this Meta Data permanently?`}
        // show={show}
        // modalClose={deleteCloseFn}
        // deleteData={deleteData}
        // deleteFn={() => deleteDataFn(deleteData)}
        type={"delete"}
      />

      {initialValues?.productAddOnItems?.length > 0 && <div className={`table_main_container rounded-3 border-0`}>
        <table className={"table_container h-100"}>
          <thead className={`table_heading_container table_heading_border`}>
            <tr className="table_heading_row">
              {/* {editable && ( */}
              <th
                className={` position-sticky end-0 text-start  
                  text-nowrap products_table_heading_data`}
              >
                <input
                  e={{ transform: "scale(1.1)" }}
                  type="checkbox"
                  id="myCheckbox"
                  className="custom-checkbox form-check-input"
                />
              </th>
              {/* )} */}
              <th
                className={`text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
              >
                <div className={`discounts_table_Action_heading`}>
                  Name
                </div>
              </th>
              <th
                className={`text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
              >
                <div className={`discounts_table_Action_heading`}>
                  Price
                </div>
              </th>
              {/* {editable && ( */}

              {
                page != "view" &&
                <th
                  className={`position-sticky end-0 text-start text-nowrap addons_table_heading_data color_707070 font_poppins`}
                >
                  <div className={``}>
                    Actions
                  </div>
                </th>
              }

              {/* )} */}
            </tr>
          </thead>
          <tbody className="table_body_container h-100">

            {
              initialValues?.productAddOnItems?.map((item, index) => {
                return (
                  <tr
                    // key={index}
                    className={`table_body_row fs_13 bg_color_ffffff`}
                  >
                    {/* {editable && ( */}
                    <td className="text-nowrap meta_table_body_data">
                      <input
                        // id={item?.id}
                        style={{ transform: "scale(1.2)" }}
                        type="checkbox"
                        className="custom-checkbox form-check-input"
                      />
                    </td>
                    {/* )} */}
                    <td className="text-nowrap color_030304 meta_table_body_data fw_500">
                      <div class="expanding-input d-flex flex-column  color_030304">
                        <InputField
                          isDisabled={page === "view" ? true : false}
                          placeholder="Enter Item Name"
                          containerClass="position-relative"
                          errMsgContainerClass="d-none"
                          label=""
                          name={`productAddOnItems[${index}].name`}
                          id={`productAddOnItems[${index}].name`}
                          type="text"
                        />
                        {/* <span className="color_F23D3D">Required!</span> */}
                      </div>
                    </td>
                    <td className="text-nowrap color_030304 meta_table_body_data fw_500">
                      <div class="expanding-input d-flex flex-column  color_030304">
                        <InputField
                          isDisabled={page === "view" ? true : false}
                          isUnit={true}
                          placeholder="Enter Price"
                          containerClass="  position-relative"
                          errMsgContainerClass="d-none"
                          label=""
                          name={`productAddOnItems[${index}].price`}
                          id={`productAddOnItems[${index}].price`}
                          type="text"
                        />
                      </div>
                    </td>
                    {
                      page != "view" &&
                      <>
                        {
                          index > 0 ?
                            <td
                              className={`addons_table_body_data text-nowrap position-sticky  end-0 color_030304      h-100 bg_color_ffffff`}
                            >
                              <div className={`align-items-center d-flex`}>
                                <button
                                  onClick={() => handleDeleteVariation(index)}
                                  type="button"
                                  className={`color_F24444 border-0 bg_color_ffffff   fs_15 fw_500 font_poppins `}
                                >
                                  Delete
                                </button>
                              </div>
                            </td>

                            : <td
                              className={`addons_table_body_data text-nowrap position-sticky  end-0 color_030304      h-100 bg_color_ffffff`}
                            >
                              <div className={`align-items-center d-flex`}>

                              </div>
                            </td>
                        }
                      </>
                    }
                  </tr>
                )
              }
              )
            }
          </tbody>
        </table>
      </div>}
    </>
  );
}

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#E5ECFFB2",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins,sans-serif",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#2820DA",
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    padding: "0px",
  }),
};
