import {
    BRANDS_CREATE,
    BRANDS_DELETE,
    BRANDS_DROPDOWN_LIST,
    BRANDS_EDIT,
    BRANDS_MULTI_DELETE,
    BRANDS_TABLE,
    BRANDS_VIEW,
  } from "../types";
  
  export const brandsCreateAction = (data, onSuccess, onError) => {
    return {
      type: BRANDS_CREATE,
      data,
      onSuccess,
      onError,
    };
  };
  
  export const brandsTableAction = (data, onSuccess, onError) => {
    return {
      type: BRANDS_TABLE,
      data,
      onSuccess,
      onError,
    };
  };
  
  export const brandsViewAction = (id, onSuccess, onError) => {
    return {
      type: BRANDS_VIEW,
      id,
      onSuccess,
      onError,
    };
  };
  
  export const brandsEditAction = (data, onSuccess, onError) => {
    return {
      type: BRANDS_EDIT,
      data,
      onSuccess,
      onError,
    };
  };
  export const brandsDeleteAction = (data, onSuccess, onError) => {
    return {
      type: BRANDS_DELETE,
      data,
      onSuccess,
      onError,
    };
  };
  export const brandsMultiDeleteAction = (data, onSuccess, onError) => {
    return {
      type: BRANDS_MULTI_DELETE,
      data,
      onSuccess,
      onError,
    };
  };

  export const brandsDropDownListAction = (onSuccess, onError) => {
    return { type: BRANDS_DROPDOWN_LIST, onSuccess, onError };
  };
  