import { ErrorMessage } from "formik";
import React, { Fragment } from "react";

export default function ErrorMsg({
  containerClass = "",
  name = "",
  textClass = "color_F24444 fs_12 d-block",
  isContainer = true,
  isDot = true,
  customStyle = { minHeight: "1.32rem" },
}) {
  const Container = isContainer ? "div" : Fragment;
  return (
    <Container style={{ ...customStyle }} className={`${containerClass}`}>
      <ErrorMessage name={name}>
        {(msg) => (
          <>
            <span role="alert" className={`${textClass}`}>
              {isDot && <span className="fs_14 me-1 fw_600">&middot;</span>}
              {"" + msg + "!"}
            </span>
          </>
        )}
      </ErrorMessage>{" "}
    </Container>
  );
}
