
import React from "react";

const HeadsetIcon = ({ fill = "#707070", bodyFill = "none" }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5002 10.3532V9.1665C17.5002 7.17738 16.7101 5.26973 15.3035 3.8632C13.897 2.45668 11.9894 1.6665 10.0002 1.6665C8.01112 1.6665 6.10347 2.45668 4.69694 3.8632C3.29042 5.26973 2.50024 7.17738 2.50024 9.1665V10.3532C1.62621 10.738 0.910911 11.4115 0.474095 12.2607C0.0372792 13.11 -0.0945389 14.0835 0.100708 15.0184C0.295956 15.9532 0.806416 16.7926 1.54664 17.396C2.28685 17.9995 3.2119 18.3303 4.16691 18.3332C4.60894 18.3332 5.03286 18.1576 5.34542 17.845C5.65798 17.5325 5.83358 17.1085 5.83358 16.6665V11.6665C5.83358 11.2245 5.65798 10.8006 5.34542 10.488C5.03286 10.1754 4.60894 9.99984 4.16691 9.99984V9.1665C4.16691 7.61941 4.78149 6.13568 5.87545 5.04171C6.96942 3.94775 8.45315 3.33317 10.0002 3.33317C11.5473 3.33317 13.0311 3.94775 14.125 5.04171C15.219 6.13568 15.8336 7.61941 15.8336 9.1665V9.99984C15.3916 9.99984 14.9676 10.1754 14.6551 10.488C14.3425 10.8006 14.1669 11.2245 14.1669 11.6665V16.6665H11.6669C11.4459 16.6665 11.2339 16.7543 11.0777 16.9106C10.9214 17.0669 10.8336 17.2788 10.8336 17.4998C10.8336 17.7209 10.9214 17.9328 11.0777 18.0891C11.2339 18.2454 11.4459 18.3332 11.6669 18.3332H15.8336C16.7886 18.3303 17.7136 17.9995 18.4539 17.396C19.1941 16.7926 19.7045 15.9532 19.8998 15.0184C20.095 14.0835 19.9632 13.11 19.5264 12.2607C19.0896 11.4115 18.3743 10.738 17.5002 10.3532ZM4.16691 16.6665C3.50387 16.6665 2.86798 16.4031 2.39914 15.9343C1.9303 15.4654 1.66691 14.8295 1.66691 14.1665C1.66691 13.5035 1.9303 12.8676 2.39914 12.3987C2.86798 11.9299 3.50387 11.6665 4.16691 11.6665V16.6665ZM15.8336 16.6665V11.6665C16.4966 11.6665 17.1325 11.9299 17.6013 12.3987C18.0702 12.8676 18.3336 13.5035 18.3336 14.1665C18.3336 14.8295 18.0702 15.4654 17.6013 15.9343C17.1325 16.4031 16.4966 16.6665 15.8336 16.6665Z" fill={fill} />
    </svg>
  );
};

export default HeadsetIcon;
