import { all } from "redux-saga/effects";
import {
  watchBrandsCreateWorker,
  watchBrandsDeleteWorker,
  watchBrandsEditWorker,
  watchBrandsMultiDeleteWorker,
  watchBrandsTableWorker,
  watchBrandsViewWorker,
  watchBrandsDropDownListWorker,
} from "../brands-saga";

export default function* brandsRootSaga() {
  yield all([
    watchBrandsTableWorker(),
    watchBrandsViewWorker(),
    watchBrandsEditWorker(),
    watchBrandsDeleteWorker(),
    watchBrandsMultiDeleteWorker(),
    watchBrandsCreateWorker(),
    watchBrandsDropDownListWorker(),
  ]);
}
