import React, { useEffect, useMemo, useState } from "react";
import "../table.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Offcanvas from "react-bootstrap/Offcanvas";
import backarrow from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import InputField from "../../forms/input-field";
import { ApplyButton, ClearButton } from "../../buttons/text-button";
import SelectField from "../../forms/select-field";
import { customerListDropdownAction } from "../../../../redux/actions/address-action";
import { useDispatch } from "react-redux";
import SearchBar from "./search-bar";
import { Country } from "country-state-city";
import { State } from "country-state-city";
function AddressTableFilter({ setFilterShow, filterShow, setFilterData, filterData }) {
  console.log(filterData);
  const [customerList, setCustomerList] = useState([]);
  const [custId, setCustId] = useState([]);
  const dispatch = useDispatch();
  const initialValues = {
    customer_id: [],
    country: filterData?.country,
    state: filterData?.state,
    pincode: filterData?.pincode,
    is_default: filterData?.is_default
  };
  const onSubmit = (values) => {
    setFilterData({
      customer_id: custId,
      country: values?.country,
      state: values?.state,
      pincode: values?.pincode,
      is_default: values?.is_default
    });
    setFilterShow(false);
  };
  // function clearFn() {
  //   setCustId([]);
  //   setFilterData({
  //     customer_id: [],
  //     country: "",
  //     state: "",
  //     pincode: "",
  //     is_default: false
  //   });
  //   window.location.reload()
  // }

  useEffect(() => {
    dispatch(customerListDropdownAction(OnFetchSuccess, onFetchError));
  }, []);

  const OnFetchSuccess = (data) => {
    setCustomerList(
      data.data.map((item) => {
        return { label: item.id + " - " + item.name, value: item.id };
      })
    );
  };
  const onFetchError = () => {
    console.log("failed to load parent category list");
  };

  const countriesList = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: `${country.isoCode}`,
      })),
    []
  );
  return (
    <div>
      <Offcanvas
        show={filterShow}
        onHide={() => setFilterShow(false)}
        placement="end"
        className="side-bar-container"
      >
        <Offcanvas.Body className="p-0">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, resetForm }) => {
              const clearFn = () => {
                resetForm(); // Reset the form values
                setCustId([]);
                setFilterData({
                  customer_id: [],
                  country: "",
                  state: "",
                  pincode: "",
                  is_default: false
                });
              };
              return (
                <Form className=" h-100 row g-0 align-items-between ">
                  <div className="pt-3">
                    <div className="d-flex ps-3 pt-3 gap-3">
                      <img
                        onClick={() => setFilterShow(false)}
                        width={"30px"}
                        src={backarrow}
                        alt="back arrow"
                      />{" "}
                      <span className="fs_22 fw_500 font_montserrat color_030304">
                        Filter
                      </span>
                    </div>
                    <hr className="colored-line" />
                    <div className="px-4">
                      {/* NEEDED CODE */}
                      <div className="mx-3">
                        <SearchBar
                          customerList={customerList}
                          setCustId={setCustId}
                          custId={custId}
                        />
                      </div>
                      <div className="mx-3">
                        <SelectField
                          labelClass="color_#444444 fs_14"
                          placeholder={"Select country"}
                          label={"Country"}
                          id={"country"}
                          name="country"
                          errMsgContainerClass="ps-2"
                          options={countriesList}
                        />
                      </div>
                      <div className="mx-3">
                        <SelectField
                          labelClass="color_#444444 fs_14"
                          placeholder={"Select state"}
                          label={"State"}
                          id={"state"}
                          name="state"
                          errMsgContainerClass="ps-2"
                          isFormikControlled={true}
                          options={State.getStatesOfCountry(values.country).map(
                            (state) => ({
                              label: state.name,
                              value: `${state.name}`,
                            })
                          )}
                        />
                      </div>

                      <div className="mx-3">
                        <InputField
                          inputClass="py-2"
                          labelClass="color_#444444 fs_14 mb-1"
                          placeholder="Select pin code"
                          containerClass="mb-2 position-relative w-100 d-flex flex-column"
                          errMsgContainerClass="ps-2"
                          label="Pin Code"
                          name="pincode"
                          type="text"
                        />
                      </div>
                      <div className="mx-3 d-flex align-items-center gap-2">
                        <Field
                          className="form-check-input mb-1"
                          style={{ width: "15px", height: "15px" }}
                          name="is_default"
                          type="checkbox"
                        />
                        <label className="fw_500 fs_14">
                          Is Default Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <div className="w-100">
                      <hr className="colored-line" />
                      <div className="d-flex justify-content-end gap-3 p-2">
                        <ClearButton
                          clearFn={() => clearFn()}
                          type="reset"
                          clearClassName={"px-4 py-2 fs_18 fw_600"}
                        />
                        <ApplyButton
                          type="submit"
                          applyClassName={"px-4 py-2 fs_18 fw_600"}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
export default AddressTableFilter;
