import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { SaveButton } from "../buttons/text-button";
import backarrow from "../../../assets/icons/back-arrow-button.svg";
import ImgUploadLogo from "../../../assets/images/Upload_product_img_logo.svg";
import LoadingSpinner from "../../helper-functions/helper/loadingSpinner";
import PlusIcon from "../../../assets/icons/plus-icon";
import DeleteIcon from "../../../assets/icons/delete-icon";
import dummyZipImage from "../../../assets/icons/zipfileImage.png";
import { useDispatch } from "react-redux";
import {
  pluginCreateAction,
  pluginImageUploadAction,
  pluginUploadAction,
} from "../../../redux/actions/plugin-action";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import ImageUpload from "../forms/image-upload";
import Compressor from "compressorjs";
import LoadingSpinnerTable from "../../helper-functions/helper/loadingSpinner-table";
import TextAreaField from "../forms/textarea-field";
import "../../../modules/plugin/plugin.css"

const PluginUploadModal = ({
  show,
  modalClose,
  id = "logo",
  inputName = "img",
  defaultImage = "",
  defaultImageWidth = "50",
  setLoading,
  loading,
}) => {
  const dispatch = useDispatch();
  const [imgLoading, setImgLoading] = useState(false);
  const [imgUrl, setImageUrl] = useState("");
  const [imageSize, setImageSize] = useState(false);
  const [uploadedPlugin, setUploadedPlugin] = useState({
    url: "",
    originalName: "",
    err: false,
  });
  var fileName = "";
  const handlePlugin = (e) => {
    fileName = e.target.files[0];
    addPlugin();
  };

  function resetFn() {
    setImageUrl("");
  }

  const addPluginImage = (img) => {
    console.log(img,"fyhbkbxsdfjhb");
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          console.log(result,"fyhbkbxsdfjhb");
          dispatch(
            pluginImageUploadAction(
              result,
              onUploadImageSuccess,
              onUploadImageError
            )
          );
        },
      });
    } else {
      setImageSize(true);
    }
  };

  const onUploadImageSuccess = (data) => {
    setImageUrl(data?.filePath);
    setImgLoading(false);
  };

  const onUploadImageError = () => {};

  const addPlugin = () => {
    dispatch(pluginUploadAction(fileName, onUploadSuccess, onUploadError));
  };

  const onUploadSuccess = (data) => {
    setUploadedPlugin({
      url: data?.filePath,
      originalName: data?.fileOriginalname,
    });
  };
  const onUploadError = () => {};

  const initialValues = {
    name: "",
    description: "",
    image_url: imgUrl,
    version: "",
    original_name: uploadedPlugin?.originalName,
    url: uploadedPlugin?.url,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required!"),
    version: Yup.string().trim().required("Required!"),
    description: Yup.string().trim().required("Required!"),
  });

  const onSubmit = (values) => {
    console.log(values, "values");
    dispatch(
      pluginCreateAction(
        {
          name: values.name,
          version: values.version,
          url: uploadedPlugin?.url,
          image_url: imgUrl,
          description: values.description,
          original_name: uploadedPlugin?.originalName,
        },
        onCreateSuccess,
        onCreateError
      )
    );
  };
  const onCreateSuccess = (data) => {
    if (data) {
      setLoading(true);
      modalClose();
    }
  };
  const onCreateError = (err) => {
    if (err) {
      setLoading(true);
      modalClose();
    }
  };
  return (
    <Modal
      show={show}
      onHide={modalClose}
      centered
      dialogClassName="mx-sm-auto"
      contentClassName="rounded-3 pluginModal"
    >
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="">
            <ModalHeader className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center">
                <img
                  onClick={modalClose}
                  width={"30px"}
                  src={backarrow}
                  alt="back arrow"
                />
                <span className="fs_18 fw_500">Upload Plugin</span>
              </div>
              <SaveButton type="submit" />
            </ModalHeader>
            <ModalBody className="">
              <ImageUpload
                id="image_url"
                title="Image"
                containerClass=""
                page="create"
                name={"image_url"}
                getImgUrl={addPluginImage}
                data={imgUrl}
                imgLoading={imgLoading}
                setImgLoading={setImgLoading}
                height={"120px"}
                maxWidth="120px"
                imgWidth="120px"
                imgHeight="120px"
                insideLogoWidth="50px"
                insideLogoHeight="50px"
                uploadIconWidth="25px"
                resetFn={resetFn}
              />
              {imageSize && (
                <label className="fs_14 ms-5 text-danger mb-3">
                  "Please upload below 3mb"
                </label>
              )}
              <InputField
                unitClassName="text-nowrap"
                id={"name"}
                label={"Name"}
                placeholder={"Enter name"}
                name={"name"}
              />
              <TextAreaField
                unitClassName="text-nowrap"
                id={"description"}
                label={"Description"}
                placeholder={"Enter description"}
                name={"description"}
              />
              <InputField
                unitClassName="text-nowrap"
                id={"version"}
                label={"Version"}
                placeholder={"Enter version"}
                name={"version"}
              />
              <div className="d-flex justify-content-center text-center align-items-center mt-4">
                <div>
                  {!uploadedPlugin.url && (
                    <>
                      <img src={ImgUploadLogo} alt="images" />
                      <div className="my-2 mb-4 color_9E9E9E">
                        Please Upload Pugin
                      </div>
                    </>
                  )}
                  <div style={{ maxWidth: "150px" }}>
                    <div
                      className={`${
                        !uploadedPlugin.url && "border_1px_DAE8F2"
                      } position-relative mt-1`}
                      style={{ height: !uploadedPlugin.url && "30px" }}
                    >
                      {imgLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "130px", height: "130px" }}
                        >
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <span>
                          {uploadedPlugin.url ? (
                            <div
                              className="py-3 rounded-2"
                              style={{ border: "1px solid grey" }}
                            >
                              <img
                                className={
                                  uploadedPlugin.url && "border_radius_7px"
                                }
                                width={100}
                                height={100}
                                src={uploadedPlugin.url && dummyZipImage}
                                style={{ objectFit: "cover" }}
                              />
                              <h6>{uploadedPlugin?.originalName}</h6>
                            </div>
                          ) : (
                            <React.Fragment>
                              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                <img
                                  width={defaultImageWidth}
                                  src={defaultImage}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </span>
                      )}

                      {!uploadedPlugin.url && (
                        <div className="position-absolute bottom-0 d-flex justify-content-end align-items-center w-100 pe-1 pb-1 bg_color_2820DA rounded-2 text-white">
                          <label className="cursor_pointer" htmlFor={id}>
                            <label htmlFor={id} className="mb-1">
                              <PlusIcon
                                width="14"
                                height="16"
                                className="pb-1 ms-2"
                                fill="#FFFFFF"
                              />
                            </label>
                            <label htmlFor={id} className="mt-2 ms-2 me-3">
                              ADD PLUGIN
                            </label>
                          </label>
                        </div>
                      )}

                      {uploadedPlugin.url && (
                        <div className="position-absolute bottom-0 d-flex justify-content-end w-100 pe-1 cursor_pointer">
                          <label
                            className="cursor_pointer"
                            onClick={() => {
                              uploadedPlugin.url &&
                                setUploadedPlugin({
                                  url: "",
                                  err: false,
                                });
                            }}
                          >
                            <div className=" bg-secondary p-1 px-2 rounded-2">
                              <DeleteIcon />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                    <input
                      disabled={uploadedPlugin.url}
                      type={"file"}
                      style={{ visibility: "hidden", width: "100%" }}
                      id={id}
                      name={inputName}
                      accept=".zip"
                      onChange={handlePlugin}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
          </Form>
        </Formik>
      )}
    </Modal>
  );
};

export default PluginUploadModal;
