import React from "react";
import { Route } from "react-router-dom";


const privateRouteConfig = {
    routes: [
    ]
};

export default privateRouteConfig;
