import React from 'react'

const EyeCloseIcon = ({ className = "", fill = "#2820DA", onClick = () => { } }) => {
    return (
        <svg onClick={onClick} className={className} width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.389 7.83971C18.637 6.60825 17.6884 5.50827 16.5809 4.58342L18.9134 2.25097C19.233 1.92003 19.2238 1.39269 18.8929 1.07308C18.57 0.761284 18.0582 0.761284 17.7354 1.07308L15.1989 3.61296C13.6264 2.67901 11.8288 2.1922 9.99998 2.20516C4.84271 2.20516 1.90381 5.73553 0.610965 7.83975C-0.203655 9.15735 -0.203655 10.8222 0.610965 12.1398C1.36299 13.3713 2.31158 14.4713 3.4191 15.3961L1.08665 17.7286C0.755716 18.0482 0.746539 18.5756 1.06615 18.9065C1.38576 19.2374 1.91314 19.2466 2.24403 18.927C2.25098 18.9202 2.25781 18.9134 2.26453 18.9065L4.80691 16.3641C6.37738 17.2979 8.17288 17.7855 9.99998 17.7744C15.1572 17.7744 18.0962 14.244 19.389 12.1398C20.2037 10.8222 20.2037 9.15735 19.389 7.83971ZM5.00187 9.98977C4.99715 7.23396 7.2273 4.99612 9.98311 4.99139C11.0258 4.9896 12.0429 5.31452 12.8914 5.92046L11.6819 7.13001C11.1741 6.8238 10.593 6.66058 10 6.65769C8.15977 6.65769 6.66794 8.14952 6.66794 9.98977C6.67083 10.5827 6.83405 11.1639 7.14026 11.6716L5.93071 12.8812C5.32672 12.038 5.00191 11.0269 5.00187 9.98977ZM10 14.9879C8.96287 14.9879 7.95176 14.6631 7.10859 14.0591L8.31814 12.8495C8.82592 13.1557 9.40708 13.319 10 13.3219C11.8403 13.3219 13.3321 11.83 13.3321 9.98977C13.3292 9.39683 13.166 8.81568 12.8598 8.3079L14.0693 7.09835C15.6708 9.34103 15.151 12.4574 12.9084 14.0588C12.0598 14.6648 11.0427 14.9897 10 14.9879Z" fill={fill} />
        </svg>


    )
}

export default EyeCloseIcon