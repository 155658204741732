import React from "react";

const ConfigurationIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.749984 3.56262H2.80194C2.96291 4.1549 3.31431 4.67776 3.80191 5.05053C4.28952 5.4233 4.88623 5.62527 5.50001 5.62527C6.11378 5.62527 6.71049 5.4233 7.1981 5.05053C7.6857 4.67776 8.0371 4.1549 8.19807 3.56262H17.2496C17.4485 3.56262 17.6393 3.4836 17.7799 3.34295C17.9206 3.2023 17.9996 3.01154 17.9996 2.81263C17.9996 2.61373 17.9206 2.42296 17.7799 2.28231C17.6393 2.14167 17.4485 2.06265 17.2496 2.06265H8.19807C8.0371 1.47036 7.6857 0.947503 7.1981 0.574733C6.71049 0.201964 6.11378 0 5.50001 0C4.88623 0 4.28952 0.201964 3.80191 0.574733C3.31431 0.947503 2.96291 1.47036 2.80194 2.06265H0.749984C0.551076 2.06265 0.360314 2.14167 0.219665 2.28231C0.0790159 2.42296 0 2.61373 0 2.81263C0 3.01154 0.0790159 3.2023 0.219665 3.34295C0.360314 3.4836 0.551076 3.56262 0.749984 3.56262ZM5.49963 1.50016C5.75921 1.50016 6.01297 1.57714 6.2288 1.72135C6.44464 1.86557 6.61286 2.07055 6.7122 2.31037C6.81153 2.55019 6.83753 2.81409 6.78688 3.06868C6.73624 3.32328 6.61124 3.55714 6.42769 3.74069C6.24414 3.92424 6.01028 4.04924 5.75568 4.09989C5.50109 4.15053 5.23719 4.12454 4.99737 4.0252C4.75755 3.92586 4.55257 3.75764 4.40835 3.5418C4.26413 3.32597 4.18716 3.07222 4.18716 2.81263C4.18756 2.46467 4.32596 2.13106 4.57201 1.88501C4.81806 1.63896 5.15166 1.50056 5.49963 1.50016Z"
        fill={fill}
      />
      <path
        d="M17.2496 8.25088H15.1977C15.037 7.65846 14.6857 7.13541 14.1981 6.76248C13.7106 6.38956 13.1138 6.1875 12.5 6.1875C11.8861 6.1875 11.2894 6.38956 10.8018 6.76248C10.3142 7.13541 9.963 7.65846 9.80229 8.25088H0.749984C0.551076 8.25088 0.360314 8.3299 0.219665 8.47055C0.0790159 8.6112 0 8.80196 0 9.00086C0 9.19977 0.0790159 9.39053 0.219665 9.53118C0.360314 9.67183 0.551076 9.75085 0.749984 9.75085H9.80229C9.963 10.3433 10.3142 10.8663 10.8018 11.2392C11.2894 11.6122 11.8861 11.8142 12.5 11.8142C13.1138 11.8142 13.7106 11.6122 14.1981 11.2392C14.6857 10.8663 15.037 10.3433 15.1977 9.75085H17.2496C17.4485 9.75085 17.6393 9.67183 17.7799 9.53118C17.9206 9.39053 17.9996 9.19977 17.9996 9.00086C17.9996 8.80196 17.9206 8.6112 17.7799 8.47055C17.6393 8.3299 17.4485 8.25088 17.2496 8.25088ZM12.5 10.3133C12.2404 10.3133 11.9866 10.2364 11.7708 10.0921C11.555 9.94793 11.3868 9.74295 11.2874 9.50313C11.1881 9.2633 11.1621 8.99941 11.2127 8.74481C11.2634 8.49022 11.3884 8.25636 11.5719 8.07281C11.7555 7.88925 11.9893 7.76425 12.2439 7.71361C12.4985 7.66297 12.7624 7.68896 13.0022 7.7883C13.2421 7.88764 13.447 8.05586 13.5913 8.27169C13.7355 8.48753 13.8125 8.74128 13.8125 9.00086C13.8121 9.34883 13.6736 9.68243 13.4276 9.92848C13.1815 10.1745 12.8479 10.3129 12.5 10.3133Z"
        fill={fill}
      />
      <path
        d="M17.2496 14.4377H8.19807C8.0371 13.8454 7.6857 13.3225 7.1981 12.9497C6.71049 12.577 6.11378 12.375 5.50001 12.375C4.88623 12.375 4.28952 12.577 3.80191 12.9497C3.31431 13.3225 2.96291 13.8454 2.80194 14.4377H0.749984C0.551076 14.4377 0.360314 14.5167 0.219665 14.6573C0.0790159 14.798 0 14.9887 0 15.1876C0 15.3865 0.0790159 15.5773 0.219665 15.718C0.360314 15.8586 0.551076 15.9376 0.749984 15.9376H2.80194C2.96291 16.5299 3.31431 17.0528 3.80191 17.4255C4.28952 17.7983 4.88623 18.0003 5.50001 18.0003C6.11378 18.0003 6.71049 17.7983 7.1981 17.4255C7.6857 17.0528 8.0371 16.5299 8.19807 15.9376H17.2496C17.4485 15.9376 17.6393 15.8586 17.7799 15.718C17.9206 15.5773 17.9996 15.3865 17.9996 15.1876C17.9996 14.9887 17.9206 14.798 17.7799 14.6573C17.6393 14.5167 17.4485 14.4377 17.2496 14.4377ZM5.49963 16.5001C5.24005 16.5001 4.9863 16.4231 4.77046 16.2789C4.55463 16.1347 4.3864 15.9297 4.28707 15.6899C4.18773 15.4501 4.16174 15.1862 4.21238 14.9316C4.26302 14.677 4.38802 14.4431 4.57157 14.2596C4.75513 14.076 4.98899 13.951 5.24358 13.9004C5.49818 13.8497 5.76207 13.8757 6.00189 13.9751C6.24171 14.0744 6.44669 14.2426 6.59091 14.4585C6.73513 14.6743 6.8121 14.9281 6.8121 15.1876C6.81151 15.5355 6.67304 15.869 6.42703 16.115C6.18102 16.361 5.84754 16.4995 5.49963 16.5001Z"
        fill={fill}
      />
    </svg>
  );
};

export default ConfigurationIcon;
