import React from 'react'
import HistoryIcon from '../../../assets/icons/history'
import { removeUnderScore, truncateString } from '../../helper-functions/helper/helper'
import dayjs from 'dayjs'
import "../../../modules/orders/orders.css"

function OrderHistory({ orderDetails }) {
    return (
        <div className='bg_color_E5ECFFB2 rounded-2 p-3 order_history_container'>
            <h3 className='fw_500 fs_20 font_montserrat color_030304 mb-4'>Order History</h3>

            {orderDetails?.order_history?.map((item, index) => {
                console.log(removeUnderScore(Object?.keys(item)?.[0]), "rtrettreter");
                return <div title={removeUnderScore(Object?.keys(item)?.[0])} key={index} className='d-flex align-items-center gap-2 mb-2'>
                    <HistoryIcon /> <span className='color_030304 fs_14 fw_400 text-nowrap'>{truncateString(removeUnderScore(Object?.keys(item)?.[0]), 25)}:</span> <span className='color_9E9E9E fs_10 fw_400'>
                        {dayjs(Object?.values(item)?.[0]).format("DD MMM YYYY hh:mm:ss A")}
                    </span>
                </div>
            }
            )}
        </div>
    )
}

export default OrderHistory