import { all } from "redux-saga/effects";
import {
  watchDiscountCreateWorker,
  watchDiscountDeleteWorker,
  watchDiscountEditWorker,
  watchDiscountMultiDeleteWorker,
  watchDiscountTableWorker,
  watchDiscountViewWorker,
} from "../discounts-saga";
export default function* discountRootSaga() {
  yield all([
    watchDiscountTableWorker(),
    watchDiscountDeleteWorker(),
    watchDiscountMultiDeleteWorker(),
    watchDiscountViewWorker(),
    watchDiscountEditWorker(),
    watchDiscountCreateWorker(),
  ]);
}
