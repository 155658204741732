import React from "react";
import CreateButton from "../buttons/text-button";
import { useNavigate } from "react-router";
import { removeUnderScore } from "../../helper-functions/helper/helper";
import "./dashboard.module.css"
import { Link } from "react-router-dom";
const DashboardProductTable = ({
    tableTitle1 = "Dashboard-table",
    tableTitle2 = "",
    headerBackground = "#ffffff",
    viewAll = false,
    tableHeading,
    tableBody,
    customStyles = {},
    tableBorder = "1px solid #E5ECFF",
    accept = false,
    viewPath,
    acceptPath,
    buttonPath,
    acceptFn,
    canWrite,
    topProduct = false
}) => {
    console.log(tableBody, "recievedOrdersTableBody");
    const navigate = useNavigate();
    return (
        <div style={{ ...customStyles }}>
            <div className={`d-flex justify-content-between`}>
                <label className={`text-black fs_20 fw_500`}>
                    {tableTitle1}
                    <span className="color_FF6C1A">{tableTitle2}</span>
                </label>
                {viewAll && (
                    <CreateButton
                        createFn={() => navigate(buttonPath)}
                        icon={false}
                        name="View All"
                        textClass=""
                    />
                )}
            </div>
            <div className="mt-3 rounded-2" style={{ border: tableBorder }}>
                <div
                    className={`${tableBody?.length > 0 && `overflow-scroll`} table_main_container`}
                    style={{ height: "35.5rem" }}
                >
                    <table className="w-100">
                        <thead style={{ backgroundColor: headerBackground }}>
                            <tr style={{ borderBottom: "1px solid #E5ECFF" }}>
                                {tableHeading.map((heading, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {heading.value != "actions" && (
                                                <th
                                                    className="fs_14 color_707070 fw_500 ps-3 py-2 text-nowrap"
                                                    style={{ minWidth: "8rem" }}
                                                >
                                                    {heading.label}
                                                </th>
                                            )}
                                            {heading.value === "actions" && (
                                                <th
                                                    className=" px-2 py-1 fs_14 color_707070 fw_500 position-sticky"
                                                    style={{
                                                        right: "0px",
                                                        width: "100%",
                                                        backgroundColor: headerBackground,
                                                    }}
                                                >
                                                    {heading.label}
                                                </th>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tableBody?.map((tableValues, index) => {
                                console.log(tableValues, "recievedOrdersTableBody");
                                return (
                                    <>
                                        {
                                            topProduct === false ?
                                                <tr key={index} style={{ borderBottom: "1px solid #E5ECFF" }}>
                                                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                                                        <span className={`fs_14 text-black`}>
                                                            {tableValues.code ?? "--"}
                                                        </span>
                                                    </td>
                                                    {
                                                        (tableTitle1 === "Today's Product Enquiries" || tableTitle1 === "Top Product Enquiries") &&
                                                        <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                                                            <Link to={`/products/view/${tableValues.product_code?.[1]}`} className={`color_2820DA d-block ${tableValues.product_code?.[0] && "text-decoration-underline"}`}> {tableValues.product_code?.[0] ?? "--"}</Link>
                                                        </td>
                                                    }
                                                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                                                        <span className={`fs_14 text-black`}>
                                                            {tableValues.name ?? "--"}
                                                        </span>
                                                    </td>
                                                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                                                        <span className={`fs_14 text-black`}>
                                                            {tableValues.phone_number ?? "--"}
                                                        </span>
                                                    </td>
                                                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>

                                                    </td>
                                                    <td
                                                        className=" position-sticky bg-white px-2 pt-2 py-1 d-flex gap-2"
                                                        style={{ right: "0px", width: "100%" }}
                                                    >
                                                        <div
                                                            onClick={() => navigate(`${viewPath}/${tableValues?.id}`)}
                                                            className="fs_14 fw_500 pe-2 cursor_pointer color_3AD64C "
                                                            style={{ right: "0px", width: "100%" }}
                                                        >
                                                            View
                                                        </div>

                                                    </td>
                                                </tr>
                                                :
                                                <>

                                                    <tr key={index} style={{ borderBottom: "1px solid #E5ECFF" }}>
                                                        {
                                                            (tableTitle1 === "Today's Product Enquiries" || tableTitle1 === "Top Product Enquiries") &&
                                                            <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                                                                <Link to={`/products/view/${tableValues.product_code?.[1]}`} className={`color_2820DA d-block ${tableValues.product_code?.[0] && "text-decoration-underline"}`}> {tableValues.product_code?.[0] ?? "--"}</Link>
                                                            </td>
                                                        }
                                                        <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                                                            <span className={`fs_14 text-black`}>
                                                                {tableValues.name ?? "--"}
                                                            </span>
                                                        </td>
                                                        <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                                                            <span className={`fs_14 text-black`}>
                                                                {tableValues.enquiryCount ?? "--"}
                                                            </span>
                                                        </td>

                                                        <td
                                                            className=" position-sticky bg-white px-2 pt-2 py-1 d-flex gap-2"
                                                            style={{ right: "0px", width: "100%" }}
                                                        >
                                                            <div
                                                                onClick={() => navigate(`${viewPath}/${tableValues.product_code?.[1]}`)}
                                                                className="fs_14 fw_500 pe-2 cursor_pointer color_3AD64C "
                                                                style={{ right: "0px", width: "100%" }}
                                                            >
                                                                View
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                        }
                                    </>

                                );
                            })}
                        </tbody>
                    </table>
                    {tableBody?.length === 0 && (
                        <div className="d-flex align-items-center justify-content-center w-100 h-100 fs_20 fw_500">
                            -- Table is Empty --
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardProductTable;
