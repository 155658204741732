import React, { useState } from "react";
import UplaodImgLogo from "../../../assets/icons/Upload_image_logo.svg";
import "./table.css";
import Select from "react-select";
import { variationFunction } from "../../helper-functions/helper/date";

export default function ProductRejectionTable({
    tableDiscountHeader = [],
    product = [],
    setProduct,
    neededProduct,
    setNeededProduct
}) {
    const qty = product?.map((item) => item?.quantity);
    function createArrayOfObjectsFromQty(qtyArray) {
        return qtyArray?.map((qty) =>
            Array.from({ length: qty }, (_, index) => ({
                label: index + 1,
                value: index + 1,
            }))
        );
    }
    const qtyArray = createArrayOfObjectsFromQty(qty);
    const [selectedOptions, setSelectedOptions] = useState(
        product?.map((val) => val?.quantity)
    );
    // function updateTotalFn(index) {
    //     let total_price = 0;
    //     let price = 0;
    //     for (let i = 0; i < neededProduct.length; i++) {
    //         var ele = neededProduct[i]

    //         for (let inn = 0; inn < ele?.add_ons?.length; i++) {
    //             var addon = ele?.add_ons[inn]
    //             for (let adi = 0; adi < addon?.add_on_items?.length; i++) {
    //                 price = parseInt(addon?.add_on_items?.[adi]?.price) * addon?.add_on_items?.[adi] ?
    //             }
    //         }

    //     }
    // }
    console.log(product, "dsjhdjdshgfjdshgf");
    const handleSelected = (selectedOption, index, id) => {
        setSelectedOptions((prevSelectedOptions) => {
            const newSelectedOptions = [...prevSelectedOptions];
            newSelectedOptions[index] = selectedOption?.value;
            return newSelectedOptions;
        });
        setNeededProduct((prev) =>
            prev?.map((item, indexs) => {
                if (indexs === index) {
                    return {
                        ...item,
                        quantity: selectedOption?.value,
                        product_total_price: selectedOption?.value * item?.product_total_without_qty_price, // updating product quantity to 1
                    };
                } else {
                    return item;
                }
            })
        );
        // setProduct((prev) =>
        //     prev.map((item, indexs) => {
        //         if (indexs === index) {
        //             return {
        //                 ...item,
        //                 product_total_price: selectedOption?.value * item?.product_total_without_qty_price,
        //                 quantity: selectedOption?.value,
        //             };
        //         } else {
        //             return item;
        //         }
        //     })
        // );
    };

    function variantFn(item) {
        if (item?.variation?.length > 0 && Array.isArray(item?.variation)) {
            return item?.variation?.[0]?.variants?.map((item) => {
                return { value: item };
            })
        }
        else if (item?.variation && !Array.isArray(item?.variation)) {
            return item?.variation?.variants?.map((item) => {

                return { value: item };
            })
        }
        else return ["--"]
    }
    const removeProductByIndex = (index) => {
        setNeededProduct((prevProducts) => {
            const updatedProducts = prevProducts.filter((_, i) => i !== index);
            return updatedProducts;
        });
        setProduct((prevProducts) => {
            const updatedProducts = prevProducts.filter((_, i) => i !== index);
            return updatedProducts;
        });
    };
    return (
        <>
            <div
                className={`table_main_container rounded-3`}
            >
                <table className={"table_container h-100"}>
                    <thead className={`table_heading_container table_heading_border`}>
                        <tr className="table_heading_row">
                            {tableDiscountHeader?.map((head) => {
                                return (
                                    <th
                                        key={head?.label}
                                        className={` ${head?.label === "Actions" && "position-sticky end-0"
                                            } text-start 
                  text-nowrap products_table_heading_data color_707070 font_poppins`}
                                    >
                                        <div
                                            className={` dis_table_heading_without_edit_delete
                                                `}
                                        >
                                            {head?.label}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className="table_body_container h-100">
                        <>
                            {neededProduct?.map((item, index) => {

                                return (
                                    <tr
                                        key={index}
                                        className={`table_body_row fs_13 bg_color_ffffff   `}
                                    >
                                        <td
                                            className={`text-nowrap color_030304 dis_table_body_data fw_500 `}
                                        >
                                            <img
                                                src={
                                                    /^https?:\/\//i.test(item?.thumbnail_image)
                                                        ? item?.thumbnail_image ?? item?.product_picture
                                                        : UplaodImgLogo
                                                }
                                                width={"55px"}
                                                height={"55px"}
                                                className="rounded-1"
                                            />
                                        </td>
                                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                                            {
                                                variantFn(item)?.length > 0 ? variantFn(item)?.map((val) => {
                                                    return <span>{val?.value} | </span>
                                                }) : "--"
                                            }
                                        </td>
                                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                                            <div style={{ width: "4rem" }}>
                                                <Select
                                                    onChange={(selectedOption) =>
                                                        handleSelected(selectedOption, index, item?.id)
                                                    }
                                                    options={qtyArray[index]}
                                                    styles={customStyles}
                                                    defaultValue={qtyArray[index]?.find(
                                                        (opt) => opt.label === product[index]?.quantity
                                                    )}
                                                />
                                            </div>
                                        </td>
                                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                                            <span className="color_030304 p-1 pe-3">
                                                {item?.add_ons ? item?.add_ons?.map((item) => item?.add_on_items?.map(addon => {
                                                    return (
                                                        <p className="color_030304 fw_400 bg_order rounded-3 py-2 px-2 m-0">{addon?.name}<span className="color_9E9E9E">{addon?.quantity === 1 ? " " : " x "}</span>{addon?.quantity === 1 ? " " : addon?.quantity}<span className="fw_500 primary_color ms-3">₹ {addon?.price}</span></p>
                                                    )
                                                })) : "--"}
                                            </span>
                                        </td>
                                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                                            <span className="color_030304 p-1 pe-3">
                                                {variationFunction(item, "price")}
                                            </span>
                                        </td>
                                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                                            <span className="color_030304 p-1 pe-3">
                                                {variationFunction(item, "discount")}
                                            </span>
                                        </td>
                                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                                            <span className="color_030304 p-1 pe-3">
                                                {item?.product_total_without_qty_price * item?.quantity}
                                            </span>
                                        </td>
                                        <td className={`position-sticky end-0 px-3 text-nowrap dis_table_body_data bg_color_ffffff`}
                                        >
                                            <div className="position-sticky end-0">
                                                <button
                                                    className={`color_F24444 border-0 p-0 pe-md-4 pe-0 fs_15 fw_500 font_poppins  bg_color_ffffff`}
                                                    onClick={() => {
                                                        removeProductByIndex(index)
                                                    }}
                                                    type="button"
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    </tbody>
                </table>
            </div>
        </>
    );
}

const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (provided) => ({
        ...provided,
        border: "none",
        boxShadow: "none",
        backgroundColor: "#E5ECFFB2",
        borderRadius: "6px",
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Poppins,sans-serif",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#2820DA",
    }),
    singleValue: (base) => ({
        ...base,
        width: "fit-content",
        padding: "0px",
    }),
};