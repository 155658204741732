import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../../assets/icons/back-arrow-button.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../../modals/modal.css";
import { CancelButton, DeleteButton } from "../../buttons/text-button";

import invoiceimg from "../../../../assets/images/invoice.png";
import { useDispatch } from "react-redux";
import Compressor from "compressorjs";
import { categoriesImgUploadAction } from "../../../../redux/actions/categories-action";
import errorToast from "../../../helper-functions/helper/error-toast";
import pdfimage from "../../../../assets/images/pdf-image.png";
import DeleteIconWithBg from "../../../../assets/icons/products-delete";
import LoadingSpinner from "../../../helper-functions/helper/loadingSpinner";
import ErrorMsg from "../../forms/error-msg";
import successToast from "../../../helper-functions/helper/sucess-toast";
import { ordersEditAction } from "../../../../redux/actions/orders-action";

export default function UploadInvoice({
  show,
  modalClose,
  setFileType,
  fileType,
  reload,
  setReload,
  id = { id },
  selectedOption,
  paymentOption,
}) {
  const dispatch = useDispatch();
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [imageSize, setImageSize] = useState(false);
  const initialValues = {
    imagePdf: "",
  };
  const validationSchema = Yup.object().shape({
    imagePdf: Yup.string(),
  });

  const onSubmit = (values, { resetForm }) => {
    dispatch(
      ordersEditAction(
        {
          order_id: id,
          orders_status: selectedOption?.value,
          "payment_admin_status": "paid",
          order_invoice: values?.imagePdf,
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    setReload(!reload);
    successToast("Order Updated SuccessFully");
    modalClose();
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="add_delivery_details_model"
        contentClassName="rounded-3"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, field, errors, setFieldValue, resetForm }) => {
            console.log(errors, "ergyteytertert");
            console.log(values, "ergyteytertert");
            const handleImage = (e) => {
              setIsImgLoad(true);
              let img = e.target.files[0];
              if (img?.size <= 10485760) {
                dispatch(
                  categoriesImgUploadAction(
                    img,
                    (data) => onImageSuccess(data),
                    onImageError
                  )
                );
              } else {
                setImageSize(true);
              }
            };
            const onImageSuccess = (data, fieldName) => {
              setIsImgLoad(false);
              setFieldValue("imagePdf", data);
              const fileExtension = data?.split(".").pop();
              if (fileExtension === "pdf") {
                setFileType("pdf");
              } else {
                setFileType("image");
              }
            };
            const onImageError = (data) => {
              setIsImgLoad(false);
              errorToast(data.data.message ?? "Failed to upload image");
            };
            return (
              <Form>
                <ModalHeader>
                  <div className="ms-2">
                    <img onClick={modalClose} src={BackIcon} />
                    <span className="ms-2 fs_16 fw_500">Upload Invoice</span>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div style={{ height: "25rem" }} className="ms-4">
                    <div className="border_1px_e5ecff rounded-3 h-75 d-flex justify-content-center flex-column align-items-center">
                      {isImgLoad ? (
                        <LoadingSpinner />
                      ) : fileType === "pdf" ? (
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <img
                              className=""
                              src={pdfimage}
                              alt="invoice image"
                              height={"100px"}
                              width={"100px"}
                            />
                          </div>
                          <br />
                          <span>
                            {values?.imagePdf?.substring(
                              values?.imagePdf.lastIndexOf("/") + 1
                            )}
                          </span>
                          <br />
                          <div className="d-flex justify-content-center mt-3">
                            {" "}
                            <DeleteButton
                              deleteFn={() => {
                                setFieldValue("imagePdf", "");
                                setFileType("");
                              }}
                              page=""
                            />
                          </div>
                        </div>
                      ) : fileType === "image" ? (
                        <div className="w-100 h-100 position-relative">
                          <img
                            width={"100%"}
                            src={values?.imagePdf}
                            alt="invoice image"
                            height={"100%"}
                          />
                          <span
                            onClick={() => {
                              setFieldValue("imagePdf", "");
                              setFileType("");
                            }}
                            className="position-absolute top-0 end-0  pb-2"
                          >
                            <DeleteIconWithBg
                              height="60"
                              width="60"
                              fill="#F24444"
                              bgFill="#FFFFFF"
                            />
                          </span>
                        </div>
                      ) : (
                        <div
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault();
                            handleImage({
                              target: { files: e.dataTransfer.files },
                            });
                          }}
                          className="w-100"
                        >
                          <div className="d-flex justify-content-center">
                            <img
                              src={invoiceimg}
                              alt="invoice image"
                              height={"100px"}
                              width={"100px"}
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            <p className="text-center w-75 color_9E9E9E fw_400">
                              Drag & Drop invoice image or pdf here or <br />
                              <label
                                htmlFor="imagePdf"
                                className="primary_color cursor_pointer"
                              >
                                Browse files
                              </label>{" "}
                              on your computer
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <ErrorMsg
                      textClass="color_F24444 fs_16 d-block"
                      name="imagePdf"
                    />

                    {/* {
                      imageSize && "Please upload below 10mb"
                    } */}
                    <div className="d-flex justify-content-end  gap-3 me-4 my-3">
                      <span
                        onClick={() => {
                          modalClose();
                          setIsImgLoad(false);
                        }}
                      >
                        <CancelButton cancelClassName="px-2" />
                      </span>
                      <CancelButton
                        isDisabled={isImgLoad ? true : false}
                        name="SUBMIT"
                        cancelClassName="px-2"
                        bgColor="#2820DA"
                        textColor="text-white"
                        type="submit"
                      />
                    </div>
                  </div>
                  <div className="d-none">
                    <Field name="imagePdf">
                      {({ field, form }) => (
                        <div style={{ height: "0px" }}>
                          <input
                            accept="image/*,application/pdf;capture=camera,image/jpeg,image/png,image/svg,image/webp,image/jfif,application/pdf"
                            style={{
                              visibility: "hidden",
                              width: "100%",
                              height: "0px",
                            }}
                            type="file"
                            id={"imagePdf"}
                            // name={"imagePdf"}
                            // {...field}
                            onChange={handleImage}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </ModalBody>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
