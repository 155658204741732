import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  tagsDeleteAction,
  tagsViewAction,
} from "../../redux/actions/tags-action";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import { contactEnquiryViewAction } from "../../redux/actions/contact-enquiry-action";
import NotificationComponent from "../../components/notificationComponent";
const ContactEnquiriesView = () => {
  const params = useParams();
  const [contactEnq, setContactEnqDetails] = useState();
  const [isImgLoading, setIsImgLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(contactEnquiryViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setContactEnqDetails(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const tagsFindOne = [

    {
      label: "Customer Enquiry ID",
      value: contactEnq?.code ?? "--",
    },
    {
      label: "Name",
      value: contactEnq?.name ?? "--",
    },
    {
      label: "Mobile Number",
      value: contactEnq?.phone_number ?? "--",
    },
    {
      label: "Is WhatsApp Number",
      value: contactEnq?.is_whatsapp === true ? "Yes" : "No",
    },
    {
      label: "Message",
      value: contactEnq?.message ?? "--",
    },
  ];
  return (
    <>
    <NotificationComponent />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          <InnerLayout
            name={`${contactEnq?.code ?? "Tags View"}`}
            id={contactEnq?.id}
            deleteAction={tagsDeleteAction}
            path={`/contact-enquiry`}
            editButtonPath={`/tags/edit/${contactEnq?.id}`}
            type="tags"
          />

          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            {tagsFindOne.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <label className="color_707070 fs_14">{item.label}</label>
                  <div className="color_030304 fs_14 mb-1">{item.value}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ContactEnquiriesView;
