import { call, takeLatest } from "redux-saga/effects";

import {
  CONTACT_ENQUIRY_TABLE,
  CONTACT_ENQUIRY_VIEW,
} from "../types";
import { contactEnquiryTableApi, contactEnquiryViewApi } from "../apis/contact-enquiry-api";

export function* contactEnquiryTableWorker(action) {
  try {
    const res = yield call(contactEnquiryTableApi, action.data);
    console.log(action.data, "fsdfsdhfsdfsdfgsd");
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* contactEnquiryViewWorker(action) {
  try {
    const res = yield call(contactEnquiryViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchcontactEnquiryTableWorker() {
  yield takeLatest(CONTACT_ENQUIRY_TABLE, contactEnquiryTableWorker);
}
export function* watchcontactEnquiryViewWorker() {
  yield takeLatest(CONTACT_ENQUIRY_VIEW, contactEnquiryViewWorker);
}