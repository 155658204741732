import {
  call,
  cancel,
  fork,
  race,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  categoriesEditApi,
  categoriesDeleteApi,
  categoriesMultiDeleteApi,
  categoriesTableApi,
  categoriesViewApi,
  categoriesCreateApi,
  categoriesImgUploadApi,
  categoriesDropdownApi,
  configurationImgUploadApi,
} from "../apis/categories-api";
import {
  CATEGORIES_EDIT,
  CATEGORIES_DELETE,
  CATEGORIES_MULTI_DELETE,
  CATEGORIES_TABLE,
  CATEGORIES_VIEW,
  CATEGORIES_CREATE,
  CATEGORIES_IMAGE_UPLOAD,
  CATEGORIES_DROPDOWN_UPLOAD,
  CONFIGURATION_IMAGE_UPLOAD,
  CANCEL_CATEGORIES_IMAGE_UPLOAD,
  // CANCEL_CATEGORIES_IMAGE_UPLOAD,
} from "../types";
import { s3BucketText } from "../config";

export function* categoriesTableWorker(action) {
  try {
    const res = yield call(categoriesTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* categoriesViewWorker(action) {
  try {
    const res = yield call(categoriesViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* categoriesDeleteWorker(action) {
  try {
    const res = yield call(categoriesDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* categoriesMultiDeleteWorker(action) {
  console.log(action);
  try {
    const res = yield call(categoriesMultiDeleteApi, { data: action.data });
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
    console.log(error);
  }
}

export function* categoriesCreateWorker(action) {
  try {
    const res = yield call(categoriesCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* categoriesEditWorker(action) {
  try {
    const res = yield call(categoriesEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* categoriesImgUploadWorker(action) {
  console.log(action, "rgeytsdfsdfety");
  try {
    const imageData = new FormData();
    imageData.append("image", action.data);
    console.log(imageData, "imageData");
    const res = yield call(categoriesImgUploadApi, imageData);
    console.log(res.data.data);
    if (res.status === 200) {
      let urlParts = res.data.data.split("/");
      let lastPart = urlParts[urlParts.length - 1];
      yield action.onSuccess(
        res.data.data
      );
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* configurationImgUploadWorker(action) {
  console.log(action, "gdtdfgfdgdfgfg");
  try {
    const imageData = new FormData();
    imageData.append("image", action.data);
    const res = yield call(configurationImgUploadApi, imageData);
    if (res.status === 200) {
      let urlParts = res.data.data.split("/");
      let lastPart = urlParts[urlParts.length - 1];
      yield action.onSuccess(
        res.data.data
      );
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* categoriesDropdownWorker(action) {
  try {
    const res = yield call(categoriesDropdownApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* watchcategoriesTableWorker() {
  yield takeLatest(CATEGORIES_TABLE, categoriesTableWorker);
}
export function* watchcategoriesViewWorker() {
  yield takeLatest(CATEGORIES_VIEW, categoriesViewWorker);
}
export function* watchCategoriesEditWorker() {
  yield takeLatest(CATEGORIES_EDIT, categoriesEditWorker);
}
export function* watchcategoriesDeleteWorker() {
  yield takeLatest(CATEGORIES_DELETE, categoriesDeleteWorker);
}
export function* watchcategoriesMultiDeleteWorker() {
  yield takeLatest(CATEGORIES_MULTI_DELETE, categoriesMultiDeleteWorker);
}
export function* watchcategorieCreateWorker() {
  yield takeLatest(CATEGORIES_CREATE, categoriesCreateWorker);
}
// export function* watchCategorieImgUploadWorker() {
//   yield takeEvery(CATEGORIES_IMAGE_UPLOAD, categoriesImgUploadWorker);
// }
export function* watchCategorieDropdownWorker() {
  yield takeLatest(CATEGORIES_DROPDOWN_UPLOAD, categoriesDropdownWorker);
}

export function* watchConfigurationImgUploadWorker() {
  yield takeLatest(CONFIGURATION_IMAGE_UPLOAD, configurationImgUploadWorker);
}

// export function* watchCategorieImgUploadWithCancel() {
//   while (true) {
//     const action = yield take(CATEGORIES_IMAGE_UPLOAD);
//     yield race({
//       task: call(categoriesImgUploadWorker, action),
//       cancel: take(CANCEL_CATEGORIES_IMAGE_UPLOAD),
//     });
//   }
// }

export function* watchCategorieImgUploadWithCancel() {
  let tasks = [];
  while (true) {
    const action = yield take([
      CATEGORIES_IMAGE_UPLOAD,
      CANCEL_CATEGORIES_IMAGE_UPLOAD,
    ]);

    if (action.type === CANCEL_CATEGORIES_IMAGE_UPLOAD) {
      for (let task of tasks) {
        yield cancel(task);
      }
      tasks = [];
    } else if (action.type === CATEGORIES_IMAGE_UPLOAD) {
      const task = yield fork(categoriesImgUploadWorker, action);
      tasks.push(task);
    }
  }
}
