import React, { useEffect, useState } from "react";
import "./meta-table.css";
import DeleteModal from "../modals/delete-modal";
export default function ProductMetaDataTable({
  metaList,
  setMetaList,
  editable = true,
}) {
  const [deleteData, setDeleteData] = useState();
  const [show, setShow] = useState(false);
  console.log(metaList, "dfghdkgdfsdfjhdgjkhdfgj");
  const [textareaHeights, setTextareaHeights] = useState({});
  const deleteCloseFn = () => setShow(false);

  useEffect(() => {
    const newHeights = {};
    const newHeights1 = {};
    metaList.forEach((item, index) => {
      const textarea = document.getElementById(`inputField-${index}`);
      const textarea1 = document.getElementById(`inputField1-${index}`);
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
        newHeights[index] = `${textarea.scrollHeight}px`;
      }
      if (textarea1) {
        textarea1.style.height = "auto";
        textarea1.style.height = `${textarea1.scrollHeight}px`;
        newHeights1[index] = `${textarea1.scrollHeight}px`;
      }
    });
    setTextareaHeights(newHeights);
  }, [metaList]);

  const handleTextareaChange = (e, index) => {
    setMetaList((prevMetaList) => {
      const updatedMetaList = [...prevMetaList];
      updatedMetaList[index]["title"] = e.target.value;
      return updatedMetaList;
    });
    const newHeights = { ...textareaHeights };
    newHeights[index] = `${e.target.scrollHeight}px`;
    setTextareaHeights(newHeights);
  };

  const [textareaHeights1, setTextareaHeights1] = useState({});
  const handleTextareaChange1 = (e, index) => {
    setMetaList((prevMetaList) => {
      const updatedMetaList = [...prevMetaList];
      updatedMetaList[index]["value"] = e.target.value;
      return updatedMetaList;
    });
    const newHeights = { ...textareaHeights };
    newHeights[index] = `${e.target.scrollHeight}px`;
    setTextareaHeights1(newHeights);
  };
  function deleteFn(index) {
    setDeleteData(index);
    setShow(true);
  }
  function deleteDataFn(index) {
    setMetaList((prevMetaList) => {
      const updatedMetaList = prevMetaList.filter((item, i) => i !== index);
      return updatedMetaList;
    });
    setShow(false);
  }
  return (
    <>
      <DeleteModal
        text={`Are you sure, you want to delete this Meta Data permanently?`}
        show={show}
        modalClose={deleteCloseFn}
        deleteData={deleteData}
        deleteFn={() => deleteDataFn(deleteData)}
        type={"delete"}
      />
      <div className={`table_main_container rounded-3 border-0`}>
        <table className={"table_container h-100"}>
          <thead className={`table_heading_container table_heading_border`}>
            <tr className="table_heading_row">
              {editable && (
                <th
                  className={` position-sticky end-0 text-start  
                  text-nowrap products_table_heading_data`}
                >
                  <input
                    e={{ transform: "scale(1.1)" }}
                    type="checkbox"
                    id="myCheckbox"
                    className="custom-checkbox form-check-input"
                  />
                </th>
              )}
              <th
                className={`text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
              >
                <div className={`discounts_table_Action_heading`}>
                  Meta Title
                </div>
              </th>
              <th
                className={`text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
              >
                <div className={`discounts_table_Action_heading`}>
                  Meta Value
                </div>
              </th>
              {editable && (
                <th
                  className={`position-sticky end-0 text-start text-nowrap products_table_heading_data color_707070 font_poppins`}
                >
                  <div className={`discounts_table_Action_heading`}>
                    Actions
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="table_body_container h-100">
            <>
              {metaList?.map((item, index) => (
                <tr
                  key={index}
                  className={`table_body_row fs_13 bg_color_ffffff`}
                >
                  {editable && (
                    <td className="text-nowrap meta_table_body_data">
                      <input
                        id={item?.id}
                        style={{ transform: "scale(1.2)" }}
                        type="checkbox"
                        className="custom-checkbox form-check-input"
                      />
                    </td>
                  )}
                  <td className="text-nowrap color_030304 meta_table_body_data fw_500">
                    <div class="expanding-input d-flex flex-column  color_030304">
                      <textarea
                        // disabled={index + 1 < metaList?.length}
                        value={item?.title}
                        style={{ height: textareaHeights[index] || "auto" }}
                        onChange={(e) => handleTextareaChange(e, index)}
                        placeholder="Meta title goes here"
                        className="metaInputField rounded-2 w-100"
                        rows="1"
                        id={`inputField-${index}`}
                      ></textarea>
                      {/* <span className="color_F23D3D">Required!</span> */}
                    </div>
                  </td>
                  <td className="text-nowrap color_030304 meta_table_body_data fw_500">
                    <div class="expanding-input d-flex flex-column  color_030304">
                      <textarea
                        // disabled={index + 1 < metaList?.length}
                        value={item?.value}
                        style={{ height: textareaHeights1[index] || "auto" }}
                        onChange={(e) => handleTextareaChange1(e, index)}
                        placeholder="Meta value goes here"
                        className="metaInputField rounded-2 w-100"
                        rows="1"
                        id={`inputField1-${index}`}
                      ></textarea>
                      {/* <span className="color_F23D3D">Required!</span>d */}
                    </div>
                  </td>
                  {editable && (
                    <td
                      className={`meta_table_body_data text-nowrap position-sticky  end-0 color_030304      h-100 bg_color_ffffff`}
                    >
                      <div className={`/ align-items-center d-flex`}>
                        <button
                          onClick={() => deleteFn(index)}
                          type="button"
                          className={`color_F24444 border-0 bg_color_ffffff   p-0 pe-md-4 pe-0 fs_15 fw_500 font_poppins `}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
}

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#E5ECFFB2",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins,sans-serif",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#2820DA",
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    padding: "0px",
  }),
};
