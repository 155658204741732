import React, { useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import ImageUpload from "../../components/ui/forms/image-upload";
import InputField from "../../components/ui/forms/input-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import { tagsCreateAction } from "../../redux/actions/tags-action";
import { categoriesImgUploadAction } from "../../redux/actions/categories-action";
import Compressor from "compressorjs";
import NotificationComponent from "../../components/notificationComponent";
const TagsCreate = () => {
  const navigate = useNavigate();
  const [imgUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageSize, setImageSize] = useState(false);
  const dispatch = useDispatch();
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (data) => {
    setLoading(false);
    setImageUrl(data);
  };
  const onImgError = (data) => {
    setLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  const initialValues = {
    name: "",
    slug: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Category Name is required"),
    slug: Yup.string().trim().nullable(),
  });
  const onSubmit = (values) => {
    dispatch(
      tagsCreateAction(
        {
          tag_picture: imgUrl,
          name: values.name,
          slug: values.slug,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/tags`);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };

  function resetFn() {
    setImageUrl("");
  }
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <InnerLayout
            name="New Tag"
            page="create"
            edit={true}
            resetFn={resetFn}
            rolePermission={"tags"}
          />
          <span>
          <ImageUpload
            labelClass="color_030304"
            name="tag_picture"
            getImgUrl={getImgUrl}
            resetFn={resetFn}
            data={imgUrl}
            loading={loading}
          />
          {imageSize && <label className="fs_14 ms-5 text-danger mb-3">"Please upload below 10mb"</label>}
          </span>
          
          <div
            className="ms-md-5 ms-4 pe-4 pe-md-0"
            style={{ maxWidth: "40rem" }}
          >
            <div>
              <InputField
                placeholder="Enter your tag name"
                containerClass="mb-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Tag Name"
                name="name"
                type="text"
              />
            </div>
            <div>
              <InputField
                placeholder="Enter slug name"
                containerClass="mb-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Slug"
                name="slug"
                type="text"
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default TagsCreate;
