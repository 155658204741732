import { call, takeLatest } from "redux-saga/effects";
import {
  DELIVERYPARTNER_CREATE,
  DELIVERYPARTNER_DELETE,
  TAGS_DROPDOWN_LIST,
  DELIVERYPARTNER_EDIT,
  DELIVERYPARTNER_MULTI_DELETE,
  DELIVERYPARTNER_TABLE,
  DELIVERYPARTNER_VIEW,
  DELIVERY_BLOCK,
} from "../types";
import {
  deliverypartnerCreateApi,
  deliverypartnerDeleteApi,
  tagsDropDownListApi,
  deliverypartnerEditApi,
  deliverypartnerMultiDeleteApi,
  deliverypartnerTableApi,
  deliverypartnerViewApi,
  deliveryBlockApi,
} from "../apis/delivery-partner-api";

export function* deliverypartnerTableWorker(action) {
  try {
    const res = yield call(deliverypartnerTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* deliverypartnerViewWorker(action) {
  try {
    const res = yield call(deliverypartnerViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* deliverypartnerDeleteWorker(action) {

  console.log(action, "dvfxvxcdvcxv");

  try {
    const res = yield call(deliverypartnerDeleteApi,
      {
        "driver_id": action?.data
      }
    );
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* deliverypartnerMultiDeleteWorker(action) {
  console.log(action, "dsvfsfdsfds");

  try {
    const res = yield call(deliverypartnerMultiDeleteApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);

  }
}

export function* deliverypartnerCreateWorker(action) {
  try {
    const res = yield call(deliverypartnerCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* deliverypartnerEditWorker(action) {
  try {
    const res = yield call(deliverypartnerEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tagsDropDownListWorker(action) {
  try {
    const res = yield call(tagsDropDownListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* deliveryBlockWorker(action) {


  try {
    const res = yield call(deliveryBlockApi,
      { driver_id: action.data }
    );
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchDeliverypartnerTableWorker() {
  yield takeLatest(DELIVERYPARTNER_TABLE, deliverypartnerTableWorker);
}
export function* watchDeliverypartnerViewWorker() {
  yield takeLatest(DELIVERYPARTNER_VIEW, deliverypartnerViewWorker);
}
export function* watchDeliverypartnerEditWorker() {
  yield takeLatest(DELIVERYPARTNER_EDIT, deliverypartnerEditWorker);
}
export function* watchDeliverypartnerDeleteWorker() {
  yield takeLatest(DELIVERYPARTNER_DELETE, deliverypartnerDeleteWorker);
}
export function* watchDeliverypartnerMultiDeleteWorker() {
  yield takeLatest(DELIVERYPARTNER_MULTI_DELETE, deliverypartnerMultiDeleteWorker);
}
export function* watchDeliverypartnerCreateWorker() {
  yield takeLatest(DELIVERYPARTNER_CREATE, deliverypartnerCreateWorker);
}
export function* watchtagsDropDownListWorker() {
  yield takeLatest(TAGS_DROPDOWN_LIST, tagsDropDownListWorker);
}

export function* watchDeliveryBlockWorker() {
  yield takeLatest(DELIVERY_BLOCK, deliveryBlockWorker);
}
