import { api } from "./api";

export const attributesTableApi = (data, params) => {
  return api.get(
    `/core/attributes/list?search=${data?.search}&page=${data?.page}&page_size=${data?.size}&attribute_name=${data?.attribute_name}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};
export const attributesDeleteApi = (data, params) => {
  return api.delete(
    `/core/attributes/delete?attribute_id=${data}`,
    JSON.stringify(params)
  );
};
export const attributesMultiDeleteApi = (params) => {
  return api.delete(`/core/attributes/bulk-delete`, params);
};
export const attributesViewApi = (data) => {
  return api.get(`/core/attributes/view?attribute_id=${data}`);
};

export const attributesEditApi = (params) => {
  return api.post(`/core/attributes/edit`, JSON.stringify(params));
};

export const attributesCreateApi = (params) => {
  return api.post(`/core/attributes/create`, JSON.stringify(params));
};
export const attributesDropDownListApi = () => {
  return api.get(`/core/attributes/list-all-attributes`);
};
