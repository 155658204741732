import { formatDate } from "../../helper-functions/helper/date";
import { booleanConverter } from "../../helper-functions/helper/helper";

export function ViewContent({
  dataKeys = {},
  labels = [],
  data = {},
  containerClass = "m-4 p-4",
  listClass = "mb-4",
  listValueClass = "color_030304",
  dateFormat = "DD MMM YYYY",
}) {
  function valueRender(label, index) {
    if (label.renderAs) {
      return label.renderAs(data[dataKeys[index]], labels, data);
    } else if (label.type === "date") {
      return (
        <p className={listValueClass}>
          {formatDate(data[dataKeys[index]], dateFormat) ?? "--"}
        </p>
      );
    } else {
      return (
        <p
          className={`${listValueClass} ${
            dataKeys[index] === "email_address" ? "" : "text-capitalize"
          }`}
        >
          {data[dataKeys[index]]
            ? booleanConverter(data[dataKeys[index]])
            : `--`}
        </p>
      );
    }
  }
  return (
    labels.length > 0 && (
      <ul className={`list-unstyled ${containerClass} font_poppins`}>
        {labels.map((label, index) => {
          return (
            <li key={index} className={listClass}>
              {valueRender(label, index) && (
                <p className="color_707070 fs_14 mb-1 fw_500">
                  {label.label
                    ? typeof label.label === "function"
                      ? label.label()
                      : label.label
                    : label}
                </p>
              )}
              {valueRender(label, index)}
            </li>
          );
        })}
      </ul>
    )
  );
}
