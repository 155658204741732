import React, { useEffect, useState } from "react";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import * as Yup from "yup";
import {
  BackButton as UndoButton,
  SaveButton,
  DeleteButton,
} from "../../buttons/text-button";
import { Form, Formik } from "formik";
import InputField from "../../forms/input-field";
import QuillField from "../../forms/quill-field";
import DeleteModal from "../../modals/delete-modal";
import { useDispatch } from "react-redux";
import { configPageDeleteAction, configPageEditAction, configPageViewAction } from "../../../../redux/actions/configuration-action";
import errorToast from "../../../helper-functions/helper/error-toast";
import successToast from "../../../helper-functions/helper/sucess-toast";
export default function ConfigSelectedPage({ data, onBack = () => { } }) {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false);
  const [main_type_id, setMain_type_id] = useState(null)
  const dispatch = useDispatch()
  const initialValues = {
    title: data.page_data?.title ?? "",
    slug: data.page_data?.slug ?? "",
    content: data.page_data?.content ?? "",
  };
  const pageSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    slug: Yup.string(),
    content: Yup.string().required("Required"),
  });

  function onDeleteSuccess(data) {
    setShow(false)
    successToast(data.message)
    onBack()
  }
  function onDeleteError(data) {
    console.log(data);
    errorToast(data.data.message)
  }
  function onEditSuccess(data) {
    setShow(false)
    successToast(data.message)
    setLoading(false)
    onBack()
  }
  function onEditError(data) {
    console.log(data);
    setLoading(false)
    errorToast(data.data.message)
  }

  useEffect(() => {
    if (!data.id) {
      dispatch(configPageViewAction(data.main_page_type, onFetchSuccess, onFetchError))
    }
  }, [])

  function onFetchSuccess(data) {
    setMain_type_id(data.data?.id)
    console.log(data);

  }
  function onFetchError(data) {
    setMain_type_id(null)
    errorToast(data.data?.message)
  }

  console.log(data.pageData?.title);
  return (
    <>
      {" "}
      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={pageSchema}

        onSubmit={(values) => {
          setLoading(true)
          const formData = {
            "page_id": data.id ? data.id : main_type_id,
            "page_data": {
              "title": values.title,
              "slug": values.slug,
              "content": values.content
            }
          }
          dispatch(configPageEditAction(formData, onEditSuccess, onEditError))
        }}
      >
        {({ values }) => {
          return (
            <Form>
              {" "}
              <div
                style={{ top: "3.5rem" }}
                className="col-12 d-flex flex-wrap gap-3 z-3 align-items-center justify-content-between py-3 bg-white  position-sticky   border_bottom_1px_e5ecff   
            px-3
            px-sm-5
            "
              >
                <div
                  onClick={onBack}
                  className="d-flex align-items-center gap-2 cursor_pointer"
                >
                  <img src={backIcon} alt="back" width={25} height={25} />
                  <span className="font_montserrat color_030304 fw_500 fs_20">
                    {data.page_name}
                  </span>
                </div>

                <div className="d-flex flex-wrap gap-3">
                  {data.id && < DeleteButton
                    deleteFn={() => {
                      setShow(true);
                    }}
                    disabled={loading}
                    page=""
                  />}
                  <UndoButton
                    disabled={JSON.stringify(values) === JSON.stringify(data.page_data) || loading}
                    type="reset"
                    name="UNDO"
                    backClassName="fs_14"
                  />
                  <SaveButton
                    disabled={JSON.stringify(values) === JSON.stringify(data.page_data) || loading}
                    loading={loading}
                    //   disabled={disabled}
                    type="submit"
                    backClassName="fs_14"
                  //   onClick={saveFn}
                  />
                </div>
              </div>
              <div className="mt-5 px-3 px-sm-5">
                <InputField
                  inputClass="py-2 w-100"
                  label="Title"
                  name="title"
                  errMsgContainerClass="ps-2"
                  placeholder="Enter title"
                />
                <InputField
                  inputClass="py-2 w-100"
                  label="Slug"
                  name="slug"
                  errMsgContainerClass="ps-2"
                  placeholder="Enter slug"
                />{" "}
                <QuillField
                  toolbarItems={[
                    [{ align: "" }, { align: "center" }, { align: "right" }],

                    [
                      {
                        size: [
                          "10px",
                          "12px",
                          "14px",
                          "16px",
                          "18px",
                          "20px",
                          "22px",
                          "24px",
                          "26px",
                        ],
                      },
                    ],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["image"],
                  ]}
                  editorStyle="
            .ql-toolbar > :nth-last-child(2) {
              margin-left: 0px;
              }
              .ql-toolbar.ql-snow > :nth-child(2) > .ql-picker {
                width: 60px;
                background-color: white;
                border-radius: 5px;
              }
              @media only screen and (max-width: 576px) {
                .ql-toolbar.ql-snow .ql-formats {
                  margin-right: 0px;
              } 
            "
                  name="content"
                  label="Content"
                  placeholder="Write your content here"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <DeleteModal
        text={`Are you sure, you want to delete this page permanently?`}
        show={show}
        modalClose={() => {
          setShow(false);
        }}
        // deleteData={deleteData}
        deleteFn={() => {
          dispatch(configPageDeleteAction(data.id, onDeleteSuccess, onDeleteError))
        }

        }
        type={"delete"}
      />
    </>
  );
}
