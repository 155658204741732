import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminUserDetailsAction } from "../../../redux/actions/admin-users-action";

const useLoggedAdminDetails = () => {
  const dispatch = useDispatch();
  // const [loggedAdminData, setLoggedAdminData] = useState({});
  const [timer, setTimer] = useState(null);
  const loggedAdminData = useSelector((store) => store.loggedAdminReducer);
  useEffect(() => {
    const fetchAdminDetails = () => {
      // dispatch(adminUserDetailsAction(onSuccess, onError));
      dispatch(adminUserDetailsAction());
    };
    fetchAdminDetails();
    const intervalId = setInterval(fetchAdminDetails, 15 * 60 * 1000);
    setTimer(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // const onSuccess = (data) => {
  //   setLoggedAdminData(data?.data);
  //   localStorage.setItem("loggedAdminData",JSON.stringify(data?.data))
  //   console.log(data);
  // };
  // const onError = (error) => {
  //   console.log(error);
  // };

  // return loggedAdminData;
  return loggedAdminData;
};

export default useLoggedAdminDetails;
