import React, { useEffect, useState } from "react";

import FaqTable from "./faq-table";
import FaqCreate from "./faq-create";
import FaqEdit from "./faq-edit";
import { faqViewAction } from "../../../../redux/actions/faq-action";
import { useDispatch } from "react-redux";
import FaqView from "./faq-view";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";

export default function FaqSection({ name, closeSection }) {
  const dispatch = useDispatch()
  const [tab, setTab] = useState("table")
  const [id, setId] = useState("")
  const [faq, setFaq] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    dispatch(
      faqViewAction(
        id,
        onViewSuccess,
        onViewError,
      )
    )
  }, [tab, id])

  const onViewSuccess = (data) => {
    setLoading(false)
    setFaq(data?.data)
  }
  const onViewError = (data) => {
    setLoading(false)

  }
  return (
    <>


      <>
        {
          tab === "table" &&
          <>
            <FaqTable currentPage={currentPage} setCurrentPage={setCurrentPage} setId={setId} closeSection={closeSection} tab={tab} setTab={setTab} />
          </>
        }
        {
          tab === "create" &&
          <>
            <FaqCreate closeSection={closeSection} tab={tab} setTab={setTab} />
          </>
        }
        {
          tab === "edit" && faq != {} &&
          <>
            {
              loading ?
                <LoadingSpinnerTable /> : <FaqEdit setCurrentPage={setCurrentPage} currentPage={currentPage} faq={faq} closeSection={closeSection} tab={tab} setTab={setTab} />
            }

          </>
        }
        {
          tab === "view" && faq != {} &&
          <>
            {
              loading ?
                <LoadingSpinnerTable /> :
                <FaqView setCurrentPage={setCurrentPage} currentPage={currentPage} faq={faq} closeSection={closeSection} tab={tab} setTab={setTab} setId={setId} />
            }
          </>
        }
      </>
      {/* } */}

    </>
  );
}


