import React, { useEffect, useState } from "react";
import CreateButton, {
  AddWithBorderButton,
  ApplyButton,
  BackButton,
  SaveButton,
  UnblockButton,
} from "../../buttons/text-button";
import Compressor from "compressorjs";
import usePermissions from "../../../helper-functions/hooks/usePermission-checker";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import isEqual from "lodash.isequal";
import {
  homeSettingsCreateAction,
  homeSettingsViewAction,
  mailNotifyCreateAction,
  mailNotifyViewAction,
} from "../../../../redux/actions/configuration-action";
import { useDispatch } from "react-redux";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";
import ImageUpload from "../../forms/image-upload";
import {
  categoriesDropdownAction,
  categoriesImgUploadAction,
} from "../../../../redux/actions/categories-action";
import InputField from "../../forms/input-field";
import HomeVariant from "./home-variant";
import { tagsDropDownListAction } from "../../../../redux/actions/tags-action";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import BannerImageField from "../../forms/banner-image-field";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import addBanner from "../../../../assets/icons/layout-icons/newIcons/addBanner.svg";
import FillBannerModal from "../../modals/fill-banner-modal";

const HomeSetting = ({ connected = false, closeSection }) => {
  const dispatch = useDispatch();
  const { canWrite } = usePermissions();
  const [mailNotifyData, setMailNotifyData] = useState();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [numVariations, setNumVariations] = useState(1);
  const [viewData, setViewData] = useState();
  const [tagsList, setTagsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [bannerValues, setBannerValues] = useState([]);
  const [fillBannerShow, setFillBannerShow] = useState(false);
  const [fillBannerMsg, setFillBannerMsg] = useState(
    "Please fill all previous banners to add more banner"
  );

  useEffect(() => {
    dispatch(categoriesDropdownAction(onFetchCatSuccess, onFetchCatError));

    dispatch(tagsDropDownListAction(onFetchTagSuccess, onFetchTagError));
  }, [reload]);

  const onFetchCatSuccess = (data) => {
    setCategoryList(
      data.data.map((item) => {
        return { label: item.category_name, value: item.id };
      })
    );
  };
  const onFetchCatError = () => {
    console.log("failed to load parent category list");
  };

  const onFetchTagSuccess = (data) => {
    setTagsList(
      data.data.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  };
  const onFetchTagError = () => {
    console.log("failed to load tags list");
  };
  const [initialValues, setInitialValues] = useState({
    banners: [
      {
        image: "",
        url: "",
        is_created: false,
      },
    ],

    variations: Array.from({ length: numVariations }, (_, i) => ({
      product_list_name: "",
      tags: [],
      categories: [],
      banner: "",
    })),
  });

  useEffect(() => {
    setLoading(true);
    dispatch(
      homeSettingsViewAction((data) => onViewSuccess(data), onViewError)
    );
  }, [reload]);
  const onViewSuccess = (data) => {
    setLoading(false);
    setViewData(data?.data);
    const initData = data?.data;
    setNumVariations(initData?.product_list?.length ?? 0);
    setInitialValues({
      banners:
        initData?.banners?.length > 0
          ? initData?.banners
          : [
              {
                image: "",
                url: "",
                is_created: false,
              },
            ],
      variations: Array.from(
        { length: initData?.product_list?.length ?? 0 },
        (_, i) => ({
          product_list_name:
            initData?.product_list?.[i]?.product_list_name ?? "",
          tags:
            initData?.product_list?.[i]?.tags?.map((tag) => ({
              label: tag?.name,
              value: tag?.id,
            })) ?? [],
          categories:
            initData?.product_list?.[i]?.categories?.map((tag) => ({
              label: tag?.name,
              value: tag?.id,
            })) ?? [],
          banner: initData?.product_list?.[i]?.banner ?? "",
        })
      ),
    });
  };
  const onViewError = (data) => {
    setLoading(false);
  };
  console.log(initialValues, "initialValues");
  const validationSchema = Yup.object().shape({
    // image: Yup.mixed().required("Required!"),
    // url: Yup.string().required("Required"),

    variations: Yup.array().of(
      Yup.object().shape({
        product_list_name: Yup.string().required(
          "Product List Name is required"
        ),
        // tags: Yup.array().min(1, "At least one tag must be selected").required("At least one tag must be selected"),
        // categories: Yup.array().min(1, "At least one category must be selected").required("At least one category must be selected"),
        tags: Yup.array().test(
          "at-least-one-selected",
          "At least one tag or category must be selected",
          function (value, context) {
            const categories = context.parent.categories || [];
            return (
              (value && value.length > 0) ||
              (categories && categories.length > 0)
            );
          }
        ),
        categories: Yup.array().test(
          "at-least-one-selected",
          "At least one tag or category must be selected",
          function (value, context) {
            const tags = context.parent.tags || [];
            return (value && value.length > 0) || (tags && tags.length > 0);
          }
        ),
        banner: Yup.mixed(),
      })
    ),
  });

  const onSubmit = (values) => {
    console.log(values, "values ddddddd");
    const bannersWithoutEmpty = values.banners?.filter(
      (item) => (item.url !== "" || item.image !== "") && item
    );
    dispatch(
      homeSettingsCreateAction(
        {
          banners: bannersWithoutEmpty ?? [],
          product_list: values?.variations?.map((item) => ({
            product_list_name: item?.product_list_name,
            tags: item?.tags?.map((tag) => ({
              id: tag?.value,
              name: tag?.label,
            })),
            categories: item?.categories?.map((cat) => ({
              id: cat?.value,
              name: cat?.label,
            })),
            banner: item?.banner,
          })),
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data.message);
    setReload(!reload);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };

  function valuesCompareFn(values) {
    console.log(initialValues, values, "initialValuesinitialValues");
    const areEqual = isEqual(initialValues, values);
    if (
      areEqual &&
      initialValues?.variations?.length === viewData?.product_list?.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  const AddMoreBanner = (setFieldValue, values) => {
    // console.log("banner data", values);
    const previousRowFilled = values.banners?.every(
      (item) => item?.url !== "" && item?.image !== ""
    );
    const emptyValuesIndex = values.banners?.findIndex(
      (item) => item?.url === "" || item?.image === ""
    );
    if (previousRowFilled == false) {
      setFillBannerShow(true);
      setFillBannerMsg(
        `Please fill  banner ${emptyValuesIndex + 1} to add more banner  `
      );
    }
    console.log(previousRowFilled);
    if (previousRowFilled) {
      const newCard = {
        image: "",
        url: "",
        is_created: false,
      };
      const updatedCards = [...values.banners, newCard];
      setFieldValue("banners", updatedCards);
      console.log(values, "banner data");
    }
  };

  const handleRemoveCard = (setFieldValue, values, index) => {
    const updatedCards = [...values.banners];
    updatedCards.splice(index, 1);
    setFieldValue("banners", updatedCards);
    console.log(values);
  };

  console.log(
    bannerValues.map((item, index) => ({
      ...item,
      key: `banner-${index}`,
    })),
    "bannerData"
  );

  return (
    <div className="">
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, isValid, errors, setFieldValue }) => {
            const moveBanner = (dragIndex, hoverIndex) => {
              const draggedBanner = values.banners[dragIndex];
              const updatedBanners = [...values.banners];
              updatedBanners.splice(dragIndex, 1);
              updatedBanners.splice(hoverIndex, 0, draggedBanner);
              setFieldValue("banners", updatedBanners);
            };
            const Banner = ({ data, index }) => {
              const [, drag] = useDrag({
                type: "BANNER",
                item: { index },
              });

              const [, drop] = useDrop({
                accept: "BANNER",
                hover: (item) => {
                  if (item.index !== index) {
                    moveBanner(item.index, index);
                    item.index = index;
                  }
                },
              });

              return (
                <div
                  ref={(node) => drag(drop(node))}
                  className="rounded-2 mb-4"
                >
                  <BannerImageField
                    data={data}
                    index={index}
                    setFieldValue={setFieldValue}
                  />
                  <input
                    disabled
                    style={{ border: "1px solid #E5ECFF", width: "98%" }}
                    className="mt-4 rounded-1 px-2 py-1"
                    value={data?.url}
                  />
                </div>
              );
            };

            console.log(values, "imgUghghrl");
            console.log(errors, "viewDataviewDataviewDataviewData");
            setBannerValues(values?.banners);
            return (
              <>
                <Form>
                  <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                    <div
                      className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat mb-0 px-xxl-4 me-2"
                      style={{ width: "fit-Content" }}
                    >
                      <img
                        className="d-inline-block d-md-none me-2"
                        onClick={closeSection}
                        src={backIcon}
                      />{" "}
                      Home Settings
                    </div>
                    <div
                      className="me-xxl-5 pe-xxl-5"
                      style={{ width: "fit-Content" }}
                    >
                      {canWrite("configuration") && (
                        <div className="d-flex gap-3">
                          <BackButton
                            undoFn={() => {
                              setReload(!reload);
                            }}
                            disabled={valuesCompareFn(values)}
                            type="reset"
                            name="UNDO"
                            backClassName="fs_14"
                          />

                          <SaveButton
                            // disabled={valuesCompareFn(values)}
                            type="submit"
                            backClassName="fs_14"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-4  px-md-5 px-3 px-md-0 mb-5">
                    <span className="font_montserrat fs_18 fw_500 color_030304">
                      Ads Section
                    </span>
                    <br />
                    <p className="font_poppins fs_16 fw_500 color_000000 mb-2">
                      Banners
                    </p>
                    <DndProvider backend={HTML5Backend}>
                      <div className="row">
                        {values?.banners?.map((data, index) => (
                          <>
                            <div className="col-xl-4 col-sm-6 col-12 mt-xl-0 mt-3">
                              <div className="d-flex justify-content-between align-items-end mb-1">
                                <label className="fs_14">
                                  Banner {index + 1}{" "}
                                  {/* {index === 0 && (
                                    <span className="text-danger">*</span>
                                  )} */}
                                </label>
                                <>
                                  {index > -1 ? (
                                    <button
                                      type="button"
                                      className="d-flex align-items-center justify-content-center remove-banner-btn ps-2 text-uppercase "
                                      onClick={() =>
                                        handleRemoveCard(
                                          setFieldValue,
                                          values,
                                          index
                                        )
                                      }
                                    >
                                      {/* <img src={removeIcon} /> */}
                                      <span className="ms-2 mt-2 fs_12 bg_color_E5ECFF px-1 rounded-1">
                                        remove banner
                                      </span>
                                    </button>
                                  ) : (
                                    <div className="mt-4 pt-1"></div>
                                  )}
                                </>
                              </div>

                              <div className="position-relative">
                                <Banner key={index} data={data} index={index} />
                                <div
                                  className="position-absolute rounded-2 w-100"
                                  style={{ bottom: "-1.5rem" }}
                                >
                                  <InputField
                                    containerClass="mt-4 w-100"
                                    label=""
                                    id={"url"}
                                    placeholder={"Enter URL here"}
                                    name={`banners[${index}].url`}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}

                        <div className="col-xl-4 col-sm-6 col-12 mt-xl-0 mt-3">
                          <button
                            type="button"
                            className="w-100 mt-4 pt-3"
                            onClick={() => AddMoreBanner(setFieldValue, values)}
                          >
                            <div
                              style={{
                                height: "12rem",
                                border: "1px solid #E5ECFF",
                                backgroundColor: "#F9F9FC",
                              }}
                              className="rounded-3 position-relative w-100 d-flex justify-content-center align-items-center"
                            >
                              <div
                                className="p-2 rounded-1 bg-white"
                                style={{
                                  boxShadow:
                                    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                }}
                              >
                                <img src={addBanner} />
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </DndProvider>
                    <p className="font_montserrat fs_18 fw_500 color_030304 mt-4">
                      Product List Section
                    </p>
                    <HomeVariant
                      setInitialValues={setInitialValues}
                      setNumVariations={setNumVariations}
                      categoryList={categoryList}
                      tagsList={tagsList}
                      numVariations={numVariations}
                      addVariant={(value = 1) => {
                        setNumVariations((prev) => prev + value);
                        setInitialValues({
                          ...values,
                          variations: [
                            ...values.variations,
                            {
                              product_list_name: "",
                              tags: [],
                              categories: [],
                              banner: "",
                            },
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className="row g-0 justify-content-between align-items-center  p-3">
                    <div
                      className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat mb-0 px-xxl-4 me-2"
                      style={{ width: "fit-Content" }}
                    ></div>
                    <div
                      className="me-xxl-5 pe-xxl-5"
                      style={{ width: "fit-Content" }}
                    >
                      {canWrite("configuration") && (
                        <div className="d-flex gap-3">
                          <BackButton
                            undoFn={() => {
                              setReload(!reload);
                            }}
                            disabled={valuesCompareFn(values)}
                            type="reset"
                            name="UNDO"
                            backClassName="fs_14"
                          />

                          <SaveButton
                            disabled={valuesCompareFn(values)}
                            type="submit"
                            backClassName="fs_14"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
      <FillBannerModal
        show={fillBannerShow}
        fillBannerMsg={fillBannerMsg}
        modalClose={() => setFillBannerShow(false)}
      />
    </div>
  );
};

export default HomeSetting;
