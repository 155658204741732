import React from "react";
import CreateButton from "../buttons/text-button";
import { useNavigate } from "react-router";
import { removeUnderScore } from "../../helper-functions/helper/helper";
import "./dashboard.module.css"
const DashboardTable = ({
  tableTitle1 = "Dashboard-table",
  tableTitle2 = "",
  headerBackground = "#ffffff",
  viewAll = false,
  tableHeading,
  tableBody,
  customStyles = {},
  tableBorder = "1px solid #E5ECFF",
  accept = false,
  viewPath,
  acceptPath,
  buttonPath,
  acceptFn,
  canWrite
}) => {
  console.log(tableBody, "recievedOrdersTableBody");
  const navigate = useNavigate();
  return (
    <div style={{ ...customStyles }}>
      <div className={`d-flex justify-content-between`}>
        <label className={`text-black fs_20 fw_500`}>
          {tableTitle1}
          <span className="color_FF6C1A">{tableTitle2}</span>
        </label>
        {viewAll && (
          <CreateButton
            createFn={() => navigate(buttonPath)}
            icon={false}
            name="View All"
            textClass=""
          />
        )}
      </div>
      <div className="mt-3 rounded-2" style={{ border: tableBorder }}>
        <div
          className={`${tableBody?.length > 0 && `overflow-scroll`} table_main_container`}
          style={{ height: "35.5rem" }}
        >
          <table className="w-100">
            <thead style={{ backgroundColor: headerBackground }}>
              <tr style={{ borderBottom: "1px solid #E5ECFF" }}>
                {tableHeading.map((heading, index) => {
                  return (
                    <React.Fragment key={index}>
                      {heading.value != "actions" && (
                        <th
                          className="fs_14 color_707070 fw_500 ps-3 py-2 text-nowrap"
                          style={{ minWidth: "8rem" }}
                        >
                          {heading.label}
                        </th>
                      )}
                      {heading.value === "actions" && (
                        <th
                          className=" px-2 py-1 fs_14 color_707070 fw_500 position-sticky"
                          style={{
                            right: "0px",
                            width: "100%",
                            backgroundColor: headerBackground,
                          }}
                        >
                          {heading.label}
                        </th>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableBody?.map((tableValues, index) => {
                console.log(tableValues, "recievedOrdersTableBody");
                return (
                  <tr key={index} style={{ borderBottom: "1px solid #E5ECFF" }}>
                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                      <span className={`fs_14 text-black`}>
                        {tableValues.code ?? "--"}
                      </span>
                    </td>
                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                      <span className={`fs_14 text-black`}>
                        {tableValues.no_of_items ?? "--"}
                      </span>
                    </td>
                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                      <span className={`fs_14 text-black`}>
                        {tableValues.order_date ?? "--"}
                      </span>
                    </td>
                    {viewAll && (
                      <td
                        className={`text-black fw_400  ps-3 py-2 text-nowrap`}
                      >
                        <span
                          className={`fs_14 text-capitalize ${tableValues?.payment_status === "paid"
                            ? ` px-2 py-1 rounded-3 color_3AD64C bg_color_34BF441A fw_500`
                            : tableValues?.payment_status === "unpaid"
                              ? ` px-2 py-1 rounded-3 color_F23D3D bg_color_F23D3D1A fw_500`
                              : "text-black"
                            }`}
                        >
                          {tableValues.payment_status}
                        </span>
                      </td>
                    )}

                    <td className={`text-black fw_400  ps-3 py-2 text-nowrap`}>
                      <span className={`fs_14 text-black`}>
                        {tableValues.order_value ?? "--"}
                      </span>
                    </td>

                    {
                      tableValues?.Action &&
                      <td
                        className=" position-sticky bg-white px-2 pt-2 py-1 d-flex gap-2"
                        style={{ right: "0px", width: "100%" }}
                      >
                        <div
                          onClick={() => navigate(`${viewPath}/${tableValues?.id}`)}
                          className="fs_14 fw_500 pe-2 cursor_pointer color_3AD64C "
                          style={{ right: "0px", width: "100%" }}
                        >
                          View
                        </div>
                        {accept && canWrite("orders") && (
                          <div
                            onClick={() => acceptFn(tableValues?.id)}
                            className="fs_14 fw_500 pe-2 cursor_pointer primary_color"
                            style={{ right: "0px", width: "100%" }}
                          >
                            Accept
                          </div>
                        )}
                      </td>
                    }

                  </tr>
                );
              })}
            </tbody>
          </table>
          {tableBody?.length === 0 && (
            <div className="d-flex align-items-center justify-content-center w-100 h-100 fs_20 fw_500">
              -- Table is Empty --
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTable;
