import { api } from "./api";
export const adminUsersTableApi = (params) => {
  return api.get(
    `/core/admin/list?page=${params?.page}&page_size=${params?.size}&search=${params?.search}&${params?.sort != null && `sort_by=${params?.sort?.sort_by}&sort_order=${params?.sort?.sort_order}`}`,
  );
};
export const adminUsersViewApi = (params) => {
  return api.get(`/core/admin/view?admin_id=${params}`);
};
export const adminUsersEditApi = (params) => {
  return api.post(`/core/admin/edit`, JSON.stringify(params));
};
export const adminUsersCreateApi = (params) => {
  return api.post(`/core/admin/create`, JSON.stringify(params));
};
export const adminUsersDeleteApi = (params) => {
  return api.delete(`core/admin/delete?admin_id=${params}`);
};
export const adminUsersMultiDeleteApi = (params) => {
  return api.delete(`/core/admin/bulk-delete`, (params));
};
export const adminUsersImageUploadApi = (params) => {
  return api.post(`/images/upload-admin-user-profile`, params, {
    headers: {
      "Content-Type": false,
    },
  });
};
export const adminUsersResetPasswordApi = (params) => {
  return api.post(`/core/admin/reset-password`, JSON.stringify(params));
};
export const adminUserDetailsApi = () => {
  return api.get(`/core/admin/view-admin`);
};
export const adminUsersSendPasswordApi = (params, password) => {
  return api.post(`/core/admin/send-password-user-mail?admin_id=${params}&password=${password}`);
};
// export const adminUsersGetImageApi = (params) => {
//     return api.get(`/images/get-profiles/${params}`);
// };
export const loggedAdminUserEditApi = (params) => {
  return api.post(`/core/admin/edit-account-settings`, JSON.stringify(params));
}