import { Form, Formik } from 'formik';
import React from 'react'
import InnerLayout from '../../layouts/inner-layout';
import * as Yup from "yup";
import CreateButton, { BackButton, ClearButton, SaveButton } from '../../buttons/text-button';
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import QuillField from '../../forms/quill-field';
import InputField from '../../forms/input-field';
import { useDispatch } from 'react-redux';
import { faqCreateAction } from '../../../../redux/actions/faq-action';
import successToast from '../../../helper-functions/helper/sucess-toast';
import errorToast from '../../../helper-functions/helper/error-toast';


function FaqCreate({ closeSection, tab = { tab }, setTab = { setTab } }) {



    const dispatch = useDispatch()

    const initialValues = {
        question: "",
        answer: "",

    };
    const validationSchema = Yup.object().shape({
        question: Yup.string().trim().required("Qu is required"),
        answer: Yup.string().trim().required("Category Name is required"),

    });
    const onSubmit = (values) => {
        dispatch(
            faqCreateAction(
                values,
                onSuccess,
                onError
            )
        )
    };

    const onSuccess = (data) => {
        successToast(data?.data?.message)
        setTab("table")
    }
    const onError = (data) => {
        errorToast(data?.data?.message)
    }
    return (
        <>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values }) => {
                        console.log(values, "categorecreate");
                        return (
                            <Form>
                                <div
                                    style={{ padding: ".95rem 1rem" }}
                                    className="col-12  d-flex align-items-center justify-content-between px-md-5   border_bottom_1px_e5ecff"
                                >
                                    <>
                                        <div className="d-flex gap-2">
                                            <img
                                                onClick={() => setTab("table")}
                                                src={backIcon}
                                            />
                                            <h1 className="fs_22 fw_600 font_montserrat mb-0 ">{"FAQs"}</h1>
                                        </div>
                                    </>
                                    <div className='d-flex gap-3'>
                                        <SaveButton
                                            type='submit'
                                        />
                                        <BackButton
                                            type='reset'
                                            name='UNDO' />
                                    </div>
                                </div>
                                <div className='pt-4 ps-5'>
                                    <InputField
                                        containerClass='col-5'
                                        id={"question"}
                                        label={"Frequently Asked Question"}
                                        placeholder={"Enter question"}
                                        name={"question"}
                                    />
                                    <QuillField
                                        containerClass='col-7'
                                        name="answer"
                                        label="Answer"
                                        placeholder="Write a content here"
                                        toolbarItems = {
                                            [
                                                [{ align: "" }, { align: "center" }, { align: "right" }],
                                                ["bold", "italic", "underline"], // toggled buttons
                                                [{ list: "ordered" }],
                                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                // ["image"], // custom button values
                                                [
                                                  {
                                                    size: [
                                                      "10px",
                                                      "12px",
                                                      "14px",
                                                      "16px",
                                                      "18px",
                                                      "20px",
                                                      "22px",
                                                      "24px",
                                                      "26px",
                                                    ],
                                                  },
                                                ],
                                              ]
                                        }
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    )
}

export default FaqCreate