import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import ImageUpload from "../../components/ui/forms/image-upload";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../components/ui/forms/input-field";
import { useDispatch } from "react-redux";
import {
  brandsEditAction,
  brandsViewAction,
} from "../../redux/actions/brands-action";
import errorToast from "../../components/helper-functions/helper/error-toast";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import { categoriesImgUploadAction } from "../../redux/actions/categories-action";
import isEqual from "lodash.isequal";
import Compressor from "compressorjs";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import NotificationComponent from "../../components/notificationComponent";

const BrandsEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [tagsEditDetails, setTagsEditDetails] = useState();
  const [imgUrl, setImageUrl] = useState();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [imageSize, setImageSize] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");

  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setImageUrl(tagsEditDetails?.brand_picture);
  }, [tagsEditDetails]);
  console.log(imgUrl, "imgurl");

  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        }
      })
    }
    else {
      setImageSize(true)
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  
  useEffect(() => {
    setLoading(true);
    dispatch(brandsViewAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setTagsEditDetails(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data?.message);
  };

  const initialValues = {
    name: tagsEditDetails?.name ?? "",
    slug: tagsEditDetails?.slug ?? "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Category Name is required"),
    slug: Yup.string().trim().nullable(),
  });
  const onSubmit = (values) => {
    dispatch(
      brandsEditAction(
        {
          brand_id: params.id,
          brand_picture: imgUrl,
          name: values?.name,
          slug: values?.slug,
          is_active: tagsEditDetails?.is_active,
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };
  const onSuccessEdit = (data) => {
    successToast(data.messsage);
    setEdited(!edited);
    if (currentPage) {
      navigate("/brands", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onErrorEdit = (data) => {
    errorToast(data?.message);
  };
  function resetFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setImageUrl(tagsEditDetails?.brand_picture);
    }
  }
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual && tagsEditDetails?.brand_picture === imgUrl) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
    <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              {loading ? <LoadingSpinnerTable /> :
                <>
                  <InnerLayout
                    name={`${tagsEditDetails?.code ?? "Brands Edit"}`}
                    page="edit"
                    edit={true}
                    rolePermission={"brands"}
                    resetFn={resetFn}
                    path={"/brands"}
                    disabled={valuesCompareFn(values)}
                  />
                  <span>
                    <ImageUpload
                      name="Profile Picture"
                      getImgUrl={getImgUrl}
                      resetFn={resetFn}
                      data={imgUrl}
                      imgLoading={imgLoading}
                      setImgLoading={setImgLoading}
                    />
                    {imageSize && <label className="fs_14 ms-5 text-danger mb-3">"Please upload below 10mb"</label>}
                  </span>
                  <div
                    className="ms-md-5 ms-4  pe-4 pe-md-0"
                    style={{ maxWidth: "40rem" }}
                  >
                    <InputField
                      placeholder="Enter your brand name"
                      containerClass="mb-3 position-relative"
                      errMsgContainerClass="ps-2"
                      label="Brand Name"
                      name="name"
                      type="text"
                    />
                    <div>
                      <InputField
                        placeholder="Enter slug name"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Slug"
                        name="slug"
                        type="text"
                      />
                    </div>
                  </div>
                </>
              }
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default BrandsEdit;
