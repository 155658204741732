import {
  CUSTOMER_BLOCK,
  CUSTOMER_CREATE,
  CUSTOMER_DELETE,
  CUSTOMER_EDIT,
  CUSTOMER_MULTI_DELETE,
  CUSTOMER_RESETPSW,
  CUSTOMER_SEND_PASSWORD,
  CUSTOMER_TABLE,
  CUSTOMER_VIEW,
} from "../types";

export const customerTableAction = (data, data1, onSuccess, onError) => {
  return {
    type: CUSTOMER_TABLE,
    data,
    data1,
    onSuccess,
    onError,
  };
};

export const customerCreateAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const customerViewAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const customerEditAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const customerDeleteAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const customerMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const customerBlockAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_BLOCK,
    data,
    onSuccess,
    onError,
  };
};

export const customerResetPswAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_RESETPSW,
    data,
    onSuccess,
    onError,
  };
};
export const customerSendPasswordAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_SEND_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};