import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import usePermissions from "../../helper-functions/hooks/usePermission-checker";
import { SaveButton } from "../buttons/text-button";
import { useDispatch } from "react-redux";
import backIcon from "../../../assets/icons/back-arrow-button.svg";
import LoadingSpinnerTable from "../../helper-functions/helper/loadingSpinner-table";
import uploadIcon from "../../../assets/icons/upload-plugin.svg";
import TableInputFilter from "../tables/table-input-filter";
import pluginIcon from "../../../assets/icons/plugin-icon.png";
import PluginUploadModal from "./plugin-upload-modal";
import {
  pluginInstallAction,
  pluginListAction,
} from "../../../redux/actions/plugin-action";
import errorToast from "../../helper-functions/helper/error-toast";
import InstallLoading from "../../helper-functions/helper/installing-loader";
const NewPlugins = ({ closeSection }) => {
  const dispatch = useDispatch();
  const { canWrite } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [pluginList, setPluginList] = useState();
  const [pluginModalhow, setPluginModalShow] = useState(false);
  const [images, setImages] = useState([]);
  const [reload, setReload] = useState(false);
  const [installationLoading, setInstallationLoading] = useState(false);

  useEffect(() => {
    dispatch(
      pluginListAction({ search: inputValue }, onFetchSuccess, onFetchError)
    );
  }, [inputValue]);

  useEffect(() => {
    if (reload) {
      dispatch(pluginListAction({ search: "" }, onFetchSuccess, onFetchError));
    }
  }, [reload]);

  const onFetchSuccess = (data) => {
    setPluginList(data);
  };
  const onFetchError = () => {};

  const timeOutSet = () => {
    setLoading(false);
    setReload(!reload);
    window.location.reload();
  };

  const pluginInstallFn = (item) => {
    dispatch(
      pluginInstallAction(
        {
          id: item?.id,
          name: item?.name,
          version: item?.version,
          description: item?.description,
          image_url: item?.image_url,
          url: item?.url,
          original_name: item?.original_name,
        },
        onSuccess,
        onError
      ),
      setLoading(true),
      setInstallationLoading(true)
    );
  };

  const onSuccess = (data) => {
    setInstallationLoading(false);
    setReload(!reload);
    setLoading(true);
    setTimeout(timeOutSet, 10000);
    console.log(data);
  };

  const onError = (data) => {
    console.log(data);
    setLoading(false);
    setInstallationLoading(false);
    errorToast(data.data.message);
  };

  const AddressCharacterHider = (str) => {
    return str?.substring(0, 45) + "...";
  };

  return (
    <div>
      {loading ? (
        installationLoading ? (
          <InstallLoading label="Installing" />
        ) : (
          <LoadingSpinnerTable />
        )
      ) : (
        <Formik initialValues={{}} enableReinitialize={true}>
          {({ setFieldValue }) => {
            return (
              <Form className="">
                <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                  <div
                    className="mb-0 px-xxl-4 d-flex align-items-center"
                    style={{ width: "fit-Content" }}
                  >
                    <span>
                      <img
                        className="d-inline-block d-md-none"
                        onClick={closeSection}
                        src={backIcon}
                      />
                    </span>
                    <span className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat ms-2 me-4">
                      New Plugins
                    </span>{" "}
                    {canWrite("configuration") && (
                      <SaveButton
                        onClick={() => setPluginModalShow(true)}
                        type="button"
                        name="UPLOAD"
                        icon={uploadIcon}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="mt-5 ms-xl-5 me-xl-5 ms-4 me-4"
                  style={{ maxWidth: "40rem" }}
                >
                  <TableInputFilter
                    placeholder={"Search by plugin name"}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    canWrite={canWrite}
                  />
                </div>
                <div className="m-3 row g-0 gap-5 mt-5 ms-xl-5 me-xl-5 ms-4 me-4">
                  {pluginList?.map((item, index) => (
                    <div
                      key={index}
                      className="p-3 w-100 rounded-4 d-flex flex-column justify-content-between"
                      style={{
                        maxWidth: "350px",
                        border: "1px solid #E5ECFF",
                        height: "18rem",
                        maxHeight: "12rem",
                      }}
                    >
                      <div>
                        <div className="d-flex align-items-center gap-2">
                          {item?.image_url && (
                            <img
                              className="rounded-2"
                              src={
                                item?.image_url ? item?.image_url : pluginIcon
                              }
                              alt="icon"
                              width={35}
                              height={35}
                            />
                          )}
                          <span className="fs_17 fw_500">{item.name}</span>
                        </div>
                        <div className="my-1 fs_14 mt-2 color_707070">
                          {item.description &&
                            AddressCharacterHider(item.description)}
                        </div>
                        <span className="color_707070 text-nowrap">
                          Version:{" "}
                        </span>
                        {item?.version ? item?.version : "--"}
                      </div>
                      <div>
                        <button
                          onClick={() => pluginInstallFn(item)}
                          type="button"
                          style={{ border: "2px solid #030304" }}
                          className="px-2 py-1 rounded-2 my-2 fw_500 fs_15"
                        >
                          INSTALL
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <PluginUploadModal
                  show={pluginModalhow}
                  modalClose={() => setPluginModalShow(false)}
                  setFieldValue={setFieldValue}
                  images={images}
                  setImages={setImages}
                  setLoading={setLoading}
                  loading={loading}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default NewPlugins;
