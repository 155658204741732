import { all } from "redux-saga/effects";
import {
  watchaddressFetchWorker,
  watchbillingGenerateWorker,
  watchordersAssignWorker,
  watchordersBillingEditWorker,
  watchordersCreateWorker,
  watchordersDeliveryPartnersWorker,
  watchordersEditWorker,
  watchordersPartialRejectWorker,
  watchordersRejectWorker,
  watchordersReturnEditWorker,
  watchordersServiceWorker,
  watchordersTableWorker,
  watchordersViewWorker,
} from "../orders-saga";

export default function* ordersRootSaga() {
  yield all([
    watchordersTableWorker(),
    watchordersCreateWorker(),
    watchbillingGenerateWorker(),
    watchaddressFetchWorker(),
    watchordersViewWorker(),
    watchordersEditWorker(),
    watchordersRejectWorker(),
    watchordersPartialRejectWorker(),
    watchordersBillingEditWorker(),
    watchordersDeliveryPartnersWorker(),
    watchordersReturnEditWorker(),
    watchordersServiceWorker(),
    watchordersAssignWorker()
  ]);
}
