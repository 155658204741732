import { api } from "./api";

export const productEnquiryTableApi = (data, params) => {
  return api.get(
    `core/admin-product-enquiry/list?page=${data?.page}&page_size=${data?.size}`,
    // `/core/admin-order-enquiry/list?search=${data?.search}&page=${data?.page}&page_size=${data?.size}&${data?.sort != null && `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`}`,
    JSON.stringify(params)
  );
};
export const productEnquiryViewApi = (data) => {
  return api.get(`core/admin-product-enquiry/view/?product_enquiry_id=${data}`);
};