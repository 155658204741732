import React from "react";

const LinkedinLogo = ({ width = "20", height = "20", fill = "#2820DA" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78373 4.16742C5.78343 4.84601 5.37173 5.45666 4.74278 5.71142C4.11383 5.96619 3.39323 5.81419 2.92075 5.32711C2.44828 4.84001 2.31829 4.11511 2.59208 3.49421C2.86588 2.87331 3.48878 2.4804 4.16707 2.50075C5.06796 2.5278 5.78414 3.26612 5.78373 4.16742ZM5.83373 7.06742H2.5004V17.5007H5.83373V7.06742ZM11.1004 7.06742H7.78373V17.5007H11.0671V12.0257C11.0671 8.97573 15.0421 8.6924 15.0421 12.0257V17.5007H18.3338V10.8924C18.3338 5.75075 12.4504 5.94242 11.0671 8.4674L11.1004 7.06742Z"
        fill={fill}
      />
    </svg>
  );
};

export default LinkedinLogo;
