import { DISCOUNT_CREATE, DISCOUNT_DELETE, DISCOUNT_EDIT, DISCOUNT_MULTI_DELETE, DISCOUNT_TABLE, DISCOUNT_VIEW } from "../types";

export const discountTableAction = (data, onSuccess, onError) => {
    return {
        type: DISCOUNT_TABLE,
        data,
        onSuccess,
        onError,
    };
};
export const discountDeleteAction = (data, onSuccess, onError) => {
    return {
        type: DISCOUNT_DELETE,
        data,
        onSuccess,
        onError,
    };
};
export const discountMultiDeleteAction = (data, onSuccess, onError) => {
    return {
        type: DISCOUNT_MULTI_DELETE,
        data,
        onSuccess,
        onError,
    };
};
export const discountViewAction = (data, onSuccess, onError) => {
    return {
        type: DISCOUNT_VIEW,
        data,
        onSuccess,
        onError,
    };
};
export const discountEditAction = (data, onSuccess, onError) => {
    return {
        type: DISCOUNT_EDIT,
        data,
        onSuccess,
        onError,
    };
};
export const discountCreateAction = (data, onSuccess, onError) => {
    return {
        type: DISCOUNT_CREATE,
        data,
        onSuccess,
        onError,
    };
};