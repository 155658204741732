import React from "react";

const GuideIcon = ({ fill = "#707070" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 8.5C10.855 8.5 8.5 10.855 8.5 13.75C8.5 16.645 10.855 19 13.75 19C16.645 19 19 16.645 19 13.75C19 10.855 16.645 8.5 13.75 8.5ZM13.75 17.5C11.68 17.5 10 15.82 10 13.75C10 11.68 11.68 10 13.75 10C15.82 10 17.5 11.68 17.5 13.75C17.5 15.82 15.82 17.5 13.75 17.5ZM14.875 11.875C14.875 12.4975 14.3725 13 13.75 13C13.1275 13 12.625 12.4975 12.625 11.875C12.625 11.2525 13.1275 10.75 13.75 10.75C14.3725 10.75 14.875 11.2525 14.875 11.875ZM14.5 14.5V16C14.5 16.4125 14.1625 16.75 13.75 16.75C13.3375 16.75 13 16.4125 13 16V14.5C13 14.0875 13.3375 13.75 13.75 13.75C14.1625 13.75 14.5 14.0875 14.5 14.5ZM7.75 16.75C7.75 17.1625 7.4125 17.5 7 17.5H4.75C2.68 17.5 1 15.82 1 13.75V4.75C1 2.68 2.68 1 4.75 1H9.07C9.8575 1 10.63 1.3225 11.1925 1.8775L13.6225 4.3075C14.125 4.81 14.4325 5.4775 14.4925 6.1825C14.5225 6.595 14.215 6.955 13.8025 6.9925C13.78 6.9925 13.765 6.9925 13.7425 6.9925C13.3525 6.9925 13.03 6.6925 12.9925 6.3025C12.9925 6.28 12.9925 6.265 12.9925 6.2425H10.7575C9.9325 6.2425 9.2575 5.5675 9.2575 4.7425V2.515C9.1975 2.515 9.1375 2.5 9.0775 2.5H4.75C3.5125 2.5 2.5 3.5125 2.5 4.75V13.75C2.5 14.9875 3.5125 16 4.75 16H7C7.4125 16 7.75 16.3375 7.75 16.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default GuideIcon;
