import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { BackButton, SaveButton } from "../buttons/text-button";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { useDispatch } from "react-redux";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";
import ImageUpload from "../forms/image-upload";
import { categoriesImgUploadAction } from "../../../redux/actions/categories-action";
import "./modal.css";
import { tagsCreateAction } from "../../../redux/actions/tags-action";

export default function CreateTagModal({ show, modalClose, onCreateSuccess }) {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [imgUrl, setImageUrl] = useState("");
  const getImgUrl = (img) => {
    dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (data) => {
    setImageUrl(data);
  };
  const onImgError = (data) => {
    errorToast(data.data.message ?? "Failed to upload image");
  };
  function resetFn() {
    setImageUrl("");
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    slug: Yup.string().trim(),
  });
  const onSubmit = (values) => {
    dispatch(
      tagsCreateAction(
        {
          tag_picture: imgUrl,
          name: values.name,
          slug: values.slug,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    onCreateSuccess && onCreateSuccess(data.data);
    setImageUrl("");
    successToast(data.message);
    modalClose();
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  return (
    <Modal
      show={show}
      onHide={modalClose}
      centered
      dialogClassName={`mx-sm-auto tag_modal_container`}
      contentClassName="rounded-3"
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          name: "",
          slug: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <ModalHeader>
            <div className="text-black fw_500 fs_18 d-flex align-items-center gap-2">
              <img
                onClick={modalClose}
                style={{ background: "#F7F7F7", borderRadius: "3px" }}
                src={BackIcon}
                alt="back"
              />
              <span>Create Tag</span>
            </div>
            <div className="d-sm-flex g-0 gap-sm-3 gap-2 text-center">
              <div onClick={resetFn} className="mb-2 mb-sm-0">
                <BackButton name="RESET" type="reset" />
              </div>
              <div className="mt-2 mt-sm-0">
                <SaveButton type="submit" />
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="px-sm-5">
            <div>
              <ImageUpload
                name="tag_picture"
                getImgUrl={getImgUrl}
                resetFn={resetFn}
                data={imgUrl}
                containerClass="mt-0"
                labelClass="fs_14"
                maxWidth="230px"
                height="245px"
                id="tagImage"
                inputName="tag"
              />
              <InputField
                placeholder="Attribute Name Goes Here"
                containerClass=" mt-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Tag Name"
                name="name"
                type="text"
              />
              <InputField
                placeholder="Slug Text Goes Here"
                containerClass="mb-3 position-relative"
                errMsgContainerClass="ps-2"
                label="Slug"
                name="slug"
                type="text"
              />
            </div>
          </ModalBody>
        </Form>
      </Formik>
    </Modal>
  );
}
