import { FAQ_CREATE, FAQ_DELETE,  FAQ_EDIT, FAQ_MULTI_DELETE, FAQ_TABLE, FAQ_VIEW } from "../types";

export const faqTableAction = (data, onSuccess, onError) => {
  return {
    type: FAQ_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const faqViewAction = (id, onSuccess, onError) => {
  return {
    type: FAQ_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const faqEditAction = (data, onSuccess, onError) => {
  return {
    type: FAQ_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const faqDeleteAction = (data, onSuccess, onError) => {
    return {
        type: FAQ_DELETE,
        data,
        onSuccess,
        onError,
    };
};
export const faqMultiDeleteAction = (data, onSuccess, onError) => {
    return {
        type: FAQ_MULTI_DELETE,
        data,
        onSuccess,
        onError,
    };
};

export const faqCreateAction = (data, onSuccess, onError) => {
  return {
    type: FAQ_CREATE,
    data,
    onSuccess,
    onError,
  };
};

