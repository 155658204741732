import { api } from "./api";

export const categoriesTableApi = (data, params) => {
  console.log(data);
  return api.get(
    `/core/categories/list?page=${data?.page}&page_size=${data?.size}&search=${
      data?.search
    }&${
      data?.sort != null &&
      `sort_by=${data?.sort?.sort_by}&sort_order=${data?.sort?.sort_order}`
    }`,
    JSON.stringify(params)
  );
};
export const categoriesDeleteApi = (data, params) => {
  return api.delete(
    `/core/categories/delete?category_id=${data}`,
    JSON.stringify(params)
  );
};
export const categoriesMultiDeleteApi = (params) => {
  return api.delete(`/core/categories/bulk-delete`, params);
};
export const categoriesViewApi = (data) => {
  return api.get(`/core/categories/view?category_id=${data}`);
};

export const categoriesEditApi = (params) => {
  return api.post(`/core/categories/edit`, JSON.stringify(params));
};

export const categoriesCreateApi = (params) => {
  return api.post(`/core/categories/create`, JSON.stringify(params));
};

export const categoriesImgUploadApi = (params) => {
  return api.post(`/images/upload-product-tags-categories-images`, params, {
    headers: {
      "Content-Type": false,
    },
  });
};
export const configurationImgUploadApi = (params) => {
  return api.post(`/images/upload-product-tags-categories-images`, params, {
    headers: {
      "Content-Type": false,
    },
  });
};

export const categoriesDropdownApi = () => {
  return api.get(`/core/categories/list-all-categories`);
};
