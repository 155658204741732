import { call, takeLatest } from "redux-saga/effects";
import { dashBoardDataApi } from "../apis/dashboard-api";
import { DASHBOARD_DATA } from "../types";

export function* dashBoardDataWorker(action) {
  try {
    const res = yield call(dashBoardDataApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchDashBoardDataWorker() {
    yield takeLatest(DASHBOARD_DATA, dashBoardDataWorker);
  }
