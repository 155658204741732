import React, { useEffect, useState } from "react";
import CreateButton, { AddButton, DeleteButton } from "../buttons/text-button";
import TablePagination from "../tables/table-pagination";
import { Link } from "react-router-dom";
import TableInputFilter from "../tables/table-input-filter";
import FrontEndPagination from "../tables/front-end-pagination";
import UplaodImgLogo from "../../../assets/icons/Upload_image_logo.svg";
import ProductsEmptyImage from "../../../assets/images/products-empty.svg";
import DeleteModal from "../modals/delete-modal";
import SelectField from "../forms/select-field";
import Select from "react-select";
export default function DiscountedProductsList({
  productList = [],
  addProductModalShow,
  tableDiscountHeader = [],
  setCurrentPage,
  pagination = {},
  setAddedProductsList,
  setSelectedProductList,
  selectedProductList,
  action = "",
  addedProductsList = [],
  page = 0,
  setInputValue,
  inputValue = "",
  emptyProductsError = false,
  searchTopLabel = "",
  orderCreate = false,
  editProduct = false,
  setFieldValue,
  setProductVariations,
  productVariations,
  pageType,
}) {
  console.log(addedProductsList, "sdfsfs");

  const [removeSelectedItems, setRemoveSelectedItems] = useState([]);
  const [warningModalShow, setWarningModalShow] = useState(false);
  const handleWarningModalClose = () => setWarningModalShow(false);
  const handleWarningModalShow = () => setWarningModalShow(true);
  const [frontCurrentPage, setFrontCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const selectedProductFn = (item, type) => {
    if (page === "modal") {
      if (type === "all") {
        if (item.length > 0 && selectedProductList?.length !== item?.length) {
          setSelectedProductList([...item]);
        } else {
          setSelectedProductList([]);
        }
      } else {
        setSelectedProductList((prevSelectedProductList) => {
          const isSelected = prevSelectedProductList.includes(item);
          // console.log(isSelected);
          if (isSelected) {
            return prevSelectedProductList.filter(
              (selectedItem) => selectedItem !== item
            );
          } else {
            return [...prevSelectedProductList, item];
          }
        });
      }
    } else {
      if (type === "all") {
        if (item.length > 0 && removeSelectedItems?.length !== item?.length) {
          setRemoveSelectedItems([...item]);
        } else {
          setRemoveSelectedItems([]);
        }
      } else {
        setRemoveSelectedItems((prevSelectedProductList) => {
          const isSelected = prevSelectedProductList.includes(item);
          if (isSelected) {
            return prevSelectedProductList.filter(
              (selectedItem) => selectedItem !== item
            );
          } else {
            return [...prevSelectedProductList, item];
          }
        });
      }
    }
  };

  const allData = productList;
  console.log(allData?.length, "tests");
  const filteredData = addedProductsList;
  if (page != "modal") {
    addedProductsList = filteredData.filter((item) =>
      item?.product_name?.toLowerCase()?.includes(inputValue.toLowerCase())
    );
  }

  useEffect(() => {
    if (page != "modal") {
      setFrontCurrentPage(1);
    }
  }, [inputValue]);

  // Calculate the indexes of the items to display on the current page
  const indexOfLastItem = frontCurrentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = addedProductsList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  if (page !== "modal") {
    productList = currentItems;
  }
  const [deletableRowsId, setDeletableRowsId] = useState("");
  const [deletableIndex, setDeletableIndex] = useState("");
  const totalPages = Math.ceil(addedProductsList.length / itemsPerPage);
  // const removeSelectedProducts = () => {
  //   setAddedProductsList(
  //     addedProductsList?.filter((item) => !deletableRowsId.includes(item?.id))
  //   );
  //   setRemoveSelectedItems(
  //     removeSelectedItems?.filter((item) => !deletableRowsId.includes(item?.id))
  //   );
  //   if (currentItems?.length === 1) {
  //     setFrontCurrentPage(frontCurrentPage - 1);
  //   }
  //   handleWarningModalClose();
  // };

  const removeSelectedProducts = () => {
    setAddedProductsList((prevAddedProductsList) => {
      const indexToRemove = prevAddedProductsList.findIndex(
        (item, index) => item.id === deletableRowsId && index === deletableIndex
      );

      if (indexToRemove !== -1) {
        // Remove the specific row with the found index
        return prevAddedProductsList.filter(
          (_, index) => index !== indexToRemove
        );
      }

      return prevAddedProductsList;
    });

    setRemoveSelectedItems((prevRemoveSelectedItems) =>
      prevRemoveSelectedItems.filter(
        (item) => item?.id !== deletableRowsId && item?.index !== deletableIndex
      )
    );

    if (currentItems?.length === 1) {
      setFrontCurrentPage(frontCurrentPage - 1);
    }

    handleWarningModalClose();
  };

  function dectementCOunt(id, indexs) {
    setAddedProductsList((prevProductList) =>
      prevProductList.map((product, index) => {
        if (product.id === id && index === indexs) {
          const qty = product.qty > 1 ? product.qty - 1 : 1;
          let total_price = 0;

          if (product.newVariant) {
            total_price =
              product.newVariant.discounted_price !== null &&
              product.newVariant.discounted_price !== undefined &&
              product.newVariant.discounted_price !== ""
                ? parseFloat(product.newVariant.discounted_price) * qty
                : parseFloat(product.newVariant.price) * qty;
          } else {
            total_price =
              product.discount_price !== null &&
              product.discount_price !== undefined &&
              product.discount_price !== ""
                ? parseFloat(product.discount_price) * qty
                : parseFloat(product.price) * qty;
          }

          return {
            ...product,
            qty: qty,
            total_price: total_price,
          };
        } else {
          return product;
        }
      })
    );
  }

  function incrementCount(id, indexs) {
    setAddedProductsList((prevProductList) =>
      prevProductList.map((product, index) => {
        if (product.id === id && index === indexs) {
          const qty = product.qty ? product.qty + 1 : 2;
          let total_price = 0;

          if (product.newVariant) {
            total_price =
              product.newVariant.discounted_price !== null &&
              product.newVariant.discounted_price !== undefined &&
              product.newVariant.discounted_price !== ""
                ? parseFloat(product.newVariant.discounted_price) * qty
                : parseFloat(product.newVariant.price) * qty;
          } else {
            total_price =
              product.discount_price !== null &&
              product.discount_price !== undefined &&
              product.discount_price !== ""
                ? parseFloat(product.discount_price) * qty
                : parseFloat(product.price) * qty;
          }

          return {
            ...product,
            qty: qty,
            total_price: total_price,
          };
        } else {
          return product;
        }
      })
    );
  }

  console.log(productList, "akjdadadad");

  const handleVariationClick = (
    value,
    variant,
    id,
    indexs,
    variation_products,
    allVariant
  ) => {
    const productVariationKey = `${id}-${variant.id}-${indexs}`;
    const currentProductVariation = productVariations[productVariationKey] || {
      value: "",
      variation_name: "",
      error: "",
    };
    let error = "";

    // Add your validation logic here and set the error message if needed
    if (value === "") {
      error = "Please fill in the value.";
    }

    setProductVariations((prevProductVariations) => ({
      ...prevProductVariations,
      [productVariationKey]: {
        ...currentProductVariation,
        value: value,
        variation_name: variant.variation_name,
        error: error,
      },
    }));

    setAddedProductsList((prevProductList) =>
      prevProductList.map((item, index) => {
        if (item.id === id && index === indexs) {
          const newVariant = {
            id: variant.id,
            variation_name: variant.variation_name,
            value: value,
          };
          const selectedVariants = [
            ...(item.addVariant || []).filter(
              (selectedVariant) => selectedVariant.id !== variant.id
            ),
            newVariant,
          ];

          const matchedProduct = item.variation_products?.find(
            (product) =>
              JSON.stringify(product.variants) ===
                JSON.stringify(selectedVariants.map((v) => v.value)) ||
              JSON.stringify(product.variants?.sort()) ===
                JSON.stringify(selectedVariants.map((v) => v.value).sort())
          );

          const qty = matchedProduct ? 1 : item.qty; // Set qty to 1 if newVariant ID is changed

          let total_price = 0;

          if (matchedProduct) {
            total_price =
              matchedProduct.discounted_price !== null &&
              matchedProduct.discounted_price !== undefined &&
              matchedProduct.discounted_price !== ""
                ? parseFloat(matchedProduct.discounted_price)
                : parseFloat(matchedProduct.price);
          }
          console.log(total_price, "adhadsada");
          console.log(qty, "adhadsada");
          // Multiply total_price by qty to get the correct value
          total_price *= qty;

          return {
            ...item,
            qty: qty,
            total_price: total_price,
            addVariant: selectedVariants,
            newVariant: matchedProduct,
            newError: false,
          };
        } else {
          return item;
        }
      })
    );
  };

  const qty = addedProductsList?.map((item) => item?.qty);

  function createArrayOfObjectsFromQty(qtyArray) {
    return qtyArray?.map((qty) =>
      Array.from({ length: qty }, (_, index) => ({
        label: index + 1,
        value: index + 1,
      }))
    );
  }
  const qtyArray = createArrayOfObjectsFromQty(qty);
  console.log(qtyArray, "qtyArray");

  const [selectedOptions, setSelectedOptions] = useState(
    productList?.map((val) => val?.qty)
  );
  const handleSelected = (selectedOption, index) => {
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      newSelectedOptions[index] = selectedOption?.value;
      return newSelectedOptions;
    });
  };
  console.log(qtyArray, "qtyArray");

  const array = [
    {
      variation: [
        {
          id: "417fa615-c85c-4e8e-906c-8713e5dae5a0",
          values: [("1kg", "2kg")],
          variation_name: "Weight",
        },
        {
          id: "7da631f2-4df4-4719-8f86-6b743c743ce6",
          values: [("Normal", "Special")],
          variation_name: "Type",
        },
      ],
    },
  ];

  // const getVariantValueClassName = (value, variant, item) => {
  //   if (!item.newVariant) {
  //     return "color_F23D3D border_1px_F24444 mx-2 rounded-1";
  //   }

  //   const selectedValues =
  //     item.addVariant?.map((addVariant) => addVariant.value) || [];
  //   const isSelected =
  //     selectedValues.includes(value) || item.newVariant.value === value;

  //   return isSelected
  //     ? "color_FFFFFF bg_color_2820DA mx-2 rounded-1"
  //     : "color_030304 bg_color_E5ECFFB2 mx-2 rounded-1";
  // };

  return (
    <>
      <DeleteModal
        text={`Are you sure, you want to remove this products ?`}
        show={warningModalShow}
        modalClose={handleWarningModalClose}
        deleteFn={() => {
          removeSelectedProducts();
        }}
        type="remove"
      />
      {page != "modal" && !orderCreate && (
        <span className={`font_montserrat fs_18 fw_600`}>
          {allData?.length > 0 ? searchTopLabel : `Select Products`}
        </span>
      )}
      {orderCreate && (
        <span className="fs_16 color_030304 fw_500">Product List</span>
      )}
      <div
        className={`${
          action && `d-md-flex justify-content-between gap-3`
        } mb-2`}
      >
        <div
          style={{ maxWidth: page !== "modal" && "35rem" }}
          className={`w-100 mt-1`}
        >
          {(page === "modal" || allData?.length > 0) &&
            !editProduct &&
            !orderCreate && (
              <div className="w-100 mb-2">
                <TableInputFilter
                  placeholder={"Search product here"}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                />
              </div>
            )}
        </div>
        <div className="d-flex gap-2">
          {removeSelectedItems?.length > 0 && action && (
            <DeleteButton
              deleteClassName={"px-lg-3 px-3 fs_15 fs_md_12 fw_400 text-nowrap"}
              deleteFn={() => {
                setDeletableRowsId(
                  removeSelectedItems?.map((item) => item?.id)
                );
                handleWarningModalShow();
              }}
            />
          )}
          {allData?.length > 0 && action && !editProduct && (
            <AddButton
              AddFn={() => addProductModalShow()}
              addClassName="mb-1"
            />
          )}
        </div>
      </div>
      <div
        className={`${
          !emptyProductsError
            ? "table_main_container"
            : "table_main_container_error"
        } rounded-3`}
      >
        <table className={"table_container h-100"}>
          <thead className={`table_heading_container table_heading_border`}>
            <tr className="table_heading_row">
              {page !== "view" && !editProduct && pageType != "order" && (
                <th
                  className={` position-sticky end-0 text-start  
                  text-nowrap table_heading_data`}
                >
                  <input
                    e={{ transform: "scale(1.1)" }}
                    type="checkbox"
                    id="myCheckbox"
                    className="custom-checkbox form-check-input"
                    onChange={() => {
                      selectedProductFn(
                        productList?.map((item) => item),
                        "all"
                      );
                    }}
                    checked={
                      page === "modal"
                        ? selectedProductList?.length === productList.length &&
                          selectedProductList?.length > 0
                        : removeSelectedItems?.length === productList.length &&
                          removeSelectedItems?.length > 0
                    }
                  />
                </th>
              )}
              {tableDiscountHeader?.map((head) => {
                return (
                  <th
                    key={head?.label}
                    className={` ${
                      head?.label === "Actions" && "position-sticky end-0"
                    } text-start 
                  text-nowrap products_table_heading_data color_707070 font_poppins`}
                  >
                    <div
                      className={`${
                        page !== "view"
                          ? "discounts_table_Action_heading"
                          : "dis_table_heading_without_edit_delete"
                      }`}
                    >
                      {head?.label}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="table_body_container h-100">
            {productList?.length !== 0 ? (
              <>
                {productList?.map((item, index) => (
                  <tr
                    key={index}
                    className={`table_body_row fs_13 bg_color_ffffff   ${
                      (page !== "view" &&
                        selectedProductList
                          ?.map((checkedItem) => {
                            return checkedItem?.id;
                          })
                          .includes(item?.id)) ||
                      removeSelectedItems
                        ?.map((checkedItem) => {
                          return checkedItem?.id;
                        })
                        .includes(item?.id)
                        ? "bg_table"
                        : "bg_color_ffffff"
                    }`}
                  >
                    {page !== "view" && !editProduct && pageType != "order" && (
                      <td className="text-nowrap dis_table_body_data">
                        <input
                          onChange={(e) => {
                            selectedProductFn(item);
                          }}
                          checked={
                            page === "modal"
                              ? selectedProductList?.includes(item)
                              : removeSelectedItems?.includes(item)
                          }
                          id={item?.id}
                          style={{ transform: "scale(1.2)" }}
                          type="checkbox"
                          className="custom-checkbox form-check-input"
                        />
                      </td>
                    )}
                    <td
                      className={`text-nowrap color_030304 dis_table_body_data fw_500 `}
                    >
                      <img
                        src={
                          /^https?:\/\//i.test(item?.thumbnail_image)
                            ? item?.thumbnail_image ?? item?.product_picture
                            : UplaodImgLogo
                        }
                        width={"55px"}
                        height={"55px"}
                        className="rounded-1"
                      />
                    </td>
                    {!editProduct && (
                      <>
                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                          <span className="color_030304 p-1 pe-3">
                            {item?.id}
                          </span>
                        </td>
                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                          <span className="color_030304 p-1 pe-3">
                            {item?.product_name}
                          </span>
                        </td>
                      </>
                    )}

                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        {item?.variant?.map((val) => {
                          return <span className="ms-2">{val} |</span>;
                        })}
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <div style={{ width: "4rem" }}>
                          <Select
                            onChange={(selectedOption) =>
                              handleSelected(selectedOption, index)
                            }
                            options={qtyArray[index]}
                            styles={customStyles}
                            defaultValue={qtyArray[index]?.find(
                              (opt) => opt.label === productList[index]?.qty
                            )}
                          />
                        </div>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.sku ? item?.sku : "NO"}</span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.barcode ? item?.barcode : "No"}</span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.price ? item?.price : "No"}</span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>
                          {item?.discount_price ? item?.discount_price : "No"}
                        </span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.price ? item?.price : "--"}</span>
                      </td>
                    )}

                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500 cursor_pointer">
                        <span className="d-flex gap-4">
                          {item?.variation?.map((variant) => {
                            const isSelectedVariant =
                              item?.addVariant?.some(
                                (addVariant) => addVariant.id === variant.id
                              ) ||
                              (item?.newVariant &&
                                item?.newVariant.id === variant.id);

                            const selectedVaraints = item?.addVariant?.map(
                              (items) => items.value
                            );

                            const filterSelectedVaraints =
                              item?.variation_products?.filter((item) =>
                                selectedVaraints?.every(
                                  (value) =>
                                    item.variants?.includes(value) ||
                                    !selectedVaraints?.includes(value)
                                )
                              );

                            const newArray = [
                              ...new Set([
                                ...filterSelectedVaraints.flatMap(
                                  (item) => item.variants
                                ),
                                ...item?.variation
                                  ?.filter((item) =>
                                    selectedVaraints?.some((value) =>
                                      item.values.includes(value)
                                    )
                                  )
                                  ?.flatMap((item) => item.values),
                              ]),
                            ];

                            const filteredArray =
                              newArray?.length >= 1
                                ? variant?.values?.filter((subArray) => {
                                    return newArray?.includes(subArray);
                                  })
                                : variant?.values;

                            return (
                              <span key={variant.id}>
                                {filteredArray?.map((value, variantIndex) => {
                                  console.log(value, "kldalsdad");
                                  const isSelected =
                                    item?.addVariant?.some(
                                      (addVariant) =>
                                        addVariant.value === value &&
                                        addVariant.id === variant.id
                                    ) ||
                                    (item?.newVariant &&
                                      item?.newVariant.value === value &&
                                      item?.newVariant.id === variant.id);

                                  return (
                                    <span
                                      key={variantIndex}
                                      className={
                                        isSelected
                                          ? "color_FFFFFF bg_color_2820DA mx-1 rounded-1"
                                          : isSelectedVariant
                                          ? "color_030304 bg_color_E5ECFFB2 mx-1 rounded-1"
                                          : "color_F23D3D border_1px_F24444 mx-1 rounded-1"
                                      }
                                      onClick={() => {
                                        handleVariationClick(
                                          value,
                                          variant,
                                          item?.id,
                                          index,
                                          item?.variation_products,
                                          item?.variation
                                        );
                                      }}
                                      style={{
                                        padding: "5px",
                                      }}
                                    >
                                      {value}
                                    </span>
                                  );
                                })}
                              </span>
                            );
                          })}
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500 cursor_pointer">
                        <span className="color_030304">
                          <span
                            className="color_030304 fw_500 fs_20 me-2 px-2 border_radius_5px"
                            style={{ backgroundColor: "#E5ECFF" }}
                            onClick={() =>
                              item?.qty != 1 && dectementCOunt(item?.id, index)
                            }
                          >
                            -
                          </span>
                          <span className="fs_16">
                            {item.qty ? item.qty : 1}
                          </span>
                          <span
                            className="ms-2 color_030304 fw_500 fs_20 px-2 border_radius_5px"
                            style={{ backgroundColor: "#E5ECFF" }}
                            onClick={() => incrementCount(item?.id, index)}
                          >
                            +
                          </span>
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span className="color_030304 p-1 pe-3">
                          {item?.newVariant?.price
                            ? item?.newVariant?.price
                            : item?.price
                            ? item?.price
                            : "--"}
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span className="color_030304 p-1 pe-3">
                          {item?.newVariant?.discounted_price
                            ? item?.newVariant?.discounted_price
                            : item?.discount_price
                            ? item?.discount_price
                            : "--"}
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span className="color_030304 p-1 pe-3">
                          {item?.total_price
                            ? item?.total_price
                            : item?.newVariant?.discount_price
                            ? item?.newVariant?.discount_price
                            : item?.newVariant?.price
                            ? item?.newVariant?.price
                            : item?.discount_price
                            ? item?.discount_price
                            : item?.price}
                        </span>
                      </td>
                    )}
                    {page !== "modal" && (
                      <td
                        className={`position-sticky end-0 px-3 text-nowrap dis_table_body_data ${
                          (page !== "view" &&
                            selectedProductList
                              ?.map((checkedItem) => {
                                return checkedItem?.id;
                              })
                              .includes(item?.id)) ||
                          removeSelectedItems
                            ?.map((checkedItem) => {
                              return checkedItem?.id;
                            })
                            .includes(item?.id)
                            ? "bg_table"
                            : "bg_color_ffffff"
                        }`}
                      >
                        <div className="position-sticky end-0">
                          {!editProduct && !orderCreate && (
                            <Link
                              to={{
                                pathname: `/products/view/${item.id}`,
                              }}
                              className={`color_3AD64C border-0 p-0 pe-md-4 pe-3 fs_15 fw_500 font_poppins bg_color_ffffff
                      `}
                            >
                              View
                            </Link>
                          )}

                          {page !== "view" && (
                            <button
                              className={`color_F24444 border-0 p-0 pe-md-4 pe-0 fs_15 fw_500 font_poppins  bg_color_ffffff
                              `}
                              onClick={() => {
                                setDeletableRowsId(item?.id);
                                setDeletableIndex(index);
                                handleWarningModalShow();
                              }}
                              type="button"
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </>
            ) : addedProductsList?.length !== 0 ? (
              <>
                {addedProductsList?.map((item, index) => (
                  <tr
                    key={index}
                    className={`table_body_row fs_13 bg_color_ffffff   ${
                      (page !== "view" &&
                        selectedProductList
                          ?.map((checkedItem) => {
                            return checkedItem?.id;
                          })
                          .includes(item?.id)) ||
                      removeSelectedItems
                        ?.map((checkedItem) => {
                          return checkedItem?.id;
                        })
                        .includes(item?.id)
                        ? "bg_table"
                        : "bg_color_ffffff"
                    }`}
                  >
                    {page !== "view" && !editProduct && (
                      <td className="text-nowrap dis_table_body_data">
                        <input
                          onChange={(e) => {
                            selectedProductFn(item);
                          }}
                          checked={
                            page === "modal"
                              ? selectedProductList?.includes(item)
                              : removeSelectedItems?.includes(item)
                          }
                          id={item?.id}
                          style={{ transform: "scale(1.2)" }}
                          type="checkbox"
                          className="custom-checkbox form-check-input"
                        />
                      </td>
                    )}
                    <td
                      className={`text-nowrap color_030304 dis_table_body_data fw_500 `}
                    >
                      <img
                        src={
                          /^https?:\/\//i.test(item?.thumbnail_image)
                            ? item?.thumbnail_image ?? item?.product_picture
                            : UplaodImgLogo
                        }
                        width={"55px"}
                        height={"55px"}
                        className="rounded-1"
                      />
                    </td>
                    {!editProduct && (
                      <>
                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                          <span className="color_030304 p-1 pe-3">
                            {item?.id}
                          </span>
                        </td>
                        <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                          <span className="color_030304 p-1 pe-3">
                            {item?.product_name}
                          </span>
                        </td>
                      </>
                    )}

                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        {item?.variant?.map((val) => {
                          return <span className="ms-2">{val} |</span>;
                        })}
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <div style={{ width: "4rem" }}>
                          <Select
                            onChange={(selectedOption) =>
                              handleSelected(selectedOption, index)
                            }
                            options={qtyArray[index]}
                            styles={customStyles}
                            defaultValue={qtyArray[index]?.find(
                              (opt) => opt.label === productList[index]?.qty
                            )}
                          />
                        </div>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.sku ? item?.sku : "NO"}</span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.barcode ? item?.barcode : "No"}</span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.price ? item?.price : "No"}</span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>
                          {item?.discount_price ? item?.discount_price : "No"}
                        </span>
                      </td>
                    )}
                    {editProduct && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span>{item?.price ? item?.price : "--"}</span>
                      </td>
                    )}

                    {orderCreate && (
                      // <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                      //   <span className="d-flex gap-3">
                      //     {item?.variation?.map((variant) => (
                      //       <span key={variant.id}>
                      //         {variant?.values?.map((value, variantIndex) => {

                      //           const isSelected =
                      //             item?.addVariant?.some(
                      //               (addVariant) => addVariant.value === value
                      //             ) ||
                      //             (item?.newVariant &&
                      //               item?.newVariant.value === value);

                      //           return (
                      //             <span
                      //               key={variantIndex}
                      //               className={
                      //                 isSelected
                      //                   ? "color_FFFFFF bg_color_2820DA mx-2 rounded-1"
                      //                   : item?.newError === null ||
                      //                     item?.newError === true
                      //                   ? "color_F23D3D border_1px_F24444 mx-2 rounded-1"
                      //                   : isSelected
                      //                   ? "color_FFFFFF bg_color_2820DA mx-2 rounded-1"
                      //                   : "color_030304 bg_color_E5ECFFB2 mx-2 rounded-1"
                      //               }
                      //               onClick={() =>
                      //                 handleVariationClick(
                      //                   value,
                      //                   variant,
                      //                   item?.id,
                      //                   index
                      //                 )
                      //               }
                      //               style={{
                      //                 padding: "5px",
                      //               }}
                      //             >
                      //               {value}
                      //             </span>
                      //           );
                      //         })}
                      //       </span>
                      //     ))}
                      //   </span>
                      // </td>
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500 cursor_pointer">
                        <span className="d-flex gap-4">
                          {item?.variation?.map((variant) => {
                            const isSelectedVariant =
                              item?.addVariant?.some(
                                (addVariant) => addVariant.id === variant.id
                              ) ||
                              (item?.newVariant &&
                                item?.newVariant.id === variant.id);

                            const selectedVaraints = item?.addVariant?.map(
                              (items) => items.value
                            );

                            const filterSelectedVaraints =
                              item?.variation_products?.filter((item) =>
                                selectedVaraints?.every(
                                  (value) =>
                                    item.variants?.includes(value) ||
                                    !selectedVaraints?.includes(value)
                                )
                              );

                            const newArray = [
                              ...new Set([
                                ...filterSelectedVaraints.flatMap(
                                  (item) => item.variants
                                ),
                                ...item?.variation
                                  ?.filter((item) =>
                                    selectedVaraints?.some((value) =>
                                      item.values.includes(value)
                                    )
                                  )
                                  ?.flatMap((item) => item.values),
                              ]),
                            ];

                            const filteredArray =
                              newArray?.length >= 1
                                ? variant?.values?.filter((subArray) => {
                                    return newArray?.includes(subArray);
                                  })
                                : variant?.values;

                            return (
                              <span key={variant.id}>
                                {filteredArray?.map((value, variantIndex) => {
                                  const isSelected =
                                    item?.addVariant?.some(
                                      (addVariant) =>
                                        addVariant.value === value &&
                                        addVariant.id === variant.id
                                    ) ||
                                    (item?.newVariant &&
                                      item?.newVariant.value === value &&
                                      item?.newVariant.id === variant.id);

                                  return (
                                    <span
                                      key={variantIndex}
                                      className={
                                        isSelected
                                          ? "color_FFFFFF bg_color_2820DA mx-1 rounded-1"
                                          : isSelectedVariant
                                          ? "color_030304 bg_color_E5ECFFB2 mx-1 rounded-1"
                                          : "color_F23D3D border_1px_F24444 mx-1 rounded-1"
                                      }
                                      onClick={() =>
                                        handleVariationClick(
                                          value,
                                          variant,
                                          item?.id,
                                          index,
                                          item?.variation_products,
                                          item?.variation
                                        )
                                      }
                                      style={{
                                        padding: "5px",
                                      }}
                                    >
                                      {value}
                                    </span>
                                  );
                                })}
                              </span>
                            );
                          })}
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500 cursor_pointer">
                        <span className="color_030304">
                          <span
                            className="color_030304 fw_500 fs_20 me-2 px-2 border_radius_5px"
                            style={{ backgroundColor: "#E5ECFF" }}
                            onClick={() =>
                              item?.qty != 1 && dectementCOunt(item?.id, index)
                            }
                          >
                            {/* <img src={MinusIcon} width={25} /> */}-
                          </span>
                          <span className="fs_16">
                            {item.qty ? item.qty : 1}
                          </span>
                          <span
                            className="ms-2 color_030304 fw_500 fs_20 px-2 border_radius_5px"
                            style={{ backgroundColor: "#E5ECFF" }}
                            onClick={() => incrementCount(item?.id, index)}
                          >
                            {/* <img src={PlusIcon} width={25}/> */}+
                          </span>
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span className="color_030304 p-1 pe-3">
                          {/* {item?.price ? item?.price : "--"} */}
                          {item?.newVariant?.price
                            ? item?.newVariant?.price
                            : item?.price
                            ? item?.price
                            : "--"}
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span className="color_030304 p-1 pe-3">
                          {item?.newVariant?.discounted_price
                            ? item?.newVariant?.discounted_price
                            : item?.discount_price
                            ? item?.discount_price
                            : "--"}
                        </span>
                      </td>
                    )}
                    {orderCreate && (
                      <td className="text-nowrap color_030304 dis_table_body_data fw_500">
                        <span className="color_030304 p-1 pe-3">
                          {item?.total_price
                            ? item?.total_price
                            : item?.newVariant?.discount_price
                            ? item?.newVariant?.discount_price
                            : item?.newVariant?.price
                            ? item?.newVariant?.price
                            : item?.discount_price
                            ? item?.discount_price
                            : item?.price}
                        </span>
                      </td>
                    )}
                    {page !== "modal" && (
                      <td
                        className={`position-sticky end-0 px-3 text-nowrap dis_table_body_data ${
                          (page !== "view" &&
                            selectedProductList
                              ?.map((checkedItem) => {
                                return checkedItem?.id;
                              })
                              .includes(item?.id)) ||
                          removeSelectedItems
                            ?.map((checkedItem) => {
                              return checkedItem?.id;
                            })
                            .includes(item?.id)
                            ? "bg_table"
                            : "bg_color_ffffff"
                        }`}
                      >
                        <div className="position-sticky end-0">
                          {!editProduct && !orderCreate && (
                            <Link
                              to={{
                                pathname: `/products/view/${item.id}`,
                              }}
                              className={`color_3AD64C border-0 p-0 pe-md-4 pe-3 fs_15 fw_500 font_poppins bg_color_ffffff
                      `}
                            >
                              View
                            </Link>
                          )}

                          {page !== "view" && (
                            <button
                              className={`color_F24444 border-0 p-0 pe-md-4 pe-0 fs_15 fw_500 font_poppins  bg_color_ffffff
                              `}
                              onClick={() => {
                                setDeletableRowsId(item?.id);
                                setDeletableIndex(index);
                                handleWarningModalShow();
                              }}
                              type="button"
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </>
            ) : (
              <>
                {allData?.length === 0 ||
                (productList?.length === 0 &&
                  page !== "modal" &&
                  page !== "view") ? (
                  <tr className="table_body_row vh65">
                    <td
                      onClick={() => addProductModalShow()}
                      className="position-absolute top-50 start-50 translate-middle text-center"
                    >
                      <img src={ProductsEmptyImage} width={100} height={100} />
                      <div
                        className={`${
                          !emptyProductsError ? "color_9E9E9E" : "color_F24444"
                        }  fw_400 mt-2 mb-4`}
                      >
                        Please select products to apply this discount
                      </div>
                      {!editProduct && (
                        <CreateButton
                          name={"ADD PRODUCTS"}
                          createClassName={"fw_500"}
                        />
                      )}
                    </td>
                  </tr>
                ) : (
                  <tr className="table_body_row vh65">
                    <td className="position-absolute top-50 start-50 translate-middle">
                      Table is empty
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <TablePagination
          setCurrentPage={setCurrentPage}
          pagination={pagination}
          setCheckedId={setSelectedProductList}
        />
        {page !== "modal" && (
          <FrontEndPagination
            list={productList}
            pagination={frontCurrentPage}
            setCurrentPage={setFrontCurrentPage}
            totalPages={totalPages}
            setRemoveSelectedItems={setRemoveSelectedItems}
          />
        )}
      </div>
    </>
  );
}

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#E5ECFFB2",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins,sans-serif",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#2820DA",
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    padding: "0px",
  }),
};
