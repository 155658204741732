import React, { useEffect, useState } from "react";
import styles from "../configuration/configuration.module.css";
import ConfigPages from "../../components/ui/configurations/pages/config-pages";
import useScreenSize from "../../components/helper-functions/hooks/useScreenSize";
import arrowLeftIcon from "../../assets/icons/arrow-right-icon.svg";
import { useNavigate } from "react-router";
import InstalledPlugins from "../../components/ui/plugins/installed-plugins";
import NewPlugins from "../../components/ui/plugins/new-plugins";
import NotificationComponent from "../../components/notificationComponent";
export default function Plugin() {
  const { width } = useScreenSize();
  const [activeConfig, setActiveConfig] = useState(
    localStorage.getItem("plugin_section_status")
  );
  const configNav = width > 768 ? true : !activeConfig;
  const configContent = width > 768 ? true : activeConfig;
  function handleCloseSection() {
    setActiveConfig(null);
    localStorage.setItem("plugin_section_status", "");
  }

  function renderConfig(state) {
    switch (state) {
      case "Installed Plugins":
        return <InstalledPlugins closeSection={handleCloseSection} />;
      case "New Plugins":
        return (
          <NewPlugins name={activeConfig} closeSection={handleCloseSection} />
        );

      default:
        return (
          <ConfigPages name={activeConfig} closeSection={handleCloseSection} />
        );
    }
  }
  useEffect(() => {
    if (width < 720) {
      !activeConfig && setActiveConfig(null);
    } else {
      const configSection = localStorage.getItem("plugin_section_status");
      setActiveConfig(configSection ? configSection : "New Plugins");
    }
  }, [width]);

  const navigate = useNavigate();
  return (
    <>
      <NotificationComponent />
      <div className="mx-3 me-sm-0  ms-sm-4 mt-5 mt-lg-4  col-sm-11 pt-4 mb-4">
        <div className="d-flex align-items-center gap-2">
          <span className="font_montserrat color_030304 fw_500 fs_22">
            Plugins
          </span>
        </div>
        <div
          className={`rounded ${styles.config_content__container} border_1px_e5ecff d-flex flex-wrap mt-4`}
        >
          {configNav && (
            <div className={`${styles.config_nav__container} p-4`}>
              <p className="color_444444 fw_500 fs_14 mt-2 d-none d-md-block">
                Plugins
              </p>
              <ul
                style={{ top: "5rem" }}
                className="d-flex flex-column gap-3 gap-md-2 ps-0 position-sticky"
              >
                {configList.map((item) => {
                  return (
                    <li
                      key={item}
                      onClick={() => {
                        setActiveConfig(item);
                        localStorage.setItem("plugin_section_status", item);
                      }}
                      className={`cursor_pointer list-unstyled d-flex d-md-block justify-content-between  px-3 py-3 py-md-1  border_radius_5px ${
                        styles.config_list__item
                      }
                  ${
                    activeConfig === item
                      ? "bg_color_E5ECFFB2 color_030304 fw_500"
                      : "color_707070"
                  }`}
                    >
                      {item}
                      <img
                        className="d-inline-block d-md-none"
                        src={arrowLeftIcon}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {configContent && (
            <div className={`${styles.config_section__container}`}>
              {renderConfig(activeConfig)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const configList = ["Installed Plugins", "New Plugins"];
