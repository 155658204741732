import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../components/ui/tables/table";
import { useDispatch } from "react-redux";
import {
  attributesDeleteAction,
  attributesMultiDeleteAction,
  attributesTableAction,
} from "../../redux/actions/attributes-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import { useLocation } from "react-router";
import { ordersTableAction } from "../../redux/actions/orders-action";
import { formatDateWithDay } from "../../components/helper-functions/helper/date";
import OrdersTableFilter from "../../components/ui/tables/table-filter/orders-filter";
import { socket } from "../../redux/config";
import successToastOrder from "../../components/helper-functions/helper/sucess-toast-order";

const OrdersTable = () => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState();
  const [reload, setReload] = useState(false);
  const location = useLocation();
  const [filterShow, setFilterShow] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [filterSubmission, setFilterSubmission] = useState("submit");

  const noticationSound =
    "https://ff-ecommerce-development.s3.ap-south-1.amazonaws.com/harp-motif2-36586.mp3";

  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }

  useEffect(() => {
    socket.on("new_order_placed", (data) => {
      console.log(data, "new_order_placed");
      if (window.location.pathname == "/orders") {
        successToastOrder(`New Order Placed - ${data}`);
        playAudio();
        setLoading(true);
        dispatch(
          ordersTableAction(
            {
              page: currentPage,
              page_size: 10,
              search: inputValue,
              sort_by: sortConfig?.sort_by,
              sort_order: sortConfig?.sort_order,
              filtration: {
                status: filterValues?.orders_Status,
                payment_status: filterValues?.payment_status,
                user_id: filterValues?.user_id,
                min_order_value: parseInt(filterValues?.min_order_value),
                max_order_value: parseInt(filterValues?.max_order_value),
                from_date: filterValues?.from_date,
                to_date: filterValues?.to_date,
              },
            },
            onSuccess,
            onError
          )
        );
      }
    });
    return () => {
      socket.off("new_order_placed");
      console.log("new_order_placed", "disconnected");
    };
  }, []);

  const [filterValues, setFilterValues] = useState({
    orders_Status: "",
    payment_status: "",
    user_id: "",
    min_order_value: null,
    max_order_value: null,
    from_date: "",
    to_date: "",
  });
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      ordersTableAction(
        {
          page: currentPage,
          page_size: 10,
          search: inputValue,
          sort_by: sortConfig?.sort_by,
          sort_order: sortConfig?.sort_order,
          filtration: {
            status: filterValues?.orders_Status,
            payment_status: filterValues?.payment_status,
            user_id: filterValues?.user_id,
            min_order_value: parseInt(filterValues?.min_order_value),
            max_order_value: parseInt(filterValues?.max_order_value),
            from_date: filterValues?.from_date,
            to_date: filterValues?.to_date,
          },
        },
        onSuccess,
        onError
      )
    );
  }, [
    currentPage,
    reload,
    inputValue,
    sortConfig,
    filterValues,
    isOrderPlaced,
  ]);

  const listFnc = () => {
    dispatch(
      ordersTableAction(
        {
          page: currentPage,
          page_size: 10,
          search: inputValue,
          sort_by: sortConfig?.sort_by,
          sort_order: sortConfig?.sort_order,
          filtration: {
            status: filterValues?.orders_Status,
            payment_status: filterValues?.payment_status,
            user_id: filterValues?.user_id,
            min_order_value: parseInt(filterValues?.min_order_value),
            max_order_value: parseInt(filterValues?.max_order_value),
            from_date: filterValues?.from_date,
            to_date: filterValues?.to_date,
          },
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    setOrders(data?.data?.orders);
    setPagination(data?.data?.pagination);
    setLoading(false);
    setError(false);
    filterSubmission === "submit" && setFilterShow(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };
  const handleFilterValues = (values, type) => {
    setFilterSubmission(type);
    setFilterValues(values);
  };
  const tableData = orders?.map((item) => ({
    id: item?.id,
    code: item?.code,
    no_of_items: item?.getItems ?? 0,
    order_date: formatDateWithDay(item?.createdAt),
    orders_status: Array.isArray(item?.orders_status)
      ? item?.orders_status
      : [item?.orders_status],
    payment_status: [
      item?.payment_admin_status === "paid" ? "paid" : "unpaid",
    ] ?? ["unpaid"],
    payment_type:
      item?.payment_method?.main_payment_type === "CashOnDelivery" ? (
        <span
          className="rounded-1 px-2 py-1"
          style={{
            backgroundColor: "rgba(242, 61, 61, 0.063)",
            color: "#f23d3d",
          }}
        >
          Cash On Delivery
        </span>
      ) : (
        (
          <span
            className="rounded-1 px-2 py-1"
            style={{
              backgroundColor: "rgba(4, 186, 113, 0.063)",
              color: "#34BF44",
            }}
          >
            Online
          </span>
        ) ?? (
          <span
            className="rounded-1 px-2 py-1"
            style={{
              backgroundColor: "rgba(242, 61, 61, 0.063)",
              color: "#f23d3d",
            }}
          >
            Cash On Delivery
          </span>
        )
      ),
    order_value: item?.total_value_with_discount_with_delivery
      ? `${
          localStorage?.getItem("currency") + " " ?? "₹ "
        } ${item?.total_value_with_discount_with_delivery.toFixed(2)}`
      : "--",
    Action: ["/orders/status/", "/orders/edit/"],
  }));
  return (
    <div className=" col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5 ">
      <div>
        <audio ref={audioPlayer} src={noticationSound} />
      </div>
      <DynamicTable
        placeholder={"Search order here by id, name "}
        modalText={"order"}
        tabletitle={"Orders"}
        tableHeading={tableAttributeHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        createButtonName="CREATE ORDER"
        createpath="/orders/create"
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={attributesDeleteAction}
        multiDeleteAction={attributesMultiDeleteAction}
        loading={loading}
        setLoading={setLoading}
        error={error}
        id_name={"order_id"}
        setReload={setReload}
        reload={reload}
        rolePermission="orders"
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        filtration={true}
        filterFn={() => setFilterShow(true)}
      />
      <OrdersTableFilter
        table="order"
        show={filterShow}
        handleClose={() => setFilterShow(false)}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterValues={handleFilterValues}
      />
    </div>
  );
};
export default OrdersTable;
const tableAttributeHeader = [
  { label: "ID", value: "code" },
  { label: "No. of Items", value: "getItems" },
  { label: "Order Date", value: "order_date" },
  { label: "Order Status", value: "orders_status" },
  { label: "Payment Status", value: "payment_admin_status" },
  { label: "Payment Type", value: "payment_type" },
  { label: "Order Value", value: "total_value_with_discount_with_delivery" },
  { label: "Actions", value: "Actions" },
];
