import { all } from "redux-saga/effects";
import authRootSaga from "./auth-root-saga";
import categoriesRootSaga from "./categories-root-saga";
import adminRootSaga from "./admin-root-saga";
import attributesRootSaga from "./attributes-root-saga";
import tagsRootSaga from "./tags-root-saga";
import discountRootSaga from "./discount-root-saga";
import customerRootSaga from "./customer-root-saga";
import addressRootSaga from "./address-root-saga";
import productsRootSaga from "./products-root-saga";
import configurationRootSaga from "./configuration-root-saga";
import ordersRootSaga from "./orders-root-saga";
import { watchDashBoardDataWorker } from "../dashboard-saga";
import faqRootSaga from "./faq-root-saga";
import { watchConfigurationImgUploadWorker } from "../categories-saga";
import deliverypartnerRootSaga from "./delivery-partner-rootsaga";
import brandsRootSaga from "./brands-root-saga";
import contactEnquiryRootSaga from "./contact-enquiry-root-saga";
import productEnquiryRootSaga from "./product-enquiry-root-saga";
import interfaceRootSaga from "./interface-root-saga";

export default function* rootSaga() {
  yield all([
    authRootSaga(),
    categoriesRootSaga(),
    adminRootSaga(),
    attributesRootSaga(),
    tagsRootSaga(),
    discountRootSaga(),
    customerRootSaga(),
    addressRootSaga(),
    productsRootSaga(),
    ordersRootSaga(),
    configurationRootSaga(),
    watchDashBoardDataWorker(),
    faqRootSaga(),
    watchConfigurationImgUploadWorker(),
    deliverypartnerRootSaga(),
    brandsRootSaga(),
    contactEnquiryRootSaga(),
    productEnquiryRootSaga(),
    interfaceRootSaga()
  ]);
}
