import {
  TAGS_CREATE,
  TAGS_DELETE,
  TAGS_DROPDOWN_LIST,
  TAGS_EDIT,
  TAGS_MULTI_DELETE,
  TAGS_TABLE,
  TAGS_VIEW,
} from "../types";

export const tagsCreateAction = (data, onSuccess, onError) => {
  return {
    type: TAGS_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const tagsTableAction = (data, onSuccess, onError) => {
  return {
    type: TAGS_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const tagsViewAction = (id, onSuccess, onError) => {
  return {
    type: TAGS_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const tagsEditAction = (data, onSuccess, onError) => {
  return {
    type: TAGS_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const tagsDeleteAction = (data, onSuccess, onError) => {
  return {
    type: TAGS_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const tagsMultiDeleteAction = (data, onSuccess, onError) => {
  return {
    type: TAGS_MULTI_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const tagsDropDownListAction = (onSuccess, onError) => {
  return { type: TAGS_DROPDOWN_LIST, onSuccess, onError };
};
