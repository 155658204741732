import { call, takeLatest } from "redux-saga/effects";

import {
  PRODUCT_ENQUIRY_TABLE,
  PRODUCT_ENQUIRY_VIEW,
} from "../types";
import { productEnquiryTableApi, productEnquiryViewApi } from "../apis/product-enquiry-api";

export function* productEnquiryTableWorker(action) {
  console.log(action, "fsdfsdhfsdfsdfgsd");
  try {
    const res = yield call(productEnquiryTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* productEnquiryViewWorker(action) {
  try {
    const res = yield call(productEnquiryViewApi, action.id);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* watchproductEnquiryTableWorker() {
  yield takeLatest(PRODUCT_ENQUIRY_TABLE, productEnquiryTableWorker);
}
export function* watchproductEnquiryViewWorker() {
  yield takeLatest(PRODUCT_ENQUIRY_VIEW, productEnquiryViewWorker);
}