import React, { useCallback, useEffect, useState } from 'react'
import { Marker, Popup } from 'react-leaflet'
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import 'leaflet/dist/leaflet.css';
import { BoatIcon } from '../../../assets/icons/helperIcon'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
const AddressLeaflet = ({
    lat = 12.2958104,
    lng = 76.639380,
    setFieldValue = () => { },
    viewPage = false,
    page = ""
}) => {
    const [address, setAddress] = useState('');
    const [marker, setMarker] = useState({
        lat: lat,
        lng: lng,
    });
    useEffect(() => {
        setFieldValue("lat", marker?.lat)
        setFieldValue("lng", marker?.lng)
    }, [marker])
    const [map, setMap] = React.useState(null)

    const handleChangeaddress = (newAddress) => {
        setAddress(newAddress);
    };
    const handleSelect = (selectedAddress) => {
        setAddress(selectedAddress)
        geocodeByAddress(selectedAddress)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setMarker(latLng);
                map.panTo(latLng);
                map.moveCamera({
                    zoom: 19
                });
                map.setZoom(19);
            })
            .catch(error => console.error('Error', error));
    };
    const onMove = useCallback(() => {
        setMarker(map.getCenter())
    }, [map])
    useEffect(() => {
        map?.on('move', onMove)
        return () => {
            map?.off('move', onMove)
        }
    }, [map, onMove])
    const maxBounds = [
        [6.755, 68.11],  // Southwest coordinates (latitude, longitude)
        [35.674, 97.395] // Northeast coordinates (bounding box of India)
    ];
    return (
        <>
            {
                !viewPage &&
                <>
                    <label className={`d-flex fs_14 mb-1 ${address ? "color_9E9E9E" : "text-black"} fw_500 mb-2`}>Address</label>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChangeaddress}
                        onSelect={handleSelect}
                        searchOptions={{ componentRestrictions: { country: 'IN' } }} // Limit to India
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className='position-relative mb-3 '>
                                <input
                                    style={{ width: "100%" }}
                                    {...getInputProps({
                                        placeholder: 'Choose a location on the map.',
                                        className: 'location-search-input inputField_border focus_outline__none',
                                    })}
                                />
                                <div style={{ zIndex: 999 }} className="autocomplete-dropdown-container position-absolute h-100">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </>
            }
            <div style={{ height: "30vh" }} >
                <MapContainer
                    maxBounds={maxBounds}
                    ref={viewPage ? null : setMap}
                    center={marker}
                    zoom={19}
                    style={{ height: "100%", width: "100%" }}
                    scrollWheelZoom={true}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[marker.lat, marker.lng]} icon={BoatIcon}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker>
                </MapContainer>
            </div >
        </>
    )
}
export default AddressLeaflet