import React from "react";

const LinkIcon = ({fill="#2820DA", width="20", height="20"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07213 14.4343C8.14577 15.397 6.63715 15.4858 5.60473 14.6384C4.98987 14.1049 4.67579 13.3028 4.76457 12.4926C4.84145 11.8953 5.12002 11.3423 5.55397 10.9257L7.23196 9.24297C7.5736 8.9005 7.5736 8.34534 7.23196 8.00287C6.89021 7.6605 6.33623 7.6605 5.99448 8.00287L4.35209 9.64934C3.60344 10.3728 3.13132 11.3362 3.01774 12.3722C2.80559 14.7055 4.52116 16.7694 6.8496 16.982C8.10002 17.0962 9.33652 16.6483 10.225 15.7592L11.98 14.0011C12.3217 13.6586 12.3217 13.1035 11.98 12.761C11.6383 12.4186 11.0843 12.4186 10.7425 12.761L9.07213 14.4343Z"
        fill={fill}
      />
      <path
        d="M15.3441 3.8427C13.6018 2.55989 11.1824 2.761 9.67482 4.31396L8.02308 5.96977C7.68143 6.31225 7.68143 6.8674 8.02308 7.20988C8.36483 7.55224 8.91881 7.55224 9.26056 7.20988L10.9386 5.53008C11.8447 4.59093 13.3107 4.48514 14.3418 5.28452C15.403 6.15655 15.5579 7.72557 14.6877 8.78903C14.6368 8.85127 14.5829 8.91099 14.5261 8.968L12.7711 10.7267C12.4295 11.0692 12.4295 11.6244 12.7711 11.9668C13.1129 12.3092 13.6669 12.3092 14.0086 11.9668L15.7636 10.2081C17.4159 8.54709 17.4116 5.85834 15.7541 4.20261C15.6253 4.07396 15.4884 3.95375 15.3441 3.8427Z"
        fill={fill}
      />
      <path
        d="M8.02318 10.7279L10.7423 8.00297C11.0841 7.66047 11.6382 7.66047 11.98 8.00297C12.3218 8.34547 12.3218 8.90077 11.98 9.24327L9.26086 11.9682C8.91908 12.3107 8.36496 12.3107 8.02318 11.9682C7.6814 11.6257 7.6814 11.0704 8.02318 10.7279Z"
        fill={fill}
      />
    </svg>
  );
};

export default LinkIcon;
