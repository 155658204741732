import React, { useState } from "react";
import ResetEmailImage from "../../assets/images/reset-email-sent-image.png";
import { OkayButton } from "../../components/ui/buttons/text-button";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { forgotPasswordAction } from "../../redux/actions/auth-action";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
export default function ResetEmailSent() {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleResendEmailBtn = () => {
    setLoading(true);
    setLoading(true);
    dispatch(
      forgotPasswordAction(
        {
          email_address: params?.id,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    setLoading(false);
    successToast(`Forgot password link resent on email`);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
  };
  return (
    <div className="d-flex justify-content-center align-items-center text-center vh-100">
      <div style={{ maxWidth: "30rem" }} className="">
        <div className="fs_32 fs_md_22 fw_400 font_montserrat">
          Email has been sent!
        </div>
        <div className="color_9E9E9E fs_15 fs_md_12 px-sm-4 px-3">
          Please check your inbox and click in the received link to reset a
          password.
        </div>
        <div className="my-5 py-3">
          <img style={{ height: "200px" }} src={ResetEmailImage} alt="image" />
        </div>
        <div className="mt-5 mx-1">
          <OkayButton
            name="Check your Email"
            okayClassName="w-100 fs_16 fs_md_14 px-3"
          />
        </div>
        <div className="color_9E9E9E fw_400 mt-2">
          Didn’t receive the link?{" "}
          <button
            className="color_FF6F1E fw_500"
            type="button"
            onClick={() => {
              handleResendEmailBtn();
            }}
          >
            Resend
          </button>
        </div>
      </div>
    </div>
  );
}
