import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import SideBar from "./sidebar";
import DropDownIcon from "../../../assets/icons/layout-icons/drop-down.svg";
import MenuIcon from "../../../assets/icons/layout-icons/menu-line.svg";
import { Offcanvas } from "react-bootstrap";
import navbarLogo from "../../../assets/images/adair-logo.svg";
import CloseIcon from "../../../assets/icons/close-icon";
import "./layouts.css";
import { useDispatch } from "react-redux";
import successToast from "../../helper-functions/helper/sucess-toast";
import errorToast from "../../helper-functions/helper/error-toast";
import {
  activeStoreAction,
  adminLogoutAction,
} from "../../../redux/actions/auth-action";
import useLoggedAdminDetails from "../../helper-functions/hooks/useLoggedAdmin-details";
import UplaodImgLogo from "../../../assets/icons/user (3) 1.svg";
import GuideIcon from "../../../assets/icons/layout-icons/guide-icon";
import FaqsIcon from "../../../assets/icons/layout-icons/faqs-icon";
import HelpCenterIcon from "../../../assets/icons/layout-icons/help-center-icon";
import LogoutIcon from "../../../assets/icons/layout-icons/logout-icon";
import DeleteModal from "../modals/delete-modal";
import ToggleSwitch from "../buttons/toggle-button";
import { siteViewWithoutAccessAction } from "../../../redux/actions/configuration-action";
import NotificationSound from "../../../assets/audio/notification.mp3";
import successToastOrder from "../../helper-functions/helper/sucess-toast-order";
import { socket } from "../../../redux/config";

const MainLayout = ({ siteSettingsData, useSiteSettings }) => {
  console.log(siteSettingsData, "siteSettingsData");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [optionsMenu, setOptionsMenu] = useState(false);
  const [activeMenuOption, setActiveMenuOption] = useState("");
  const handleLogout = () => {
    dispatch(adminLogoutAction(onSuccess, onError));
  };
  const onSuccess = (data) => {
    navigate("/login");
    successToast(data?.message);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };
  const loggedAdminData = useLoggedAdminDetails();
  console.log(loggedAdminData?.data?.data, "loggedAdminData");
  const buttonRef = useRef();
  const dropDownRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (buttonRef.current && buttonRef.current.contains(e.target)) {
        return;
      }
      if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setOptionsMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [optionsMenu]);

  const fetchSiteSettingsDetails = () => {
    dispatch(siteViewWithoutAccessAction());
  };
  const activeStatus = () => {
    dispatch(activeStoreAction(onActiveSuccess, onActiveError));
  };
  const onActiveSuccess = (data) => {
    fetchSiteSettingsDetails();
    successToast(data?.message);
  };
  const onActiveError = (data) => {
    errorToast(data.data.message);
  };
  const [logoutModalShow, setLogoutModalShow] = useState(false);
  const handleLogoutModalShow = () => setLogoutModalShow(true);
  const handleLogoutModalClose = () => setLogoutModalShow(false);
  const menuOptions = [
    {
      image: <GuideIcon />,
      title: "Guide",
      action: () => {
        console.log("guide");
      },
    },
    {
      image: <FaqsIcon />,
      title: "FAQS",
      action: () => {
        navigate("/configuration");
        localStorage.setItem("config_section_status", "FAQs");
      },
    },
    {
      image: <HelpCenterIcon />,
      title: "Help Center",
      action: () => {
        console.log("Help");
      },
    },
    {
      image: <LogoutIcon />,
      title: "Logout",
      action: () => {
        handleLogoutModalShow();
      },
    },
  ];

  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }
 

  return (
    <>
      {" "}
      <audio ref={audioPlayer} src={NotificationSound} />
      <div className="row gx-0">
        <div
          style={{ zIndex: "999" }}
          className="d-flex justify-content-between px-lg-3 p-2 navbar_container position-fixed top-0 bg-white"
        >
          <img
            src={navbarLogo}
            className="ps-lg-3 w-100"
            alt="logo"
            style={{
              maxWidth: "100px",
            }}
          />
          <div className="profile_container d-flex align-items-center gap-1">
            <div className="mx-2">
              <ToggleSwitch
                handleToggleBtnChange={() => activeStatus()}
                name={`store_active`}
                value={siteSettingsData?.store_active}
                label={"Store Status:"}
                id={"store_active"}
                containerClassName={`bg_color_F9F9FC px-2`}
                labelStart={true}
                store_active_data={true}
              />
            </div>
            <div className="profile_image_container rounded-circle d-lg-block d-none">
              <img
                src={
                  /^https?:\/\//i.test(
                    loggedAdminData?.data?.data?.profile_picture
                  )
                    ? loggedAdminData?.data?.data?.profile_picture
                    : UplaodImgLogo
                }
                className="rounded-circle"
                width={"100%"}
                height={"100%"}
                alt="logo"
              />
            </div>
            <span className="font_poppins color_707070 d-lg-block d-none fw_500">
              {loggedAdminData?.data?.data?.email_address}
            </span>
            <div
              ref={buttonRef}
              onClick={() => {
                setOptionsMenu(!optionsMenu);
              }}
              className="rounded-2 bg_color_E5ECFF position-relative d-flex justify-content-center"
            >
              <img src={DropDownIcon} width={25} height={23} alt="icon" />
            </div>{" "}
            <div className="postion-relative">
              {" "}
              {optionsMenu && (
                <div
                  ref={dropDownRef}
                  className="position-absolute py-3 bg-white fs_17 rounded"
                  style={{
                    top: "4rem",
                    right: "1.5rem",
                    boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.12)",
                    width: "16rem",
                  }}
                  onMouseLeave={() => setActiveMenuOption("")}
                >
                  {menuOptions?.map((item, index) => (
                    <>
                      {menuOptions?.length - 1 === index ? (
                        <hr
                          className=" w-100 m-0 my-2"
                          style={{ border: "1px solid #E5ECFF" }}
                        />
                      ) : (
                        <></>
                      )}
                      <div
                        onClick={item?.action}
                        onMouseEnter={() => setActiveMenuOption(item?.title)}
                        className={`cursor_pointer d-flex ${
                          activeMenuOption === item?.title &&
                          "bg_color_E5ECFFB2 rounded-2"
                        } align-items-center ps-4 gap-2 color_707070 py-2 mx-2`}
                      >
                        {item?.image} {item?.title}
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
            <span onClick={() => setShow(true)} className="d-lg-none">
              <img src={MenuIcon} width={25} height={25} alt="icon" />
            </span>
          </div>
          <Offcanvas
            show={show}
            onHide={() => setShow(false)}
            placement="start"
            className="side-bar-container"
          >
            <Offcanvas.Body>
              <div className="text-end" onClick={() => setShow(false)}>
                <CloseIcon fill="#000000" width="12" height="14" />
              </div>
              <div className=" ">
                <SideBar setShow={setShow} show={show} />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
      <div className="d-flex mt-lg-5">
        <div className={`d-lg-block d-none position-fixed`}>
          <SideBar />
        </div>

        <div className="main_layout_container ms-auto pt-2">
          <Outlet />
        </div>
      </div>
      <DeleteModal
        show={logoutModalShow}
        modalClose={handleLogoutModalClose}
        deleteFn={() => handleLogout()}
        text={`Are you sure, you want to logout from First Floor Ecommerce?`}
        type={"logout"}
      />
    </>
  );
};

export default MainLayout;
