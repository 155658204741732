import React, { useState } from "react";
import ConfigSection from "../config-section";
import PaymentGateway from "./payment-gateway";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
const PaymentSettings = ({ closeSection }) => {
  const PaymentSettingList = [
    {
      title: "Cash on Delivery",
      subHeading: "Pay for your order in cash when it is delivered to your doorstep",
      type: "CashOnDelivery",
    },
    {
      title: "Razorpay Payment Gateway",
      subHeading: "Your payment is processed securely using Razorpay's encrypted payment gateway.",
      type: "RazorpayPaymentGateway",
    },
    // {
    //   title: "Paytm Payment Gateway",
    //   subHeading: "Your payment is processed securely using Paytm's encrypted payment gateway.",
    //   type: "PaytmPaymentGateway",
    // },
    // {
    //   title: "Other Payment Gateway",
    //   subHeading: "Choose your convenient Payment Gateway",
    //   type: "OtherPaymentGateway",
    // },
    {
      title: "Adair Payment Gateway",
      subHeading: "Your payment is processed securely using Razorpay's encrypted payment gateway.",
      type: "AdairPay",
    },
    {
      title: "Stripe Payment Gateway",
      subHeading: "Your payment is processed securely using Stripe's encrypted payment gateway.",
      type: "Stripe",
    },
  ];
  const [selectedPayment, setSelectedPayment] = useState(null);

  const onBack = () => {
    setSelectedPayment(null);
  };

  return (
    <>
      {!selectedPayment && (
        <>
          <div className="border_bottom_1px_e5ecff py-3 fs_22 fw_600 font_montserrat mb-0 color_030304 ps-sm-5 ps-3 d-flex align-items-center">
            <img
              className="d-inline-block d-md-none me-2"
              onClick={closeSection}
              src={backIcon}
            />
            <span>Payment Settings</span>
          </div>
          <div className="mt-5 px-sm-5 px-2">
            {PaymentSettingList.map((item) => (
              <ConfigSection
                onClick={() => {
                  setSelectedPayment(item);
                }}
                item={item}
              />
            ))}
          </div>
        </>
      )}
      {selectedPayment && (
        <PaymentGateway onBack={onBack} data={selectedPayment} />
      )}
    </>
  );
};

export default PaymentSettings;
