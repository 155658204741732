import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddVariantTypeButton, {
  AddWithBorderButton,
  SaveButton,
  AddVariantButton as AddVariantValueButton,
  EditButton,
} from "../buttons/text-button";
import { generateRandomId } from "../../helper-functions/helper/helper";
import DownArrowIcon from "../../../assets/icons/down-arrow-icon";
import { useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "../forms/select-field";
import { components } from "react-select";
import DeleteIconWithBg from "../../../assets/icons/products-delete";
import { variantTypeOptions } from "../../helper-functions/helper/data";
import dragIcon from "../../../assets/icons/drag.svg";
import { List, arrayMove } from "react-movable";
import infoIcon from "../../../assets/icons/info-icon.svg";
import { v4 as uuidv4 } from 'uuid';
function ProductsVariationsNew(props, ref) {

  const {
    comboDeleted,
    setComboDeleted,
    productData,
    setInitialValues,
    tagsList,
    attributesList,
    categoryList,
    brandList,
    onVariationUpdate,
    setVariantStatus,
    varaintStatus,
    taxData,
    setEditvariantData,
  } = props;

  // 26930

  // 50,000-

  //40,000
  const [variantTypeList, setVariantTypeList] = useState({});
  const [variantDeleted, setVariantDeleted] = useState(false);
  const { values: formValues, setFieldValue } = useFormikContext();
  console.log(productData?.gst_tax, "productData.gst_tax");
  useEffect(() => {
    if (productData?.product_name) {
      const variantData = {};
      const varaintStatusData = {};
      let variation_products = [];
      if (productData?.variation
        && productData?.variation_products) {
        console.log(productData?.variation, "dfghdfghdfgh");
        productData?.variation
          .forEach(function (item) {
            const varaintId = item?.id;
            varaintStatusData[varaintId] = { saved: true, error: false };
            variantData[varaintId] = {
              id: varaintId,
              variation_name: item.variation_name,
              values: item.values.map((item) => {
                return { value: item, id: generateRandomId(6) };
              }),
            };
          });
        const variantValuesList = Object.keys(variantData)
          .map((key) => variantData[key].values)
          .flat();
        variation_products = productData.variation_products.map((item) => {
          const itemCopy = {
            ...item,
            actions: "",
            // delete: false,
            variant: item.variants.map((item) => {
              return variantValuesList?.find(
                (valueItem) => valueItem?.value === item
              );
            }),
          };
          return itemCopy;
        });
        setVariantTypeList(variantData);
        setEditvariantData(variantData)
        setVariantStatus(varaintStatusData);
        ref.current = variation_products;
      }

      setInitialValues((prev) => {
        return {
          ...prev,
          product_name: productData.product_name ?? "",
          slug: productData.slug ?? "",
          category_id:
            categoryList.filter((item) =>
              productData.category_id?.includes(item.value)
            ) ?? [],
          attributes:
            attributesList.filter((item) =>
              productData.attributes_id?.includes(item.value)
            ) ?? [],
          tags:
            tagsList.filter((item) =>
              productData.tag_id?.includes(item.value)
            ) ?? [],
          brand_id:
            productData.brand_id ?? null,
          variation_search
            : productData.variation_search,
          variants: variantData,
          variation_products: variation_products,
          description: productData.description ?? "",
          is_veg: productData?.is_veg === true ? "veg" : "nonveg",
          seo_title: productData.seo_title ?? "",
          image: productData.product_picture,
          seo_description: productData.seo_description ?? "",
          short_description: productData.short_description ?? "",
          price: productData.price ?? null,
          isDiscounted: productData.is_discount_price ?? false,
          isInventory: productData.is_inventory ?? false,
          discount_price: productData.discount_price ?? "",
          inventory_count: productData.inventory_count ? productData.inventory_count : "",
          sku: productData.sku ? productData.sku : "",
          product_weight: productData.product_weight ? productData.product_weight : "",
          hsn_code: productData.hsn_code ? productData.hsn_code : "",
          gst_tax: productData.gst_tax ?? taxData?.default_tax_rate ?? "",
          barcode: productData.barcode ?? "",
        };
      });
    }
  }, [productData, tagsList, attributesList, categoryList]);

  function handleAddVariant() {
    const newVariantId = uuidv4();
    setVariantTypeList({
      ...variantTypeList,
      [newVariantId]: {
        // name: "",
        id: newVariantId,
        "variation_name": "",
        values: [],
      },
    });
    ////
    setEditvariantData({
      ...variantTypeList,
      [newVariantId]: {
        // name: "",
        id: newVariantId,
        "variation_name": "",
        values: [],
      },
    })
    setVariantStatus((prev) => {
      return { ...prev, [newVariantId]: { error: false, saved: false } };
    });
  }

  const selectedVarinatList = useMemo(() => {
    return Object.keys(variantTypeList)
      .map((key) => variantTypeList[key].variation_name)
      .filter((item) => item);
  }, [variantTypeList]);

  const varinatOptions = useMemo(() => {
    return variantTypeOptions.filter(
      (item) => !selectedVarinatList.includes(item.value)
    );
  }, [selectedVarinatList]);

  const handleSaveVariation = useCallback(
    (variantId) => {
      let valuesList = variantTypeList[variantId].values.map(
        (item) => item.value
      );
      let isValidated = false;
      isValidated = valuesList.some((element, index) => {
        return valuesList.indexOf(element) !== index || element === "";
      });
      if (!isValidated) {
        // const newVariantStatus = {
        //   ...varaintStatus,
        //   [variantId]: { saved: true, error: false },
        // };
        // setVariantStatus(newVariantStatus);
        setVariantStatus((prev) => {
          return { ...prev, [variantId]: { saved: true, error: false } };
        });
        /*** combination Creation***/
        let variantValuesList = [];
        for (const [_, itemValue] of Object.entries(variantTypeList)) {
          if (
            itemValue.variation_name &&
            itemValue.values.every((item) => item.value)
            //  &&
            // newVariantStatus[variantId].saved
          ) {
            variantValuesList.push(itemValue.values);
          }
        }
        // console.log(variantValuesList, "aaaaaaaaaaa");
        // localStorage.setItem("test", JSON.stringify(variantValuesList));
        createCombination(variantValuesList);
        setFieldValue("variants", variantTypeList);
        onVariationUpdate();
      } else {
        setVariantStatus((prev) => {
          return { ...prev, [variantId]: { error: true, saved: false } };
        });
      }
    },
    [variantTypeList]
  );

  function createCombination(variantValuesList) {
    localStorage.setItem("test", JSON.stringify(variantValuesList))
    const combinations = variantValuesList.reduce((acc, curr) => {
      if (acc.length === 0) {
        return curr.map((item) => [item]);
      } else {
        const newCombinations = [];
        acc.forEach((prevCombination) => {
          curr.forEach((item) => {
            newCombinations.push([...prevCombination, item]);
          });
        });
        return newCombinations;
      }
    }, []);
    // console.log('====================================');
    // console.log(combinations,"aaaaaaaaaaa");
    // console.log('====================================');
    const data = combinations.map((combination) => {
      let existingCombinationData;
      let maxMatch = 0;

      let variant_name = [];
      let variant_json = [];
      for (let i = 0; i < combination.length; i++) {
        for (const variant in variantTypeList) {
          variantTypeList[variant].values.forEach(value => {
            if (value?.id === combination[i]?.id) {
              variant_name.push(variantTypeList[variant].variation_name);
              variant_json.push({ [variantTypeList[variant].variation_name]: value.value });
            }
          });
        }
      }

      for (let i = 0; i < ref.current.length; i++) {
        const existingVariantIds = ref.current[i].variant.map((v) => v?.id);
        const matchingElements = combination.filter((v) =>
          existingVariantIds.includes(v?.id)
        );

        // Check if all elements of the existing combination are in the new combination
        if (matchingElements.length === existingVariantIds.length) {
          const matchCount = matchingElements.length;
          if (matchCount > maxMatch) {
            maxMatch = matchCount;
            existingCombinationData = ref.current[i];
          }
        }
      }

      if (existingCombinationData) {
        return {
          variant: combination,
          price: existingCombinationData?.price,
          delete: existingCombinationData?.delete,
          variants_pictures: existingCombinationData?.variants_pictures,
          variant_name: existingCombinationData?.variant_name,
          variant_json: existingCombinationData?.variant_json,
          discounted_price: existingCombinationData?.discounted_price,
          sku: existingCombinationData?.sku,
          bar_code: existingCombinationData?.bar_code,
          inventory_count: existingCombinationData?.inventory_count,
          sku_barcode_same_as_parent_value: existingCombinationData?.sku_barcode_same_as_parent_value,
          max_weight: existingCombinationData?.max_weight,
          hsn_code: existingCombinationData?.hsn_code,
          gst_tax: existingCombinationData?.gst_tax,
          actions: "",
        };
      } else {
        let maxSubsetCombination;
        for (let i = 0; i < ref.current.length; i++) {
          const existingVariantIds = ref.current[i].variant.map((v) => v?.id);
          if (combination.every((v) => existingVariantIds.includes(v?.id))) {
            if (
              !maxSubsetCombination ||
              existingVariantIds.length > maxSubsetCombination.variant.length
            ) {
              maxSubsetCombination = ref.current[i];
            }
          }
        }

        // if a maximum subset combination exists, use its price for the current combination
        if (maxSubsetCombination) {
          return {
            variant: combination,
            price: maxSubsetCombination?.price,
            delete: maxSubsetCombination?.delete,
            variants_pictures: maxSubsetCombination?.variants_pictures,
            variant_name: maxSubsetCombination?.variant_name,
            variant_json: maxSubsetCombination?.variant_json,
            discounted_price: maxSubsetCombination?.discounted_price,
            sku: maxSubsetCombination?.sku,
            bar_code: maxSubsetCombination?.bar_code,
            inventory_count: maxSubsetCombination?.inventory_count,
            sku_barcode_same_as_parent_value: existingCombinationData?.sku_barcode_same_as_parent_value,
            max_weight: existingCombinationData?.max_weight,
            hsn_code: existingCombinationData?.hsn_code,
            gst_tax: existingCombinationData?.gst_tax,
            actions: "",
          };
        }
        // if no subset exists, set the price as empty string
        else {
          return {
            variant: combination,
            price: "",
            delete: false,
            variants_pictures: [],
            variant_name: variant_name,
            variant_json: variant_json,
            // variants_pictures: [],
            discounted_price: "",
            sku: "",
            bar_code: "",
            inventory_count: "",
            sku_barcode_same_as_parent_value: false,
            max_weight: "",
            hsn_code: "",
            gst_tax: "",
            actions: "",
          };
        }
      }
    });
    setFieldValue("variation_products", data);
    ref.current = data;
    return combinations;
  }

  // console.log(ref, "ref");
  // console.log(formValues, "form");

  useEffect(() => {
    let variantValuesList = [];
    for (const [_, itemValue] of Object.entries(variantTypeList)) {
      if (itemValue.variation_name && itemValue.values.every((item) => item.value)) {
        variantValuesList.push(itemValue.values);
      }
    }
    createCombination(variantValuesList);
  }, [variantDeleted]);

  useEffect(() => {
    // Get list of variants that are marked for deletion
    if (comboDeleted === "combo_deleted") {
      const deleteEnabledList = formValues.variation_products
        .filter((item) => item.delete)
        .map((item) => item.variant);

      // Flatten the list and keep track of the count for each value
      const valueCounts = {};
      deleteEnabledList.flat().forEach((variant) => {
        if (!valueCounts[variant.value]) {
          valueCounts[variant.value] = 0;
        }
        valueCounts[variant.value]++;
      });

      // Find out which values are marked for deletion in all their combinations
      const allCombosToDelete = [];
      for (let value in valueCounts) {
        const valueCount = valueCounts[value];
        const totalCombos = formValues.variation_products.filter((item) =>
          item.variant.some((variant) => variant.value === value)
        ).length;

        if (valueCount === totalCombos) {
          allCombosToDelete.push(value);
        }
      }

      // Filter out combinations that involve values marked for deletion from variation_products
      const updatedVariantData = formValues.variation_products.filter(
        (item) =>
          !item.variant.some((variant) =>
            allCombosToDelete.includes(variant.value)
          )
      );

      // Update the ref
      ref.current = updatedVariantData;

      // Update the form value
      setFieldValue("variation_products", updatedVariantData);

      // Update the variantTypeList
      const updatedVariantTypeList = { ...variantTypeList };
      const updatedVariantStatusList = { ...varaintStatus }
      for (let key in updatedVariantTypeList) {
        updatedVariantTypeList[key].values = updatedVariantTypeList[
          key
        ].values.filter((item) => !allCombosToDelete.includes(item.value));

        // If the values array is empty, delete the variant
        if (updatedVariantTypeList[key].values.length === 0) {
          delete updatedVariantTypeList[key];
          delete updatedVariantStatusList[key]
        }
      }
      setVariantTypeList(updatedVariantTypeList);
      setEditvariantData(updatedVariantTypeList);
      setVariantStatus(updatedVariantStatusList)
      setFieldValue("variants", updatedVariantTypeList);
      setComboDeleted("");
    } else if (comboDeleted === "reset") {
      ref.current = formValues.variation_products;
      setVariantTypeList(formValues.variants);
      setEditvariantData(formValues.variants);
      setVariantStatus(() => {
        let newState = {};
        for (let key in formValues.variants) {
          newState[key] = { saved: true, error: false };
        }
        return newState;
      });
      setComboDeleted("");
    }
  }, [comboDeleted]);

  console.log(variantTypeList, varaintStatus, "kk");
  return (
    <>
      <h3
        // onClick={() =>
        //   localStorage.setItem("data", JSON.stringify(variantTypeList)) 
        // }
        className="fs_16  color_030304 font_montserrat fw_600 mb-3 mt-2"
      >
        Variations{" "}
      </h3>
      {Object.keys(variantTypeList).length === 0 && (
        <AddWithBorderButton
          onClick={() => handleAddVariant()}
          addClassName="py-1 mb-2"
          customStyles={{ borderRadius: "4px" }}
          name="Add Variants"
        />
      )}

      {Object.keys(variantTypeList).map((variantId) => {
        const varaintData = variantTypeList[variantId];
        return (
          <React.Fragment key={variantId}>
            {varaintStatus[variantId]?.saved ? (
              <div className="mb-4">
                <p className="mb-0 color_030304 ms-1">{varaintData.variation_name}</p>
                <div className="d-flex align-items-center gap-2 my-2 mb-3">
                  {varaintData?.values?.map((item) => {
                    return (
                      <div
                        key={item.value}
                        style={{
                          border: "1.5px solid #E5ECFF",
                          padding: ".12rem 1rem",
                        }}
                        className="primary_color bg_color_F9F9FC h-100 rounded  "
                      >
                        {item.value}
                      </div>
                    );
                  })}
                  <EditButton
                    name="Edit"
                    onClick={() => {
                      setVariantStatus((prevState) => ({
                        ...prevState,
                        [variantId]: { saved: false, error: false },
                      }));
                    }}
                    editClassName="fs_14 py-1 px-2 d-flex justify-content-center"
                  />
                </div>
              </div>
            ) : (
              <div className="mb-4">
                <VariantSelectField
                  isSelected={variantTypeList[variantId].variation_name}
                  selectedOptionList={selectedVarinatList}
                  selectContainerClass={`${variantTypeList[variantId].variation_name ? "" : "mb-3"
                    }`}
                  value={
                    variantTypeList[variantId].variation_name
                      ? {
                        label: variantTypeList[variantId].variation_name,
                        value: variantTypeList[variantId].variation_name,
                      }
                      : null
                  }
                  options={varinatOptions}
                  handleSelected={(selectedOption) => {


                    setVariantTypeList((prev) => {
                      return {
                        ...prev,
                        [variantId]: {
                          ...prev[variantId],
                          // name: selectedOption.value,
                          variation_name: selectedOption.value,
                          ...(prev[variantId].values.length === 0
                            ? {
                              values: [
                                {
                                  value: "",
                                  id: generateRandomId(6),
                                },
                              ],
                            }
                            : {}),
                        },
                      };
                    });


                    setEditvariantData((prev) => {
                      return {
                        ...prev,
                        [variantId]: {
                          ...prev[variantId],
                          // name: selectedOption.value,
                          variation_name: selectedOption.value,
                          ...(prev[variantId].values.length === 0
                            ? {
                              values: [
                                {
                                  value: "",
                                  id: generateRandomId(6),
                                },
                              ],
                            }
                            : {}),
                        },
                      };
                    });


                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setVariantTypeList((prev) => {
                        const variantTypeCopy = { ...prev };
                        delete variantTypeCopy[variantId];
                        return variantTypeCopy;
                      });
                      //Added by me
                      setEditvariantData((prev) => {
                        const variantTypeCopy = { ...prev };
                        delete variantTypeCopy[variantId];
                        return variantTypeCopy;
                      })
                      //dfuhsud
                      setVariantStatus((prev) => {
                        const variantErrorCopy = { ...prev };
                        delete variantErrorCopy[variantId];
                        return variantErrorCopy;
                      });
                      setVariantDeleted(!variantDeleted);
                    }}
                    style={{ right: "-50px" }}
                    className={"position-absolute h-100  top-0  color_E6ECFF"}
                  >
                    <DeleteIconWithBg width={35} height={35} />
                  </button>
                </VariantSelectField>
                {variantTypeList[variantId].variation_name && (
                  <h5 className="fs_14 color_9E9E9E fw_400 mt-4">
                    Variant Values
                  </h5>
                )}
                {varaintData?.values.length > 0 && (
                  <List
                    values={varaintData.values}
                    onChange={({ oldIndex, newIndex }) => {
                      // console.log(oldIndex, newIndex, "aaaaaaa");
                      let variantTypeListCopy = JSON.parse(
                        JSON.stringify(variantTypeList)
                      );
                      variantTypeListCopy[variantId].values = arrayMove(
                        variantTypeListCopy[variantId].values,
                        oldIndex,
                        newIndex
                      );
                      setVariantTypeList(variantTypeListCopy);
                      setEditvariantData(variantTypeListCopy);
                    }}
                    renderList={({ children, props }) => (
                      <ul {...props} className="m-0 p-0">
                        {children}
                      </ul>
                    )}
                    renderItem={({
                      value: valueItem,
                      props,
                      isDragged,
                      index: inputIndex,
                    }) => {
                      return (
                        <li
                          {...props}
                          className="list-unstyled variant-value-item"
                        >
                          <div className="d-flex align-items-center">
                            <button
                              type="button"
                              data-movable-handle
                              style={{
                                cursor: isDragged ? "grabbing" : "grab",
                              }}
                            >
                              <img src={dragIcon} alt="drag" />
                            </button>
                            <VariationValueInputField
                              onChange={(e) => {
                                const variantTypeCopy = {
                                  ...variantTypeList,
                                };
                                variantTypeCopy[variantId].values[
                                  inputIndex
                                ].value = e.target.value;
                                setVariantTypeList(variantTypeCopy);
                                setEditvariantData(variantTypeCopy);
                              }}
                              value={valueItem?.value}
                            />

                            <button
                              type="button"
                              disabled={varaintData.values.length === 1}
                              onClick={() => {
                                if (varaintData.values.length > 1) {
                                  const variantTypeCopy = {
                                    ...variantTypeList,
                                  };
                                  const variantUpdatedData = variantTypeCopy[
                                    variantId
                                  ].values.filter(
                                    (_, itemIndex) => itemIndex !== inputIndex
                                  );
                                  variantTypeCopy[variantId].values =
                                    variantUpdatedData;
                                  setVariantTypeList(variantTypeCopy);
                                  setEditvariantData(variantTypeCopy);
                                  setFieldValue("variants", variantTypeCopy);
                                }
                              }}
                            >
                              <DeleteIconWithBg
                                className="ms-2"
                                customStyles={{ padding: "0.15rem" }}
                              />
                            </button>
                          </div>
                          <ErrorView
                            className="ps-5 ms-2"
                            msg={
                              !valueItem?.value
                                ? "Field is required!"
                                : "Values are same"
                            }
                            error={
                              varaintStatus[variantId]?.error &&
                              (!valueItem?.value ||
                                varaintData.values.some(
                                  (item, valueIndex) =>
                                    valueIndex !== inputIndex &&
                                    item.value === valueItem?.value
                                ))
                            }
                          />
                        </li>
                      );
                    }}
                  />
                )}
                {varaintData.values?.length > 0 && (
                  <div className="d-flex gap-2 justify-content-end my-3">
                    <AddVariantValueButton
                      onClick={() => {
                        const variantTypeCopy = { ...variantTypeList };
                        variantTypeCopy[variantId].values.push({
                          value: "",
                          id: generateRandomId(6),
                          // variantId +
                          // variantTypeCopy[variantId].values.length-1,
                        });
                        setVariantTypeList(variantTypeCopy);
                        setEditvariantData(variantTypeCopy);
                      }}
                    />
                    <SaveButton
                      onClick={() => {
                        handleSaveVariation(variantId);
                      }}
                      name="Save"
                      backClassName="fs_14"
                    />
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
      {Object.keys(variantTypeList).length > 0 && (
        <AddVariantTypeButton
          createFn={() => handleAddVariant()}
          createClassName={"w-100 fs_15 fs_md_12 fw_400 text-nowrap mt-3"}
          name="Add Variant Type"
        />
      )}
      {/* <p className="color_F24444 fs_12">
        {errors.variation_products && (
          <>
            <span>&middot;</span> {errors.variation_products}!
          </>
        )}
      </p> */}
    </>
  );
}

export default React.forwardRef(ProductsVariationsNew);

function VariantSelectField({
  id,
  Icon = <DownArrowIcon />,
  labelClass = "mb-2 d-flex flex-column flex-sm-row align-items-sm-center gap-2",
  labelActiveClass = "color_9E9E9E fw_400",
  labelInActiveClass = " text-black fw_500",
  children,
  // defaultSelectClass = "selectField focus_outline__none",
  options = [],
  selectedOptionList = [],
  placeholder = "Select Variant",
  isDisabled,
  defaultValue = null,
  key,
  handleSelected,
  selectContainerClass = "",
  value,
  isSelected,
}) {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {Icon}
      </components.DropdownIndicator>
    );
  };
  const CustomOption = (props) => {
    const { children, isFocused, innerRef, innerProps } = props;
    if (children.startsWith("Create new option$51$")) {
      return (
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{
            backgroundColor: isFocused ? "#E5ECFF" : null,
            padding: "8px 12px",
            cursor: "pointer",
          }}
          ref={innerRef}
          {...innerProps}
        >
          <span className="w_fit">
            {children.replace("Create new option$51$: ", "")}
          </span>
          <button className="primary_color fs_14 fw_500">Create Variant</button>
        </div>
      );
    }
    return (
      children && (
        <components.Option {...props}>{props.children}</components.Option>
      )
    );
  };

  return (
    <>
      <label
        {...(id ? { htmlFor: id } : {})}
        className={`${isSelected ? labelActiveClass : labelInActiveClass
          }   ${labelClass}`}
      >
        <span>Variant Type</span>
        { }{" "}
        <span className="bg_color_E5ECFFB2 rounded-1 py-1 px-2 fs_md_10 fs_12 fw_500 font_poppins color_444444 mb-1 mb-sm-0 w_fit">
          {" "}
          <img src={infoIcon} alt="info" className="me-1" /> You can create your
          own variant type by entering it.
        </span>
      </label>
      <div
        style={{ width: "calc(100% - 50px)" }}
        className={`position-relative  ${selectContainerClass}`}
      >
        <CreatableSelect
          {...(key ? { key: key } : {})}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
          value={value}
          {...(id ? { id: id } : {})}
          components={{
            DropdownIndicator,
            Option: CustomOption,
          }}
          styles={customStyles}
          className={`w-100 ${`selectField_border`}`}
          placeholder={placeholder}
          options={options}
          onChange={(selectedOption) => {
            handleSelected(selectedOption);
          }}
          formatCreateLabel={(inputValue) => {
            if (selectedOptionList.includes(inputValue)) {
              return `Cannot create option: ${inputValue}`;
            } else if (options.map((item) => item.value).includes(inputValue)) {
              return "";
            } else {
              return `Create new option$51$: ${inputValue}`;
            }
          }}
          isValidNewOption={(inputValue) =>
            inputValue.trim() !== "" && !selectedOptionList.includes(inputValue)
          }
          noOptionsMessage={({ inputValue }) =>
            selectedOptionList.includes(inputValue)
              ? `${inputValue} is already selected`
              : "Type to create an option"
          }
        />{" "}
        {children}{" "}
      </div>
      {/* <ErrorMsg containerClass={""} name={name} /> */}
    </>
  );
}
function VariationValueInputField({ value, onChange }) {
  return (
    <input
      placeholder="Enter Variant Value"
      type="text"
      value={value}
      onChange={onChange && onChange}
      className={`inputField focus_outline__none inputField_border w-100`}
    />
  );
}
function ErrorView({
  error,
  className = "",
  defaultClass = "mb-1",
  msg = "Field is required!",
}) {
  return (
    error && (
      <p
        className={`color_F24444  fw_500 fs_12 ${defaultClass}   ${className}`}
      >
        <span className="fs_14  fw_600">&middot;</span> {msg}
      </p>
    )
  );
}
