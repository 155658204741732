import React, { useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import ImageUpload from "../../components/ui/forms/image-upload";
import InputField from "../../components/ui/forms/input-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import errorToast from "../../components/helper-functions/helper/error-toast";
import { deliverypartnerCreateAction } from "../../redux/actions/delivery-partner-action";
import { categoriesImgUploadAction } from "../../redux/actions/categories-action";
import Compressor from "compressorjs";
import CountryCode from "../../components/ui/forms/country-code";
import NotificationComponent from "../../components/notificationComponent";
const DeliveryPartnerCreate = () => {
  const navigate = useNavigate();
  const [imgUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageSize, setImageSize] = useState(false);
  const dispatch = useDispatch();
  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        },
      });
    } else {
      setImageSize(true);
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (data) => {
    setLoading(false);
    setImageUrl(data);
  };
  const onImgError = (data) => {
    setLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };
  const initialValues = {
    photo: "",
    name: "",
    vehicle_number: "",
    vehicle_name: "",
    phone_number: ""
  };
  const validationSchema = Yup.object().shape({
    photo: Yup.mixed().required("Required"),
    name: Yup.string().trim().required("Delivery Partner Name is required"),
    vehicle_number: Yup.string().trim().required("Delivery Partner Name is required"),
    vehicle_name: Yup.string().trim().required("Delivery Partner Name is required"),
    phone_number: Yup.number().required("Enter phone number"),


  });
  const onSubmit = (values) => {
    dispatch(
      deliverypartnerCreateAction(
        {
          "name": values?.name,
          "mobile": values?.phone_number,
          "vehicle_number": values?.vehicle_number,
          "vehicle_name": values?.vehicle_name,
          "photo": imgUrl
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/delivery-partners`);
  };
  const onError = (data) => {
    errorToast(data.data.message);
  };

  function resetFn() {
    setImageUrl("");
  }
  return (
    <div>
      <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, handleChange }) => {
          return (
            <Form>
              <InnerLayout
                name="New Delivery Partner"
                page="create"
                edit={true}
                resetFn={resetFn}
                rolePermission={"brands"}
              />
              <span>
                <ImageUpload
                  page={"site"}
                  labelClass="color_030304"
                  name="photo"
                  getImgUrl={getImgUrl}
                  resetFn={resetFn}
                  data={imgUrl}
                  loading={loading}
                  setFieldValue={setFieldValue}
                  error={errors?.photo ? true : false}
                />
                {imageSize && <label className="fs_14 ms-5 text-danger mb-3">"Please upload below 10mb"</label>}
              </span>

              <div
                className="ms-md-5 ms-4 pe-4 pe-md-0"
                style={{ maxWidth: "40rem" }}
              >
                <div>
                  <InputField
                    placeholder="Enter your delivery partner name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Delivery Partner Name"
                    name="name"
                    type="text"
                  />
                </div>
                <label className="color_030304 fw_500 fs_14">
                  Phone Number
                </label>
                <div className="d-flex">
                  <span className="">
                    <CountryCode />
                  </span>
                  <span className="ms-2 w-100">
                    <InputField
                      placeholder="Enter phone number"
                      containerClass=" position-relative"
                      errMsgContainerClass="ps-2"
                      label=""
                      name="phone_number"
                      type="text"
                    />
                  </span>
                </div>
                <div>
                  <InputField
                    placeholder="Enter vehicle name"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Vehicle Name"
                    name="vehicle_name"
                    type="text"
                  />
                </div>
                <div>
                  <InputField
                    placeholder="Enter vehicle number"
                    containerClass="mb-3 position-relative"
                    errMsgContainerClass="ps-2"
                    label="Vehicle Number"
                    name="vehicle_number"
                    type="text"
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  );
};

export default DeliveryPartnerCreate;
