import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import { BackButton, SaveButton } from "../buttons/text-button";
import { Form, Formik } from "formik";
import InputField from "../forms/input-field";
import MultiImageUpload from "../forms/multiImage-upload";
import * as Yup from "yup";
import { numbRegex } from "../../helper-functions/helper/regex";
import ErrorMsg from "../forms/error-msg";
import isEqual from "lodash.isequal";
export default function EditVariantModal({
  show,
  modalClose,
  data,
  onEditUpdate,
  skuAndBarcode = {},
}) {
  const [skubarcodeChecked, setSkubarcodeChecked] = useState(data?.sku_barcode_same_as_parent_value);
  const [images, setImages] = useState([]);
  useEffect(() => {
    setImages(data?.variants_pictures ?? []);
    // setImages(data?.variants_pictures);
    setSkubarcodeChecked(data.isSkuAndBarcode);
  }, [show]);
  console.log(data?.sku_barcode_same_as_parent_value, "discounted_pricediscounted_pricediscounted_price");
  const initialValues = {
    price: data.price ?? "",
    discount_price: data.discounted_price ?? "",
    isDiscounted: data.discounted_price ? true : false,
    sku_barcode_same_as_parent_value: data?.sku_barcode_same_as_parent_value
      ? true
      : false,
    sku: data?.sku_barcode_same_as_parent_value
      ? skuAndBarcode?.sku
      : data?.sku ?? "",
    bar_code: data?.sku_barcode_same_as_parent_value
      ? skuAndBarcode?.barcode
      : data?.bar_code ?? "",
    inventory_count: data.inventory_count ?? "",
    isInventory: data?.inventory_count ? true : false,
    variants_pictures: data?.variants_pictures ? data?.variants_pictures : [],
    // variants_pictures: data?.variants_pictures ? data?.variants_pictures : [],
    product_weight: data?.max_weight ?? "",
  };
  const Schema = Yup.object().shape({
    price: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .required("Required"),
    isDiscounted: Yup.boolean(),
    isInventory: Yup.boolean(),
    isSkuAndBarcode: Yup.boolean(),
    discount_price: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .test(
        "discounted-price-validation",
        "Check discounted price based on isDiscounted",
        function (value) {
          const { isDiscounted, price } = this.parent;
          if (isDiscounted) {
            if (!value) {
              return this.createError({ message: "Required" });
            } else if (Number(value) > Number(price)) {
              return this.createError({
                message: "Discount must be less than or equal to price",
              });
            }
          }
          return true;
        }
      ),
    inventory_count: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number")
      .test("isInventory", "Required", function (value) {
        if (this?.parent.isInventory) {
          return value;
        }
        return true;
      }),
    product_weight: Yup.string()
      .trim()
      .matches(numbRegex, "Enter a valid number"),
    variants_pictures: Yup.mixed(),
    // variants_pictures: Yup.mixed(),
  });

  const onSubmit = (values) => {
    onEditUpdate &&
      onEditUpdate(
        {
          // variants_pictures: images,
          variants_pictures: images,
          variant: data.variant,
          delete: data.delete,
          price: values.price,
          discounted_price: values.discount_price,
          sku: values.sku,
          bar_code: values.bar_code,
          sku_barcode_same_as_parent_value:
            values.sku_barcode_same_as_parent_value,
          inventory_count: values.inventory_count,
          max_weight: values?.product_weight,
          sku_barcode_same_as_parent_value:
            values?.sku_barcode_same_as_parent_value,
          actions: "",
        },
        data
      );
    modalClose();
  };
  function resetFn() {
    setImages(data?.variants_pictures);
    // setImages(data?.variants_pictures);
  }
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    const areisSkuAndBarcodeEqual = isEqual(
      data?.sku_barcode_same_as_parent_value,
      values?.sku_barcode_same_as_parent_value
    );
    if (areEqual && areisSkuAndBarcodeEqual && data?.sku === values?.sku) {
      return true;
    } else {
      return false;
    }
  }
  const handleSkubarcodeCheckedChange = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setSkubarcodeChecked(e.target.checked);
    setFieldValue("sku_barcode_same_as_parent_value", isChecked);
    setFieldValue(
      "sku", isChecked ? skuAndBarcode?.sku : ""
    );
    setFieldValue("bar_code", isChecked ? skuAndBarcode?.barcode : ""
    );
  };
  return (
    <Modal
      show={show}
      onHide={modalClose}
      centered
      dialogClassName={` variant_modal_container`}
      contentClassName="rounded-3 mx-3 mx-xl-0 edit_variant_content_class"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange, errors, touched, setFieldValue }) => {
          console.log(values, "modelval");
          return (
            <Form>
              <ModalHeader>
                <div className=" d-flex align-items-center gap-2">
                  <img
                    onClick={modalClose}
                    style={{ background: "#F7F7F7", borderRadius: "3px" }}
                    src={BackIcon}
                    alt="back"
                  />
                  <span className="text-black fw_500 fs_18">Edit Variant</span>
                  <span>{data?.variant?.map((item) => {
                    return (
                      <span className="fs_14 fw_400 primary_color border_1px_e5ecff border_radius_5px bg_color_F9F9FC p-1 px-3 me-3">{item?.value}</span>
                    )
                  })}</span>
                </div>
                <div className="d-sm-flex g-0 gap-sm-3 gap-2 text-center">
                  <div className="mb-2 mb-sm-0" onClick={resetFn}>
                    <BackButton name="UNDO" type="reset" disabled={valuesCompareFn(values)} />
                  </div>
                  <div className="mt-2 mt-sm-0">
                    <SaveButton type="submit" disabled={valuesCompareFn(values)} />
                  </div>
                </div>
              </ModalHeader>
              <ModalBody className="mx-4">
                <div className="d-lg-flex justify-content-between">
                  <div className="col-lg-6 col-12 pe-lg-4">
                    <InputField
                      isUnit={true}
                      id={"price"}
                      label={"Price"} 
                      placeholder={"Enter Price"}
                      name={"price"}
                    />
                    {Object.values(skuAndBarcode).every(
                      (value) => value !== ""
                    ) && (
                        <div className="d-flex align-items-center gap-1">
                          <input
                            className="form-check-input"
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "0rem",
                            }}
                            type="checkbox"
                            id="sku_barcode_same_as_parent_value"
                            name="sku_barcode_same_as_parent_value"
                            // checked={values.sku_barcode_same_as_parent_value || skubarcodeChecked}
                            checked={values.sku_barcode_same_as_parent_value}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   setSkubarcodeChecked(e.target.checked);
                            // }}
                            onChange={(e) => {
                              handleSkubarcodeCheckedChange(e, setFieldValue);
                            }}
                          />
                          <label className="fw_500 fs_14 ps-2">
                            SKU & Barcode same as parent value?
                          </label>
                        </div>
                      )}

                    <InputField
                      isDisabled={(skubarcodeChecked && skuAndBarcode?.sku) ? true : false}
                      id={"sku"}
                      label={"SKU"}
                      placeholder={"Enter SKU"}
                      name={"sku"}
                      containerClass={"mt-2"}
                    />
                    <InputField
                      isDisabled={(skubarcodeChecked && skuAndBarcode?.barcode) ? true : false}
                      id={"bar_code"}
                      label={"Barcode"}
                      placeholder={"Enter Barcode"}
                      name={"bar_code"}
                      containerClass={"mt-"}
                    />
                  </div>
                  <div className="col-lg-6 col-12 ps-lg-4">
                    <InputField
                      unitClassName="text-nowrap"
                      isUnit={true}
                      id={"product_weight"}
                      label={"Weight"}
                      placeholder={"Enter weight"}
                      name={"product_weight"}
                      unitPosition="right"
                      unitType="Kg"
                    />

                    <div className={!values.isDiscounted && `mb-3`}>
                      <input
                        className="form-check-input mb-2"
                        style={{ width: "15px", height: "15px" }}
                        type="checkbox"
                        id="isDiscounted"
                        name="isDiscounted"
                        checked={values.isDiscounted}
                        onChange={(e) => {
                          handleChange(e);
                          if (!e.target.checked) {
                            setFieldValue("discount_price", "");
                          }
                        }}
                      />{" "}
                      <label className="fw_500 fs_14 ps-2">
                        Discounted Price?
                      </label>
                      {values.isDiscounted && (
                        <InputField
                          isUnit={true}
                          id={"discount_price"}
                          label={"Discounted Price"}
                          placeholder={"Enter Discounted Price"}
                          name={"discount_price"}
                          containerClass={"mt-1"}
                        />
                      )}
                    </div>
                    <input
                      className="form-check-input mb-"
                      style={{ width: "15px", height: "15px" }}
                      type="checkbox"
                      id="isInventory"
                      name="isInventory"
                      checked={values.isInventory}
                      onChange={(e) => {
                        handleChange(e);
                        if (!e.target.checked) {
                          setFieldValue("inventory_count", "");
                        }
                      }}
                    />
                    <label className="fw_500 fs_14 ps-2">Inventory</label>
                    {values.isInventory && (
                      <InputField
                        id={"inventory_count"}
                        label={"Inventory Count"}
                        placeholder={"Enter Inventory Count"}
                        name={"inventory_count"}
                        containerClass={"mt-1"}
                      />
                    )}
                  </div>
                </div>
                <div className={`${!values.isInventory && "mt-2"}`}>
                  <MultiImageUpload
                    setFieldValue={setFieldValue}
                    images={images}
                    setImages={setImages}
                    model={show}
                    containerClass="ms-0"
                    isRequired={
                      errors?.variants_pictures && touched?.variants_pictures

                      // errors?.variants_pictures && touched?.variants_pictures
                    }
                  />
                  <ErrorMsg name="variants_pictures" />
                  {/* <ErrorMsg name="variants_pictures" /> */}
                </div>
              </ModalBody>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
