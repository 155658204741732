import React from "react";

const AdminUsersIcon = ({ fill = "#707070" }) => {
  return (
    <svg
    width="18"
    height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3537 17.9924C11.3177 17.9977 11.2817 17.9999 11.2465 17.9999C10.8798 17.9999 10.5588 17.7307 10.5056 17.3565C10.1914 15.1578 8.25365 13.5006 5.99871 13.5006C3.74378 13.5006 1.80604 15.1578 1.49184 17.3565C1.4326 17.7667 1.05015 18.0479 0.643705 17.9932C0.233512 17.9347 -0.051449 17.5545 0.00779284 17.1443C0.426985 14.2122 3.00288 12.0015 5.99946 12.0015C8.99605 12.0015 11.5719 14.2122 11.9911 17.1443C12.0504 17.5545 11.7654 17.9347 11.3552 17.9932L11.3537 17.9924ZM1.49859 6.00161V0.575358C1.49859 0.00993612 2.23198 -0.212783 2.54619 0.258152L3.74827 2.25213L5.47079 0.242404C5.74825 -0.0808013 6.24843 -0.0808013 6.52514 0.242404L8.24765 2.25213L9.44974 0.258152C9.76394 -0.212783 10.4973 0.00993612 10.4973 0.575358V6.00161C10.4973 8.48301 8.47937 10.501 5.99796 10.501C3.51656 10.501 1.49859 8.48301 1.49859 6.00161ZM2.99838 5.25171V6.00161C2.99838 7.65588 4.34369 9.00119 5.99796 9.00119C7.65223 9.00119 8.99755 7.65588 8.99755 6.00161V5.25171H2.99838Z"
        fill={fill}
      />
    </svg>
  );
};

export default AdminUsersIcon;
