import React from "react";

const UseDangerouslyInnerHtml = ({ description }) => {
  const createMarkup = () => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = description;
    const images = tempElement.getElementsByTagName("img");

    for (let i = 0; i < images.length; i++) {
      images[i].style.maxWidth = "100%";
    }
    return { __html: tempElement.innerHTML };
  };

  return <div dangerouslySetInnerHTML={createMarkup()} />;
};

export default UseDangerouslyInnerHtml;
