import { api } from "./api";

export const pluginListApi = (params) => {
  return api.get(`/core/plugin/new-plugins`, { params: params });
};
export const themeListApi = (params) => {
  return api.get(`/core/theme-plugin/list`, { params: params });
};
export const installedPluginListApi = (params) => {
  return api.get(`/core/plugin/installed-plugins`, { params: params });
};

export const pluginCreateApi = (data) => {
  return api.post(`/core/plugin/create`, JSON.stringify(data));
};
export const themeCreateApi = (data) => {
  return api.post(`/core/theme-plugin/create`, JSON.stringify(data));
};

export const pluginUpdateApi = (data) => {
  return api.post(`/core/plugin/update-plugin`, JSON.stringify(data));
};
export const pluginInstallApi = (data) => {
  return api.post(`/core/plugin/install-plugin`, JSON.stringify(data));
};
export const themeInstallApi = (data) => {
  return api.post(`/core/theme-plugin/install`, JSON.stringify(data));
};
export const themeUninstallApi = (data) => {
  console.log(data, "delte");
  return api.delete(`/core/theme-plugin/delete`, { params: data });
};
export const pluginUninstallApi = (data) => {
  return api.post(`/core/plugin/uninstall-plugin`, JSON.stringify(data));
};
export const pluginUploadApi = (data) => {
  return api.post(`/core/plugin/upload-zip-file`, data, {
    headers: {
      "Content-Type": "form-data",
    },
  });
};
export const themeUploadApi = (data) => {
  return api.post(`/core/theme-plugin/upload-zip-file`, data, {
    headers: {
      "Content-Type": "form-data",
    },
  });
};
export const pluginUploadImageApi = (data) => {
  return api.post(`/core/plugin/upload-image`, data, {
    headers: {
      "Content-Type": false,
    },
  });
};
export const themeUploadImageApi = (data) => {
  return api.post(`/core/theme-plugin/upload-image`, data, {
    headers: {
      "Content-Type": false,
    },
  });
};
