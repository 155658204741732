import { call, takeLatest } from "redux-saga/effects";
import * as adminRoleApis from "../apis/admin-roles-api";
import {
  ADMIN_ROLE_CREATE,
  ADMIN_ROLE_DELETE,
  ADMIN_ROLE_EDIT,
  ADMIN_ROLE_LIST,
  ADMIN_ROLE_MULTI_DELETE,
  ADMIN_ROLE_TABLE,
  ADMIN_ROLE_VIEW,
  LOGGED_ADMIN_PERMISSION,
} from "../types";

export function* adminRoleTableWorker(action) {
  try {
    const res = yield call(adminRoleApis?.adminRoleTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminRoleViewWorker(action) {
  try {
    const res = yield call(adminRoleApis.adminRoleViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminRoleDeleteWorker(action) {
  try {
    const res = yield call(adminRoleApis.adminRoleDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminRoleMultiDeleteWorker(action) {
  try {
    const res = yield call(adminRoleApis.adminRoleMultiDeleteApi, {
      data: action.data,
    });
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* adminRoleCreateWorker(action) {
  try {
    const res = yield call(adminRoleApis.adminRoleCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* adminRoleEditWorker(action) {
  try {
    const res = yield call(adminRoleApis.adminRoleEditApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminRoleListWorker(action) {
  try {
    const res = yield call(adminRoleApis.adminRoleListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* loggedAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.loggedAdminRoleApi);
    console.log(res);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
      // localStorage.setItem("permission",JSON.stringify(res?.data?.data?.permission));
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* watchadminRoleTableWorker() {
  yield takeLatest(ADMIN_ROLE_TABLE, adminRoleTableWorker);
}
export function* watchadminRoleViewWorker() {
  yield takeLatest(ADMIN_ROLE_VIEW, adminRoleViewWorker);
}
export function* watchadminRoleEditWorker() {
  yield takeLatest(ADMIN_ROLE_EDIT, adminRoleEditWorker);
}
export function* watchadminRoleDeleteWorker() {
  yield takeLatest(ADMIN_ROLE_DELETE, adminRoleDeleteWorker);
}
export function* watchadminRoleMultiDeleteWorker() {
  yield takeLatest(ADMIN_ROLE_MULTI_DELETE, adminRoleMultiDeleteWorker);
}
export function* watchcategorieCreateWorker() {
  yield takeLatest(ADMIN_ROLE_CREATE, adminRoleCreateWorker);
}
export function* watchAdminRoleListWorker() {
  yield takeLatest(ADMIN_ROLE_LIST, adminRoleListWorker);
}
export function* watchloggedAdminRoleWorker() {
  yield takeLatest(LOGGED_ADMIN_PERMISSION, loggedAdminRoleWorker);
}
