import { api } from "./api";
export const discountTableApi = (params) => {
    return api.post(
        `/core/discounts/list?page=${params?.page}&page_size=${params?.size}&search=${params?.search}&${params?.sort != null && `sort_by=${params?.sort?.sort_by}&sort_order=${params?.sort?.sort_order}`}`, params.filter,
    );
};
export const discountDeleteApi = (params) => {
    return api.delete(
        `/core/discounts/delete?discount_id=${params}`,
    );
};
export const discountMutliDeleteApi = (params) => {
    return api.delete(
        `/core/discounts/bulk-delete`, (params)
    );
};
export const discountViewApi = (params) => {
    return api.get(`/core/discounts/view?discount_id=${params}`);
};
export const discountEditApi = (params) => {
    return api.post(`/core/discounts/edit`, JSON.stringify(params));
};
export const discountCreateApi = (params) => {
    return api.post(`/core/discounts/create`, JSON.stringify(params));
};