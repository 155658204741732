import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { GoogleMap, useJsApiLoader, Marker, MarkerF, OverlayViewF } from '@react-google-maps/api';
const AddressLocation = ({ lat = 12.2958104, lng = 76.639380, setFieldValue = () => { }, viewPage = false }) => {
    const [address, setAddress] = useState('');
    const [marker, setMarker] = useState({
        lat: lat,
        lng: lng,
    });
    useEffect(() => {
        setFieldValue("lat", marker?.lat)
        setFieldValue("lng", marker?.lng)
    }, [marker])

    // PLACES AUTOCOMPLETE
    const handleChangeaddress = (newAddress) => {
        setAddress(newAddress);
    };
    const handleSelect = (selectedAddress) => {
        setAddress(selectedAddress)
        geocodeByAddress(selectedAddress)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setMarker(latLng);
                map.panTo(latLng);
                map.moveCamera({
                    zoom: 19
                });

            })
            .catch(error => console.error('Error', error));
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "YOUR_API_KEY"
    })

    const [map, setMap] = React.useState(null)
    const [zoom, setZoom] = React.useState(19)

    const onLoad = React.useCallback(function callback(map) {
        map.panTo(marker);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    return (
        <>
            {
                !viewPage &&
                <>
                    <label className={`d-flex fs_14 mb-1 ${address ? "color_9E9E9E" : "text-black"} fw_500 mb-2`}>Address</label>

                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChangeaddress}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className='position-relative mb-3 '>
                                <input
                                    style={{ width: "100%" }}
                                    {...getInputProps({
                                        placeholder: 'Choose a location on the map.',
                                        className: 'location-search-input inputField_border focus_outline__none',
                                    })}
                                />
                                <div style={{ zIndex: 999 }} className="autocomplete-dropdown-container position-absolute h-100">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </>
            }
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '30vh',
                    borderRadius: "7px"
                }}
                // center={marker}
                zoom={zoom}
                // onClick={onMapClick}
                onBoundsChanged={viewPage ? false : () => {
                    if (map?.getCenter()) {
                        var e = map.getCenter();
                        setMarker({
                            lat: e.lat(),
                            lng: e.lng(),
                        })
                    }

                }}
                // onDragEnd={() => {
                //     var e = map.getCenter();
                //     setMarker({
                //         lat: e.lat(),
                //         lng: e.lng(),
                //     })
                // }}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <MarkerF
                    position={{
                        lat: marker.lat,
                        lng: marker.lng
                    }}
                    icon={{
                        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                    }}
                />
            </GoogleMap>
        </>
    )
}

export default AddressLocation