import React from 'react'

const AdairLogo = ({
    height = "100px",
    width = "100px"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 616 186" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M260.828 47.2238V51.6415C254.044 47.8923 246.264 45.7627 237.988 45.7627C211.637 45.7627 190.275 67.3621 190.275 94.0064C190.275 120.651 211.637 142.25 237.988 142.25C249.09 142.25 259.315 138.411 267.419 131.981L273.996 140.141H290.412V47.2238H260.828ZM239.611 117.115C227.175 117.115 217.095 106.405 217.095 93.1931C217.095 79.9881 227.175 69.278 239.611 69.278C252.047 69.278 262.127 79.9881 262.127 93.1931C262.127 106.405 252.047 117.115 239.611 117.115Z" fill="#030304" />
            <path d="M471.016 47.2238V51.6415C464.231 47.8923 456.452 45.7627 448.175 45.7627C421.825 45.7627 400.463 67.3621 400.463 94.0064C400.463 120.651 421.825 142.25 448.175 142.25C459.278 142.25 469.503 138.411 477.607 131.981L484.184 140.141H500.599V47.2238H471.016ZM449.799 117.115C437.363 117.115 427.283 106.405 427.283 93.1931C427.283 79.9881 437.363 69.278 449.799 69.278C462.235 69.278 472.315 79.9881 472.315 93.1931C472.315 106.405 462.235 117.115 449.799 117.115Z" fill="#030304" />
            <path d="M365.518 15.0322V51.6492C358.733 47.8931 350.954 45.7634 342.677 45.7634C316.327 45.7634 294.965 67.3628 294.965 94.0071C294.965 120.651 316.327 142.251 342.677 142.251C353.786 142.251 364.005 138.412 372.109 131.982L378.679 140.142H395.101V15.0322H365.518ZM344.301 117.116C331.865 117.116 321.785 106.406 321.785 93.1938C321.785 79.9889 331.865 69.2788 344.301 69.2788C356.737 69.2788 366.816 79.9889 366.816 93.1938C366.816 106.406 356.737 117.116 344.301 117.116Z" fill="#030304" />
            <path d="M539.005 46.7344V142.25H509.988V46.7275C513.906 50.0219 518.963 52.0068 524.497 52.0068C530.024 52.0068 535.081 50.0219 539.005 46.7344Z" fill="#030304" />
            <path d="M524.495 46.0452C533.653 46.0452 541.076 38.6397 541.076 29.5046C541.076 20.3694 533.653 12.9639 524.495 12.9639C515.338 12.9639 507.914 20.3694 507.914 29.5046C507.914 38.6397 515.338 46.0452 524.495 46.0452Z" fill="#4C54FF" />
            <path d="M596.84 76.4117C581.233 75.7639 577.26 87.177 577.26 87.177L576.88 142.251H547.863V49.1678L567.906 49.1747L572.659 68.4929C576.936 61.5527 590.339 50.9529 590.339 50.9529C610.657 39.1125 615.369 57.2797 615.369 57.2797C620.088 78.1968 596.84 76.4117 596.84 76.4117Z" fill="#030304" />
            <path d="M155.082 36.4033L98.264 3.6803C89.7455 -1.22677 79.1543 -1.22677 70.6288 3.6803L13.8176 36.4033C5.29216 41.3104 0 50.4629 0 60.277V125.723C0 135.537 5.29216 144.69 13.8176 149.597L70.6288 182.32C74.8915 184.773 79.6724 186 84.4533 186C89.2273 186 94.0082 184.773 98.264 182.32L155.082 149.597C163.608 144.69 168.9 135.537 168.9 125.723V60.277C168.9 50.4629 163.608 41.3104 155.082 36.4033ZM20.7264 48.3402L77.5376 15.6172C79.6724 14.3904 82.0629 13.777 84.4464 13.777C86.8369 13.777 89.2273 14.3904 91.3552 15.6172L148.173 48.3402C148.194 48.3539 148.215 48.3677 148.235 48.3746L78.0281 89.299L62.2069 80.1879C60.8252 79.3953 59.098 80.3877 59.098 81.9797L59.0842 123.159C59.0842 125.227 56.3759 126.012 55.2636 124.269L45.7571 109.32C44.852 107.9 43.0488 107.335 41.4943 107.99L32.202 111.912C30.834 112.49 29.321 111.484 29.321 110.009V63.6403C29.321 62.1654 28.5334 60.8008 27.2484 60.0565L15.6761 53.3919C16.8644 51.3244 18.5916 49.5669 20.7264 48.3402ZM155.082 125.723C155.082 130.63 152.436 135.206 148.173 137.66L91.3552 170.383C89.2273 171.61 86.8438 172.223 84.4533 172.223V101.525L155.082 60.3528V125.723Z" fill="#4D54FF" />
        </svg>
    )
}

export default AdairLogo
