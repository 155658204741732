import React from "react";
import arrowRight from "../../../assets/icons/PaginationArrowRight.svg";
import arrowLeft from "../../../assets/icons/pagArrowLeft.svg";

export default function FrontEndPagination({
  pagination,
  setCurrentPage,
  totalPages,
  setRemoveSelectedItems,
}) {
  const handlePageChange = (value) => {
    if (value === "next") {
      setCurrentPage(pagination + 1);
    } else if (value === "prev") {
      setCurrentPage(pagination - 1);
    } else {
      setCurrentPage(value);
    }
  };

  const getPageNumbers = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }
    if (pagination <= 2) {
      return [1, 2, 3, 4, 5];
    }
    if (pagination >= totalPages - 1) {
      return [
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }
    const startPage = pagination - 2;
    const endPage = pagination + 2;
    console.log(startPage);
    console.log(endPage);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };
  const pageNumbers = getPageNumbers();
  return (
    <>
      {pagination && totalPages > 1 ? (
        <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
          <span className="color_707070 fw_500 fs_16">
            {pagination}/{totalPages}
          </span>
          <div className="me-3" style={{ width: "28px" }}>
            <button
              disabled={pagination === 1 ? true : false}
              onClick={() => {
                handlePageChange("prev");
                setRemoveSelectedItems([]);
              }}
              type="button"
              className="border-0 bg-transparent "
            >
              <img width={"28px"} src={arrowLeft} alt="arrow left" />
            </button>
          </div>
          {pageNumbers.map((pageNumber) => (
            <button
              onClick={() => {
                handlePageChange(pageNumber);
                setRemoveSelectedItems([]);
              }}
              key={pageNumber}
              className={`border-0  ${
                pagination === pageNumber
                  ? "bg_color_E5ECFFB2 primary_color"
                  : "bg-transparent color_030304"
              }   fw_600 fs_18 px-3 rounded-2 py-1`}
              type="button"
            >
              {pageNumber ?? pageNumber}
            </button>
          ))}

          <div style={{ width: "28px" }}>
            <button
              disabled={pagination === totalPages ? true : false}
              onClick={() => {
                handlePageChange("next");
                setRemoveSelectedItems([]);
              }}
              className="border-0 bg-transparent "
              type="button"
            >
              <img width={"28px"} src={arrowRight} alt="arrow left" />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
