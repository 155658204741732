import React, { useEffect, useState } from "react";
import DynamicTable from "../../../components/ui/tables/table";
import {
  adminUsersDeleteAction,
  adminUsersMultiDeleteAction,
  adminUsersTableAction,
} from "../../../redux/actions/admin-users-action";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import NotificationComponent from "../../../components/notificationComponent";

export default function AdminUsersTable() {
  const location = useLocation();
  const [adminUsersData, setAdminUsersData] = useState({
    admins: [],
    pagination: {},
  });
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      adminUsersTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig,
        },
        onSuccess,
        onError
      )
    );
  }, [reload, inputValue, currentPage, sortConfig]);

  const onSuccess = (data) => {
    setError(false);
    setAdminUsersData(data.data);
    setLoading(false);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };
  const tableData = adminUsersData?.admins
    ?.map((item) => ({
      id: item?.id,
      code: item?.code,
      name: item?.name,
      email_address: item?.email_address,
      role_name: item?.Role?.role_name,
      Action: ["/admin/users/view/", "/admin/users/edit/"],
    }))
    .filter((item) => item.email_address !== "ff_admin@gmail.com");
  return (
    <div className="col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-lg-4 mb-5 pt-5">
      <NotificationComponent />
      <DynamicTable
        placeholder="Search admin users here by id, name and email address"
        tabletitle="Admin Users"
        loading={loading}
        reload={reload}
        error={error}
        tableHeading={tableAdminRolesHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputValue={inputValue}
        setCurrentPage={setCurrentPage}
        createButtonName="CREATE ADMIN USER"
        createpath="/admin/users/create"
        pagination={adminUsersData?.pagination}
        singleDeleteAction={adminUsersDeleteAction}
        multiDeleteAction={adminUsersMultiDeleteAction}
        setLoading={setLoading}
        setReload={setReload}
        id_name={"admin_id"}
        rolePermission={"admin_users"}
        modalText={"admin user"}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
    </div>
  );
}
const tableAdminRolesHeader = [
  { label: "Admin User ID", value: "code" },
  { label: "Name", value: "name" },
  { label: "Email Address", value: "email_address" },
  { label: "Role", value: "role_name" },
  { label: "Actions", value: "Actions" },
];
