import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/ui/tables/table";
import { useDispatch } from "react-redux";
import {
  brandsDeleteAction,
  brandsMultiDeleteAction,
  brandsTableAction,
} from "../../redux/actions/brands-action";
import { removeUnderScore } from "../../components/helper-functions/helper/helper";
import { useLocation } from "react-router";
import NotificationComponent from "../../components/notificationComponent";

const BrandsTable = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });
  useEffect(() => {
    setLoading(true);
    dispatch(
      brandsTableAction(
        {
          page: currentPage,
          size: 10,
          search: inputValue,
          sort: sortConfig
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, inputValue, reload, sortConfig]);

  const onSuccess = (data) => {
    setLoading(false);
    setTags(data?.data?.brands);
    setPagination(data?.data?.pagination);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const tableData = tags?.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: removeUnderScore(item?.name),
    status: item?.is_active,
    Action: ["/brands/view/", "/brands/edit/"],
  }));

  console.log(tags,"kkhvjchck");

  return (
    <div className=" col-lg-11 col-12 px-lg-0 px-2 ps-lg-4 pt-4 mb-5 ">
      <NotificationComponent />
      <DynamicTable
        tabletitle="Brands"
        modalText={'brand'}
        placeholder={"Search brand here by id, name"}
        tableHeading={tableTagsHeader}
        tablebody={tableData}
        setInputValue={setInputValue}
        inputVal
        ue={inputValue}
        createButtonName="CREATE BRAND"
        createpath="/brands/create"
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        singleDeleteAction={brandsDeleteAction}
        multiDeleteAction={brandsMultiDeleteAction}
        setLoading={setLoading}
        loading={loading}
        setReload={setReload}
        reload={reload}
        id_name={"brand_id"}
        rolePermission={"brands"}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
    </div>
  );
};
export default BrandsTable;

const tableTagsHeader = [
  { label: "ID", value: "code" },
  { label: "Brand Name", value: "name" },
  { label: "Status", value: "is_active" },
  { label: "Actions", value: "Actions" },
];