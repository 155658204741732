import React, { useEffect, useState } from "react";
import usePermissions from "../../../helper-functions/hooks/usePermission-checker";
import CreateButton, {
  BackButton,
  SaveButton,
} from "../../buttons/text-button";
import ConfigSection from "../config-section";
import CreatePageModal from "../../modals/create-page-modal";
import ConfigSelectedPage from "./config-selected-page";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import { useDispatch } from "react-redux";
import {
  configPageCreateAction,
  configPageListAction,
} from "../../../../redux/actions/configuration-action";
import errorToast from "../../../helper-functions/helper/error-toast";
import ConfigSectionSkeleton from "../config-section-loader";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";
export default function ConfigPages({ name, closeSection }) {
  // const { canWrite } = usePermissions();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [pageData, setPageData] = useState([])
  const mainPageList = [
    {
      main_page_type: "AboutUs",
      page_name: "About Us",
      page_description: "",
    },
    {
      main_page_type: "TermsAndCondition",
      page_name: "Terms & Condition",
      page_description: "",
    },
    {
      main_page_type: "PrivacyPolicy",
      page_name: "Privacy Policy",
      page_description: "",
    },
  ];



  const [show, setShow] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  function onFetchSuccess(data) {
    setPageData(data?.data)
    console.log(data, "rterterterteer");
    setLoading(false);
    const mainPageData = data.data.filter((item) => item.main_page_type);
    console.log(mainPageData, "rterterterteer");
    const updatedMainPageData = mainPageList.map((item) => {
      const isMainPageData = mainPageData.find(
        (mainItem) => mainItem.main_page_type === item.main_page_type
      );
      if (isMainPageData) {
        return {
          ...item,
          page_description: isMainPageData?.page_description,
          page_data: isMainPageData.page_data,
        };
      }
      return item;
    });
    console.log(updatedMainPageData, "rterterterteer");
    console.log(updatedMainPageData, "aaaaaaaaaaa");
    setPages([
      ...updatedMainPageData,
      ...data.data.filter((item) => !item.main_page_type),
    ]);
    setLoading(false);
  }
  function onFetchError(data) {
    errorToast("something went Wrong");
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    dispatch(configPageListAction("", onFetchSuccess, onFetchError));
  }, [selectedPage]);

  return (
    <>
      <CreatePageModal
        pageData={pages}
        show={show}
        modalClose={() => setShow(false)}
        onSave={(values, setLoading) => {
          setLoading(true);
          const newItem = {
            page_name: values.name,
            page_description: values.description,
            slug: "",
            page_data: {
              title: "",
              content: "",
            },
          };
          dispatch(
            configPageCreateAction(
              newItem,
              (data) => {
                setSelectedPage(data.data);
                setShow(false);
                setLoading(false);
              },
              (data) => {
                console.log(data);
                errorToast(data.data?.message);
                setLoading(false);
              }
            )
          );
        }}
      />
      {!selectedPage && (
        <>
          <div
            style={{ padding: ".95rem 1rem" }}
            className="col-12  d-flex align-items-center justify-content-between px-md-5   border_bottom_1px_e5ecff"
          >
            <div className="d-flex gap-2">
              <img
                className="d-inline-block d-md-none"
                onClick={closeSection}
                src={backIcon}
              />
              <h1 className="fs_22 fw_600 font_montserrat mb-0 ">{"Pages"}</h1>
            </div>
            <CreateButton
              createFn={() => {
                setShow(true);
              }}
              createClassName={"px-lg-3 px-3 fs_15 fs_md_12 fw_500 text-nowrap"}
              name={"CREATE PAGE"}
            />
          </div>
          <div className="mt-4 pt-2 px-sm-5 px-2">
            {loading
              ? [1, 2, 3].map(() => <ConfigSectionSkeleton />)
              : pages.map((item) => {
                return (
                  <ConfigSection
                    onClick={() => {
                      setSelectedPage(item);
                    }}
                    item={{
                      title: item.page_name,
                      subHeading: item.page_description,
                    }}
                  />
                );
              })}
            { }
          </div>
        </>
      )}
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          {selectedPage && (
            <ConfigSelectedPage
              onBack={() => setSelectedPage(null)}
              data={selectedPage}
            />
          )}
        </>
      )}
    </>
  );
}
