import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  deliverypartnerEditAction,
  deliverypartnerDeleteAction,
  deliverypartnerViewAction,
  deliveryBlockAction,
} from "../../redux/actions/delivery-partner-action";
import LoadingSpinner from "../../components/helper-functions/helper/loadingSpinner";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import UplaodImgLogo from "../../assets/icons/Upload_image_logo.svg";
import { imageRegex } from "../../components/helper-functions/helper/regex";
import errorToast from "../../components/helper-functions/helper/error-toast";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import DeleteModal from "../../components/ui/modals/delete-modal";
import DynamicTable from "../../components/ui/tables/table";
import { formatDateWithDay } from "../../components/helper-functions/helper/date";
import NotificationComponent from "../../components/notificationComponent";
const DeliveryPartnerView = () => {
  const params = useParams();
  const [tagsDetails, setTagsDetails] = useState();
  const [isImgLoading, setIsImgLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [pagination, setPagination] = useState();
  const [sortConfig, setSortConfig] = useState({ sort_by: "", sort_order: "" });


  // const currentPage = searchParams.get("currentPage");
  const [currentPage, setCurrentPage] = useState(
    parseInt(location?.state?.page) ? parseInt(location?.state?.page) : 1
  );
  const [reload, setReload] = useState(false);
  const [isCustomerBlock, isSetCustomerBlock] = useState();
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    dispatch(deliverypartnerViewAction(
      {
        id: params.id,
        page: currentPage,
        size: 10,
        search: inputValue,
        sort: sortConfig,
      },
      onSuccess, onError));
  }, [inputValue, reload, currentPage, sortConfig,]);
  const onSuccess = (data) => {
    setLoading(false);
    setTagsDetails(data?.data);
    setPagination(data?.data?.pagination);
    isSetCustomerBlock(data?.data?.status);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const [edited, setEdited] = useState(false);
  const [blockUnBlockShow, setBlockUnBlock] = useState(false);
  const tagsFindOne = [
    {
      label: "Image",
      value: (
        <>
          {isImgLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "250px", height: "250px" }}
            >
              <LoadingSpinner size="lg" />
            </div>
          )}
          <div
            className={`${imageRegex.test(tagsDetails?.photo) === false &&
              "d-flex justify-content-center align-items-center border_radius_10px border_1px_A0A0BF"
              }`}
            style={{ width: "250px", height: !isImgLoading && "250px" }}
          >
            <img
              className="rounded-3 mt-1"
              src={
                imageRegex.test(tagsDetails?.photo)
                  ? tagsDetails?.photo
                  : UplaodImgLogo
              }
              width={
                imageRegex.test(tagsDetails?.photo) ? "250px" : "100"
              }
              height={
                imageRegex.test(tagsDetails?.photo) ? "250px" : "100"
              }
              alt="image"
              onLoad={() => setIsImgLoading(false)}
              style={{ display: isImgLoading ? "none" : "block" }}
            />
          </div>
        </>
      ),
    },
    {
      label: "Delivery Partner Name",
      value: tagsDetails?.name,
    },
    {
      label: "Phone Number",
      value: tagsDetails?.mobile,
    },
    {
      label: "Vehicle Name",
      value: tagsDetails?.vehicle_name,
    },
    {
      label: "Vehicle Number",
      value: tagsDetails?.vehicle_number,
    },
  ];


  function blockUnblockFn() {
    dispatch(
      deliveryBlockAction(params.id, blockUnblockOnSuccess, blockUnblockOnError)
    );
  }
  const blockUnblockOnSuccess = (data) => {
    setBlockUnBlock(false);
    successToast(data.message);
    setReload(!reload);
    setLoading(false);
  };
  const blockUnblockOnError = (data) => {
    setReload(false);
    errorToast(data.data.message);
    setBlockUnBlock(false);
    setLoading(false);
  };

  const tableData = tagsDetails?.Orders?.map((item) => ({
    id: item?.id,
    code: item?.code,
    no_of_items: item?.cart?.length ?? 0,
    order_date: formatDateWithDay(item?.createdAt),
    orders_status: Array.isArray(item?.orders_status)
      ? item?.orders_status
      : [item?.orders_status],
    Action: ["/orders/status/", "/orders/edit/"],
  }));
  return (
    <>
    <NotificationComponent />
      <DeleteModal
        show={blockUnBlockShow}
        modalClose={() => setBlockUnBlock(false)}
        deleteData={params.id}
        deleteFn={() => blockUnblockFn(params.id)}
        type={isCustomerBlock === true ? "block" : "Unblock"}
        text={`Are you sure, you want to ${isCustomerBlock === true ? "block" : "unblock"
          } this Delivery partner permanently?`}
      />
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <>
          <InnerLayout
            Unblock={isCustomerBlock === false ? true : false}
            block={isCustomerBlock === true ? true : false}
            name={`${tagsDetails?.code ?? "Delivery Partner View"}`}
            id={tagsDetails?.id}
            deleteAction={deliverypartnerDeleteAction}
            path={`/delivery-partners`}
            editButtonPath={`/delivery-partner/edit/${tagsDetails?.id}`}
            rolePermission={"deliveryPartner"}
            type="deliverypartner"
            blockUnblockShowFn={() => setBlockUnBlock(true)}
          />

          <div className="m-sm-5 m-4 mt-5 col-md-6 col-xl-5">
            {tagsFindOne.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <label className="color_707070 fs_14">{item.label}</label>
                  <div className="color_030304 fs_14 mb-1">{item.value}</div>
                </div>
              );
            })}
          </div>
          <div className="ms-md-5 ms-lg-4 mx-4 col-lg-11 px-lg-0 ps-lg-4 mb-5">
            <DynamicTable
              placeholder={"Search order here by id, name "}
              modalText={"order"}
              tabletitle={"Delivered Orders"}
              tableHeading={tableAttributeHeader}
              tablebody={tableData}
              setInputValue={setInputValue}
              inputValue={inputValue}
              createButtonName="CREATE ORDER"
              createpath="/orders/create"
              pagination={pagination}
              setCurrentPage={setCurrentPage}
              loading={loading}
              setLoading={setLoading}
              id_name={"order_id"}
              setReload={setReload}
              reload={reload}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryPartnerView;
const tableAttributeHeader = [
  { label: "ID", value: "code" },
  { label: "No. of Items", value: "getItems" },
  { label: "Order Date", value: "order_date" },
  { label: "Order Status", value: "orders_status" },
  { label: "Actions", value: "Actions" },
];