import React, { useEffect, useState } from "react";
import CreateButton, {
  AddWithBorderButton,
  ApplyButton,
  BackButton,
  SaveButton,
  UnblockButton,
} from "../../buttons/text-button";
import usePermissions from "../../../helper-functions/hooks/usePermission-checker";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../forms/input-field";
import { passwordRegex } from "../../../helper-functions/helper/regex";
import isEqual from "lodash.isequal";
import {
  mailNotifyCreateAction,
  mailNotifyViewAction,
} from "../../../../redux/actions/configuration-action";
import { useDispatch } from "react-redux";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import RadioButton from "../../forms/radio-button";
import { emailInboxOptions } from "../../../helper-functions/helper/data";
const MailNotification = ({ connected = false, closeSection }) => {
  const dispatch = useDispatch();
  const { canWrite } = usePermissions();
  const [mailNotifyData, setMailNotifyData] = useState();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const initialValues = {
    adair_mail: mailNotifyData?.adair_mail === true ? "adair" : "custom",
    emailAddress: mailNotifyData?.email_address ?? "",
    smpt: mailNotifyData?.smtp_host ?? "",
    userName: mailNotifyData?.user_name ?? "",
    password: mailNotifyData?.password ?? "",
    port: mailNotifyData?.port ?? "",
  };
  const validationSchema = Yup.object().shape({
    adair_mail: Yup.string(),
    emailAddress: Yup.string().test('custom-email', 'Invalid email', function (value) {
      return this.parent.adair_mail === 'custom' ? !!value : true;
    }),
    smpt: Yup.string().test('custom-smpt', 'SMPT is required', function (value) {
      return this.parent.adair_mail === 'custom' ? !!value : true;
    }),
    userName: Yup.string().trim().test('custom-username', 'User Name is required', function (value) {
      return this.parent.adair_mail === 'custom' ? !!value : true;
    }),
    password: Yup.string().trim().test('custom-password', 'Password is required', function (value) {
      return this.parent.adair_mail === 'custom' ? !!value : true;
    }),
    port: Yup.number().typeError('Invalid SMPT number').test('custom-port', 'Port number is required', function (value) {
      return this.parent.adair_mail === 'custom' ? !!value : true;
    }),
  });


  useEffect(() => {
    setLoading(true);
    dispatch(mailNotifyViewAction(onFetchSuccess, onFetchError));
  }, [reload]);
  const onFetchSuccess = (data) => {
    setLoading(false);
    setMailNotifyData(data?.data);
  };
  const onFetchError = (data) => {
    setLoading(false);
  };

  const onSubmit = (values, action) => {
    console.log(values);
    dispatch(
      mailNotifyCreateAction(
        {
          adair_mail: values?.adair_mail === "adair" ? true : false,
          email_address: values?.emailAddress,
          password: values?.password,
          smtp_host: values?.smpt,
          user_name: values?.userName,
          port: values?.port,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  console.log(loading, "mailload");
  const onError = (data) => {
    errorToast(data?.data?.message);
  };
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }
  console.log(mailNotifyData, "mailNotifyData");
  return (
    <div className="">
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, isValid, setFieldValue }) => {
            return (
              <Form className="">
                <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                  <div
                    className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat mb-0 px-xxl-4 me-2 d-flex align-items-center"
                    style={{ width: "fit-Content" }}
                  >
                    <img
                      className="d-inline-block d-md-none me-2"
                      onClick={closeSection}
                      src={backIcon}
                    />
                    Mail Notification
                  </div>
                  <div
                    className="me-xxl-5 pe-xxl-5"
                    style={{ width: "fit-Content" }}
                  >
                    {canWrite("configuration") && (
                      <div className="d-flex gap-3">
                        <BackButton
                          disabled={valuesCompareFn(values)}
                          type="reset"
                          name="UNDO"
                          backClassName="fs_14"
                        />
                        {
                          values?.adair_mail === "adair" &&
                          <SaveButton
                            disabled={valuesCompareFn(values)}
                            type="submit"
                            backClassName="fs_14"
                          //   onClick={saveFn}
                          />
                        }
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="mt-4 ms-xl-5 me-xl-5 ms-4 me-4"
                  style={{ maxWidth: "40rem" }}
                >
                  <RadioButton
                    inputLabelClass="fs_16"
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("adair_mail", value);
                    }}
                    labelClass="color_9E9E9E d-flex fs_14 mb-1"
                    name="adair_mail"
                    options={emailInboxOptions}
                    label="Email Inbox"
                  />
                  {
                    values?.adair_mail != "adair" &&

                    <>
                      <InputField
                        placeholder="Enter email address"
                        containerClass="mb-1 position-relative mt-3"
                        errMsgContainerClass="ps-2"
                        label="Email Address"
                        name="emailAddress"
                        type="text"
                      />
                      <InputField
                        placeholder="Enter SMTP host"
                        containerClass="mb-1 position-relative"
                        errMsgContainerClass="ps-2"
                        label="SMTP Host"
                        name="smpt"
                        type="text"
                      />
                      <InputField
                        placeholder="Enter user name"
                        containerClass="mb-1 position-relative"
                        errMsgContainerClass="ps-2"
                        label="User Name"
                        name="userName"
                        type="text"
                      />
                      <InputField
                        placeholder="Enter password"
                        containerClass="mb-1 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Password"
                        name="password"
                        type="password"
                        isPasswordEye={true}
                      />
                      <InputField
                        placeholder="Enter Port"
                        containerClass="mb-1 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Port"
                        name="port"
                        type="text"
                      />
                      {connected ? (
                        <ApplyButton
                          type="button"
                          name="CONNECTED"
                          backgroundColor="#3AD64C"
                          applyClassName="w-100"
                        />
                      ) : (
                        <CreateButton
                          type="submit"
                          // disabled={
                          //   Object.values(values).some((val) => val !== "")
                          //     ? !isValid
                          //     : true
                          // }
                          disabled={valuesCompareFn(values)}
                          name="VERIFY & CONNECT"
                          createClassName="w-100 fw_500 mt-2"
                        />
                      )}
                    </>
                  }      
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default MailNotification;
