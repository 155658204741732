import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import DiscountedProductsList from "../discounts/discounted-products-list";
import "./modal.css";
import { productsTableAction } from "../../../redux/actions/products-action";
import { useDispatch } from "react-redux";
import CreateButton from "../buttons/text-button";

export default function DiscountsAddProductsModal({
  show,
  modalClose,
  addedProductsList,
  setAddedProductsList,
  selectedProductList,
  setSelectedProductList,
  setEmptyProductsError,
  orderCreate = false
}) {
  // console.log(selectedProductList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState("");
  console.log(inputValue);
  const [productsData, setProductsData] = useState({
    products: [],
    pagination: {},
  });
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const filteredIds = addedProductsList?.map((item) => item?.id).filter(Boolean);

  console.log(filteredIds, "filteredIds");
  useEffect(() => {
    setLoading(true);
    dispatch(
      productsTableAction(
        {
          page: currentPage,
          size: 8,
          search: inputValue,
          sort: { sort_by: "", sort_order: "" },
          filter: {
            filteredValue: "",
          },
        },
        {
          product_id: [],
          "category": [],
          out_of_stock: false
        },
        onSuccess,
        onError
      )
    );
  }, [inputValue, currentPage, addedProductsList]);

  const onSuccess = (data) => {
    console.log(data, 'alkdja');
    setLoading(false);
    setError(false);
    setProductsData(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
  };

  function handleAddProduct() {
    if (addedProductsList?.length === 0) {
      setEmptyProductsError(false);
    }
    setAddedProductsList((prevAddedProductsList) => [
      ...new Set([...selectedProductList, ...prevAddedProductsList]),
    ]);

    setSelectedProductList([]);
    setInputValue("");
    setCurrentPage(1);
  }
  console.log(addedProductsList, "test");
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName=" discounts_add_products_modal"
        contentClassName="rounded-3"
        backdrop="static"
      >
        <ModalHeader>
          <div className="fs_18 fw_500 d-flex align-items-center gap-2">
            <img
              onClick={() => {
                modalClose();
                setInputValue("");
                setCurrentPage(1);
              }}
              style={{ background: "#F7F7F7", borderRadius: "3px" }}
              src={BackIcon}
              alt="back"
            />
            <span>Add Products</span>
          </div>
          <div>
            <CreateButton
              name="ADD PRODUCTS"
              disabled={selectedProductList?.length === 0}
              type="button"
              createFn={() => {
                handleAddProduct();
                modalClose();
              }}
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <DiscountedProductsList
            productList={productsData?.products?.filter((item) => item?.out_of_stock != true)}
            tableDiscountHeader={tableDiscountHeader}
            setCurrentPage={setCurrentPage}
            pagination={productsData?.pagination}
            addedProductsList={addedProductsList}
            setAddedProductsList={setAddedProductsList}
            setSelectedProductList={setSelectedProductList}
            selectedProductList={selectedProductList}
            action={false}
            page={"modal"}
            searchBar={true}
            inputValue={inputValue}
            setInputValue={setInputValue}
            placeholder={"Search product here"}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
const tableDiscountHeader = [
  { label: "Thumbnail", value: "thumbnail" },
  { label: "ID", value: "id" },
  { label: "Product Name", value: "product_name" },
];
