import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, Formik, } from "formik";
import * as Yup from "yup";
import usePermissions from "../../../helper-functions/hooks/usePermission-checker";
import { BackButton, SaveButton } from "../../buttons/text-button";
import isEqual from "lodash.isequal";
import { useDispatch } from "react-redux";
import backIcon from "../../../../assets/icons/back-arrow-button.svg";
import successToast from "../../../helper-functions/helper/sucess-toast";
import errorToast from "../../../helper-functions/helper/error-toast";
import LoadingSpinnerTable from "../../../helper-functions/helper/loadingSpinner-table";
import { SketchPicker } from 'react-color'; // Import the color picker component
import { removeUnderScore } from "../../../helper-functions/helper/helper";
import { colorCreateAction, colorViewAction } from "../../../../redux/actions/interface-action";

const GlobalColors = ({ closeSection }) => {
  const dispatch = useDispatch();
  const { canWrite } = usePermissions();
  const [colorData, setColorData] = useState();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const colorPickerRef = useRef(null); // Ref for the color picker
  const [openColorPicker, setOpenColorPicker] = useState(null); // State to track the open color picker
  useEffect(() => {
    setLoading(true);
    dispatch(colorViewAction(onFetchSuccess, onFetchError));
  }, [reload]);
  const onFetchSuccess = (data) => {
    setLoading(false);
    setColorData(data?.data);
  };
  const onFetchError = (data) => {
    setLoading(false);
  };
  const initialValues = {
    primaryColor: colorData?.primary_colour ?? '#09B44C',
    secondaryColor: colorData?.secondary_colour.slice(0, -2) ?? '#09B44C15',
    accentColor: colorData?.accent ?? '#161617',
    headlineColor: colorData?.head_line ?? '#09B44C',
    bodyColor: colorData?.body ?? '#09B44C',
    darkNeutralColor: colorData?.dark_neutral ?? '#09B44C',
    lightNeutralColor: colorData?.light_neutral ?? '#09B44C',
    whiteColor: colorData?.white ?? '#FFFFFF',
  };


  const colorFieldNames = [
    {
      name: "primaryColor",
      label: "Primary Color",
      description: "Your main brand color. Used by most elements throughout the website.",
    },
    {
      name: "secondaryColor",
      label: "Secondary Color",
      description: "Your secondary brand color. Used mainly as a hover color or by secondary elements.",
    },
    {
      name: "accentColor",
      label: "Accent Color",
      description: "Usually a contrasting color used to draw attention to key pieces of your website.",
    },
    {
      name: "headlineColor",
      label: "Headline Color",
      description: "A dark, contrasting color, used by all headlines in your website.",
    },
    {
      name: "bodyColor",
      label: "Body Color",
      description: "A neutral grey, easy to read color, used by all text elements.",
    },
    {
      name: "darkNeutralColor",
      label: "Dark Neutral Color",
      description: "Generally used as a background color for footer, copyright, and dark sections.",
    },
    {
      name: "lightNeutralColor",
      label: "Light Neutral Color",
      description: "Generally used as a background color for light, alternating sections.",
    },
    {
      name: "whiteColor",
      label: "White Color",
      description: "Generally used as a background for white sections.",
    },
  ];
  const validationSchema = Yup.object().shape(
    {
      // primaryColor: Yup.string().required("Required"),
      // secondaryColor: Yup.string().required("Required"),
      // accentColor: Yup.string().required("Required"),
      // headlineColor: Yup.string().required("Required"),
      // bodyColor: Yup.string().required("Required"),
      // darkNeutralColor: Yup.string().required("Required"),
      // lightNeutralColor: Yup.string().required("Required"),
      // whiteColor: Yup.string().required("Required"),
    }
  );
  const onSubmit = (values, action) => {
    dispatch(
      colorCreateAction(
        {
          "primary_colour": values?.primaryColor,
          "secondary_colour": values?.secondaryColor + "15",
          "accent": values?.accentColor,
          "head_line": values?.headlineColor,
          "body": values?.bodyColor,
          "dark_neutral": values?.darkNeutralColor,
          "light_neutral": values?.lightNeutralColor,
          "white": values?.whiteColor,
        },
        onCreateSuccess,
        onCreateError
      )
    );
  };
  const onCreateSuccess = (data) => {
    setReload(!reload)
    successToast(data?.message);
  };
  const onCreateError = (data) => {
    errorToast(data?.data?.message);
  };


  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (e) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(e.target)
    ) {
      setOpenColorPicker(null);
    }
  };
  return (
    <div>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange }) => {
            console.log(values);
            return (
              <Form className="">
                <div className="row g-0 justify-content-between align-items-center border_bottem_1px p-3">
                  <div
                    className="mb-0 px-xxl-4 d-flex align-items-center"
                    style={{ width: "fit-Content" }}
                  >
                    <span>
                      <img
                        className="d-inline-block d-md-none"
                        onClick={closeSection}
                        src={backIcon}
                      />
                    </span>
                    <span className="fs_xs_16 fs_lg_18 fs_20 fw_600 font_montserrat ms-2">
                      Global Colors
                    </span>
                  </div>
                  <div
                    className="me-xxl-5 pe-xxl-5"
                    style={{ width: "fit-Content" }}
                  >
                    {canWrite("configuration") && (
                      <div className="d-flex gap-3">
                        <BackButton
                          disabled={valuesCompareFn(values)}
                          type="reset"
                          name="UNDO"
                          backClassName="fs_14"
                        />

                        <SaveButton
                          disabled={valuesCompareFn(values)}
                          type="submit"
                          backClassName="fs_14"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="mt-5 ms-xl-5 me-xl-5 ms-4 me-4"
                  style={{ maxWidth: "40rem" }}
                >
                  {colorFieldNames.map((colorField) => (
                    <div className="d-flex justify-content-between mt-5  align-items-center" key={colorField.name}>
                      <div className="w-75">
                        <label className="color_030304 fs_16 fs_sm_14 fw_600" htmlFor={colorField.name}>{colorField.label}</label>
                        <p className="mt-2 color_707070 fw_400 fs_14 fs_sm_12">{colorField.description}</p>
                      </div>
                      <div className="bg_color_E5ECFF80 py-2 px-2 d-flex gap-3 align-items-center rounded-3 position-relative">
                        <span>{values[colorField.name]}</span>
                        <div

                          ref={colorField.name === openColorPicker ? colorPickerRef : null}

                          onClick={() => {
                            if (openColorPicker === colorField.name) {
                              setOpenColorPicker(null);
                            } else {
                              setOpenColorPicker(colorField.name);
                            }
                          }}
                          className="rounded-2 position-relative" style={{ height: "2rem", width: "2rem", backgroundColor: values[colorField.name] }}>

                        </div>
                        {openColorPicker === colorField.name && (
                          <div
                            onClick={(e) => {
                              // Prevent the click event from propagating to the parent
                              e.stopPropagation();
                            }}
                            style={{ zIndex: 999, right: "0px", top: "60px" }}
                            className="position-absolute">
                            <SketchPicker
                              color={values[colorField.name]}
                              onChange={(color) => {
                                handleChange({
                                  target: { name: colorField.name, value: color.hex },
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default GlobalColors;
