import React from 'react';

const CustomBar = (props) => {
    const { x, y, width, height } = props;
    const borderRadius = 5;
    const gradientId = `custom-bar-gradient-${Math.random().toString(36).substr(2, 5)}`;
  
    const barHeight = height - borderRadius >= 0 ? height - borderRadius : 0;
  
    return (
      <g>
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#FF6C1A" />
            <stop offset="100%" stopColor="#FFCCAF" />
          </linearGradient>
        </defs>
        <rect x={x} y={y} width={width} height={height} rx={borderRadius} fill={`url(#${gradientId})`} />
        <rect x={x} y={y + borderRadius} width={width} height={barHeight} fill={`url(#${gradientId})`} />
        
      </g>
    );
  };
  
  export default CustomBar;