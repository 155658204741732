import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AddButton, SaveButton } from "../buttons/text-button";
import BackIcon from "../../../assets/icons/back-arrow-button.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { passwordRegex } from "../../helper-functions/helper/regex";
import InputField from "../forms/input-field";

const ResetPassModal = ({
  show,
  modalClose,
  resetFn = () => {},
  firstPlaceholder = "Enter password",
  secondPlaceholder = "Re-enter new password",
}) => {
  const [password, setPassword] = useState("");

  const generatePassword = () => {
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    const passwordLength = 8;
    let password = "";

    for (let i = 0; i < passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    const conditions = [
      /[a-z]/.test(password),
      /[A-Z]/.test(password),
      /[0-9]/.test(password),
      /[!@#$%^&*]/.test(password),
    ];

    if (conditions.every(Boolean)) {
      setPassword(password);
    } else {
      generatePassword();
    }
  };

  const formRef = useRef();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        passwordRegex,
        "Password must be at least 8 characters, include 1 uppercase, 1 number, and 1 special character (!@#$%^&*)"
      )
      .trim()
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Required")
      .test("password-match", "Your password didn't match", function (value) {
        return this.parent.password === value;
      }),
  });
  const onSubmit = (values) => {
    resetFn(values);
    setPassword("");
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Modal
      show={show}
      onHide={modalClose}
      centered
      dialogClassName="mx-sm-auto"
      contentClassName="rounded-3"
    >
      <ModalHeader>
        <div className="text-black fw_500 fs_18 d-flex align-items-center gap-2">
          <img
            onClick={modalClose}
            style={{ background: "#F7F7F7", borderRadius: "3px" }}
            src={BackIcon}
            alt="back"
          />
          <span>Reset Password</span>
        </div>
        <div onClick={handleSubmit}>
          <SaveButton />
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik
          innerRef={formRef}
          initialValues={{
            password: password ? password : "",
            confirmPassword: password ? password : "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          <Form className="mx-sm-4 mx-2 px-sm-1">
            <AddButton
              name={"GENERATE PASSWORD"}
              addSymbol={false}
              AddFn={() => {
                generatePassword();
              }}
            />

            <InputField
              inputClass="py-2 w-100"
              placeholder={firstPlaceholder}
              containerClass="position-relative mt-3"
              errMsgContainerClass="ps-2"
              label="New Password"
              name="password"
              type="password"
              isPasswordEye={true}
            />
            <InputField
              inputClass="py-2 w-100"
              placeholder={secondPlaceholder}
              containerClass="mb-3 position-relative"
              errMsgContainerClass="ps-2"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              isPasswordEye={true}
            />
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ResetPassModal;
