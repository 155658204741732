import { all } from "redux-saga/effects";
import {
  watchAdminUsersCreateWorker,
  watchAdminUsersTableWorker,
  watchAdminUsersViewWorker,
  watchAdminUsersResetPasswordWorker,
  watchAdminUsersImageUploadWorker,
  watchAdminUsersDeleteWorker,
  watchAdminUsersMultiDeleteWorker,
  watchAdminUsersEditWorker,
  watchAdminUsersSendPasswordWorker,
  watchadminUserDetailsWorker,
  watchLoggedAdminUserEditWorker,
} from "../admin-users-saga";
import {
  watchAdminRoleListWorker,
  watchadminRoleDeleteWorker,
  watchadminRoleEditWorker,
  watchadminRoleMultiDeleteWorker,
  watchadminRoleTableWorker,
  watchadminRoleViewWorker,
  watchcategorieCreateWorker,
  watchloggedAdminRoleWorker,
} from "../admin-roles-saga";

export default function* adminRootSaga() {
  yield all([
    watchAdminUsersTableWorker(),
    watchAdminUsersCreateWorker(),
    watchAdminUsersViewWorker(),
    watchadminRoleTableWorker(),
    watchadminRoleViewWorker(),
    watchadminRoleEditWorker(),
    watchadminRoleDeleteWorker(),
    watchadminRoleMultiDeleteWorker(),
    watchAdminRoleListWorker(),
    watchcategorieCreateWorker(),
    watchAdminUsersResetPasswordWorker(),
    watchAdminUsersImageUploadWorker(),
    watchAdminUsersDeleteWorker(),
    watchAdminUsersMultiDeleteWorker(),
    watchAdminUsersEditWorker(),
    watchAdminUsersSendPasswordWorker(),
    watchadminUserDetailsWorker(),
    watchloggedAdminRoleWorker(),
    watchLoggedAdminUserEditWorker()
  ]);
}
