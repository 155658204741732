import axios from "axios";
import { url } from "../config";
import store from "../store";
import { SERVER_ERROR, SERVER_WORKING } from "../types";

export const api = axios.create({
  baseURL: url,
  timeout: 60000,
  maxContentLength: 52428800,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem("accessToken")) {
      if (!excludeAccessToken(config)) {
        config.headers["x-access-token"] = localStorage.getItem("accessToken");
      }
    }
    return config;
  },
  async (error) => {}
);
api.interceptors.response.use(
  async (config) => {
    return config;
  },
  async (error) => {
    if (error.message === "Network Error") {
      // store.dispatch({
      //   type: SERVER_ERROR,
      //   data: error
      // });
      return;
    } else if (
      error.response.data.message === "unable to find access-token" ||
      error.response.data.message === "access-token is not valid" ||
      error.response.data.message ===
        "unable to find user with this access-token" ||
      error.response.data.message.includes("authentication failed")
    ) {
      localStorage.clear();
      window.location.reload();
    }
    if (store.getState().errorReducer.status) {
      store.dispatch({
        type: SERVER_WORKING,
        data: error,
      });
    }
    return { data: error.response };
  }
);

function excludeAccessToken(config) {
  return (
    config.url.includes("/core/auth/login") ||
    config.url.includes("/core/auth/forgot-password") ||
    config.url.includes("/core/auth/reset-password") ||
    config.url.includes("/configuration/site-settings/logo-title")
  );
}
// api.interceptors.response.use(
//     async (config) => {
//       // if (config.data.message == "Authentication Failed")
//       //   store.dispatch({
//       //     type: ADMIN_LOGOUT,
//       //     onSuccess: () => {},
//       //     onError: () => {},
//       //   });
//       // localStorage.clear();
//       // window.location.reload();
//       console.log(config);
//       return config;
//     },
//     async (error) => {
//       console.log(error.response);
//       const status = error.response.status;
//       if (status === 401) {
//         localStorage.clear();
//         window.location.reload();
//         toast.error("Unauthorized or session expired", {
//           position: "bottom-right",
//           theme: "light",
//         });
//       }
//       return error.response;
//       // const status
//     }
//   );
