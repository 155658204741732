import L from "leaflet";

const Red_MARKER = `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z" fill="#2820DA"></path></svg>
`)}`;
export const BoatIcon = L.icon({
    iconUrl: Red_MARKER,
    iconSize: [40, 40],
    iconAnchor: [12, 12],
    popupAnchor: [0, 0],
});






const BIKE_MARKER = `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
<svg width="500" height="500" viewBox="0 0 74 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.2205 24.6932C27.3266 25.0267 27.3901 25.3726 27.4067 25.7285C27.5559 28.918 23.8826 31.6813 19.2029 31.9001C14.5228 32.1189 10.6082 29.7104 10.4591 26.5209C10.4425 26.1655 10.4734 25.8151 10.5479 25.4728L27.2205 24.6932Z" fill="#FF772B"/>
<path d="M26.8605 16.9928C26.9351 16.6508 26.966 16.3005 26.9493 15.9446C26.8002 12.7551 22.8852 10.3466 18.2055 10.5654C13.5253 10.7842 9.85257 13.5475 10.0017 16.737C10.0183 17.0924 10.0818 17.4383 10.1879 17.7723L26.8605 16.9928Z" fill="#FF772B"/>
<path d="M58.2502 25.719L57.5098 30.3535L56.3862 30.174L57.1267 25.5395L58.2502 25.719Z" fill="#4F555C"/>
<path d="M55.7408 37.2514C56.2018 37.3322 56.6405 37.0247 56.7208 36.5642L57.7745 30.5645C57.944 30.6184 58.153 30.7192 58.3 30.907C58.4824 31.141 58.5365 31.4696 58.461 31.8789L57.6347 35.8428C57.6109 35.9567 57.6839 36.0693 57.7987 36.0926C57.9127 36.1168 58.0248 36.0433 58.049 35.9285L58.876 31.96C58.9758 31.4195 58.8932 30.9762 58.6302 30.6423C58.3927 30.3409 58.0605 30.2009 57.8196 30.1362C57.7863 29.7647 57.508 29.447 57.1232 29.379C56.8957 29.3392 56.6731 29.3942 56.4968 29.5157C56.3166 29.6396 56.1842 29.8337 56.1432 30.0662L55.0537 36.2719C54.9733 36.7319 55.2808 37.1706 55.7408 37.2514Z" fill="#2A363E"/>
<path d="M57.6595 13.0485L56.5576 13.332L55.388 8.78682L56.4899 8.50328L57.6595 13.0485Z" fill="#4F555C"/>
<path d="M54.0827 1.79716C54.5341 1.6737 54.9995 1.93897 55.1225 2.38993L56.7314 8.26468C56.8951 8.19516 57.0937 8.07533 57.2226 7.87461C57.3824 7.62469 57.4056 7.29244 57.2922 6.89198L56.0996 3.02249C56.0653 2.91128 56.1275 2.79237 56.2397 2.75847C56.3509 2.72371 56.4693 2.7864 56.5041 2.8985L57.6979 6.77248C57.8477 7.30134 57.8068 7.75041 57.576 8.10737C57.3678 8.42962 57.05 8.60005 56.8162 8.68695C56.8177 9.05989 56.5703 9.40216 56.1935 9.50575C55.9707 9.56666 55.744 9.53268 55.5571 9.42815C55.3661 9.32154 55.2162 9.14068 55.1537 8.91297L53.4899 2.83746C53.3669 2.3865 53.6322 1.92057 54.0827 1.79716Z" fill="#2A363E"/>
<path d="M59.8851 17.7472L71.1218 17.2218C72.0537 17.1782 72.8471 17.9008 72.8907 18.8331C72.9343 19.765 72.2117 20.5589 71.2799 20.6024L60.0427 21.1278C59.1109 21.1714 58.3174 20.4488 58.2738 19.5165C58.2302 18.5842 58.9532 17.7907 59.8851 17.7472Z" fill="#072129"/>
<path d="M55.6891 17.1324L66.9263 16.607C68.3012 16.5427 69.479 17.6149 69.5433 18.9902C69.6075 20.365 68.5354 21.5428 67.1601 21.6071L55.9229 22.1325C54.5481 22.1968 53.3703 21.1246 53.306 19.7493C53.2421 18.3745 54.3143 17.1966 55.6891 17.1324Z" fill="#FF8A4D"/>
<path d="M55.7264 17.9425L66.9636 17.4171C67.8955 17.3736 68.6889 18.0961 68.7325 19.0284C68.7761 19.9603 68.0535 20.7542 67.1217 20.7978L55.8845 21.3231C54.9526 21.3667 54.1592 20.6441 54.1156 19.7118C54.072 18.7795 54.7946 17.9861 55.7264 17.9425Z" fill="#E6333D"/>
<path d="M56.4982 13.3285L57.1006 26.2129L33.6645 27.3086L33.0621 14.4243L56.4982 13.3285Z" fill="#323D44"/>
<path d="M6.51837 18.8581L14.4166 18.4888C16.1061 18.4098 17.5528 19.7273 17.6318 21.4168C17.7108 23.1062 16.3933 24.5529 14.7039 24.6319L6.80558 25.0012C5.11617 25.0802 3.66943 23.7627 3.59044 22.0732C3.51146 20.3838 4.82896 18.9371 6.51837 18.8581Z" fill="#C9171E"/>
<path d="M6.56481 19.8522L14.4631 19.483C15.6078 19.4294 16.5828 20.3173 16.6363 21.4625C16.6898 22.6072 15.802 23.5826 14.6573 23.6361L6.75899 24.0054C5.61425 24.0589 4.63931 23.1711 4.58577 22.0259C4.53179 20.8812 5.41962 19.9058 6.56481 19.8522Z" fill="#D41920"/>
<path d="M4.78159 20.1212C5.05438 20.1085 5.28768 20.3214 5.30041 20.5937L5.43093 23.3852C5.44368 23.658 5.23124 23.8913 4.95845 23.904C4.68566 23.9168 4.45235 23.7039 4.43962 23.4316L4.3091 20.6401C4.29637 20.3677 4.50879 20.134 4.78159 20.1212Z" fill="#072129"/>
<path d="M54.5982 19.5398C54.4171 15.6662 53.9022 12.0966 52.7536 9.21766C57.8218 10.6142 59.3308 14.5876 59.5516 19.3082C59.7722 24.0283 58.6405 28.1256 53.7247 29.9885C54.5997 27.0154 54.7793 23.4138 54.5982 19.5398Z" fill="#E6333D"/>
<path d="M59.5518 19.3093C59.7584 23.7294 58.7791 27.6035 54.6169 29.6082C55.4073 26.7031 55.5669 23.2294 55.3927 19.5038C55.2185 15.7787 54.7355 12.335 53.6775 9.5159C58.0086 11.1239 59.3451 14.8893 59.5518 19.3093Z" fill="#FF8A4D"/>
<path d="M56.7021 19.4429C56.8022 21.5839 58.619 23.238 60.7596 23.1379C62.9002 23.0379 63.6288 21.2643 63.5287 19.1237C63.4286 16.9831 62.5382 15.2853 60.3972 15.3854C58.2561 15.4855 56.602 17.3018 56.7021 19.4429Z" fill="#FF8A4D"/>
<path d="M60.8427 22.7052C60.2992 21.9773 60.2496 20.8301 60.1978 19.6348L60.1649 18.9295C60.1049 17.7341 60.0472 16.5873 60.5204 15.8119C60.4863 15.8125 60.4518 15.8137 60.4168 15.8153C58.5159 15.9042 57.0409 17.5234 57.1298 19.4248C57.2187 21.3262 58.8379 22.8008 60.7393 22.7119C60.7742 22.7098 60.8082 22.7077 60.8427 22.7052Z" fill="#E6333D"/>
<path d="M63.529 19.1243C63.6104 20.8655 63.1441 22.3634 61.8153 22.9163C60.6736 22.4794 60.6923 21.0019 60.6109 19.2607C60.5295 17.5196 60.373 16.0508 61.469 15.5087C62.8436 15.9353 63.4476 17.3832 63.529 19.1243Z" fill="#F16A23"/>
<path d="M61.8163 22.4421C62.9721 21.8381 63.1563 20.3297 63.1009 19.1437C63.0454 17.9572 62.7213 16.473 61.5141 15.9795C60.9297 16.4008 60.9464 17.3803 61.0217 18.8847L61.055 19.596C61.1199 21.1009 61.1951 22.0772 61.8163 22.4421Z" fill="#D9D7D8"/>
<path d="M7.89385 20.5884L8.00577 22.9823C8.14045 25.8627 10.6073 28.1092 13.4877 27.9745L28.509 27.2722C31.3895 27.1375 33.6359 24.6707 33.5012 21.7903L33.3893 19.3964C33.2546 16.516 30.7878 14.2695 27.9074 14.4042L12.8861 15.1065C10.0061 15.2412 7.75918 17.708 7.89385 20.5884Z" fill="#323D44"/>
<path d="M9.44091 21.2813L9.48149 22.1491C9.61616 25.0296 12.083 27.276 14.9634 27.1414L27.7883 26.5417C30.6688 26.4071 32.9152 23.9402 32.7805 21.0598L32.74 20.1919C32.6053 17.3115 30.1385 15.0651 27.258 15.1997L14.4331 15.7993C11.5527 15.9336 9.30622 18.4004 9.44091 21.2813Z" fill="url(#paint0_linear_3730_82321)"/>
<path d="M22.7286 16.1374L25.7066 15.9982C28.5871 15.8635 31.0478 17.9792 31.175 20.6999C31.3022 23.4206 29.0496 25.7567 26.1692 25.8913L23.1911 26.0306L22.7286 16.1374Z" fill="#323D44"/>
<path d="M41.2582 13.2307C41.2582 13.2307 41.5497 12.0508 41.152 10.9594C40.8321 10.081 39.6333 9.55158 38.0544 9.93109L28.6117 13.2179C28.6117 13.2179 24.1766 14.8268 24.4698 21.096C24.7629 27.3653 29.3287 28.5533 29.3287 28.5533L39.0368 30.9448C40.6443 31.1753 41.7885 30.5359 42.025 29.6319C42.3191 28.5082 41.9188 27.3606 41.9188 27.3606L41.2582 13.2307Z" fill="#161A1E"/>
<path d="M27.8542 15.3334L38.218 13.1753C41.3227 13.0302 42.6846 16.1812 42.8679 20.102C43.0512 24.0228 41.9893 27.2871 38.8846 27.4323L28.3646 26.2506C28.3646 26.2506 26.4547 25.0084 26.2549 20.7354C26.0551 16.4624 27.8542 15.3334 27.8542 15.3334Z" fill="#E6333D"/>
<path d="M52.753 35.4405L54.3424 35.1969L56.0371 35.6404C56.0371 35.6404 56.9819 35.9797 57.2255 35.2555L57.8131 32.1297C57.8131 32.1297 57.9031 31.5942 57.166 31.4627L55.7341 31.1343C55.7341 31.1343 55.7935 30.6143 55.1146 30.571C54.4357 30.5281 54.2685 31.6327 54.2685 31.6327L54.0898 32.4512C54.0898 32.4512 53.7613 33.0334 52.3073 33.2428L52.753 35.4405Z" fill="#FCC587"/>
<path d="M41.4501 24.4693C41.4501 24.4693 42.9272 33.3047 52.808 32.8414L53.418 35.7879C53.418 35.7879 44.3646 37.1555 36.7607 26.6696L41.4501 24.4693Z" fill="#E6333D"/>
<path d="M52.808 32.8413L53.418 35.7878C53.418 35.7878 52.9434 35.8523 52.1238 35.8547L51.4609 32.8475C51.8953 32.8649 52.3442 32.8635 52.808 32.8413Z" fill="#F58828"/>
<path d="M51.2777 3.90725L52.8829 4.00142L54.5288 3.4018C54.5288 3.4018 55.4378 2.97583 55.7479 3.67414L56.6246 6.73141C56.6246 6.73141 56.7642 7.25619 56.0426 7.45597L54.6476 7.91649C54.6476 7.91649 54.7552 8.42867 54.0833 8.53514C53.4114 8.64161 53.1419 7.55702 53.1419 7.55702L52.8876 6.75875C52.8876 6.75875 52.5062 6.20979 51.039 6.13692L51.2777 3.90725Z" fill="#FCC587"/>
<path d="M41.0482 15.8833C41.0482 15.8833 41.6944 6.94856 51.5754 6.48794L51.9078 3.49742C51.9078 3.49742 42.7662 2.98048 36.174 14.1301L41.0482 15.8833Z" fill="#E6333D"/>
<path d="M51.575 6.48873L51.9074 3.49821C51.9074 3.49821 51.4288 3.47827 50.6126 3.55237L50.2332 6.60832C50.6641 6.55088 51.1111 6.51042 51.575 6.48873Z" fill="#F58828"/>
<path d="M39.5164 20.2591C39.3853 17.4543 42.084 15.0493 45.5441 14.8875C49.0043 14.7258 51.9156 16.8684 52.0467 19.6733C52.1779 22.4782 49.4792 24.8831 46.019 25.0449C42.5589 25.2067 39.6476 23.064 39.5164 20.2591Z" fill="#F05A28"/>
<path d="M36.9423 20.3791C36.799 17.3145 39.4521 14.7007 42.8681 14.541C46.2842 14.3813 49.1695 16.7361 49.3128 19.8007C49.4561 22.8653 46.803 25.4791 43.387 25.6388C39.971 25.7985 37.0856 23.4437 36.9423 20.3791Z" fill="#E6333D"/>
<path d="M41.0349 20.1882C40.949 18.3522 42.5385 16.7863 44.5851 16.6906C46.6316 16.5949 48.3603 18.0057 48.4461 19.8417C48.532 21.6777 46.9425 23.2436 44.8959 23.3393C42.8494 23.435 41.1207 22.0242 41.0349 20.1882Z" fill="#EE3B24"/>
<path d="M38.7425 20.2959C38.8729 23.086 41.2767 25.2881 44.2844 25.5391C43.992 25.5914 43.6925 25.6255 43.3866 25.6398C39.9705 25.7995 37.0852 23.4448 36.9419 20.38C36.7986 17.3153 39.4517 14.7017 42.8677 14.5419C43.1736 14.5276 43.475 14.5336 43.771 14.5584C40.7999 15.089 38.612 17.5053 38.7425 20.2959Z" fill="#FF8A4D"/>
<path d="M20.7361 14.1739L21.3843 28.0386C21.4555 29.5601 20.2686 30.8629 18.7472 30.934L4.79252 31.5865C3.27105 31.6576 1.96782 30.4712 1.89668 28.9498L1.24844 15.085C1.17731 13.5635 2.36416 12.2607 3.88563 12.1896L17.8403 11.5371C19.3617 11.466 20.665 12.6524 20.7361 14.1739Z" fill="#FFC520"/>
<path d="M19.7474 14.9484L20.3276 27.358C20.3913 28.7197 19.329 29.8861 17.9673 29.9497L5.47743 30.5337C4.11573 30.5974 2.94931 29.5352 2.88565 28.1735L2.30544 15.7639C2.24178 14.4022 3.304 13.2358 4.6657 13.1721L17.1556 12.5881C18.5178 12.5249 19.6837 13.5867 19.7474 14.9484Z" fill="#E6333D"/>
<path d="M8.5993 15.9127L9.14177 27.5152C9.2013 28.7884 8.2083 29.8788 6.93512 29.9383L5.92474 29.9855C4.65156 30.0451 3.56116 29.0521 3.50163 27.7789L2.95918 16.1768C2.89965 14.9036 3.89264 13.8132 5.16583 13.7537L6.17621 13.7065C7.44939 13.6469 8.53977 14.6395 8.5993 15.9127Z" fill="white"/>
<path d="M6.27285 26.5616C5.93787 26.5773 5.68764 26.6736 5.52212 26.8501C5.3566 27.0266 5.28163 27.2812 5.29718 27.6139L5.3249 28.2067L7.36426 28.1113L7.33654 27.5185C7.32099 27.1858 7.22349 26.9393 7.04404 26.7789C6.86505 26.6185 6.60782 26.5459 6.27285 26.5616ZM6.28501 26.8217C6.56325 26.8087 6.76504 26.8634 6.88995 26.9858C7.01488 27.1087 7.08294 27.2898 7.09416 27.5299L7.10972 27.8626L5.55466 27.9353L5.53911 27.6026C5.52788 27.3625 5.57965 27.1758 5.69484 27.0417C5.81007 26.9085 6.00677 26.8347 6.28501 26.8217Z" fill="#EC1C24"/>
<path d="M7.27441 26.1328L7.21614 24.8864L6.97421 24.8977L7.02032 25.884L6.39712 25.9132L6.35385 24.9877L6.11192 24.999L6.1552 25.9245L5.46209 25.9569L5.41513 24.9524L5.1732 24.9637L5.23233 26.2283L7.27441 26.1328Z" fill="#EC1C24"/>
<path d="M7.19434 24.4805L7.18218 24.2204L5.38474 24.3044L5.33975 23.3422L5.09782 23.3535L5.15497 24.5758L7.19434 24.4805Z" fill="#EC1C24"/>
<path d="M5.06957 22.7552L5.08173 23.0153L7.12109 22.9199L7.10893 22.6598L5.06957 22.7552Z" fill="#EC1C24"/>
<path d="M7.02554 20.8777L7.01296 20.6086L5.00825 21.4451L5.02071 21.7116L7.09473 22.3574L7.08214 22.0883L5.82689 21.7043L5.29606 21.5686L5.29576 21.5622L5.81161 21.3775L7.02554 20.8777Z" fill="#EC1C24"/>
<path d="M7.00293 20.3105L6.94465 19.0641L6.70272 19.0755L6.74884 20.0618L6.12564 20.0909L6.08234 19.165L5.84042 19.1763L5.88371 20.1022L5.19061 20.1346L5.14364 19.1302L4.90172 19.1415L4.96084 20.406L7.00293 20.3105Z" fill="#EC1C24"/>
<path d="M6.24579 17.2844C6.17498 17.2877 6.10875 17.3013 6.0462 17.3256C5.98365 17.3499 5.927 17.3834 5.87669 17.4263C5.82592 17.4691 5.78282 17.5203 5.74737 17.5793C5.71194 17.6387 5.68547 17.705 5.66889 17.7785L4.817 17.3334L4.83031 17.618L5.65414 18.0467L5.67336 18.4579L4.87133 18.4954L4.88349 18.7555L6.92285 18.6602L6.88877 17.9312C6.88376 17.8241 6.86297 17.7295 6.82684 17.6475C6.7907 17.5655 6.74384 17.4972 6.68668 17.4421C6.62951 17.387 6.563 17.346 6.48671 17.3195C6.41083 17.2921 6.33022 17.2804 6.24579 17.2844ZM6.25795 17.5445C6.37097 17.5392 6.46204 17.5704 6.53206 17.6376C6.60209 17.7053 6.64004 17.8068 6.64635 17.9416L6.66827 18.4105L5.9148 18.4457L5.89373 17.995C5.8874 17.8597 5.91695 17.7524 5.98329 17.6728C6.04915 17.5929 6.14085 17.5499 6.25795 17.5445Z" fill="#EC1C24"/>
<path d="M5.63359 16.2252L4.76528 16.2657L4.77744 16.5258L5.64575 16.4852L6.84863 17.1113L6.83577 16.8363L5.86405 16.3481L5.86377 16.3422L6.78571 15.7655L6.77285 15.4905L5.63359 16.2252Z" fill="#EC1C24"/>
<defs>
<linearGradient id="paint0_linear_3730_82321" x1="28.9553" y1="20.8035" x2="9.88144" y2="21.6953" gradientUnits="userSpaceOnUse">
<stop stop-color="#7B7E83"/>
<stop offset="0.2079" stop-color="#73777C"/>
<stop offset="0.5308" stop-color="#5D6369"/>
<stop offset="0.9265" stop-color="#39444A"/>
<stop offset="1" stop-color="#323D44"/>
</linearGradient>
</defs>
</svg>`)}`;
export const BikeIcon = L.icon({
    iconUrl: BIKE_MARKER,
    iconSize: [60, 60],
    iconAnchor: [12, 12],
    popupAnchor: [0, 0],
});



const USER_MARKER = `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
<svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="5.5" fill="white" stroke="#E6333D" stroke-width="3"/>
</svg>`)}`;
export const UserIcon = L.icon({
    iconUrl: USER_MARKER,
    iconSize: [40, 40],
    iconAnchor: [12, 12],
    popupAnchor: [0, 0],
});