import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/ui/layouts/inner-layout";
import ImageUpload from "../../components/ui/forms/image-upload";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../components/ui/forms/input-field";
import { useDispatch } from "react-redux";
import {
  deliverypartnerEditAction,
  deliverypartnerViewAction,
} from "../../redux/actions/delivery-partner-action";
import errorToast from "../../components/helper-functions/helper/error-toast";
import successToast from "../../components/helper-functions/helper/sucess-toast";
import { categoriesImgUploadAction } from "../../redux/actions/categories-action";
import isEqual from "lodash.isequal";
import Compressor from "compressorjs";
import LoadingSpinnerTable from "../../components/helper-functions/helper/loadingSpinner-table";
import CountryCode from "../../components/ui/forms/country-code";
import NotificationComponent from "../../components/notificationComponent";

const DeliveryPartnerEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [tagsEditDetails, setTagsEditDetails] = useState();
  const [imgUrl, setImageUrl] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [imageSize, setImageSize] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");

  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setImageUrl(tagsEditDetails?.photo);
  }, [tagsEditDetails]);
  

  const getImgUrl = (img) => {
    if (img.size <= 10485760) {
      setImgLoading(true);
      new Compressor(img, {
        quality: 0.6,
        success(result) {
          dispatch(categoriesImgUploadAction(result, onImgSuccess, onImgError));
        }
      })
    }
    else {
      setImageSize(true)
    }
    // dispatch(categoriesImgUploadAction(img, onImgSuccess, onImgError));
  };
  const onImgSuccess = (url) => {
    setImgLoading(false);
    setImageUrl(url);
  };
  const onImgError = (data) => {
    setImgLoading(false);
    errorToast(data.data.message ?? "Failed to upload image");
  };

  useEffect(() => {
    setLoading(true);
    dispatch(deliverypartnerViewAction(
      {
        id: params.id,
        page: 1,
        size: 10,
        search: "",
      }, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setTagsEditDetails(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data?.message);
  };
  const initialValues = {
    photo: tagsEditDetails?.photo ?? "",
    name: tagsEditDetails?.name ?? "",
    vehicle_number: tagsEditDetails?.vehicle_number ?? "",
    vehicle_name: tagsEditDetails?.vehicle_name ?? "",
    phone_number: tagsEditDetails?.mobile ?? "",
  };
  const validationSchema = Yup.object().shape({
    photo: Yup.mixed().required("Required"),
    name: Yup.string().trim().required("Delivery Partner Name is required"),
    vehicle_number: Yup.string().trim().required("Delivery Partner Name is required"),
    vehicle_name: Yup.string().trim().required("Delivery Partner Name is required"),
    phone_number: Yup.number().required("Enter phone number"),
  });
  const onSubmit = (values) => {
    dispatch(
      deliverypartnerEditAction(
        {
          driver_id: params.id,
          "name": values?.name,
          "mobile": values?.phone_number,
          "vehicle_number": values?.vehicle_number,
          "vehicle_name": values?.vehicle_name,
          "photo": imgUrl
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };
  const onSuccessEdit = (data) => {
    successToast(data.messsage);
    setEdited(!edited);
    if (currentPage) {
      navigate("/delivery-partners", { state: { page: currentPage } });
    } else {
      navigate(-1);
    }
  };
  const onErrorEdit = (data) => {
    errorToast(data?.message);
  };
  function resetFn(type) {
    if (type === "clear") {
      setImageUrl("");
    } else {
      setImageUrl(tagsEditDetails?.photo);
    }
  }
  function valuesCompareFn(values) {
    const areEqual = isEqual(initialValues, values);
    if (areEqual && tagsEditDetails?.photo === imgUrl) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
    <NotificationComponent />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              {loading ? <LoadingSpinnerTable /> :
                <>
                  <InnerLayout
                    name={`${tagsEditDetails?.code ?? "Delivery Partner Edit"}`}
                    page="edit"
                    edit={true}
                    rolePermission={"brands"}
                    resetFn={resetFn}
                    path={"/delivery-partners"}
                    disabled={valuesCompareFn(values)}
                  />
                  <span>
                    <ImageUpload
                      page={"site"}
                      labelClass="color_030304"
                      name="photo"
                      getImgUrl={getImgUrl}
                      resetFn={resetFn}
                      data={imgUrl}
                      loading={loading}
                      setFieldValue={setFieldValue}
                      error={errors?.photo ? true : false}
                    />
                    {imageSize && <label className="fs_14 ms-5 text-danger mb-3">"Please upload below 10mb"</label>}
                  </span>
                  <div
                    className="ms-md-5 ms-4  pe-4 pe-md-0"
                    style={{ maxWidth: "40rem" }}
                  >
                    <InputField
                      placeholder="Enter your delivery partner name"
                      containerClass="mb-3 position-relative"
                      errMsgContainerClass="ps-2"
                      label="Delivery Partner Name"
                      name="name"
                      type="text"
                    />
                    <label className="color_030304 fw_500 fs_14">
                      Phone Number
                    </label>
                    <div className="d-flex">
                      <span className="">
                        <CountryCode />
                      </span>
                      <span className="ms-2 w-100">
                        <InputField
                          placeholder="Enter phone number"
                          containerClass=" position-relative"
                          errMsgContainerClass="ps-2"
                          label=""
                          name="phone_number"
                          type="text"
                        />
                      </span>
                    </div>
                    <div>
                      <InputField
                        placeholder="Enter vehicle name"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Vehicle Name"
                        name="vehicle_name"
                        type="text"
                      />
                    </div>
                    <div>
                      <InputField
                        placeholder="Enter vehicle number"
                        containerClass="mb-3 position-relative"
                        errMsgContainerClass="ps-2"
                        label="Vehicle Number"
                        name="vehicle_number"
                        type="text"
                      />
                    </div>
                  </div>
                </>
              }
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default DeliveryPartnerEdit;
