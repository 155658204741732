import { COLOR_CREATE, COLOR_VIEW } from "../types";

export const colorCreateAction = (data, onSuccess, onError) => {
  return {
    type: COLOR_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const colorViewAction = (onSuccess, onError) => {
  return {
    type: COLOR_VIEW,
    onSuccess,
    onError,
  };
};
