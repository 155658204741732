import React from "react";

const CloseIcon = ({ fill = "#2820DA", width = "6", height = "6" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78026 1.2804L4.06056 3.00009L5.78026 4.71979C6.0735 5.01303 6.0735 5.48702 5.78026 5.78026C5.63401 5.9265 5.44202 6 5.25002 6C5.05803 6 4.86604 5.9265 4.71979 5.78026L3.00009 4.06056L1.2804 5.78026C1.13415 5.9265 0.942158 6 0.750164 6C0.55817 6 0.366176 5.9265 0.219931 5.78026C-0.0733102 5.48702 -0.0733102 5.01303 0.219931 4.71979L1.93963 3.00009L0.219931 1.2804C-0.0733102 0.987157 -0.0733102 0.513171 0.219931 0.219931C0.513171 -0.0733102 0.987157 -0.0733102 1.2804 0.219931L3.00009 1.93963L4.71979 0.219931C5.01303 -0.0733102 5.48702 -0.0733102 5.78026 0.219931C6.0735 0.513171 6.0735 0.987157 5.78026 1.2804Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
