import React from "react";

const ConfigSectionSkeleton = () => {
    return (
        <div style={{ minWidth: "320px" }} className="border_1px_e5ecff col-lg-6 rounded-2 d-flex justify-content-between px-3 align-items-center py-2 mt-2">
            <div className="w-100 py-1">
                <div style={{ background: "#ccc", height: "15px", width: "30%", marginBottom: "10px" }} className="skeleton-box"></div>
                <div style={{ background: "#ccc", height: "10px", width: "70%" }} className="skeleton-box"></div>
            </div>
            <svg className="skeleton-svg" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.34509 14L7.3544 8.44667C7.76787 8.06329 8 7.5444 8 7.0035C8 6.4626 7.76787 5.94371 7.3544 5.56033L1.33814 0L0 1.23725L6.01563 6.797C6.07488 6.8517 6.10816 6.92587 6.10816 7.00321C6.10816 7.08055 6.07488 7.15472 6.01563 7.20942L0.00695312 12.7628L1.34509 14Z" fill="#ccc" />
            </svg>

            <style jsx>{`
        .skeleton-box, .skeleton-svg {
          animation: pulse 2s infinite ease-in-out;
        }

        @keyframes pulse {
          0% {
            background-color: #ccc;
            fill: #ccc;
          }
          50% {
            background-color: #e2e2e2;
            fill: #e2e2e2;
          }
          100% {
            background-color: #ccc;
            fill: #ccc;
          }
        }
      `}</style>
        </div>
    );
};

export default ConfigSectionSkeleton;
