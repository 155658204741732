import React from "react";

const BlockIcon = ({ fill = "#F24444", width = "14", height = "14" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9692 2.20267C10.6724 0.864337 8.88523 0 7 0C3.14014 0 0 3.14014 0 7C0 10.7875 3.18639 14 7 14C10.8599 14 13.9999 10.8599 14 7C14 5.19487 13.2087 3.48213 11.9692 2.20267ZM7 2.43424C7.81765 2.43424 8.58506 2.65132 9.24958 3.02927L3.02927 9.24969C2.65132 8.58517 2.43424 7.81776 2.43424 7.00011C2.43413 4.48245 4.48234 2.43424 7 2.43424ZM7 11.5659C6.18236 11.5659 5.41494 11.3488 4.75042 10.9708L10.9707 4.75042C11.3487 5.41494 11.5658 6.18235 11.5658 7C11.5659 9.51766 9.51766 11.5659 7 11.5659Z"
        fill={fill}
      />
    </svg>
  );
};

export default BlockIcon;
