// Loading.js
import React from 'react';
import PropTypes from 'prop-types';
import './installingLoader.css';

const InstallLoading = ({ label }) => {
    return (
        <div className="loading-container">
            <div className="loading-bar">
                <div className="loading-progress"></div>
            </div>
            <p className="loading-label">{label}...</p>
        </div>
    );
};

InstallLoading.propTypes = {
    label: PropTypes.string.isRequired,
};


export default InstallLoading;
