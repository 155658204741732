import {
  PRODUCT_ENQUIRY_TABLE,
  PRODUCT_ENQUIRY_VIEW,
} from "../types";

export const productEnquiryTableAction = (data, onSuccess, onError) => {
  console.log(data, "fsdfsdhfsdfsdfgsd");
  return {
    type: PRODUCT_ENQUIRY_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const productEnquiryViewAction = (id, onSuccess, onError) => {
  return {
    type: PRODUCT_ENQUIRY_VIEW,
    id,
    onSuccess,
    onError,
  };
};
