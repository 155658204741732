import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import {
  CancelButton,
  DeleteButton,
  UnblockButton,
} from "../../ui/buttons/text-button";
import DeleteIcon from "../../../assets/icons/modals/delete-modal-icon.svg";
import UnBlockIcon from "../../../assets/icons/modals/unblock-modal-icon.svg";
import BlockIcon from "../../../assets/icons/modals/block-modal-icon.svg";
import LogOutIcon from "../../../assets/images/logout 1.png";
import WarningIcon from "../../../assets/icons/warning-icon.svg";

import "./modal.css";

const DeleteModal = ({
  show,
  modalClose,
  text = "Are you sure, you want to delete this category permanently?",
  type = "delete",
  deleteFn,
  green_bg,
}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={modalClose}
        centered
        dialogClassName="delete_modal mx-auto"
        contentClassName="rounded-3"
      >
        <ModalBody className="d-flex justify-content-center my-3">
          <div className="col-sm-9 col-11">
            <div className="text-center mb-3">
              <img
                src={
                  type === "Unblock"
                    ? UnBlockIcon
                    : type === "logout"
                    ? LogOutIcon
                    : type === "block"
                    ? BlockIcon
                    : type === "warning"
                    ? WarningIcon
                    : DeleteIcon
                }
                alt="delete-icon"
              />
            </div>
            <div className="text-black text-center mb-4">{text}</div>
            <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
              <div onClick={modalClose}>
                <CancelButton />
              </div>
              <div onClick={deleteFn}>
                {type === "delete" ||
                type === "block" ||
                type === "logout" ||
                type === "warning" ||
                type === "remove" ? (
                  <DeleteButton
                    icon={false}
                    name={
                      type === "delete"
                        ? "DELETE"
                        : type === "logout"
                        ? "LOGOUT"
                        : type === "warning"
                        ? "CONFIRM"
                        : type === "remove"
                        ? "REMOVE"
                        : "BLOCK"
                    }
                  />
                ) : (
                  type === "Unblock" && <UnblockButton green_bg={true} />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteModal;
