import { all } from "redux-saga/effects";
import {
  watchDeliveryBlockWorker,
  watchDeliverypartnerCreateWorker,
  watchDeliverypartnerDeleteWorker,
  watchDeliverypartnerEditWorker,
  watchDeliverypartnerMultiDeleteWorker,
  watchDeliverypartnerTableWorker,
  watchDeliverypartnerViewWorker,
  watchtagsDropDownListWorker,
} from "../delivery-partner-saga";

export default function* deliverypartnerRootSaga() {
  yield all([
    watchDeliverypartnerTableWorker(),
    watchDeliverypartnerViewWorker(),
    watchDeliverypartnerEditWorker(),
    watchDeliverypartnerDeleteWorker(),
    watchDeliverypartnerMultiDeleteWorker(),
    watchDeliverypartnerCreateWorker(),
    watchtagsDropDownListWorker(),
    watchDeliveryBlockWorker()
  ]);
}
